lib.registerState('manager.finances.profileLedger.lineItems', {
  url: '/line-items',
  templateUrl: 'states/manager/finances/ledgers/profile/line-items/line-items.state.html',
  resolve: {

  },

  controller: function($http, $rootScope, $scope, $state, organization) {
    'use strict';
    /* Begin page-load logic */
    /*
    crebits pulled per request dynamically adjusts to get the most results in a reasonable time
    if a req takes longer than GOTTA_GO_FAST, divide the count by _DIVISOR
    if a req completes faster than SLOW_YOUR_ROLL, increment the count by _INCREMENT
    */
    let currentCrebitsPerRequest = 200;
    const CREBITS_PER_REQUEST_MIN = 50;
    const CREBITS_PER_REQUEST_MAX = 1000;
    const GOTTA_GO_FAST = 3000;
    const CREBITS_PER_REQUEST_DIVISOR = 2;
    const SLOW_YOUR_ROLL = 1000;
    const CREBITS_PER_REQUEST_INCREMENT = 50;

    /*
      `processingCrebits`: flag for when a request is in progress. Used for disabling the "Load" More button.
      `max`: makes sure that when doing a GET request that comes empty doesn't run it again
    */
    $scope.processingCrebits = false;
    $scope.max = false;

    /* Nothin' but the concatenated results of all of our http requests. Any stateful manipulation will not be present in this object */
    let rawCrebitData = null;

    $scope.today = moment(new Date()).format('YYYY-MM-DD');
    $scope.oldestDate = '2014-01-01';
    $scope.organization = organization;
    $scope.filters = {
      text: undefined,
      startDate: $scope.oldestDate,
      endDate: $scope.today,
      crebitType: undefined,
      fromRegistration: undefined,
      profileRegistration: undefined,
      deactivatedRegistrations: 'true',
      splitAttributions: 'false'
    };
    $scope.crebitTypeChoices = [
      'ADD-ON',
      'ADJUST',
      'COUPON',
      'DONATION',
      'TUITION',
      'CONVENIENCE-FEE',
      'EXTERNAL-PAYMENT',
      'EXTERNAL-REFUND',
      'MISC',
      'PAYMENT',
      'REFUND',
      'CHARGEBACK',
      'ACH-RETURN',
    ].map((type) => ({ label: type, value: `[${type}]` }));

    $scope.regFilterChoices = generateRegFilterChoices(organization);

    $scope.trueFalseChoices = [
      { label: 'Included', value: 'true' },
      { label: 'Not Included', value: 'false' }
    ];

    $scope.showFilters = false;

    $scope.crebitTotals = {
      debits: null,
      credits: null,
      balance: null
    };

    $scope.exportQueries = requestQueries(true);

    /* Stats for top-of-page boxes */
    fetchBalances().then(({data}) => {
      $scope.crebitTotals = {
        debits: parseInt(data.debits, 10) / 100,
        credits: parseInt(data.credits, 10) / 100,
        balance: parseInt(data.balance, 10) / 100
      };
    });

    initCrebits();
    /* End page-load logic */

    /* Begin scope functions */
    $scope.printPage = function() {
      $rootScope._print();
    };

    $scope.loadMoreCrebits = function() {
      if ($scope.processingCrebits === false && $scope.max === false) {
        $scope.processingCrebits = true;
        return fetchCrebits().then(({data}) => {
          rawCrebitData = rawCrebitData.concat(data);
          $scope.crebits = rawCrebitData;
          $scope.processingCrebits = false;
        }).catch(handleErrors);
      }
    };

    $scope.runFilters = function() {
      initCrebits();
    };

    $scope.updateExportQueries = function() {
      $scope.exportQueries = requestQueries(true);
    };
    /* End scope functions */

    /* Begin private functions */
    function initCrebits() {
      $scope.processingCrebits = true;

      rawCrebitData = null;
      $scope.crebits = null;

      fetchCrebits().then(({data}) => {
        rawCrebitData = data;
        $scope.crebits = rawCrebitData.slice();
        $scope.processingCrebits = false;
      }).catch(handleErrors);
    }

    function fetchCrebits() {
      const startTime = Math.round(performance.now());
      return $http.get(`/api/organizations/${organization.id}/crebitz?${requestQueries()}`).then((results) => {
        // We verify if the request data is less that the amount requested signaling is the last of the data entry
        if (results.data.length < currentCrebitsPerRequest || results.data.length === 0) {
          $scope.max = true;
        }
        const endTime = Math.round(performance.now());
        evaluateRequestTiming({startTime, endTime});
        return Promise.resolve(results);
      }).catch((err) => {
        $scope.processingCrebits = false;
        return Promise.reject(err);
      });
    }

    function requestQueries(isExport) {
      const queries = [];
      /* Attach desired result crebit count */
      if (!isExport) queries.push('crebitCount=' + currentCrebitsPerRequest);

      /* Not our first crebit request on the page. Pass along the ID of the oldest returned crebit */
      if (!isExport && rawCrebitData && rawCrebitData.length) {
        queries.push('startFrom=' + rawCrebitData[rawCrebitData.length - 1].originalCrebitID);
      }

      /* Apply filters */
      Object.entries($scope.filters).forEach(([key, value]) => {
        if (value) queries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });

      return queries.join('&');
    }

    function fetchBalances() {
      return $http.get(`/api/organizations/${organization.id}/ledger-balance/participant`);
    }

    /*
      Takes in a start and end time to determine how long a crebit-fetching request took
      If it takes longer than a preset threshold, reduce the number of crebits we grab in the next request
    */
    function evaluateRequestTiming({startTime, endTime}) {
      const routeTiming = endTime - startTime;
      if (routeTiming > GOTTA_GO_FAST) {
        currentCrebitsPerRequest = Math.max(Math.round(currentCrebitsPerRequest / CREBITS_PER_REQUEST_DIVISOR), CREBITS_PER_REQUEST_MIN);
      } else if (routeTiming < SLOW_YOUR_ROLL) {
        currentCrebitsPerRequest = Math.min(currentCrebitsPerRequest + CREBITS_PER_REQUEST_INCREMENT, CREBITS_PER_REQUEST_MAX);
      }
    }

    function generateRegFilterChoices(organization) {
      const groups = [];
      addGroup(organization);

      return groups.map((group) => {
        if (group.chain.length > 1) group.chain.shift();
        return {
          value: group.id,
          label: group.chain.join(' » ')
        };
      });

      function addGroup(group) {
        group.chain = group.chain ? group.chain : [];
        group.chain.push(group.name);

        const {id, chain, phase} = group;
        groups.push({id, chain, phase});

        if (group.children) {
          group.children.sort((child1, child2) => {
            return (child1.name).localeCompare(child2.name);
          }).forEach((child) => {
            child.chain = group.chain.slice();
            addGroup(child);
          });
        }
      }
    }

    function handleErrors(err) {
      console.log('caught err', err);
      flash('Failed to load transactions');
    }
    /* End private functions */

  } /* End controller */
}); /* End registerState() */
