lib.registerState('home.account.security', {
  url: '/security?force',
  resolve: {
    title: function ($rootScope) {
      $rootScope._title = 'User Settings / Security';
    },
    // Show MFA settings section if org requires it or user has a device
    showMFA: function($http, session) {
      return $http.get('/api/users/mfa-required').then((res) => {
        const hasDevice = !!(session.cognitoData && session.cognitoData.ChallengeName);
        return res.data || hasDevice;
      }).catch(() => false);
    }
  },
  templateUrl: 'states/home/account/security/security.state.html',
  controller: function ($scope, $state, flash, $stateParams, showMFA) {
    $scope.showMFA = showMFA;
    $scope.force = $stateParams.force === 'true';
    if ($scope.force) {
      setTimeout(function () {
        $('[model="user.newPassword"] input').focus();
      }, 100);
    }

    $scope.save = function () {
      $scope.user.save().then(function (user) {
        $scope.user = user;
        flash('Your new password has been set');
        if ($scope.force) return window.singleSpaNavigate('/!/dn/login');
        $state.go('^.information');
      });
    };

    $scope.userSettingsNavigate = function() {
      window.singleSpaNavigate(`/!/dn-next/user-settings/${$scope.user.id}/mfa`);
    };

    $scope.passwordEncode = function(password) {
      // base64 encode
      return window.btoa(password);
    };
  }
});
