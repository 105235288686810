angular.module('dn').service('PrescreeningData', (() => {
  'use strict';

  const ERR_TAG = '[PrescreeningData]';
  const STATS_ENABLED_QUESTIONS = window.dnim.constants.prescreening.staticQuestions;

  const _store = {
    ui: {},
    _initialUi: {},
    branding: {},
    template: [],
    templateID: null,
    editTargets: {
      question: null,
      template: null,
    },
    tmp: {
      signature: '',
      saving: false,
      question: {},
      newOption: {},
      branding: {},
    }
  };

  /**
   * Provided a question object, clone it into something we can edit. Essential for creating new
   * questions and editing existing ones.
   * @param {Object} [starter] A question object to clone for editing. When providing an object,
   *   include dataType and inputType at a minimum.
   * @returns {Object} A question object for editing.
   */
  function createTmpQuestion(starter = {}) {
    const defaults = {
      id: window.uuid(),
      dataType: 'dynamic',
      label: 'New Question',
      required: false,
      value: null,
      tooltip: '',
      inputType: 'text',
    };
    if (['tags', 'select'].includes(starter.inputType)) {
      defaults.options = [];
    }
    const tmpQuestion = Object.assign(defaults, starter);
    // Add isNew so we can automagically open an editor card when adding a new question.
    if (!starter.id) tmpQuestion.isNew = true;
    return tmpQuestion;
  }

  /**
   * Returns the tmp option for tags/multi-select questions.
   * @returns {Object}
   */
  function createTmpOption() {
    return {
      id: window.uuid(),
      value: null
    };
  }

  return class PrescreeningData {
    constructor($http, $rootScope) {
      this.$http = $http;
      this.$rootScope = $rootScope;
    }

    /**
     * Returns the branding object.
     * @returns {Object}
     */
    get branding() {
      return _store.branding;
    }

    get templateID() {
      return _store.templateID;
    }

    get template() {
      return _store.template;
    }

    get questionEditTarget() {
      return _store.editTargets.question;
    }

    get templateEditTarget() {
      return _store.editTargets.template;
    }

    get inputOptions() {
      return _store.inputOptions;
    }

    get tmpFormData() {
      return _store.tmp.prescreeningFormData;
    }

    get tmp() {
      return _store.tmp;
    }

    getTemplate(activeTemplateOnly, orgID) {
      return this.$http.get(`/api/organizations/${orgID}/templates?type=screening`)
        .then(({ data }) => {
          if (activeTemplateOnly) return data.find(obj => obj.template.active);
          return data;
        });
    }

    createTemplate(templateObject) {
      _store.templateID = templateObject.templateID;
      _store.template = _.cloneDeep(templateObject.template);
      _store.branding = _.cloneDeep(templateObject.branding);
    }

    createTemplateID(id) {
      _store.templateID = id;
    }

    /**
     * Clones the branding object and assigns it to the tmp branding
     * @returns {void}
     */
    createTmpBranding() {
      _store.tmp.branding = _.cloneDeep(_store.branding);
    }

    clearTemplate() {
      _store.templateID = null;
      _store.template = [];
    }

    clearTemplateID() {
      _store.templateID = null;
    }

    clearTmp() {
      _store.tmp = {
        temperatureUnit: 'c',
        signature: '',
        saving: false,
        branding: {},
      };
      this.clearTmpQuestion();
      this.clearTmpOption();
    }

    clearTmpQuestion() {
      _store.editTargets.question = null;
      _store.tmp.question = createTmpQuestion();
    }

    /**
     * Clears the tmp option from the store for tags/multi-select questions.
     * @returns {undefined}
     */
    clearTmpOption() {
      _store.tmp.newOption = createTmpOption();
    }

    verifyFormData() {
      const result = _store.template.every(question => {
        if (!question.required) return true;

        if (Array.isArray(question.value) && !question.value.length) {
          return false;
        // Loose equality check to handle both `null` and `undefined`
        // eslint-disable-next-line eqeqeq
        } else if (question.value == null || question.value === '') {
          return false;
        } else {
          return true;
        }
      });

      return result;
    }

    /**
     * Adds a new option for tags/multi-select questions. After it's done it clears the option on
     * the store. Operates on the tmp question, not the original.
     * @param {Object} question - The question that you want to add the option to
     * @returns {undefined}
     */

    addOption() {
      // Trim the value to get rid of trailing/leading whitespace
      _store.tmp.newOption.value = _store.tmp.newOption.value.trim();
      // We make the label same as the value for it to be consumed by material input in the participant portal
      _store.tmp.newOption.label = _store.tmp.newOption.value;
      // When we create a tmp question we clone the object and since the options are nested a push would alter
      // the unaltered question. The way to get around this problem is by reasigning the options instead of a push
      _store.tmp.question.options = [..._store.tmp.question.options, _store.tmp.newOption];
      this.clearTmpOption();
    }

    /**
     * Deletes an option for tags/multi-select questions. Operates on the tmp question.
     * @param {Object} option - The option that you want to delete. Only the ID is used right now.
     * @returns {undefined}
     */
    deleteOption({ id }) {
      _store.tmp.question.options = _store.tmp.question.options.filter(option => option.id !== id);
    }


    /**
     * Given a question, remove it from the template.
     * @param {Object} question The question we'd like to delete.
     * @param {String} question.id The ID to target for removal.
     * @returns {undefined}
     */
    deleteQuestion({ id }) {
      _store.template = _store.template.filter(question => question.id !== id);
      this.clearTmpQuestion();
    }

    /**
     * Verify if the question has been edited against the tmp question
     * @param {Object} question - the question to check against the one in the store
     * @returns {(boolean|void)} It returns a boolean if it deep equals the question on the store. It will log an error if the id does not match
     */
    checkQuestionIfEdited(question) {
      if (question.id !== _store.tmp.question.id) {
        log.error(new Error('[PrescreeningData][checkQuestionIfEdited] The question id does not match the one on the store'));
        return;
      }
      return !_.isEqual(question, _store.tmp.question);
    }

    /**
     * Verify if the branding on the store is different from the one in tmp
     * @returns {boolean} the result of the comparison between the 2 branding resources
     */
    checkBrandingIfEdited() {
      return !_.isEqual(_store.branding, _store.tmp.branding);
    }

    /**
     * Given a question, clone it onto tmp for editing purposes.
     * @param {Object} question The question object we'd like to edit
     * @returns {Object} A reference to the cloned question on tmp.
     */
    editQuestion(question) {
      // If click edit on the same question we want to close the editor
      if (_store.editTargets.question && _store.editTargets.question.id === question.id) {
        this.clearTmpQuestion();
        return;
      }
      // Mark this quest as the current edit target
      _store.editTargets.question = question;
      // Make sure we clear out newOption
      this.clearTmpOption();
      // Create a tmp question from it for editing
      return _store.tmp.question = createTmpQuestion(question);
    }

    /**
     * Given a question ID, move it one position up or down in the template array. This method
     * supports wrapping, so moving the first question up one will move it to the end of the list.
     * @param {String} direction The direction in which to move the question ("up" or "down")
     * @param {String} id The ID of the question we want to move.
     * @returns {undefined}
     */
    moveQuestion(direction, id) {
      if (!id) {
        log.error(new Error('[PrescreeningData][moveQuestion] No ID was provided'));
        return;
      }

      const indexQuestionToMove = _store.template.findIndex(question => question.id === id);

      // If the index does not exist
      if (indexQuestionToMove === -1) {
        log.error(new Error('[PrescreeningData][moveQuestion] Question does not exist'));
        return;
      }

      if (direction === 'up') {
        // If the current index is 0 we want to put it at the end of the list
        if (indexQuestionToMove === 0) {
          _store.template.push(_store.template.shift());
        } else {
          const questionToMove = _store.template[indexQuestionToMove];

          // Remove the element from the template
          _store.template.splice(indexQuestionToMove, 1);
          // Add it 1 position to the left
          _store.template.splice(indexQuestionToMove - 1, 0, questionToMove);
        }
      } else if (direction === 'down') {
        if (indexQuestionToMove === _store.template.length - 1) {
          _store.template.unshift(_store.template.pop());
        } else {
          const questionToMove = _store.template[indexQuestionToMove];

          // Remove the element from the template
          _store.template.splice(indexQuestionToMove, 1);
          // Add it 1 position to the right
          _store.template.splice(indexQuestionToMove + 1, 0, questionToMove);
        }
      } else {
        log.error(new Error('[PrescreeningData][moveQuestion] Direction must be up or down'));
        return;
      }
    }

    /**
     * Adds a new question to the `template` array.
     * @param {Object} question Basic info for the question we'd like to create
     * @param {String} question.dataType the desired dataType (either a column name or "dynamic")
     * @param {String} question.inputType the desired input type (only used for "dynamic")
     * @returns {Object} A question for editing. Directly references `tmp.question`
     */
    addQuestion({ dataType, inputType }) {
      // Note: we destructure/restructure like this to strip out any unexpected params
      let starterQuestion = { dataType, inputType };
      // If it's a stats-enabled question, we'll need additional handling.
      if (dataType !== 'dynamic') {
        const constantQ = STATS_ENABLED_QUESTIONS.find(q => q.dataType === dataType);
        if (!constantQ) return log.error(`${ERR_TAG}[addQuestion] Match failed for dataType "${dataType}".`);
        // Clone it so we don't mess with the constants (they're shallow, so .assign is sufficient)
        starterQuestion = Object.assign({}, constantQ);
      }
      // Have to create the question first so we can hold a direct reference
      _store.tmp.question = createTmpQuestion(starterQuestion);
      // Set the tmp question as the edit target directly (it's new, bb);
      _store.editTargets.question = _store.tmp.question;
      _store.template.push(_store.tmp.question);
      return _store.tmp.question;
    }

    /**
     * Applies changes from a tmp object clone to its original counterpart.
     * @param {String} resourceType Which tmp resource we're trying to apply changes to.
     * @returns {?Object} Returns a reference to the original resource. If there is no question to assign it returns null.
     */
    applyChanges(resourceType) {
      const current = _store.editTargets[resourceType];
      const updated = _store.tmp[resourceType];

      // If there are no questions to assign return early
      if (!current || !updated) return null;
      // If we're creating a question or template, current and updated will be the same object.
      if (current !== updated) Object.assign(current, updated);
      // We no longer consider it new once changes are applied
      delete current.isNew;
      return current;
    }

    /**
     * Applies the changes from a deep clone of tmp branding to the branding
     * @returns {void}
     */
    applyBrandingChanges() {
      _store.branding = _.cloneDeep(_store.tmp.branding);
    }

    /**
     * Saves the template. If it's a new template it adds the templateID to the store. If not it updates the existing template
     * @returns {(Promise<HttpPromise>|Promise<void>)} http request that can be either a PUT or a POST depending if we are dealing with an existing template
     */
    saveTemplate() {
      const payload = {
        name: 'COVID Prescreening',
        template: {
          active: true,
          questions: _store.template,
          branding: _store.branding
        },
        type: 'screening'
      };

      if (_store.templateID) {
        return this.$http.put(`/api/organizations/${this.$rootScope.organization.id}/templates/${_store.templateID}`, payload);
      } else {
        return this.$http.post(`/api/organizations/${this.$rootScope.organization.id}/templates`, payload)
          .then(res => {
            this.createTemplateID(res.data.id);
          });
      }
    }

    get ui() {
      return _store.ui;
    }

    resetUi() {
      Object.assign(_store.ui, _.cloneDeep(_store._initialUi));
    }

    setUiEntry(key, value) {
      const setPath = key.split('.');
      setPath.reduce((storeUi, key, i) => {
        if (i === setPath.length - 1) {
          storeUi[key] = value;
        }
        return storeUi[key];
      }, _store.ui);
    }

    initUi(uiConfig) {
      if (!uiConfig) uiConfig = {};
      _store.ui = _.cloneDeep(uiConfig);
      _store._initialUi = _.cloneDeep(uiConfig);
    }
  };
})());
