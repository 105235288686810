
angular.module("dn").factory("RegAdjustment", function($filter) {

  function RegAdjustment({ adjustment, registration }) {

    // Generic for all Reg Adjustments
    this.amount = adjustment.amount;
    this.type = RegAdjustment.TYPES.CUSTOM;
    this.tuition = registration.tuition;
    this.quantity = 1;
    this.applied = false;
    // Generic, but only defaults to true for custom adjustmetns
    this.selected = true;
    // Only for custom adjustments, but a harmless default
    this.description = adjustment.description;

    // All the ui-facing info; formatted amounts, etc.
    this.ui = {
      amount: $filter('currency')(this.amount / 100),
      // Alias "name" for compatibility with other RegAdjustments
      name: adjustment.description,
    };
    // For coupons and add-ons, this is useful for displaying extra info (e.g. Shared)
    this.ui.fullName = this.ui.name;

  }

  RegAdjustment.TYPES = {
    ADD_ON: 'add-on',
    CUSTOM: 'custom',
    COUPON: 'coupon'
  };

  return RegAdjustment;

});
