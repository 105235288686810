lib.registerState('manager.settings.analytics', {
  url: '/analytics',
  templateUrl: 'states/manager/settings/analytics/analytics.state.html',
  resolve: {
    title: function ($rootScope, group) { return $rootScope._title = `${group.name} / Analytics`; },
    parcelConfig: function () { return window.System.import('@dn/org-settings'); }
  },
  controller: function($scope, parcelConfig) {
    $scope.parcelConfig = parcelConfig;
  }
});
