lib.registerState('manager.healthLog', {
  url: '/health-log',
  templateUrl: 'states/manager/health-log/health-log.state.html',
  resolve: {
    providers($http, organization) {
      const url = `/api/organizations/${organization.id}/profiles`;
      const filter = '[registrations].type(is:provider)';
      return $http.get(`${url}?filters=${filter}`).then(({ data }) => {
        return _.sortBy((data || []).map(({ profiles }) => profiles), 'familyName');
      });
    }
  },
  controller($http, $location, $scope, $state, organization, providers) {

    $scope.organization = organization;

    $scope.organization.providers = providers;

    if ($state.current.name === 'manager.healthLog') {
      return $state.go('.browse');
    }
  }
});
