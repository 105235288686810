angular.module('dn').directive('gradeFilter', function() {
  return {
    templateUrl: 'directives/registration-wizard/grade-filter/grade-filter.directive.html',
    restrict: 'E',
    scope: {
      filtersEnabled: "=",
      filters: '=',
      filterName: "=",
      selectedGrade: "=",
      customText: "=",
      bypass: "=",
      isComplete: '='
    },
    controller($scope) {
      /*
      Due to the navigation directive's placement at the bottom of register.state.html,
      this controller will always load first, leaving $scope.bypass as a dummy function
      Wait one digest cycle and then run the controller logic to make sure bypass is defined
      Step renders for that one digest cycle, so set the var to show the page after we've made sure we're not bypassing
      */
      setTimeout(() => {
        $scope.isComplete = () => $scope.selectedGrade;

        if (!$scope.filtersEnabled || !$scope.filters || !$scope.filters.length) return $scope.bypass();
        $scope.renderPage = true;

        $scope.gradeTitle = $scope.filterName || "Grade";
        $scope.gradeChoices = $scope.filters.map((grade, index) => {
          return { label: grade, value: parseInt(index) }
        });
      });

    }
  }
});
