'use strict';

angular.module('dn').service('SavedLists', (() => {

  const _cache = {};

  return class SavedLists {
    constructor ($http) {
      this.$http = $http;
    }

    // Returns the list of sorted and un-duplicated lists
    get loadedLists() {
      return _cache.loaded;
    }

    clearCache() {
      _cache.loaded = [];
    }

    /**
     * Finds all saved lists for the user and the organization
     * @param {Object} organization contains the id value needed for the database lookup
     * @param {Object} user contains the id value needed for the database lookup
     * @returns {Object[]} returns an array of list objects attached to the user and the organization in the db
     */
    createSavedLists(organization, user) {
      return this.$http.get(`/api/users/${user.id}/filters`).then((result) => {
        saved = result.data;
        return this.$http.get(`/api/groups/${organization.id}/filters`).then((result) => {
          return [...saved, ...result.data];
        });
      });
    }

    /**
     * Filters and sorts an array of list objects so that it only returns each list once
     * and only if they are of the correct listType
     * @param {String} listType Each list is saved with a type that determines where it will show up in the app.
     *                          makes sure that incorrect areas are filtered out of the savedLists
     * @param {Object} organization Passed down to createSavedLists for its id
     * @param {Object} user Passed down to createSavedLists for its id
     * @returns {Array} sets _cache.loaded to the sorted and pruned array of Lists from the correct type
     */
    fetchSavedLists(listType, organization, user) {
      return this.createSavedLists(organization, user).then((result) => {
        const saved = result;

        const savedLists = saved.reduce((acc, list) => {
          const shouldSkip = (list.type === listType) ? acc.find(item => item.id === list.id) : true;
          return shouldSkip ? acc : [...acc, list];
        }, []);
        savedLists.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

        return _cache.loaded = savedLists;
      });
    }
  };
})());
