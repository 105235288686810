lib.registerState("patient.deactivated", {
  url: "/deactivated",
  templateUrl: "states/patient/deactivated/deactivated.state.html",
  resolve: {
    deactivatedMessage(organization) {
      return window.lib.getOrgDeactivatedMessage(organization);
    },
    title($rootScope, deactivatedMessage) {
      return $rootScope._title = deactivatedMessage.title;
    }
  },
  controller($scope, deactivatedMessage) {
    'use strict';
    Object.assign($scope, deactivatedMessage);
  }
});
