lib.registerState "manager.alerts",
  url: "/alerts"
  templateUrl: "states/manager/alerts/alerts.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Provider Portal / News Feed"

  controller: ($scope, $state, $filter, Event, $http, organization) ->

    # Async Acknowledgements setup
    getAcknowledgements = ->
      return new Promise (resolve, reject) ->
        if $filter('permissionVisible')({ view_news_feed: 'allow' })
          $http.get("/api/organizations/#{organization.id}/acknowledgements").then (result) ->
            $scope.acknowledgements =
                demographics: result.data.change || []
                unlock: result.data.extension || []
                cancellation: result.data.cancellation || []
            $scope.ackReceived = true
          .catch (err) ->
            flash "Error loading acknowledgements"
        else return resolve({})

    getAcknowledgements()

    # Async events setup
    getEvents = ->
      return new Promise (resolve, reject) ->
        if $filter('permissionVisible')({ view_news_feed: 'allow' })
          $http.get("/api/organizations/#{organization.id}/events?limit=100").then (result) ->
            return resolve(result.data)
          .catch (err) ->
            return reject(err)
        else return resolve([])

    initEvents = ->
      getEvents().then (loadedEvents) ->
        $scope.events = _.map loadedEvents, (e) ->
          e.givenName   = e.profile.givenName
          e.familyName  = e.profile.familyName
          e
        getTypeChoices()
        findImportantEvents()
        runFilters()
        $scope.noMoreEvents = !loadedEvents.length
        $scope.eventsReceived = true
      .catch (err) ->
        console.log 'caught err', err
        $scope.events = []

    initEvents()

    findImportantEvents = () ->
      $scope.important =
          followUp:
            today:        (_.filter $scope.events, (e) -> e.type is "healthLog.followUp" and moment(e.created).isSame(moment(), "day")).length
            yesterday:    (_.filter $scope.events, (e) -> e.type is "healthLog.followUp" and moment(e.created).isSame(moment().subtract(1, "day"), "day")).length

    # Filtering choices
    $scope.filters =
      type: "all"
      name: ""

    getTypeChoices = () ->
      $scope.typeChoices = _.map _.uniq(_.map($scope.events, "type")).sort(), (type) ->
        { value: type, label: Event.translate({type:type}, 'title') }
      $scope.typeChoices.unshift { label: "All Events", value: "all" }

    runFilters = () ->
      _.map $scope.events, (e) ->
        e._hide = false
        if $scope.filters.type isnt "all" and $scope.filters.type isnt e.type
          e._hide = true


    $scope.fetching = false

    $scope.unprintedHelp = false
    $scope.goReports = () ->
      $state.go "manager.profiles",
        tab: "report-wizard"

    $scope.ACK_LIMIT = 5
    $scope.demoLimit = $scope.ACK_LIMIT
    $scope.unlockLimit = $scope.ACK_LIMIT
    $scope.cancellationLimit = $scope.ACK_LIMIT

    $scope.show =
      "demographics": false
      "unlock": false

    $scope.toggleAck = (type) ->
      $scope.show[type] = !$scope.show[type]

    $scope.fetchEvents = ->
      $scope.fetching = true
      url = "/api/organizations/#{organization.id}/events?limit=50"
      url += '&before=' + moment(new Date(_.last($scope.events).created)).utc().format('YYYY-MM-DD HH:mm:ss')
      $http.get(url).then (
        (result) ->
          $scope.fetching = false
          $scope.noMoreEvents = true if result.data.length < 50
          _.each result.data, (e) ->
            e.givenName   = e.profile.givenName
            e.familyName  = e.profile.familyName
            e._hide = false
            $scope.events.push e
          getTypeChoices()
          runFilters()
      ), (error) ->
        flash "Error loading events"
        $scope.fetching = false

    $scope.$watch "filters", runFilters, true
