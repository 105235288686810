angular.module("dn").directive "operFinancesFilter", ->
  restrict: "A"
  templateUrl: "states/oper/finances/filter/oper-finances-filter.directive.html"
  scope:
    organizations: "=operFinancesFilter"
    loading: "=loading"

  controller: ($scope, $filter) ->
    $scope.showFilters = true

    $scope.filter =
      active: "active"
      paymentMethod: "all"

    $scope.activeChoices = [
      { label: "All", value: "all" }
      { label: "Active", value: "active" }
      { label: "Deactivated", value: "deactivated" }
    ]

    $scope.paymentMethodChoices = [
      { label: "All", value: "all" }
      { label: "Payment Method", value: "true" }
      { label: "No Payment Method", value: "false" }
    ]

    $scope.runFilter = (filter) ->
      _.map $scope.organizations, (org) ->
        org._hide = false
        if filter.active isnt "all"
          if filter.active is "active" and org.deactivated then return org._hide = true
          if filter.active is "deactivated" and !org.deactivated then return org._hide = true

        if filter.paymentMethod isnt "all"
          if filter.paymentMethod is "true" and !org.paymentMethodID then return org._hide = true
          if filter.paymentMethod is "false" and org.paymentMethodID then return org._hide = true
        if filter.text
          return org._hide = true if !$filter("text") org, filter.text

    $scope.export = $scope.$parent.exportCsv

    $scope.$watch "filter", (filter) ->
      $scope.runFilter filter
      $scope.orgCount = (_.filter $scope.organizations, (o) -> !o._hide).length
    , true
