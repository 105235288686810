lib.registerState('manager.healthLog.browse', {
  url: '/browse',
  templateUrl: 'states/manager/health-log/browse/browse.state.html',
  resolve: {
    title($rootScope) {
      return $rootScope._title = 'Health Log Entries';
    },
    logEntries($http, organization) {
      const url = `/api/organizations/${organization.id}/log-entries`;
      const filter = '[logEntries].triage(is:false)&limit=50';
      return $http.get(`${url}?filters=${filter}`, {cache: true})
        .then(({ data }) => data);
    },
    savedFilters($http, organization, session) {
      return $http.get(`/api/users/${session.userID}/filters`).then(({ data: userFilters }) => {
        return $http.get(`/api/groups/${organization.id}/filters`).then(({ data: orgFilters }) => {
          return userFilters.concat(orgFilters).filter((f) => f.type === 'health-log');
        });
      });
    },
    complaints($http) {
      return $http.get('/api/constants/complaints', {cache:true}).then(({ data }) => {
        return Object.keys(data).sort();
      });
    },
    nDiag($http) {
      return $http.get('/api/constants/nursing-diagnosis', {cache:true}).then(({ data }) => data);
    }
  },
  controller($http, $location, $scope, $state, $timeout, complaints, logEntries, nDiag, providers, savedFilters, session) {
    'use strict';

    // Note: we can't use these constants in resolves, so if these values change make sure to update
    // the `logEntries` resolve above.
    const PAGE_LENGTH = 50;
    const NO_TRIAGE_FILTER = '[logEntries].triage(is:false)';

    // Init
    $scope.filterString = '';
    $scope.orgID = $scope.organization.id;
    $scope.organization.complaints = complaints;
    $scope.organization.nursingDiagnoses = nDiag;
    $scope.user = _.assign(session.user, { filters: savedFilters });
    $scope.filters = [];
    $scope.activeList = { text: '' };
    $scope.logEntries = [];
    $scope.preloadFilter = NO_TRIAGE_FILTER;
    $scope.applying = false;
    // We use it for keeping track of all our entries
    let logEntriesDictionary = {};

    $scope.assignProvider = function(entry) {
      if (entry.providerID) {
        entry.provider = providers.find(p => p.id === entry.providerID);
      }
    };

    function addNewEntry({ logEntries: entry, profiles: profile, medications: medication }) {
      // This guarantees that we are not adding duplicates cause it breaks ng-repeat
      if (logEntriesDictionary[entry.id]) return;
      logEntriesDictionary[entry.id] = true;

      entry.profile = profile;
      entry.medName = medication && medication.name;
      $scope.assignProvider(entry);

      $scope.logEntries.push(entry);
    }

    $scope.setEntries = function(rawEntries) {
      const newRawEntries = _.cloneDeep(rawEntries);
      $scope.logEntries.length = 0;

      newRawEntries.forEach(addNewEntry);
      finishProcessing(newRawEntries);
    };

    $scope.setEntries(logEntries);

    $scope.entryState = () => $state.current.name === 'manager.healthLog.browse.entry';

    // Choose-able saved filters
    $scope.lists = savedFilters.map((s) => {
      if (s.share) {
        return { label: s.name, value: s.string, optgroup: 'Shared', owner: s.userID === session.userID };
      } else {
        return { label: s.name, value: s.string, optgroup: 'Custom', owner: s.userID === session.userID };
      }
    });

    // Don't have to click 'Add Condition' on start
    if (!$scope.filters.length) $scope.filters.push(new window.lib.Filter());

    // We added a blank filter to make initial filtering less arduous, so
    // for auto-opening we check if a filter has anything in it.
    $scope.$watch('filters', (fresh, stale) => {
      if (!fresh) return;
      // This guarantees everytime we change a filter the list is fresh baby.
      if (fresh !== stale) logEntriesDictionary = {};
      if (fresh[0] && fresh[0].string && fresh[0].string.length) {
        $scope.showFilters = true;
      }
      // Extract the strings from the filters without modifying them, causing a watch-loop
      // Collections doesn't support the date comparators available in New Collections, so
      // we replace them with the standard comparators.
      $scope.filterString = getListBuilderString(fresh).replace(/\(date/g, '(');
    }, true);

    $scope.$watch('logEntries', (fresh, stale) => {
      if (fresh === stale) return;
      $scope.setEntries(fresh);
    });

    /*
     * Watch for changes in the profile search input
     * If it changes, then kick off the 'applying' flow (see html for this file and filter builder)
     *   When there's a fresh value (will come in as an id), it means we have a profile
     *     So add a profile collections filter for a patientID matching that profile id
     *   When there's not a fresh value (or the value has changed to null from clearing the input)
     *     Then just apply the NO_TRIAGE_FILTER, as there is no profile to filter for
     */
    $scope.$watch('profile', (fresh, stale) => {
      if (fresh === stale) return;
      // This guarantees everytime we change a profile the list is fresh baby.
      logEntriesDictionary = {};
      $scope.applying = true;
      if (fresh) {
        $scope.preloadFilter = `[logEntries].patientID(is:${fresh})|${NO_TRIAGE_FILTER}`;
      } else {
        $scope.preloadFilter = NO_TRIAGE_FILTER;
      }
    });

    // # Apply filters if present in URL on page load
    $timeout(() => {
      const found = _.find($scope.lists, { value: _.map($scope.filters, 'string').join('|') });
      if (found) $scope.activeList.text = found.value;
      if (!$state.params.filters && $scope.filters.length) {
        const addFilters = _.map($scope.filters, (f) => new window.lib.Filter(f.object));
        $location.search('filters', _.map(addFilters, 'string').join('|'));
      }
    });

    function parseFilter(filter) {
      // #5957 - Append the midnight timestamp to 'before or on' and 'after' filters for 'logged' dates
      // so timestamps falling on the date don't get erroneously filtered out (for <=) or included (for >)
      if (filter.indexOf('logged') > 0) {
        let timestamp = '';
        if (filter.match(/<=:|>:/)) {
          timestamp = 'T23:59:59';
        }
        return filter.replace(/\)$/, timestamp + ')');
      } else {
        return filter;
      }
    };

    function finishProcessing(nextEntries) {
      if (nextEntries && nextEntries.length < PAGE_LENGTH) {
        $scope.max = true;
      } else {
        $scope.max = false;
      }

      $scope.processingEntries = false;
    }

    // Setup for fetching more paginated entries
    $scope.max = false;
    $scope.processingEntries = false;

    function getListBuilderString(filters) {
      return filters.reduce((strings, filter) => {
        const f = new window.lib.Filter(filter.object);
        if (!['condition', 'path', 'value'].every((v) => f.object[v])) return strings;
        if (f.string) strings.push(parseFilter(f.string));
        return strings;
      }, []).join('|');
    }

    $scope.loadMoreEntries = function() {
      if ($scope.processingEntries === false && $scope.max === false) {
        $scope.processingEntries = true;
        const listFilters = getListBuilderString($scope.filters);
        const filters = [listFilters].reduce((acc, str) => str ? `${acc}|${str}` : acc, $scope.preloadFilter);
        const url = `/api/organizations/${$scope.organization.id}/log-entries`;
        const offset = $scope.logEntries.length ? `&offset=${$scope.logEntries.length}` : '';

        return $http.get(`${url}?filters=${filters}&limit=${PAGE_LENGTH}${offset}`)
          .then(({ data }) => {
            data.forEach(addNewEntry);
            finishProcessing(data);
          });
      }
    };
  }
});
