'use strict';
angular.module("dn").factory("RegData", ($http) =>
  class RegData {
    constructor(organization, groupID, insuranceStandalone) {
      this.org = organization;
      this.orgID = organization.id;
      this.groupID = groupID || null;
      this.addOns = [];
      this.coupons = [];
      this.occupancies = [];
      this.paymentMethods = [];
      this.paymentPlan = {};
      this.insuranceStandalone = insuranceStandalone || false;
    }

    // Private
    _fetch(resource, options, method) {
      return new Promise((resolve, reject) => {
        let routes = {
          "addOns": "/add-ons",
          "coupons": "/coupons",
          "occupancies": "/occupancies"
        }

        let httpMethod = method || "get";
        let params = [];
        let queryString = "";

        if (httpMethod !== "post") {
          if (options) {
            if (options.groups) params.push("groups=" + options.groups.join("|"));
            if (options.noShared) params.push("no-shared=true");
          }

          queryString = params.length ? "?" + params.join("&") : "";
        }

        let target = "/api/organizations/" + this.orgID + routes[resource] + queryString;

        return $http[httpMethod](target, httpMethod === "post" ? options.groups : null).then((success) => {
          if (resource === "addOns" || resource === "coupons") {
            this[resource] = _.uniqBy(this[resource].filter(r => r.shared).concat(success.data), "id");
          } else {
            this[resource] = success.data;
          }
          return resolve();
        }, reject);
      });
    }

    // Public
    fetchAddOns(groups) {
      return new Promise((resolve, reject) => {
          this._fetch("addOns", { "groups": groups, "noShared": !!this.addOns.length }).then(resolve).catch(reject);
      });
    }

    fetchCoupons(groups) {
      return new Promise((resolve, reject) => {
          this._fetch("coupons", { "groups": groups, "noShared": !!this.coupons.length }).then(resolve).catch(reject);
      });
    }

    fetchOccupancies(groups) {
      /* Breaking REST here and using a POST. Reasoning being that an org could have a shitton of registerable groups, and those groupIDs could potentially max out the URL address length */
      return new Promise((resolve, reject) => {
        // Changing the selected grade will change the number of groupIDs passed in, meaning we can't rely on the existing data.
        this._fetch("occupancies", { "groups": groups }, "post").then(resolve).catch(reject);
        // if (!this.occupancies.length) {
        // } else {
        //   return resolve();
        // }
      });
    }

    fetchPaymentPlan(profileID) {
      return new Promise((resolve, reject) => {
        if (!this.paymentPlan.id) {
          $http.get("/api/profiles/" + profileID + "/payment-plan").then((success) => {
            this.paymentPlan = success.data || {};
            return resolve(success.data);
          }, (error) => {
            return reject(error);
          });
        }
      });
    }

    fetchPaymentMethods(userID) {
      return new Promise((resolve, reject) => {
        $http.get("/api/users/" + userID + "/payment-methods").then((success) => {
          const newMethodOption = { label: 'New Method', value: 'new-method' };
          if (this.insuranceStandalone) {
            const methods = success.data;
            // TODO: This is the same formatting logic we have in
            // method-filter-restriction. We should decouple the formatting from
            // filtering so we don't have to duplicate code
            this.paymentMethods = methods.map(method => {
              const methodDescription = `${method.type} -${method.lastfour}`;
              return {
                label: method.name ? `[${method.name}] ${methodDescription}` : methodDescription,
                value: method.id,
                meta: method
              };
            });
            this.paymentMethods.unshift(newMethodOption);
          } else {
            const methods = window.lib.methodFilterRestriction(success.data, this.org, newMethodOption);
            this.paymentMethods = methods || [];
          }
          return resolve();
        }, (error) => {
          return reject(error);
        });
      });
    }
  }
);
