angular.module('dn').directive('prescreeningTemperatureInput', function() {
  return {
    templateUrl: 'directives/prescreening/temperature-input/temperature-input.directive.html',
    restrict: 'E',
    scope: {
      input: '='
    },
    controller($scope) {
      $scope.ui = {
        value: $scope.input.value,
        unit: $scope.input.unit || 'f',
        cacheValues
      };

      function cacheValues() {
        const { value, unit } = $scope.ui;

        // If input is empty it will return NaN. We want it to turn into null
        $scope.input.value = parseFloat(value) || null;
        $scope.input.unit = unit;
      }
    }
  };
});
