# ngModelController has an array of $parsers and $formatters (functions) that
# execute validity checks, returning the value (or modified value) if valid, and
# undefined if invalid.  This clears those $parsers and $formatters and has them
# always return the original value.

angular.module("dn").directive "clearValidators", ->
  restrict: "A"
  require: "ngModel"
  link: (scope, elm, attrs, ngModelCtrl) ->
    ngModelCtrl.$parsers = ngModelCtrl.$formatters = [ (value) -> value ]
