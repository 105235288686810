angular.module("dn").directive "qtImmunizations", ->
  restrict: "A"
  templateUrl: "directives/taker/modules/immunizations/qt-immunizations.directive.html"
  scope:
    question: "=qtImmunizations"
    immunizations: "="
    profile: "="
  controller: ($rootScope, $scope, $stateParams, HealthForm, Images) ->
    immsRegFlag = window.lib.featureFlagClient.isEnabled('imms-reg')

    # Autoload
    backToManualEntry = ->
      $scope.formState.entryType = 'web'
      $scope.loadAutoLoad = false
    applyImmsData = (immsRegData) ->
      orgQuestions = Object.values($scope.question.metadata.doses)
      immsConfig = _.filter orgQuestions, (question) -> question.included

      $scope.immunizations = window.lib.applyImmsReg(immsRegData, $scope.immunizations, immsConfig, profileID)

      do mapImmunizations
      do saveFn

    $scope.parcelProps = {
      profile: $scope.profile,
      locked: $scope.question.locked || $scope.question.stepLockout
      backToManualEntry: backToManualEntry
      applyImmsData: applyImmsData
    }
    $scope.parcelConfig = () ->
      window.System.import('@dn/imms-reg')

    showImmsRegMFE = immsRegFlag &&
      $rootScope.organization.properties.enableImmsReg &&
      !($scope.question.locked || $scope.question.stepLockout) &&
      $scope.question.metadata.inputType != "upload" &&
      !$stateParams.review

    $scope.loadAutoLoad = showImmsRegMFE

    $scope.backButtonTooltip = 'Auto lookup disabled due to your last request being less than 300 days ago'

    # Separate from loadAutoload because loadAutoload gets changed before a user would see this button
    $scope.showBackButton = showImmsRegMFE

    # Immunizations Setup
    profileID = $stateParams.profileID
    healthForm = HealthForm.get profileID

    $scope.formState = { entryType: null }
    $scope.files = []

    $scope.question.metadata.inputType ?= "both"

    if $scope.question.metadata.inputType is "both"
      $scope.displayTabs = true
    else $scope.displayTabs = false

    if $scope.question.metadata.inputType is "upload"
      $scope.formState.entryType = "upload"
    else $scope.formState.entryType = "web"

    # Since it's possible to set a default type, then change the allowed input types to something other
    # than the selected default, this if is needed to prevent "bad" metadata values on the Question
    if $scope.question.metadata.defaultType is "upload" and $scope.question.metadata.inputType isnt "web"
      $scope.formState.entryType = "upload"

    $scope.emptyFile = [{ profileID: parseInt(profileID), file: null, doseNumber: 1, name: "file" }]

    $scope.groupedImms = _.groupBy $scope.immunizations, "name"

    $scope.entries = {}
    $scope.today = moment().format("YYYY-MM-DD")

    # Detect if we've uploaded a file from grouped immunizations
    $scope.uploaded = ->
      if $scope.question.metadata.inputType is "web" then return false
      return $scope.groupedImms.file?[0]?.file isnt "" and $scope.groupedImms.file?[0]?.file isnt null

    $scope.groupedImms.file ?= $scope.emptyFile

    # Upload action for immunization files
    $scope.action = ->
      route = "/api/profiles/#{profileID}/answers/upload"
      if $scope.uploaded()
        route += "?prepend=#{$scope.groupedImms.file[0].file}"
      route

    if $scope.question.metadata.template
      Images.getForProfile(profileID, $scope.question.metadata.template, true).then (tpl) ->
        $scope.templateFiles = tpl


    # When we've uploaded a file, append to groupedImms
    $scope.completeFn = (content) ->
      if !(_.some $scope.immunizations, (i) -> i.name is "file" and i.file?.length)
        $scope.immunizations.push $scope.groupedImms.file[0]
      $scope.groupedImms.file[0].file = content.key


    # Is a dose required? Useful for displaying red lines
    $scope.checkRequired = (dose) ->
      if not $scope.question.required then return ""
      if dose.required and dose.notReceived and dose.details then return ""
      if dose.required and (!$scope.uploaded() or dose.sticky) then return "required"
      return ""

    # Upload is required if they haven't completed the web form and offer an upload option
    $scope.uploadRequired = ->
      if not $scope.question.required then return ""
      if _.some($scope.dosesAvailable, (d) -> !$scope.complete(d)) then return "required"
      return ""

    # Make included doses available with an integer array for dose iteration
    doses = _.map $scope.question.metadata.doses, (d) ->
      d.map = _.range(1, parseInt(d.doses) + 1)
      if d.included then return d
      else return null
    $scope.dosesAvailable = _.compact doses

    $scope.resultChoices = [
      { label: 'Positive', value: 'positive' }
      { label: 'Negative', value: 'negative' }
    ]

    # Update the 'required' status of a dose based on if we've uploaded a file already
    if $scope.uploaded()
      $scope.dosesAvailable = _.map $scope.dosesAvailable, (d) ->
        if $scope.question.metadata.inputType is "web" then return d
        if !d.sticky then d.required = false
        if !$scope.question.required then d.required = false
        d

      Images.getForProfile(profileID, $scope.groupedImms.file[0].file).then (result) ->
        $scope.files = result

    # Create an object grouped by immunization type with an array entry for each included dose
    # Each entry is either an empty immunization (which wont be saved), or the existing one
    mapImmunizations = ->
      $scope.immunizations = _.sortBy $scope.immunizations, "doseNumber"
      tmpImms = _.groupBy $scope.immunizations, "name"
      _.map $scope.dosesAvailable, (dose) ->
        $scope.entries[dose.key] ?= []
        _.map dose.map, (i) ->
          if tmpImms[dose.key] and _.find(tmpImms[dose.key], { doseNumber: i })
            $scope.entries[dose.key][i - 1] = _.find(tmpImms[dose.key], { doseNumber: i })
          else
            $scope.entries[dose.key][i - 1] ?= { name: dose.key, profileID: profileID, doseNumber: i }
      _.map $scope.entries, (val, key) -> $scope.entries[key] = _.sortBy val, "doseNumber"

    do mapImmunizations

    # Create a backup of entries to compare for changes made to entries
    entriesBackup = _.cloneDeep($scope.entries)

    # Check completeness across a single "dose" from dosesAvailable
    $scope.complete = (doseRequirement) ->
      if !doseRequirement.required then return true
      if $scope.question?.expiration and doseRequirement?.updated
        return false if $scope.question.expiration > doseRequirement.updated
      if $scope.uploaded() and $scope.question.metadata.inputType isnt "web" and not doseRequirement.sticky then return true
      if not $scope.entries[doseRequirement.key] then return false
      return _.every $scope.entries[doseRequirement.key], (e) ->
        if e.notReceived
          return !!e.details?.length
        return e.date

    expired = () ->
      if $scope.question.expirationType in ['step', 'form'] and $scope.question.expiration
        return _.every($scope.immunizations, (i) => i.updated < $scope.question.expiration)
      return false

    # Check completeness for every dose requirement from dosesAvailable
    healthForm.completeFns[$scope.question.id].push ->
      allComplete = $scope.question.metadata.inputType isnt 'upload' and _.every $scope.dosesAvailable, (dose) -> $scope.complete(dose)
      uploaded = $scope.question.metadata.inputType isnt 'web' and $scope.uploaded()
      return true if !$scope.question.required
      return (allComplete || uploaded) and !expired()


    # Return only filled out immunizations, since these are the only ones we want to save
    filterEmpty = ->
      filtered = []
      _.forIn $scope.entries, (entries, key) ->
        _.map entries, (e, i) ->
          if e.date or (e.notReceived and e.details?.length) or
          (entriesBackup[key][i].date != e.date) or
          (entriesBackup[key][i].details != e.details) or
          (entriesBackup[key][i].notReceived != e.notReceived)
            # if notReceived checkbox checked and details either 'neg' or 'pos'
            if e.notReceived and (e.details == 'negative' or e.details == 'positive')
              # set details to '' for clear input. This should really be set to null,
              # and see issue docnetwork/app#6141 for details.
              e.details = ''
            # if notReceived checkbox unchecked and details isn't 'neg' or 'pos'
            if !e.notReceived and (e.details != 'negative' and e.details != 'positive')
              # set details to '' to 'delete' the old details. This happens when you uncheck
              # the box without clearing the input, leaving behind the old details; we can
              # assume the user means for that info to be cleared
              e.details = ''

            filtered.push e
      filtered


    # Re-group and map when something changes
    $scope.$watch "immunizations", ->
      $scope.groupedImms = _.groupBy $scope.immunizations, "name"
      $scope.groupedImms.file ?= $scope.emptyFile
      do mapImmunizations
    , true

    resetReview = ->
      if !$stateParams.review
        healthForm.profile.reviewFlags ?= []
        healthForm.profile.reviewFlags.push($scope.question.id.toString())

    # Save Queue for regular immunizations
    saveFn = (nothing, fn=(->)) ->
      do resetReview
      healthForm.save("immunizations", profileID, filterEmpty()).success (imms) ->
        _.map imms, (i) ->
          found = _.find $scope.immunizations, { doseNumber: i.doseNumber, name: i.name }
          if not found then $scope.immunizations.push i
          else found.id = i.id
        do mapImmunizations
        fn()

    saveQueue = async.queue saveFn, 1

    addSave = _.debounce ->
      saveQueue.push saveFn
    , 1000


    # Separate queue for uploads
    saveUpload = (nothing, fn=(->)) ->
      do resetReview
      healthForm.save("immunizations", profileID, $scope.groupedImms.file).success (imms) ->
        $scope.groupedImms.file[0].file = imms[0].file
        $scope.groupedImms.file[0].id = imms[0].id
        fn()

    uploadQueue = async.queue saveUpload, 1

    addUpload = _.debounce ->
      uploadQueue.push saveUpload
    , 500


    $scope.$watch ->
      filterEmpty()
    , (entries, stale) ->
      if (!_.isEqual(entries, stale))
        do healthForm.checkComplete[$scope.question.id]
        do addSave
    , true


    $scope.$watch ->
      return unless $scope.groupedImms.file
      $scope.groupedImms.file[0]
    , (uploaded, stale) ->
      return if uploaded?.file is stale?.file

      $scope.dosesAvailable = _.map $scope.dosesAvailable, (d) ->
        if !d.sticky then d.required = false
        d

      do healthForm.checkComplete[$scope.question.id]
      do addUpload
    , true
