angular.module("dn").factory "Constants", ($http) ->

  @states = ->
    $http.get("/api/constants/state-province", { cache: true }).then (result) =>
      @_states = result.data
      result.data

  @countries = ->
    $http.get("/api/constants/countries", { cache: true }).then (result) =>
      @_countries = result.data
      result.data

  @fdaMeds = ->
    $http.get("/api/constants/fda-med", { cache: true }).then (result) =>
      @_fdaMeds = result.data
      result.data

  @allergies = ->
    $http.get("/api/constants/allergies", { cache: true }).then (result) =>
      @_allergies = result.data
      result.data

  @affects = ->
    $http.get("/api/constants/affects", { cache: true }).then (result) =>
      @_affects = result.data
      result.data

  @moods = ->
    $http.get("/api/constants/moods", { cache: true }).then (result) =>
      @_moods = result.data
      result.data

  @sras = ->
    $http.get("/api/constants/sras", { cache: true }).then (result) =>
      @_sras = result.data
      result.data

  @bras = ->
    $http.get("/api/constants/bras", { cache: true }).then (result) =>
      @_bras = result.data
      result.data

  @sibas = ->
    $http.get("/api/constants/sibas", { cache: true }).then (result) =>
      @_affects = result.data
      result.data

  @anras = ->
    $http.get("/api/constants/anras", { cache: true }).then (result) =>
      @_anras = result.data
      result.data

  @cognitiveFunctionings = ->
    $http.get("/api/constants/cognitiveFunctionings", { cache: true }).then (result) =>
      @_cognitiveFunctionings = result.data
      result.data

  @hras = ->
    $http.get("/api/constants/hras", { cache: true }).then (result) =>
      @_hra = result.data
      result.data

  @
