lib.registerState('manager.attendance.list', {
  url: '/:action',
  templateUrl: 'states/manager/attendance/list/list.state.html',
  controller: function($http, $location, $scope, $state, $timeout, organization, session, AttendanceData) {
    'use strict';

    // clear out the store
    AttendanceData.clearStore();

    $scope.data = AttendanceData;
    $scope.participants = AttendanceData.ui.helpers.patientNoun('plural');

    // Check to see if they're taking attendance
    $scope.takingAttendance = $state.params.action === 'attendance';
    // If they are, then set a default type as the model
    // and format the org's types as dropdown items
    if ($scope.takingAttendance) {
      $scope.attendanceTypes = ($scope.organization.properties.features.attendance.types || []).map(t => ({ label: t, value: t }));
    }

    // Initialize $scope values for list builder
    $scope.profiles = AttendanceData.profiles;
    $scope.preloadFilter = `[registrations].type(is:patient)${ $state.params.action === 'audit' ? '' : '|phase(is:present)'}`;
    $scope.filters = [];
    $scope.activeList = { text: $location.search().filters || '' };
    $scope.organization = organization;
    $scope.user = session.user || {};
    // Setup $scope.lists here - but with a flag to signal when they're done loading
    $scope.lists = [];
    $scope.listsInitialized = false;

    if ($state.params.action === 'audit') {
      $scope.lbTooltip = `
        Only ${$scope.participants} appear on the ${AttendanceData.ui.title} List, but
        you can apply extra filters for more specificity (e.g. only include past ${$scope.participants}).
      `;
    } else {
      $scope.lbTooltip = `
        Only current ${$scope.participants} appear on the ${AttendanceData.ui.title} List, but you
        can apply extra filters for more specificity.
      `;
    }

    fetchProfiles();
    fetchSavedLists().then(handleSavedLists);

    // Don't have to click 'Add Condition' on start
    if (!$scope.filters.length) {
      $scope.filters.push(new window.lib.Filter());
    }


    // Apply filters if present in URL on page load (lifted from health log browse)
    $timeout(() => {
      const find = _.find($scope.lists, { value: _.map($scope.filters, 'string').join('|') });
      if (find) {
        $scope.activeList.text = find.value;
      }

      if (!$state.params.filters && $scope.filters.length) {
        const addFilters = _.map($scope.filters, (f) => new window.lib.Filter(f.object));
        $location.search('filters', _.map(addFilters, 'string').join('|'));
      }
    });

    function fetchProfiles() {
      const filterString = $location.search().filters || $scope.preloadFilter;
      $http.get(`/api/organizations/${organization.id}/attendance-records?filters=${filterString}&includeAvatar=md`).then(({ data }) => {
        $scope.profiles = data;
        AttendanceData.loadFromCollections(data);
      });
    }

    function fetchSavedLists() {
      return new Promise((resolve, reject) => {
        $http.get(`/api/users/${$scope.user.id}/filters`).then(({ data: custom }) => {
          $http.get(`/api/groups/${$scope.organization.id}/filters`).then(({ data: shared }) => {
            return resolve(custom.concat(shared).filter(l => l.type === 'attendance'));
          }).catch((err) => {
            return reject(err);
          });
        });
      });
    }

    function handleSavedLists(lists) {
      let optgroup, owner;
      Object.assign($scope.user, { filters: lists });
      $scope.lists = (lists || []).map(({ name: label, string: value, share, userID }) => {
        optgroup = share ? 'Shared' : 'Custom';
        owner = userID === $scope.user.id;
        return { label, value, optgroup, owner };
      });
      $scope.listsInitialized = true;
    }
  }
});
