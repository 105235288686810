dn = angular.module("dn")
requestHistory = {}

dn.run ($rootScope) ->
  window.currentState ?= ""

  $rootScope.$on "$stateChangeSuccess", (event, toState) ->
    window.currentState = toState.name
    return

  $rootScope.$on "accessRedirect", (event, toState) ->
    return

dn.factory "httpHistory", ($rootScope) ->
  httpHistory = []
  httpHistory.add = (config) ->
    id = uuid.v4()
    config.headers["x-id"] = id
    httpHistory.push
      id: id
      created: new Date()
      method: config.method
      url: config.url
      headers: config.headers
      from: window.location.pathname
      state: window.currentState
    config

  httpHistory.complete = (response) ->
    return response unless response
    if response?.headers?("X-Session-Expires")
      timeLeft = Math.ceil((new Date(response.headers("X-Session-Expires")) - new Date()) / 1000)
      if timeLeft > 0
        window.sessionExpires = response.headers("X-Session-Expires")
        $rootScope.sessionExpires = response.headers("X-Session-Expires")

    if not response.config.headers["x-id"] then return response

    request = _.find httpHistory, (req) ->
      req.id is response.config.headers["x-id"] and !_.has req, "statusCode"
    if not request then return
    request.statusCode = response.status
    request.completed = new Date()
    request.time = request.completed - request.created
    request.sameState = request.state is window.currentState

    if request.statusCode in [401, 403] and request.sameState and request.state isnt "home.auth.login"
      window.sessionStorage.setItem 'deepLink', window.location.pathname + window.location.search
      window.location.href = "/!/dn/login"
    response

  setInterval ->
    httpHistory = _.reject httpHistory, (request) ->
      if request.completed then return new Date() - request.completed > 10000
      else return new Date() - request.created > 60000
      false
  , 10000
  httpHistory

dn.config ($httpProvider) ->
  $httpProvider.interceptors.push ($q, httpHistory) ->
    request: (config) ->
      if not config or config.url.match(".html") then return config
      return httpHistory.add config
    response: (response) ->
      httpHistory.complete response
    responseError: (rejection) ->
      $q.reject httpHistory.complete rejection
