lib.registerState "manager.profiles.profile.notifications.notification",
  url: "/:notificationID"
  templateUrl: "states/manager/profiles/profile/notifications/notification/notification.state.html"

  controller: ($http, $scope, $stateParams, flash) ->
    $scope.blockLoading = true

    interval = setInterval ->
      if !$scope.$parent || !$scope.$parent.notifications then return

      notification = _.find $scope.$parent.notifications, (n) -> n.id is parseInt($stateParams.notificationID)

      $scope.notification = notification
      $scope.resends = _.filter $scope.$parent.notifications, { originalNotificationID: notification.id }

      $scope.reSend = (notification) ->
        $scope.sending = true
        $http.post("/api/profiles/#{$stateParams.profileID}/notifications/#{notification.id}/resend").then ->
          flash "Notification Re-Sent!"
          $scope.sending = false
          $scope.resends.unshift { created: new Date().toISOString() }

      clearInterval(interval)
      $scope.blockLoading = false
    , 250
