angular.module("dn").directive "qbText", ->
  templateUrl: "directives/builder/text/qb-text.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbText"
    mode: "@"
  controller: ($scope) ->
    $scope.inputTypeOptions = window.lib.builderTextInputOptions

    # Called immediately to format the old data into the new
    $scope.question.metadata.inputType = window.lib.builderTextInputFormatter($scope.question.metadata) || 'default'

    # Remove the old metadata until we update the DB
    window.lib.removeOldMetadata($scope.question.metadata)
