lib.registerState "manager.settings.general.healthLog",
  url: "/health-log"
  templateUrl: "states/manager/settings/general/health-log/health-log.state.html"
  controller: ($scope, group, organization, flash) ->
    $scope.group = group
    $scope.isOrganization = group.id is organization.id

    $scope.healthLogTab = 'locations'
    $scope.healthLogTabs = [
      {label: 'Locations', value: 'locations'}
      {label: 'Dispositions', value: 'dispositions'}
      {label: 'Triage', value: 'triage', permissions: { settings_health_log: 'edit' }}
    ]

    $scope.locations = group.properties.healthLogLocations

    $scope.$watch "locations", ->
      $scope.group.properties.healthLogLocations.sort()
    , true

    # initialize custom dispositions for the group
    stockDispositions = [
      "Admitted"
      "Ambulance"
      "Discharged"
      "Emergency Department"
      "Home"
      "Observed"
      "Urgent Care"
    ]
    $scope.group.properties.customDispositions ?= stockDispositions

    whichProperty =
      disposition: "customDispositions"
      location: "healthLogLocations"

    $scope.add = (item, type) ->
      if _.includes $scope.group.properties[whichProperty[type]], item
        return sameNameSwal(item, type)
      $scope.group.properties[whichProperty[type]].push item
      $scope.group.properties[whichProperty[type]].sort()
      $scope.group.save(['properties']).then ->
        flash "'#{item}' Added"
        $scope.showAdd = false
        $scope.showAddDisp = false
        $scope.newLocation = null
        $scope.newDisposition = null

    $scope.remove = (item, type) ->
      $scope.group.properties[whichProperty[type]] = _.without $scope.group.properties[whichProperty[type]], item
      $scope.group.save(['properties']).then ->
        flash "'#{item}' Removed"

    sameNameSwal = (item, type) ->
      window.swal
        type: "warning"
        title: "Item Already Exists"
        text: "'#{item}' already exists in your custom #{type}s."
