lib.registerState "patient.profile.questionnaire",
  url: "/questionnaire?review"
  templateUrl: "states/patient/profile/questionnaire/questionnaire.state.html"
  resolve:
    healthProfile: (organization) ->
      alias = organization.properties.portals?.patient?.sections?.questionnaire?.alias
      altName = organization.properties.branding?.text?.healthProfileAlternateName
      alias or altName or "Health Profile"
    title: ($rootScope, profile, organization, healthProfile) ->
      $rootScope._title = "#{profile.givenName} #{profile.familyName} / #{healthProfile}"
    states: (Constants) ->
      Constants.states().then (states) -> states
    countries: (Constants) ->
      Constants.countries().then (countries) -> countries
    registrations: ($http, profile) ->
      takerPerfFlag = window.lib.featureFlagClient.isEnabled('takerPerformance')
      url = "/api/profiles/#{profile.id}/registrations"

      if takerPerfFlag
        url += '?area=taker'

      $http.get(url).then (result) ->
        result.data
    users: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/users").then (result) ->
        _.reject result.data, (u) -> u.deactivated or u.profileUser.deactivated or u.profileUser.campgramsOnly
    immsRegEnabled: ($http, profile) ->
      $http.get("/api/imms-reg/profiles/#{profile.id}/eligibility")
        .then (result) ->
          profile.immsRegEnabled = result.data.eligible
          profile.eligibilityError = false
        .catch () ->
          profile.immsRegEnabled = true
          profile.eligibilityError = true

  controller: ($http, $interval, $scope, $state, $timeout, Event, HealthForm, WhatDivision, campgramsOnly, healthProfile, organization, profile, registrations, session, users) ->
    ## Redirect to the patient landing page if we're hiding the questionnaire
    if organization?.properties?.portals?.patient?.sections?.questionnaire?.hide
      return $state.go 'patient.profile'

    return $state.go 'patient.profile.funkNotes' if campgramsOnly

    # Init
    if organization.properties.languages?.length
      languages = organization.properties.languages
      languages.push 'English' unless 'English' in languages

      $scope.languageOptions = _(languages)
        .map((value) -> value)
        .push('English')
        .sort()
        .map (lang) ->
          label: lang
          value: lang
        .value()

    $scope.profile.selectedLang = 'English'

    org = $scope.organization
    $scope.division = WhatDivision
    $scope.healthProfile = healthProfile or "Health Profile"
    $(".print-wordmark").addClass "smaller"

    # Are we in review mode?
    $scope.reviewMode = $state.params.review

    # Handle missing info first
    if ($scope.profile.infoMissing or $scope.profile.photoMissing) and !session.skipDemographics
      if !$scope.reviewMode then return $state.go "patient.profile.demographics"

    # Last saved date formatting
    $scope.lastSaved = (date) -> moment(new Date(date)).fromNow()

    # Don't show old reg modal if RegEnhancements is true
    if !window.lib.featureFlagClient.isEnabled('RegEnhancements')
      # Determine if just transitioned from registering for a session
      if $state.previous.name is "patient.profile.registration.register" and registrations.length and _.some(registrations, (r) -> moment(new Date(r.created)).isAfter(moment().subtract(5, 'minutes')))
        if !org.properties.features || !org.properties.features.disableSocialPopup
          $scope.modalShown = true
          shareText = "#{$scope.profile.givenName} #{$scope.profile.familyName} signed up for #{division.place.singular} at #{org.name} using #{division.name}!"
          shareURL = "https://app.#{division.domain}/register/#{org.shortName}"
          $scope.twitterText = "?text=#{shareText}&via=#{division.lcName}&url=#{shareURL}&hashtags=#{division.lcName}"

    $scope.shareFB = ->
      FB.ui
        method: 'feed'
        name: "#{org.name} - #{division.name}"
        description: shareText
        picture: if org.properties.branding?.wordmark then "#{window.lib.brandingConfig.values.wordmarks.customUrl}/#{org.id}.png" else "#{window.lib.brandingConfig.values.logos.stockUrl}/#{division.lcName}.png"
        link: shareURL

    $scope.closeModal = ->
      $scope.modalShown = false

    registrations = _.filter registrations, (r) -> r.group.phase isnt "past" and !r.deactivated

    # Filter registrations and disable if none
    if not registrations?.length
      $scope.disabled = true
      $scope.noRegistrations = true
    # Grab waitlisted regs for display
    # it was requested that it not appear unless a camper has no non-waitlist regs, hence the allWaitlists
    $scope.waitlistedRegs = _.filter registrations, {'waitlisted': true}
    $scope.allWaitlists = _.every registrations, {'waitlisted': true}

    $scope.profile.registrations = registrations

    # Assign health form
    $scope.healthForm = HealthForm.get $scope.profile.id, $scope.profile

    # Function to check age restrictions
    checkAge = (question, profile) ->
      overAge = moment(profile.dob.toString()).add(+question.metadata.ageRestrict?.age, "years").isBefore(moment())

      if question.metadata?.ageRestrict?.olderThan
        return false if !overAge
      else if overAge
        return false
      true

    # Step Navigation

    q = $scope.profile.newQuestionnaire
    _.remove q.questions, (question) ->
      return true if question.metadata.sexRestrict && $scope.profile.sex != question.metadata.sexRestrict
      return true if question.metadata.ageRestrict && $scope.profile.dob && !checkAge(question, $scope.profile)

    $scope.max = if q.questions then q.questions.length else 0
    $scope.min = 1

    $scope.noNext = ->
      currentStep = parseInt($state.params.step)
      $scope.max is 0 or currentStep is $scope.max

    $scope.noPrevious = ->
      currentStep = parseInt($state.params.step)
      currentStep is $scope.min

    transition = ->
      $scope.healthForm.saveMessage = true
      $timeout ->
        $scope.healthForm.saveMessage = false
      , 2000

    $scope.transitionState = (direction) ->
      evaluateDirection =
        "next": +1
        "previous": -1

      currentStep = parseInt($state.params.step)
      do transition

      $state.go "^.step", { step: +currentStep + evaluateDirection[direction] }

    # Compile contacts
    $scope.contacts = _.map registrations, (r) ->
      return null if r.group.phase is "past"
      return {
        name: r.group.compiledProperties.contact_name
        phone: r.group.compiledProperties.contact_phone
        email: r.group.compiledProperties.contact_email
      }

    $scope.contacts = _.compact $scope.contacts
    $scope.contacts = _.uniqBy $scope.contacts, (c) -> c.name

    # Progress calculation
    $scope.notified = false

    progressCalc = ->
      questions = $scope.profile.newQuestionnaire.questions
      complete = (_.filter questions, (q) -> q.complete).length
      progress = Math.floor (complete / questions.length) * 100
      $scope.profile.completeness = progress
      if ($scope.progress) and progress isnt $scope.progress
        complete = (progress is 100)
        if complete isnt $scope.profile.newQuestionnaire.complete
          if progress is 100
            $scope.profile.newQuestionnaire.complete = true
          else
            $scope.profile.newQuestionnaire.complete = false
          #$scope.healthForm.saveProfile $scope.profile.id
      $scope.progress = progress

    do progressCalc

    updateProgress = _.debounce progressCalc, 200
    $interval progressCalc, 200

    # Lockout check
    firstCheck = true
    checkLockout = ->
      $scope.profile.lockedOut = (->
        if $scope.nextLockout is "Invalid date" then return false
        if $scope.profile.properties.extensionDate then return false
        if $scope.reviewMode then return false
        if !firstCheck and !$scope.profile.lockedOut then return false
        firstCheck = false
        if not $scope.nextLockout then return true
        $scope.progress is 100 and moment(new Date($scope.nextLockoutRaw)).unix() < moment().unix()
      )()

    checkLockout()
    $scope.lastDayToComplete = moment(new Date($scope.nextLockout)).subtract(1, 'days').format("MMMM DD, YYYY")

    $scope.groups = _(registrations).filter((r) -> !r.deactivated).map("group").value()

    # Interval checking
    notifyComplete = (lockoutText) ->
      body =
        healthProfile: healthProfile
        org: org.name
        disableComplete: org.properties?.notifications?.disableComplete
        lockoutText: lockoutText
        division: division.name
        to: _.map users, "email"

      nextGroups = _($scope.groups).filter((g) ->
          return g.compiledProperties?.emailText?.complete && moment().isBefore(new Date(g.properties.start))
        ).sortBy('properties.start').value()

      if nextGroups?[0]?.compiledProperties?.emailText?.complete
        body.customText = nextGroups[0].compiledProperties.emailText.complete
      else if org.properties?.emailText?.complete
        body.customText = org.properties.emailText.complete

      $http.post("/api/profiles/#{$scope.profile.id}/complete-notification", body).then (result) ->

        # Fire this request to run generateQuestionnaire and completeness one last time
        $http.get("/api/profiles/#{$scope.profile.id}?regenerate")

        window.swal
          title: "#{healthProfile} Complete"
          text: "
            The #{healthProfile.toLowerCase()} for #{$scope.profile.givenName} #{$scope.profile.familyName}
            is now complete, and has been submitted to #{org.name}!
            \n
            Please note, #{org.name} may contact you if they have any questions about the information
            you provided.
            \n
            #{lockoutText}
          "
          type: "success"
          confirmButtonColor: "#5CA100"
          allowOutsideClick: true
          confirmButtonText: "OK"

    notifyLockedOut = ->
      window.swal
        title: "Locked Out"
        text: """
          Since #{$scope.profile.givenName} #{$scope.profile.familyName}'s #{healthProfile.toLowerCase()}
          is 100% complete, and it is past the nearest lockout date, you will be unable to make changes.
          You can issue an unlock request below if you must make changes.
        """
        type: "info"
        showCancelButton: true
        cancelButtonText: "I Don't Need to Make Changes"
        confirmButtonColor: "#5CA100"
        confirmButtonText: "Request Extension"
        closeOnCancel: true
        allowOutsideClick: true
        closeOnConfirm: false
      , (unlock) ->
        return unless unlock
        $scope.profile.properties.extensionRequested = true
        new Event(
          profileID: $scope.profile.id
          type: "healthForm.extensionRequested"
        ).save().then ->
          window.swal "Unlock Request Sent", "You will receive an email when this account has been unlocked", "success"

    $interval ->
      if $scope.reviewMode then return
      checkLockout()
      if $scope.progress isnt 100 or $scope.notified then return
      lockoutText = ""
      if $scope.nextLockout isnt "Invalid date"
        lockoutText = "
          You may return to your #{$scope.division.name} account before #{$scope.nextLockout}
          to update any health information that may change for #{$scope.profile.givenName}.
        "
      if not $scope.profile.lockedOut then notifyComplete(lockoutText)
      else do notifyLockedOut
      $scope.notified = true
    , 2000

    # Page Status Functions
    $scope.small = -> if $scope.viewPort.width > 768 then "split" else ""
    $scope.blocked = ->
      return false if $scope.reviewMode
      not $scope.profile.dob or (not $scope.profile.sex and not org.properties.sexOptional)
    $scope.stepState = -> $state.current.name.indexOf("step") > -1
    $scope.continue = -> $state.go ".step", { step: 1 }

    # Profile updates / Saving
    save = _.debounce ->
      # Reset reviews via API if we're not in reviewMode
      saveProfile = _.pick $scope.profile, ["id", "has", "properties", "reviewFlags", "completeness"]
      if !$scope.reviewMode and $scope.profile.reviewFlags?.length
        saveProfile.reviewFlags = _.uniq(saveProfile.reviewFlags)
        saveProfile.resetReviews = true
      $http.put("/api/profiles/#{$scope.profile.id}", saveProfile)
      $scope.healthForm.saveMessage = true
      $timeout ->
        $scope.healthForm.saveMessage = false
      , 2000
    , 500

    $scope.$watch "profile.properties", updateProgress, true

    # Save when 'completeness' changes
    $scope.$watch "profile.completeness", (completeness, stale) ->
      do updateProgress
      do save
    , true

    # Save when 'has' changes
    $scope.$watch "profile.has", (has, stale) ->
      if has isnt stale
        do updateProgress
        do save
    , true

    $scope.$watch "profile.properties", (properties, stale) ->
      if properties isnt stale then do save
    , true

    # Same story for reviewFlags
    $scope.$watch "profile.reviewFlags", (flags, stale) ->
      if flags isnt stale then do save
    , true

    $scope.continue() if org.properties.bypassInstructions && !$state.params.step
