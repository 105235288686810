lib.registerState("manager.profiles.profile.registrations.protectionPlan", {
  url: '/protection-plan/:registrationID',
  templateUrl: 'states/manager/profiles/profile/registrations/protection-plan/protection-plan.state.html',
  resolve: {
    title: function($rootScope) {
      $rootScope._title = "Protection Plan";
    },
    registration: function($http, $stateParams, Registration) {
      return $http.get("/api/profiles/" + $stateParams.profileID + "/registrations/" + $stateParams.registrationID)
        .then(
          // GET registration success
          function(success) {
            return new Registration(success.data);
          },
          // GET registration error
          function(err) {
            window.swal({
              type: "error",
              title: "Error",
              text: "There was an error retrieving registration data:\n\n" + JSON.stringify(err),
              confirmButtonText: "Try Again",
              showCancelButton: true,
              cancelButtonText: "Go Back",
              allowOutsideClick: false,
              allowEscapeKey: false
            }, function(confirmed) {
              if (confirmed) {
                return $state.reload();
              } else {
                return $state.go("^", { profileID: $stateParams.profileID, registrationsTab: "protection-plans" });
              }
            });
          });
    }
  },
  controller: function ($http, $scope, $state, $stateParams, profile, registration, flash) {
    $scope.profile = profile;
    $scope.associatedPlans = [];
    $scope.confirmDelete = null;
    $scope.deleting = false;


    // Set selectedPlan
    $scope.selectedPlan = profile.protectionPlans.find(plan => {
      return parseInt(plan.registrationID) === parseInt($stateParams.registrationID);
    });

    // Fill associatedPlans with any plans that have the same crebitID as selectedPlan
    $scope.associatedPlans = profile.protectionPlans.filter(plan => {
      if (plan.crebitID === $scope.selectedPlan.crebitID && plan.registrationID !== parseInt($stateParams.registrationID)) {
        return plan;
      }
    })

    // Get the parents of selectedPlan via a self-executing function
    // This approach is taken from registration.state.coffee
    $scope.parents = (function() {

      // Empty array to hold parent objects
      var parents = [];

      // Set up a recursive function to grab all the parents
      function addParent(parent) {
        parents.push(parent);
        if (parent.parent) {
          return addParent(parent.parent);
        }
      };

      // Process parents of selectedPlan
      if (registration.group.parent) {
        addParent(registration.group.parent);
      }

      // Sort by the length of the parents array so we put the group names
      // in the appropriate order
      parents = _.sortBy(parents, function(p) {
        return p.parents.length;
      });

      // Filter out the top level org, leaving only the constituent groups
      return _.reject(parents, function(p) {
        return p.classification === "organization";
      });
    })();

    /*
    There's more than one way to get to this page
    If someone was redirected here by the org-wide prot plan page, they'd expect `Back` to take them back there
    Otherwise just go to the profile registrations tab
    */
    $scope.goBack = function() {
      if ($state && $state.previous && $state.previous.name === 'manager.travelProtection') {
        return $state.go('manager.travelProtection');
      } else {
        return $state.go('^', {registrationsTab: 'protection-plans'});
      }
    }

    $scope.deletePlan = function() {

      // A function to return formatted strings for swal depending on the number
      // of plans deleted
      function setSuccessStrings() {
        switch($scope.associatedPlans.length) {
          case 0:
            var info = {
              title: "Protection Plan Deleted!",
              text: "Successfully deleted protection plan " + $scope.selectedPlan.registrationID + " and it's associated crebit."
            }
            return info;
          case 1:
            var info = {
              title: "Protection Plans Deleted!",
              text: "Successfully deleted protection plans " + $scope.selectedPlan.registrationID + " and " + $scope.associatedPlans[0].registrationID + ", and their associated crebit."
            }
            return info;
          default:
            var associatedRegistrationIDs = $scope.associatedPlans.map(function(plan) {
              return plan.registrationID;
            });
            var info = {
              title: "Protection Plans Deleted!",
              text: "Successfully deleted the crebit and " + $scope.associatedPlans.length + " protection plans associated with the following registrations:\n"
            }
            info.text += $scope.associatedPlans.map(p => {return p.registrationID}).join(", ");
            return info;
        }
      }

      // You may notice that the route uses selectedPlan.id instead of registrationID, like we use in almost
      // every other case in this file. I thought that the actual plan ID makes more sense to use in the route
      // (following our other REST stuff) while the registrationID (also known as Confirmation Number) is more
      // recognizable to the people who will actually use it.
      $http.delete("/api/profiles/" + $stateParams.profileID + "/protection-plans/" + $scope.selectedPlan.id, {data: {crebitID: $scope.selectedPlan.crebitID}})
        .then(
          // DELETE plan success
          function(success) {
            var msg = setSuccessStrings();
            window.swal({
              type: "success",
              title: msg.title,
              text: msg.text,
              confirmButtonText: "Okay",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }, function(confirmed) {
              if (confirmed) {
                return $state.go("^", { profileID: $stateParams.profileID, registrationsTab: "protection-plans" }, {reload: true});
              }
            });
          },
          // DELETE plan error
          function(err) {
            window.swal({
              type: "error",
              title: "Error",
              text: "There was an error deleting the protection plan(s):\n" + JSON.stringify(err),
              confirmButtonText: "Reload",
              showCancelButton: true,
              cancelButtonText: "Go Back",
              allowOutsideClick: false,
              allowEscapeKey: false
            }, function(confirmed) {
              if (confirmed) {
                return $state.reload();
              } else {
                return $state.go("^", { profileID: $stateParams.profileID, registrationsTab: "protection-plans" });
              }
            });
          }
        )
    }
  } // end controller
});
