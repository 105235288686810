lib.registerState "manager.setup.update",
  url: "/update"
  templateUrl: "states/manager/setup/update/update.state.html"
  controller: ($scope, $http, $state, Season, flash, seasons) ->

    $scope.org.properties.location ?= {}
    # Wipe out location objects from the old `Contact Information` settings tab
    if !$scope.org.properties.location.hasOwnProperty('mailing') and
       !$scope.org.properties.location.hasOwnProperty('billing')
      $scope.org.properties.location = {}

    $scope.interestInReg = _($scope.seasons).reject('archived').filter((s) -> s.properties?.interestIn?.registration).value().length
    newOrg = true unless _.reject($scope.seasons, 'archived').length

    $scope.referrerChoices = [
      {label: 'Conference', value: 'conference'}
      {label: 'Facebook', value: 'facebook'}
      {label: 'Google', value: 'google'}
      {label: 'LinkedIn', value: 'linkedin'}
      {label: 'Letter From Us', value: 'letter'}
      {label: 'Promotional Email', value: 'email'}
      {label: 'Reference', value: 'referral'}
      {label: 'Twitter', value: 'twitter'}
      {label: 'Other', value: 'other'}
    ]

    $scope.submit = (org) ->
      org.properties.lastUpdated = new Date().toISOString()
      org.properties.address.mailing = org.properties.location.mailing.formatted
      org.properties.address.billing = org.properties.location.billing.formatted
      org.save(["properties"]).then -> flash "Organization updated"
      if newOrg
        swal {
          title: 'Please name your new submission'
          type: 'input'
          showCancelButton: true
          closeOnConfirm: false
          showLoaderOnConfirm: true
          inputPlaceholder: 'Submission Name'
          confirmButtonText: 'Start Submission'
        }, (input) ->
          name = _.trim(input)
          if input == false
            return false
          if name == ''
            swal.showInputError 'You need to name the submission!'
            input = ''
            return false
          season = {
            orgID: $scope.org.id
            name: name
            updates: []
          }
          new Season(season).save().then(
            (result) ->
              $state.go("manager.setup.season", {index: result.id}, {reload: true})
              swal.close()
            (error) ->
              swal "Error!", "There was an error creating #{name}", "error"
          )
          return
      else
        if ($scope.selectedSeason.archived == null && ($scope.seasonStatus($scope.selectedSeason) != 'confirmed'))
          $state.go "manager.setup.season", {index: $scope.selectedSeason.id}, {reload: true}
        else
          $state.go "manager.setup"

      return

    requiredFieldsPresent = (org) ->
      org.properties.address ?= {}
      org.properties.contacts ?= {}
      org.properties.contacts.sales ?= {}
      org.properties.contacts.medical ?= {}
      org.properties.contacts.general ?= {}
      org.properties.usage ?= {}
      _.every [
        org.name
        org.properties.location.billing
        org.properties.location.mailing
        org.properties.contacts.general.name
        org.properties.contacts.general.phone
        org.properties.contacts.general.email
        org.properties.contacts.sales.name
        org.properties.contacts.sales.phone
        org.properties.contacts.sales.email
        org.properties.contacts.medical.name
        org.properties.contacts.medical.phone
        org.properties.contacts.medical.email
        org.properties.usage.dayPrograms
        org.properties.usage.residentPrograms
        org.properties.usage.familyPrograms
        org.properties.usage.multipleLocations
        org.properties.usage.yearRound
        org.properties.usage.healthConcern
        org.properties.nonProfit
      ], (property) -> property?

    $scope.incomplete = (org) ->
      return true unless requiredFieldsPresent org
      org.properties.usage ?= {}
      if org.properties.usage.healthConcern and !org.properties.usage.healthConcernDetails
        return true
      false
