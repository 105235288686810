angular.module('dn').directive('interval', function () {
  return {
    templateUrl: 'directives/interval/interval.directive.html',
    restrict: 'E',
    scope: {
      dates: '=',
      fields: '=',
      timezone: '=',
      isNew: '=',
      enableEndDate: '='
    },
    controller($scope) {

      // fields is required to update data outside
      if (!$scope.fields) return;

      /// Helper Functions

      /**
       * helper function to make sure we are using the appropiate timezone when using moment
       * @param {string} timestamp
       * @param {boolean} [utcOverride] - Override the timezone passed to the directive with UTC
       * @returns {function} moment function set to the timezone passed or utc
       */
      function momentTZ(timestamp, utcOverride) {
        let timezone;
        if ($scope.timezone && $scope.timezone.length !== 0 && !utcOverride) {
          timezone = $scope.timezone;
        }

        if (utcOverride) timezone = 'UTC';

        // if timestamp is not defined it will use the local computer time converted to the timezone
        return timezone ? moment(timestamp).tz(timezone) : moment(timestamp).utc();
      }

      /**
       * Validates the timestamp to be able to populate the inputs with a date.
       * Passes true to isValidDateTime to prevent unnecessary error logging.
       * @param {string} timestamp - the timestamp passed
       * @returns {boolean} true if it is a timestamp
       */
      function validateTimestamp(timestamp) {
        return window.dnim.dateTime.isValidDateTime(timestamp, true);
      }

      /// Defaults
      $scope.today = momentTZ().format('YYYY-MM-DD');
      $scope.fiveYearsFromToday = momentTZ().add(5, 'year').format('YYYY-MM-DD');
      $scope.yesNo = [
        { label: 'No', value: true },
        { label: 'Yes', value: false },
      ];
      // We lose the reference of startDate and endDate without an object.
      // Material input sucks
      $scope.internalDates = {
        startDate: null,
        endDate: null,
      };
      $scope.frequency = ['Daily', 'Weekly', 'Monthly'];
      $scope.selectedFrequency = 'Daily';
      $scope.selectedDays = [
        {
          label: 'Sunday',
          value: false,
        },
        {
          label: 'Monday',
          value: false,
        },
        {
          label: 'Tuesday',
          value: false,
        },
        {
          label: 'Wednesday',
          value: false,
        },
        {
          label: 'Thursday',
          value: false,
        },
        {
          label: 'Friday',
          value: false,
        },
        {
          label: 'Saturday',
          value: false,
        },
      ];

      /// Input Functions

      /**
       * When selected frequency changes it resets the values for other properties
       */
      $scope.frequencyChange = function () {
        if ($scope.selectedFrequency === 'Weekly') {
          // Automatically updates the $scope.fields since the change function will get triggered
          $scope.selectedDays[momentTZ($scope.dates.startDate).day()].value = true;
          $scope.updateDayOfWeek();
          resetDayOfMonth();
        } else if ($scope.selectedFrequency === 'Monthly') {
          updateDayOfMonth();
          resetDayOfWeek();
        } else {
          // Daily
          resetDayOfMonth();
          resetDayOfWeek();
        }
      };

      /**
       * format the startDate for the inputs to consume
       */
      function formatStart() {
        if (validateTimestamp($scope.dates.startDate)) {
          $scope.internalDates.startDate = momentTZ($scope.dates.startDate).format('YYYY-MM-DD');
        } else {
          $scope.internalDates.startDate = $scope.today;
        }
      }

      /**
       * Updates the start date timestamp based on the internal start date and runs the function
       * that updates the day of month since they are related
       */
      $scope.updateStart = function () {
        const hour = moment().tz($scope.timezone).hours($scope.fields.hour[0]).format('HH');
        const minute = moment().tz($scope.timezone).minutes($scope.fields.minute[0]).format('mm');
        const protoStamp = `${$scope.internalDates.startDate} ${hour}:${minute}`;

        $scope.dates.startDate = moment.tz(protoStamp, $scope.timezone).utc().format();
        updateDayOfMonth();
      };

      /**
       * format the endDate for the inputs to consume
       */
      function formatEnd() {
        // If no end date is given e.g. a new reminder, we leave the input empty
        if (validateTimestamp($scope.dates.endDate)) {
          $scope.internalDates.endDate = momentTZ($scope.dates.endDate).format('YYYY-MM-DD');
        } else {
          // If its not a new reminder and endDate is null we assume the person disabled it
          $scope.enableEndDate = $scope.isNew;
          $scope.internalDates.endDate = null;
        }
      }

      /**
       * Updates the end date timestamp based on the internal end date.
       * (Insert Linkin Park song after timezone hell)
       */
      $scope.updateEnd = function () {
        const hour = moment().tz($scope.timezone).hours($scope.fields.hour[0]).format('HH');
        const minute = moment().tz($scope.timezone).minutes($scope.fields.minute[0]).format('mm');
        const protoStamp = `${$scope.internalDates.endDate} ${hour}:${minute}`;

        $scope.dates.endDate = moment.tz(protoStamp, $scope.timezone).utc().format();
        // Clear the end date if it's disabled so we don't have "Invalid Date"
        $scope.clearEndDate();
      };

      /**
       * Clear the endDate if user does not want an endDate
       */
      $scope.clearEndDate = () => {
        if ($scope.enableEndDate === false) {
          $scope.dates.endDate = null;
          $scope.internalDates.endDate = null;
        }
      };

      /**
       * Sets the time for the inputs to consume
       */
      function formatTime() {
        let hour, minute;

        if ($scope.fields.hour
            && $scope.fields.minute
            && $scope.fields.hour.length === 1
            && $scope.fields.minute.length === 1
        ) {
          hour = momentTZ().hours($scope.fields.hour[0]).format('HH');
          minute = momentTZ().minutes($scope.fields.minute[0]).format('mm');
        } else {
          // Moment is dumb. We got to pass moment again to hours and minutes
          hour = momentTZ().hours(momentTZ().format('HH')).format('HH');
          minute = momentTZ().minutes(momentTZ().format('mm')).format('mm');
          $scope.fields.hour = [Number(hour)];
          $scope.fields.minute = [Number(minute)];
        }

        $scope.time = `${hour}:${minute}`;
      }

      /**
       * Formats time for the field to take and updates start/end dates timestamps since they depend on time
       */
      $scope.updateTime = function () {
        if ($scope.time.length !== 0) {
          const [hour, minute] = $scope.time.split(':');

          $scope.fields.hour = [Number(hour)];
          $scope.fields.minute = [Number(minute)];

          $scope.updateStart();
          $scope.updateEnd();
        }
      };

      /**
       * Formats the day of the week for the inputs to consume
       */
      function formatDayOfWeek() {
        if ($scope.fields.dayOfWeek.length < 8) {
          $scope.selectedFrequency = 'Weekly';

          $scope.fields.dayOfWeek.forEach(day => {
            // Sunday in CRON can be 0 or 7
            if (day === 7) $scope.selectedDays[0].value = true;
            $scope.selectedDays[day].value = true;
          });
        }
      }

      /**
       * Formats dayOfWeek for the field to take
       */
      $scope.updateDayOfWeek = function () {
        if ($scope.selectedDays) {
          // Reset the array
          $scope.fields.dayOfWeek = [];

          $scope.selectedDays.forEach((day, i) => {
            if (day.value === true) $scope.fields.dayOfWeek.push(i);
          });

          // We need to push the digit 7 in order for cron-parser to star the cron string
          if ($scope.fields.dayOfWeek.length === 7) $scope.fields.dayOfWeek.push(7);
        }
      };

      /**
       * Resets dayOfWeek in fields
       */
      function resetDayOfWeek() {
        $scope.fields.dayOfWeek = Array.from(Array(8).keys());
      }

      /**
       * format the dayOfMonth for the inputs to consume
       */
      function formatDayOfMonth() {
        if ($scope.fields.dayOfMonth.length === 1) {
          $scope.selectedFrequency = 'Monthly';
          $scope.dates.startDate = momentTZ($scope.dates.startDate)
            .date($scope.fields.dayOfMonth)
            .format('YYYY-MM-DD');
        }
      }

      /**
       * formats the dayOfMonth for the fields to take
       */
      function updateDayOfMonth() {
        if ($scope.selectedFrequency === 'Monthly') {
          $scope.fields.dayOfMonth = [momentTZ($scope.dates.startDate).date()];
        }
      }

      /**
       * Resets dayOfMonth in fields
       */
      function resetDayOfMonth() {
        $scope.fields.dayOfMonth = Array.from(Array(32).keys());
        $scope.fields.dayOfMonth.shift();
      }

      /**
       * Reset the month in case is not available
       */
      function resetMonth() {
        $scope.fields.month = Array.from(Array(13).keys());
        $scope.fields.month.shift();
      }

      /// Defaulting

      // We default these 3 very nicely if there is no data
      formatStart();
      formatEnd();
      formatTime();

      // Default if it is an existing reminder
      if (!$scope.isNew) {
        formatDayOfWeek();
        formatDayOfMonth();
      } else {
        // If it is empty we need to construct the object
        resetDayOfMonth();
        resetDayOfWeek();
        // We reset month just for cron-parser
        resetMonth();
        // Make sure the timestamp is populated
        $scope.updateStart();
      }
    },
  };
});
