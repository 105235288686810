angular.module("dn").directive "qbQuestion", ->
  templateUrl: "directives/builder/question/qb-question.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbQuestion"
    builder: "="
    group: "="
    clipboard: "="
  controller: ($scope, $stateParams, $state, $timeout, $http) ->

    groupID = parseInt $stateParams.groupID
    if $scope.clipboard then $scope.question.multiple = 1

    originalSubgroups = _.cloneDeep $scope.question.subgroups

    if $scope.question.type is "step" or $scope.question.type is "form"
      $scope.question.overwriteChildren = false
    else
      $scope.question.overwriteChildren = true


    $scope.question.toggleOverwrite = ->
      if !$scope.question.overwriteChildren
        $scope.question.resetSubgroups = true
      else
        $scope.question.resetSubgroups = false

    # Emit update signal to recompile form
    update = -> $scope.$emit "stepUpdate"

    tellMerged = -> $scope.$emit "merged"

    # Icon mapping for questions in view mode
    $scope.icons =
      allergies:          "fas fa-fw fa-stethoscope"
      dimensions:         "fas fa-fw fa-cube"
      emergencyContacts:  "fas fa-fw fa-phone"
      encrypted:          "fas fa-fw fa-lock"
      insurances:         "fas fa-fw fa-h-square"
      immunizations:      "fas fa-fw fa-calendar-alt"
      instructions:       "fas fa-fw fa-info-circle"
      medications:        "fas fa-fw fa-medkit"
      primaryProviders:   "fas fa-fw fa-user-md"
      text:               "far fa-fw fa-file-alt"
      select:             "fas fa-fw fa-list"
      boolean:            "far fa-fw fa-dot-circle"
      authorization:      "fas fa-fw fa-pencil-alt"
      "multi-auth":       "far fa-fw fa-edit"
      upload:             "far fa-fw fa-image"
      "Male":             "fas fa-fw fa-male"
      "Female":           "fas fa-fw fa-female"

    $scope.newQuestionTypes = [
      { value: "allergies",         label: "Allergies" }
      { value: "authorization",     label: "Authorization" }
      { value: "multi-auth",        label: "Expanded Authorization" }
      { value: "dimensions",        label: "Dimensions" }
      { value: "download",          label: "Download" }
      { value: "emergencyContacts", label: "Emergency Contacts" }
      { value: "encrypted",         label: "Encrypted Text" }
      { value: "form",              label: "Form" }
      { value: "immunizations",     label: "Immunizations" }
      { value: "instructions",      label: "Instructions" }
      { value: "insurances",        label: "Insurances" }
      { value: "medications",       label: "Medications" }
      { value: "otc",               label: "OTC Medications" }
      { value: "primaryProviders",  label: "Primary Providers" }
      { value: "select",            label: "Select" }
      { value: "text",              label: "Text" }
      { value: "boolean",           label: "Yes/No" }
      { value: "boolean.details",   label: "Yes/No + Details" }
      { value: "upload",            label: "Upload" }
    ]

    # originalQuestion used for 'cancel' command
    $scope.question.translating = false

    $scope.originalQuestion = _.cloneDeep $scope.question
    $scope.parentQuestion = $scope.question.parentQuestion

    $scope.question.subgroups ?= []
    $scope.question.groupID ?= groupID

    $scope.group.removed ?= []
    $scope.group.reactivated ?= []

    # Figure out which groups to belong to if parent changes
    determineSubgroups = ->
      return unless $scope.parentQuestion

      if $scope.parentQuestion.overwriteChildren
        $scope.question.subgroups = _.cloneDeep $scope.parentQuestion.subgroups
        $scope.question.overwriteChildren = $scope.parentQuestion.overwriteChildren
        $scope.question.resetSubgroups = $scope.parentQuestion.resetSubgroups

      if $scope.parentQuestion.resetSubgroups
        $scope.question.subgroups = _.cloneDeep originalSubgroups
        $scope.question.overwriteChildren = $scope.parentQuestion.overwriteChildren
        $scope.question.resetSubgroups = $scope.parentQuestion.resetSubgroups

      $scope.originalQuestion = _.clone $scope.question

    determinerWatcher = (fresh, stale) ->
      return if _.isEqual(fresh, stale)
      return if !$scope.question.id and !$scope.question.subgroups?.length
      $scope.question.save = true
      determineSubgroups()

    $scope.$watch "parentQuestion.subgroups", determinerWatcher, true
    $scope.$watch "parentQuestion.resetSubgroups", determinerWatcher, true

    $scope.cancel = (form) ->
      _.assign $scope.question, $scope.originalQuestion
      if form
        form.edit = false
        form.heading = false

    # reset is called from this directive when cancelling
    # it's called from qb-heading when saving.....
    $scope.reset = (updateNeedSave) ->
      $scope.form = { followUp: false, question: false }
      $scope.newQuestion = { groupID: groupID, metadata: {}, subgroups: [], required: true }
      if updateNeedSave then $scope.$parent.updateNeedSave(true)
    $scope.reset()


    # Question Actions
    $scope.moveUp = ->
      index = $scope.question.index
      $scope.builder.swap index, parseInt(index) - 1, $scope.parentQuestion
      update()

    $scope.moveDown = ->
      index = $scope.question.index
      $scope.builder.swap index, parseInt(index) + 1, $scope.parentQuestion
      update()

    $scope.cut = (deactivated) ->
      if deactivated
        $scope.builder.cutDeactivated $scope.question
      else
        $scope.builder.cut $scope.question.index, $scope.parentQuestion
      update()

    $scope.copy = ->
      $scope.builder.copy $scope.question
      update()

    $scope.paste = ->
      # reactivate deactivated questions on save
      if $scope.builder.clipboard.deactivated && $scope.builder.clipboard.id
        $scope.group.reactivated.push $scope.builder.clipboard.id

      $scope.question.subQuestions ?= []
      if $scope.question.type is "step" or $scope.question.type is "form"
        $scope.builder.paste $scope.question.index + 1, $scope.question
      else
        $scope.builder.paste $scope.question.index + 1, $scope.question.parentQuestion
      update()

    $scope.increasePaste = ->
      $scope.question.multiple += 1

    $scope.decreasePaste = ->
      return unless $scope.question.multiple > 1
      $scope.question.multiple -= 1

    $scope.addQuestion =->
      return if not $scope.newQuestion.label or not $scope.newQuestion.type
      $scope.builder.addQuestion $scope.newQuestion, $scope.parentQuestion, $scope.question.index + 1
      $scope.reset()
      update()

    $scope.addFollowup = ->
      return if not $scope.newQuestion.label or not $scope.newQuestion.type
      $scope.builder.addQuestion $scope.newQuestion, $scope.question
      $scope.reset()
      update()

    removeChildSubgroups = ->
      parentMap = _.keyBy($scope.group.$selectableChildren(), 'value')
      $scope.question.subgroups = _.reject $scope.question.subgroups, (g) ->
        parents = _.clone parentMap[g].parents
        parents.pop()
        _.intersection($scope.question.subgroups, parents).length

    $scope.save = ->
      $scope.form.edit = false
      $scope.question.translating = false
      $scope.question.save = true
      if $scope.question.subgroups?.length > 1 then removeChildSubgroups()
      $scope.originalQuestion = _.cloneDeep $scope.question
      # Signal to the questionnaire state that we need to save the step.
      # Every other action in this directive that would need the same calls update() which eventually leads to the same scope var changing.
      $scope.$parent.updateNeedSave(true)

    removeSubQuestions = (questions) ->
      _.each questions, (q) ->
        if q.id
          $scope.group.removed.push q.id
        if q.subQuestions?.length
          removeSubQuestions(q.subQuestions)

    $scope.deleteQuestion = (deletePermanently) ->
      updateType = if deletePermanently then 'delete' else 'deactivate'
      swalConfig =
        title: "Really #{updateType.capitalize()} Question?"
        text: "Are you sure you want to #{updateType} this question?"
        type: "warning"
        confirmButtonColor: "#DD6B55"
        confirmButtonText: "#{updateType.capitalize()} Question"
        showCancelButton: true
        html: true

      if deletePermanently
        swalConfig.text += " This question will be deleted <b>permanently</b> and <b>can not be undone</b> "

      window.swal swalConfig, (really) ->
        return unless really
        $scope.$parent.updateNeedSave()
        if deletePermanently
          $http.delete("/api/organizations/#{$scope.question.groupID}/questions/#{$scope.question.id}").then(
            (result) ->
              swal "Question Deleted", "Question has been permanently deleted", "success"
              $state.reload()
            (err) ->
              error = err.data || "Unable To Delete Question"
              swal "Error", "#{error}", "error"
          )
        else
          removeSubQuestions([$scope.question])
          $scope.builder.removeQuestion $scope.question.index, $scope.parentQuestion
          $scope.reset()
          update()
          if not $scope.parentQuestion then return $state.go "^"


    # Merge two questions together
    $scope.mergeQuestions = ->
      groupID = $scope.group.id
      oldQuestion = $scope.builder.merge
      window.swal
        title: "Merge Questions?"
        text: """
          Merging "#{oldQuestion.label}" into "#{$scope.question.label}".
          This will link all old answers to the question
          into which you are merging.
        """
        type: "warning"
        showCancelButton: true
      , (merge) ->
        if not merge
          $scope.builder.clearMerge()
          return
        $scope.$parent.updateNeedSave()
        data =
          oldID: oldQuestion.id
          newID: $scope.question.id
        $http.put("/api/groups/#{groupID}/merge-answers", data)
        .success ->
          window.swal
            title: "Questions Merged"
            type: "success"
            timer: 1000
          oldQuestion.metadata ?= {}
          oldQuestion.metadata.mergedWith = $scope.question.id
          $scope.question.save = true
          oldQuestion.save = true
          # Connor commented this out on 10/12/2016 because merging was taking too long. I didn't notice anything wrong with not saving the form after a merge.
          # do tellMerged
          do $scope.builder.clearMerge
        .error ->
          window.swal
            title: "Failed to Merge"
            text: """
              Failed to merge these two questions.
              Please contact DocNetwork support for assistance.
            """
            type: "warning"
            timer: 2000


    $scope.selectForMerge = ->
      $scope.builder.merge = $scope.question


    $scope.$watch "newQuestion", (fresh, stale) ->
      if fresh.type is stale.type then return
      type = fresh.type.split "."
      $scope.newQuestion.type = type[0]
      if type[1] and type[1] is "details"
        $scope.addingDetails = true
        $scope.newQuestion.subQuestions ?= []
        $scope.newQuestion.subQuestions.push(
          type: "text"
          label: "Details"
          condition: "yes"
          required: true
        )
      else
        if stale.type is "boolean.details" then return
        $scope.addingDetails = false
        $scope.newQuestion.subQuestions = []
    , true

    $scope.question.metadata ?= {}
    $scope.question.metadata.translations ?= {}
