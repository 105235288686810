lib.registerState('manager.profiles.profile.account.lineItem', {
  url: '/:crebitID',
  params: { crebitID: null, balance: null },
  templateUrl: 'states/manager/profiles/profile/account/line-item/line-item.state.html',
  resolve: {
    title: ($rootScope) => {
      $rootScope._title = 'Account';
      return;
    },
  },
  controller: ($scope, $state, $stateParams, $filter, $http, Crebit, PayProcessor, flash, crebits, profile) => {
    function navigateToAccount () {
      $state.go('manager.profiles.profile.account');
    }

    function navigateToAndReloadAccount () {
      $state.go('manager.profiles.profile.account', {}, { reload: true });
    }

    function applyUpdatedCrebitDescription (updatedDescription){
      $scope.crebit.description = updatedDescription;
      $scope.saveUpdatedCrebit();
    }

    $scope.saveUpdatedCrebit = () => {
      return new Crebit({
        id: $scope.crebit.id,
        profileID: $scope.crebit.profileID,
        description: $scope.crebit.description
      }).save().then((saved) => {
        flash('Line item updated');
        $scope.crebit.description = saved.description;
      }).catch(() => {
        flash('Error updating description. Please reload the page.');
      });
    };
    /*
      Find the crebit we're supposed to be looking at from the object returned from the parent state
      Attach any parent, child, or sibling crebit, any crebit attributing to this one
    */

    /*
      The deep clone of crebits is necessary for this state to work. Without it, the watcher on
      the parent state will trigger an infinite digest loop, despite the fact that the lodash
      functions used below should not mutate the crebits array...
    */
    const clonedCrebits = _.cloneDeep(crebits);
    $scope.voidingCrebit = false;
    // Primarily used for TN type of processors where we can quickly verify the amount to be refunded
    // using the confirmation number. Less complexity makes me happy.
    const crebitDictionary = {};

    // Used to get a tally of all the crebits
    let totalCrebitAmount = 0;

    // Material input money input cannot use just a number or it won't update the variable.
    // Needs to be an object. (╯°□°)╯︵ ┻━┻
    $scope.refund = {
      amount: 0
    };

    clonedCrebits.forEach(c => {
      if (c.id === parseInt($stateParams.crebitID)) {
        /* Throw that thang on scope, grrrrrl */
        $scope.crebit = c;
      }

      totalCrebitAmount += c.amount;

      if (crebitDictionary[c.confirmation]) {
        crebitDictionary[c.confirmation].totalAmount += c.amount;
        crebitDictionary[c.confirmation].crebits.push(c);
      } else {
        crebitDictionary[c.confirmation] = {
          totalAmount: c.amount,
          crebits: [c]
        };
      }
    });

    const isNegativeCrebit = () => $scope.crebit.amount < 0;

    const isNotAchReturnFee = () => {
      return $scope.crebit.description !== '[ACH-RETURN] Fee';
    };

    const transactionalCrebitTypes = [
      { crebitType: '[PAYMENT]',           isTransactionalCrebit: () => true },
      { crebitType: '[EXTERNAL-PAYMENT]',  isTransactionalCrebit: () => true },
      { crebitType: '[REFUND]',            isTransactionalCrebit: () => true },
      { crebitType: '[EXTERNAL-REFUND]',   isTransactionalCrebit: () => true },
      { crebitType: '[TRANSFER]',          isTransactionalCrebit: () => true },
      { crebitType: '[COUPON]',            isTransactionalCrebit: () => true },
      { crebitType: '[CHARGEBACK]',        isTransactionalCrebit: () => true },
      { crebitType: '[MISC]',        isTransactionalCrebit: isNegativeCrebit },
      { crebitType: '[ACH-RETURN]', isTransactionalCrebit: isNotAchReturnFee },
    ];

    const isTransactionalCrebit = transactionalCrebitTypes.some(
      ({ crebitType, isTransactionalCrebit }) => {
        return $scope.crebit.description.startsWith(crebitType)
          && isTransactionalCrebit();
      }
    );

    const lineItemViewMFE = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');

    $scope.showLineItemViewMFE = lineItemViewMFE && isTransactionalCrebit;

    if ($scope.showLineItemViewMFE) {
      $scope.parcelConfig = () => window.System.import('@dn/provider-profile-account');
      $scope.parcelProps = {
        lineItemType: null,
        crebitID: $stateParams.crebitID,
        crebit: $scope.crebit,
        balance: $stateParams.balance,
        profileID: profile.id,
        paymentMethods: null,
        orgID: null,
        orgName: null,
        profileCrebits: crebits,
        profileGivenName: profile.givenName,
        readOnly: !$filter('permissionVisible')({ profile_account: 'edit' }),
        acceptedCards: null,
        navigateToAccount,
        navigateToAndReloadAccount,
        applyUpdatedCrebitDescription
      };
    }

    $scope.crebitFromDictionary = crebitDictionary[$scope.crebit.confirmation];
    $scope.balanceForConfirmation = $scope.crebitFromDictionary.totalAmount * -1;

    const getMethodCategory = window.dnim.crebitUtils.getMethodCategory;
    $scope.showRefund = !PayProcessor.features.refundAsNewCharge
      && $scope.balanceForConfirmation > 0
      && $scope.crebit.confirmation !== null
      && $scope.crebit.description.includes('[PAYMENT]')
      && (PayProcessor.features.achRefunds || getMethodCategory($scope.crebit) !== 'bank');
    $scope.showLineItems = !PayProcessor.features.refundAsNewCharge
      && $scope.crebitFromDictionary.crebits.length > 1
      && $scope.crebit.confirmation !== null;

    $scope.crebit.parent = _.find(clonedCrebits, (c) => { return c.id === $scope.crebit.adjusts; });

    $scope.crebit.relatedAttributions = _(clonedCrebits).filter((c) => {
      return _.some(c.attributingTo, (at) => {
        return at.attributedTo === $scope.crebit.id && at.amount;
      });
    }).map((c) => {
      c.amountAttributed = _.find(c.attributingTo, (at) => { return at.attributedTo === $scope.crebit.id; }).amount;
      return c;
    }).value();

    const isRelatedAttribution = (id) => {
      if (!lineItemViewMFE) return false;
      return $scope.crebit.relatedAttributions.find(c => id === c.id);
    };
    $scope.crebit.children = _.filter(clonedCrebits, (c) => {
      const isChild = c.adjusts === $scope.crebit.id;
      return isChild && !isRelatedAttribution(c.id);
    });
    $scope.crebit.siblings = _.filter(clonedCrebits, (c) => {
      const isSibling = c.adjusts && c.adjusts === $scope.crebit.adjusts && c.id !== $scope.crebit.id;
      return isSibling && !isRelatedAttribution(c.id);
    });

    $http.get(`/api/crebits/${$scope.crebit.id}/users`).then(({ data: [user] }) => {
      $scope.userCreated = user;
    });

    /* forgive me, gentle developer, for this reload. We need to reload the profile account state
     * if we've just voided a crebit. Passing crebits via parent's resolve makes any changes we
     * make to that list of crebits soft changes, in that if we navigate to the parent state and
     * then into another child state, that child state will be getting its list of crebits from the
     * parent's resolve, not from the list we modified
     * */
    $scope.navToParent = () => {
      // if we're voiding a crebit, reload; else, just nav back
      $state.go('manager.profiles.profile.account', {}, { reload: $scope.voidingCrebit });
    };

    $scope.voidable = voidable($scope.crebit);

    $scope.voidCrebit = (crebit) => {
      return swal({
        title: 'Void Transaction',
        text: 'Void this transaction?',
        showCancelButton: true,
        closeOnConfirm: false,
        confirmButtonText: 'Void'
      }, () => {
        $http.post('/api/profiles/crebits/void', { profileID: crebit.profileID, id: crebit.id }).then(() => {
          $scope.voidingCrebit = true;
          return swal({
            type: 'success',
            title: 'Success',
            text: 'This charge has been successfully voided.',
            confirmButtonText: 'Back to Account'
          }, () => $scope.navToParent());
        },
        (err) => {
          return swal({
            type: 'error',
            title: 'Error During Void',
            html: true,
            text: err.data,
            confirmButtonText: 'Back to Account'
          }, () => $scope.navToParent());
        });
      });
    };

    /* Determine if we can edit the description */
    $scope.editable = !_.some([
      $scope.crebit.registrationID,
      $scope.crebit.addOnID,
      $scope.crebit.couponID,
      $scope.crebit.confirmation
    ]);

    /* Find any coupons or adjusting crebits for this crebit */
    var couponsAndAdjustments = _(clonedCrebits).filter((c) => {
      if (c.couponID || c.description.match(/^\[ADJUST\] /)) {
        return $scope.crebit;
      }
    }).compact().value();
    $scope.attributable = window.lib.crebitAttributable($scope.crebit);

    /**
     * Saves the refund amount
     */
    $scope.refundCrebit = () => {
      if ($scope.refund.amount) {
        const body = {
          refundAmount: $scope.refund.amount
        };

        // Simple math, the order of operations
        const balance = (totalCrebitAmount + $scope.refund.amount) / 100;

        return swal({
          html: true,
          title: 'Confirm Refund',
          type: 'info',
          text: `<p>Add a <b> ${$filter('currency')($scope.refund.amount / 100)} refund </b> to ${profile.givenName}'s balance?</p>\n<br>\n<p>The remaining balance will be <b> ${$filter('currency')(balance)} </b>.</p>`,
          confirmButtonText: 'Add Line Item',
          showCancelButton: true
        }, function(isConfirmed) {
          if (isConfirmed) {
            $scope.saving = true;
            return $http.post(`/api/profiles/${$scope.crebit.profileID}/crebits/${$scope.crebit.id}/refund`, body).then(() => {
              flash('Refund saved');
              $scope.saving = null;

              // Get all the crebits and views updated
              $state.reload();
            }, () => {
              flash('Error saving refund. Please reload the page.');
              $scope.saving = null;
            });
          }
        });
      }
    };

    /**
     * Compact function that runs functions when saving changes for a crebit
     */
    $scope.saveCrebitChanges = () => {
      if ($scope.refund.amount) $scope.refundCrebit();
      if ($scope.attributable && $scope.attributionCandidates.length) $scope.saveAttributions();
    };

    /*
      Find all tuition crebits that we can attribute this crebit to
      Don't bother running if this crebit can't be attributed to anything
      TODO: Maybe find a way to have the sum and balance functions not run every digest cycle
    */
    if ($scope.attributable) {
      /* Get all crebs that you can attribute this one to */
      $scope.attributionCandidates = attributionCandidates(clonedCrebits, couponsAndAdjustments);
      $scope.attributionSum = function() {
        return _.reduce($scope.attributionCandidates, (sum, candidate) => {
          if (candidate.attribution) sum += candidate.attribution;
          return sum;
        }, 0);
      };

      $scope.attributionsBalance = function() {
        const total = $scope.attributionSum();
        if (!total) return true;
        return total <= Math.abs($scope.crebit.amount);
      };

      $scope.saveAttributions = () => {
        $scope.saving = true;
        const attributions = _.reduce($scope.attributionCandidates, (attrs, ac) => {
          /*
            Idea is to only have one attribution per a pair of crebits
            So if the attr already exists, slap the ID on and update rather than create a new one
          */
          const found = _.find($scope.crebit.attributingTo, { attributedTo: ac.id });
          if (found && Math.abs(found.amount) !== Math.abs(ac.attribution)) {
            attrs.push({
              id: found.id,
              attributedTo: ac.id,
              crebitID: $scope.crebit.id,
              amount: attributionAmount($scope.crebit, ac)
            });
          } else if (!found && ac.attribution) {
            attrs.push({
              attributedTo: ac.id,
              crebitID: $scope.crebit.id,
              amount: attributionAmount($scope.crebit, ac)
            });
          }
          return attrs;
        }, []);

        if (!attributions.length) {
          $scope.saving = null;
          return;
        }

        return $http.post(`/api/profiles/${$scope.crebit.profileID}/crebits/${$scope.crebit.id}/attributions`, attributions).then((result) => {
          flash('Attributions saved');
          $scope.saving = null;
          if (result && result.data) {
            updateCrebits(result.data);
          }
        }, () => {
          flash('Error saving attributions. Please reload the page.');
          $scope.saving = null;
        });
      };

      $scope.invalidAttributions = [];

      $scope.validateAttributions = () => {
        /*
          Check that we're not over-attributing to any one crebit
          Set length to 0 to empty array without changing the object reference
        */
        $scope.invalidAttributions.length = 0;
        _.map($scope.attributionCandidates, (candidate) => {
          if (candidate.attribution > Math.abs(candidate.amountRemaining)) $scope.invalidAttributions.push(candidate);
        });
      };
    }

    /* Only used for the `one of Patient's registrations` link text */
    $scope.registrationID = $scope.crebit.registrationID || ($scope.crebit.parent || {}).registrationID;

    $scope.crebitDescription = displayDescription($scope.crebit.description);

    /* Returns only the `[CREBIT LABEL]` of a crebit description */
    $scope.displayLabel = (description) => {
      return description.slice(0, closingBracket(description) + 1);
    };

    $scope.cancelDescriptionEdit = () => {
      $scope.editing = false;
      $scope.crebitDescription = displayDescription($scope.crebit.description);
    };

    $scope.saveCrebit = () => {
      return new Crebit({
        id: $scope.crebit.id,
        profileID: $scope.crebit.profileID,
        description: `${$scope.displayLabel($scope.crebit.description)} ${$scope.crebitDescription}`
      }).save().then((saved) => {
        flash('Line item updated');
        $scope.crebit.description = saved.description;
        $scope.editing = false;
      }).catch(() => {
        flash('Error updating description. Please reload the page.');
      });
    };

    $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission);

    function isConvenienceFee(crebit) {
      return crebit.description.match(/^\[CONVENIENCE-FEE\]/);
    };

    /* Returns everything after the `[CREBIT LABEL]` of a crebit description */
    function displayDescription(description) {
      return description.slice(closingBracket(description) + 1).trim();
    }

    /* Returns the index of the first closing bracket `]` in the description provided */
    function closingBracket(description) {
      return description.indexOf(']');
    }

    /*
      Returns all crebits that this crebit can potentially be attributed to
      Auto-applies coupon discounts or other adjustments to tuition crebits
      Finds attributions already applied to the given candidate and subtracts those amounts from the total attributable amount
      Attaches a .attribution property, auto-fills the field if there is an existing attribution
    */
    function attributionCandidates(crebits, adjustments) {
      return _(crebits)
        .filter((c) => {
          const isConvenience = !!c.registrationID || !!c.addOnID || !!c.donation || isConvenienceFee(c);
          return (isConvenience && !c.description.match(/^\[ADJUST\] /) && c.amount);
        }).map((c) => {
          const discount = _.reduce(adjustments, (sum, adjust) => {
            if (adjust.adjusts === c.id) sum += adjust.amount;
            return sum;
          }, 0);

          const alreadyAttributed = _.reduce(c.attributions, (sum, attr) => {
            if (attr && attr.amount && attr.crebitID !== $scope.crebit.id) sum += attr.amount;
            return sum;
          }, 0);

          // Make any convenience fee attributions uneditable
          // Only used in the front-end
          if (isConvenienceFee(c)) {
            c.isConvenienceFee = true;
          }

          c.type = assignType(c);
          const descriptionRegex = /\[(?:TUITION|ADD-ON|PAYMENT|MISC|EXTERNAL-PAYMENT|ADJUST|DONATION|CONVENIENCE-FEE)\] /g;
          c.description = c.description.replace(descriptionRegex, '');
          c.amountRemaining = (c.amount + discount + alreadyAttributed);

          c.attribution = (function() {
            const found = _.find($scope.crebit.attributingTo, { attributedTo: c.id });
            if (!found) return 0;
            else return Math.abs(found.amount);
          })();
          return c;
        }).filter((c) => {
          if (crebitType($scope.crebit) === 'payment') return c.amountRemaining > 0;
          else if (crebitType($scope.crebit) === 'refund') return c.amountRemaining < 0;
          else return c.amountRemaining;
        }).value();
    }

    function assignType(c) {
      /* I'm not sure if the Payment or Adjust clauses are needed anymore since we've changed what refunds can attribute */
      if (c.registrationID) return 'Registration';
      else if (c.addOnID) return 'Add-On';
      else if (c.description.match(/^\[MISC\]/)) return 'Misc';
      else if (c.description.match(/^\[PAYMENT\]|\[EXTERNAL-PAYMENT\]/)) return 'Payment';
      else if (c.description.match(/^\[ADJUST\]/)) return 'Adjust';
      else if (c.description.match(/^\[DONATION\]/)) return 'Donation';
      else if (isConvenienceFee(c)) return 'Fee';
    }

    /*
      Update crebits entries so it's reflected in parent state without reload
      Lordy lordy the vocab here is ridiculous.
    */
    function updateCrebits(savedAttributions) {
      _.map(savedAttributions, (savedAttribution) => {
        /*
          foundAttributedTo is the in-scope crebit's existing attribution to the currently-looped savedAttribution.attributedTo
          if it already exists, just update the value, else add this attribution to the array
        */
        const foundAttributedTo = _.find($scope.crebit.attributingTo, {id: savedAttribution.id});
        if (foundAttributedTo) {
          foundAttributedTo.amount = savedAttribution.amount;
        } else {
          $scope.crebit.attributingTo.push(savedAttribution);
        }
        /* modify crebits in parent function to reflect updated scope crebit */
        const scopeCrebIndex = _.findIndex(crebits, creb => { return creb.id === $scope.crebit.id; });
        $scope.crebit._hide = false;
        crebits.splice(scopeCrebIndex, 1, $scope.crebit);

        /*
          parentCrebit is the parent state crebit that the currently-looped savedAttribution.attributedTo
          foundAttribution is checking for an existing attribution between $scope.crebit and the one this is attributing to
          if it already exists, just update the value, else add this attribution to the array
        */
        const parentCrebit = _.find(crebits, {id: savedAttribution.attributedTo});
        const foundAttribution = _.find(parentCrebit.attributions, {id: savedAttribution.id});
        if (foundAttribution) {
          foundAttribution.amount = savedAttribution.amount;
        } else {
          parentCrebit.attributions.push(savedAttribution);
        }
        /* modify crebits in parent function to reflect updated parent crebit */
        const crebIndex = _.findIndex(crebits, creb => { return creb.id === parentCrebit.id; });
        parentCrebit._hide = false;
        crebits.splice(crebIndex, 1, parentCrebit);
      });
      /* Function from profile-account state to re-check amount attributed by each crebit. Updates tooltip color and text */
      $scope.$parent.assignScopeCrebits(crebits);
    }

    /* Determines if a crebit is a payment or a refund */
    function crebitType(crebit) {
      if (crebit.description.match(/^\[REFUND\]|\[MISC\]|\[EXTERNAL-REFUND\]/) && crebit.amount > 0) return 'refund';
      else if (crebit.description.match(/^\[PAYMENT\]|\[EXTERNAL-PAYMENT\]|\[MISC\]/) && crebit.amount < 0) return 'payment';
      else return 'Cannot determine line item type';
    }

    /*
      Determines what amount to set an attribution to, since all inputs take positive ints only
    */
    function attributionAmount(crebit, attr) {
      switch (crebitType(crebit)) {
        case 'payment':
          return Math.abs(attr.attribution) * -1;
        case 'refund':
          return Math.abs(attr.attribution);
        default:
          return 0;
      }
    }

    /*
     * Voidable: a function returning true for voidable crebits, false otherwise. Runs once per
     *  page load.
     * */
    function voidable (crebit) {
      if (lineItemViewMFE) return false;
      // if no confirmation, not an iCheck creb; so, can't void with iCheck; if no amount,
      // then already voided
      if (!crebit.confirmation || !crebit.amount) return false;

      $http.post('/api/profiles/crebits/check-voidability', { id: crebit.id }).then((result) => {
        $scope.voidable = result.data;
      }).catch((err) => {
        return swal({
          title: 'Error Checking Voidability',
          html: true,
          text: `<b>If you don't need to void this transaction, ignore this error.</b><br>${err.data || ''}`,
          showCancelButton: true,
          cancelButtonText: 'Continue Anyway',
          type: 'error',
          confirmButtonText: 'Reload'
        }, () => {
          $scope.voidable = false;
          $state.go('manager.profiles.profile.account', { reload: true });
        });
      });
    }

  } // end controller
});
