lib.registerState('oper.roi', {
  url: '/roi',
  templateUrl: 'states/oper/roi/roi.state.html',
  resolve: {
    title($rootScope) {
      return $rootScope._title = "ROI";
    },
    organizations($http, session) {
      if (!session || !session.access || !session.access.operator) return null;
      return $http.get("/api/groups?organization=true", {cache:true}).then(({data}) => {
        return data.map((org) => {
          return { value: org.id, label: org.name };
        });
      });
    }
  },
  controller($scope, $state, $http, organizations, session) {
    $scope.organizations = { organizations, selected: 21437 };
    $scope.isMichael = session.userID === 21437;


    $scope.getRoiData = function () {
      if (!$scope.organizations.selected) {
        return {};
      }
      return $http.get(`/api/operator/roi/${$scope.organizations.selected}`).then(({data}) => {
        $scope.data = data;
      });
    };
  },
});
