lib.registerState("patient.profile.labels", {
  url: "/labels",
  templateUrl: "states/patient/profile/labels/labels.state.html",
  resolve: {
    title($rootScope, profile) {
      return $rootScope._title = `${profile.givenName} ${profile.familyName} / Custom Labels`;
    }
  },
  controller($scope, $state, campgramsOnly, organization, profile, session) {

    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    /* No docnetwork.oliverslabels.com just yet, default to campdoc */
    const whichDivision = (() => {
      if (organization.properties.division === 'DocNetwork') return 'CampDoc';
      else return organization.properties.division;
    })();

    const {
      name,
      lcName
    } = window.dnim.getDivision(whichDivision);

    // Make sure these are accessible in the template
    $scope.profile       = profile;
    $scope.organization  = organization;
    $scope.oliversLabels = {
      alt: `Oliver's Labels Logo`,
      img: `${window.lib.brandingConfig.values.wordmarks.baseUrl}/partners/color/olivers-labels.png`
    };

    // Pre-fill as many payment fields as we can for the user
    function generateURL(user) {
      user.location = user.location || {};
      const userFields = {
        name: `${user.givenName}${user.familyName ? ' ' + user.familyName : ''}`,
        email: user.email,
        phone: user.phone,
        address1: user.location.addr1,
        address2: user.location.addr2,
        city: user.location.city,
        province: ((user.location.state || '').length === 2 ? user.location.state : ''),
        country: ((user.location.country || '').length === 2 ? user.location.country : ''),
        zip: user.location.zip,
      };
      const orgQuery = `?orgid=${organization.id}&orgname=${encodeURIComponent(organization.name)}`;
      return Object.entries(userFields).reduce((url, [key, value]) => {
        if (value) url += `&${key}=${encodeURIComponent(value)}`;
        return url;
      }, `https://${lcName}.oliverslabels.com/partners/${lcName}/${orgQuery}`);
    }

    // Used in the template to confirm redirect to Oliver's Labels store
    $scope.confirmLeaveApp = function() {
      return swal({
        type: 'warning',
        title: 'Visit Oliver\'s Labels',
        text: `
          We partner with and trust Oliver's Labels, but you are visiting an
          <b>external website</b> that we do not own or operate.
          <br><br>
          <b>Never enter your ${name} login on another website.</b>
          <br>
          Oliver's Labels will <b>never</b> ask for your ${name} login or password
          for any reason.
          <br><br>
          By visiting Oliver's Labels, you agree to share your name, email, and address to
          facilitate quick and easy ordering.
          <br><br>
          Would you like to proceed to our Oliver's Labels store?
        `,
        html: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true,
        cancelButtonText: 'Stay Here',
        confirmButtonText: 'Get Labels'
      }, (confirmed) => {
        if (confirmed) {
          const labelsWindow = window.open();
          // https://mathiasbynens.github.io/rel-noopener/
          labelsWindow.opener = null;
          return labelsWindow.location = generateURL(session.user);
        }
      });
    }
  }
});
