angular.module('dn').directive('invoiceLedger', () => {
  return {
    templateUrl: 'directives/invoice-ledger/invoice-ledger.directive.html',
    restrict: 'A',
    scope: {
      accountManager: '=',
      billingContact: '=',
      crebits:        '=',
      group:          '=',
      organizationId: '=',
    },
    controller: ($http, $rootScope, $scope) => {
      'use strict';

      const preAssignFrz = Object.freeze({
        DEFAULT_RATE: 4, // 02/11/19: current default rate
        DEFAULT_MONTH: 10, // 02/11/19: current default month
      });

      Object.assign($scope, preAssignFrz, {
        addAdjustmentHandler,
        billingPeriodMonth: getBillingPeriodMonth($scope.group),
        close: false,
        closeFn, // closes ui-routed crebit view by setting scope.close to false
        customDescription: null,
        customAmount: undefined, // default for 'empty' display
        monthName,
        print: () => window.print(),
        rate: setRate($scope.group),
        setRate,
        today: moment().format('YYYY-MM-DD'),
      });


      function addAdjustmentHandler (amount, description, { compiledProperties }) {
        const adjustment = {
          accountManager: $scope.accountManager,
          amount,
          billingContact: $scope.billingContact,
          description,
          division: compiledProperties && compiledProperties.division,
        };

        $scope.submittingAdjustment = true;

        return Promise.all([addAdjustment(adjustment)])
          .then(scopeUpdateOnSuccess)
          .then(resetCrebits)
          .then(emitCrebits)
          .catch(errorSwal);
      }

      function addAdjustment (adjustment) {
        return $http.post(`/api/groups/${$scope.organizationId}/adjust-invoice`, adjustment);
      }

      function scopeUpdateOnSuccess () {
        $scope.submittingAdjustment = false;
        $scope.customAmount = NaN;
        $scope.customDescription = null;

        return successSwal('Adjustment successfully added');
      }

      /*
       * this sets the original rate, but also visually updates (i.e., not with 'live'
       * data) the rate after saving a new rate, which is why it has no param. The
       * save directive won't call it with any arg.  * */
      function setRate () {
        return $scope.rate = ($scope.group.properties.billing && ($scope.group.properties.billing.rate || $scope.group.properties.billing.rate === 0))
          ? $scope.group.properties.billing.rate / 100
          : preAssignFrz.DEFAULT_RATE;
      }

      function getBillingPeriodMonth (group) {
        return (group.properties.billing && group.properties.billing.month)
          ? group.properties.billing.month
          : preAssignFrz.DEFAULT_MONTH;
      }

      function monthName (num) {
        return moment().month(num - 1).format('MMMM');
      }

      /*
       * GENERAL HELPER FUNCTIONS
       * */
      function resetCrebits () {
        return $http.get(`/api/organizations/${$scope.organizationId}/invoice-crebits`)
          .then(({ data: crebits }) => {
            return crebits; // pass along to emitCrebits
          })
          .catch(errorSwal);
      }

      function emitCrebits (crebits) {
        return $rootScope.$emit('updateInvoiceCrebits', { crebits });
      }

      function successSwal (text) {
        return swal({
          title: 'Success!',
          text,
          type: 'success'
        });
      }

      function closeFn(){
        $scope.close = true;
        return;
      }

      function errorSwal () {
        $scope.submittingAdjustment = false;
        return swal({
          title: 'Error',
          text: 'There was an error processing your request.',
          type: 'error'
        });
      }

      $rootScope.$on('closeInvoice', function closeInvoice (event, { closeInvoice, crebitDeleted }) {
        $scope.close = closeInvoice;
        if (crebitDeleted) {
          return resetCrebits().then(emitCrebits).catch(errorSwal);
        }
        return;
      });

      // Data for pagination of line items
      $scope.currentPage = 0;
      $scope.limit = 15;

      function setPages(crebits) {
        $scope.pages = _.times(Math.ceil(crebits.length / $scope.limit), Number);
        return $scope.pages;
      }
      setPages($scope.crebits);

      // Allow $0 line items to be filtered out
      $scope.display0Crebits = false;
      $scope.yesNo = [
        { label: 'No', value: false },
        { label: 'Yes', value: true }
      ];

      $scope.displayCrebit = (crebit) => {
        return $scope.display0Crebits ? true : crebit.amount !== '0';
      };

      $scope.$watch('display0Crebits', () => {
        const filteredCrebits = $scope.crebits.filter($scope.displayCrebit);
        return setPages(filteredCrebits);
      });
    }
  };
});
