const getValue = (item, property) => {
  const propertyChain = property.split('.');
  let value = _.cloneDeep(item);
  _.each(propertyChain, (property) => {
    if (value) value = value[property];
  });
  return value;
};

angular.module('dn').filter('unique', () => (items, property) => {
  return _.uniqBy(items, (item) => property ? getValue(item, property) : item);
});
