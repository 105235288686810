lib.registerState('manager.prescreening.template', {
  url: '/template',
  templateUrl: 'states/manager/prescreening/template/template.state.html',
  resolve: {
    title($rootScope, GroupBranding, organization) {
      // Have to use `organization` because child resolves happen _before_ parent resolves, so
      // $rootScope.organization isn't set yet here when we hard refresh.
      return $rootScope._title = `${GroupBranding.getPrescreeningAlias(organization)} Template`;
    },
    template(organization, PrescreeningData) {
      const { features } = organization.properties;

      if (!features || !features.prescreening || !features.prescreening.enabled) {
        return new Error('Preescreening feature not enabled');
      }

      return PrescreeningData.getTemplate(true, organization.id)
        .then(data => {
          if (data) {
            // In the future we will allow multiple templates but for now only one
            PrescreeningData.createTemplate({
              templateID: data.id,
              template: data.template.questions,
              branding: data.template.branding || window.dnim.constants.prescreening.branding
            });
          } else {
            PrescreeningData.createTemplate({ template: [], branding: window.dnim.constants.prescreening.branding });
            window.dnim.constants.prescreening.staticQuestions.forEach((q) => {
              PrescreeningData.addQuestion(q);
              PrescreeningData.applyChanges('question');
            });
            PrescreeningData.clearTmpQuestion();
          }
        })
        .catch(() => new Error('There was a problem loading the page'));
    }
  },
  controller: function($scope, $rootScope, template, PrescreeningData) {
    'use strict';
    if (template instanceof Error) {
      $scope.errorText = template.message;
      $rootScope._title = 'Error';
      return;
    }

    // Define the tabs
    const templateEditor = {
      label: 'Template Editor',
      value: 'editor'
    };
    const branding = {
      label: 'Customization',
      value: 'branding'
    };

    // Tab configuration
    $scope.tabDisabled = false;
    Object.defineProperty(branding, 'disabled', {
      enumerable: true,
      writeable: false,
      configurable: false,
      get() {
        if (PrescreeningData.questionEditTarget
            && (PrescreeningData.checkQuestionIfEdited(PrescreeningData.questionEditTarget) || PrescreeningData.questionEditTarget.isNew)
        ) {
          $scope.brandingDisabled = true;
          $scope.displayTab = 'Customization Tab';
          return true;
        } else {
          $scope.brandingDisabled = false;
          return false;
        }
      }
    });

    Object.defineProperty(templateEditor, 'disabled', {
      enumerable: true,
      writeable: false,
      configurable: false,
      get() {
        if (Object.keys(PrescreeningData.tmp.branding).length !== 0 && PrescreeningData.checkBrandingIfEdited()) {
          $scope.templateDisabled = true;
          $scope.displayTab = 'Template Editor Tab';
          return true;
        } else {
          $scope.templateDisabled = false;
          return false;
        }
      }
    });

    // Tabs setup
    $scope.tabs = [
      templateEditor,
      branding
    ];

    // Default tab
    $scope.tab = 'editor';
  }
});
