angular.module('dn').directive('templateEditorQuestion', function () {
  return {
    restrict: 'E',
    templateUrl: 'directives/prescreening/template-editor-question/template-editor-question.directive.html',
    scope: {
      question: '='
    },
    controller($scope, $rootScope, PrescreeningData) {
      'use strict';

      try {

        const ICON_TYPE_MAP = {
          'boolean': 'fas fa-toggle-on',
          'number': 'fas fa-sort',
          'tags': 'fas fa-tasks',
          'select': 'fas fa-mouse-pointer',
          'temperature': 'fas fa-temperature-high',
          'text': 'fas fa-pencil-alt',
          'textarea': 'fas fa-edit',
          'upload': 'fas fa-upload',
        };
        const DEFAULT_TEXT_COLOR = 'text-black';
        const WARNING_TEXT_COLOR = 'text-red';
        const BTN_GREY = 'btn-default';
        const BTN_RED = 'btn-danger';
        const ICON_SELECTOR = $scope.question.dataType === 'temperature' ? $scope.question.dataType : $scope.question.inputType;

        $scope.yesNo = [
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ];

        const swalConfig = {
          type: 'warning',
          title: 'Unsaved Changes',
          text: 'Are you sure you want to proceed? Your changes will be discarded.',
          html: true,
          allowOutsideClick: true,
          allowEscapeKey: true,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Discard Changes',
          cancelButtonText: 'Continue Editing'
        };


        /**
         * handles all move operations needed like checking for changes, discarding them, moving the question and saving it
         * @param {String} direction - string must be up or down
         * @param {Object} question - the question we are moving
         * @returns {void}
         */
        function handleMove(direction, question) {
          if (!direction) {
            log.error(new Error('[template-editor-question][handleMove] No direction was passed'));
            return;
          }

          if (typeof direction !== 'string') {
            log.error(new Error('[template-editor-question][handleMove] direction is not a string'));
            return;
          }

          if (direction !== 'up' && direction !== 'down') {
            log.error(new Error('[template-editor-question][handleMove] direction must be up or down'));
            return;
          }

          /**
           * handles the operations needed to move a question
           * @returns {void}
           */
          function applyMove() {
            PrescreeningData.moveQuestion(direction, question.id);
            $scope.ui.movingQuestion = true;
            handleSaveForMove();
          }

          if (PrescreeningData.questionEditTarget
              && (PrescreeningData.checkQuestionIfEdited(PrescreeningData.questionEditTarget) || PrescreeningData.questionEditTarget.isNew)
          ) {
            swal(swalConfig, function(confirmed) {
              if (confirmed) {
                // If we delete a new question there is nothing else to do
                if (PrescreeningData.questionEditTarget.isNew) {
                  PrescreeningData.deleteQuestion(PrescreeningData.questionEditTarget);
                  return;
                }
                // Clear changes
                PrescreeningData.clearTmpQuestion();
                // Mark it for editing since users can move multiple times a question
                PrescreeningData.editQuestion(question);
                applyMove();
              }
            });
          } else {
            applyMove();
          }
        }

        $scope.icons = [
          // Question Type Icon
          {
            icon: ICON_TYPE_MAP[ICON_SELECTOR],
            hide: false,
            color: DEFAULT_TEXT_COLOR,
            title: `${ICON_SELECTOR.capitalize()} Question`,
          },
          // Required Icon
          Object.defineProperties({}, {
            icon: {
              value: 'fas fa-asterisk'
            },
            hide: {
              get() {
                return $scope.question.required !== true;
              }
            },
            color: {
              value: WARNING_TEXT_COLOR,
            },
            title: {
              value: 'Required'
            },
          }),
          // Stats-enabled Icon
          {
            icon: 'fas fa-chart-line',
            hide: !$scope.question.dataType || $scope.question.dataType === 'dynamic',
            color: DEFAULT_TEXT_COLOR,
            title: 'Stats-enabled',
          },
        ];

        $scope.buttonConfig = [
          Object.defineProperties({}, {
            name: {
              value: 'moveUp',
            },
            title: {
              value: 'Move Question Up',
            },
            icon: {
              value: 'fas fa-arrow-up',
            },
            color: {
              value: BTN_GREY,
            },
            disabled: {
              get() {
                return $scope.ui.saving;
              }
            },
            action: {
              value: function (question) {
                handleMove('up', question);
              }
            }
          }),
          Object.defineProperties({}, {
            name: {
              value: 'moveDown',
            },
            title: {
              value: 'Move Question Down',
            },
            icon: {
              value: 'fas fa-arrow-down',
            },
            color: {
              value: BTN_GREY,
            },
            disabled: {
              get() {
                return $scope.ui.saving;
              }
            },
            action: {
              value: function (question) {
                handleMove('down', question);
              }
            }
          }),
          Object.defineProperties({}, {
            name: {
              value: 'delete',
            },
            title: {
              value: 'Delete Question',
            },
            icon: {
              value: 'far fa-trash-alt',
            },
            color: {
              value: BTN_RED,
            },
            disabled: {
              get() {
                return $scope.ui.saving || $scope.ui.movingQuestion;
              }
            },
            action: {
              value: function (question) {
                let deleteConfig;
                if (question.dataType === 'dynamic' && !question.isNew) {
                  deleteConfig = {
                    title: 'Data Loss Warning',
                    text: `
                      Deleting this question will <b>permanently delete historic answer data.</b> It
                      will no longer appear in reports. Please make sure you\'ve exported all data
                      for your records before proceeding.
                    `,
                    confirmButtonText: 'Delete Question'
                  };
                } else if (question.dataType !== 'dynamic' && !question.isNew) {
                  deleteConfig = {
                    title: 'Delete Question?',
                    text: `
                      Answers to this question will no longer appear in reports. Because it\'s a
                      stats-enabled question, no historic answer data will be lost.
                    `,
                    confirmButtonText: 'Delete Question'
                  };
                } else {
                  deleteConfig = swalConfig;
                }
                swal(Object.assign({}, swalConfig, deleteConfig), function(confirmed) {
                  if (confirmed) {
                    PrescreeningData.deleteQuestion(question);
                    saveTemplate();
                  }
                });
              }
            }
          }),
          Object.defineProperties({}, {
            name: {
              value: 'edit',
            },
            title: {
              value: 'Edit Question',
            },
            icon: {
              value: 'fas fa-cogs',
            },
            color: {
              value: BTN_GREY,
            },
            disabled: {
              get() {
                return $scope.ui.saving || $scope.ui.movingQuestion;
              }
            },
            action: {
              value: function (question) {
                // If the user doesn't save the changes on a question and decided to edit another one or the same one we display a warning if they want to discard their changes
                // If they confirm we discard those changes.
                if (PrescreeningData.questionEditTarget
                    && (PrescreeningData.checkQuestionIfEdited(PrescreeningData.questionEditTarget) || PrescreeningData.questionEditTarget.isNew)
                ) {
                  swal(swalConfig, function(confirmed) {
                    if (confirmed) {
                      // We save it on a variable since clearTmpQuestion will delete the edit target
                      const previousQuestionId = PrescreeningData.questionEditTarget.id;
                      if (PrescreeningData.questionEditTarget.isNew) PrescreeningData.deleteQuestion(PrescreeningData.questionEditTarget);
                      PrescreeningData.clearTmpQuestion();
                      // If we are trying to open a different question clearTmpQuestion is going to disallow opening it.
                      // We need to call it only for opening that different question.
                      if (previousQuestionId !== question.id) PrescreeningData.editQuestion(question);
                    }
                  });
                } else {
                  PrescreeningData.editQuestion(question);
                  // If we were adding a question and click edit on any other question we need to close the add new question editor
                  if (PrescreeningData.ui.addingNewQuestion) PrescreeningData.setUiEntry('addingNewQuestion', !PrescreeningData.ui.addingNewQuestion);
                }
              }
            }
          }),
        ];

        $scope.ui = {
          saving: false,
          movingQuestion: false,
          tmp: PrescreeningData.tmp,
          cancel() {
            // We want to ask the user for discarding changes
            if (PrescreeningData.questionEditTarget
                && (PrescreeningData.checkQuestionIfEdited(PrescreeningData.questionEditTarget) || PrescreeningData.questionEditTarget.isNew)
            ) {
              swal(swalConfig, function(confirmed) {
                if (confirmed) {
                  if (PrescreeningData.questionEditTarget.isNew) PrescreeningData.deleteQuestion(PrescreeningData.questionEditTarget);
                  PrescreeningData.clearTmpQuestion();
                }
              });
            } else {
              PrescreeningData.clearTmpQuestion();
            }
          },
          save() {
            PrescreeningData.applyChanges('question');
            PrescreeningData.clearTmpQuestion();
            saveTemplate();
          },
        };

        /**
         * saveTemplate - allows us to save the template by calling the method on the service.
         * It handles disabling the buttons and showing the progress
         * @returns {void}
         */
        function saveTemplate() {
          $scope.ui.saving = true;
          $rootScope.showProgress();

          PrescreeningData.saveTemplate()
            .catch(err => {
              // Logging for local dev environments.
              log.error(err);
              // Display user-friendly error
              swal({
                type: 'error',
                title: 'Error',
                text: 'There was an error submitting the template. Please try again later.'
              });
            })
            .finally(() => {
              $scope.ui.saving = false;
              $rootScope.hideProgress();
            });
        }

        /*
         * handleSaveForMove - debounces for 1 second to allow users to move the question before
         * saving the template and setting the movingQuestion to false enabling the inputs
         * @returns {void}
         */
        const handleSaveForMove = _.debounce(() => {
          $scope.ui.movingQuestion = false;
          // If we are dealing with a new question we want to remove the .isNew property since we are calling a save
          PrescreeningData.applyChanges('question');
          saveTemplate();
        }, 1000);

        Object.defineProperty($scope.ui, 'editing', {
          enumerable: true,
          configurable: false,
          get() {
            return PrescreeningData.questionEditTarget === $scope.question;
          }
        });

      } catch (error) {
        log.error('[template-editor-question]', error);
      }

    }
  };
});
