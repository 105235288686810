Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "AddOn", ($http) ->
  AddOn = new Resource(
    route: "/api/groups/:groupID/add-ons/:id"
    schema:
      name:
        type: "text"
        required: true

      amount:
        type: "number"
        required: true

      capacity:
        type: "number"

      groupID:
        type: "number"

      expires:
        type: "date"

      parentID:
        type: "number"

      properties:
        type: "json"
  )
  AddOn
