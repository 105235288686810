angular.module('dn').directive('reportItem', function ($http) {
  return {
    // We can use ng-include to dynamically set our layout type
    template: '<ng-include src="setTemplate()" />',
    restrict: 'E',
    scope: {
      report: '=',
      layout: "=",
      org:    "=",
      delete: "=",
      reportType: "@"
    },
    link: function(scope, elm, attrs) {
      scope.deleteReport = function(reportKey) {
        // We need to URI encode the report key because it contains the file extension
        $http.delete(`/api/organizations/${scope.org}/${scope.reportType}/${encodeURIComponent(reportKey)}`).then((success) => {
          // Pull it out of the reports array
          const thisID = scope.$parent.reports.findIndex(r => r.key === scope.report.key);
          scope.$parent.reports.splice(thisID, 1);
          // Update the page count in case this is the only report on the page
          flash("Report Deleted");
          return scope.$parent.updatePages()
        }).catch((err) => {
          flash("Error Deleting Report - Please Try Again");
        });
      }
    },
    controller: function ($scope) {

      // Function used to set our layout
      $scope.setTemplate = function() {
        if ($scope.layout === 'card') {
          return 'directives/reports-ui/report-item/card/report-card.directive.html';
        }
        else {
          return 'directives/reports-ui/report-item/li/report-li.directive.html';
        }
      }

      function setThumbnail(mimeType) {
        switch(mimeType) {
          case "CSV":
          case "text/csv":
            return {
              src: window.lib.brandingConfig.values.fallbacks.csv.thumb,
              alt: "CSV Report",
              title: "CSV Report - "
            }
          case "PDF":
          case "application/pdf":
            return {
              src: window.lib.brandingConfig.values.fallbacks.pdf.thumb,
              alt: "PDF Report",
              title: "PDF Report - "
            }
          case "ZIP":
          case "application/zip":
            return {
              src: window.lib.brandingConfig.values.fallbacks.zip.thumb,
              alt: "Zipped Report",
              title: "Zipped PDF Reports - "
            }
          default:
            return {
              src: window.lib.brandingConfig.values.fallbacks.file.thumb,
              alt: "Report",
              title: "Report - "
            }
        }
      }

      // Set our SVG data
      $scope.svg = setThumbnail($scope.report.type);

      $scope.svgFallback = window.lib.brandingConfig.values.fallbacks.file.thumb;

      // Delete multiple reports with the checkboxes
      $scope.selected = false;

      $scope.toggleDeleteMultiple = function(key) {
        const targetIndex = $scope.delete.findIndex(target => target === key);
        if (targetIndex === -1) {
          $scope.selected = true;
          return $scope.delete.push(key);
        }
        else {
          $scope.selected = false;
          return $scope.delete.splice(targetIndex, 1);
        }
      }


      // Delete a single report with the trash button
      $scope.confirmDeleteReportSingle = (reportKey) => {
        return swal({
          type: "warning",
          title: "Are You Sure?",
          text: `
            This will <b>delete the report</b> and cannot be undone.
          `,
          html: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          closeOnConfirm: true
        }, (confirmed) => {
          if (!confirmed) return;
          return $scope.deleteReport($scope.report.key);
        });
      };
    }
  }
});
