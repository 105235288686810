angular.module("dn").directive "topbar", (User) ->
  restrict: "E"
  templateUrl: "directives/topbar/topbar.directive.html"
  controller: ($scope, $state, $http, $rootScope, $filter, WhatDivision, flash, SupportDirectory) ->
    hiddenByState = (state) ->
      _.some ["home.auth", "renew", "checkIn"], (hidden) -> state.indexOf(hidden) >= 0

    $scope.division = WhatDivision
    $scope.state = $state
    $scope.topBarHidden = true
    $scope.deauthenticate = User.deauthenticate

    $scope.showMenuButton = ->
      $scope.viewPort ?= {}
      return $scope.viewPort.mobile and !$scope.sideBarHidden and !$scope.whereTo

    $scope.setUsername = (username) ->
      if username
        $scope.userName = username
      else if !$scope.user
        return
      if $scope.user.familyName && $scope.user.givenName
        $scope.userName = $scope.user.givenName + ' ' + $scope.user.familyName
      else if $scope.user.givenName
        $scope.userName = $scope.user.givenName
      else
        $scope.userName = $scope.user.email
    $scope.setUsername()

    $scope.openSideBar = ->
      $scope.sidebar = true

    $scope.canViewNewsFeed = false

    checkNewsFeedPermissions = (topBarHidden) ->
      if !topBarHidden
        $scope.canViewNewsFeed = $filter('permissionVisible')({ view_news_feed: 'allow' })

    $scope.$watch "state.current.name", (name, stale) ->

      $scope.topBarHidden = hiddenByState name
      checkNewsFeedPermissions($scope.topBarHidden)
      if !name
        # since we don't have schooldoc support, we're directing them to the help email
        # Change this once we have support mirrored for SchoolDoc, DaycareDoc, JailDoc, etc... :D
        if $scope.division.domain == 'schooldoc.com'
          $scope.helpUrl = "https://support.schooldoc.com"
        else
          $scope.helpUrl = "https://support.campdoc.com"
      else
        $scope.helpUrl = SupportDirectory.findDoc(name)

    $rootScope.$on "$stateChangeSuccess", (_, __, toParams) ->
      if toParams.username
        $scope.setUsername(toParams.username)

      $scope.topBarHidden = hiddenByState name
      return if $scope.user or $scope.topBarHidden
      User.current().then (user) ->
        return unless user
        $scope.user = user
        $scope.setUsername()
