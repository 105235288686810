angular.module("dn").directive("flatTabs", function () {
  return {
    templateUrl: "directives/flat-tabs/flat-tabs.directive.html",
    restrict: "E",
    scope: {
      /**
       * NOTE: config is two-way bound. Changes here affect the the config externally, and external
       * updates to the object will affect display in this directive.
       * @var {Object} config Object for configuring the tab display.
       * @var {Object[]} config.tabs An array of objects for configuring a tab.
       * @var {String} config.tabs[].label The human-readable label to display for the tab.
       * @var {String} config.tabs[].value The data value to set when the tab is active.
       * @var {Boolean} config.tabs[].[hide] (Optional) Hide this tab if true.
       * @var {Object} config.tabs[].[permissions] (Optional) Permissions object to restrict tab display.
       * @var {String} config.active The `value` for the currently selected tab.
       */
      config: "=",
    },
    controller($scope) {

      try {

        if (!($scope.config || {}).tabs || !$scope.config.tabs.length) {
          throw Error('No tab config.tabs provided.');
        }
        // Validate tabs
        $scope.config.tabs.forEach((o) => {
          if (!o.label) throw Error(`Missing label for tab: ${JSON.stringify(o)}`);
          if (!o.value) throw Error(`Missing value for tab: ${JSON.stringify(o)}`);
          // Ensure each of them has a unique ID
          o.id = window.uuid();
        });
        $scope.navID = window.uuid();
        $scope.setActive = (val) => $scope.config.active = val;

      } catch (error) {
        _handleError('CONTROLLER', 'Failed to initalize controller', error);
      }

      function _handleError(tag, msg, err) {
        const formatted = `[flatTabs][${tag}] ${msg}${err ? ': ' + err.stack.subtring(0, 400) : ''}`;
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          console.error(formatted);
        }
      }
    }
  };
});
