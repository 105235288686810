angular.module("dn").directive "crebitTag", ->
  templateUrl: "directives/crebit-tag/crebit-tag.directive.html"
  transclude: true
  replace: true
  restrict: "A"
  scope:
    crebit: "=crebitTag"
  link: (scope, elm, attrs) ->
    scope.types =
      "DEPOSIT":            "blue"
      "TUITION":            "blue"
      "ADD-ON":             "blue"
      "ADJUST": { positive: "blue", negative: "green" }
      "MISC":   { positive: "blue", negative: "green" }
      "SMS-MESSAGES":        "orange"

      "COUPON":             "green"
      "ELECTRONIC-PAYMENT": "green"
      "EXTERNAL-PAYMENT":   "green"
      "EXTERNAL-REFUND":    "red"
      "PAYMENT":            "green"

      "DONATION":           "purple"

      "REFUND":             "red"

      "ACH-RETURN":         "orange"
      "CHARGEBACK":         "orange"
      "PAYOUT":             "orange"


      "PHARMACY":           "black"
      "PRESCRIPTION":       "blue"

      "PROTECTION PLAN":    "grey"

      "CAMPGRAMS":          "blue"

      "INVOICE":            "black"
      "INVOICE-RUSH-FEE":   "black"
      "INVOICE-PAYMENT":    "green"
      "INVOICE-DEPOSIT":    "green"
      "INVOICE-ADJUSTMENT": { positive: "blue", negative: "green" }
      "CONVENIENCE-FEE":    "blue"
      "TRANSFER":           "grey"


    scope.copy = _.cloneDeep scope.crebit
    scope.crebitType = ""
    foundType = null
    _.map scope.types, (color, type) ->
      if !scope.copy.description?.match(type) then return
      c = scope.copy.description
      rightBound = c.indexOf "]"
      foundType = c.slice(c.indexOf("[") + 1, rightBound)
      return unless scope.types[foundType]
      scope.type = foundType
      if (_.isPlainObject scope.types[foundType])
        if scope.crebit.amount < 0  then scope.crebitType = scope.types[foundType].negative
        else scope.crebitType = scope.types[foundType].positive
      else
        scope.crebitType = scope.types[foundType]

    Object.defineProperty(scope, 'description', {
      enumerable: false,
      configurable: false,
      get: () ->
        return scope.crebit.description unless scope.crebitType
        copy = scope.crebit.description.replace(foundType, "").trim()
        return copy.replace(/(\[|\])/g, "")
    })
