lib.registerState "manager.finances.profileLedger.balances",
  url: "/balances"
  templateUrl: "states/manager/finances/ledgers/profile/balances/balances.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Participant Balances"
    profiles: ($stateParams, $http) ->
      $http.get("/api/organizations/#{$stateParams.orgID}/crebits/balances").then (result) ->
        result.data

  controller: ($scope, $state, profiles) ->
    $scope.tab = "all"

    $scope.groups = do ->
      groups = []
      return if !$scope.organization
      addGroup = (group) ->
        group.chain ?= []
        group.chain.push group.name
        groups.push
          id: group.id
          chain: group.chain
          phase: group.phase
        return if !group.children or !group.children.length
        _.each _.sortBy(group.children, "name"), (child) ->
          child.chain = _.clone(group.chain)
          addGroup child
      addGroup $scope.organization
      _.map groups, (g) ->
        g.chain.shift() if g.chain.length > 1
        {
          value: g.id
          label: g.chain.join " » "
          phase: g.phase
        }

    $scope.group = $scope.organization.id

    $scope.profiles = (tab) ->
      tab ?= "all"
      return profiles if tab is "all" and ($scope.group is $scope.organization.id)
      _.filter profiles, (profile) ->
        if $scope.organization.id isnt parseInt $scope.group
          return false if !_.includes profile.groups, parseInt $scope.group
        return true if tab is "all"
        return parseInt(profile.balance) is 0 if tab is "paid"
        return parseInt(profile.balance) > 0 if tab is "outstanding"
        return parseInt(profile.balance) < 0 if tab is "refund"
