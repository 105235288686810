angular.module('dn').directive('location', function ($timeout) {
  return {
    templateUrl: 'directives/input/types/location/location.directive.html',
    restrict: 'A',
    scope: {
      model: '=location',
      // Used when we only want a formatted address, not a location data object
      formattedOnly: '='
    },
    link: function(scope, elm) {

      // Some Utility Functions
      // Reformat PAC results to make life easier
      function processPlaceResult(result) {

        // If the user DOESN'T select from the list, return whatever they typed
        if (Object.keys(result).length === 1) {
          return {
            address: result.name,
            components: null
          };
        }

        // format location data for storage in the db
        const parts = result.address_components.map(c => {
          // We'll only ever need these parts for our stuff. Let's simplify now.
          return {
            value: c.short_name,
            types: c.types
          };
        });

        return {
          components: parts,
          geometry: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng()
          }
        };
      }

      // Make it easier to access the components
      function getAddr1(components) {
        const streetAddress = {};
        components.map(c => {
          if (_.includes(c.types, 'street_number')) {
            streetAddress.street_number = c.value;
          } else if (_.includes(c.types, 'route')) {
            streetAddress.route = c.value;
          }
        });
        // Now format the street address string
        const parts = [streetAddress.street_number, streetAddress.route];
        // Use loose eqaulity to match `null` or `undefined`
        // eslint-disable-next-line eqeqeq
        return parts.filter(p => p != null).join(" ");
      }

      // Returns the matched value or null
      function getSingleComponent(components, match) {
        const matched = components.find(c => _.includes(c.types, match));
        return (matched && matched.value ? matched.value : null);
      }

      // Apply PAC results to the address model
      function useResult(pacResult) {
        let formatted = processPlaceResult(pacResult);
        if (formatted.components) {
          let data = formatted.components;
          // This is OUR address object - it's quite a bit different from the
          // one returned by Google. Note that we only add a formatted address
          // string after the user confirms the location
          return scope.newAddress = {
            addr1: getAddr1(data),
            addr2: getSingleComponent(data, 'subpremise'),
            city: getSingleComponent(data, 'locality'),
            state: getSingleComponent(data, 'administrative_area_level_1'),
            zip: getSingleComponent(data, 'postal_code'),
            country: getSingleComponent(data, 'country'),
            geometry: formatted.geometry
          };
        } else {
          return scope.newAddress = {
            addr1: formatted.address
          };
        }
      }

      // If we have a well-formed oldAddress, decompose it into newAddress.
      function decomposeAddressObj() {
        // If we already decomposed newAddress or it's a PAC result, return early
        if (!!scope.newAddress.formatted || scope.placeChanged || scope.addressEdited) return;
        // If it's a string, we have nothing to decompose. Ignore it.
        // Otherwise...
        if (!_.isEmpty(scope.oldAddress) && typeof(scope.oldAddress === 'object') && scope.oldAddress.formatted) {
          // If oldAddress.formatted, it's a well-formed address. Break it into
          // its constituent parts. If it's not, do nothing.
          return scope.newAddress = Object.assign({}, scope.oldAddress);
        }
      }

      // Grab our PAC input
      const acInput = elm.find('.main-location-input');

      // Grab other inputs so we can check them for focus
      const otherInputs = $('.extra input');
      function otherInputsFocused() {
        const focused = [];
        otherInputs.each(function() {
          const val = $(this).is(":focus");
          focused.push(val);
        });
        return focused.some(f => f);
      }

      // Engage editing mode if someone taps/clicks the main input
      acInput.on('focus', () => {
        scope.intentToEdit = true;
        // As long as we haven't already edited, decompose the address object
        if (!scope.addressEdited) decomposeAddressObj();
      });

      // Disengage editing mode if we lose focus (on select occasions)
      acInput.on('blur', () => {
        // Do it in a timeout so we can check the other inputs for focus
        return $timeout(() => {
          // Cancel if they clicked the input but didn't change anything and
          // they didn't click on any other inputs
          if (scope.locationForm
              && scope.locationForm.pac.$pristine
              && !scope.addressEdited
              && !otherInputsFocused()
          ) {
            return scope.onCancel();
          }
        });
      });

      // Set up Google PAC
      if (typeof google !== 'undefined') {
        pacOptions = { types: ['address'] };
        scope.pac = new google.maps.places.Autocomplete(acInput[0], pacOptions);
        // Restrict our search to these two fields so we aren't charged for data
        // we don't even use
        const PAC_FIELDS = [ 'address_components', 'geometry' ];
        scope.pac.setFields(PAC_FIELDS);
        // Event listener for when a place is selected
        google.maps.event.addListener(scope.pac, 'place_changed', () => {
          scope.placeChanged = true;
          const newPlace = scope.pac.getPlace();
          // Change label of mat input
          if (scope.$parent.label === scope.oldLabel) scope.$parent.label = 'Street Address';
          useResult(newPlace);
        });
      }

    },
    controller: function ($scope) {

      $scope.intentToEdit = false;
      $scope.addressEdited = false;
      $scope.oldLabel = $scope.$parent.label;
      // It seems isRequired isn't calculated when the directive initializes,
      // so do it in a timeout.
      $timeout(() => {
        $scope.required = $scope.$parent.isRequired;
      });

      // Create a copy of the model so we can reference it internally and ensure
      // that this behaves like a mat input externally (i.e. sets model to
      // null when the user is editing/value is invalid).
      function setOldAddress() {
        if (!_.isEmpty($scope.model) && typeof($scope.model) === 'string') {
          // Strip extra whitespace from our old free-text stuff
          return $scope.oldAddress = $scope.model.replace(/\s+/g, ' ');
          // Works for new address objects AND null/undefined
        } else if (!_.isEmpty($scope.model) && typeof($scope.model) === 'object') {
          return $scope.oldAddress = Object.assign({}, $scope.model);
        } else {
          return $scope.oldAddress = null;
        }
      }

      // An Important Note:
      // The newAddress.addr1 property is very all-purpose in this directive. It
      // serves as the "placeholder" for existing addresses, the value for the
      // PAC input, and as an actual address component. It can do these things
      // because newAddress is an isolated COPY of oldAddress.
      function resetAddressObj() {

        // If the model HAD an address, reset newAddress with that address.
        if (!_.isEmpty($scope.oldAddress)) {

          // Init this to an empty object so it can't be undefined
          // Related to docnetwork/issues#1859
          $scope.newAddress = {};

          // If it's a string...
          if (typeof($scope.oldAddress) === 'string') {
            // Set the first component of newAddress
            $scope.newAddress = {addr1: $scope.oldAddress};
            // If it's one of our new location objects with formatted address...
          } else if (typeof($scope.oldAddress === 'object') && $scope.oldAddress.formatted) {
            // Set the first newAddress component to the formatted address
            $scope.newAddress = {addr1: $scope.oldAddress.formatted};
          }

        // Otherwise, just make it an empty object
        } else {
          $scope.newAddress = {};
        }

        // Finally, reset the model to the old address
        return $scope.model = $scope.oldAddress;
      }

      // Compare newAddress and oldAddress for meaningful differences
      function addressChanged() {
        let addressesEqual;

        if (typeof $scope.oldAddress === 'string') {
          addressesEqual = $scope.oldAddress === $scope.newAddress.addr1;
        } else {
          const oldKeys = Object.keys($scope.oldAddress || {});
          const newKeys = Object.keys($scope.newAddress);
          // For previously-undefined location objects, there will be no oldKeys
          if (oldKeys.length === 0 && newKeys.length !== 0) {
            addressesEqual = false;
          }
          // If we've just saved an address object, our newAddress will change to
          // { addr1: oldAddress.formatted }, so check for that.
          if (oldKeys.length && newKeys.length === 1 & newKeys[0] === 'addr1') {
            addressesEqual = $scope.oldAddress.formatted === $scope.newAddress.addr1;
          } else {
            const sameKeys = oldKeys.every((k, i) => k === newKeys[i]) && oldKeys.length === newKeys.length;
            const sameValues = oldKeys.every(k => $scope.oldAddress[k] === $scope.newAddress[k]);
            addressesEqual = sameKeys && sameValues;
          }
        }

        return !addressesEqual;
      }

      setOldAddress();
      resetAddressObj();

      // This action must take place on save in case the user alters parts of
      // the PAC response
      function formatAddress(addr) {
        // Get only the keys we want in the proper order
        const keyz = ['addr1', 'addr2', 'city', 'state', 'zip', 'country'];
        // Map all the values into an array, filter out falsey vals, then join.
        return keyz.map(k => addr[k]).filter(v => !!v).join(", ");
      }

      function setModelValue(addr) {
        // Returns "" if user clears input, which fails `validate-with="required"`.
        if ($scope.formattedOnly) {
          return addr.formatted;
        }
        // If they clear out the address input, return null.
        // Fails validators. CANNOT return undefined because mat input won't
        // actually set a model to `undefined`.
        // eslint-disable-next-line eqeqeq
        if (addr.addr1 == null) return null;
        // Return addr object if !formattedOnly && input hasn't been cleared
        return addr;
      }

      $scope.onCancel = function() {
        // Reset $scope constants
        $scope.intentToEdit = false;
        $scope.placeChanged = false;
        $scope.addressEdited = false;
        // Reset Model
        resetAddressObj();
        // Reset label
        $scope.$parent.label = $scope.oldLabel;
        // Reset form
        $scope.locationForm.$setPristine();
      };

      $scope.onSave = function() {
        // Save is disabled until $scope.newAddress exists
        $scope.newAddress.formatted = formatAddress($scope.newAddress);
        // Return formatted address or address obj depending on fromattedOnly.
        // We never save $scope.newAddress, we only copy it to the model. This
        // method gives us the flexibility and also makes it possible to cancel
        // an editing session.
        $scope.model = setModelValue($scope.newAddress);
        // Reset newAddress and oldAddress to match the new model value.
        setOldAddress();
        resetAddressObj();
        $scope.intentToEdit = false;
        $scope.placeChanged = false;
        $scope.addressEdited = false;
        // Reset label
        $scope.$parent.label = $scope.oldLabel;
        // Reset form
        // We get locationForm from the <form> elm whose name is 'locationForm'
        $scope.locationForm.$setPristine();
      };

      $scope.incomplete = function() {
        // Recreate this on each run so we don't operate on cached values
        const subFields = [$scope.newAddress.city, $scope.newAddress.state, $scope.newAddress.zip, $scope.newAddress.country];

        // If the input isn't required and there isn't any address entered, let
        // the user save a `null` address.
        if (!$scope.required && !$scope.newAddress.addr1) return false;
        // Require all subfields if we enter anything in addr1 This prevents people from entering an
        // entire address on the first line for non-required inputs.
        else if ($scope.newAddress.addr1) {
          // Check that all the subfields are filled
          return !subFields.every(f => !!f);
        // If required...
        } else if ($scope.required) {
          // Check that all the fields are filled
          return !($scope.newAddress.addr1 && subFields.every(f => !!f));
        }

        return true;
      };

      $scope.$watch('newAddress', (fresh, stale) => {
        // Go no further if this is first digest.
        if (fresh === stale) return;
        if (addressChanged()) {
          // Change the label if they're editing the input.
          $scope.$parent.label = 'Street Address';
          $scope.model = null;
          return $scope.addressEdited = true;
        }
      }, true);

      // Make sure that if we change our outer model, we apply those changes to the inner models
      $scope.$watch('model', (fresh, stale) => {
        // Go no further if this is first digest.
        if (fresh === stale) return;
        $scope.oldAddress = fresh;
        if (addressChanged()) resetAddressObj();
      }, true);

    }
  };
});
