lib.registerState "home.whereTo",
  url: "/where-to"
  params:
    username: undefined
  templateUrl: "states/home/where-to/where-to.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Select an organization"
    session: ($http, $state) ->
      $http.get("/api/session?reaccess").then (result) ->
        # Get the passthrough url and go there if they have one
        passthrough = sessionStorage.getItem 'passthroughURL'
        if !result.data?.access or !result.data?.userID
          window.location.href = "/!/dn/login"
          return
        else if passthrough
          # Delete the passthrough if we're actually authenticated and ready to navigate
          sessionStorage.removeItem 'passthroughURL'
          window.location.href = passthrough;
        result.data
    user: (session, User, $state) ->
      new User(session.userID).load().then (user) ->
        if user.forcePasswordReset
          return $state.go "home.account.security", {userID: user.id, force: true}
        return user
    organizations: ($http, session) ->
      if !session?.access?.operator then return null
      $http.get("/api/groups?organization=true", {cache:true}).then (organizations) ->
        _.map _.sortBy(organizations.data, "name"), (o) -> { value: o.id, label: o.name }
    invitation: ($location, ProfilesUser, $q, session) ->
      d = $q.defer()
      if !$location.search().i then return d.resolve(null)
      inviteRequest = ProfilesUser.byInvite $location.search().i
      inviteRequest.then (invite) ->
        d.resolve(invite)
        return
      inviteRequest["catch"] ->
        d.resolve "ERROR"
        return
      return d.promise
    testAccount: ($http, session, $state) ->
      if $state.previous?.name is ("home.auth.email" or "home.auth.login")
        $http.get("/api/users/#{session.userID}/test-account").then (result) ->
          result.data[0]
    accountManagers: ($state, $http, session) ->
      $http.get("/api/account-managers").then (managers) ->
        managers.data

  controller: ($filter, $http, $interval, $rootScope, $scope, $state, Group, WhatDivision, accountManagers, invitation, organizations, registration, session, testAccount, user) ->
    if !session.access then return $state.go "home.auth"

    $scope.oper = { organizations: organizations, selected: 21437 }
    $scope.operOrgs = organizations
    $scope.operOrg ?= 21437

    $scope.access = session.access
    $scope.organizations = session.access.organizations
    $scope.state = $state
    $scope.division = WhatDivision
    $scope.year = moment().year()
    $scope.registration = registration

    $scope.hideClassicBuilderFlag = window.lib.featureFlagClient.isEnabled('hideClassicBuilder')
    $scope.cubesLink = if $scope.hideClassicBuilderFlag then 'manager.settings.builder' else 'manager.settings.questionnaire'

    $scope.shortcuts = {
      portals: [
        {
          text: 'Oper Portal'
          state: 'oper'
          icon: 'cogs'
        }
        {
          text: 'Patient Portal'
          state: 'patient'
          icon: 'child'
        }
        {
          text: 'Provider Portal'
          state: 'manager'
          icon: 'user-md'
        }
      ]
      locations: [
        {
          text: 'Registration Setup'
          state: 'manager.groups.registration'
          icon: 'sitemap'
        }
        {
          text: 'Finances (Organization Ledger)'
          state: 'manager.finances.orgLedger'
          icon: 'dollar-sign'
        }
        {
          text: 'Protection Plan'
          state: 'manager.travelProtection'
          icon: 'shield-alt'
        }
        {
          text: 'Health Log (View)'
          state: 'manager.healthLog'
          icon: 'book'
        }
        {
          text: 'eMAR'
          state: 'manager.emar'
          icon: 'medkit'
        }
        {
          text: 'Settings (General)'
          state: 'manager.settings'
          icon: 'cog'
        }
        {
          text: 'Settings (Builder)'
          state: $scope.cubesLink
          icon: 'cubes'
        }
        {
          text: 'Settings (Billing)'
          state: 'manager.settings.billing'
          icon: 'credit-card'
        }
        {
          text: 'Setup'
          state: 'manager.setup'
          icon: 'map'
        }
        {
          text: 'Patient Importer'
          state: 'manager.import.profile-import'
          icon: 'upload'
        }
      ]
    }

    $scope.quickjump = (destination) ->
      $state.go destination, {orgID: $scope.oper.selected, groupID: $scope.oper.selected}

    $scope.hasPatients = (org) -> org.patients and org.orphans and (org.patients.length + org.orphans.length > 0)
    $scope.hasProviders = (org) -> org.providers?.length > 0

    $scope.showPortals = (org) ->
      portals = -1
      if $scope.hasPatients(org) then portals += 1
      if $scope.hasProviders(org) then portals += 1
      !!portals

    $scope.takeMeTo = (where, org) ->
      switch where
        when "operator" then return $state.go "oper"
        when "patient" then return $state.go "patient", { orgID: org.id }
        when "manager" then return $state.go "manager", { orgID: org.id }

    $scope.myLastHope = (org) ->
      if $scope.hasProviders(org) then return "manager"
      return "patient"

    $scope.hasInvites = ->
      amount = session.access.invites.length
      if $state.params.i && invitation isnt "ERROR" then amount += 1
      amount

    if $scope.hasInvites()
      phrase = $rootScope.division.patient
      window.swal
        title: if $scope.hasInvites() > 1 then "New Invites" else "New Invite"
        text: """
          Visit your account settings to manage
          #{if $scope.hasInvites() > 1 then "" else "an"}
          additional
          #{if $scope.hasInvites() > 1 then phrase.plural else phrase.singular}.
        """.replace(/\n/g, " ")
        type: "success"
        showCancelButton: true
        confirmButtonColor: "#5CA100"
        confirmButtonText: "Account Settings"
        cancelButtonText: "Skip"
      , (visit) ->
        return unless visit
        return $state.go "home.account.profiles", { userID: session.userID }

    # Prevent redirects from whereTo unless the browser is supported
    if window.lib.browserCheck && window.lib.browserCheck.supported
      if $state.current.name is "home.whereTo"
        if $scope.registration
          $state.go "patient", { orgID: $scope.registration.id, r: $state.params.r.split(/\s+/)[1] }
        else if !session.access.organizations.length
          $state.go "home.account.profiles", { userID: session.userID }
        # Immediate redirect when only one option
        else if ($scope.hasInvites() or
        $scope.access.operator or
        $scope.organizations?.length > 1 or
        $scope.organizations?.length is 0 or
        $scope.showPortals $scope.organizations[0])
          # Some noop just for clarity
          () -> return
        else
          org = $scope.organizations[0]
          $scope.takeMeTo $scope.myLastHope(org), org
    else if !window.lib.browserCheck
      swal {
        type: "warning"
        title: "Couldn't Detect Browser"
        text: "
        Unless you're using one of our
        <a href=\"https://www.#{division.domain}/supported-browsers/\" target=\"_blank\">
          supported browsers</a> you may experience problems.
        "
        html: true
      }

    splashes = [
      "blue-lake"
      "calm-lake"
      "canoe"
      "foggy-docks"
      "foggy-hillside"
      "hiking-on-boards"
      "lake-at-dusk"
      "lake-over-trees"
      "magical-forest"
      "magical-lake"
      "rocky-waterfall"
      "stairway-to-nature"
      "sunny-valley"
    ]
    randomSplash = splashes[_.random(0,splashes.length-1)]
    $scope.splashUrl = (org) ->
      url = "stock/campdoc/#{randomSplash}.jpg"
      if !org?.branding then return url
      if org.branding.splash?.campdoc then url = org.branding.splash[$scope.division.lcName]
      if org.branding.customSplash then url = "custom/#{$scope.division.lcName}/#{org.id}.jpg"
      url

    $scope.baseUrl = window.lib.brandingConfig.values.base

    # Are you a test account?
    if testAccount?.testaccount?.toString() is "true"
      deletionDate = moment(testAccount.deletedate).format("MM/DD/YYYY")
      window.swal
        title: "Test Account"
        text: """
          You are accessing a test account.

          On #{deletionDate}, this account will be deactivated, along with all profiles and registrations linked to this account.

          Please note that you will not be billed for any test accounts.

          If you believe this is an error, please contact #{division.name} support.
        """
        type: "warning"
        confirmButtonColor: "#5CA100"
        confirmButtonText: "Confirm"
        allowOutsideClick: true

    # If org is in setup mode, user will have endless redirect loop.
    if $state.previous?.name is "manager"
      new Group($state.previous.params.orgID).load().then (group) ->
        return unless group.properties.setupMode or group.compiledProperties.setupMode
        ac = _.find accountManagers, (accMan) ->
          +accMan.id is +group.properties?.accountManager
        window.swal
          type: "info"
          title: "Setup Mode"
          text: """
            Your account is currently in setup mode, and the provider portal is temporarily disabled.

            If you have any questions, please contact your account manager, #{ac.name}, at #{ac.email} or #{ac.phone}.
          """

    if _.some(session?.access?.organizations, (o) -> o.providers.length) && !user.prevLogin
      window.swal({
        title: "Welcome to #{$scope.division.name}!"
        text: """
          If you're not sure how to get started, check out our
          <a href="https://training.docnetwork.org/" target="_blank">Training Resources</a>
          page to learn how to use the app.
          <br /><br />

          If you ever need help, you can click the <i class="fas fa-fw fa-question-circle"></i>
          icon in the upper right corner to go to our
          <a href="https://support.#{$scope.division.domain}" target="_blank">Support Page</a>.
          <br /><br />
        """
        type: "info"
        html: true
        confirmButtonText: "Get Started!"
        allowOutsideClick: true
      })
      user.prevLogin = moment().toISOString()
      user.save(['prevLogin'])

    if _.some(session?.access?.organizations, (o) -> o.providers.length) && user.prevLogin
      $http.get("/api/announcements?date=#{user.prevLogin}").then (result) ->
        announcements = result.data
        if !_.isEmpty(announcements)
          announceConcat = '<ul style="text-align:left; margin: 0 auto">'
          _.map announcements, (a) ->
            announceConcat = announceConcat + "<li>" + a.message + "</li><br/>"
          announceConcat += '</ul>'

          announceConcat = $filter('md')(announceConcat)
          window.swal(
            {
              title: "Product Announcements"
              text: announceConcat
              type: "info"
              html: true
              confirmButtonText: "View"
              allowOutsideClick: true
              showCancelButton: true
              cancelButtonText: "Close"
            }, (confirm) ->
              if confirm then window.open "http://www.campdoc.com/product-announcements"
              user.prevLogin = moment().toISOString()
              user.save(['prevLogin'])
          )

    $rootScope.$on "$stateChangeSuccess", (e, to, toParams, from, fromParams) ->
      if to.name == "home.whereTo"
        $(".top-banner").removeClass("red blue")
        $("#topbar .upper").removeClass("red blue")
        $("#sidebar .brand").removeClass("red blue")
