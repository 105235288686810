'use strict';

angular.module('dn').directive('exportBuilder', function() {
  return {
    restrict: 'A',
    templateUrl: 'directives/export-builder/export-builder.directive.html',
    scope:  {
      area: '@',
      profiles: '=',
      organization: '=',
      selectedProfiles: '='
    },
    controller: function($http, $location, $scope) {
      const orgID = $scope.organization.id;
      let healthProfile;
      if (_.has($scope.organization, 'properties.branding.text.healthProfileAlternateName')) {
        healthProfile = $scope.organization.properties.branding.text.healthProfileAlternateName;
      } else {
        healthProfile = 'Health Profile';
      }

      $scope.isFullTextSearchEnabled = () => {
        return window.lib.featureFlagClient.isEnabled('FullTextSearchProfiles');
      };

      $scope.report = {
        date: moment().format('YYYY-MM-DD'),
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
      };

      function flatten(questions, array) {
        if (questions && questions.length === 0) return;
        _.map(questions, function(q) {
          if (['text', 'select'].includes(q.type)) {
            array.push({ label: q.label, value: q.id });
          }
          return flatten(q.subQuestions, array);
        });
        return array;
      };

      $scope.columnOneQuestions = flatten(($scope.organization.newQuestionnaire || {}).questions, []);
      $scope.columnTwoQuestions = flatten(($scope.organization.newQuestionnaire || {}).questions, []);

      $scope.presets = [
        {
          label: 'All Notifications',
          value: 'notifications',
          optgroup: 'Profiles'
        },
        {
          label: 'All Profiles',
          value: 'all-profiles',
          optgroup: 'Profiles'
        },
        {
          label: 'All Registrations',
          value: 'all-registrations',
          optgroup: 'Profiles'
        },
        {
          label: 'All Users',
          value: 'users',
          optgroup: 'Profiles'
        },
        {
          label: 'Bounced Emails',
          value: 'bounced-emails',
          optgroup: 'Profiles'
        },
        {
          label: 'Check-In Report',
          value: 'check-in',
          optgroup: 'Profiles'
        },
        {
          label: 'Photo Roster',
          value: 'photo-roster',
          optgroup: 'Profiles'
        },
        {
          label: 'Duplicates',
          value: 'duplicates',
          optgroup: 'Profiles'
        },
        {
          label: 'Unaccepted Invites',
          value: 'unaccepted',
          optgroup: 'Profiles'
        },
        {
          label: 'Provider Permissions',
          value: 'roles',
          optgroup: 'Profiles'
        },
        {
          label: 'Completion Summary',
          value: 'completeness',
          optgroup: healthProfile
        },
        {
          label: 'Notes',
          value: 'notes',
          optgroup: healthProfile
        },
        {
          label: 'Review Summary',
          value: 'reviews',
          optgroup: healthProfile
        },

        {
          label: 'All Allergies',
          value: 'all-allergies',
          optgroup: 'Allergies',
          lookUp: 'allergies'
        },
        {
          label: 'Drug Allergies',
          value: 'drug-allergies',
          optgroup: 'Allergies',
          lookUp: 'allergies'
        },
        {
          label: 'Environmental Allergies',
          value: 'env-allergies',
          optgroup: 'Allergies',
          lookUp: 'allergies'
        },
        {
          label: 'Food Allergies',
          value: 'food-allergies',
          optgroup: 'Allergies',
          lookUp: 'allergies'
        },

        {
          label: 'Emergency Contacts',
          value: 'emergencyContacts',
          optgroup: 'Health Form',
          lookUp: 'emergencyContacts'
        },
        {
          label: 'Immunizations',
          value: 'immunizations',
          optgroup: 'Health Form',
          lookUp: 'immunizations'
        },
        {
          label: 'Insurances',
          value: 'insurances',
          optgroup: 'Health Form',
          lookUp: 'insurances'
        },
        {
          label: 'Primary Care Providers',
          value: 'primaryProviders',
          optgroup: 'Health Form',
          lookUp: 'primaryProviders'
        },

        {
          label: 'All Medications',
          value: 'all-medications',
          optgroup: 'Medications',
          lookUp: 'medications'
        },
        {
          label: 'Medication Administration Records (MARs)',
          value: 'mars',
          optgroup: 'Medications',
          lookUp: 'medications'
        },
        {
          label: 'Medication Confirmation Forms',
          value: 'med-confirmation',
          optgroup: 'Medications',
          lookUp: 'medications'
        },
        {
          label: 'Medication Counts',
          value: 'rxCounts',
          optgroup: 'Medications',
          lookUp: 'medications'
        },
        {
          label: 'Medication Labels',
          value: 'labels',
          optgroup: 'Medications',
          lookUp: 'medications'
        },
        {
          label: 'Medication Summary',
          value: 'medication-summary',
          optgroup: 'Medications',
          lookUp: 'medications'
        },
        {
          label: 'Authorized OTCs',
          value: 'otc',
          optgroup: 'OTCs',
          lookup: 'otc'
        },
        {
          label: 'Unauthorized OTCs',
          value: 'unauthorized-otc',
          optgroup: 'OTCs',
          lookup: 'otc'
        },
      ];

      $scope.reportDescriptions = {
        'all-allergies': 'A list of profiles that have either a food, drug, or environmental allergy. CSV includes basic demographic fields as well as Allergy Name, Reaction, Risk for Anaphylaxis, Epi-Pen status. Each allergy will be listed on a separate row. PDF includes a list of profiles and allergies grouped by profile name.',
        'all-medications': 'A list of any profile that has medications. CSV includes basic demographic fields as well as medication name, strength, and detailed dosing instructions. Each medication will be listed on a separate row. PDF includes a list of profiles and medications grouped by profile name.',
        'bounced-emails': 'A list of bounced email addresses. CSV includes basic demographic fields as well as the Bounced Email Address. Each bounced email will be listed on a separate row.',
        'check-in': "You can include two custom columns in a landscape check-in report (one for portrait-mode), and each column can have as many answers to text-only questions that you'd like. An example would be 'Approved Pick Up' with the values of pick up questions. Options include a daily vs weekly types, including profile completeness and adding custom columns.",
        'completeness': 'A list of profiles broken down by complete and incomplete steps. CSV includes basic demographic fields as well as each step and whether or not it is complete or incomplete.',
        'med-confirmation': 'Report includes medication confirmation forms for any profile that has medications. Typically downloaded and printed by parent/guardian in the Patient Portal, but backup copies available in this report.',
        'duplicates': 'Report can be selected to match duplicates based on Name and Birthday or Name alone. CSV includes basic demographic fields as well as Profile ID and Completeness.',
        'drug-allergies': 'A list of profiles that have a drug allergy. CSV includes basic demographic fields as well as Allergy Name, Reaction, Risk for Anaphylaxis, Epi-Pen status. Each allergy will be listed on a separate row. PDF includes a list of profiles and allergies grouped by profile name.',
        'emergencyContacts': 'A list of emergency contacts for each profile. CSV includes basic demographic fields as well as name, relationship, phone, email, and address. Each emergency contact will be listed on a separate row.',
        'env-allergies': 'A list of profiles that have an environmental allergy. CSV includes basic demographic fields as well as Allergy Name, Reaction, Risk for Anaphylaxis, Epi-Pen status. Each allergy will be listed on a separate row. PDF includes a list of profiles and allergies grouped by profile name.',
        'food-allergies': 'A list of profiles that have a food allergy. CSV includes basic demographic fields as well as Allergy Name, Reaction, Risk for Anaphylaxis, Epi-Pen status. Each allergy will be listed on a separate row. PDF includes a list of profiles and allergies grouped by profile name.',
        'immunizations': 'A list of self-reported immunizations for each profile. CSV includes basic demographic fields as well as immunization name, dose number and date. Each immunization dose will be listed on a separate row.',
        'insurances': 'A list of insurance for each profile. CSV includes basic demographic fields as well as company name, phone, group number, policy number, policy holder, BIN, and PCN.',
        'labels': 'Report creates printable medication labels using Avery label types.',
        'mars': 'Report includes a printable medication administration record to be used when the the eMAR is not an available option. Options include either a one week of four week format.',
        'medication-summary': 'A list of any profile that has medications. PDF includes a list of profiles grouped by time of day.',
        'notes': 'A list of profiles and their notes. Report can be selected to include all notes or a specific note category. CSV includes basic demographic fields as well as Category, Priority, Provider Name and timestamp. Each note will be listed on a separate row. PDF includes a list of profiles and their notes grouped by profile name.',
        'notifications': 'A list of all notifications sent in the past year. CSV includes basic demographic fields as well as Email Address, User Name, Email Subject, Notification Type, Sent Date, and Opened Status. Each notification will be listed on a separate row.',
        'all-registrations': 'A list of registration data. CSV includes basic demographic fields as well as Group ID, Registration Type, Created Date, Waitlist Status, and Group Names. Each registration will be listed on a separate row. PDF includes a list of profiles grouped by Registration Name.',
        'all-profiles': 'A list of basic demographic data. CSV includes Name, Date of Birth, Sex, Age, Identifier, Completeness, Users, Registrations and Tags. PDF includes Name, Date of Birth and Sex.',
        'payment-plans': 'A list of profiles that have active payment plans. CSV includes basic demographic fields as well as payment amount, payment day, remaining payments, payment plan start date, and total payment amount.',
        'primaryProviders': 'A list of healthcare providers for each profile. CSV includes basic demographic fields as well as provider name, phone, fax, and address.',
        'reviews': 'A list of profiles and their review statuses. CSV includes basic demographic fields as well as each review category, review status and timestamp.',
        'rxCounts': 'A list of any profile that has tracked medications. CSV includes basic demographic fields as well as medication name and medication count. Each tracked medication will be listed on a separate row.',
        'users': 'A list of detailed user data. CSV includes basic demographic fields as well as Email, Name, Last Login, Phone, and Mailing Address. Each user will be listed on a separate row.',
        'unaccepted': 'A list of all unaccepted user invites. CSV includes basic demographic fields as well as the Email Address and Bounced Status. Each unaccepted invite will be listed on a separate row.',
        'attendance': 'A list of attendance events. CSV includes basic demographic fields and fields with information on both the provider and trusted contact who checked the participant in and out.',
        'trusted_contacts': 'A list of all trusted contacts. CSV includes basic demographic fields as well as Email, Name, Phone, and Address. Each trusted contact will be listed on a separate row.',
      };

      // For reg orgs, also allow account balances and payment plans reports
      if ($scope.organization.shortName) {
        $scope.presets.push({
          label: 'Account Balances',
          value: 'balances',
          optgroup: 'Finances'
        });
        $scope.presets.push({
          label: 'Payment Plans',
          value: 'payment-plans',
          optgroup: 'Finances'
        });
      }

      // If Attendance is enabled, add attendance report
      let attendanceEnabled = false;
      if ($scope.organization.properties.features && $scope.organization.properties.features.attendance && $scope.organization.properties.features.attendance.enabled) {
        attendanceEnabled = true;
      }
      if (attendanceEnabled) {
        $scope.presets.push({
          label: 'Attendance',
          value: 'attendance',
          optgroup: 'Attendance',
        });
      }

      // If Text Alerts are enabled, add those reports
      if ($scope.organization.properties.smsEnabled) {
        $scope.presets.push({
          label: 'Text Message Users',
          value: 'smsUsers',
          optgroup: 'Text Message Alerts'
        });
        $scope.presets.push({
          label: 'Text Message Notifications',
          value: 'smsNotifications',
          optgroup: 'Text Message Alerts'
        });
      }

      // If Attendance is not enabled, remove the trusted contacts report
      if (!attendanceEnabled) {
        $scope.presets = _.filter($scope.presets, (p) => p.value !== 'trusted_contacts');
      } else {
        $scope.presets.splice(10, 0, {
          label: 'Trusted Contacts',
          value: 'trusted_contacts',
          optgroup: 'Profiles'
        });
      }

      // Grab all of the unique question types from the org's questionnaire to
      // see what modules they're using
      function typeGrabber(questions) {
        const unique = [];
        if (!questions) return unique;
        // Recursively grab question types
        function getTypes(questions) {
          questions.forEach((q) => {
            if (q.type && !unique.includes(q.type)) unique.push(q.type);
            if (q.subQuestions) return getTypes(q.subQuestions);
          });
        }
        getTypes(questions);
        return unique;
      };

      const qTypes = typeGrabber(($scope.organization.newQuestionnaire || {}).questions);

      // limit the module reports available based on what modules are used
      $scope.presets = _.filter($scope.presets, (p) => {
        return (!p.lookUp || _.includes(qTypes, p.lookUp));
      });

      // Format choices, though PDF is disabled for now
      $scope.formats = [
        { label: 'CSV', value: '.csv' },
        { label: 'PDF', value: '.pdf' }
      ];
      $scope.format = '.csv';

      $scope.report.orientation = 'landscape';
      $scope.orientations = [
        { label: 'Landscape', value: 'landscape'},
        { label: 'Portrait', value: 'portrait'}
      ];

      $scope.weeks = '1';
      $scope.weekChoices = [
        { label: 'One Week MAR', value: '1' },
        { label: 'Four Week MAR', value: '4' }
      ];

      $scope.report.includeCompleteness = false;
      $scope.includeCompletenessChoices = [
        { label: 'No', value: false },
        { label: 'Yes', value: true }
      ];

      $scope.report.weekly = false;
      $scope.weeklyChoices = [
        { label: 'Daily', value: false },
        { label: 'Weekly', value: true }
      ];

      // Basic configuration for generating the url
      // for different report types. Those reports
      // whose 'include' isn't an object are still
      // on old collections: e.g., otc. See the custom
      // urls for which reports are still on old
      const baseConfigs = {
        '.pdf': {
          'all-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
          },
          'food-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
            filter: '[allergies].type(is:food)'
          },
          'drug-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
            filter: '[allergies].type(is:drug)'
          },
          'env-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
            filter: '[allergies].type(is:environmental)'
          },
          'all-medications': {
            include: [{ collection: 'medications', schema: 'public', optional: false }],
            filter: '[profiles_has_medications].has_medications(is:true)|[medications].otcID(null:true)'
          },
          'medication-summary': {
            include: [{ collection: 'medications', schema: 'public', optional: false }],
            filter: '[profiles_has_medications].has_medications(is:true)|[medications].otcID(null:true)',
            summary: true
          },
          'otc': {
            include: 'otc',
            filter: '[otc]',
            format: 'pdf'
          },
          'unauthorized-otc': {
            include: 'otc',
            filter: '[otc]|unauthorized',
            format: 'pdf'
          },
          'all-registrations': {
            include: [{ collection: 'registrations_phase_view', schema: 'collections', optional: false }],
            summary: true
          },
          'all-profiles': {
            include: []
          },
          'photo-roster': {
            include: []
          },
          'notes': {
            include: [{ collection: 'notes_view', schema: 'reports', optional: false }]
          }
        },

        '.csv': {
          'all-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
          },
          'food-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
            filter: '[allergies].type(is:food)'
          },
          'drug-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
            filter: '[allergies].type(is:drug)'
          },
          'env-allergies': {
            include: [{ collection: 'allergies', schema: 'public', optional: false }],
            filter: '[allergies].type(is:environmental)'
          },
          'bounced-emails': {
            include: [{ collection: 'profilesUsers', schema: 'public', optional: false }],
            filter: '[profilesUsers].inviteBounce(is:true)'
          },
          'notifications': {
            include: [{ collection: 'notifications_view', schema: 'reports', optional: false }],
          },
          'all-medications': {
            include: [{ collection: 'medications', schema: 'public', optional: false }],
            filter: '[profiles_has_medications].has_medications(is:true)|[medications].otcID(null:true)'
          },
          'otc': {
            include: [{ collection: 'medications', schema: 'public', optional: false }],
            filter: '[otc]',
            format: 'csv'
          },
          'balances': {
            include: [{ collection: 'crebits_view', schema: 'collections', optional: false }],
          },
          'payment-plans': {
            include: [
              {
                collection: 'paymentPlans',
                schema: 'public',
                optional: false,
              },
              {
                collection: 'crebits_view',
                schema: 'collections',
                optional: false,
              },
            ]
          },
          'all-registrations': {
            include: [{ collection: 'registrations_phase_view', schema: 'collections', optional: false }],
          },
          'notes': {
            include: [{ collection: 'notes_view', schema: 'reports', optional: false }],
          },
          'reviews': {
            include: [{ collection: 'reports_reviews_view', schema: 'reports', optional: true }],
          },
          'users': {
            include: [{ collection: 'users', schema: 'public', optional: false }],
          },
          'completeness': {
            include: [{ collection: 'steps_complete_view', schema: 'collections', optional: true }],
          },
          'immunizations': {
            include: [{ collection: 'immunizations', schema: 'public', optional: false }],
          },
          'primaryProviders': {
            include: [{ collection: 'primaryProviders', schema: 'public', optional: false }],
          },
          'insurances': {
            include: [{ collection: 'insurances', schema: 'public', optional: false }],
          },
          'emergencyContacts': {
            include: [{ collection: 'emergencyContacts', schema: 'public', optional: false }],
          },
          'rxCounts': {
            include: [{ collection: 'med_count_view', schema: 'reports', optional: false }],
            filter: '[medications]|has.medications(is:true)'
          },
          'unaccepted': {
            include: [{ collection: 'profilesUsers', schema: 'public', optional: false }],
            filter: '[profilesUsers].userID(is:null)'
          },
          'attendance': {
            include: [{ collection: 'attendance_report_view', schema: 'reports', optional: false }],
          },
        }
      };

      // Populate a third section of options for query parameters
      // when one of these presets is selected.
      $scope.extras = {
        'notes': {
          label: 'Category',
          choices: [ { label: 'All', value: 'all' } ],
          appendToFilter: '[reports.notes_view].category(is:___)'
        },
        'mars': {
          label: 'Weeks',
          query: 'weeks',
          choices: [
            { label: 'One Week MAR', value: '1' },
            { label: 'Four Week MAR', value: '4' }
          ]
        },
        'duplicates': {
          label: 'Match Type',
          query: 'includeBirthday',
          choices: [
            { label: 'Match with Birthday', value: 'true' },
            { label:'Name Only', value: 'false' }
          ]
        }
      };

      const quantities = _.map(_.range(1, 31), n => ({ label: n, value: n }));

      const today = moment().format('YYYY-MM-DD');

      // Date option for MAR
      $scope.report.date = today;

      // Different options for medication labels
      $scope.labelOptions = [
        {
          label: 'Show As Needed Medications',
          query: 'asNeeded',
          value: 'all',
          choices: [
            { label: "All, Including 'As Needed'", value: 'all' },
            { label: 'Only As Needed', value: 'only' },
            { label: 'No As Needed', value: 'no' }
          ]
        },
        {
          label: 'Label Type',
          query: 'labelType',
          value: '5160',
          choices: $scope.organization.labelTypes
        },
        {
          label: 'One Label Per Dose?',
          query: 'perDose',
          value: 'yes',
          choices: [ { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' } ]
        },
        {
          label: 'Quantity',
          query: 'quantity',
          value: '1',
          choices: quantities
        },
        {
          label: 'Printing for Date',
          query: 'date',
          value: today,
          date: true
        }
      ];

      // Add note categories to extras options
      _.map(($scope.organization.properties || {}).noteCategories, category => $scope.extras.notes.choices.push({ label: category, value: category }));

      // These have their own routes and can only be exported via PDF
      $scope.customURLs = {
        'duplicates':        'duplicates.csv',
        'check-in':          'check-in-report',
        'med-confirmation':  'medication-confirmations',
        'labels':            'medication-labels',
        'mars':              'mars',
        'otc':               'otc-report',
        'unauthorized-otc':  'otc-report',
        'roles':             'roles-report',
        'smsUsers':          'sms-users',
        'smsNotifications':  'sms-notifications',
      };

      // Reports that do NOT offer a PDF option
      const csvOnly = [
        'users',
        'bounced-emails',
        'notifications',
        'balances',
        'completeness',
        'duplicates',
        'reviews',
        'immunizations',
        'insurances',
        'unaccepted',
        'payment-plans',
        'primaryProviders',
        'emergencyContacts',
        'rxCounts',
        'smsUsers',
        'smsNotifications',
        'roles',
        'attendance',
        'trusted_contacts',
      ];

      // Reports that do NOT offer a CSV option
      const pdfOnly = [
        'medication-summary',
        'unauthorized-otc',
        'check-in',
        'med-confirmation',
        'labels',
        'mars',
        'photo-roster'
      ];

      function customUrl() {
        return $scope.customURLs[$scope.preset] ? $scope.customURLs[$scope.preset] : false;
      }

      // Disable output selection if one of our PDF only reports is selected
      $scope.pdfOnly = () => {
        return _.includes(pdfOnly, $scope.preset);
      };

      // Disable output selection if one of our CSV only reports is selected
      $scope.csvOnly = () => {
        return _.includes(csvOnly, $scope.preset);
      };

      const POSSIBLE_CONFLICTS = [
        { filter: 'allergies', display: 'allergies' },
        { filter: 'medication', display: 'OTC or medications' },
      ];
      function hasPossibleConflicts(preset) {
        // Use `some` so we finish as soon as we have a conflict
        const conflict = POSSIBLE_CONFLICTS.some((c) => {
          if (preset.indexOf(c.filter) > -1) {
            $scope.possibleConflictingFilters = c.display;
            return true;
          }
          return false;
        });
        if (!conflict) $scope.possibleConflictingFilters = false;
      }

      $scope.$watch('preset', (preset, stale) => {
        $scope.possibleConflictingFilters = false;
        if (preset === stale) return;
        // Default to CSV unless its a PDF-only report
        $scope.pdfOnly() ? $scope.format = '.pdf' : $scope.format = '.csv';
        // Set the report description
        $scope.report.description = $scope.reportDescriptions[preset];
        $scope.report.name = $scope.presets.find(p => p.value === preset).label;
        // Check for conflicting filters
        hasPossibleConflicts(preset);
      }, true);

      // This function sets up the report generation payload for POSTing
      function configurePayload() {

        const baseDefaults = {
          // For Everything
          format: $scope.format,
          type: 'all-profiles',
          name: 'All Profiles Report',
          description: 'ALL THE PROFILES!!1!!1!',
          requestedBy: 'Bingo Bill~',
          filters: [],
          filterNames: null,
          profileIDs: [],
          // For Some Things
          include: null,
          sortBy: null,
          groupBy: null,
          summary: false,
          reviewTypes: $scope.organization.reviewTypes || [],
          // A report.date (same model) is specified for both mars and check-in.
          // If left null, it will be assigned a new Date() on the server.
          date: null,
          // From Date and To Date are specified for the attendance report
          fromDate: null,
          toDate: null,
        };

        const checkInOnly = {
          questions: [
            {
              name: null,     // question title
              question: null  // questionID
            },
          ],
          includeBirthday: false,
          includeCompleteness: false,
          weekly: false,
        };

        // eslint-disable-next-line no-unused-vars
        const campGramsOnly = {
          tagSort: null,
          printed: null,
          gramStart: null,
          gramEnd: null
        };

        // Configure the payload object.
        let reportConfig = _.cloneDeep($scope.report);
        let reportDefaults = _.cloneDeep(baseConfigs[$scope.format][$scope.preset]);
        // 03/27/18 - None of the custom cases in this switch are useful yet.
        // Those reports all have custom routes and will be rolled out later
        switch ($scope.preset) {
          case 'attendance':
            reportDefaults = _.merge(baseDefaults, reportDefaults);
            reportDefaults.filters.push(`[reports.attendance_report_view].check_in_day(>=:${$scope.report.fromDate})`);
            reportDefaults.filters.push(`[reports.attendance_report_view].check_in_day(<=:${$scope.report.toDate})`);
            break;
          case 'check-in':
            reportDefaults = _.merge(baseDefaults, reportDefaults, checkInOnly);
            // Selectize casts bools to string "0" and "1"; '!!+' casts back to bool
            reportConfig.includeCompleteness = !!+$scope.report.includeCompleteness;
            reportConfig.weekly = !!+$scope.report.weekly;
            break;

          case 'labels':
            const labelParameters = {};
            $scope.labelOptions.forEach(lo => labelParameters[lo.query] = lo.value);
            reportDefaults = _.merge(baseDefaults, reportDefaults, labelParameters);
            break;

          default:
            reportDefaults = _.merge(baseDefaults, reportDefaults);
            break;
        }
        /* eslint-enable indent */

        reportConfig = _.defaults(reportConfig, reportDefaults);
        reportConfig.type = $scope.preset;

        // Append List Builder filters
        const otherFilters = ($location.search().filters || '').split('|');
        if (otherFilters.length > 0) {
          reportConfig.filters = reportConfig.filters.concat(otherFilters);
        }
        // Report-specific filters are set on the .filter prop; push them in too
        const reportFilters = (reportConfig.filter || '').split('|');
        if (reportFilters.length > 0) {
          reportConfig.filters = reportConfig.filters.concat(reportFilters);
        }

        // For the note category selection, we want to append it like it's a
        // filter like [notes].category(is:___)
        if (($scope.extras[$scope.preset] || {}).appendToFilter) {
          if (reportConfig.param !== 'all') {
            reportConfig.name = `${reportConfig.param} ${reportConfig.name}`;
            const f = $scope.extras[$scope.preset].appendToFilter.replace('___', reportConfig.param);
            reportConfig.filters.push(f);
          } else {
            reportConfig.name = `All ${reportConfig.name}`;
          }
        }

        // If selected profiles
        if ($scope.profiles.some(p => p.selected) || $scope.selectedProfiles.length) {
          let selectedProfiles;
          if ($scope.isFullTextSearchEnabled() && $scope.selectedProfiles) {
            selectedProfiles = $scope.selectedProfiles;
          } else {
            selectedProfiles = $scope.profiles.reduce((acc, p) => {
              if (p.selected) acc.push(p.profiles.id);
              return acc;
            }, []);
          }
          // Add their ids into a new filter
          reportConfig.filters.push(`[profiles].id(any:${JSON.stringify(selectedProfiles)})`);
        }

        // Get applied filter names for PDF reports
        if ($scope.format === '.pdf') {
          reportConfig.filterNames = sessionStorage.getItem('appliedFilterNames').split('|');
        }

        // Enforce groupBy
        // CSVs need default groupBy values, PDFs only use it if it's a specific field
        if ($scope.format === '.csv') {
          const defaultGroupBy = ['stepsComplete', 'reviewStatus', 'reviewSummaryReport'];
          const groupBy = reportConfig.groupBy ? reportConfig.groupBy : reportConfig.include;
          if (!defaultGroupBy.includes(groupBy)) {
            reportConfig.groupBy = groupBy;
          }
        }

        // Use a ternary because !! catches empty strings while val || []
        reportConfig.include = !!reportConfig.include ? reportConfig.include : [];

        return Promise.resolve({payload: reportConfig, customUrl: customUrl()});

      } // END configurePayload

      function makePOST(postBody) {
        return new Promise(function(resolve, reject) {
          // eslint-disable-next-line no-unused-vars
          $http.post(`/api/organizations/${orgID}/new-reports`, postBody).then((success) => {
            // success
            return resolve();
          }, (err) => {
            // err
            return reject(err);
          });
        });
      }

      function makeGET(customUrl, reportName) {
        const url = genCustomUrl(customUrl, reportName);
        return new Promise(function(resolve, reject) {
          // eslint-disable-next-line no-unused-vars
          $http.get(url).then((success) => {
            // success
            console.log('Report Successful');
            return resolve();
          }, (err) => {
            // err
            return reject(err);
          });
        });
      }

      $scope.requesting = false;

      nameReport = function({ payload, customUrl }) {
        return new Promise(function(resolve, reject) {
          swal({
            title: 'Name Your Report',
            text: 'Please enter a name for your report download.',
            type: 'input',
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Submit',
            showCancelButton: true,
            inputValue: payload.name
          }, (input) => {
            if (!input) return reject(new Error('No Name Provided'));
            payload.name = input;
            return resolve({ payload, customUrl });
          });
        });
      };

      function requestReportErrorHandling(err) {
        $scope.requesting = false;
        const errorConfig = {
          title: 'Error Requesting Report',
          text: 'Please refresh the page and try again.',
          type: 'error',
          showCancelButton: false,
          closeOnConfirm: true,
        };
        if (err.status === 404) {
          errorConfig.type = 'info';
          errorConfig.title = 'No Data Found';
          errorConfig.text = (err.data || {}).message || err.statusText;
        } else if (err.status === 429) {
          errorConfig.text = 'Too many requests, please wait 15 minutes before retrying.';
        } else if (err.status === 504) {
        // 504 status comes from Cloudflare after 60 seconds
          errorConfig.type = 'info';
          errorConfig.title = 'Creating Report';
          errorConfig.text = 'Your report is taking longer than expected, but we are still working on it. If you do not see your report in 30 minutes, please try again.';
        } else if (err.message === 'No Name Provided') {
          errorConfig.title = err.message;
          errorConfig.text = 'Request your report again and be sure to name it.';
        }
        return swal(errorConfig);
      }

      function requestReportSuccess() {
        $scope.requesting = false;
        return swal({
          title: 'Report Request Successful',
          text: 'Head to the DOWNLOADS tab to check for your report. \n\n A standard report may take up to 5 minutes to generate. However, report times vary based on the complexity and number of profiles in the report.',
          type: 'success',
          showCancelButton: false,
          closeOnConfirm: true,
          allowOutsideClick: true
        });
      }

      $scope.requestReport = function() {
        return configurePayload().then(nameReport)
          .then(({ payload, customUrl }) => {
            $scope.requesting = true;
            // List builder notes filters look at [notes]. But the notes standard report
            // filters need to look at [reports.notes_view]. Doing this the naive way until
            // we have a better solution.
            if (payload.type === 'notes') {
              payload.filters = payload.filters.map((filter) => {
                return filter.replace('[notes]', '[reports.notes_view]');
              });
            }
            if (customUrl) {
              return makeGET(customUrl, payload.name);
            }
            return makePOST(payload);
          }).then(() => {
            return requestReportSuccess();
          })
          .catch((err) => {
            return requestReportErrorHandling(err);
          });
      };

      // This function generates URLs for GETting reports. It uses all the old
      // ways of doin' stuff, so it works just swell with the old route.
      function genCustomUrl(type, reportName) {
        let groupBy;
        if (!$scope.preset || !$scope.format) { return; }

        // Base URL
        let url = `/api/organizations/${orgID}/${type}`;

        // Draw from config
        const settings = _.cloneDeep(baseConfigs[$scope.format][$scope.preset]) || {};

        // include append
        url += `?include=${settings.include || ''}`;

        if (settings.sortBy) { url += `&sortBy=${settings.sortBy}`; }

        // groupBy appending
        // CSVs need default groupBy values, PDFs only use it if it's a specific field
        if ($scope.format === '.csv') {
          groupBy = settings.groupBy ? settings.groupBy : settings.include;
          if (!['stepsComplete', 'reviewStatus', 'reviewSummaryReport'].includes(groupBy)) {
            url += `&groupBy=${groupBy}`;
          }
        } else {
          if (settings.groupBy) {
            url += `&groupBy=${settings.groupBy}`;
          }
        }

        if (settings.summary) { url += '&summary=true'; }

        // Add extra params
        if ($scope.report.param && $scope.extras[$scope.preset] && !$scope.extras[$scope.preset].appendToFilter) {
          url += `&${$scope.extras[$scope.preset].query}=${$scope.report.param}`;
        }

        // CheckIn Report joins selected question IDs into a query parameter
        if ($scope.report.checkInColumnOne) {
          if ($scope.report.columnOneName) {
            url += `&columnOneName=${encodeURIComponent($scope.report.columnOneName)}`;
          }
          const questionsOne = $scope.columnOneQuestions.filter(q => q.include).map(q => q.value);
          if (questionsOne.length) { url += `&columnOne=${questionsOne.join('|')}`; }
        }

        if ($scope.report.checkInColumnTwo && ($scope.report.orientation === 'landscape')) {
          if ($scope.report.columnTwoName) { url += `&columnTwoName=${encodeURIComponent($scope.report.columnTwoName)}`; }
          const questionsTwo = _.map((_.filter($scope.columnTwoQuestions, { include: true })), 'value');
          if (questionsTwo.length) { url += `&columnTwo=${questionsTwo.join('|')}`; }
        }

        // Set a date and other values for the CheckIn report
        if ($scope.preset === 'check-in') {
          url += `&date=${$scope.report.date}`;
          // selectize stupidly casts bools to string "0" and "1"; use '!!+' to cast back to bool
          if (!!+$scope.report.includeCompleteness) { url += '&includeCompleteness=true'; }
          if (!!+$scope.report.weekly) { url += '&weekly=true'; }
          url += `&orientation=${$scope.report.orientation}`;
        }

        // Filter appending
        let filters = ($location.search().filters || '').split('|');
        if (settings.filter) { filters = filters.concat([settings.filter]); }

        // For the note category selection, we want to append it like it's
        // a filter like [notes].category(is:___)
        // eslint-disable-next-line eqeqeq
        if (($scope.extras[$scope.preset] != null ? $scope.extras[$scope.preset].appendToFilter : undefined) && $scope.report.param && ($scope.report.param !== 'all')) {
          const f = $scope.extras[$scope.preset].appendToFilter.replace('___', $scope.report.param);
          filters = filters.concat(f);
        }

        // Finally append the filters
        let filterString = filters.join('|');
        // The next two lines are temporary to make CSVs work properly until we
        // get CSV reports hooked up to new collections. These filters throw
        // errors with new collections - this is just the default behavior.
        if ($scope.preset === 'all-profiles')  filterString += '|[profiles]';
        if ($scope.preset === 'all-allergies') filterString += '|[allergies]';
        url += `&filters=${encodeURIComponent(filterString)}`;

        // Format appending for otc ... sorry
        if (settings.format) { url += `&format=${settings.format}`; }

        // Reports should ALWAYS have a reportName, but just be safe.
        if (reportName) {
          url += `&name=${encodeURIComponent(reportName)}`;
        } else {
          const name = _.find($scope.presets, { value: $scope.preset }).label;
          const paramName = $scope.report.param && ($scope.report.param !== 'false') ? `_${$scope.report.param}` : '';
          if (name) { url += `&name=${$scope.organization.name}_${name}${paramName}_Report`; }
        }

        // Add the label options as query parameters
        if ($scope.preset === 'labels') {
          _.map($scope.labelOptions, option => url += `&${option.query}=${option.value}`);
        }

        // add MAR date as query parameter
        if ($scope.preset === 'mars') {
          url += `&date=${$scope.report.date}`;
        }

        // Specify which profileIDs to include
        if ($scope.profiles.some(p => p.selected)) {
          const selectedProfiles = $scope.profiles.reduce((acc, p) => {
            if (p.selected) acc.push(p.profiles.id);
            return acc;
          }, []).join('|');
          url += `&profileIDs=${selectedProfiles}`;
        }

        // FilterNames useful only for PDF reports
        const filterNames = sessionStorage.getItem('appliedFilterNames');
        url += `&filterNames=${encodeURIComponent(filterNames)}`;

        // Always upload reports from the export-builder to S3
        url += '&sendToS3=true';

        return url;
      };

    } // END controller
  };
});
