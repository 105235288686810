lib.registerState "patient.profile.demographics",
  url: "/demographics"
  templateUrl: "states/patient/profile/demographics/demographics.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Demographics"
  controller: ($http, $scope, $state, Event, Profile, campgramsOnly, patients, session) ->

    return $state.go 'patient.profile.funkNotes' if campgramsOnly

    $scope.mergable = patients.length > 1
    $scope.tempSkip = session.skipDemographics

    $scope.sexes = _.map ["Female", "Male"], (g) -> { label: g, value: g }

    $scope.missing =
      givenName: !$scope.profile.givenName
      familyName: !$scope.profile.familyName
      sex: !$scope.profile.sex
      dob: !$scope.profile.dob

    $scope.profile.properties.changeRequest = _.cloneDeep(
      givenName: $scope.profile.properties?.changeRequest?.givenName or $scope.profile.givenName
      middleName: $scope.profile.properties?.changeRequest?.middleName or $scope.profile.middleName
      familyName: $scope.profile.properties?.changeRequest?.familyName or $scope.profile.familyName
      dob: $scope.profile.properties?.changeRequest?.dob or $scope.profile.dob
      sex: $scope.profile.properties?.changeRequest?.sex or $scope.profile.sex
    )

    originalChangeRequest = JSON.stringify $scope.profile.properties.changeRequest

    $scope.editedChangeRequest = ->
      originalChangeRequest isnt JSON.stringify $scope.profile.properties.changeRequest

    $scope.ready = ->
      fields = ["givenName", "middleName", "familyName", "dob"]
      if !$scope.organization.properties.sexOptional then fields.push "sex"
      filled = _.every fields, (f) ->
        f is "middleName" or $scope.profile.properties.changeRequest[f]?.length
      if not filled then  return "missing"
      different = _.some fields, (f) -> $scope.profile.properties.changeRequest[f] isnt $scope.profile[f]
      if different and $scope.editedChangeRequest() then return true else return "same"


    $scope.changeRequested = $scope.profile.properties.changeRequested is true
    $scope.today = new Date().toISOString().slice 0, 10

    $scope.postUpload = (content) -> $state.go "^", undefined, { reload:true }

    $scope.alertProvider = ->
      new Event(
        profileID: $scope.profile.id
        type: "demographics.changeRequest"
      ).save()

    # turns out you can only modify the session with a POST route
    $scope.tempPass = ->
      $scope.gettingPass = true
      # if they were trying to access another state and got intercepted by demographics, remember what they were trying to visit for the Do Later button
      if $state.previous && $state.previous.name && $state.previous.name != "patient.profile.demographics"
        goToState = $state.previous.name || "patient.profile"
      else
        goToState = "patient.profile"
      $http.post("/api/users/tempSkip").then ->
        $state.go goToState, undefined, { reload:true }

    $scope.rotateAvatar = (degrees) ->
      $scope.rotating = true
      data =
        resource: pickProfileData()
        type:     'avatar'
        key:      $scope.profile.avatar?.lg
        degrees:  degrees

      $http.post("/api/profiles/#{$scope.profile.id}/edit-image", data).then (result) ->
        $scope.profile.avatar = result.data
        $scope.rotating = false
      , (err) ->
        swal("Rotation Error", "There was an error rotating the avatar photo.\nPlease refresh the page and try again", "error")
        $state.reload()

    $scope.requestMerge = ->
      $scope.requestingMerge = true
      return swal {
        type: 'warning'
        title: 'Remove Duplicate Profile?'
        text: "
        Requesting a merge will delete this profile and copy its data to another one.
        Are you sure you want to request a profile merge?
        "
        showCancelButton: true
        allowOutsideClick: true
        allowEscapeKey: true
      }, (confirmed) ->
        if (confirmed)
          $scope.profile.properties.mergeRequested = moment().toISOString()
          $scope.saveProfile ->
            new Event(
              profileID: $scope.profile.id
              type: "profile.mergeRequested"
            ).save().then ->
              flash "Merge Requested"
              $scope.requestingMerge = false
            .catch (err) ->
              flash "Merge Request Failed"
              $scope.requestingMerge = false
        else
          $scope.requestingMerge = false

    $scope.cancelMergeRequest = ->
      $scope.cancelingMergeRequest = true
      delete $scope.profile.properties.mergeRequested
      $scope.saveProfile ->
        new Event(
          profileID: $scope.profile.id
          type: "profile.cancelMergeRequest"
        ).save().then ->
          $scope.cancelingMergeRequest = false
          flash "Merge Request Withdrawn"

    $scope.redirect = ->
      return $state.go "patient.profile", undefined, { reload: true }

    $scope.saveProfile = (callback) ->
      return saveProfile().then ->
        return if callback then callback() else $scope.redirect()

    $scope.alertAndRedirect = ->
      $scope.alertProvider()
      $scope.redirect()


    pickProfileData = ->
      return _.pick($scope.profile, ['id', 'givenName', 'familyName', 'middleName', 'dob', 'sex', 'avatar', 'properties', 'organizationID', 'digest', 'has', 'phase', 'identifier', 'completeness'])

    saveProfile = ->
      return new Promise (resolve, reject) ->
        $scope.saving = true
        profile = pickProfileData()
        new Profile(profile).save().then (savedProf) ->
          $scope.saving = null
          return resolve()
