/* eslint-disable no-unused-vars */
angular.module('dn').directive('paymentPlan', function() {
  return {
    templateUrl: 'directives/payment-plan/payment-plan.directive.html',
    restrict: 'E',
    scope: {
      paymentPlan: '=',
      organization: '=',
      amount: '=',
      paymentType: '=',
      passingOnFee: '=',
      existingBalance: '=',
      planPastDue: '=',
      invalidPlan: '=',
      profileName: '=',
      convenienceFeeTypes: '='
    },
    controller($http, $scope) {
      $scope.calculateFinalPayment = function(plan) {
        plan.amount = calculateMonthly(plan);
        setPaymentDates(plan);
        $scope.remainder = window.dnim.calculateRemainder(
          $scope.paymentPlan.totalAmount,
          $scope.paymentPlan.remaining
        );

        const dueDate = $scope.dueDate ? moment(toISO($scope.dueDate)) : null;
        if (dueDate && $scope.finalPaymentDate && moment(toISO($scope.finalPaymentDate)).isAfter(dueDate)) $scope.planPastDue = true;
        else $scope.planPastDue = false;

        checkFeeWithRemainder();
      };

      $scope.dueDate = setDueDate();
      $scope.paymentPlan.totalAmount = $scope.amount + $scope.existingBalance;
      $scope.paymentPlan.day = Math.floor(Math.random() * (28 - 1) + 1); // Math.random() * (max-min) + min
      $scope.paymentPlan.remaining = initRemaining();
      $scope.remainder = window.dnim.calculateRemainder(
        $scope.paymentPlan.totalAmount,
        $scope.paymentPlan.remaining
      );
      $scope.convenienceFee = 0;
      $scope.monthlyAmountWithFee = 0;
      $scope.calculateFinalPayment($scope.paymentPlan); /* Initializes a few things: plan.amount, start and finish dates. Needs to run nice and early just in case we somehow auto-populate with bad data */

      function toISO(dateString) {
        return new Date(dateString).toISOString();
      }

      function initRemaining() {
        let remaining = 3;
        if ($scope.dueDate) {
          const monthDiff = parseInt(moment(toISO($scope.dueDate)).diff(moment(), 'month'));
          remaining =  monthDiff > 0 && monthDiff < 13 ? monthDiff : 1;
        }
        return remaining;
      }

      function setDueDate() {
        return $scope.organization.properties.paymentPlansDue
               && moment(toISO($scope.organization.properties.paymentPlansDue)).isAfter(moment())
          ? $scope.organization.properties.paymentPlansDue
          : null;
      }

      function setFees() {
        if (!$scope.passingOnFee) return;

        // Extracts fee types from org properties
        $scope.convenience = $scope.convenienceFeeTypes[$scope.paymentType] || {};

        $scope.variableFee = $scope.convenience.variable
          ? $scope.convenience.variable
          : 0;
        $scope.flatFee = $scope.convenience.flat
          ? $scope.convenience.flat
          : 0;
      }

      /**
       * Calculates the convenience fee for the payment plan monthly payment
       * @param {Number} total - The monthly payment amount
       */
      function calculateFee(total) {
        return Math.ceil(($scope.variableFee / 100) * total) + $scope.flatFee;
      }

      // Variable to track if there is a difference in the first month's fee vs the rest of the months' fees.
      // If so, show remainderWithFee.
      $scope.hasDiff = false;
      $scope.remainderWithFee = null;

      /**
       * Checks if the fee is the same for the first month's new amount, when a remainder is added
       */
      function checkFeeWithRemainder() {
        if (!$scope.passingOnFee) return;

        const monthlyPayment = Math.floor($scope.paymentPlan.totalAmount / $scope.paymentPlan.remaining);
        const remainder = $scope.remainder;

        const convFee0 = calculateFee(monthlyPayment);
        const convFee1 = calculateFee(monthlyPayment + remainder);

        if (convFee0 !== convFee1) {
          $scope.hasDiff = true;

          const delta = Math.abs(convFee0 - convFee1);
          $scope.remainderWithFee = remainder + delta;

        } else $scope.hasDiff = false;
      }

      function setPaymentDates(plan) {
        $scope.firstPaymentDate = firstPaymentDate(plan.day);
        $scope.finalPaymentDate = finalPaymentDate(plan);
      }

      function firstPaymentDate(day) {
        if (!day) return null;

        const proposed = moment().date(day);
        if (proposed.isSameOrBefore(moment())) proposed.add(1, 'month');
        $scope.paymentPlan.starts = proposed;
        return proposed.format('YYYY-MM-DD');
      }

      function finalPaymentDate(plan) {
        return plan.day ? moment(firstPaymentDate(plan.day)).add(plan.remaining - 1, 'months').format('YYYY-MM-DD') : null;
      }

      /**
       * Calculates the monthly payment amount for the payment plan.
       * Adds a convenience fee if the organization has chosen to pass it on.
       * @param {Object} plan - The payment plan object
       * @returns - The monthly payment amount
       */
      function calculateMonthly(plan) {
        const monthlyPayment = Math.floor(plan.totalAmount / plan.remaining);
        if ($scope.passingOnFee && plan.remaining > 0) {
          $scope.convenienceFee = calculateFee(monthlyPayment);
          $scope.monthlyAmountWithFee = monthlyPayment + $scope.convenienceFee;
          $scope.totalAmountWithFees = $scope.monthlyAmountWithFee * $scope.paymentPlan.remaining;
        }

        return monthlyPayment;
      }

      $scope.$watch('paymentType', () => {
        if ($scope.passingOnFee) setFees();
        $scope.paymentPlan.amount = calculateMonthly($scope.paymentPlan);
        if ($scope.passingOnFee) $scope.monthlyAmountWithFee = $scope.paymentPlan.amount + $scope.convenienceFee;

        checkFeeWithRemainder();
      });

      $scope.$watch('amount', (val) => {
        $scope.paymentPlan.totalAmount = val + $scope.existingBalance;
        $scope.paymentPlan.amount = calculateMonthly($scope.paymentPlan);
        if ($scope.passingOnFee) {
          $scope.monthlyAmountWithFee = $scope.paymentPlan.amount + $scope.convenienceFee;
          $scope.totalAmountWithFees = $scope.monthlyAmountWithFee * $scope.paymentPlan.remaining;
        }

        checkFeeWithRemainder();
      });

      $scope.$watch('paymentPlan', (fresh, stale) => {
        /* These two fields can get set to null if a validator is triggered */
        var requiredFields = ['day', 'remaining'];
        var badField = null;
        requiredFields.map((field) => {
          if (badField) return;
          if (!$scope.paymentPlan[field]) badField = true;
        });
        if (badField) $scope.invalidPlan = true;
        else $scope.invalidPlan = false;
      }, true);
    }
  };
});
