const Resource = angular.injector(["dn"]).get("Resource");
angular.module("dn").factory("UserPhone", function($http) {
  const UserPhone = new Resource({
    route: "/api/users/:userID/user-phone",
    schema: {
      userID:       { type: "text",    required: true },
      phone_number: { type: "text",    required: true },
      may_text:     { type: "boolean", required: true },
      created:      { type: "date" },
      updated:      { type: "date" },
      deactivated:  { type: "date" }
    }
  });
  return UserPhone;
});
