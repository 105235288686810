/**
 * A factory used to get data about the org's payment processor.
 */
angular.module('dn').factory('PayProcessor', ($rootScope) => {
  'use strict';

  const payProcessorTypes = window.dnim.constants.billing.PAY_PROCESSOR_TYPES;

  return {
    /**
     * Get the features for an org's payment processor.
     */
    get features() {
      const orgPayProcessors = _.get($rootScope, 'organization.orgPayProcessors');
      if (!orgPayProcessors || !orgPayProcessors.length) {
        return payProcessorTypes.iCheck;
      }

      const payProcessorType = orgPayProcessors[0].processor_type;
      const payProcessorFeatures = payProcessorTypes[payProcessorType];
      if (payProcessorFeatures) {
        return payProcessorFeatures;
      } else {
        throw new Error(`Unknown payment processor type: ${payProcessorType}`);
      }
    }
  };
});
