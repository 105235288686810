Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "FunkNote", ($http) ->

  FunkNote = new Resource
    route: "/api/funk-notes/:funkID"
    schema:
      body:      { type: "text", required: true }
      profileID: { type: "number", required: true }
      deliverOn: { type: "date", required: true }
      subject:   { type: "text" }
      printed:   { type: "date" }
      sentBy:    { type: "text" }
      files:     { type: "text" }

  FunkNote
