angular.module('dn').service('ProfileRegMocks', function () {
  'use strict';

  /**
   * This service is simply intended to share mock data for ProfileRegData-related unit tests. These
   * tests include stuff for the service itself, tests for the reg-adjustment-editor directive, and
   * tests for the reg-adjustment-item directve at the time of writing. If you find them useful for
   * other things, have at it!
   */

  class ProfileRegMocks {

    constructor() {
      Object.assign(this, initMocks());
    }

    resetMocks() {
      Object.assign(this, initMocks());
    }

  }


  function initMocks() {
    const initial = {};
    // Set up reg mocks
    initial.specificRegs = {
      group4: { groupID: 4, id: 4, profileID: 1, type: 'patient', waitlisted: false, deactivated: null },
      group5: { groupID: 5, id: 5, profileID: 1, type: 'patient', waitlisted: true, deactivated: null },
    };
    initial.mockRegs = Object.values(initial.specificRegs);

    // Set up crebit mocks
    initial.specificCrebits = {
      g4Tuition: { id: 11, registrationID: 4, profileID: 1, amount: 5000, description: "[TUITON] Group 4" },
      g5Tuition: { id: 2, registrationID: 5, profileID: 1, amount: 5000, description: "[TUITON] Group 5" },
    };
    initial.mockCrebits = [
      ...Object.values(initial.specificCrebits),
      { id: 3, addOnQuantity: 1, couponID: 1, adjusts: 11, profileID: 1, amount: 1000, description: "[COUPON] Group 4" },
      { id: 4, addOnQuantity: 1, addOnID: 2, adjusts: 11, profileID: 1, amount: 2500, description: "[ADD-ON] Kewl Kidz T-shirt / XXL" },
      { id: 5, profileID: 1, amount: 2500, description: "[PAYMENT] Visa 1337" },
      { id: 6, addOnQuantity: 3, addOnID: 10, adjusts: 11, profileID: 1, amount: 750, description: "[ADD-ON] Biscuits (get em while they\'re hot) x3" },
      { id: 7, addOnQuantity: 0, addOnID: 11, adjusts: 11, profileID: 1, amount: 25000, description: "[ADD-ON] Crappy Ter\'angreal (Random)" },
      { id: 8, addOnQuantity: 0, addOnID: 11, adjusts: 11, profileID: 1, amount: -25000, description: "[ADD-ON] Remove Crappy Ter\'angreal (Random)" },
      { id: 9, addOnQuantity: 1, addOnID: 6, adjusts: 2, profileID: 1, amount: 1499, description: "[ADD-ON] 2001 by Dr. Dre" },
    ];

    // Set up add-on mocks
    initial.specificAddOns = {
      purchased: {
        multi: { id: 1, name: 'Kewl Kidz T-shirt', price: 0, groupID: 4, required: false, properties: {}, },
        single: { id: 10, name: 'Biscuits (get em while they\'re hot)', numeric: true, price: 250, groupID: 1, required: false, properties: {}, },
        forOtherGroup: { id: 6, name: '2001 by Dr. Dre', price: 1499, groupID: 2, required: true, properties: {}, },
      },
      notPurchased: {
        multi: { id: 13, name: 'Crappy Ter\'angreal (Choice)', price: 0, groupID: 4, required: false, properties: {}, },
        single: { id: 17, name: 'Postcard from Tyre', price: 50, groupID: 4, required: false, properties: {}, },
        shared: { id: 18, name: 'Postcard from DevCamp', price: 50, groupID: 1, required: false, properties: {}, },
      },
      voided: { id: 11, name: 'Crappy Ter\'angreal (Random)', price: 25000, groupID: 4, required: false, properties: {}, },
      expired: { id: 7, name: 'All other rap albums', price: 1099, expires: '2001-11-16', groupID: 2, required: false, properties: {}, },
      overCapacity: { id: 8, name: 'Times Square NYE', price: 10000, used: 50000, capacity: 25000, groupID: 1, required: false, properties: {}, },
      atCapacity: { id: 9, name: 'A Full Sauna', price: 10000, used: 4, capacity: 4, groupID: 1, required: false, properties: {}, },
      forOtherGroup: { id: 12, name: 'Crappy Ter\'angreal (Random)', price: 25000, groupID: 6, required: false, properties: {}, },
      forParentNotShared: { id: 19, name: 'No Soup For You', price: 0, groupID: 2, required: false, properties: {}, },
    };
    initial.mockAddOns = [
      ...Object.values(initial.specificAddOns).reduce((arr, obj) => {
        if (obj.id) {
          arr.push(obj);
          return arr;
        } else {
          return [ ...arr, ...Object.values(obj) ];
        }
      }, []),
      // Options for purchased.multi
      { id: 2, parentID: 1, name: 'XXL', price: 2500, groupID: 4, required: false, properties: { displayOrder: 1 } },
      { id: 3, parentID: 1, name: 'XL', price: 2500, groupID: 4, required: false, properties: { displayOrder: 2 } },
      { id: 4, parentID: 1, name: 'L', price: 2500, groupID: 4, required: false, properties: { displayOrder: 3 } },
      { id: 5, parentID: 1, name: 'M', price: 2500, groupID: 4, required: false, properties: { displayOrder: 4 } },
      // Options for notPurchased.multi
      { id: 14, parentID: 13, name: 'Rod of Mishapen Strawberries', price: 55000, groupID: 4, required: false, properties: { displayOrder: 1 } },
      { id: 15, parentID: 13, name: 'Cup of Partial Fullness', price: 55000, groupID: 4, required: false, properties: { displayOrder: 2 } },
      { id: 16, parentID: 13, name: 'Cup of Partial Emptiness', price: 55000, groupID: 4, required: false, properties: { displayOrder: 3 } },
    ];

    // Set up profile mock
    initial.mockProfile = {
      id: 1,
      givenName: "Lan",
      familyName: "Mangragoran",
      organizationID: 1
    };

    // Set up indexedOrg mock
    initial.indexedOrg = {
      1: {
        id: 1,
        phase: "present",
        parents: [0, 1],
        properties: {}
      },
      2: {
        id: 2,
        phase: "present",
        parents: [0, 1, 2],
        properties: {}
      },
      3: {
        id: 3,
        phase: "past",
        parents: [0, 1, 2, 3],
        properties: {}
      },
      // extra info used for coupon tests
      4: {
        id: 4,
        phase: "future",
        name: 'Test Group',
        parents: [0, 1, 2, 4],
        properties: {}
      },
      5: {
        id: 5,
        phase: "future",
        parents: [0, 1, 2, 5],
        properties: {}
      }, // Phase cannot be past for waitlist filter
      6: {
        id: 6,
        phase: "future",
        parents: [0, 1, 2, 6],
        properties: {}
      } // Phase doesn't matter here
    };

    return initial;

  };

  return ProfileRegMocks;

});
