lib.registerState('patient.profile.prescreening.confirmation', {
  url: '/confirmation',
  templateUrl: 'states/patient/profile/prescreening/confirmation/confirmation.state.html',
  resolve: {
    title($rootScope, GroupBranding, organization) {
      return $rootScope._title = `${GroupBranding.getPrescreeningAlias(organization)} Confirmation`;
    }
  },
  controller($http, $scope, $rootScope, $state, campgramsOnly, profile, PrescreeningData) {
    'use strict';

    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    // If the form is not filled out and the user enters this state (url hack/reload) send them back to the form
    if (!PrescreeningData.verifyFormData()) return $state.go('patient.profile.prescreening');

    $scope.userName = `${profile.givenName} ${profile.familyName}`;
    $scope.orgName = $rootScope.organization.name;
    $scope.tmp = PrescreeningData.tmp;
    $scope.confirmationBranding = PrescreeningData.branding.confirmation;

    function saveData() {
      $scope.tmp.saving = true;
      return $http.post(`/api/profiles/${profile.id}/screenings`, generatePayload());
    }

    /**
    * Loops through all questions in the template to generate a raw Screening. Sets stats-enabled
    * values directly on the object and puts custom questions into the appropriate array.
    * @returns {Screening} A raw Screening resource for saving to the DB.
    */
    function generatePayload() {
      const { template } = PrescreeningData;
      const payload = {
        profile_id: profile.id,
        template_id: PrescreeningData.templateID,
        signed_by: $scope.tmp.signature,
        answers: [],
      };

      // Multi-purpose reduce: creates custom answers array & sets stats-enabled values on payload
      template.reduce((answers, question) => {
        const { dataType } = question;
        switch (dataType) {
          // If it's a custom question, push it into the answers array. ezpz.
          case 'dynamic':
            answers.push({questionID: question.id, value: question.value });
            break;
          // Uploads require some extra special sauce
          case 'uploads':
            if (!Array.isArray(question.value) || question.value.length === 0) {
              payload[dataType] = null;
            } else {
              payload[dataType] = question.value.map(file => file.Key).join('|');
            }
            break;
          // Tags inputs output a comma separated string, but we need an array.
          case 'symptoms':
            // Use a ternary to catch null/undefined/empty string values
            payload[dataType] = !question.value ? [] : question.value.split(',');
            break;
          case 'temperature':
            if (question.unit === 'f') question.value = (5 / 9) * (question.value - 32);
            // No break here because we can let it fall through to the default for assignment
          // Other stats-enabled questions get set directly on the payload
          default:
            payload[dataType] = question.value;
            break;
        }
        return answers;
      }, payload.answers);
      return payload;
    }

    // Button Configuration
    const buttonConfig = {
      back: {
        text: 'Go Back',
        hide: false,
        disabled: false,
        action() {
          return $state.go('patient.profile.prescreening.form');
        }
      },
      next: Object.defineProperties({}, {
        text: {
          enumerable: true,
          writable: true,
          value: 'Continue'
        },
        hide: {
          enumerable: true,
          writable: true,
          value: false
        },
        disabled: {
          enumerable: true,
          configurable: false,
          get() {
            return $scope.tmp.signature === '' || $scope.tmp.saving;
          }
        },
        action: {
          enumerable: false,
          configurable: false,
          value: function() {
            saveData()
              .then(res => {
                $scope.tmp.saving = false;
                return $state.go('patient.profile.prescreening.submission');
              })
              .catch(err => {
                $scope.tmp.saving = false;
                swal({
                  type: 'error',
                  title: 'Error',
                  text: `There was an error submitting the form. Please try again later: ${JSON.stringify(err.data)}`
                });
              });
          }
        },
      }),
    };

    $scope.$on('$stateChangeSuccess', () => {
      PrescreeningData.setUiEntry('buttons', buttonConfig);
    });
  }
});
