angular.module("dn").directive("funkTokens", function() {
  return {
    templateUrl: "directives/funk-notes/funk-tokens/funk-tokens.directive.html",
    restrict: "A",
    controller: function($http, $scope, $state, flash) {

      if (!$scope.profile.tokens) {
        $scope.buyingTokens = true;
      }

      // We don't want to restrict payments methods here because these payments go straight to us.
      // We only want to restrict payments that go the org.
      $http.get("/api/users/" + $scope.user.id + "/payment-methods").then((function(result) {
        $scope.paymentMethods = _(result.data).map(function(method){
          if (method.category === 'card') {
            method.label = method.type + " -" + method.lastfour;
            method.value = method.id;
            return method;
          } else {
            return null;
          }
        }).compact().value()

        if (!$scope.paymentMethods.length) {
          $scope.useNewMethod = true;
        }
        else if ($scope.paymentMethods.length === 1) {
          $scope.useNewMethod = false;
          $scope.savedMethod = $scope.paymentMethods[0].value;
        }
      }), function(err) {
        $scope.paymentMethods = [];
        $scope.useNewMethod = true;
      });

      $scope.packages = {
        "sm": {
          size: "sm",
          price: 500,
          quantity: 10
        },
        "md": {
          size: "md",
          price: 800,
          quantity: 17
        },
        "lg": {
          size: "lg",
          price: 1000,
          quantity: 22
        }
      }

      $scope.choosePackage = function(size) {
        $scope.selectedPackage = $scope.packages[size];
      };

      var choosePaymentMethod = function(methodID) {
        return _.find($scope.paymentMethods, function(p){
          return parseInt(p.id) === parseInt(methodID);
        })
      }

      $scope.purchaseTokens = function () {
        $scope.submitting = true;
        var charge = {
          profileID: $scope.profile.id,
          amount: $scope.selectedPackage.price,
          description: '[CAMPGRAMS] CampGrams Tokens x ' + $scope.selectedPackage.quantity,
          ledger: 'campgrams'
        }

        var paymentMethod = $scope.useNewMethod ? $scope.freshMethod : choosePaymentMethod($scope.savedMethod);
        /* Methods won't save without a userID */
        if ($scope.useNewMethod) {
          paymentMethod.userID = $scope.sessionUser.id;
        }

        var description = '[PAYMENT] ' + paymentMethod.type + ' -' + paymentMethod.lastfour;
        var payment = {
          profileID: $scope.profile.id,
          amount: $scope.selectedPackage.price * -1,
          paymentMethod: paymentMethod,
          description: description,
          ledger: 'campgrams',
          hideBalance: true
        }

        if (_.includes($scope.sessionUser.options, '"testAccount"=>"true"')) {
          charge.amount = payment.amount = 0;
          charge.properties = payment.properties = {
            testCrebit: true
          }
        }

        var payload = {
          crebits: {
            "payment": payment,
            "charge": charge
          },
          quantity: $scope.selectedPackage.quantity
        }

        $http.post("/api/profiles/" + $scope.profile.id + "/funk-tokens", payload).then(
          function(result) {
            flash("Tokens Purchased!");
            $state.reload();
          }, function(err) {
            $scope.submitting = false;
            swal({
              type: "error",
              title: "Error",
              text: "There was an error buying tokens. Please ensure your payment information is accurate and up to date\nReason: " + JSON.stringify(err.data)
            });
          }
        )
      }

      $scope.disablePayment = function() {
        if ($scope.submitting) {
          return true;
        }
        var saved = $scope.savedMethod && !$scope.useNewMethod;
        var fresh = $scope.freshMethod && $scope.useNewMethod;
        if (saved || fresh) {
          return false;
        }
        return true;
      };

    } // end controller
  };
});
