lib.registerState('manager.attendance.list.profiles.profile.trustedContacts', {
  url: '/trusted-contacts',
  templateUrl: 'states/manager/attendance/profiles/profile/trusted-contacts/trusted-contacts.state.html',
  controller: function($scope, $state, AttendanceData, ContactData) {
    'use strict';
    $scope.selectContact = function (contact) {
      // If there are already some selected participants for a trusted contact, they have to be de-selected
      // so that they can't be checked in/out by another trusted contact, and all tmp data must be deleted
      AttendanceData.selectedProfiles.forEach((profile) => {
        if (profile.id === parseInt($state.params.profileID)) return;
        AttendanceData.setProfileSelected(profile.id, false);
        AttendanceData.deleteTmpRecord(profile.id);
        AttendanceData.deleteTmpNote(profile.id);
      });
      AttendanceData.selectedContact = contact;
    };

    $scope.universalContactEnabled = AttendanceData.ui.settings.universalContactEnabled && ContactData.universalContact;
  }
});
