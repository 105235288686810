angular.module("dn").directive("operButtons", function() {
  return {
    templateUrl: "directives/oper-buttons/oper-buttons.directive.html",
    restrict: "E",

    controller: function($scope, $state, $http) {
      "use strict";

      $scope.insuranceLastUpdated = '<click to refresh>';
      $scope.refreshInsuranceReport = function() {
        $http.get('/api/operator/insurance-policies-refresh')
          .then(() => {
            flash('Report is refreshing. Check back in a few');
          })
          .catch((err) => {
            throw err;
          });
      };
      $scope.updateInsuranceStat = function() {
        $http.get('/api/operator/insurance-policies-stat')
          .then((success) => {
            $scope.insuranceLastUpdated = success.data;
          })
          .catch((err) => {
            throw err;
          });
      };
      $scope.updateInsuranceStat();

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
