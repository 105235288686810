angular.module("dn").factory "Images", ($http) ->

  @getForProfile = (profileID, imageString, template) ->
    route = "/api/profiles/#{profileID}/images/#{imageString}"
    if template then route += "?template"
    $http.get(route).then (result) -> result.data

  @getForGroup = (groupID, imageString) ->
    route = "/api/groups/#{groupID}/images/#{imageString}"
    $http.get(route).then (result) -> result.data

  @getConstantImage = (imageString) ->
    route = "/api/constants/images/#{imageString}"
    $http.get(route).then (result) -> result.data

  @
