lib.registerState "manager.finances.orgLedger.payouts",
  url: "/payouts"
  templateUrl: "states/manager/finances/ledgers/org/payouts/payouts.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Payouts"

    payouts: ($http, $stateParams) ->
      $http.get("/api/organizations/#{$stateParams.orgID}/payouts").then((success) ->
        return success.data
      , (err) ->
        flash("Failed to Load Payout Data")
        return []
      )

    bankAccount: ($http, organization) ->
      $http.get("/api/organizations/#{organization.id}/payment-methods").then(
        (result) ->
          return _(result.data).reject('deactivated').orderBy('created', 'desc').first()
        , (err) ->
          return null
      )

  controller: ($scope, $state, $http, payouts, crebits, flash, bankAccount, balanceData, $filter) ->
    PAYMENT_ERRORS = /(DECLINED|INVALID|INVLD|INCORRECT|CALL)/
    $scope.payouts = _(payouts).sortBy('created').reverse().value()
    $scope.bankAccount = bankAccount
    $scope.savingPayoutMethod = false
    $scope.truncation = {
      method: {
        truncated: false
        expanded: false
      },
      payouts: {
        truncated: false
        expanded: false
      }
    }

    $scope.toggleExpanded = (cardString) ->
      $scope.truncation[cardString].expanded = !$scope.truncation[cardString].expanded

    $scope.savePayoutMethod = ->
      $scope.savingPayoutMethod = true
      $http.post("/api/organizations/#{$state.params.orgID}/payment-methods", $scope.newMethod).then(
        (success) ->
          $scope.bankAccount = success.data
          $scope.savingPayoutMethod = false
          $scope.editingBank = false
          flash "Payment Method Added"
          $scope.toggleExpanded('method');
        (error) ->
          $scope.savingPayoutMethod = false
          if error.data.match(PAYMENT_ERRORS)
            reason = error.data
          window.swal
            title: "Error"
            text: """
              There was an error saving your payment method.
              #{if reason then "Reason: " + reason else ""}
              Please ensure your payment method information is correct.
            """
            type: "error"
      )

    $scope.balance = balanceData.balance

    $scope.total = _.reduce payouts, (sum, crebit) ->
      return sum if crebit.amount <= 0
      sum += parseInt crebit.amount if crebit.amount
      sum
    , 0

    $scope.exportPayout = (crebitID) ->
      $http.get("/api/organizations/#{$scope.organization.id}/payouts/#{crebitID}/report").then(
        (result) ->
          csvData = new Blob([result.data], {type: "text/csv;charset=utf-8;"})
          filename = "payout-#{crebitID}-report.csv"

          if window.navigator.msSaveOrOpenBlob
            window.navigator.msSaveOrOpenBlob(csvData, filename)
          else
            csvURL = window.URL.createObjectURL(csvData)
            a = document.createElement("a")
            document.getElementById("content").appendChild(a)
            a.href = csvURL
            a.setAttribute("download", filename)
            a.click()
            a.remove()
            window.URL.revokeObjectURL(csvURL)
            a = csvURL = null
          csvData = null
        (err) ->
          flash('Error generating export')
      );

    $scope.isGlCodesP3FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority3');
