angular.module("dn").directive "organizationsFilter", ->
  restrict: "A"
  templateUrl: "states/oper/organizations/filter/organizations-filter.directive.html"
  scope:
    accountManagers: "=accountManagers"
    organizations: "=organizationsFilter"
    newOrg: "="
  controller: ($scope, $filter) ->
    $scope.interestPossibilities = [
      { label: "All", value: "all" }
      { label: "Enabled", value: "true" }
      { label: "Interested", value: "interested" }
      { label: "Not Enabled", value: "false" }
    ]

    $scope.activeChoices = [
      { label: "All", value: "all" }
      { label: "Active", value: "active" }
      { label: "Deactivated", value: "deactivated" }
    ]

    $scope.insuranceChoices = [
      { label: "All", value: "all" }
      { label: "Enabled", value: "true" }
      { label: "Not Enabled", value: "false" }
    ]

    $scope.stageChoices = [
      { label: "All", value: "all" }
      { label: "Pending", value: "pending" }
      { label: "Started", value: "started" }
      { label: "Submitted", value: "submitted" }
      { label: "Reviewed", value: "reviewed" }
      { label: "Confirmed", value: "confirmed" }
      { label: "Authorized", value: "authorized"}
    ]

    $scope.testChoices = [
      { label: "All", value: "all" }
      { label: "Yes", value: "yes" }
      { label: "No", value: "no" }
    ]

    $scope.managerChoices = $scope.accountManagers.reduce (acc, am) ->
      if am.name
        am.name = am.name.split(' ').reverse().join(', ') if am.name isnt "Help Desk"
        option = { label: am.name, value: am.name }
        acc.push option
      return acc
    , [{ label: "All", value: "all" }]
    .sort (a, b) ->
      alabel = a.label.toLowerCase()
      blabel = b.label.toLowerCase()
      if blabel == 'all' then return 1
      if alabel < blabel then return -1
      else if blabel < alabel then return 1
      else return 0

    $scope.filter =
      regStatus: "all"
      insuranceStatus: "all"
      activeStatus: "all"
      managerStatus: "all"
      stageStatus: "all"
      funkStatus: "all"
      testStatus: "no"

    $scope.runFilter = (filter) ->
      _.map $scope.organizations, (org) ->
        org._hide = false

        if filter.managerStatus isnt "all" and org.manager?.name.toString() isnt filter.managerStatus
          return org._hide = true
        if filter.stageStatus isnt "all" and org.seasonStatus() isnt filter.stageStatus
          return org._hide = true
        if filter.regStatus isnt "all" and org.registration.toString() isnt filter.regStatus
          return org._hide = true
        if filter.insuranceStatus isnt "all" and org.travelInsurance.toString() isnt filter.insuranceStatus
          return org._hide = true
        if filter.funkStatus isnt "all" and org.campGrams.toString() isnt filter.funkStatus
          return org._hide = true
        if filter.activeStatus isnt "all"
          if filter.activeStatus is "active" and org.deactivated then return org._hide = true
          if filter.activeStatus is "deactivated" and !org.deactivated then return org._hide = true
        if filter.text
          return org._hide = true if !$filter("text") org, filter.text
        if filter.testStatus isnt "all"
          if filter.testStatus is "yes" and (org.testOrg is null or !org.testOrg)
            return org._hide = true
          if filter.testStatus is "no" and org.testOrg
            return org._hide = true

    $scope.$watch "filter", (filter) ->
      $scope.runFilter filter
      $scope.orgCount = (_.filter $scope.organizations, (o) -> !o._hide).length
    , true
