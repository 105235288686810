# Manage support documents for the Support state
# which is oper only at time of writing
lib.registerState "oper.documents",
  url: "/documents"
  resolve:
    documents: ($http) ->
      $http.get("/api/constants/documents").then (result) -> result.data
  templateUrl: "states/oper/documents/documents.state.html"
  controller: ($scope, $http, documents, flash, Images) ->

    # We pulled documents from the constants table, they're organizated as detailed below
    $scope.allDocuments = documents.constant

    ###
    #   Documents are stored in the form
    #   {
    #     campdoc: [
    #       { name: "Top-10", files: <uuid|uuid>, description: "Lorem ipsum" }
    #     ]
    #   }
    ###
    _.map documents.constant, (division) ->
      _.map division, (doc) ->
        if doc.files
          Images.getConstantImage(doc.files).then (urls) ->
            doc.urls = urls
        doc.completeFn = (content) -> doc.files = content.key

    $scope.division = "campdoc"
    $scope.divisions = [
      { label: "CampDoc.com", value: "campdoc" }
      { label: "SchoolDoc.com", value: "schooldoc" }
    ]

    # Change active documents
    $scope.$watch "division", ->
      documents.constant[$scope.division] ?= []
      $scope.documents = documents.constant[$scope.division]
    , true

    # When we upload or delete a document we want to re-save
    save = _.debounce ->
      $http.put "/api/constants/documents", documents
    , 1000

    init = false
    $scope.$watch "documents", ->
      if !init then return init = true
      do save
    , true

    $scope.deleteDocument = (doc) ->
      $scope.documents = _.without $scope.documents, doc
      documents.constant[$scope.division] = $scope.documents
      do save

    $scope.newDocument = ->
      $scope.documents.push { files: "", description: "", name: "" }
      doc = _.last $scope.documents
      doc.completeFn = (content) -> doc.files = content.key

    # Move documents up and down in the manner in which they'll be ordered
    $scope.up = (index) ->
      return if index is 0
      tmp = _.cloneDeep $scope.documents[index - 1]
      $scope.documents[index - 1] = $scope.documents[index]
      $scope.documents[index] = tmp


    $scope.down = (index) ->
      return if index is $scope.documents.length - 1
      tmp = _.cloneDeep $scope.documents[index + 1]
      $scope.documents[index + 1] = $scope.documents[index]
      $scope.documents[index] = tmp
