lib.registerState('patient.profile.registration.details', {
  url: '/details/:registrationID',
  templateUrl: 'states/patient/profile/registration/details/details.state.html',
  resolve: {
    registration: function($stateParams, registrations) {
      return registrations.find(r => r.id === parseInt($stateParams.registrationID));
    }
  },
  controller: function($filter, $http, $rootScope, $scope, $state, organization, registration, profile, RegData) {
    'use strict';

    const regData = new RegData(organization);

    try {
      setCompiledProperties();

      $scope.registration = registration;
      $scope.organization = organization;

      /* Set up arrays of adjusting crebits */
      const { addOns, coupons } = findAdjustingCrebits();
      $scope.appliedAddOns = addOns;
      $scope.appliedCoupons = coupons;

      $scope.tuition = profile.crebits.find((c) => {
        return c.registrationID === $scope.registration.id;
      });

      /* Find addOns we can add to the reg */
      allAddOns().then((data) => {
        $scope.availableAddOns = availableAddOns(data);

        const groupHasAddOns = ($scope.appliedAddOns.length || $scope.availableAddOns.length);

        $scope.shouldShowAddOns = (
          registration.approved
          && !registration.waitlisted
          && groupHasAddOns
          && $scope.tuition
        );
      }).catch(err => { throw err; });

      $scope.requestCancellation = requestCancellation;
      $scope.withdrawCancellation = withdrawCancellation;
      $scope.saveAddOns = saveAddOns;
      $scope.changeOption = changeOption;

      /* blocks multi-clicks on things while other things are thinging */
      $scope.processing = null;

    } catch (err) {
      errorSwal({
        title: 'Error Loading Page',
        text: 'There was an error loading your data.',
      });
    }

    /*
    This func assumes that validAdjustCrebit will only return true for addOns and coupons
    */
    function findAdjustingCrebits() {
      return profile.crebits.reduce((applied, crebit) => {
        if (validAdjustCrebit(crebit)) applied[crebit.addOnID ? 'addOns' : 'coupons'].push(removeTag(crebit));
        return applied;
      }, { addOns: [], coupons: [] });
    }

    /*
    Returns true if:
    crebit has an addOnID or couponID
    AND crebit adjusts something. Not sure it's possible for this to ever be false if addOnID exists
    AND crebit it adjusts is for the reg we're currently looking at
    */
    function validAdjustCrebit(crebit) {
      const hasAddOnOrCoupon = !!(crebit.addOnID || crebit.couponID);
      if (!hasAddOnOrCoupon || !crebit.adjusts) {
        return false;
      }

      const crebitToAdjust = profile.crebits.find(c => c.id === crebit.adjusts) || {};
      return crebitToAdjust.registrationID === registration.id;
    }

    /*
    Removes the [TAG] from crebit descriptions for display purposes
    */
    function removeTag(crebit) {
      crebit.description = crebit.description.replace(/^\[.+?\]/g, '');
      return crebit;
    }

    function toggleProcessing(bool) {
      if (bool) {
        $rootScope.showProgress();
        $scope.processing = true;
      } else {
        $rootScope.hideProgress();
        $scope.processing = null;
      }
    }

    function requestCancellation() {
      toggleProcessing(true);
      return $http.post(`/api/profiles/${profile.id}/registrations/${registration.id}/request-cancellation`).then(({data}) => {
        registration.properties = data.properties;
        toggleProcessing();

        flash('Cancellation Request Submitted');
      }).catch(() => {
        toggleProcessing();
        return errorSwal({
          title: 'Error Submitting Cancellation',
          text: 'There was an error submitting your cancellation request.',
        });
      });
    }

    function withdrawCancellation() {
      toggleProcessing(true);
      return $http.post(`/api/profiles/${profile.id}/registrations/${registration.id}/withdraw-cancellation`).then(({data}) => {
        registration.properties = data.properties;
        toggleProcessing();
        flash('Cancellation Request Withdrawn');
      }).catch(() => {
        toggleProcessing();
        return errorSwal({
          title: 'Error Withdrawing Cancellation',
          text: 'There was an error withdrawing your cancellation request.',
        });
      });
    }

    function allAddOns() {
      return new Promise((resolve, reject) => {
        /* use the reg data factory for pulling available addons */
        regData.fetchAddOns([registration.group.id]).then(() => {
          return resolve(regData.addOns);
        }).catch((err) => {
          console.error('caught err loading addons', err);
          return reject(err);
        });
      });
    }

    function availableAddOns(addOns) {
      return addOns
        .filter(purchasableAddOn)
        .map((addOn) => {
          setRemaining(addOn);
          setQuantity(addOn);
          createOptions(addOn);
          selectRequired(addOn);
          return addOn;
        }).filter(removeOptions);
    }

    /* largely ripped from the reg wizard addOns directive */
    function purchasableAddOn(addOn) {
      const purchased = $scope.crebits.reduce((sum, crebit) => {
        if (crebit.addOnID === addOn.id) sum += crebit.addOnQuantity;
        return sum;
      }, 0);

      const deactivated = addOn.deactivated;
      /* eslint-disable-next-line eqeqeq */
      const overCapacity = addOn.capacity != null && addOn.used >= addOn.capacity;
      const expired = window.lib.isExpired(addOn, organization.properties.timezone);

      return !expired && !deactivated && !overCapacity && !purchased;
    }

    function setRemaining(addOn) {
      if (!addOn.capacity) return addOn.remaining = null;
      addOn.remaining = addOn.capacity ? addOn.capacity - (addOn.used || 0) : null;
    }

    function setQuantity(addOn) {
      if (addOn.numeric) addOn.quantity = 1;
    }

    function createOptions(addOn) {
      addOn.options = regData.addOns
        .filter(a => a.parentID === addOn.id)
        .sort((a, b) => +a.properties.displayOrder - +b.properties.displayOrder);
    }

    function selectRequired(addOn) {
      if (!addOn.options.length && addOn.required) addOn.selected = true;
    }

    /* largely ripped from the reg wizard addOn directive */
    function removeOptions(addOn) {
      // filter purchased multichoice addons (first pass doesn't catch this since options weren't attached)
      const purchased = $scope.crebits.reduce((sum, crebit) => {
        addOn.options.forEach(option => {
          if (crebit.addOnID === option.id) sum += crebit.addOnQuantity;
        });
        return sum;
      }, 0);

      const hadOptions = !!addOn.options.length;
      addOn.options = addOn.options.filter(o => {
        /* eslint-disable-next-line eqeqeq */
        const underCapacity = o.capacity == null || o.capacity > o.used;
        return underCapacity && !window.lib.isExpired(o, organization.properties.timezone);
      });

      const allOptionsRemoved = hadOptions && !addOn.options.length;
      createSelectChoices(addOn);

      const applicableToGroup = registration.group.parents.includes(addOn.groupID);

      return !purchased && !addOn.parentID && !allOptionsRemoved && applicableToGroup;
    }

    function createSelectChoices(addOn) {
      if (addOn.options && addOn.options.length) {
        addOn.selectOptions = addOn.options.map((option, index) => {
          return { label: option.name, value: index, price: option.price, id: option.id };
        });

      }
      return addOn;
    }

    function getSelectedAddOns() {
      const selectedAddOns = $scope.availableAddOns.filter((addOn) => {
        if (addOn.selectOptions) {
          const optionSelected = addOn.selectOptions.some((option) => {
            return option.selected;
          });
          return optionSelected;
        } else if (addOn.numeric && addOn.remaining) {
          return addOn.selected && addOn.quantity <= addOn.remaining;
        } else {
          return addOn.selected;
        }
      });
      return selectedAddOns;
    }

    function saveAddOns() {
      toggleProcessing(true);

      const selectedAddOns = getSelectedAddOns();
      const crebits = selectedAddOns.map((addOn) => {
        let amount = addOn.price;
        let description = `[ADD-ON] ${addOn.name}`;
        let addOnID = addOn.id;

        if (addOn.selectOptions && addOn.selectOptions.length) {
          const option = addOn.selectOptions.find(o => o.selected);
          amount = option.price;
          description += ` / ${option.label}`;
          addOnID = option.id;
        }

        if (addOn.numeric) {
          description += ` (${addOn.quantity})`;
          amount = addOn.price * addOn.quantity;
        }

        return {
          profileID: profile.id,
          adjusts: $scope.tuition.id,
          description,
          amount,
          ledger: 'organization',
          addOnID,
          addOnQuantity: addOn.quantity,
        };
      });

      // TODO: When removing the 'GLCodesPriority2' feature flag, we should
      //   remove 'orgID' from the request body since it was only needed for
      //   checking the value of the feature flag.
      $http.post(`/api/profiles/${profile.id}/crebits`,
        { crebits, orgID: $scope.organization.id })
        // eslint-disable-next-line no-unused-vars
        .then((success) => {
          return $state.reload();
        })
        .catch((err) => {
          console.error('Error posting crebits', err);
          errorSwal({
            title: 'Error Submitting Add-Ons'
          });
          toggleProcessing();
        });
    }

    $scope.addOnsSelected = () => {
      return getSelectedAddOns().length > 0;
    };

    // Deselect all options except the option this was called on
    function changeOption(option, addOn) {
      if (!option.id || !addOn.options || !option.selected) {
        return;
      }

      // Deselect all other options
      const otherOptions = addOn.selectOptions.filter(o => o.id !== option.id);
      otherOptions.forEach(o => o.selected = false);
    }

    /*
    Need compiledProperties for contact information and (less likely) dates
    compiledProperties exists on organization, but not its children
    Fetch all group entries from the orgnization tree
      and reverse to do leaf->parent searches for data
    */
    function setCompiledProperties() {
      const groupAndParents = window.dnim.gimmeABeer({
        arrayToSearch: [organization],
        searchElements: registration.group.parents,
        searchElementKey: 'id',
        arrayNestingKey: 'children'
      }).reverse();

      registration.group.compiledProperties = groupAndParents.reduce((finalProps, {properties}) => {
        const {start, finish, contact_name, contact_phone, contact_email} = properties;
        return _.defaults(finalProps, {start, finish, contact_name, contact_phone, contact_email});
      }, {});
    }

    function errorSwal(options) {
      return swal({
        title: options.title,
        type: 'error',
        text: options.text,
        showCancelButton: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        confirmButtonText: 'Reload'
      }, (isConfirm) => {
        if (isConfirm) return $state.reload();
      });
    }

  }
});
