lib.registerState('manager.medicalSupplies', {
  url: '/medical-supplies',
  templateUrl: 'states/manager/medical-supplies/medical-supplies.state.html',
  resolve: {
    title: function($rootScope) { return $rootScope._title = 'Medical Supplies'; },
  },
  controller: function($scope, organization) {
    $scope.division = window.dnim.getDivision(organization.properties.division);
    $scope.suppliesSite = `https://supplies.${$scope.division.domain}`;
  }
});
