angular.module('dn').directive('sessionSelect', function() {
  return {
    templateUrl: 'directives/registration-wizard/session-select/session-select.directive.html',
    restrict: 'E',
    scope: {
      groups: '=',
      organization: '=',
      profile: '=',
      flatOrg: '=',
      regData: '=',
      selectedGrade: '=',
      isTestAccount: '=',
      isComplete: '=',
      resetRegistration: '=',
      onContinue: '='
    },
    controller($http, $scope, $stateParams, $rootScope, $filter) {
      $scope.regFlag = window.lib.featureFlagClient.isEnabled('RegEnhancements');

      /*
        Mission-critical functions at the top.
        Everything else is ordered alphabetically after errorHandler().
        $scope functions at the very bottom
      */
      $scope.isComplete = () => $scope.groups.length;
      $scope.quickLinks = setQuickLinks();

      /* allRegs includes deactivated objects which can't super-feasibly be fixed for the regular .registrations object */
      var allProfileRegistrations = ($scope.profile.registrations || []).concat($scope.profile.allRegs || []);

      var registerable = getRegisterableGroups();

      var previouslySelectedGroups = $scope.groups.map(g => g.id);

      $rootScope.showProgress();

      $scope.fetchingRegData = true;
      $scope.regData.fetchOccupancies(registerable.map(group => group.id)).then(occupancyHandler).catch(errorHandler);

      function occupancyHandler() {
        const occupancyData = $scope.regData.occupancies;
        assignOccupancies(registerable, occupancyData);

        assignShortNameSections();
      }

      function errorHandler(err) {
        flash('Error loading registration data. Please try again.');
        console.error('Error getting occupancy data', err);
      }

      function ageRestricted(group) {
        if (!$scope.profile.dob) return true;
        var ageInterval = group.registration.age;
        var ageRestriction = ageInterval && (ageInterval[0] || ageInterval[1]);
        if (!ageRestriction) return false;
        var ageAtStart = Math.abs(moment($scope.profile.dob).diff(group.properties.start, 'years'));
        if (ageInterval[0] && ageAtStart < parseInt(ageInterval[0])) return true;
        if (ageInterval[1] && ageAtStart > parseInt(ageInterval[1])) return true;

        return false;
      }

      function assignOccupancies(groupArray, occupancyData) {
        occupancyData.map((groupData) => {
          groupData.activeRegistrations = groupData.registrations.filter(registration => !registration.deactivated && !registration.waitlisted);

          const groupMatch = groupArray.find(group => group.id === parseInt(groupData.groupID));
          if (!groupMatch) return;

          groupMatch.occupancy = {
            total: groupData.activeRegistrations.length,
            male: groupData.activeRegistrations.filter(reg => reg.sex === 'Male').length,
            female: groupData.activeRegistrations.filter(reg => reg.sex === 'Female').length
          };
        });
      }

      function assignShortNameSections(targetShortName) {
        // Lodash over ES6 mostly because _.uniq is darn good. targetShortName is used for quickLinks (reg links, wizard links, whatever)
        var sections = _(registerable)
          .map('shortNameSection')
          .uniq()
          .filter(shortNameSection => {
            return targetShortName ? !!findShortNameParent(shortNameSection, targetShortName) : true;
          }).map(shortNameSection => {

            shortNameSection.registerableSessions = _(registerable)
              .filter(regGroup => regGroup.shortNameSection.id === shortNameSection.id)
              .map(regGroup => {
                /* If group is full and has waitlist enabled, flag as waitlistable. Full and no waitlist, flag as full and find a way to make them not registerable while still visible */
                if (sessionFull(regGroup)) {
                  if (regGroup.registration.waitlist) regGroup.waitlistRegistration = true;
                  else regGroup.atCapacity = true;
                }
                return regGroup;
              }).sortBy(session => {
                if (!$scope.organization.displayOrder || !$scope.organization.displayOrder[shortNameSection.shortName]) return;
                return $scope.organization.displayOrder[shortNameSection.shortName].indexOf(session.id);
              }).compact().value();

            return shortNameSection;

          }).sortBy(section => {
            if (!$scope.organization.displayOrder || !$scope.organization.displayOrder.shortNames) return;
            return $scope.organization.displayOrder.shortNames.indexOf(section.shortName);
          }).value();
        $scope.shortNameSections = sections;
        $scope.selectedShortName = targetShortName ? targetShortName.toLowerCase() : null;
        $scope.fetchingRegData = null;
        $rootScope.hideProgress();
        // Get total number of registerable sessions for the search bar
        $scope.totalSessionCount = $scope.shortNameSections.reduce((sum, section) => {
          if (section.registerableSessions) sum += section.registerableSessions.length;
          return sum;
        }, 0);
      }

      function findShortNameParent(group, targetShortName) {
        /*
          targetShortName is used if we want to check that a specific shortName exists anywhere in the given group's parent chain,
          whereas without one it'll return the first group in the chain with a shortName
        */
        var foundSection = null;
        var parents = [...group.parents]; // clone array with ES6 spread operator
        var reversedParents = parents.reverse().slice(0, parents.length - 1); // Reverse and remove the last element (21436)
        reversedParents.map(parentID => {
          if (foundSection) return;
          const flatMatch = $scope.flatOrg.find(flatGroup => flatGroup.id === parentID);

          if (flatMatch.shortName && (targetShortName ? flatMatch.shortName === targetShortName : true)) foundSection = flatMatch;
        });
        return foundSection;
      }

      function findGroupParent(group) {
        return $scope.flatOrg.find(flatGroup => flatGroup.id === group.parentID);
      }

      function getRegisterableGroups() {
        /*
          Do as much group filtering as possible before we have to fire any extra http requests
          First filter down to only groups with reg enabled and are in the future (notRegisterableBasics())
          Then filter out groups based on all things that could exclude a group from the list
        */
        return $scope.flatOrg.filter((group) => {
          if (notRegisterableBasics(group)) return false;
          if (notCoveredByJumpUrl(group)) return false;
          if (profileIsRegistered(group.id)) return false;
          if (gradeRestricted(group)) return false;
          if (sexRestricted(group)) return false;
          if (ageRestricted(group)) return false;
          if ($scope.isTestAccount) return true; // As of docnetwork/app#5814, test accounts should only ignore reg open/close windows
          if (notWithinRegistrationTimes(group)) return false;
          return true;
        }).map((group) => {
          group.parent = findGroupParent(group);
          group.parentName = group.parent.name;
          group.shortNameSection = findShortNameParent(group);
          return group;
        });
      }

      function gradeRestricted(group) {
        if (!$scope.organization.properties.features || !$scope.organization.properties.features.orgGrades || !group.registration.grade || !$scope.selectedGrade) return false;

        const groupGrade = group.registration.grade;

        /*
          isFinite checks that it's a real number, and returns true for a 0
          Basically checking if registration.grade = [,]
          parseInt's are necessary because _.isFinite("1") is false, doesn't coerce to an int
        */
        if (!_.isFinite(parseInt(groupGrade[0])) && !_.isFinite(parseInt(groupGrade[1]))) return false;


        const tooLow = _.isFinite(parseInt(groupGrade[0])) && parseInt($scope.selectedGrade) < parseInt(group.registration.grade[0]);
        const tooHigh = _.isFinite(parseInt(groupGrade[1])) && parseInt($scope.selectedGrade) > parseInt(group.registration.grade[1]);
        return tooLow || tooHigh;
      }

      function notCoveredByJumpUrl(group) {
        /*
          If someone hits app.campdoc.com/register/discovery/voyager,
            it'll 'jump' them straight to the reg wizard (changing the URL to include ?r=shortName),
            and it should only display groups that fall under the given shortName
          StateParam shortName could be at ANY level, not just the immediate parent group
        */
        if (!$stateParams.r) return false;
        if (findShortNameParent(group, $stateParams.r)) return false;
        else return true;
      }

      function notRegisterableBasics(group) {
        return !group.registration.enabled || group.phase === 'past';
      }

      function notWithinRegistrationTimes(group) {
        if (!group.properties.start) return true;
        group.start = new Date(group.properties.start).toISOString();
        return !window.dnim.sessionOpen(group, $scope.organization);
      }

      function profileIsRegistered(groupID) {
        /* Simple. If profile already has a patient registration for the group, don't show it */
        return !!allProfileRegistrations.find(registration => registration.groupID === groupID && registration.type === 'patient');
      }

      function sessionFull(group) {
        if (!group.occupancy) group.occupancy = { total: 0, male: 0, female: 0 };

        if (!group.registration.capacity && group.registration.capacity !== 0) {
          return false;
        } else if (parseInt(group.registration.capacity) === parseInt(group.registration.capacity)) {
          /* Capacity is either already an int or a string */
          return group.occupancy.total >= parseInt(group.registration.capacity);
        } else {
          /* Capacity is an object, meaning it'll have .female and/or .male counts instead */
          var totalCapacity = 0;
          if (group.registration.capacity.male) totalCapacity += parseInt(group.registration.capacity.male);
          if (group.registration.capacity.female) totalCapacity += parseInt(group.registration.capacity.female);
          if ($scope.profile.sex === 'Female')
            return group.registration.capacity.female ? group.occupancy.female >= group.registration.capacity.female : true;
          else if ($scope.profile.sex === 'Male')
            return group.registration.capacity.male ? group.occupancy.male >= group.registration.capacity.male : true;
          else return group.occupancy.total >= totalCapacity;
        }
      }

      function setQuickLinks() {
        const links = [];
        if ($scope.organization.registration.linkedSections && $scope.organization.registration.linkedSections.length) {
          $scope.organization.registration.linkedSections.map((shortName) => {
            const groupMatch = $scope.flatOrg.find(group => group.shortName === shortName);
            if (groupMatch) links.push({ name: groupMatch.name, shortName: shortName });
          });
        }
        return links;
      }

      function sexRestricted(group) {
        /*
          Don't have occupancy data just yet, so we're only checking if the group has a capacity of 0 for the current profile's sex
          Return true if they DON'T have access to the session
        */
        if (group.registration.capacity) {
          if (parseInt(group.registration.capacity) === parseInt(group.registration.capacity)) {
            /* Capacity is either a string or an int, no sex-based capacities. Allow! */
            return false;
          } else {
            /* Group has at least one sex-based capacity */
            var profileSex = $scope.profile.sex ? $scope.profile.sex.toLowerCase() : null;
            if (!profileSex) {
              /* We know group has sex capacities set, profile no has secs. Deny! */
              return true;
            } else if (!group.registration.capacity[profileSex]) {
              /* Group does not have a capacity set for the given sex, or it's set to int 0. Deny! */
              return true;
            } else if (group.registration.capacity[profileSex] && !parseInt(group.registration.capacity[profileSex])) {
              /* Group capacity for this sex is set to "0". Deny! */
              return true;
            } else {
              /* I forgot a profile/group edge case. Allow! */
              return false;
            }
          }
        } else {
          /* No capacity set whatsoever. Allow! */
          return false;
        }
      }

      $scope.filterSections = assignShortNameSections;

      $scope.selectAllSessions = function(section, val) {
        $scope.selectGroups(section.registerableSessions, val);
      };

      const sortSection = (g) => {
        if (!$scope.organization.displayOrder || !$scope.organization.displayOrder.shortNames) return;
        return $scope.organization.displayOrder.shortNames.indexOf(g.shortNameSection.shortName);
      };

      const sortGroup = (g) => {
        if (!$scope.organization.displayOrder || !$scope.organization.displayOrder[g.shortNameSection.shortName]) return;
        return $scope.organization.displayOrder[g.shortNameSection.shortName].indexOf(g.id);
      };

      $scope.filterSection = function(section, search) {
        return section.registerableSessions.some(s => $filter('text')({ name: s.name, parentName: s.parentName }, search));
      };

      $scope.selectGroups = function(groups, forceValue) {
        groups.map(g => {
          if (g.atCapacity) {
            if (groups.length === 1) flash('Cannot register for full sessions');
            return;
          }
          g.selected = _.isUndefined(forceValue) ? !g.selected : forceValue;
        });

        $scope.groups = _(registerable)
          .filter(group => group.selected)
          .sortBy([sortSection, sortGroup])
          .value();

        $scope.shortNameSections.map(section => {
          /* Property used to show/hide the Select All/None buttons */
          section.allSelected = _.every(section.registerableSessions, 'selected');
        });
      };

      $scope.spacesLeft = function(group) {
        if (!group.registration.capacity) return;
        let remaining;
        // check for regular or sex based capacity with object comparison
        if (parseInt(group.registration.capacity) === parseInt(group.registration.capacity))
          remaining = parseInt(group.registration.capacity) - group.occupancy.total;
        else {
          remaining = parseInt(group.registration.capacity[$scope.profile.sex.toLowerCase()]) - group.occupancy[$scope.profile.sex.toLowerCase()];
        };
        return `${remaining} space${remaining > 1 ? 's' : ''} left`;

      };

      $scope.onContinue = () => {
        if (_.xor($scope.groups.map(g => g.id), previouslySelectedGroups).length) {
          $scope.resetRegistration(['addOns', 'coupons', 'insurancePolicies', 'overBalance']);
        }
      };

    } // End controller
  }; // End return statement
}); // End directive
