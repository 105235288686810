lib.registerState('patient.profile.registration.register', {
  url: '/register',
  templateUrl: 'states/patient/profile/registration/register/register.state.html',
  params: {
    regCode: null
  },
  resolve: {

    title($rootScope, profile) {
      return $rootScope._title = `${profile.givenName} ${profile.familyName} / Register`;
    },
    registrations($rootScope, $http, profile) {
      /* Need access to deactivated profile regs and the load that happens in profile.state already excludes them because fuck yeah */
      return $http.get(`/api/profiles/${profile.id}/registrations?regOnly=true`).then(function(success) {
        return success.data;
      }, function() {
        return [];
      });
    },
    orgFeeSettings: function($http, organization) {
      feeFeatureFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees');

      if (feeFeatureFlag) {
        const url = `/api/organization/${organization.id}/finance/org-fee-settings`;

        return $http.get(url).then((res) => {
          if (res.status === 200) {
            return {
              feePassedOn: res.data.feePassedOn,
              bank: {
                flat: res.data.bankFlatFee,
                variable: res.data.bankVariableRate,
              },
              card: {
                flat: res.data.cardFlatFee,
                variable: res.data.cardVariableRate
              }
            };
          }
          return false;

        }).catch((err) => {
          if (err.status !== 404) return flash('Unable to load organization fee settings');
          return false;
        });
      } else return false;
    }
  },
  controller($http, $scope, $state, $stateParams, profile, organization, RegData, session, registrations, user, gaTagIDs, orgFeeSettings) {

    $scope.passingOnFee = orgFeeSettings.feePassedOn;
    $scope.convenienceFeeTypes = { bank: orgFeeSettings.bank, card: orgFeeSettings.card };

    const features = organization.properties;
    if (features.earlyRegistrationEnabled && features.earlyRegistrationCodeSet) {
      if (!$stateParams.regCode) {
        return $state.go('patient.profile.registration');
      }
    }

    $scope.gaTagIDs = gaTagIDs;
    if (gaTagIDs) {
      const config = {
        eventName: 'start_registration',
        eventCategory: 'registration',
        eventLabel: 'start',
        gaTagIDs: $scope.gaTagIDs,
      };
      window.lib.emitGAEvent(config);
    };

    $scope.regData = new RegData(organization);
    $scope.userID = session.userID;

    $scope.profile.allRegs = registrations || [];

    // Flat org truthers unite
    $scope.flatOrg = flattenOrg(organization);

    function flattenOrg(organization) {

      const GROUP_PROPS = [ 'id', 'name', 'classification', 'parentID', 'parents', 'phase', 'deactivated', 'registration', 'shortName', 'properties', 'compiledProperties' ];
      const org = _.pick(organization, GROUP_PROPS);
      return flatten(organization.children, [ org ]);

      function flatten(children, flat) {
        children.map((group) => {
          if (group.parentID === organization.id) {
            if (!group.properties.start) group.properties.start = organization.properties.start;
            if (!group.properties.finish) group.properties.finish = organization.properties.finish;
          }
          flat.push(_.pick(group, GROUP_PROPS));
          if (group.children) {
            group.children.map((child) => {
              if (!child.properties.start) child.properties.start = group.properties.start;
              if (!child.properties.finish) child.properties.finish = group.properties.finish;
            });
            flatten(group.children, flat);
          }
        });
        return flat;
      }

    }

    $scope.registration = {
      selectedGrade: '',
      groups: [],
      addOns: [],
      coupons: [],
      hasCoupons: null,
      overBalance: {},
      insurancePolicies: [],
      donation: 0,
      paymentMethod: {},
      paymentPlan: {},
      isTestAccount: !!session.user.options.includes('"testAccount"=>"true"')
    };

    $scope.steps = [
      'grade-filter',
      'session-select',
      'add-ons',
      'coupons',
      'protection-plan',
      'donation',
      'payment'
    ];


    /* default state for step navigation
     * `isComplete` is passed to each step to disable the continue button
     * `bypass` is passed to each step to allow skipping steps (eg no coupons)
     * `direction` is passed to navigation to determine forward/back for bypass
     * `numSteps` is passed to navigation to keep track of step bounds
     * `current` is passed to navigation to manipulate the current step
     */
    $scope.step = {
      isComplete: () => false,
      bypass: () => null,
      onContinue: () => null,
      disableBack: () => null,
      submit: () => null,
      submitText: 'Register',
      direction: 'forward',
      numSteps: $scope.steps.length,
      current: 0
    };

    $scope.resetRegistration = function(props) {
      // props is an arr of registration properties
      // ie, addons, coupons, insurancePolicies
      props.map(prop => $scope.registration[prop] = []);
      if (_.includes(props, 'coupons')) $scope.registration.hasCoupons = null;
      if (_.includes(props, 'insurancePolicies')) $scope.registration.insuranceBuy = null;
      if (_.includes(props, 'overBalance')) $scope.registration.overBalance = {};
    };

    $scope.regData.fetchPaymentPlan(profile.id).catch(errorHandler);
    $scope.regData.fetchPaymentMethods(session.userID).catch(errorHandler);

    function errorHandler() {
      flash('Error fetching payment data');
      return null;
    }

    /*
    If the client has a slow network and uses a reg link
     it's possible to click `register for a new session`
     before the redirect. And by that time thanks to the state routing
     the catch in the base profile state won't trigger again
     so we have another here to catch the sneaky devils
    */
    if (window.lib.missingUserFields(user))
      return $state.go('home.account.information', { userID: user.id });

  }
});
