// Welcome to the experimental ECMA6 state!
// Unfortunately, we can't use ES6 things like `let`, template strings, and fat arrow functions
// My main focus for this file is to abstract as much stuff away from $scope as possible because that's all practically publicly available
// -Connor
lib.registerState("manager.settings.general.tags", {
  url: "/tags",
  templateUrl: "states/manager/settings/general/tags/group-tags.state.html",
  controller: function($scope, $state, group, organization, $http, flash) {
    'use strict';

    $scope.isOrganization = group.id == organization.id
    $scope.group = group;

    /* Private functions for the page */
    // organize child tags under their parents
    // IIFE because why not
    (function tagParenting () {
      $scope.displayTags = {};
      _.map(organization.tags, function (t) {
        _.map(organization.tags, function (c) {
          if (c.parentID === t.id) {
            $scope.displayTags[t.id] = t;
            if (!$scope.displayTags[t.id].options) { $scope.displayTags[t.id].options = {}; }
            $scope.displayTags[t.id].options[c.id] = c;
          }
        });
        if (!$scope.displayTags[t.id]) { $scope.displayTags[t.id] = t; }
      });
      _.map($scope.displayTags, function (t) {
        if (!_.isNull(t.parentID)) { delete $scope.displayTags[t.id]; }
      });
      _.map($scope.displayTags, function (t) {
        if (!$scope.displayTags[t.id].options) { $scope.displayTags[t.id].options = {}; }
      });
    })();
    // end tagParenting

    // reset newTag values
    // not an IIFE because I need to call it elsewhere
    $scope.resetNewTag = function () {
      $scope.newTag = {
        value: "",
        options: {},
        groupID: organization.id
      };
      $scope.addingTag = false;
      $scope.editingTag = false;
      $scope.option = "";
    };
    $scope.resetNewTag();
    // end resetNewTag

    // main function to hit group-tag related routes
    function routeHitter (method, body, done) {
      var route = '/api/organizations/' + organization.id + '/group-tags';
      if (method === "delete") { route += '?tagID=' + body.id; }

      $http[method](route, body).then (
        function (success) { return done(success); },
        function (error) { return done(error); }
      );
    }
    // end routeHitter

    // configurable error swal generator
    function errorSwal (method, response) {
      var errMsg = JSON.stringify(response.data);
      window.swal({
        type: "error",
        title: "Unexpected Error",
        text: 'An error occurred while ' + method + ' this tag. \n\n' + errMsg + '\n\n Please reload the page and try again.',
        allowOutsideClick: true,
        confirmButtonText: "Reload Page"
      }, function (reloadPage) {
        if (reloadPage) { return $state.reload(); }
      });
    }
    // end errorSwal
    /* End private functions for the page */

    //enter support
    $scope.newLabel = function() {
      if ($scope.newTag) {
        $scope.saveTag($scope.newTag);
      }
    };

    /* Operations for a free-text/parent tag */
    // create/update a tag
    $scope.saveTag = function (newTag) {
      if (!newTag.value) { return; }

      if (newTag.id) {
        return routeHitter("put", newTag, function (result) {
          if (result.status === 200) {
            $scope.displayTags[result.data.id] = result.data;
            $scope.resetNewTag();
            return flash(result.data.value + ' Updated!');
          }
          else { return errorSwal("updating", result); }
        });
      }
      else {
        var tag = { groupID: $scope.organization.id, value: newTag.value };

        return routeHitter("post", tag, function (result) {
          if (result.status === 201) {
            $scope.displayTags[result.data.id] = result.data;
            $scope.resetNewTag();
            return flash(tag.value + ' Created!');
          }
          else { return errorSwal("creating", result); }
        });
      }
    };
    // end newTag

    // edit the value or options for a specific tag
    $scope.editTag = function (tag) {
      $scope.editingTag = true;
      $scope.newTag = _.cloneDeep(tag);
      if (!$scope.newTag.options) { $scope.newTag.options = {}; }
    };
    // end editTag


    // delete an existing tag
    $scope.deleteTag = function (tag) {
      if (!tag.id) { return; }

      window.swal({
        type: "warning",
        title: "Deleting Tag",
        text: "You are about to delete the tag " + tag.value + " and its options.\n\nThis tag will be removed from all profiles. This cannot be undone.\n\nAre you sure you want to delete this tag?",
        confirmButtonText: "Delete Tag",
        allowOutsideClick: true,
        showCancelButton: true
      }, function (isConfirm) {
        if (isConfirm) {
          return routeHitter("delete", tag, function (result) {
            if (result.status === 200) {
              delete($scope.displayTags[tag.id]);
              $scope.resetNewTag();
              return flash(tag.value + ' Deleted!');
            }
            else { return errorSwal("deleting", result); }
          });
        }
      });
    };
    // end deleteTag

    // deactivate or reactivate a tag
    $scope.deReactivateTag = function (tag) {
      tag.deactivated = tag.deactivated ? null : new Date();

      if ($scope.displayTags[tag.id].options && Object.keys($scope.displayTags[tag.id].options).length) {
        _.each($scope.displayTags[tag.id].options, function (o) {
          o.deactivated = tag.deactivated;
          return routeHitter("put", o, function (result) {
            if (result.status === 200) {
              if (result.data.deactivated) { flash(result.data.value + ' Deactivated!'); }
              if (!result.data.deactivated) { flash(result.data.value + ' Reactivated!'); }
              $scope.displayTags[result.data.parentID].options[result.data.id] = result.data;
              return;
            }
            else { return errorSwal("updating", result); }
          });
        });
      }
      return routeHitter("put", tag, function (result) {
        if (result.status === 200) {
          if (result.data.deactivated) { flash(result.data.value + ' Deactivated!'); }
          if (!result.data.deactivated) { flash(result.data.value + ' Reactivated!'); }
          $scope.displayTags[result.data.id] = result.data;
          return;
        }
        else { return errorSwal("updating", result); }
      });
    };
    // end deReactivateTag
    /* End operations for a free-text/parent tag */

    //enter support
    $scope.newOptions = function() {
      if ($scope.option) {
        $scope.createOption($scope.option);
      }
    };

    /* Operations for an option tag */
    // create an option for a tag
    $scope.createOption = function (option) {
      if (!option || !$scope.newTag.id) { return; }

      var optionTag = {
        parentID: $scope.newTag.id,
        value: option,
        groupID: organization.id,
        deactivated: $scope.newTag.deactivated
      };

      return routeHitter("post", optionTag, function (result) {
        if (result.status === 201) {
          if (!$scope.displayTags[optionTag.parentID].options) {
            $scope.displayTags[optionTag.parentID].options = {};
          }
          $scope.displayTags[optionTag.parentID].options[result.data.id] = result.data;
          $scope.newTag = _.cloneDeep($scope.displayTags[optionTag.parentID]);
          $scope.option = "";
          return flash(result.data.value + " option created!");
        }
        else { return errorSwal("creating", result); }
      });
    };
    // end createOption

    // edit an option tag
    $scope.updateOption = function (option, newVal) {
      if (option.value === newVal) { return; }

      var updateBody = {
        tag: option,
        updates: { value: newVal }
      };
      option.value = newVal;
      return routeHitter("put", option, function (result) {
        if (result.status === 200) {
          $scope.displayTags[option.parentID].options[option.id] = result.data;
          flash(option.value + " Updated!");
          return $scope.resetNewTag();
        }
        else { return errorSwal("updating", result); }
      });
    };
    // end editOption

    // delete an option tag
    $scope.deleteOption = function (option) {
      if (!option.id || !option.parentID) { return; }

      window.swal({
        type: "warning",
        title: "Deleting Option",
        text: "You are about to delete the option " + option.value + ".\n\nTags on any profiles with this option will be removed. This cannot be undone.\n\nAre you sure you want to delete this option?",
        confirmButtonText: "Delete Option",
        allowOutsideClick: true,
        showCancelButton: true
      }, function (isConfirm) {
        if (isConfirm) {
          return routeHitter("delete", option, function (result) {
            if (result.status === 200) {
              delete($scope.displayTags[option.parentID].options[option.id]);
              $scope.newTag = _.cloneDeep($scope.displayTags[option.parentID]);
              return flash(option.value + ' Deleted!');
            }
            else { return errorSwal("deleting", result); }
          });
        }
      });

    };
    // end deleteOption
    /* End operations for an option tag */

  }
  // end controller
});
// end state
