angular.module('dn').service('$vuex', (() => {
  'use strict';

  const ERR_TAG = '[$vuex]';
  let _store;
  if (window.vuetils && window.vuetils.store) {
    // Grab access to the store and stash it within the closure
    _store = window.vuetils.store;
    // Delete it off of window so we don't have global access
    delete window.vuetils.store;
  } else {
    log.error(ERR_TAG, 'vuetils not yet defined.');
  }

  class VuexError extends Error {
    constructor(callingFn, message) {
      const tag = `${ERR_TAG}[${callingFn}]`;
      super(`${tag} ${message}`);
    }
  };

  class $vuex {
    constructor() {
      this.state = _store.state;
    }

    _handleError(callingFn, messageOrError) {
      // Figure out the message
      let message;
      if (typeof messageOrError === 'string') message = messageOrError;
      else message = messageOrError.message;
      // Log and throw the error
      const error = new VuexError(callingFn, message);
      log.error(error);
      throw error;
    }

    commit(mutation, payload) {
      try {
        return _store.commit(mutation, payload);
      } catch (error) {
        this._handleError('commit', error);
      }
    }

    dispatch(action, payload) {
      return _store.dispatch(action, payload);
    }

  };

  return $vuex;

})());
