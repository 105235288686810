"use strict";

lib.registerState("manager.profiles.createProfile", {
  url: "/create-profile",
  templateUrl: "states/manager/profiles/create-profile/create-profile.state.html",
  resolve: {
    title: ($rootScope) => {
      $rootScope._title = "Create a Profile"
    }
  },

  controller: ($scope, $state, $stateParams, Profile, organization, $http, session) => {
    $scope.profile = new Profile();
    $scope.profile.organizationID = parseInt($stateParams.orgID);
    $scope.profile.set = { registrations: [] };

    $scope.searched = false;
    $scope.searching = false;

    $scope.sexes = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ];

    // Get only the YYYY-MM-DD portion
    $scope.today = new Date().toISOString().slice(0,10);

    // If we haven't checked for dups, we need to do that before creating the profile
    $scope.searchAndCreate = () => $scope.searched ? createProfile() : $scope.search();

    // Look for dups with the entered name
    $scope.search = () => {
      $scope.searching = true;
      route = `/api/organizations/${organization.id}/search-profiles`;
      route += `?givenName=${$scope.profile.givenName}`;
      route += `&familyName=${$scope.profile.familyName}`;

      $http.get(route).then((result) => {
        $scope.searching = false
        $scope.searched = true
        $scope.matches = result.data
        // If we didn't find any potential dups, safe to just create it
        if (!$scope.matches || !$scope.matches.length) {
          createProfile();
        } else {
          $scope.matches.map((match) => {
            match.accessible = session.access.profiles.indexOf(match.id) != -1;
          });
        }
      });
    };

    createProfile = () => {
      $scope.saving = true;
      $scope.profile.save().then((profile) => {
        $scope.saving = false;

        // Need to reaccess to add new profile into session
        $http.get("/api/session?reaccess").then((result) => {
          $state.go("manager.profiles.profile", { profileID: profile.id }, { reload: true });
        });
      });
    };
  },
});
