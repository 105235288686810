# This is the controller for the UI of the custom report builder.
# We'll handle saving and loading reports, as well as selecting questions,
# options, and formats, and then generating a report URL from that configuration.

angular.module("dn").directive "reportWizard", ->
  templateUrl: "directives/report-wizard/report-wizard.directive.html"
  restrict: "A"
  scope:
    area: "@"
    profiles: "="
    profileCount: "="
    organization: "="
    selectedProfiles: "="
    user: "="

  controller: ($scope, $location, flash, $http, $timeout, $filter) ->
    orgID         = $scope.organization.id
    loadingFilter = false
    $scope.filters = -> $location.search().filters or ""

    $scope.isFullTextSearchEnabled = () ->
      return window.lib.featureFlagClient.isEnabled("FullTextSearchProfiles")

    $scope.reportTypes = [
      { label: "Custom Report", value: "custom" }
      { label: "Summary Report", value: "summary" }
    ]
    $scope.reportType = "standard"

    omit = ["instructions", "step", "form", "download"]

    mapSaved = ->
      $scope.savedReports = $scope.user?.savedReports
      $scope.savedReportSelect = _($scope.savedReports)
      .map (r) ->
        { label: r.name, value: r.id.toString(), optgroup: if r.share then "Shared" else "General" }
      .sortBy('label')
      .value()

    do mapSaved

    $scope.loaded = null
    $scope.saveType = "new"
    $scope.ids = []
    $scope.formats = [{ label: "CSV", value: ".csv" }, { label: "PDF", value: ".pdf" }]
    $scope.format = ".pdf"
    $scope.includeDetails = true

    $scope.details = {}
    $scope.anyDetails = -> !!_.size $scope.details
    $scope.detailChoices = [
      { label: "Yes", value: "yes" }
      { label: "No", value: "no" }
      {label: "Either", value: "either" }
    ]

    $scope.showChoices = [
      { label: "'Yes' Answers", value: "yes" }
      { label: "'No' Answers", value: "no" }
      { label: "All Answers", value: "none" }
    ]
    $scope.showOnly = "none"

    $scope.includeChoices = [
      { label: "All questions", value: "all" }
      { label: "At least one question", value: "any" }
    ]
    $scope.include = "all"

    $scope.pdfTypes = [
      { label: "Individual", value: "individual" }
      { label: "Combined", value: "combined" }
      { label: "Condensed", value: "condensed" }
    ]
    $scope.pdfType = "individual"

    ###
    # Choices arrays for PDF advanced options
    ###
    $scope.imageTypes = [
      { label: "Not Included", value: "no" }
      { label: "Included", value: "yes" }
      { label: "Images Only", value: "only" }
    ]

    $scope.advancedChoices = [
      { label: "Not Included", value: "false" }
      { label: "Included", value: "true" }
    ]

    # AP-4972 - We are only setting includeEmpty to true as part of an issue with reports.
    # This will be restored once we get the go ahead from the business.
    $scope.includeEmptyOptions = [
      { label: "Included", value: "true" }
    ]

    $scope.templateShareChoices = [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ]
    $scope.shareTemplate = false

    $scope.setDefaults = () ->
      # If a template has been loaded, ignore this function
      return if $scope.loaded

      $scope.imageType = "no"
      $scope.excludeExpired = "false"
      $scope.includeNotes = "false"
      $scope.includePast = "false"
      $scope.regInfo = "true"
      $scope.includeEmpty = "true"
      $scope.accountBalance = "false"
      $scope.paymentPlans = "false"
      $scope.addOnsReport = "false"
      $scope.couponsReport = "false"

    # end advanced options

    $scope.setDefaults()

    # Because templateShareChoices are casted to '0' and '1' at some point
    numToBool = (num) ->
      if parseInt num then return true else return false

    bothChoices = [
      {label: "New Report", value: "new"}
      {label: "Existing Report", value: "existing"}
    ]

    $scope.$watch "reportType", (type, stale) ->
      if type is "summary" then $scope.format = ".pdf"
      return if type is stale
      return if loadingFilter

      # Reset since we changed our report type
      $scope.details = {}
      _.map $scope.questions, (q) ->
        q._selected = false
        q._disabled = false
        if type is "summary" and q.type isnt "boolean" then q._disabled = true

    , true

    newOnly = [ { label: "New Report", value: "new" } ]

    $scope.$watch "savedReports", (reports, stale) ->
      $scope.saveTypes = newOnly
      if reports?.length then $scope.saveTypes = bothChoices
      $scope.saveType = "new"
    , true

    toBoolean = (val) ->
      if val is "true" then return true
      return false


    # Load a saved report after selected a reportID
    # Checkboxes have to have boolean models, but since we generated a saved
    # report from a URL string, they're all going to be strings coming in.
    $scope.loadReport = (reportID) ->
      if $scope.loaded is reportID then return
      report = _.find $scope.savedReports, (r) -> r.id.toString() is reportID.toString()
      return unless report?.conditions

      # Overwrite properties in current scope
      loadingFilter           = true
      $scope.savedReport      = report.id
      $scope.loaded           = report.id
      $scope.savedReportName  = report.name
      $scope.s3ReportName     = report.name  # Suggest report names on download
      $scope.format           = report.conditions.format
      $scope.combinedPDF      = toBoolean report.conditions.combined
      $scope.includeImages    = toBoolean report.conditions.includeImages
      $scope.onlyImages       = toBoolean report.conditions.onlyImages
      $scope.details          = report.conditions.details or {}
      $scope.showOnly         = report.conditions.showOnly or "none"
      $scope.includeDetails   = toBoolean(report.conditions.includeDetails) or true
      $scope.reportType       = report.conditions.reportType or "custom"
      if $scope.reportType is "summary" then $scope.format = ".pdf"
      if report?.conditions?.combined && !report?.conditions?.condensed then $scope.pdfType = "combined"
      else if report?.conditions?.condensed then $scope.pdfType = "condensed"
      else $scope.pdfType = 'individual'

      # Overwrite display variables so actual values are reflected in browser.
      $scope.excludeExpired   = if toBoolean(report.conditions.excludeExpired) then "false" else "true"
      $scope.includeNotes     = report.conditions.includeNotes || "false"
      $scope.includePast      = report.conditions.includePast || "false"
      $scope.imageType        = "only" if $scope.onlyImages
      $scope.imageType        = "yes" if $scope.includeImages
      $scope.regInfo          = if toBoolean(report.conditions.excludeRegInfo) then "false" else "true"
      $scope.accountBalance   = if report?.conditions?.include?.match(/balance/g) then "true" else "false"
      $scope.paymentPlans     = if report?.conditions?.include?.match(/paymentPlans/g) then "true" else "false"
      $scope.addOnsReport     = if report?.conditions?.include?.match(/addOns/g) then "true" else "false"
      $scope.couponsReport    = if report?.conditions?.include?.match(/coupons/g) then "true" else "false"

      # Set question selected values from the report config
      $timeout ->
        _.map $scope.questions, (q) ->
          if q.id.toString() in report.conditions.healthForm then q._selected = true
          else if q._selected then q._selected = false

          if $scope.reportType is "summary" and q.type isnt "boolean" then q._disabled = true

      # After we do that, disable this guard so that $scope.checked() can run properly.
      # It was enabled to avoid doubling up the calls, raising the count too much per step.
      $timeout ->
        updateCount($scope.questions)
        loadingFilter = false


    # Generate a report from a generated URL
    # We basically split query parameters into key->value
    $scope.generateSave = ->
      string = generateUrl()
      conditions = { name: $scope.reportName }
      if string.match "profiles.pdf" then conditions.format = ".pdf"
      else conditions.format = ".csv"

      params = _.tail string.split "&"
      _.map params, (p) ->
        vals = p.split "="
        return if vals[0] is "name"
        if vals[0] is "healthForm" then vals[1] = vals[1].split("|")
        if vals[0] is "details"
          vals[1] = _.reduce vals[1].split("|"), (result, val) ->
            result[val.split(":")[0]] = val.split(":")[1]
            result
          , {}

        conditions[vals[0]] = vals[1]

      conditions.reportType = $scope.reportType

      report =
        name: $scope.reportName
        conditions: conditions
        userID: $scope.user?.id
        type: "report"

    # Save over-top of an existing report
    $scope.saveExistingReport = (reportID) ->
      report = _.find $scope.savedReports, (r) -> r.id.toString() is reportID.toString()
      newReport = $scope.generateSave()
      report.conditions = newReport.conditions
      if numToBool $scope.shareTemplate
        report.share = true
        report.groupID = $scope.organization.id
      delete report.conditions.filterNames

      $http.post("/api/users/#{$scope.user?.id}/filters", report).then (result) ->
        saved = result.data
        flash "Report Saved"
        $scope.savedReport = saved.id.toString()
        $scope.saveDialog = false
        do mapSaved


    # Save a new report. Report object generation in $scope.generateSave()
    $scope.saveNewReport = ->
      report = $scope.generateSave()
      if numToBool $scope.shareTemplate
        report.share = true
        report.groupID = $scope.organization.id

      delete report.conditions.filterNames
      $http.post("/api/users/#{$scope.user?.id}/filters", report).then (result) ->
        saved = result.data
        flash "Report Saved"
        $scope.savedReports.push result.data
        $scope.savedReport = saved.id.toString()
        $scope.saveDialog = false
        do mapSaved


    # Delete a saved report
    $scope.deleteReport = (savedReport) ->
      $http["delete"]("/api/users/#{$scope.user?.id}/filters/#{savedReport}").then (result) ->
        $scope.user?.savedReports = _.reject $scope.user?.savedReports, (f) ->
          +f.id is +savedReport
        do mapSaved
        $scope.savedReport = null
        $scope.deleteDialog = false
        flash "Report Deleted"


    # Create global questions array
    $scope.questions = []

    checkEncryptedPermission = (type) ->
      # Some question types have permissions attached to them
      # We filter them out if the user does not have permission to see them
      if type == "encrypted"
        return $filter('permissionVisible')({ profile_encrypted_answers: 'view' })
      else
        return true

    # Basic recursive traversal through questions
    # We assign a stepID so we know how to increment the count, since we're
    # technically "rendering" all of the questions in the DOM, but only
    # showing those for the step we selected.
    flatten = (questions, depth, stepID, array) ->
      return unless questions?.length
      _.map questions, (q) ->
        q.depth = depth
        if q.type is "step" then stepID = q.id
        else q.stepID = stepID
        q.label = q.body if q.body and q.label == " "
        q.label = _.capitalize q.type if !q.body and q.label == " "
        if q.type not in omit and checkEncryptedPermission(q.type)
          array.push q
          flatten q.subQuestions, depth + 1, stepID, array
        else
          flatten q.subQuestions, depth, stepID, array
      return array

    q = $scope.organization.newQuestionnaire.questions
    $scope.questions = flatten q, 0, 0, $scope.questions

    # Select steps on the left
    $scope.steps = _.map $scope.organization.newQuestionnaire.questions, (q) ->
      if (!_.some $scope.questions, (question) -> question.stepID is q.id) then return null
      return _.pick q, ["id", "label"]

    $scope.steps        = _.compact $scope.steps
    $scope.selectedStep = $scope.steps[0]
    $scope.select       = (step) -> $scope.selectedStep = step


    # setCount() creates an object, keyed by step IDs, valued by total number of
    # associated questions. Totals begin at 0. Calling setCount() 'cleans' the count
    # object by setting counts back to 0. updateCount() is the primary interactor for
    # setCount()
    setCount = () ->
      $scope.count = $scope.steps.reduce (result, step) ->
        result[step.id] = 0
        result
      , {}

    # set question IDs on scope
    setIDs = () ->
      $scope.ids = _.map (_.filter $scope.questions, { _selected: true }), "id"

    # updateCount() checks whether a question is selected and increments its step's
    # count
    updateCount = (questions) ->
      # clean count object
      setCount()
      # ensure the ids on scope are the ones selected
      $scope.ids = []
      # iterate over each question
      _.forEach questions, (question) ->
        # checking if it's been selected; if so, increment
        if question._selected
          $scope.ids.push question.id
          $scope.count[question.stepID] += 1



    # Check/uncheck subQuestions when checked/unchecked
    # Also add the IDs to $scope.ids
    # Increment/decrement the count for that specific step
    $scope.checked = (question) ->
      _.map question.subQuestions, (q) -> q._selected = question._selected
      setIDs()

      # every time you uncheck a question, delete from $scope.details if applicable
      # fix for issue #2841
      # updated fix to resolve issues repo #903 & #904

      if !question._selected && $scope.details[question.id]
        delete $scope.details[question.id]
        # call updateCount on all questions each time to get the cleanest count
      updateCount($scope.questions)

    $scope.selectAll = (step) ->
      _.map $scope.questions, (s) ->
        return if step and s.stepID isnt step.id
        if $scope.reportType isnt "summary" or s.type is "boolean" then s._selected = true
      # fresh count
      updateCount($scope.questions)

    $scope.selectNone = (step) ->
      _.map $scope.questions, (s) ->
        if !step or (s.stepID is step.id)
          if s._selected then s._selected = false
      # if we don't have a step, user hit general selectNone; so, remove all counts
      if !step then setCount()
      setIDs()

    $scope.disableExistingSave = ->
      return !$scope.overwrite || !$scope.ids.length || (_.find($scope.savedReports, { id: +$scope.overwrite }).userID != $scope.user.id)

    # Generate report url
    generateUrl = ->

      # $scope.ids are all of our selected questions
      return null unless $scope.ids?.length
      ids = $scope.ids.join "|"
      type = if $scope.reportType is "custom" then "profiles" else "summary"

      # Basic URL pieces
      url = "/api/organizations/#{orgID}/#{type}#{$scope.format}"
      url += "?filters=#{encodeURIComponent($scope.filters())}"
      url += "&healthForm=#{ids}"

      # Have we selected specific profiles for report generation?
      if (_.some $scope.profiles, (p) -> p.selected)
        profileIDs = _.map(_.filter($scope.profiles, (p) -> p.selected), "profiles.id").join("|")
        url += "&profileIDs=#{profileIDs}"
      else if $scope.profiles.length
        url += "&profileCount=#{$scope.profiles.length}"

      # Gather details from selected IDs in case they want specific answers
      details = []
      _.map $scope.ids, (id) ->
        if $scope.details[id] and $scope.details[id] isnt "either" and $scope.details[id] isnt ""
          details.push "#{id}:#{$scope.details[id]}"

      if details.length then url += "&details=#{details.join('|')}"

      # PDF options to choose from
      if $scope.format is ".pdf"
        if $scope.pdfType is "combined" then url += "&combined=true"
        if $scope.pdfType is "condensed" then url += "&combined=true&condensed=true"
        if $scope.imageType is "yes" then url += "&includeImages=true"
        if $scope.imageType is "only" then url += "&onlyImages=true"
        if toBoolean $scope.includeNotes then url += "&includeNotes=true"
        if toBoolean $scope.includePast then url += "&includePast=true"
        if !toBoolean $scope.excludeExpired then url += "&excludeExpired=true"
        if !toBoolean $scope.regInfo then url += "&excludeRegInfo=true"

      # CSV options to choose from
      if $scope.format is ".csv"
        if toBoolean $scope.includeEmpty then url += "&includeEmpty=true"
        if !toBoolean $scope.excludeExpired then url += "&excludeExpired=true"

        if toBoolean $scope.accountBalance
          if url.match /include=/g
            url = url.replace /include=/g, "include=balance|"
          else
            url += "&include=balance"

        if toBoolean $scope.paymentPlans
          if url.match /include=/g
            url = url.replace /include=/g, "include=paymentPlans|"
          else
            url += "&include=paymentPlans"

        if toBoolean $scope.addOnsReport
          if url.match /include=/g
            url = url.replace /include=/g, "include=addOns|"
          else
            url += "&include=addOns"

        if toBoolean $scope.couponsReport
          if url.match /include=/g
            url = url.replace /include=/g, "include=coupons|"
          else
            url += "&include=coupons"

      if $scope.include is "any" and $scope.anyDetails() then url += "&any=true"

      # Summary report options
      if $scope.reportType is "summary"
        if $scope.showOnly isnt "all" then url += "&showOnly=#{$scope.showOnly}"
        url += "&includeDetails=#{$scope.includeDetails}"

      # Filter names from filterBuilder to put on the PDF header
      filterNames = sessionStorage.getItem "appliedFilterNames"
      url += "&filterNames=#{encodeURIComponent(filterNames)}"

      ## New reports UI
      url += "&sendToS3=true"
      if !!$scope.s3ReportName then url += "&name=#{encodeURIComponent($scope.s3ReportName)}"

      url

    noNameErrSwal = ->
      return swal {
        title: 'No Name Provided',
        text: 'Request your report again and be sure to name it.',
        type: 'error',
        closeOnConfirm: true,
        confirmButtonText: 'OK',
        showCancelButton: false,
        showLoaderOnConfirm: true
      }
    nameReport = ->
      defaultCustom = "Custom #{$scope.pdfType.capitalize()} Report"
      defaultSummary = "Summary Report"
      # If it's a saved report s3ReportName will already be set. If not, suggest
      # a sensible default
      $scope.s3ReportName ?= if $scope.reportType == "custom" then defaultCustom else defaultSummary
      return new Promise (resolve, reject) ->
        swal {
          title: 'Name Your Report',
          text: 'Please enter a name for your report download.',
          type: 'input',
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Submit',
          showCancelButton: true,
          inputValue: $scope.s3ReportName
        }, (input) ->
          if !input
            if input == '' then return reject(new Error("Report Not Named"))
            else return reject(new Error('Report Canceled'))
          $scope.s3ReportName = input
          resolve()

    configureReport = () ->
      advancedOptions = {}
      if $scope.format is '.pdf'
        advancedOptions = {
          imageType: $scope.imageType,
          excludeRegInfo: !toBoolean($scope.regInfo),
          includeNotes: toBoolean($scope.includeNotes),
          includePast: toBoolean($scope.includePast),
          customLayout: $scope.pdfType,
          includeEmpty: true
        }
      else
        advancedOptions = {
          accountBalance: toBoolean($scope.accountBalance),
          addOnsReport: toBoolean($scope.addOnsReport),
          couponsReport: toBoolean($scope.couponsReport),
          includeEmpty: toBoolean($scope.includeEmpty),
          paymentPlans: toBoolean($scope.paymentPlans),
        }

      if $scope.isFullTextSearchEnabled() and $scope.selectedProfiles
        profileIDs = $scope.selectedProfiles
      else
        profileIDs = _.map(_.filter($scope.profiles, (p) -> p.selected), "profiles.id")
      baseConfig = {
        details: $scope.details,
        include: $scope.include,
        selectedQuestions: $scope.ids,
        showOnly: $scope.showOnly,
        excludeExpired: !toBoolean($scope.excludeExpired),
      }
      reportConfig = {
        format: $scope.format,
        type: $scope.reportType,
        name: $scope.s3ReportName,
        description: "",
        filters: $scope.filters().split("|"),
        filterNames: sessionStorage.getItem("appliedFilterNames").split("|"),
        include: [],
        sortBy: null,
        groupBy: null,
        profileIDs: profileIDs,
        contentConfig: Object.assign({}, baseConfig, advancedOptions),
        showOnly: $scope.showOnly,
        includeDetails: $scope.includeDetails
      }

    $scope.requestSummaryReport = () ->
      $scope.requesting = true

      nameReport()
        .then () ->
          # generate the new config
          reportConfig = configureReport()

          # generate the old config (query params)
          oldUrl = generateUrl()

          # get all query params from oldUrl as string to append to new route
          queryParams = oldUrl.split('?')[1]

          # hit the new route
          $http.post("/api/organizations/#{orgID}/#{$scope.format}/summary-reports?#{queryParams}", reportConfig)
            .then () ->
              $scope.requesting = false;
              return swal {
                title: "Report Request Successful",
                text: "Head to the DOWNLOADS tab to check for your report. \n\n A standard report may take up to 5 minutes to generate. However, report times vary based on the complexity and number of profiles in the report.",
                type: "success",
                showCancelButton: false,
                closeOnConfirm: true,
                allowOutsideClick: true
              }

          # show all of the swal stuff we normally do
        .catch (err) ->
          if (err.message == 'Report Not Named')
            return noNameErrSwal()

    $scope.requestNewReport = () ->
      $scope.requesting = true
      nameReport()
        .then () ->
          reportConfig = configureReport()
          $http.post("/api/organizations/#{orgID}/new-reports", reportConfig)
            .then () ->
              $scope.requesting = false;
              return swal {
                title: "Report Request Successful",
                text: "Head to the DOWNLOADS tab to check for your report. \n\n A standard report may take up to 5 minutes to generate. However, report times vary based on the complexity and number of profiles in the report.",
                type: "success",
                showCancelButton: false,
                closeOnConfirm: true,
                allowOutsideClick: true
              }
        .catch (err) ->
          if (err.message == 'Report Not Named')
            return noNameErrSwal()

    $scope.requestReport = () ->
      $scope.requesting = true
      nameReport().then () ->
        $http.get(generateUrl())
      .then () ->
        # Don't suggest the same name for the next report unless it's saved
        $scope.s3ReportName = null unless !!$scope.loaded
        $scope.requesting = false;
        return swal {
          title: "Report Request Successful",
          text: "Head to the DOWNLOADS tab to check for your report. \n\n A standard report may take up to 5 minutes to generate. However, report times vary based on the complexity and number of profiles in the report.",
          type: "success",
          showCancelButton: false,
          closeOnConfirm: true,
          allowOutsideClick: true
        }
      .catch (err) ->
        $scope.requesting = false;

        if err.message == 'Report Canceled' then return

        if err.status == 504
          errorConfig = {
            title: "Creating Report",
            text: "Your report is taking longer than expected, but we are still working on it. If you do not see your report in 30 minutes, please try again.",
            type: "info",
            showCancelButton: false,
            closeOnConfirm: true,
            allowOutsideClick: true
          }
        else
          errorConfig = {
            title: "Error Requesting Report",
            text: "Please refresh the page and try again.",
            type: "error",
            showCancelButton: false,
            closeOnConfirm: true
          }
          if err.status == 404
            errorConfig.text = err.data?.message || err.statusText
          else if err.status == 429
            errorConfig.text = "Too many requests, please wait 15 minutes before retrying."
          else if err.message == "Report Not Named"
            return noNameErrSwal()
          else if err.status == 500
            errorConfig.text = err.data?.message || err.statusText
        swal errorConfig
