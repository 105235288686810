angular.module("dn").directive("permissions", function($filter) {
  return {
    restrict: "A",
    priority: 100,

    link: function(scope, elm, attrs) {
      /*
        Store a backup of the element's initial display property
        Set display to none to hide elements until we know they can be shown or not
        Reset display property when we know the element will be shown

        The timeout was pulled from the old permissions directive.
        I believe it's to give the FE a chance to render all elements
          to avoid any reference errors when this directive loads
      */

      const displayBackup = elm.css('display') || 'initial';
      // elm.css('display', 'none');

      return setTimeout(function() {

        let permissions = attrs.permissions;

        if (permissions === "") {
          // elm.css('display', displayBackup);
          return elm.show();
        }

        permissions = JSON.parse(permissions);

        if (!$filter("permissionVisible")(permissions)) {
          if (elm.attr("show-no-permissions")) {
            elm[0].innerHTML = `
              <div class="no-permission">
                <h2>You don't have permission to view this content.</h2>
                <h3>
                  If you think this is in error, talk to your system admin about your permission level.
                </h3>
              </div>
            `
          } else return elm.remove();
        }

        // elm.css('display', displayBackup);
        return elm.show();
      });
    }
  };
});
