lib.registerState('patient.profile.prescreening.form', {
  url: '/form',
  templateUrl: 'states/patient/profile/prescreening/form/form.state.html',
  resolve: {
    title($rootScope, GroupBranding) {
      return $rootScope._title = `${GroupBranding.aliases.prescreening} Form`;
    },
  },
  controller($scope, $state, campgramsOnly, profile, PrescreeningData) {
    'use strict';

    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    // Load all the data
    $scope.profile = profile;
    $scope.template = PrescreeningData.template;
    $scope.formBranding = PrescreeningData.branding.form;

    // Button Configuration
    const buttonConfig = {
      next: Object.defineProperties({}, {
        disabled: {
          configurable: false,
          enumerable: true,
          get() {
            return !PrescreeningData.verifyFormData();
          }
        },
        text: {
          configurable: false,
          enumerable: true,
          get() {
            return 'Submit';
          }
        },
        action: {
          configrable: false,
          enumerable: false,
          value: function() {
            return $state.go('patient.profile.prescreening.confirmation');
          }
        }
      }),
      back: {
        text: 'Return Home',
        action() {
          return $state.go('patient.profile');
        },
        hide: false,
        disabled: false
      }
    };

    $scope.$on('$stateChangeSuccess', () => {
      PrescreeningData.setUiEntry('buttons', buttonConfig);
    });

    const uploadQuestion = PrescreeningData.template.find(question => question.dataType === 'uploads');
    $scope.uploadHandlers = {
      // If we have an upload question, shallow clone its values into `existingUploads` whenever we
      // run the controller. This will happen when we navigate between states.
      existing: !uploadQuestion ? [] : uploadQuestion.value.map((fileObj) => {
        return Object.assign({}, fileObj);
      }),
      /**
       * When an upload completes, stores an info-rich upload object on an upload question's `value`
       * key so we can see previews when we navigate to/from the confirmation state.
       *
       * @param {Object} res The response object from an S3 upload
       * @returns {void}
       */
      onComplete(res) {
        // Attach the image URL so we can display the preview after navigation
        res.url = `/api/profiles/${profile.id}/screening-upload/${res.Key}`;
        // Way more info than we save, but we delegate data prep to saveData in confirmation state
        uploadQuestion.value.push(res);
      },
      /**
       * Removes a file object from an upload question's `value` by matching its ContentDisposition
       * against the object we receive from dropzone.
       *
       * @param {Object} target The file object we want to remove. Comes from dropzone.
       * @returns {void}
       */
      onRemove(target) {
        uploadQuestion.value = uploadQuestion.value.filter((file) => {
          return file.ContentDisposition && !file.ContentDisposition.match(target.name);
        });
      }
    };
  }
});
