angular.module("dn").directive("profilePreview", function() {
  return {
    templateUrl: "directives/importer/profile-preview/profile-preview.directive.html",
    restrict: "E",
    scope: {
      profile: "="
    },

    controller: function($scope, $state) {

      $scope._ = _; /* Isolate scopes make things like lodash and Object unavailable in the HTML (maybe) */

      $scope.minifyProfileIdentifier = function(profileIdentifier) {
        if (profileIdentifier) {
          if (profileIdentifier.length >= 10) {
            return profileIdentifier.toString().slice(0, 7) + '...';
          } else {
            return profileIdentifier;
          }
        } else {
          return 'No Identifier';
        }
      };

      if ($scope.profile.conflicts.length === 0 && $scope.profile.warnings.length === 0) {
        $scope.profile.success = true;
      } else {
        $scope.profile.success = false;
      }

      $scope.profileHeaderTranslations = {
        "givenName": "First Name",
        "familyName": "Last Name",
        "middleName": "Middle Name",
        "identifier": "Identifier",
        "dob": "Date of Birth",
        "sex": "Sex"
      }
    } /* end controller */
  }; /* end return statement */
}); /* end directive */
