angular.module('dn').directive('notificationSettings', function () {
  return {
    templateUrl: 'states/manager/settings/general/notifications/notification.settings.directive.html',
    restrict: 'E',
    scope: {
      canEdit: '=',
      hasInterval: '=',
      name: '@',
      notification: '=',
      noToggle: '=',
      org: '=',
      tooltip: '@'
    },
    controller: function ($scope) {

      $scope.fields = ['header', 'body', 'footer', 'attachment'];

      // This keeps the box checked by default - for cases where the user cannot disable the notifications
      if ($scope.noToggle) {
        $scope.notification.enabled = true;
      }

      // If there's an interval that you can configure, but it isn't set on the notification, set it to 10
      if ($scope.hasInterval && !$scope.notification.interval) {
        $scope.notification.interval = 10;
      }

      // Helper for dynamically setting heading/input ids for arialabelledy
      $scope.idName = $scope.name.toLowerCase().split(' ').join('-');

      // Tracking model variables to set whether or not notification has a certain field
      $scope.has = {
        header: !!$scope.notification.header,
        body: !!$scope.notification.body,
        footer: !!$scope.notification.footer,
        attachment: !!($scope.notification.attachment && $scope.notification.attachment.key)
      }

      $scope.toggle = function (field) {
        if (!$scope.has[field]) {
          delete $scope.notification[field];
          return;
        }
      }

      $scope.uploadComplete = function (result) {
        if (!_.isPlainObject(result)) {
          swal("Error", "There was an error uploading your file", "error");
          return;
        } else {
          $scope.notification.attachment = result;
          return;
        }
      }

      $scope.removeAttachment = function () {
        delete $scope.notification.attachment;
        return;
      }

      $scope.checkIntervalRange = function () {
        if ($scope.notification.interval < 1) {
          $scope.notification.interval = 1;
        } else if ($scope.notification.interval > 30) {
          $scope.notification.interval = 30;
        } else if (!$scope.notification.interval) {
          $scope.notification.interval = 10
        }
        return;
      }
    }
  }
});
