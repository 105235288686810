angular.module("dn").directive "healthLogEntry", ->
  templateUrl: "directives/health-log/health-log-entry.directive.html"
  restrict: "A"
  scope:
    entry: "="
    locked: "="
    profile: "="
    user: "="
    timezone: "="
  controller: ($filter, $scope, Images, LogEntry) ->
    # timezone handling
    # if timezone undefined, moment formats without failure
    $scope.formatDate = (date) ->
      if date then moment.tz(date, $scope.timezone).format('MM/DD/YYYY') else ""
    $scope.formatTime = (time) ->
      if time then moment.tz(time, $scope.timezone).format('hh:mm A') else ""
    $scope.formatDateTime = (dateTime) ->
      if dateTime then moment.tz(new Date(dateTime), $scope.timezone).format('MM/DD/YYYY hh:mm A') else ""

    if $scope.entry.fullPage then $scope.entry.show = true

    $scope.toggleShow = ->
      if $scope.entry.fullPage then return
      if $scope.locked then return
      $scope.entry.show = !$scope.entry.show

    sectionType = if $scope.entry.logType is "behavioral-health" then "behavioralHealth" else "generalHealth"
    $scope.sections = _.filter _.cloneDeep(LogEntry.sections[sectionType]), (s) ->
      s.subsections = _.filter s.subsections, (sub) ->
        return false if sub.label in ["Chief Complaint", "Disposition"]
        if sub.models[0].model is "providerID" then return false
        $scope.entry[sub.models[0].model] or $scope.entry.physicalExam?[sub.models[0].model]
      s.subsections?.length

    $scope.formatBehavioralSingleAnswer = (answer) ->
      if answer[0] is '{' then return answer.split('"')[1]
      if typeof answer is "object" then return answer.join(", ")

    $scope.isBehavioralAnswer = (option) ->
      behavioralHealthOptions = ["mood", "affect", "cognitiveFunctioning", "bra", "siba", "anra"]
      return behavioralHealthOptions.includes(option)

    $scope.entry.fileLinks = $filter("md") _($scope.entry.files or [])
      .map (url, index) -> "[Page #{index+1}](#{url})"
      .value()
      .join("\n")

    $scope.getProvider = (scope) ->
      return unless scope
      if scope.provider then return scope.provider
      return $scope.getProvider scope.$parent

    $scope.provider = $scope.getProvider $scope

    # Parse followup
    if $scope.entry.followUp
      $scope.entry.followUp = moment($scope.entry.followUp).format "MM/DD/YYYY hh:mm a"

    $scope.hideReview = ->
      return true unless $scope.provider && $scope.user
      return _.some $scope.entry.addendum, (a) ->
        a.review is true and a.providerID is $scope.provider?.id

    $scope.saveReview = () ->
      review =
        content: "Reviewed by #{$scope.provider?.name}"
        userID: $scope.user.id
        providerID: $scope.provider?.id
        review: true
        timestamp: moment().utc().format()
        name: $scope.provider?.name

      $scope.entry.addendum ?= []
      $scope.entry.addendum.push review

      stringEntry = _.cloneDeep $scope.entry
      stringEntry.addendum = JSON.stringify stringEntry.addendum
      new LogEntry(stringEntry).save()

    $scope.getImage = (image) ->
      # I was dumb and didn't include the key in the original save response
      # so all addendum uploads prior to this commit will not have a .key property.
      # this has now been changed, so all new addendums will have a .key available.
      key = image.key or (image.url.match /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g)[0]

      Images.getForProfile($scope.entry.patientID, key).then (urls) ->
        window.open urls[0]

    #this function determines whether to show a default value based on the properties of the entryModel object.
    $scope.shouldShowDefaultValue = (entryModel) ->
      entryModel.model isnt 'file' and
      not entryModel.exam and
      entryModel.model isnt 'medicationID' and
      not $scope.isBehavioralAnswer(entryModel.model)
