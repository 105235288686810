angular.module('dn').directive('providerRoles', function() {
  return {
    templateUrl: 'directives/permissions/provider-roles.directive.html',
    restrict: 'E',
    scope: {
      organization: '=',
      profiles: '=',
      filters: '=',
      back: '=',
      fullTextSearchEnabled: '='
    },
    controller: function($scope, $rootScope, $http, flash, GroupBranding) {

      $scope.roleChoices = $scope.organization.roles.map((r) => { return { label: r.name, value: r.id }; });

      $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission);

      /* Can't use the feature unless you're filtering for provider profiles */
      /* eslint-disable no-unused-vars */
      $scope.$watch('filters', (filters, stale) => {
        if (filters.find(f => f.object.path === '[registrations].type' && f.object.value === 'provider')) {
          $scope.missingProviderFilter = false;
        } else {
          $scope.missingProviderFilter = true;
        }
      }, true);

      $scope.findRole = function() {
        $scope.selectedRole = $scope.organization.roles.find(r => r.id === parseInt($scope.selectedRoleID));
      };

      $scope.yesOrNo = function(boolVal) {
        if (boolVal) return 'Yes';
        else return 'No';
      };

      $scope.viewOrEdit = function(permission) {
        if ($scope.selectedRole) {
          if (permission === 'view') return 'View';
          else if (permission === 'edit') return 'Edit';
          else return 'Restricted';
        } else return '';
      };

      $scope.fixingBilling = function(permission) {
        if ($scope.selectedRole) {
          /*
            If billing permissions have been set to "edit",
            show them as "view" in the app, since the move to Bill.com,
            the edit and view permissions are functionally the same.
          */
          if (permission === 'edit') return 'View';
          else return $scope.viewOrEdit(permission);
        } else return '';
      };

      $scope.questionnaireName = GroupBranding.aliases.healthProfile;

      $scope.grantRole = function() {
        const selectedProfileIDs = () => getSelectedProfiles();

        if ($scope.fullTextSearchEnabled && !selectedProfileIDs().length) {
          return window.swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to add role.'
          });
        }

        if (!selectedProfileIDs().length) return;

        $http.post('/api/profiles-roles', { profileIDs: selectedProfileIDs(), roleID: $scope.selectedRole.id, organizationID: $scope.organization.id }).then(
          function(success) {
            flash('Role successfully added');

          }, function(err) {
            flash('Error adding role to profiles');
          }
        );
      };

      $scope.revokeRole = function() {
        const selectedProfileIDs = () => getSelectedProfiles();

        if ($scope.fullTextSearchEnabled && !selectedProfileIDs().length) {
          return window.swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to revoke role.'
          });
        }

        if (!selectedProfileIDs().length) return;

        $http.put('/api/profiles-roles', { profileIDs: selectedProfileIDs(), roleID: $scope.selectedRole.id, organizationID: $scope.organization.id }).then(
          function(success) {
            flash('Role successfully revoked');
          },
          function(err) {
            flash('Error revoking role');
          }
        );
      };

      $scope.prescreeningFeatureFlag = $rootScope.featureFlags.prescreening;

      function getSelectedProfiles() {
        return $scope.profiles.reduce((profileIDs, profile) => {
          if (profile.selected) profileIDs.push(profile.profiles.id);
          return profileIDs;
        }, []);
      }
    }
  };
});
