# Directive to create and apply filters
#
# appliedFilters: array of (likely empty) filters already applied
# area:           'manager', 'provider', etc...
# collection:     array of profiles to filter
# organization:   $scope.organization, used for $selectableChildren()

angular.module("dn").directive "filterBuilder", ->
  templateUrl: "directives/filter-builder/filter-builder.directive.html"
  restrict: "A"
  scope:
    appliedFilters: "="
    area: "@"
    collection: "="
    organization: "="
    lists: "="
    activeList: "="
    user: "="
    selectedProfiles: "="
    listJump: "="
    collectionError: "="
    export: "&"
    preloadFilter: "="
    applying: "="
    searchValue: "="

  controller: ($scope, $location, $timeout, $filter, FilterSets, $http, $rootScope, AttendanceData) ->

    # Init and map filter set
    Filter = window.lib.Filter
    $scope.newFilter = {}
    $scope.preset = {}
    $scope.area ?= "manager"

    filterPermissionsMap = [
      { optGroup: "permissions", permissions: { manage_providers: "allow" } },
      { optGroup: "financial",  permissions: { financial_ledgers: "view" } },
      { optGroup: "questions", permissions: { profile_questionnaire: "view" } }
    ]

    filterPermissions = filterPermissionsMap.reduce (acc, pf) ->
      acc[pf.optGroup] = $filter('permissionVisible')(pf.permissions)
      acc
    , {}

    $scope.filterSets = FilterSets $scope.organization, $scope.area, filterPermissions

    $scope.paths = _.keyBy $scope.filterSets, (f) -> f.path.value

    $scope.collectionError = false

    $scope.saveDialog = false

    $scope.applying = false

    $scope.division = $rootScope.division

    $scope.healthForm = "health profile"
    if $scope.area is "manager"
      name = $scope.organization.properties.portals?.patient?.sections?.questionnaire?.alias
      if name then $scope.healthForm = name.toLowerCase()

    $scope.areaIn = (areas) ->
      areas.includes $scope.area

    # Blacklist of areas to not evoke runFilters - to prevent erroneous request from being made
    disabledRunFilterAreas = ['funkNotes']

    $scope.enableSaveFilters = $scope.areaIn(['manager', 'healthLog', 'attendance'])

    routeParams =
      manager: "profiles"
      healthLog: "log-entries"
      funkNotes: "funk-notes-report"
      attendance: "attendance-records"
      prescreening: "screenings"

    $scope.collectionTypes =
      manager: "Profiles"
      healthLog: "Log Entries"
      funkNotes: "CampGrams"
      attendance: "Profiles"
      prescreening: "Prescreening"

    filterTypes =
      manager: "profiles-manager"
      healthLog: "health-log"
      attendance: "attendance"
      # can't save filters with funkNotes (yet)

    $scope.makeFilterObjects = (filterString) ->
      return filterString.split("|").map (portion) ->
        return new Filter(portion)

    saveRemap = ->
      $scope.savedFilterMap = _.reduce $scope.lists, (result, list) ->
        # If `.owner` exist list is user created (shared/un-shared)
        if list.owner != null
          result[list.label] = { exists: true, shared: list.optgroup is "Shared" }
          result[list.value] = list.label
        result
      , {}

      $scope.presets = $scope.lists.reduce (presets, list) ->
        if list.optgroup is 'Presets'
          presets[list.value] = true
        presets
      , {}

      # Set up overwriting saved lists
      $scope.saveTypes = [{ label: "New List", value: "new" }]
      $scope.saveTypes.push [{ label: "Overwrite Existing", value: "existing" }]
      $scope.saveType = "new"
      $scope.custom = _.filter $scope.lists, (l) -> l.optgroup isnt "Presets"
      $scope.custom = _.sortBy (_.map $scope.custom, (c) -> { label: c.label, value: c.label }), "label"

      return

    do saveRemap

    $scope.enableListAction = (action) ->
      return unless $scope.enableSaveFilters and !$scope.saveDialog
      if action == 'save'
        return $scope.appliedFilters.length
      else if action == 'delete'
        return unless $scope.savedFilterMap[$scope.activeList.text]
        # Should not be able to delete presets in the manager area (profiles state)
        if $scope.areaIn(['manager']) then return !$scope.presets[$scope.activeList.text]
        return true

    setShareCheckboxToCurrent = () ->
      current = $scope.savedFilterMap[$scope.existingString] && $scope.savedFilterMap[$scope.existingString].shared
      $scope.shareFilter = current

    $scope.$watch "existingString", () ->
      # Some existings are shared, some are not. Default to current state
      setShareCheckboxToCurrent()

    # When the dropdown changes, re-evaluate the checkbox
    $scope.$watch "saveType", () ->
      # Don't share by default
      if $scope.saveType is "new" then $scope.shareFilter = false
      # If overwriting, default to it's current state
      else if $scope.saveType is "existing" then setShareCheckboxToCurrent()

    # Map to path for easy access of choices
    $scope.setMap  = _.keyBy $scope.filterSets, (f) -> f.path.value

    $scope.getListProperties = (list) ->
      isPresetList = undefined
      listIsSaved = $scope.lists.some((l) ->
        if l.value is list.text
          isPresetList = l.optgroup is 'Presets'
          return true
        return false)
      return [isPresetList, listIsSaved]

    groupCheckboxPath = '[registrations].groupParents'

    $scope.setShowPastGroupsCheckbox = (pastGroupsValue) ->
      $scope.setMap[groupCheckboxPath].option.selected = pastGroupsValue
      return $scope.setMap[groupCheckboxPath].option.selected

    # Parse any existing filters in the URL
    parseFromUrl = ->
      $scope.appliedFilters = _.map $scope.existingInUrl, (u) -> new Filter(u)
      $scope.fullString = (_.map $scope.appliedFilters, "string").join("|")
      [isPresetList, listIsSaved] = $scope.getListProperties({text: $scope.fullString})
      $scope.setShowPastGroupsCheckbox false
      $scope.appliedFilters.map (f) ->
        if not isPresetList and f.object.path is groupCheckboxPath
          # When loading saved lists, we want the past groups to be checked and active
          $scope.setShowPastGroupsCheckbox true

    $scope.existingInUrl = _.filter (($location.search().filters or "").split("|")), (f) -> f.length
    if $scope.existingInUrl.length then do parseFromUrl

    # New Filter Path Choices
    $scope.pathChoices = _.map $scope.filterSets, "path"

    # Revert to first preset filters
    $scope.restoreDefaults = ->
      $scope.applyingFromSaved = true
      $scope.appliedFilters = $scope.makeFilterObjects $scope.lists[0].value

    withPreload = (fromUrl) ->
      fromUrl + encodeURIComponent "|#{$scope.preloadFilter}"

    updateAttendanceData = (data) ->
      AttendanceData.clearAllProfiles()
      AttendanceData.loadFromCollections(data)
      AttendanceData.setUiEntry('loading', false)

    runFilters = (nothing, fn=(->)) ->
      return if $scope.areaIn(disabledRunFilterAreas)
      $scope.applying = true
      if $scope.areaIn ['attendance'] then AttendanceData.setUiEntry('loading', true)
      # encode the filters to avoid ampersands from creating bad query params
      $scope.filters = encodeURIComponent($location.search().filters or "")
      # if we're passing in preload filters to apply to each successive collections call
      if $scope.preloadFilter then $scope.filters = withPreload($scope.filters)
      extras = ''
      # if we're dealing with attendance, then we need to flag it in the request so that it
      # returns the right attendance targets: attendance_records, trusted_contacts, and profileTags
      if $scope.areaIn ['attendance'] then extras = 'attendance=true&includeAvatar=md&'
      if $scope.areaIn ['healthLog'] then extras = 'limit=50&'

      $scope.fullTextEnabled = window.lib.featureFlagClient.isEnabled('FullTextSearchProfiles');
      # Include the query param for minimized results
      if $scope.fullTextEnabled and $scope.areaIn(['manager'])
        findProfileSearchCount()

        # These are the filters that require post processing that are the exception to limiting profile results.
        postProcessFilters = [
          '[users].email',
          '[paymentPlans].remaining',
          '[allergies].type(in:food_drug_environmental)',
          '[registrations].type(is:false)'
        ]

        # Check if the user has any of the post process filters
        # If so, we should retrieve the whole list of profiles in the response
        # AP-7634 TODO: We should consider revamping the post processing to work in tangent with our pagination performance improvements
        # at a later point in the software cycle.
        hasPostProcessFilter = _.some postProcessFilters, (filter) ->
          return $location.search().filters.includes filter

        if hasPostProcessFilter
          extras += 'offset=0&minimizeProfiles=true&'
        else
          extras += 'limitResults=30&offset=0&minimizeProfiles=true&'

        # If the user has entered a profile search value, include it in the request
        if $scope.searchValue
          extras += "profileSearch=#{encodeURIComponent($scope.searchValue)}&"

      route = "/api/organizations/#{$scope.organization.id}/#{routeParams[$scope.area]}?#{extras}filters=#{$scope.filters}"
      $http.get(route).then (result) ->
        if $scope.areaIn ['attendance'] then updateAttendanceData(result.data)
        $scope.collection = result.data
        $rootScope.$broadcast 'profilesEvent', { profiles: $scope.collection }
        $scope.applying = false
        $scope.collectionError = false
        fn()
      .catch (err) ->
        $scope.collection = []
        $scope.applying = false
        $scope.collectionError = true
        fn()

    # Run when the profile search value changes
    $scope.$watch "searchValue", (newVal, oldVal) ->
      return unless newVal isnt oldVal
      if $scope.fullTextEnabled and $scope.areaIn(['manager'])
        findProfileSearchCount()

    profileCountRequest = 0
    # Find the number of profiles that match the search value
    findProfileSearchCount = () ->
      hasProfileSearch = if $scope.searchValue then "profileSearch=#{encodeURIComponent($scope.searchValue)}&" else ''
      route = "/api/organizations/#{$scope.organization.id}/profile-select-count?minimizeProfiles=true&#{hasProfileSearch}filters=#{$scope.filters}"
      $scope.applyingProfileCount = true

      # Increment the counter for each call.
      profileCountRequest++
      currentRequest = profileCountRequest

      # Immediately indicate that a request is in progress.
      $scope.applyingProfileCount = true

      $http.get(route).then (result) ->
        $scope.profileSearchResultLength = result.data[0]?.count

        # Emits back the profile count to profile state, since the filters don't load
        # initially like filter builder's does
        $rootScope.$broadcast 'profilesEvent', { profileCount: $scope.profileSearchResultLength }

        # If the current request is the last request, then we can indicate that the request is complete.
        if currentRequest == profileCountRequest
          $scope.applyingProfileCount = false
      .catch (err) ->
        $scope.profileSearchResultLength = 0
        if currentRequest == profileCountRequest
          $scope.applyingProfileCount = false

    ###
      Do an initial run of the filters if they came pre-packaged with the URL.
      This is important to prevent running collections 2 or more times.
      Please let the directive handle ALL filtering to avoid issues! =)
    ###
    filterRequestQueue = async.queue runFilters, 1
    filterRequestQueue.push runFilters if $location.search().filters

    # Add another filter to appliedFilters
    $scope.addAnother = ->
      $scope.appliedFilters.push new Filter()

    # Whether or not a filter has all properties necessary to apply
    validFilter = (filter) ->
      return false unless filter.object
      _.every ["path", "condition", "value"], (field) -> filter.object[field]

    # Modify URL filters array and update collection
    # Takes an array of valid Filter instances
    $scope.applyFilters = (filterSet) ->
      urlValues = _.clone $scope.existingInUrl
      $scope.existingInUrl = []

      # Modify existing in URL from updated filters
      _.each filterSet, (f) ->
        $scope.existingInUrl.push f.string.replace(/^tags/, "metadata")
        return

      $scope.existingInUrl = _.uniq $scope.existingInUrl

      if (_.xor $scope.existingInUrl, urlValues).length > 0
        $location.search "filters", $scope.existingInUrl.join("|")
        vals = $scope.existingInUrl.join("|")
        $scope.activeList = { text: vals }
        filterRequestQueue.push runFilters

    # Abstract since we want this to happen in watcher and when we remove filters
    $scope.setActiveFilters = ->
      filterSet = _.map $scope.appliedFilters, (f) -> new Filter(f.object)
      filterSet = _.filter filterSet, (f) -> validFilter(f)
      if (_.map filterSet, "string").join("|") isnt $scope.preset.selected
        $scope.preset = { selected: null }

      $scope.fullString = (_.map filterSet, "string").join("|")
      $timeout ->
        $scope.applyFilters filterSet
        $scope.storeFilterNames()

    # Remove a filter and update collection
    # Triggers the watcher which will re-run the filters
    $scope.removeFilter = (filter) ->
      $scope.removing = true
      $scope.appliedFilters = _.without $scope.appliedFilters, filter
      $scope.setActiveFilters()
      $timeout ->
        $scope.removing = false

    # DRYs up the permission check for PUT, POST, and DELETE requests
    confirmPermissions = () ->
      return true if !$filter('permissionVisible')({ health_log: 'edit', behavioral_health: 'edit' }) && $scope.area == 'healthLog'

    # Save an existing filter
    # Similar enough to normal saving, but different enough
    # to warrant its own function, which is more self-explanatory than
    # a bunch of 'if' statements in the main save fn
    $scope.saveExisting = (filterValue) ->
      return if confirmPermissions()
      existing = _.find $scope.user?.filters, (f) -> f.name is filterValue
      existing.string = $scope.fullString
      # We need the org ID so that we can check the permissions for this org on the backend
      existing.organizationID = $scope.organization.id
      if $scope.shareFilter
        existing.groupID = $scope.organization.id
        existing.share = true
      else
        # since the shareFilter value isn't bound to the actual share value on the filter object
        #   we need this to allow users to un-share their filters
        existing.groupID = null
        existing.share = false
      $http.put("/api/users/#{$scope.user?.id}/filters/#{existing.id}", existing).then (result) ->
        $scope.user?.filters = _.reject $scope.user?.filters, (f) -> f.name is filterValue
        $scope.lists = _.reject $scope.lists, (l) -> l.label is filterValue
        $scope.user?.filters.push result.data
        $scope.saveDialog = false
        $scope.shareFilter = false
        $scope.lists.push {label: existing.name, value: result.data.string, optgroup: "Custom", owner: true }
        do saveRemap
        $scope.activeList = { text: result.data.string }
        return

    # Save Filter
    $scope.saveFilter = (name) ->
      return if confirmPermissions()
      return unless name
      filter = {name: name}
      filter.type = filterTypes[$scope.area]
      filter.string = $scope.fullString
      filter.userID = $scope.user?.id
      # We need the org ID so that we can check the permissions for this org on the backend
      filter.organizationID = $scope.organization.id

      if $scope.shareFilter
        filter.groupID = $scope.organization.id
        filter.share = true

      $http.post("/api/users/#{$scope.user?.id}/filters", filter).then (result) ->
        newFilter = result.data
        $scope.saved = newFilter.string
        $scope.user?.filters ?= []
        $scope.user?.filters.push result.data
        $scope.saveDialog = false
        $scope.shareFilter = false
        $scope.lists.push { label: name, value: $scope.saved, optgroup: "Custom", owner: true }
        do saveRemap
        $scope.activeList = { text: $scope.saved }
        return

    # Delete filters
    $scope.deleteFilter = (filterString) ->
      return if confirmPermissions()
      filter = _.find $scope.user?.filters, { string: filterString }
      # We need the org ID so that we can check the permissions for this org on the backend
      orgData = { organizationID: $scope.organization.id }
      $http["delete"]("/api/users/#{filter.userID}/filters/#{filter.id}", { data: orgData }).then (result) ->
        $timeout ->
          $scope.user?.filters = _.reject $scope.user?.filters, (f) -> f.string is filterString
          $scope.lists = _.reject $scope.lists, (f) -> f.value is filterString
          $scope.deleting = false
          do saveRemap
    # Store the filter strings in sessionStorage to send when creating reports
    $scope.storeFilterNames = ->
      sessionStorage.setItem "appliedFilterNames", null
      filterNames = []

      filterNames = window.lib.filterParse.getReadableFilterArray $scope.filterSets, $scope.appliedFilters

      sessionStorage.setItem "appliedFilterNames", _.compact(filterNames).join "|"

    # Update choices based on newFilter changes
    $scope.$watch "appliedFilters", (filters, stale) ->
      $scope.storeFilterNames()
      if (_.isEqual filters, stale)
        return $scope.applyingFromSaved = false

      # Remove changed filters
      _.each filters, (f, i) ->

        ## Make sure stale filter was complete as well and remove/replace if so
        if not stale[i] or not f then return
        if not validFilter(stale[i]) then return
        if (f.object isnt stale[i].object) and $scope.applyingFromSaved
          $scope.removeFilter stale[i]
        if (f.object?.path isnt stale[i].object?.path)
          if !$scope.applyingFromSaved and !$scope.removing
            f.object.condition = ""
            f.object.value = ""

      $scope.applyingFromSaved = false

      _.map filters, (f) ->
        # If there's only one condition for that filter, select it by default
        if $scope.setMap[f.object.path]?.conditions?.length is 1
          f.object.condition = $scope.setMap[f.object.path].conditions[0].value
        if $scope.setMap[f.object.path]?.values?.length is 1

          # Edge-case: only one current group, don't want to auto-select by default
          if $scope.setMap[f.object.path].option then return

          f.object.value = $scope.setMap[f.object.path].values[0].value

      $scope.setActiveFilters()
    , true

    $scope.fullString = (_.map $scope.appliedFilters, "string").join("|")

    $scope.activeListWatcher = (list, stale) ->
      # Don't allow deletion of lists in between changing lists
      $scope.deleting = false
      # If the new list doesn't have filters, we don't care
      return if not list?.text
      # If it's the same thing, we also don't care
      return unless list.text isnt stale.selected

      # Find if the list is preset while finding if it's saved
      [isPresetList, listIsSaved] = $scope.getListProperties(list)

      if listIsSaved
        # Set it to false to start
        $scope.setShowPastGroupsCheckbox false
        $scope.appliedFilters = $scope.makeFilterObjects list.text
        $scope.appliedFilters.map (f) ->
          if not isPresetList and f.object.path is groupCheckboxPath
            # When loading saved lists, we want the past groups to be checked and active
            $scope.setShowPastGroupsCheckbox true

        $scope.applyingFromSaved = true
        $scope.storeFilterNames()
        $timeout ->
          $scope.applyingFromSaved = false

    $scope.$watch("activeList", $scope.activeListWatcher, true)

    $scope.$watch "preloadFilter", (fresh, stale) ->
      if fresh is stale then return
      runFilters()

    # Get an object with all the relative date conditions (values) for a date filter set
    # Returns an object instead of an array to make for easier lookups
    # @param {Object} f - A FilterSet Object
    # @returns {Object} An object keyed by relative date comparators
    # @example Example output: {
    #   'relativedate<=:': true,
    #   'relativedate>=:': true
    # }
    relativeDateConditions = (f) ->
      return f.conditions.reduce (conditions, c) ->
        if c.relative then conditions[c.value] = true
        return conditions
      , {}

    # relativeDates is an object keyed by filter path. The object only contains
    # filter paths with relative date conditions
    # @example Example object:
    # {
    #   '[registrations].finish': {
    #     'relativedate<=:': true,
    #     'relativedate>=:': true
    #   },
    #   '[registrations].start': {
    #     'relativedate<=:': true,
    #     'relativedate>=:': true
    #   },
    # }
    $scope.relativeDates = $scope.filterSets.reduce (relDates, f) ->
      if f.date
        relConditions = relativeDateConditions(f)
        if Object.keys(relConditions).length != 0
          relDates[f.path.value] = relConditions
      return relDates
    , {}
