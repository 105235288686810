/* eslint-disable comma-spacing, no-unused-vars, semi, space-infix-ops, no-unneeded-ternary*/
/* eslint-disable eqeqeq, prefer-const, space-in-parens, brace-style, prefer-const*/
lib.registerState('manager.groups.registration', {
  url: '/registration',
  templateUrl: 'states/manager/groups/registration/registration.state.html',
  resolve: {
    title: function($rootScope) {
      $rootScope._title = 'Registrations';
    }
  },

  controller: function($scope, $state, $http, $filter, Group, organization, session) {
    $scope.showPast         = false;
    $scope.displayOrder     = _.cloneDeep(organization.displayOrder);
    $scope.creationEnabled  = organization.properties.features ? (organization.properties.features.enableSessionCreation || false) : false;
    $scope.orgAccess        = session.access.operator || _.includes(session.access.groups, organization.id);

    // Priority Registration initial values
    $scope.showPriorityRegSettings = false;
    $scope.enablePriorityRegistration = false;
    $scope.priorityRegistrationCode = null;
    $scope.hasGroupEditAccess = $filter('permissionVisible')({ roster_registration_setup: 'edit'});
    let previouslySavedCode = null;
    let hasSavedCode = false;
    // Check if the priority registration feature flag is enabled
    $scope.priorityRegFeatureFlag = window.lib.featureFlagClient.isEnabled('registrationCodes');

    // feature flag is enabled and organization has the feature enabled
    $scope.fullyEnabled = $scope.priorityRegFeatureFlag && organization.properties.earlyRegistrationEnabled;

    /**
     * Get the registration code for the organization if there is one
     */
    const getCode = function() {
      if ($scope.fullyEnabled && organization.properties.earlyRegistrationCodeSet) {
        $http.get(`/api/organizations/${organization.id}/registrationCodes`)
          .then(resolve => {
            if (resolve.data) {
              $scope.priorityRegistrationCode = previouslySavedCode = resolve.data.code;
              $scope.showPriorityRegSettings = true;
              $scope.enablePriorityRegistration = true;
              hasSavedCode = true;
            }
          }).catch(error => {
            if (error.status === 400 || error.status === 403) {
              flash('Error retrieving early registration code')
            }
          });
      }
    };
    getCode();

    /**
     * Deletes the registration code for the organization
     */
    const deleteCode = function() {
      if (hasSavedCode) {
        $http.delete(`/api/organizations/${organization.id}/registrationCodes`)
          .then(resolve => {
            $scope.priorityRegistrationCode = null;
            previouslySavedCode = null;
            $scope.showPriorityRegSettings = false;
            flash('Early registration code deleted');
          }).catch(error => flash('Error deleting early registration code'));
      }
    };

    /**
     * Save the new registration code for the organization
     */
    const saveNewCode = function() {
      $http.post(`/api/organizations/${organization.id}/registrationCodes`, { code: $scope.priorityRegistrationCode })
        .then(resolve => {
          hasSavedCode = true;
          previouslySavedCode = $scope.priorityRegistrationCode;
          flash('Early registration code saved');
        }).catch(error => {
          if (error.status === 409) flash(error.data);
          else flash('Error saving early registration code');
        });
    };

    /**
     * Save or delete the registration code for the organization depending on the state
     */
    $scope.saveOrDelete = function() {
      if ($scope.enablePriorityRegistration && $scope.priorityRegistrationCode) {
        saveNewCode();
      } else if (!$scope.enablePriorityRegistration && hasSavedCode) {
        deleteCode();
      }
    };

    /**
     * Disable the save button if the priority registration code is empty or there isn't an existing code to delete
     */
    $scope.disableSave = function() {
      if (!$scope.hasGroupEditAccess) {
        return true;
      }
      const savedCodeBtnCondition = hasSavedCode && (!$scope.enablePriorityRegistration || $scope.priorityRegistrationCode);
      const newCodeBtnCondition = $scope.priorityRegistrationCode && !hasSavedCode;
      if (savedCodeBtnCondition || newCodeBtnCondition) {
        return false;
      } else {
        return true;
      }
    }

    /**
     * Check if the priority registration setting is displayed
     */
    $scope.priorityRegDisplayed = function() {
      return $scope.priorityRegFeatureFlag && !$scope.showPriorityRegSettings;
    }

    /**
     * Toggle the priority registration settings
     */
    $scope.priorityRegBtnClick = function() {
      $scope.showPriorityRegSettings = !$scope.showPriorityRegSettings;

      // undo any changes and set it to their code set in the database
      $scope.priorityRegistrationCode = previouslySavedCode;
    }

    const flatGroups = [];
    const registerableGroupIDs = [];

    function flattenator(groups, groupArray) {
      _.map(groups, function(group) {
        groupArray.push( _.pick(group, ['id', 'name', 'classification', 'parentID', 'parents', 'phase', 'deactivated', 'registration', 'shortName', 'properties', 'compiledProperties']) );
        if (group.children) flattenator(group.children, groupArray);
      });
    }

    flattenator([organization], flatGroups);

    /* SortBy phase to grab capacity and date data for current groups first, filter out groups they don't have access to */
    var registerableGroups = _(flatGroups).filter(function(group) {
      return group.registration.enabled && (_.includes(session.access.groups, group.id) || session.access.operator);
    }).sortBy('phase').value();

    /*
    This parent is the immediate parent of the group, used for the first half of the name display in the tables
    As long as we're looping through, get the IDs into another array for a capacity request
    */
    _.map(registerableGroups, function(group) {
      let parent = _.find(flatGroups, function(g) { return g.id === group.parentID; });
      group.parent = { id: parent.id, name: parent.name, shortName: parent.shortName }

      registerableGroupIDs.push(group.id);
    });

    /*
    Start grabbing group occupancies async
    */
    getOccupancies().then((occupancies) => {
      _.map(registerableGroups, (rg) => {
        const occupancyResult = occupancies.data.find(od => od.groupID === rg.id);
        rg.occupancy = parseInt(occupancyResult ? occupancyResult.count || 0 : 0, 10);
        rg.totalCapacity = calculateCapacity(rg.registration);
      });
    }).catch((err) => {
      flash('Error retrieving occupancy data');
    });

    function getOccupancies() {
      return new Promise((resolve, reject) => {
        return $http.post(`/api/organizations/${organization.id}/occupancies?totalsOnly`, registerableGroupIDs).then(resolve).catch(reject);
      });
    }

    /*
      TODO: break up sectionGroups a bit eh?
      I made this state look and act like the shitty old one as best I could and not much farther than that
      This function can absolutely be broken up into more readable chunks, but that'll have to wait until the dev team is given time to fix things
    */
    var sectionGroupsArray = [];
    function sectionGroups() {
      /* Clear things out from the last time we cooked up sectionGroups */
      sectionGroupsArray = [];
      _.map(registerableGroups, function(group) {
        delete group.parentFound;
      });

      if (organization.shortName) {
        /* Find nearest group with shortName in group parents. This group's name will be the header */
        _(registerableGroups).filter(function(group) {
          return $scope.showPast ? group.phase === 'past': group.phase !== 'past';
        }).map(function(group) {
          /* Need to clone the array before reversing else parents is mutated and backwards */
          var parentIDs = _.clone(group.parents).reverse();
          _.map(parentIDs, function(parentID) {
            if (parentID === 21436 || group.parentFound) return;

            /* This parent is a parent group that we've already added into the outer array that we want to add more groups to */
            var parent = _.find(sectionGroupsArray, function(g) { return g.id === parentID; });
            if (parent) {
              parent.children.push(group);
              group.parentFound = true;
              return;
            }
            /* This parent is the nearest parent group with a shortName, which means it will be a 'section' in the outer array */
            parent = _.find(flatGroups, function(g) { return g.id === parentID; });
            if (parent.shortName) {
              var sectionGroupObj = _.cloneDeep(parent);
              sectionGroupObj.parent = { name: parent.name, id: parent.id }
              sectionGroupObj.children = [];
              sectionGroupObj.children.push(group);
              sectionGroupObj.autoSort = {
                prop: null,
                direction: null
              }
              sectionGroupsArray.push(sectionGroupObj);
              group.parentFound = true;
              return;
            }
          });
        }).compact().value();
      } else {
        /* Orgs that don't use us for registration but do use us for travel protection (BBYO) will need this block here */
        sectionGroupsArray.push({ name: organization.name, children: [] });
        _(registerableGroups).filter(function(group) {
          return $scope.showPast ? group.phase === 'past' : group.phase !== 'past';
        }).map(function(group) {
          sectionGroupsArray[0].children.push(group);
        }).value();
        if (!sectionGroupsArray[0].children.length) sectionGroupsArray = [];
      }

      /* Sort out the sections */
      if ($scope.displayOrder && $scope.displayOrder.shortNames && $scope.displayOrder.shortNames.length) {
        sectionGroupsArray = _.sortBy(sectionGroupsArray, function(section) {
          if ($scope.displayOrder[section.shortName]) {
            section.children = _.sortBy(section.children, function(child) {
              return _.findIndex($scope.displayOrder[section.shortName], function(gID) {
                return gID === child.id;
              });
            });
          }
          return _.findIndex($scope.displayOrder.shortNames, function(shortName) {
            return shortName === section.shortName;
          });
        });
      }

      $scope.sectionGroups = sectionGroupsArray;
    }
    sectionGroups();

    /* Known formats for registration.capacity are: 150, "150", {"female": 75, "male": "75"}, {"female": "150"}, null */
    function calculateCapacity(registration) {
      registration = registration.capacity;
      if (!registration) return null;
      if (typeof registration === 'string') return parseInt(registration);
      if (typeof registration === 'object') {
        var total = 0;
        if (registration.male) total += parseInt(registration.male);
        if (registration.female) total += parseInt(registration.female);
        return total;
      }
      return registration;
    }

    $scope.changePhase = function() {
      $scope.showPast = !$scope.showPast;
      sectionGroups();
    }

    /* Begin Display Order functions */
    function generateDisplayOrder() {
      return $scope.sectionGroups.reduce((newDisplayOrder, section) => {
        newDisplayOrder.shortNames.push(section.shortName);
        newDisplayOrder[section.shortName] = section.children.map(child => child.id);
        return newDisplayOrder;
      }, { shortNames: [] })
    }

    if (!$scope.displayOrder || !$scope.displayOrder.shortNames || !$scope.displayOrder.shortNames.length)
      $scope.displayOrder = generateDisplayOrder();

    $scope.resetDisplayOrder = function() {
      $scope.displayOrder = {};
      sectionGroups();
    }

    $scope.saveDisplayOrder = function() {
      organization.displayOrder = $scope.displayOrder = generateDisplayOrder();
      new Group(organization).save(['displayOrder']).then(function() {
        $scope.editingDisplayOrder = false;
        swal('Display Order Updated', 'Sessions will now appear in this order during registration', 'success');
      });
    }

    $scope.cancelDisplayOrder = function() {
      $scope.editingDisplayOrder = false;
      sectionGroups();
    }

    function swapPositions(arr, i, j) {
      if ((j < 0) || (i < 0) || (j >= arr.length) || (i >= arr.length)) return arr;
      var swap = arr[i];
      arr[i] = arr[j];
      arr[j] = swap;
      return arr;
    }

    $scope.moveItem = function(shortName, index, direction, section) {
      // After manually moving a group, they are no longer autosorted.
      section.autoSort = {
        prop: null,
        direction: null
      }
      if (direction === 'up') {
        if (shortName) {
          swapPositions(section.children, index, index - 1);
          swapPositions($scope.displayOrder[shortName], index, index - 1);
        }
        else swapPositions($scope.sectionGroups, index, index - 1);
      } else if (direction === 'down') {
        if (shortName) {
          swapPositions(section.children, index, index + 1);
          swapPositions($scope.displayOrder[shortName], index, index + 1);
        }
        else swapPositions($scope.sectionGroups, index, index + 1);
      }
    }

    function autoSort(section) {

      // Set the arguments for _.orderBy
      let props, directions;
      switch (section.autoSort.prop) {
        case 'name':
          props = ['name', 'parent.name', 'properties.start', 'properties.finish'];
          if (section.autoSort.direction === true) {
            directions = ['asc', 'asc', 'desc', 'desc']
          }
          else {
            directions = ['desc', 'desc', 'desc', 'desc'];
          }
          break;
        case 'start':
          props = ['properties.start', 'name', 'parent.name', 'properties.finish'];
          if (section.autoSort.direction === true) {
            directions = ['asc', 'asc', 'asc', 'asc']
          }
          else {
            directions = ['desc', 'asc', 'asc', 'desc'];
          }
          break;
        case 'finish':
          props = ['properties.finish', 'name', 'parent.name', 'properties.start'];
          if (section.autoSort.direction === true) {
            directions = ['asc', 'asc', 'asc', 'asc']
          }
          else {
            directions = ['desc', 'asc', 'asc', 'desc'];
          }
          break;
        default:
          break;
      }

      // If `section.autoSort.prop` isn't on the above list, log/throw an error
      if (!props || !directions) {
        let payload = {
          message: `Auto-sort: Undefined Arguments - "${section.autoSort.prop}" isn't an auto-sort option.`
        };
        $http.post('/api/dev/logger', payload);
        throw new Error(payload.message);
      }

      // Reorder the section's children; overwrite existing to update the view
      section.children = _.orderBy(section.children, props, directions);
      // Get child IDs and set displayOrder with them
      return $scope.displayOrder[section.shortName] = section.children.map(g => g.id);

    }

    $scope.sortByProperty = (section, prop) => {
      // If no direction is set or the user is sorting on a new prop, sort asc
      if (section.autoSort.direction === null || section.autoSort.prop !== prop) {
        section.autoSort.direction = true;
      }
      // Otherwise, toggle the direction on click
      else {
        section.autoSort.direction = !section.autoSort.direction;
      }

      // After setting the direction, set the sort property
      section.autoSort.prop = prop;

      // Do the sorting
      autoSort(section);
    }
    /* End Display Order functions */

    /* Begin Registration Open/Close Setter functions */
    $scope.toggleSection = function(section) {
      _.map(section.children, function(child) {
        child.selected = section.allSelected;
      });
    }

    $scope.toggleChild = function(section) {
      section.allSelected = _.every(section.children, 'selected') ? true : false;
    }

    $scope.editBulkDate = function() {
      $scope.savingDates = true;
      var payload = {
        open: $scope.openRegistration,
        close: $scope.closeRegistration,
        openTime: $scope.openTime,
        closeTime: $scope.closeTime,
        groupIDs: _(registerableGroups).filter('selected').map('id').value()
      }

      $http.post('/api/organizations/' + organization.id + '/registration-dates', payload).then(
        function(success) {
          flash('Registration dates updated');
          $scope.savingDates = false;
          $scope.editingDates = false;
          _.map(registerableGroups, function(g) {
            g.selected = false;
            if (g.allSelected) g.allSelected = false;
          });
        },
        function(err) {
          flash('Error updating registration dates');
          $scope.savingDates = false;
        }
      );
    }
    /* End Registration Open/Close Setter functions */

    /*
      Big 'ol CSV generatin' function
      Complexity came from the large number of columns and having multiple rows per group depending on addOn/coupon counts
      - Set up header columns
      - Get IDs of all child groups
      - Fire a request with those IDs to get addOns and coupons for each
      - Compare addOn count to coupon count for each group to determine the number of rows that group needs
      - Write that many rows with group data. Only one coupon and one addOn per row
      - Functions that add on to csvString are below the main runner logic
    */
    $scope.exportGroups = function() {
      if ($scope.buildingCSV) return;
      $scope.buildingCSV = true;

      let csvString;

      csvString = 'Group ID,Group Name,Lowgrade,Highgrade,Description,Session Start Date,Session End Date,Due Date,Lockout Date,Tuition,Tuition GL Code,Deposit,Capacity,Girls Capacity,Boys Capacity,Youngest,Oldest,Open,Close,Waitlist,Add-On Custom Text,Coupon Custom Text,Payment Custom Text,Coupon Code,Coupon GL Code,Amount,Capacity,Expiration,Bypasses Deposit?,Percent?,Shared?,Add-On Name,Add-On GL Code,Multiple Choice,Price,Required,Due with Deposit,Numeric,Capacity,Expiration,Donation GL Code,Misc GL Code\n';
      if (!organization.properties.donation) {
        csvString = csvString.split(',').filter(header => header !== 'Donation GL Code').join(',');
      }

      var childIDs = _($scope.sectionGroups).map(function(section) {
        return _.map(section.children, 'id');
      }).flatten().value();

      if (!childIDs.length) return;

      $http.post('/api/groups/' + childIDs[0] + '/reg-setup-csv-data', { groupIDs: childIDs }).then(
        function(result) {
          var regData = result.data;
          var defaultGlCodes = regData.defaultGlCodes;
          delete regData.defaultGlCodes;

          const allChildren = $scope.sectionGroups.reduce((acc, section) => {
            return acc.concat(section.children);
          }, []);

          Object.keys(regData).map((row) => {
            var groupData = regData[row];

            // If it's a shared coupon, it won't have a `child`, so build part of one
            var child = allChildren.find((c) => c.id == row) || {
              id: row,
              name: groupData.name,
            };
            // If the group doesn't have a GL code, use the default
            child.glCode = groupData.glCode || defaultGlCodes.default_gl_code;
            var addOnCount = groupData.parentAddOns.length;
            var couponCount = groupData.coupons.length;
            var numberOfRows = Math.max(addOnCount, couponCount);
            if (numberOfRows > 0) {
              _.times(numberOfRows, function(rowNum) {
                csvGroupData(child);
                csvCouponAndGlCode(groupData, rowNum, defaultGlCodes);
                csvAddOnAndGlCode(groupData, rowNum, defaultGlCodes);
                csvDonationGlCode(defaultGlCodes);
                csvMiscGlCode(defaultGlCodes);
                csvNewline();
              });
            } else {
              csvGroupData(child);
              csvSpace();
              csvDonationGlCode(defaultGlCodes);
              csvMiscGlCode(defaultGlCodes);
              csvNewline();
            }
            groupData = null;
            addOnCount = null;
            couponCount = null;
            numberOfRows = null;
          });

          let csvData = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
          let fileName = 'registration_data.csv';

          if (window.navigator.msSaveOrOpenBlob) {
            // Blob support for edge
            window.navigator.msSaveOrOpenBlob(csvData, filename);
          } else {
            let csvURL = window.URL.createObjectURL(csvData);
            let a = document.createElement('a');
            // firefox works better with an attached anchor tag
            document.getElementById('content').appendChild(a);
            a.href = csvURL;
            a.setAttribute('download', fileName);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(csvURL);
            a = csvURL = null;
          }
          csvData = csvString = null;
          $scope.buildingCSV = false;
        },
        function(err) {
          flash('Error generating export');
        }
      );

      function csvIDAndName(child) {
        if (child.id) {
          csvString += child.id + ',';
        } else {
          csvString += ',';
        }
        if (child.name) {
          csvString += '"' + child.name + '",';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvGrades(child) {
        if (child.registration && child.registration.grade && child.registration.grade.length) {
          csvString += (child.registration.grade[0] ? child.registration.grade[0] : '') + ',';
          csvString += (child.registration.grade[1] ? child.registration.grade[1] : '') + ',';
        } else {
          csvString += ',,';
        }
        return;
      }

      function csvGroupDescription(child) {
        if (child.registration) {
          csvString += (child.registration.description ? '"' + child.registration.description.replace(/"/gm, '""') + '"' : '') + ',';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvStartAndEndDates(child) {
        if (child.properties) {
          if (child.properties.start) {
            csvString += (child.properties.start ? moment(child.properties.start).format('M/D/YYYY') : '') + ',';
          } else {
            csvString += ',';
          }
          if (child.properties.finish) {
            csvString += (child.properties.finish ? moment(child.properties.finish).format('M/D/YYYY') : '') + ',';
          } else {
            csvString += ',';
          }
        } else {
          csvString += ',,';
        }
        return;
      }

      function csvDueDate(child) {
        if (child.properties && child.properties.due) {
          csvString += moment(child.properties.due).format('M/D/YYYY') + ',';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvLockoutDate(child) {
        if (child.properties) {
          csvString += (child.properties.lockout ? moment(child.properties.lockout).format('M/D/YYYY') : '') + ',';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvTuition(child) {
        if (child.registration) {
          csvString += (child.registration.tuition ? '$' + child.registration.tuition/100 : '') + ',';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvTuitionGlCode(child) {
        csvString += (child.glCode ? child.glCode : '') + ',';
        return;
      }

      function csvDeposit(child) {
        if (child.registration) {
          csvString += (child.registration.deposit ? '$' + child.registration.deposit/100 : '') + ',';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvTuitionAndDeposit(child) {
        if (child.registration) {
          csvString += (child.registration.tuition ? '$' + child.registration.tuition/100 : '') + ',';
          csvString += (child.registration.deposit ? '$' + child.registration.deposit/100 : '') + ',';
        } else {
          csvString += ',,';
        }
        return;
      }

      function csvGroupCapacity(child) {
        if (child.registration && child.registration.capacity) {
          csvString += calculateCapacity(child.registration) + ',';
          csvString += (child.registration.capacity.female ? child.registration.capacity.female : '') + ',';
          csvString += (child.registration.capacity.male ? child.registration.capacity.male : '') + ',';
        } else {
          csvString += ',,,';
        }
        return;
      }

      function csvAge(child) {
        if (child.registration && child.registration.age) {
          csvString += (child.registration.age[0] ? child.registration.age[0] : '') + ',';
          csvString += (child.registration.age[1] ? child.registration.age[1] : '') + ',';
        } else {
          csvString += ',,';
        }
        return;
      }

      function csvRegOpenDates(child) {
        if (child.registration && child.registration.open) {
          csvString += (child.registration.open[0] ? moment(child.registration.open[0]).format('M/D/YYYY') : '') + ',';
          csvString += (child.registration.open[1] ? moment(child.registration.open[1]).format('M/D/YYYY') : '') + ',';
        } else {
          csvString += ',,';
        }
        return;
      }

      function csvWaitlist(child) {
        if (child.registration && child.registration.waitlist && child.registration.waitlist.toString() === 'true') {
          csvString += 'TRUE' + ',';
        } else {
          csvString += ',';
        }
        return;
      }

      function csvCustomText(child) {
        if (child.registration && child.registration.text) {
          csvString += (child.registration.text.addOnSelect ? '"' + child.registration.text.addOnSelect + '"' : '') + ',';
          csvString += (child.registration.text.couponEntry ? '"' + child.registration.text.couponEntry + '"' : '') + ',';
          csvString += (child.registration.text.payment ? '"' + child.registration.text.payment + '"' : '') + ',';
        } else {
          csvString += ',,,';
        }
        return;
      }

      function csvAddOn(groupData, rowNum) {
        if (groupData.parentAddOns[rowNum]) {
          var childAddOns = _(groupData.childAddOns).map(function(ca) {
            if (ca.parentID === groupData.parentAddOns[rowNum].id) {
              return ca.name;
            } else {
              return null;
            }
          }).compact().value();
          csvString += `"${groupData.parentAddOns[rowNum].name}"` + ',';
          csvString += '$' + groupData.parentAddOns[rowNum].price/100 + ',';
          csvString += (groupData.parentAddOns[rowNum].required || '') + ',';
          csvString += (groupData.parentAddOns[rowNum].properties.dueAtDeposit || '') + ',';
          csvString += (groupData.parentAddOns[rowNum].numeric || '') + ',';
          csvString += groupData.parentAddOns[rowNum].capacity + ',';
          csvString += (groupData.parentAddOns[rowNum].expires ? moment(groupData.parentAddOns[rowNum].expires).format('M/D/YYYY') : '') + ',';
          csvString += '"' + childAddOns.join(' || ') + '"' + ',';
          childAddOns = null;
        } else {
          csvString += ',,,,,,,,';
        }
      }

      function csvCoupon(groupData, rowNum) {
        if (groupData.coupons[rowNum]) {
          csvString += groupData.coupons[rowNum].code + ',';
          if (groupData.coupons[rowNum].properties.isPercentage) {
            csvString += groupData.coupons[rowNum].amount/100 + '%,';
          } else {
            csvString += '$' + groupData.coupons[rowNum].amount/100 + ',';
          }
          csvString += groupData.coupons[rowNum].capacity + ',';
          csvString += (groupData.coupons[rowNum].expires ? moment(groupData.coupons[rowNum].expires).format('M/D/YYYY') : '') + ',';
          csvString += (groupData.coupons[rowNum].properties.bypassDeposit || '') + ',';
          csvString += (groupData.coupons[rowNum].properties.isPercentage || '') + ',';
          csvString += (groupData.coupons[rowNum].shared || '') + ',';
        } else {
          csvString += ',,,,,,,';
        }
        return;
      }

      function csvAddOnAndGlCode(groupData, rowNum, defaultGlCodes) {
        if (groupData.parentAddOns[rowNum]) {
          var childAddOns = _(groupData.childAddOns).map(function(ca) {
            if (ca.parentID === groupData.parentAddOns[rowNum].id) {
              return ca.name;
            } else {
              return null;
            }
          }).compact().value();

          var childGlCodes = _(groupData.childAddOns).map(function(ca) {
            if (ca.parentID === groupData.parentAddOns[rowNum].id) {
              return ca.glCode || defaultGlCodes.default_add_on_gl_code || '';
            } else {
              return null;
            }
          }).compact().value();

          csvString += `"${groupData.parentAddOns[rowNum].name}"` + ',';
          if (childGlCodes.length) {
            csvString += '"' + childGlCodes.join(' || ') + '"' + ',';
          } else {
            csvString += (groupData.parentAddOns[rowNum].glCode || defaultGlCodes.default_add_on_gl_code || '') + ',';
          }
          csvString += '"' + childAddOns.join(' || ') + '"' + ',';
          csvString += '$' + groupData.parentAddOns[rowNum].price/100 + ',';
          csvString += (groupData.parentAddOns[rowNum].required || '') + ',';
          csvString += (groupData.parentAddOns[rowNum].properties.dueAtDeposit || '') + ',';
          csvString += (groupData.parentAddOns[rowNum].numeric || '') + ',';
          csvString += groupData.parentAddOns[rowNum].capacity + ',';
          csvString += (groupData.parentAddOns[rowNum].expires ? moment(groupData.parentAddOns[rowNum].expires).format('M/D/YYYY') : '') + ',';
          childAddOns = null;
        } else {
          csvString += ',,,,,,,,,';
        }
      }

      function csvCouponAndGlCode(groupData, rowNum, defaultGlCodes) {
        if (groupData.coupons[rowNum]) {
          csvString += groupData.coupons[rowNum].code + ',';
          csvString += (groupData.coupons[rowNum].glCode || defaultGlCodes.default_coupon_gl_code || '') + ',';
          if (groupData.coupons[rowNum].properties.isPercentage) {
            csvString += groupData.coupons[rowNum].amount/100 + '%,';
          } else {
            csvString += '$' + groupData.coupons[rowNum].amount/100 + ',';
          }
          csvString += groupData.coupons[rowNum].capacity + ',';
          csvString += (groupData.coupons[rowNum].expires ? moment(groupData.coupons[rowNum].expires).format('M/D/YYYY') : '') + ',';
          csvString += (groupData.coupons[rowNum].properties.bypassDeposit || '') + ',';
          csvString += (groupData.coupons[rowNum].properties.isPercentage || '') + ',';
          csvString += (groupData.coupons[rowNum].shared || '') + ',';
        } else {
          csvString += ',,,,,,,,';
        }
        return;
      }

      function csvDonationGlCode(defaultGlCodes) {
        if (!organization.properties.donation) return;
        csvString += (defaultGlCodes.default_donation_gl_code || '') + ',';
        return;
      }

      function csvMiscGlCode(defaultGlCodes) {
        csvString += (defaultGlCodes.default_misc_gl_code || '') + ',';
        return;
      }

      function csvGroupData(child) {
        csvIDAndName(child);
        csvGrades(child);
        csvGroupDescription(child);
        csvStartAndEndDates(child);
        csvDueDate(child);
        csvLockoutDate(child);
        csvTuition(child);
        csvTuitionGlCode(child);
        csvDeposit(child);
        csvGroupCapacity(child);
        csvAge(child);
        csvRegOpenDates(child);
        csvWaitlist(child);
        csvCustomText(child);
      }

      function csvNewline() {
        csvString += '\n';
        return;
      }

      function csvSpace() {
        csvString += ',,,,,,,,,,,,,,,,,';
        return;
      }

    }
    /* End that big 'ol CSV generatin' function */

  }
});
