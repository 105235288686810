'use strict';

lib.registerState('oper', {
  url: '/operator',
  templateUrl: 'states/oper/oper.state.html',
  resolve: {
    session: function($http, $rootScope) {
      return $http.get('/api/session').then(function(result) {
        if (!result || !result.data || !result.data.user || !result.data.user.operator) {
          return window.location.pathname = "/!";
        }
        $rootScope.operator = result.data.user.operator;
        return result.data;
      });
    },
    title: function($rootScope) {
      return $rootScope._title = 'Operator Portal';
    },
    orgNameReset: function($rootScope) {
      $rootScope.organization = null;
    },
    clearPatientAccess: ($rootScope) => {
    /*
      Patient portal introduces $rootScope.hasPatientAccess to allow people with direct access to patient profiles to use the portal uninhibited
      If you access the patient portal and have this property applied, then go to the provider portal, the property sticks and bypasses all front end permission checks
      Clear it out here to avoid the big 'ol bug
    */
      $rootScope.hasPatientAccess = null;
    }
  },

  controller: function($rootScope, $scope, $state) {
    $rootScope._nav = [
      { text: 'Organizations', state: 'oper.organizations', icon: 'eye', iconPrefix: 'far' },
      { text: 'Finances', state: 'oper.finances', icon: 'dollar-sign' },
      { text: 'Profiles', state: 'oper.profiles', icon: 'smile', iconPrefix: 'far' },
      { text: 'Users', state: 'oper.users', icon: 'users' },
      { text: 'Transactions', state: 'oper.transactions', icon: 'credit-card', iconPrefix: 'far' },
      { text: 'Documents', state: 'oper.documents', icon: 'file-alt', iconPrefix: 'far' },
      { text: 'Notifications', state: 'oper.notifications', icon: 'envelope', iconPrefix: 'far' },
      { text: 'Exports', state: 'oper.buttons', icon: 'random' },
      { text: 'ROI', state: 'oper.roi', icon: 'chart-area' },
      { text: 'Report a Bug', icon: 'bug', id: 'submit-bug' },
      { text: 'Oper Request', icon: 'band-aid', id: 'submit-oper-request' },
    ];

    if ($state.current.name === 'oper') return $state.go('.organizations');
  }
});
