angular.module("dn").directive("qbOtc", function() {
  return {
    templateUrl: "directives/builder/modules/otc/qb-otc.directive.html",
    replace: true,
    restrict: "A",
    scope: {
      module: "=qbOtc",
      mode: "@",
      group: "="
    },

    controller: function($scope, $http) {
      $http.get(`/api/organizations/${$scope.group.id}/otc-meds`).then(
        (success) => {
          $scope.groupOtcMeds = success.data
          afterOtcFetch()
        }
        , (error) => {
          $scope.groupOtcMeds = []
          afterOtcFetch()
        }
      )

      function afterOtcFetch () {
        $scope.noOTCs = $scope.groupOtcMeds.length ? false : true
      }

      $scope.module.label = "OTC Medications"
      $scope.module.metadata ? $scope.module.metadata : {}
      $scope.uploadChoices = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]

      var defaultOTCs = [
        "Acetaminophen (Tylenol)",
        "Aloe",
        "Antibiotic Ointment (Bacitracin, Neosporin)",
        "Bismuth Subsalicylate (Peptoismol, Kaopectate)",
        "Calamine Lotion",
        "Calcium Carbonate (Tums, Rolaids)",
        "Cetirizine (Zyrtec)",
        "Cough Drops",
        "Diphenhydramine (Benadryl)",
        "Fexofenadine (Allegra)",
        "Hydrocortisone 1% (Cortisone)",
        "Ibuprofen (Advil, Motrin)",
        "Lice Shampoo (Nix, Rid)",
        "Loratadine (Claritin)",
        "Phenylephrine (Sudafed PE)",
        "Polyethylene Glycol (Miralax)",
        "Pseudoephedrine (Sudafed)",
      ];

      $scope.setDefaults = () => {
        $scope.settingDefaults = true
        $http.post(`/api/organizations/${$scope.group.id}/otc`, defaultOTCs).then(
          (success) => {
            $scope.noOTCs = success.data.length ? false : true
            flash("Medications added")
            $scope.settingDefaults = false
          }, (error) => {
            flash("Unable to add Medications")
            $scope.settingDefaults = false
          }
        )
      }
    }
  }
})
