angular.module('dn').directive('attendanceFeed', function () {
  return {
    templateUrl: 'directives/attendance/attendance-feed/attendance-feed.directive.html',
    restrict: 'E',
    scope: {
      records: '=',
      limit: '=',
      interactionFn: '='
    },
    controller($scope) {
      // Paginate Navigate Directive Default Values
      $scope.limit = $scope.limit || 1;
      $scope.currentPage = 0;
      $scope.pages = _.times(Math.ceil($scope.records.length / $scope.limit), Number);
      $scope.$watch('records', function(fresh, stale) {
        // Sorts records by check in time so check outs or attendance events don't go to the wrong records visually
        $scope.records = fresh.sort(function(a, b) {
          if (moment(a.check_in_time).isBefore(moment(b.check_in_time))) return 1;
          if (moment(a.check_in_time).isAfter(moment(b.check_in_time))) return -1;
          return 0;
        });

        // After sorting the records from newest to oldest we sort the timeline events making them go from
        // the earliest date to the oldest
        fresh.forEach(function (record, i) {
          $scope.records[i].timeline = record.timeline.sort(function(a, b) {
            if (moment(a.time).isBefore(moment(b.time))) return -1;
            if (moment(a.time).isAfter(moment(b.time))) return 1;
            return 0;
          });
        });
      }, true);

    }
  };
});
