angular.module("dn").directive("attendanceNote", function() {
  return {
    templateUrl: "directives/attendance-note/attendance-note.directive.html",
    restrict: "E",
    scope: {
      label: '@',
      note: '=',
      newNotesInputs: '=',
      profileId: '=',
      copyFunction: '&'
    },

    controller: function($scope, AttendanceData) {
      'use strict';

      $scope.profileCount = AttendanceData.selectedProfiles.length;

      $scope.lowNormalHigh = [
        { label: 'Low', value: 'Low' },
        { label: 'Normal', value: 'Normal' },
        { label: 'High', value: 'High' },
      ];

      $scope.note.priority = $scope.note.priority || 'Normal';
    }
  };
});
