angular.module("dn").directive("tooltip", function() {
  return {
    templateUrl: "directives/tooltip/tooltip.directive.html",
    restrict: "E",
    scope: {
      heading: "@heading",
      medical: "@",
      profile: "@",
      icon: "@",
      position: "@"
    },
    transclude: true,
    controller: function($scope, Tooltip) {

      if ($scope.medical && $scope.medical === 'true' && $scope.profile) {
        const profile = JSON.parse($scope.profile) || {};
        const profileID = profile.id;

        if (!Tooltip.getInitialized(profileID)) {
          Tooltip.initProfile(profile);
          Tooltip.setInitialized(profileID);
        }

        Tooltip.accessor({ profileID })
          .then(({ allergies, dimensions, medications }) => {
            if (allergies instanceof Error) $scope.algError = true;
            else $scope.allergies = allergies;
            if (dimensions instanceof Error) $scope.dimError = true;
            else $scope.dimString = dimensions;
            if (medications instanceof Error) $scope.medError = true;
            else $scope.medications = medications;
          });

      }
    }
  };
});
