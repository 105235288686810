angular.module("dn").directive "time", ->
  templateUrl: "directives/input/types/time/time.directive.html"
  restrict: "E"
  scope:
    model: "="
    placeholder: "="
    ngDisabled: "="
  controller: ($scope) ->

    $scope.timeParts =
      hour: ""
      minute: ""
      period: ""

    $scope.hourChoices    = _.map (_.range 1, 13), (t) -> {label:t, value: t}
    $scope.periodChoices  = _.map ["AM", "PM"], (t) -> { label: t, value: t }
    $scope.minuteChoices  = _.map (_.range 0, 60), (t) ->
      val = if t < 10 then "0#{t}" else t
      {label:val, value: val}

    partsFromDate = (date) ->
      return unless date
      if _.isString(date) and date.length is 5
        today = new Date().toISOString().slice 0, 10
        date = moment("#{today}T#{date}")
      return unless moment(date).isValid()
      date = moment(date)
      $scope.timeParts.period = "AM"
      $scope.timeParts.hour   = +date.format("H")

      if $scope.timeParts.hour > 12
        $scope.timeParts.period = "PM"
        $scope.timeParts.hour = $scope.timeParts.hour - 12

      if $scope.timeParts.hour is 12 then $scope.timeParts.period = "PM"
      if $scope.timeParts.hour is 0
        $scope.timeParts.hour = 12
        $scope.timeParts.period = "AM"

      $scope.timeParts.hour = $scope.timeParts.hour.toString()
      $scope.timeParts.minute = date.format("mm")

    partsFromDate($scope.model)

    $scope.$watch "model", partsFromDate

    $scope.$watch "timeParts", (parts) ->
      return unless parts.hour || parts.minute
      formatted = [parts.hour, parts.minute]
      if +formatted[0] >= 12  then formatted[0] = (+formatted[0] - 12).toString()
      if parts.period is "PM" then formatted[0] = (+formatted[0] + 12).toString()
      $scope.model = (_.map formatted, (f) -> _.padStart f, 2, "0").join ":"
    , true
