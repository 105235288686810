angular.module("dn").directive("multiAddon", function() {
  return {
    templateUrl: "directives/registration-wizard/add-ons/multi-addon/multi-addon.directive.html",
    restrict: "E",
    scope: {
      addOn: "="
    },

    controller: function($scope, $state) {
      "use strict";

      /* Check if any options have been selected already (only used when navigating back and forth through the wizard) */
      _.map($scope.addOn.options, function(o, index) {
        if (o.selected) { $scope.selected = index; }
      });

      $scope.changeOption = function(selected) {
        /*
          selected is the Index of the addOn.selectOptions that corresponds
          to the index in the addOn array
          if an option was previously selected and then backspace'd over, `selected` has no value, hence the isNaN check
        */
        if (!$scope.addOn || !$scope.addOn.options) { return; }

        _.map($scope.addOn.options, function(o) { o.selected = false; });

        if (parseInt(selected) !== -1 && !_.isNaN(parseInt(selected))) {
          $scope.addOn.options[selected].selected = true;
        }
      }

      $scope.complete = function() {
        if (!$scope.addOn.required) return true;
        return _.some($scope.addOn.options, o => o.selected);
      }

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
