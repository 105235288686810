angular.module('dn').directive('attendanceAuditorList', function () {
  return {
    templateUrl: 'directives/attendance-auditor-list/attendance-auditor-list.directive.html',
    restrict: "E",
    scope: {
      auditors: "=",
    },
    controller($rootScope, $scope) {
      const timezone = $rootScope.organization.properties.timezone;
      $scope.auditors = [...$scope.auditors].reverse();

      $scope.formatTime = function(time) {
        return moment.tz(time, timezone).format("MMMM Do, YYYY @ h:mma");
      };

      // Paginate Navigate Directive Default Values
      $scope.limit = 20;
      $scope.currentPage = 0;
      $scope.pages = _.times(Math.ceil($scope.auditors.length / $scope.limit), Number);
    }
  };
});
