angular.module("dn").directive("qbEncrypted", function() {
  return {
    templateUrl: "directives/builder/modules/encrypted/qb-encrypted.directive.html",
    replace: true,
    restrict: "A",
    scope: {
      question: "=qbEncrypted",
      mode: "@"
    },
    controller: function($scope) {

    }
  };
});
