lib.registerState "manager.groups",
  url: "/groups"
  templateUrl: "states/manager/groups/groups.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Groups"

  controller: ($scope, $state, session) ->
    if $state.current.name is "manager.groups"
      $state.go ".roster"
