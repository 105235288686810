lib.registerState "manager.profiles.profile.metadata",
  url: "/metadata"
  templateUrl: "states/manager/profiles/profile/metadata/metadata.state.html"
  controller: ($scope, flash, $state) ->
    $scope.edit = {}

    $scope.metadataChoices = {}
    _.map $scope.organization.metadata, (item, key) ->
      if !item.values?.length then return

      key = key.toString() if typeof key is "number"

      $scope.metadataChoices[key.toLowerCase()] = _.map item.values, (v) -> { label: v, value: v }
      $scope.metadataChoices[key.toLowerCase()].unshift { label: "", value: "" }

    $scope.clearMetadata = (key) ->
      return unless key
      $scope.profile.metadata[key] = ""

    $scope.save = ->
      _.forIn $scope.profile.metadata, (value, key) ->
        if !value then delete $scope.profile.metadata[key]
      $scope.profile.save().then (result) ->
        flash "Tag updated"
        $state.reload $state.current.name
