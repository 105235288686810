lib.registerState "manager.profiles.profile.medications",
  url: "/medications"
  templateUrl: "states/manager/profiles/profile/medications/profile-medications.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Medications"
    profile: (profile) ->
      return profile.load(
        ["medications", "allergies", "mars", "registrations"],
        { addlParamsString: "excludeOrg"}
      )

  controller: ($scope, $state, profile) ->
    $scope.profile = profile

    $scope.specificState = ->
      $state.current.name isnt "manager.profiles.profile.medications"

    $state.go(".list") unless $scope.specificState()

    $scope.anyAllergies = do ->
      return false unless $scope.profile.has.allergies
      _.some ["drug", "food", "environmental"], (allergyType) ->
        $scope.profile.has?.allergies?[allergyType]

    $scope.tabs = [
      { label: "Medications", sref: ".list", permissions: { profile_medications: 'view' } }
      { label: "eMAR", sref: ".mars", permissions: { emar: 'view' } }
    ]

    otcTab = { label: "OTCS", sref: ".otc-list", permissions: { profile_medications: 'view' } }

    if $scope.organization.properties.enableOTC
      $scope.tabs.splice(1, 0, otcTab)
