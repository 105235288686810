angular.module('dn').directive('daysOfTheWeekCheckbox', function () {
  return {
    templateUrl: 'directives/days-of-the-week-checkbox/days-of-the-week-checkbox.directive.html',
    restrict: 'E',
    scope: {
      days: '=',
      changeFn: '=',
    },
    controller($scope) {
      /**
       * Makes sure each key in the day is valid
       * @param {Object} day - day of the week
       * @param {string} day.id - id generated on the directive using uuid
       * @param {string} day.label - The label of the button.
       * @param {boolean} day.value - The value that determines if the user clicked the button
       */
      function validateDays(day) {
        // eslint-disable-next-line eqeqeq
        if ([day.id, day.label, day.value].some(required => required == null)) {
          throw TypeError('id, label or value are undefined or null', 'days-of-the-week-checkbox.directive.js');
        } else if (typeof day.label !== 'string') {
          throw TypeError('label must be a string', 'days-of-the-week-checkbox.directive.js');
        } else if (typeof day.value !== 'boolean') {
          throw TypeError('value must be a boolean', 'days-of-the-week-checkbox.directive.js');
        } else {
          return true;
        }
      };

      /**
       * Makes sure a changeFn is passed to update the parent element
       */
      function validateChangeFn() {
        if (typeof $scope.changeFn !== 'function') {
          throw TypeError('changeFn must be a function', 'days-of-the-week-checkbox.directive.js');
        }
      }

      try {
        $scope.days.map(day => {
          day.id = window.uuid();
          validateDays(day);
        });
        validateChangeFn();
      } catch (err) {
        console.error(err);
        return;
      }

      /**
       * Marks checkbox if keypress was space
       * @param {Object} event - generated when the user interacts with an element. Look at $event
       * @param {Object} day - day of the week
       */
      $scope.keyPress = (event, day) => {
        if (event.originalEvent.keyCode === 32) {
          day.value = !day.value;
        }
      };

      /**
       * Updates the specific day that was clicked and called the function passed to the directive.
       * changeFn is an updater function that you can use to update the parent element using it.
       * @param {Object} day - day of the week
       */
      $scope.updateDay = function(day) {
        day.value = !day.value;
        $scope.changeFn(day);
      };
    }
  };
});
