lib.registerState('manager.settings.billing.invoice', {
  url: '/:crebitID',
  templateUrl: 'states/manager/settings/billing/invoice/invoice.state.html',
  resolve: {
    title: ($rootScope, group) => $rootScope._title = `${group.name} / Billing / Invoice`,
    toOrg: ($state, group, organization, flash) => {
      if (organization.id === group.id) return;
      flash(`Group changed to ${organization.name} for billing settings`);
      $state.go('manager.settings.billing.invoice', { groupID: organization.id });
    },
    crebitID: ($stateParams) => parseInt($stateParams.crebitID),
  },
  controller: ($http, $rootScope, $scope, $state, crebitID, session) => {
    'use strict';

    const preAssignFrz = Object.freeze({
      crebit: findInvoice($scope.crebits),
    });

    Object.assign($scope, preAssignFrz, {
      back,
      deleteLineItem,
      isInvoice: isInvoice(preAssignFrz.crebit),
      isMichael: session.userID === 21437,
      print: () => window.print(),
    });


    function deleteLineItem ({ groupID, id: crebitID }) {
      $http.delete(`/api/groups/${groupID}/crebits/${crebitID}`)
        .then(deleteSuccessFn, deleteErrorFn);
    }

    function deleteSuccessFn () {
      return successSwal();
    }

    function successSwal() {
      return swal({
        title: 'Success!',
        text: 'Successfully deleted line item!',
        type: 'success',
      }, () => back(true));
    }

    function deleteErrorFn () {
      return errorSwal();
    }

    function errorSwal(err) {
      return swal({
        title: 'Error',
        text: 'Error deleting line item',
        type: 'error',
      }, () => $state.reload());
    }

    function isInvoice (crebit) {
      return !!crebit.invoice;
    }

    function back (crebitDeleted=false) {
      return $rootScope.$emit('closeInvoice', { closeInvoice: false, crebitDeleted });
    }

    function findInvoice (crebits) {
      return crebits.find(crebit => crebit.id === crebitID);
    }

    $rootScope.$on('updateInvoiceCrebits', function updateInvoiceCrebit (event, { crebits }) {
      $scope.crebit = findInvoice(crebits);
      return;
    });

  }
});
