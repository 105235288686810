lib.registerState "manager.settings.general.medications",
  url: "/medications"
  templateUrl: "states/manager/settings/general/medications/medications.state.html"

  resolve:
    customNotes: ($http, organization) ->
      $http.get("/api/organizations/" + organization.id + "/emars/notes").then (result) ->
        return result.data || []

    groupOTCs: ($http, group) ->
      $http.get("/api/organizations/#{group.id}/otc-meds").then(
        (success) ->
          return success.data
        , (error) ->
          return []
      )

  controller: ($filter, $http, $scope, $state, customNotes, groupOTCs, flash, group, organization, Template, session) ->
    $scope.group = group
    $scope.groupOTCs = groupOTCs
    $scope.isOrganization = group.id is organization.id
    $scope.group.properties.otcMeds ?= []

    if session.access.operator
      $scope.medTab = 'setup'
      $scope.medTabs = [
        {label: 'Setup', value: 'setup', oper: true}
        {label: 'OTCS', value: 'otc', oper: true}
        {label: 'eMAR', value: 'emar'}
        {label: 'Confirmation Form', value: 'conf', oper: true}
      ]
    else
      $scope.medTab = 'emar'
      $scope.medTabs = []

    groupOTCLabels = _.map($scope.groupOTCs, 'label')

    defaultOTCs = [
      "Acetaminophen (Tylenol)",
      "Aloe",
      "Antibiotic Ointment (Bacitracin, Neosporin)",
      "Bismuth Subsalicylate (Peptoismol, Kaopectate)",
      "Calamine Lotion",
      "Calcium Carbonate (Tums, Rolaids)",
      "Cetirizine (Zyrtec)",
      "Cough Drops",
      "Diphenhydramine (Benadryl)",
      "Fexofenadine (Allegra)",
      "Hydrocortisone 1% (Cortisone)",
      "Ibuprofen (Advil, Motrin)",
      "Lice Shampoo (Nix, Rid)",
      "Loratadine (Claritin)",
      "Phenylephrine (Sudafed PE)",
      "Polyethylene Glycol (Miralax)",
      "Pseudoephedrine (Sudafed)",
    ];

    $scope.$watch('groupOTCs', () ->
      $scope.otcChoices = _.map(_.xor(defaultOTCs, groupOTCLabels), (o) ->
        return {label: o, value: o}
      )
    , true)

    $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission)

    $scope.addOTC = () ->
      otcMeds = $scope.otcMeds.split(/,(?!\s)/)
      $http.post("/api/organizations/#{$scope.group.id}/otc", otcMeds).then(
        (success) ->
          $scope.groupOTCs = $scope.groupOTCs.concat success.data
          $scope.otcMeds = ""
          flash "Medications added"
          $scope.addingOTCs = false
        , (error) ->
          flash "Unable to add Medications"
      )

    $scope.removeOTC = (med) ->
      $http.delete("/api/organizations/#{$scope.group.id}/otc/#{med.id}").then(
        (success) ->
          $scope.groupOTCs = _.reject($scope.groupOTCs, ['id', success.data.id])
          flash "Medication removed"
        , (error) ->
          flash "Unable to remove medication"
      )

    $scope.templateChoices = _.map customNotes, (note) ->
      return { label: note.name, value: note.id }

    $scope.templateChoices.unshift { label: "New Template", value: 0 }

    blankNote =
      name: ""
      template:
        note: ""

    templates = _.keyBy customNotes, "id"

    # Getter for notes after delete/create(same as resolve function.)
    cleanseNotes = (id) ->
      return $scope.templateChoices = _.filter $scope.templateChoices, (template) ->
        return template.value != id

    # Initialize to a "blank" note.
    $scope.marNote = blankNote

    $scope.updateNoteSelection = (noteID) ->
      if noteID isnt $scope.marNote.id
        if !parseInt(noteID) then $scope.marNote = blankNote else $scope.marNote = templates[noteID]

    $scope.cancelMARNote = () ->
      $scope.noteID = null
      $scope.marNote = blankNote

    $scope.deleteMARNote = (note) ->
      return if !parseInt(note.id) || !$scope.marNote.id

      $http.delete("/api/organizations/#{organization.id}/templates/#{$scope.marNote.id}").then (result) ->
        cleanseNotes(parseInt($scope.noteID))
        $scope.cancelMARNote()
        flash "Note Template Deleted"

    $scope.saveMARNote = (note) ->
      if note.id
        $http.put("/api/organizations/#{organization.id}/templates/#{note.id}", note).then (result) ->
          $scope.cancelMARNote()
          flash "Note Template Updated"
      else
        note.shared  = true
        note.type    = "marNote"

        $http.post("/api/organizations/#{organization.id}/templates", note).then (result) ->
          $scope.templateChoices.push { label: result.data.name, value: result.data.id }
          $scope.cancelMARNote()
          flash "Note Template Saved"
