lib.registerState('manager.settings.branding', {
  url: '/branding',
  templateUrl: 'states/manager/settings/branding/branding.state.html',
  resolve: {
    title: function ($rootScope, group) { return $rootScope._title = `${group.name} Branding` }
  },
  controller: function ($scope, $rootScope, $filter, GroupBranding, flash, group, session) {
    'use strict';

    const DEFAULT_BRANDING_PROPERTIES = {
      branding: {
        customSplash: undefined,
        wordmark: undefined,
        splash: {
          campdoc: 'stock/campdoc/blue-lake.jpg',
          schooldoc: 'stock/schooldoc/up-the-stairs.jpg'
        },
        portals: {
          patient: {
            sections: {
              prescreening: {},
            },
          },
        },
        text: {},
      }
    };

    $scope.GroupBranding = GroupBranding;
    $scope.division = window.dnim.getDivision(group.properties.division);
    $scope.group.properties = _.defaultsDeep($scope.group.properties, DEFAULT_BRANDING_PROPERTIES);

    $scope.splashOptions = {
      campdoc: [
        {
          label: 'Blue Lake',
          value: 'stock/campdoc/blue-lake.jpg'
        },
        {
          label: 'Hiking on Boards',
          value: 'stock/campdoc/hiking-on-boards.jpg'
        },
        {
          label: 'Magical Forest',
          value: 'stock/campdoc/magical-forest.jpg'
        },
        {
          label: 'Stairway to Nature',
          value: 'stock/campdoc/stairway-to-nature.jpg'
        },
        {
          label: 'Sunny Valley',
          value: 'stock/campdoc/sunny-valley.jpg'
        }
      ],
      schooldoc: [
        {
          label: 'Up the Stairs',
          value: 'stock/schooldoc/up-the-stairs.jpg'
        }
      ]
    }

    $scope.tabs = [
      { label: 'Login', value: 'login' },
      {
        label: `${$scope.division.patient.singular.capitalize()} Portal`,
        value: 'patient'
      }
    ];

    if (group.shortName) {
      $scope.tabs.push({ label: 'Registration', value: 'registration' });
    }

    $scope.divisionName = $scope.division.lcName;
    $scope.divisionDomain = $scope.division.name;

    $scope.tab = 'login';
    $scope.rand = _.random(1000000);

    $scope.subGroupProvider = window.lib.isSubGroupProvider(session, $scope.organization);

    $scope.nonOrgAccessExplanation = $scope.subGroupProvider
      ? 'You do not have sufficient privileges to change branding settings. Only top-level providers may change branding settings.'
      : '';

    $scope.editPermissions = $filter('permissionVisible')({ settings_branding: 'edit' });

    $scope.canEdit = $scope.editPermissions && !$scope.subGroupProvider;
    $scope.cannotEdit = !$scope.editPermissions || $scope.subGroupProvider;

    $scope.brandingUrlStyle = () => {
      return {
        background: `url('${$scope.brandingUrl('splash')}') no-repeat center center`,
        backgroundSize: 'cover',
        backgroundClip: 'content-box'
      }
    }

    $scope.brandingUrl = (imageType) => {
      let imagePath;
      if (imageType === 'splash') {
        imagePath = `splash/${$scope.group.properties.branding.splash[$scope.divisionName]}`;
      } else if (imageType === 'wordmark') {
        imagePath = `wordmarks/custom/${$scope.group.id}.png`;
      }
      return `//${window.lib.brandingConfig.values.base}/${imagePath}?cache-bust=${$scope.rand}`;
    }

    $scope.uploadedWordmark = (response) => {
      if (response.err || !response.key) {
        return swal(response.err, response.text, 'error');
      } else {
        $scope.rand = _.random(1000000);
        $scope.group.properties.branding.wordmark = true;
        return flash('Custom Wordmark Added!');
      }
    }

    $scope.uploadedSplash = (response) => {
      if (response.err || !response.key) {
        return swal(response.err, response.text, 'error');
      } else {
        $scope.rand = _.random(1000000);
        $scope.group.properties.branding.splash[$scope.divisionName] = `custom/${$scope.divisionName}/${group.id}.jpg`;
        $scope.group.properties.branding.customSplash = true;
        return flash('Custom CampDoc Splash Added!');
      }
    }

    $scope.restoreSplash = () => {
      if (!$scope.group.properties.branding.customSplash) {
        $scope.group.properties.branding.splash[$scope.divisionName] = $scope.splashOptions[$scope.divisionName][0].value;
      }
      return;
    }
  }
})
