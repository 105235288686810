angular.module('dn').directive('relativeDate', function() {
  return {
    templateUrl: 'directives/input/types/relative-date/relative-date.directive.html',
    restrict: 'E',
    scope: {
      model: '=',
      placeholder: '=',
      ngDisabled: '='
    },
    controller: function($scope) {
      $scope.units = [
        { value: 'days', label: 'Days' },
        { value: 'weeks', label: 'Weeks' },
        { value: 'months', label: 'Months' },
        { value: 'years', label: 'Years' }
      ];

      $scope.numberOfUnits = Array.from({ length: 100 }, (_, index) => ({
        label: (index + 1) + '',
        value: (index + 1) + ''
      }));

      $scope.relativeDate = ($scope.model || '').split(' ');

      $scope.$watch('relativeDate', fresh => {
        // Check length so model is set to null even if the unit is entered first
        if (fresh.some(val => !val) || fresh.length < 2) {
          $scope.model = null;
          return;
        }
        $scope.model = fresh.join(' ');
      }, true);

      $scope.$watch('model', newValue => {
        if (!newValue) return;
        $scope.relativeDate = newValue.split(' ');
      });

      if ($scope.placeholder && $scope.placeholder.length > 0) {
        // Example placeholders: '9 days' -> defaultNumber = '9', defaultUnit = 'Days'
        //                       '2 years' -> defaultNumber = '2', defaultUnit = 'Years'
        //                       '4 months' -> defaultNumber = '4', defaultUnit = 'Months'
        const defaultRelativeDate = $scope.placeholder.split(' ');

        $scope.defaultNumber = $scope.numberOfUnits.find(number => {
          return number.value === defaultRelativeDate[0];
        });
        if ($scope.defaultNumber) $scope.defaultNumber = $scope.defaultNumber.label;

        $scope.defaultUnit = $scope.units.find(unit => {
          return unit.value === defaultRelativeDate[1];
        });
        if ($scope.defaultUnit) $scope.defaultUnit = $scope.defaultUnit.label;
      }
    }
  };
});
