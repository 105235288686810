Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "Review", ($http) ->

  Review = new Resource
    route: "/api/profiles/:profileID/reviews/:id"
    schema:
      profileID: { type: "number" }
      status: { type: "text" }
      userID: { type: "number" }
      timestamp: { type: "date" }
      reviewTypeID: { type: "number" }

  Review
