angular.module("dn").directive("smsTokens", function() {
  return {
    templateUrl: "directives/sms-messages/sms-tokens/sms-tokens.directive.html",
    restrict: "E",
    scope: {
      profileId: "=",
      user: "="
    },
    controller: function($http, $scope, $rootScope, $state, flash) {

      // We don't want to restrict payments methods here because these payments go straight to us.
      // We only want to restrict payments that go the org.
      $http.get("/api/profiles/" + $scope.profileId + "/payment-methods").then((function(result) {
        $scope.paymentMethods = _(result.data).map(function(method){
          if (method.category === 'card') {
            method.label = method.type + " -" + method.lastfour;
            method.value = method.id;
            return method;
          } else {
            return null;
          }
        }).compact().value()

        if (!$scope.paymentMethods.length) {
          $scope.useNewMethod = true;
        }
        else if ($scope.paymentMethods.length === 1) {
          $scope.useNewMethod = false;
          $scope.savedMethod = $scope.paymentMethods[0].value;
        }
      }), function(err) {
        $scope.paymentMethods = [];
        $scope.useNewMethod = true;
      });

      $scope.packages = {
        "sm": {
          size: "sm",
          price: 12500,
          quantity: 2500
        },
        "md": {
          size: "md",
          price: 25000,
          quantity: 5500
        },
        "lg": {
          size: "lg",
          price: 50000,
          quantity: 11000
        }
      }

      $scope.choosePackage = function(size) {
        $scope.selectedPackage = $scope.packages[size];
        $scope.formattedQuantity = $scope.selectedPackage.quantity.toLocaleString();
      };

      var choosePaymentMethod = function(methodID) {
        return _.find($scope.paymentMethods, function(p){
          return parseInt(p.id) === parseInt(methodID);
        })
      }

      $scope.purchaseTokens = function () {
        $scope.submitting = true;
        var charge = {
          profileID: $scope.profileId,
          amount: $scope.selectedPackage.price,
          description: '[SMS-MESSAGES] SMS Tokens x ' + $scope.selectedPackage.quantity,
          ledger: 'sms'
        }

        var paymentMethod = $scope.useNewMethod ? $scope.freshMethod : choosePaymentMethod($scope.savedMethod);
        /* Methods won't save without a userID */
        if ($scope.useNewMethod) {
          paymentMethod.userID = $scope.user.id;
        }

        var description = '[PAYMENT] ' + paymentMethod.type + ' -' + paymentMethod.lastfour;
        var payment = {
          profileID: $scope.profileId,
          amount: $scope.selectedPackage.price * -1,
          paymentMethod: paymentMethod,
          description: description,
          ledger: 'sms',
          hideBalance: true
        }

        var payload = {
          crebits: {
            "payment": payment,
            "charge": charge
          },
          quantity: $scope.selectedPackage.quantity
        }

        $http.post("/api/organizations/" + $rootScope.organization.id + "/sms-tokens", payload).then(
          function({ data }) {
            $scope.$parent.tokensPurchased(data);
            flash("Tokens Purchased!");
            $scope.submitting = false;
          }, function(err) {
            $scope.submitting = false;
            swal({
              type: "error",
              title: "Error",
              text: "There was an error buying tokens. Please ensure your payment information is accurate and up to date\nReason: " + JSON.stringify(err.data)
            });
          }
        )
      }

      $scope.disablePayment = function() {
        if ($scope.submitting) {
          return true;
        }
        var saved = $scope.savedMethod && !$scope.useNewMethod;
        var fresh = $scope.freshMethod && $scope.useNewMethod;
        if (saved || fresh) {
          return false;
        }
        return true;
      };

    } // end controller
  };
});
