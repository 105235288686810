lib.registerState "manager.settings.questionnaire.step",
  url: "/:step"
  templateUrl: "states/manager/settings/questionnaire/step/step.state.html"

  controller: ($http, $scope, $stateParams, $timeout, organization) ->

    $scope.index = $stateParams.step
    $scope.step = [$scope.questionnaire.questions[$stateParams.step - 1]]
    $scope.deactivated = $stateParams.step == 'deactivated'

    if $scope.deactivated
      $scope.loadingDeactivated = true
      $http.get("/api/organizations/#{organization.id}/questions?deactivated").then (result) ->
        $scope.loadingDeactivated = false
        # add uniqID (for ng-repeat) and set uniform depth
        # also sorts by question ID, in ascending order
        # (eg 1 comes before 2 which comes before 3, you're welcome connor)
        $scope.flatStep = _(result.data || [])
          .map((elm, i) ->
            elm.uniqID = i + 1
            elm.depth = 1
            if elm.type != 'step' && elm.type != 'form'
              return elm
          ).compact().sortBy('id').value()

    # Flattens questionnaire to avoid recursive compile steps
    # uniqID is for ng-repeat tracking of potentially duplicate objects
    uniqIndex = 1
    flatten = (questions, depth, flat, parent) ->
      return if !questions or !questions.length
      _.each questions, (q, index) ->
        q.parentQuestion = parent
        q.depth          = depth
        q.uniqID         = uniqIndex++
        q.index          = index if q.type isnt "step"
        q.condition      = q.where unless q.condition

        flat.push q
        flatten q.subQuestions, depth + 1, flat, q

      flat

    if !$scope.deactivated
      $scope.flatStep = flatten $scope.step, 0, [], null

    $scope.$on "stepUpdate", ->
      $scope.flatStep = flatten $scope.step, 0, [], null
