angular.module("dn").directive("markdownEditor", function() {
  return {
    templateUrl: "directives/input/types/markdown-editor/markdown-editor.directive.html",
    restrict: "E",
    scope: {
      model: "=",
      label: "@",
      placeholder: "@",
    },
    controller: function($scope, $timeout) {
      // Generate a unique ID for the instance of the directive
      $scope.uuid = uuid.v4();

      // Timeout is necessary because otherwise jQuery is looking for the UUID in the state's HTML before it generates
      $timeout(function() {
        const simplemde = new SimpleMDE({
          autoDownloadFontAwesome: false,       // `true` will default to Font Awesome 4.7 and break icons
          element: $("#" + $scope.uuid)[0],     // Selects the textarea element with the corresponding UUID
          placeholder: $scope.placeholder,      // Defines the placeholder text
          spellChecker: false,                  // Turn off the spellchecker, it's annoying
          status: false,                        // Turn off the status bar at the bottom in favor of our validation
          toolbar: [                            // Customize the icons on the top bar
            {
              name: "bold",
              action: SimpleMDE.toggleBold,
              className: "fas fa-bold",
              title: "Bold",
            },
            {
              name: "italic",
              action: SimpleMDE.toggleItalic,
              className: "fas fa-italic",
              title: "Italic",
            },
            {
              name: "heading-smaller",
              action: SimpleMDE.toggleHeadingSmaller,
              className: "fas fa-heading fa-header-x fa-header-smaller",
              title: "Smaller Heading",
            },
            {
              name: "heading-bigger",
              action: SimpleMDE.toggleHeadingBigger,
              className: "fas fa-heading fa-header-x fa-header-bigger",
              title: "Bigger Heading",
            },
            "|",
            {
              name: "quote",
              action: SimpleMDE.toggleBlockquote,
              className: "fas fa-quote-left",
              title: "Quote",
            },
            {
              name: "unordered-list",
              action: SimpleMDE.toggleUnorderedList,
              className: "fas fa-list-ul",
              title: "Generic List",
            },
            {
              name: "ordered-list",
              action: SimpleMDE.toggleOrderedList,
              className: "fas fa-list-ol",
              title: "Numbered List",
            },
            "|",
            {
              name: "link",
              action: SimpleMDE.drawLink,
              className: "fas fa-link",
              title: "Create Link",
            },
            "|",
            {
              name: "preview",
              action: SimpleMDE.togglePreview,
              className: "far fa-eye no-disable",
              title: "Toggle Preview",
            },
            {
              name: "guide",
              action: function openLink() {
                window.open('https://guides.github.com/features/mastering-markdown/', '_blank');
              },
              className: "fas fa-question-circle",
              title: "Markdown Guide",
            },
          ],
        });

        // Set aria-label attribute if label or placeholder exists
        if ($scope.label || $scope.placeholder) {
          simplemde.codemirror
            .getWrapperElement()
            .querySelector('div > textarea')
            .setAttribute('aria-label', `${$scope.label || $scope.placeholder} preview`);
        }

        // Updating the model with each keystroke, making sure to keep the cursor in the right place
        // even when validation kicks in
        simplemde.codemirror.on("beforeChange", function(cm, change) {
          $scope.cursorPos = cm.getDoc().getCursor();
        });

        simplemde.codemirror.on("change", function(cm, change) {
          if ($scope.cursorPos && change.origin === "setValue") {
            cm.getDoc().setCursor($scope.cursorPos);
          }
          $scope.model = simplemde.value();
        });

        $scope.$watch("model", function(fresh, stale) {
          simplemde.value(fresh);
        });

      }, 100);
    }
  };
});
