// Close over all this stuff with an IIFE so we don't have everything sitting in global scope
angular.module('dn').service('ProfileRegData', (function() {
  'use strict';

  const ERR_TAG = '[ProfileRegData]';
  const PROFILE_BASE_ROUTE = '/api/profiles/:profileID/';
  const ORG_BASE_ROUTE = '/api/organizations/:orgID/';

  const resource2route = {
    'AddOn': {
      route: ORG_BASE_ROUTE + 'add-ons',
    },
    'Coupon': {
      route: ORG_BASE_ROUTE + 'coupons',
    },
    'Crebit': {
      route: PROFILE_BASE_ROUTE + 'account',
      query: {
        attributedTo: true,
        attributions: true,
      }
    },
    'ProtectionPlan': {
      route: PROFILE_BASE_ROUTE + 'protection-plans',
    },
    'Registration': {
      route: PROFILE_BASE_ROUTE + 'registrations',
      query: {
        regOnly: true
      }
    },
    'Role': {
      route: PROFILE_BASE_ROUTE + 'roles',
    }
  };

  const _profileRegStore = {
    addOns:          [],
    coupons:         [],
    crebits:         [],
    events:          [],
    indexedOrg:      {},
    profile:         {},
    protectionPlans: [],
    registrations:   [],
    roles:           [],
    tmp: initTmp()
  };

  function initTmp() {
    return {
      adjustments: [],
      registrations: {}
    };
  }

  const regAccessors = Object.create(Object.prototype, {
    deactivated: {
      enumerable: true,
      configurable: false,
      get() {
        return _profileRegStore.registrations.filter(r => r.deactivated);
      }
    },
    future: {
      enumerable: true,
      configurable: false,
      get() {
        return _profileRegStore.registrations.filter((r) => {
          return !r.deactivated && r.type === 'patient' && r.group.phase === 'future';
        });
      }
    },
    past: {
      enumerable: true,
      configurable: false,
      get() {
        return _profileRegStore.registrations.filter((r) => {
          return !r.deactivated && r.type === 'patient' && r.group.phase === 'past';
        });
      }
    },
    present: {
      enumerable: true,
      configurable: false,
      get() {
        return _profileRegStore.registrations.filter((r) => {
          return !r.deactivated && r.type === 'patient' && r.group.phase === 'present';
        });
      }
    },
    provider: {
      enumerable: true,
      configurable: false,
      get() {
        return _profileRegStore.registrations.filter(r => !r.deactivated && r.type === 'provider');
      }
    },
    tmp: {
      enumerable: true,
      configurable: false,
      get() {
        return _profileRegStore.tmp.registrations;
      }
    },
  });

  /**
   * This service is designed to facilitate data flow within the Provider Portal's profile state and
   * all its children. The service can handle addOns, coupons, crebits, events, protection plans, and
   * registrations. There's also potential to extend it to handle roles on provider regs.
   *
   * Much of the functionality herein is based around accessin the data in an efficient manner. You'll
   * find lots of getters and search functions. The only _editing_ functionality this service contains
   * pertains to registrations and crebits, and even that is limited.
   *
   * More description and documentation to follow as we continue working on this
   *
   * @summary An AngularJS Service that manages one profile's data, from crebits to registrations.
   * @class ProfileRegData
   */
  class ProfileRegData {
    constructor($http, $rootScope, AddOn, Coupon, Crebit, Registration) {
      this.$http = $http;
      this.$rootScope = $rootScope;
      this.AddOn = AddOn;
      this.Coupon = Coupon;
      this.Crebit = Crebit;
      this.Registration = Registration;

      // Get easy access to the store via accessors
      this.registrations = regAccessors;
      // With reg accessors in place, set up accessors for protection plans
      this.protectionPlans = {};
      ['deactivated', 'future', 'past', 'present'].forEach((key) => {
        Object.defineProperty(this.protectionPlans, key, {
          enumerable: true,
          configurable: false,
          get() {
            return this.registrations[key].reduce((plans, reg) => {
              if (reg.protectionPlan) plans.push(reg.protectionPlan);
              return plans;
            }, []);
          }
        });
      });
    }

    _handleHttpError(error, fnName, resourceName) {
      // If the request fails, we'll have `data` as the error. If it was something else, we'll
      // have an actual error object. Handle both.
      const data = error.data ? error.data : error;
      const err = `${ERR_TAG}[${fnName}] ${resourceName} request failed: ${data}`;
      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        console.error(err);
      }
      throw new Error(err);
    }

    _handleInternalError(fnName, msg, error) {
      const err = Error(`${ERR_TAG}[${fnName}] ${msg}: ${error.stack}`);
      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        console.error(err);
      }
    }

    _rawToResource(modelName, config) {
      return new this[modelName](config);
    }

    _addAddOnAccessors(registration) {
      const addOnAccessors = Object.create({}, {
        available: {
          enumerable: true,
          configurable: false,
          get() {

            /**
             * AddOns are "avaiable" (can be added BY A PROVIDER) if:
             * 1. They belong to the group or are shared to a group in its parent chain
             * 2. It hasn't already been purchased for THIS registration
             *
             * NOTE: add-ons which are at/over capacity or expired are fair game for providers, but
             * not for patients. If we intend to use this service patient side in the future, we'll
             * need to add additional functionality to filter those out.
             */

            // The parents array included the groups own ID in indexedOrg and in the DB
            try {
              const groupIDs = registration.group.parents;
              return _profileRegStore.addOns.filter((addOn) => {
                // Don't include options. They're available via a getter on their parent if needed.
                if (addOn.parentID) return false;
                // If it's not accessible to the registration's group, then it's not available
                // NOTE: unlike coupons, add-ons don't have a "shared" column. That's just determined
                // by where their groupID sits in the parent chain.
                if (!groupIDs.includes(addOn.groupID)) return false;
                // If it's already applied to this reg, it's not available
                return !registration.addOns.applied.includes(addOn);
              }).sort((a, b) => {
                // Sort by display order with shared add-ons hoisted to the top
                return (a.shared ? -1 : +(a.properties.displayOrder || 0))
                  - (b.shared ? -1 : +(b.properties.displayOrder || 0));
              });
            } catch (error) {
              this._handleInternalError('reg.addOns.available', 'Failed to get available add-ons', error);
              return [];
            }
          }
        },
        applied: {
          enumerable: true,
          configurable: false,
          get() {
            // Check if it's been purchased for THIS registration. An add-on can be purchased
            // multiple times under different regs, but not under the same reg.
            try {
              return registration.crebits.reduce((applied, crebit) => {
                // When we remove add-ons, we leave the crebit but set the quanitity to 0
                if (crebit.addOnQuantity < 1) return applied;
                // See if we can find the add-on in the store. Access store directly for better value
                // cahcing (I think; I haven't tested)
                let purchasedAddOn = _profileRegStore.addOns.find(a => a.id === crebit.addOnID);
                // If we can't find an add-on associated with the crebit, there's nothin to do
                if (!purchasedAddOn) return applied;
                // If it's an option, ignore that and get its parent instead
                if (purchasedAddOn.parent) purchasedAddOn = purchasedAddOn.parent;
                // If it passes all our conditions, then we know its applied.
                // Under our current system, providers can still purchase multiple options for a
                // multiple choice add-on. Prevent duplicate add-ons by checking if the parent's ID
                // is already in the `applied` array.
                if (!applied.includes(purchasedAddOn)) applied.push(purchasedAddOn);
                return applied;
              }, []);
            } catch (error) {
              this._handleInternalError('reg.addOns.applied', 'Failed to get applied add-ons', error);
              return [];
            }
          }
        }
      });
      Object.defineProperty(registration, 'addOns', {
        enumerable: true,
        writable: false,
        configurable: false,
        value: addOnAccessors
      });
    }

    _addCouponAccessors(registration) {
      const groupIDs = registration.group.parents;
      const couponAccessors = Object.create({}, {
        available: {
          enumerable: true,
          configurable: false,
          get() {
            return _profileRegStore.coupons.filter((coupon) => {
              if (!groupIDs.includes(coupon.groupID)) return false;
              // If it's already applied to this reg, it's not available
              return !registration.coupons.applied.includes(coupon);
            });
          }
        },
        applied: {
          enumerable: true,
          configurable: false,
          get() {
            return _profileRegStore.coupons.filter((coupon) => {
              if (!groupIDs.includes(coupon.groupID)) return false;
              // Check to see if any crebits correspond to an applied coupon
              return _profileRegStore.crebits.some((crebit) => {
                // If it's not a crebit for a used coupon, then there's nothing to see here
                if (!(coupon.id === crebit.couponID && crebit.addOnQuantity)) return false;
                // If it's not a multi-use shared coupon, then it can't be used again
                if (!(coupon.shared && coupon.multiUse)) return true;
                // It's only considered applied if it adjusts the given registration
                return registration.tuition && crebit.adjusts === registration.tuition.id;
              });
            });
          }
        }
      });
      Object.defineProperty(registration, 'coupons', {
        enumerable: true,
        writable: false,
        configurable: false,
        value: couponAccessors
      });
    }

    _addTuitionAccessors(registration) {
      Object.defineProperty(registration, 'tuition', {
        enumerable: true,
        configurable: false,
        get () {
          return _profileRegStore.crebits.find((c) => c.registrationID === registration.id);
        }
      });
      Object.defineProperty(registration, 'adjustments', {
        enumerable: true,
        configurable: false,
        get () {
          if (!this.tuition) return [];
          try {
            return _profileRegStore.crebits.filter((c) => c.adjusts === this.tuition.id);
          } catch (e) {
            this._handleInternalError('registration.adjustments', 'Filtering adjustments failed', e);
          }
        }
      });
      Object.defineProperty(registration, 'crebits', {
        enumerable: true,
        configurable: false,
        get () {
          if (!this.tuition) return [];
          return [this.tuition, ...this.adjustments];
        }
      });
      Object.defineProperty(registration, 'balance', {
        enumerable: true,
        configurable: false,
        get () {
          return this.crebits.reduce((sum, crebit) => {
            return sum += crebit.amount;
          }, 0);
        }
      });
      // We'll need a special balance just for the purpose of displaying on confirmation swals
      // that takes into account all non-misc and non-remove registration crebits
      // Otherwise, the amount on the swals display an incorrect balance with cancellation fees
      Object.defineProperty(registration, 'swalBalance', {
        enumerable: true,
        configurable: false,
        get () {
          return this.crebits.reduce((sum, crebit) => {
            const glCodesP2FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');
            const isValidCrebit = glCodesP2FlagEnabled
              ? crebit.registrationID || crebit.addOnID
              : crebit.registrationID || crebit.addOnID || crebit.couponID;

            if (crebit.addOnQuantity > 0 && isValidCrebit) {
              sum += crebit.amount;
            }
            return sum;
          }, 0);
        }
      });
    }

    clearStore(resourceName) {
      Object.entries(_profileRegStore).forEach(([ key, value ]) => {
        if (resourceName && resourceName !== key) return;
        if (Array.isArray(value)) value.length = 0;
        else if (key === 'tmp') value = initTmp();
        else value = {};
      });
    }

    /**
     * Get the specified resource(s) from the server.
     *
     * @param {String} resourceName Name of the resource to fetch. MUST match its factory's name.
     * @param {Object} config An object containing parameters, queries, and data to include in the request.
     * @returns {Promise} A promise that resolves to the data we've loaded and upserted into store.
     */
    fetchResources(resourceName, config = { params: {}, query: {} }) {
      const { genQueryString, replaceParams } = window.lib.requestUtils;
      const route = resource2route[resourceName].route;
      const params = config.params;
      const queries = Object.assign({}, resource2route[resourceName].query, config.query);
      const url = `${replaceParams(route, params, { autoInterpolateID: true })}${genQueryString(queries)}`;
      return this.$http.get(url)
        .then(result => this[`load${resourceName}s`](result.data || [], config))
        .catch(error => this._handleHttpError(error, 'fetchResources', resourceName));
    }

    deactivateResource(resource, resourceName, config = { params: {}, query: {} }) {
      const { genQueryString, replaceParams } = window.lib.requestUtils;
      // Guard against malformed params - replaceQuery will throw an error if missing any params
      const route = resource2route[resourceName].route;
      const params = Object.assign(config.params || {}, { id: resource.id });
      const queries = Object.assign({}, resource2route[resourceName].query, config.query);
      const url = `${replaceParams(route, params, { autoInterpolateID: true })}${genQueryString(queries)}`;
      return this.$http.delete(url)
        .then(({ data }) => {
          if (data) return this[`upsert${resourceName}`](data || [], config);
          resource.deactivated = new Date().toISOString();
          return resource;
        })
        .catch(error => this._handleHttpError(error, 'deactivateResource', resourceName));
    }

    reactivateRegistration(registration) {
      const tmpReg = this.initTmpRegistration(registration.id);
      tmpReg.deactivated = null;
      return this.saveTmpRegistration(tmpReg.id, ['deactivated'])
        .then(handleReactivationTuition.bind(this))
        .catch(error => this._handleHttpError(error, 'reactivateRegistration', 'Registration'));

      function handleReactivationTuition (responseReg) {
        // If it's a registration that doesn't have a tuition, we won't make an adjustment
        const { tuition } = (this.regById(registration.id) || {});
        if (!tuition) return Promise.resolve(responseReg);
        const adjConfig = {
          tuition: tuition,
          description: '[ADJUST] Registration Reactivated',
          amount: tuition.amount
        };
        return this.createAdjustment(adjConfig)
          .save()
          .then(this.upsertCrebit.bind(this))
          .then(() => registration);
      }
    }

    saveTuition(registration) {
      return new this.Crebit({
        profileID: registration.profileID,
        registrationID: registration.id,
        amount: registration.group.registration.tuition || 0,
        description: `[TUITION] ${registration.group.name}`,
        ledger: 'organization'
      }).save()
        .then(this.upsertCrebit.bind(this))
        .catch(error => this._handleHttpError(error, 'saveTuition', 'Crebit'));
    }

    saveAdjustments(profile, crebits, isAddOnRemoval = false, isCouponRemoval = false) {
      // TODO: When removing the 'GLCodesPriority2' feature flag, we should
      //   remove 'orgID' from the request body since it was only needed for
      //   checking the value of the feature flag.
      return this.$http.post(`/api/profiles/${profile.id}/crebits`, {
        crebits,
        orgID: profile.organizationID,
        isAddOnRemoval,
        isCouponRemoval
      })
        .then(({ data }) => data.forEach(this.upsertCrebit.bind(this)))
        .catch(error => this._handleHttpError(error, 'saveAdjustments', 'Crebit'));
    }

    removeAdjustment(profile, crebit) {
      const crebits = [];
      if (crebit.addOnID) {
        // First, we gotta zero out the addOnQuantity
        crebit.addOnQuantity = 0;
        // Now, we have to prepare to save a removal crebit for the add-on
        const removalCrebit = _.cloneDeep(crebit);
        delete removalCrebit.id;
        // Invert it's amount
        removalCrebit.amount = removalCrebit.amount * -1;
        // Mark it as a removal in the description (regex removes the orginal "[ADD-ON] " tag)
        removalCrebit.description = `[ADJUST] Remove ${removalCrebit.description.replace(/^\[.+\] /, '')}`;
        // Push em into the save array
        crebits.push(crebit, removalCrebit);
        return this.saveAdjustments(profile, crebits, true);
      } else {
        // Handling for non add-on crebits (coupons, cancellation fees, misc line items)
        crebit.description = `${crebit.description} (Void)`;
        crebit.addOnQuantity = 0;
        crebit.amount = 0;
        const isCouponRemoval = !!crebit.couponID;
        crebits.push(crebit);
        return this.saveAdjustments(profile, crebits, false, isCouponRemoval);
      }
    }

    createAdjustmentCrebit(regAdjustment) {
      const adjConfig = {
        description: `[ADJUST] ${regAdjustment.description}`,
        tuition: regAdjustment.tuition,
        amount: regAdjustment.amount,
        adjustedLineItemID: regAdjustment.adjustedLineItemID,
      };
      return this.createAdjustment(adjConfig);
    }

    createCouponCrebit(regCoupon) {
      const adjConfig = {
        description: `[COUPON] ${regCoupon.code}`,
        tuition: regCoupon.tuition,
        amount: regCoupon.amount * -1,
        couponID: regCoupon.id
      };
      return this.createAdjustment(adjConfig);
    }

    createAddOnCrebit(regAddOn) {
      try {
        let description;
        if (regAddOn.ui.numeric) {
          description = `${regAddOn.name} (${regAddOn.quantity})`;
        } else if (regAddOn.ui.isOption) {
          description = `${regAddOn.parent.name} / ${regAddOn.name}`;
        } else {
          description = regAddOn.name;
        }
        const adjConfig = {
          description: `[ADD-ON] ${description}`,
          tuition: regAddOn.tuition,
          amount: regAddOn.amount * regAddOn.quantity,
          addOnID: regAddOn.id,
          addOnQuantity: regAddOn.quantity
        };
        return this.createAdjustment(adjConfig);
      } catch (error) {
        this._handleInternalError('createAddOnCrebit', 'Failed to prepare add-on crebit', error);
      }
    }

    createAdjustment(config) {
      const { tuition, description, amount, couponID, addOnID, addOnQuantity, adjustedLineItemID } = config;
      if (!tuition) {
        throw Error(`${ERR_TAG}[createAdjustment] Can't create adjustment without tuition.`);
      }
      return new this.Crebit({
        ledger: 'organization',
        profileID: tuition.profileID,
        adjusts: tuition.id,
        description,
        amount,
        couponID,
        addOnID,
        addOnQuantity,
        adjustedLineItemID
      });
    }

    removeCrebitsByReg(registration) {
      return registration.crebits.forEach((regCrebit) => {
        return _profileRegStore.crebits.splice(_profileRegStore.crebits.findIndex((storeCrebit) => {
          return storeCrebit.id === regCrebit.id;
        }), 1);
      });
    }

    waitlistRegistration(registration) {
      const tmpReg = this.initTmpRegistration(registration.id);
      tmpReg.waitlisted = true;
      return this.saveTmpRegistration(tmpReg.id, ['waitlisted'])
        .then(() => this.removeCrebitsByReg(registration))
        .catch(error => this._handleHttpError(error, 'waitlistRegistration', 'Registration'));
    }

    promoteRegistrationFromWaitlist(registration) {
      const tmpReg = this.initTmpRegistration(registration.id);
      tmpReg.waitlisted = false;
      return this.saveTmpRegistration(tmpReg.id, ['waitlisted'])
        .then(() => this.saveTuition(registration))
        .catch(error => this._handleHttpError(error, 'promoteRegistrationFromWaitlist', 'Registration'));
    }

    deactivateRegistration(registration) {
      const tmpReg = this.initTmpRegistration(registration.id);
      return this.Registration.deactivateFlow(tmpReg)
        .then(({ data }) => {
          if (data) return this.upsertRegistration(data);
          else registration.deactivated = new Date().toISOString();
        })
        .catch(error => this._handleHttpError(error, 'deactivateRegistration', 'Registration'));
    }

    loadAddOns(addOns) {
      _profileRegStore.addOns.length = 0;
      return addOns.forEach(addOn => this.upsertAddOn(addOn));
    }

    loadCrebits(crebits) {
      _profileRegStore.crebits.length = 0;
      return crebits.forEach((crebit) => this.upsertCrebit(crebit));
    }

    loadCoupons(coupons) {
      _profileRegStore.coupons.length = 0;
      return coupons.forEach((coupon) => {
        // filter out the ones that bypass deposit
        if (coupon.properties.bypassDeposit) return;
        // only keep the good ones around to use
        _profileRegStore.coupons.push(coupon);
      });
    }

    loadRegistrations(registrations) {
      _profileRegStore.registrations.length = 0;
      return Promise.resolve(registrations.forEach((reg) => this.upsertRegistration(reg)));
    }

    upsertAddOn(newAddOn) {
      try {
        const existing = _profileRegStore.addOns.find(existing => existing.id === newAddOn.id);
        if (existing) {
          Object.assign(existing, newAddOn);
        } else {
          if (!newAddOn.parentID) {
            Object.defineProperty(newAddOn, 'options', {
              enumerable: true,
              configurable: false,
              get() {
                // borrowed from add-ons.directive.js which is used in the reg wizard
                return _profileRegStore.addOns
                  .filter(a => a.parentID === newAddOn.id)
                  .sort((a, b) => +a.properties.displayOrder - +b.properties.displayOrder);
              }
            });
          } else {
            Object.defineProperty(newAddOn, 'parent', {
              // Should stay false to avoid circular references during stringification
              enumerable: false,
              configurable: false,
              get() {
                return _profileRegStore.addOns.find(a => a.id === newAddOn.parentID);
              }
            });
          }
          Object.defineProperty(newAddOn, 'purchased', {
            enumerable: true,
            configurable: false,
            get() {
              // borrowed from add-ons.directive.js which is used in the reg wizard
              // When voiding an add-on we create a new crebit and set its quanitity to -1. If we have
              // anything for this sum, it's currently purchased.
              // Even if an add-on has already been purchased on a different registration, we can do
              // so again. We should show that it's already been purchased and link to that reg. This
              // code DOES NOT do that right now. We're not sure how we want to create that link yet,
              // so this is kind of a placeholder.
              if (this.options && this.options.length) {
                return this.options.some(opt => opt.purchased);
              }
              return _profileRegStore.crebits.reduce((count, c) => {
                if (c.addOnID === this.id) count += c.addOnQuantity;
                return count;
              }, 0) > 0;
            }
          });
          _profileRegStore.addOns.push(newAddOn);
        }
        return existing || newAddOn;
      } catch (error) {
        this._handleInternalError('upsertAddOn', 'Add-on upsert failed', error);
      }
    }

    upsertCrebit(newCrebit) {
      try {
        const existing = this.crebitById(newCrebit.id);
        if (existing) {
          Object.assign(existing, newCrebit);
        } else _profileRegStore.crebits.push(newCrebit);
        return existing || newCrebit;
      } catch (error) {
        this._handleInternalError('upsertCrebit', 'Crebit upsert failed', error);
      }
    }

    upsertRegistration(newReg) {
      // Guard against bad data - can't find a group, can't do nothin with it.
      if (!_profileRegStore.indexedOrg[newReg.groupID]) return;
      // Always assign the group that's already loaded into the FE.
      newReg.group = _profileRegStore.indexedOrg[newReg.groupID];
      // Let's see if it exists already
      const existing = this.regById(newReg.id);
      // Get the reg into the store, new or otherwise
      if (existing) {
        // add cancellation fee and other crebits to the store
        if (newReg.crebits) newReg.crebits.forEach(c => {
          // TODO - figure out why the API would ever send back null crebits!?!?!?
          if (c) this.upsertCrebit(c);
        });
        // the 'crebits' on the reg from the api need to be deleted, as the crebits key
        // on the reg is reserved for the non-configurable tuition accessor
        delete newReg.crebits;

        if (existing !== newReg) Object.assign(existing, newReg);
      } else _profileRegStore.registrations.push(newReg);
      // Get stable reference to current registration object
      const currentReg = existing || newReg;
      // Add accessors
      if (!currentReg.hasOwnProperty('tuition')) {
        this._addTuitionAccessors(currentReg);
      }
      if (!currentReg.hasOwnProperty('coupons')) {
        this._addCouponAccessors(currentReg);
      }
      if (!currentReg.hasOwnProperty('addOns')) {
        this._addAddOnAccessors(currentReg);
      }
      // Return the existing record if we've updated or the new reg if there wasn't an existing one.
      return currentReg;
    }

    /**
     * Deeply copies a registration to an intermediate placeholder for editing and stores it. The
     * temporary copy is stored at ProfileRegData.registrations.tmp[regID].
     * @param {Number} regID The ID of registration to copy
     * @returns {Object} tmp The temporary registration for editing.
     */
    initTmpRegistration(regID) {
      const existing = this.regById(regID);
      if (!existing) {
        throw Error(`${ERR_TAG}[initTmpRegistration] No registration found matching ID ${regID}`);
      }
      // Copy stuff over, but don't include the crebits array
      const tmp = Object.assign(this.Registration.blankSchema(), existing, { crebits: [] });
      _profileRegStore.tmp.registrations[regID] = tmp;
      return tmp;
    }

    /*
    * This function is fuckin' untestable. We've spent literally 5 hours on it. Our best move
    * now is just testing around it, so be careful if you change this up. We're testing
    * `_rawToResource` and `upsertRegistration`, but not this one.
    */
    saveTmpRegistration(regID, fieldsToSave) {
      const regToSave = _profileRegStore.tmp.registrations[regID];
      return this._rawToResource('Registration', regToSave)
        .save(fieldsToSave)
        .then(this.upsertRegistration.bind(this));
    }

    addOnById(addOnId) {
      try {
        return _profileRegStore.addOns.find(addOn => addOn.id === addOnId);
      } catch (error) {
        this._handleInternalError('addOnById', `Failed to find add-on ${addOnId}`, error);
      }
    }

    regByGroupId(groupId) {
      try {
        return _profileRegStore.registrations.find(reg => reg.groupID === groupId);
      } catch (error) {
        this._handleInternalError('regByGroupId', `Failed to find registration with group ID ${groupId}`, error);
      }
    }

    regById(regId) {
      try {
        return _profileRegStore.registrations.find(reg => reg.id === regId);
      } catch (error) {
        this._handleInternalError('regById', `Failed to find registration ${groupId}`, error);
      }
    }

    crebitById(crebitId) {
      try {
        return _profileRegStore.crebits.find(crebit => crebit.id === crebitId);
      } catch (error) {
        this._handleInternalError('crebitById', `Failed to find crebit ${crebitId}`, error);
      }
    }

    get profile() {
      return _profileRegStore.profile;
    }

    set profile(newprofile) {
      _profileRegStore.profile = newprofile;
      return _profileRegStore.profile;
    }

    get indexedOrg() {
      return _profileRegStore.indexedOrg;
    }

    set indexedOrg(newIndexedOrg) {
      _profileRegStore.indexedOrg = newIndexedOrg;
      return _profileRegStore.indexedOrg;
    }
  }

  return ProfileRegData;
})());
