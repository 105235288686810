lib.registerState('manager.profiles.profile.account.attributionProcess', {
  templateUrl: 'states/manager/profiles/profile/account/attribution-process/attribution-process.state.html',
  resolve: {
    title: ($rootScope) => $rootScope._title = 'Account',
  },
  params: { crebitID: null, lineItemType: null, balance: null, paymentMethods: [], organization: null, profileCrebits: [], profileGivenName: null },
  controller: function($filter, $scope, $state, $stateParams) {
    // TODO: These functions are defined twice: once in here and once in
    //   `line-item-state.js`. We should DRY up this code.
    function navigateToAccount () {
      $state.go('manager.profiles.profile.account');
    }

    function navigateToAndReloadAccount () {
      $state.go('manager.profiles.profile.account', {}, { reload: true });
    }

    $scope.parcelConfig = () => window.System.import('@dn/provider-profile-account');
    $scope.parcelProps = {
      lineItemType: $stateParams.lineItemType,
      crebitID: $stateParams.crebitID,
      crebit: {},
      balance: $stateParams.balance,
      profileID: $scope.profile.id,
      paymentMethods: $stateParams.paymentMethods,
      orgID: $stateParams.organization.id,
      orgName: $stateParams.organization.name || null,
      profileCrebits: $stateParams.profileCrebits,
      profileGivenName: $stateParams.profileGivenName || null,
      readOnly: !$filter('permissionVisible')({ profile_account: 'edit' }),
      acceptedCards: $stateParams.organization.properties.billing.acceptedCards,
      navigateToAccount,
      navigateToAndReloadAccount,
      applyUpdatedCrebitDescription: () => {}
    };
  }
});
