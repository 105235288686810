lib.registerState "manager.setup.season",
  url: "/seasons/:index"
  templateUrl: "states/manager/setup/season/season.state.html"
  resolve:
    season: ($stateParams, seasons) ->
      return _.find seasons, 'id': parseInt $stateParams.index

    accountManagers: ($http) ->
      $http.get("/api/account-managers").then (result) -> result.data

  controller: ($scope, $http, $state, $filter, Season, flash, session, season, accountManagers) ->

    # if we nav back to the parent state, it better be updated with latest data because
    # we can navigate to, e.g., update information and see stale data
    $scope.backButton = () ->
      return $state.go '^', undefined, {reload: true}

    $scope.EHR_TEMPLATE = "#{window.lib.brandingConfig.values.onboarding}/#{$scope.organization.division.name}-EHR-Template.xlsx"
    $scope.REG_TEMPLATE = "#{window.lib.brandingConfig.values.onboarding}/#{$scope.organization.division.name}-Registration-Template.xlsx"
    $scope.FORM_TEMPLATES = "https://support.docnetwork.org/hc/en-us/articles/115001183410#h_bb160256-bfea-4b0e-88f6-a802614b5d19"

    # Easy access to this value
    $scope.protPlanEnabled = !!($scope.organization.properties.features || {}).travelInsurance

    $scope.season = season || {}
    $scope.season.status ?= {}

    $scope.season.updates ?= []
    # Updates is a stringified array, and sometimes its stringified several times. Be warned.
    khaliseeBreakerOfLoops = 0
    while typeof($scope.season.updates) == 'string' && khaliseeBreakerOfLoops < 10
      $scope.season.updates = JSON.parse($scope.season.updates)
      khaliseeBreakerOfLoops += 1

    $scope.season.forms ?= {}
    $scope.season.forms.uploads ?= []
    $scope.season.properties ?= {}
    $scope.season.properties.signature ?= {}

    # `survey` looks like this:
    $scope.season.properties.survey ?= {
      regSoftware: null
      otherRegSoftware: null
      camperMail: null
      camperMailSoftware: null
      medicalMonth: null
    }

    # `interestIn` looks like this:
    $scope.season.properties.interestIn ?= {
      registration: null
      campGrams: null
      medical: null
      texting: null
      protectionPlan: null
      attendance: null
    }

    # explicitly default button for same forms as last season to 'no'
    $scope.season.forms.sameAsLastSeason ?= false

    $scope.season.status.started ?= new Date().toISOString()
    $scope.regSoftwareChoices = [
      {label: "ActiveNetwork", value: "activenetwork"}
      {label: "Blackbaud", value: "blackbaud"}
      {label: "Bunk1", value: "bunk"}
      {label: "CampBrain", value: "campbrain"}
      {label: "Campium", value: "campium"}
      {label: "CampMinder", value: "campminder"}
      {label: "CampSite", value: "campsite"}
      {label: "CircuiTree", value: "circuitree"}
      {label: "Daxko", value: "daxko"}
      {label: "DoubleKnot", value: "doubleknot"}
      {label: "Eventbrite", value: "eventbrite"}
      {label: "EZ-Camp", value: "ezcamp"}
      {label: "Google Forms", value: "googleforms"}
      {label: "Personify", value: "personify"}
      {label: "RecTrac", value: "rectrac"}
      {label: "Regpack", value: "regpack"}
      {label: "RenWeb", value: "renweb"}
      {label: "Salesforce", value: "salesforce"}
      {label: "SeniorSystems", value: "seniorsystems"}
      {label: "UltraCamp", value: "ultracamp"}
      {label: "WooFoo", value: "woofoo"}
      {label: "Other", value: "other"}
    ]
    $scope.yNotCampGrams = [
      {label: "Our campers do not receive mail at camp", value: "nomail"}
      {label: "We prefer that our campers receive snail mail", value: "snailmail"}
      {label: "We use other software for families to send electronic messages to campers", value: "other"}
    ]
    $scope.months = [
      {label: "January", value: "1"}
      {label: "February", value: "2"}
      {label: "March", value: "3"}
      {label: "April", value: "4"}
      {label: "May", value: "5"}
      {label: "June", value: "6"}
      {label: "July", value: "7"}
      {label: "August", value: "8"}
      {label: "September", value: "9"}
      {label: "October", value: "10"}
      {label: "November", value: "11"}
      {label: "December", value: "12"}
    ]

    # Used for date validation/rush fee stuff
    today = new Date().toISOString()
    fourWeeksFromNow = moment(today).add(27, "days").format("YYYY-MM-DD")
    # endOf('year') is used here to avoid errors on input validation if the user picks a date
    # that is after what today would be 2 years from now. https://momentjs.com/docs/#/manipulating/end-of/
    $scope.twoYearsFromCurrentYear = moment().add(2, 'y').endOf('year').format('YYYY-MM-DD')
    $scope.now = moment(today).format("YYYY-MM-DD")

    # Dynamically set the label for the Launch Timeline input
    $scope.launchLabelYesReg = "What date would you like to open registration to #{$scope.organization.division.patient.plural}?"
    $scope.launchLabelNoReg = "What is your target launch date?"

    # Ensure that if an org changes their mind and decides to use registration,
    # CampGrams, or stop buying medical supplies, we don't keep outdated info.
    # Performance wise, this seems a better option than watchers. Called before
    # each `save` operation.
    conformSurveyData = () ->
      if $scope.season.properties.interestIn.registration
        $scope.season.properties.survey.regSoftware = null
        $scope.season.properties.survey.otherRegSoftware = null
      if $scope.season.properties.interestIn.campGrams
        $scope.season.properties.survey.camperMail = null
        $scope.season.properties.survey.camperMailSoftware = null
      if !$scope.season.properties.interestIn.medical
        $scope.season.properties.survey.medicalMonth = null

    #################################################
    ##            WELCOME 2 DA DROPZONE            ##
    #################################################
    # Parse filenames from S3
    $scope.parseFileName = (ContentDisposition="") ->
      ContentDisposition.replace(/attachment; filename="|"/g, "")

    # A generic function for handling uploaded files
    mimez = {
      csv: "text/csv"
      xls: "application/vnd.ms-excel"
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      doc: "application/msword"
      docx:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      pdf: "application/pdf"
    }

    # A generic function for adding file references to arrays
    addFile = (section) ->
      saveArray = undefined
      if (section == 'sessionInfo')
        saveArray = $scope.season.updates
      else if (section == 'forms')
        saveArray = $scope.season.forms.uploads
      else
        return

      # Called on Dropzone's 'fileadded' event
      return (addedDoc) ->
        # Add a `created` date and save the doc object to the appropriate array
        addedDoc.created = new Date().toISOString()
        saveArray.push addedDoc
        $scope.save()

    # A generic function for deleteing file references from arrays
    removeFile = (section) ->
      saveArray = undefined
      if (section == 'sessionInfo')
        saveArray = $scope.season.updates
      else if (section == 'forms')
        saveArray = $scope.season.forms.uploads
      else
        return

      # Called on Dropzone's 'fileremoved' event
      return (removedDoc) ->
        _.remove saveArray, (savedDoc) ->
          # be careful! removedDoc has a lower case "k"
          return savedDoc.Key == removedDoc.key
        $scope.save()

    # Maps through saved documents, clones them, and adds a URL for downloading
    # them and retrieving thumbnails
    getExisting = (section) ->
      activeArray = undefined
      if section == 'sessionInfo'
        activeArray = $scope.season.updates
      else if section == 'forms'
        activeArray = $scope.season.forms.uploads
      # Though I developed a route to GET multiple signed URLs at once for use
      # in this state, it's ultimately not useful. The uploader directive still
      # attempts to individually GET each one for a thumbnail. We can craft
      # a URL without this route, meaning we don't wait for an extra, useless
      # HTTP request to finish.
      return activeArray.map (doc) ->
        url = "/api/groups/#{$scope.organization.id}/onboarding-documents/#{doc.Key}"
        # Clone the obj so we don't save the URL when we save `$scope.season`
        uploaderDoc = _.cloneDeep(doc)
        uploaderDoc.url = url
        return uploaderDoc

    # A convenient way to assign methods and properties to the uploaders
    $scope.uploaderConfig = {
      sessionInfo: {
        complete: addFile('sessionInfo')
        remove: removeFile('sessionInfo')
        accept: [
          mimez.csv,
          mimez.xls,
          mimez.xlsx
          ].join(",")
        existing: getExisting('sessionInfo')
      }
      forms: {
        complete: addFile('forms')
        remove: removeFile('forms')
        accept: [
          mimez.csv,
          mimez.xls,
          mimez.xlsx,
          mimez.doc,
          mimez.docx,
          mimez.pdf
          ].join(",")
        existing: getExisting('forms')
      }
    }
    #################################################
    ##         U R NOW LEAVING DA DROPZONE         ##
    #################################################

    calculateDates = (intervals) ->
      intervals = _.mapValues(intervals, (value, key) ->
        parseInt(value)
      )

      addDays = (days) ->
        date = new Date
        return moment().add(days, 'day').format('YYYY-MM-DD')

      return {
        testAccount: addDays parseInt(intervals.testAccount)
        reviewBy: addDays(intervals.testAccount + intervals.reviewBy)
        launch: addDays(intervals.testAccount + intervals.reviewBy + intervals.launch)
      }

    $scope.tlCheck = false
    $scope.$watch 'season.timeline.dates.requestedLaunch', (fresh, stale) ->
      return $scope.tlCheck = false if fresh is null
      # If the validator determines the date is `past`, it will set the model to undefined
      return $scope.tlCheck = 'past' if fresh is undefined
      return $scope.tlCheck = 'nope' if moment(fresh).isSameOrBefore(fourWeeksFromNow, 'day')
      return $scope.tlCheck = 'good'

    $scope.hasEstimates = ->
      return false unless $scope.season.timeline.estimates
      return false unless $scope.season.timeline.estimates.testAccount
      return false unless $scope.season.timeline.estimates.reviewBy
      return false unless $scope.season.timeline.estimates.launch
      return true

    $scope.fees =
      ccVariable: do ->
        if $scope.org.properties.processingFees?.card?.variable?
          return $scope.org.properties.processingFees.card.variable + '%'
        '3.95%'
      ccFlat: do ->
        if $scope.org.properties.processingFees?.card?.flat?
          return $scope.org.properties.processingFees.card.flat/100
        0.3
      achVariable: do ->
        if $scope.org.properties.processingFees?.bank?.variable?
          return $scope.org.properties.processingFees.bank.variable + '%'
        '1.5%'
      achFlat: do ->
        if $scope.org.properties.processingFees?.bank?.flat?
          return $scope.org.properties.processingFees.bank.flat/100
        0.5

    $scope.rate = if $scope.org.properties.billing?.rate? then $scope.org.properties.billing.rate/100 else 4

    initialStatus = do ->
      _.cloneDeep(season.status)

    if $scope.season.start?
      $scope.season.start = moment($scope.season.start).utc().format("YYYY-MM-DD")
    if $scope.season.finish?
      $scope.season.finish = moment($scope.season.finish).utc().format("YYYY-MM-DD")

    $scope.syncDates = ->
      seasonObj = _.cloneDeep $scope.season
      seasonObj.depositRequired = seasonObj.depositRequired || 0
      new Season(seasonObj).save().then(
        (success) ->
          $scope.season = success
          flash "Saved!"
        (error) ->
          flash "Error updating dates"
      )

    $scope.save = (sendNotification) ->
      $scope.season.profileEstimate = $scope.season.profileEstimate || 0
      $scope.season.depositRequired = $scope.season.depositRequired || 0
      conformSurveyData()

      seasonObj = _.cloneDeep $scope.season

      am = _.find accountManagers, (am) ->
        am.id.toString() is $scope.org.properties.accountManager

      content =
        orgName:        $scope.org?.name
        accountManager: am
        contacts:       $scope.org?.properties?.contacts

      payload =
        orgProperties: $scope.org.properties
        season: seasonObj
        content: content
        sendNotification: sendNotification

      # if (seasonObj.status.submitted && !seasonObj.status.reviewed) || (seasonObj.status.reviewed && !seasonObj.status.confirmed)
      $http.post("/api/organizations/#{$scope.org.id}/seasons/#{seasonObj.id}/stage-completed", payload).then(
        (result) ->
          if payload.season.status.launched
            swal "Ready to Launch", "Launch scheduled for #{moment(payload.season.timeline.dates.launch).format('LL')}", "success"
            $state.go("^", undefined, {reload:true})
          flash "Submission Saved"
        , (err) ->
          swal "Error Saving Submission", "There was an error saving your submission", "error"
        )

    $scope.monthName = (num) ->
      # year is irrelevant; translates numeric month into month name
      moment(new Date("2015-#{num or 10}-1")).format("MMMM")

    requiredFieldsPresent = (season) ->
      requiredFields = [
        {
          value: season.profileEstimate > 0
          msg: "Please estimate the number of #{$scope.organization.division.patient.plural} to continue"
        }
        {
          value: season.start?
          msg: "Please ensure your start and end dates are entered and valid to continue"
        }
        {
          value: season.finish?
          msg: "Please ensure your start and end dates are entered and valid to continue"
        }
        {
          value: (new Date(season.start) <= new Date(season.finish))
          msg: "Please ensure your start and end dates are entered and valid to continue"
        }
        {
          value: season.properties.interestIn.registration?
          msg: "Please indicate whether or not you're interested in registration to continue"
        }
        {
          value: season.properties.interestIn.texting?
          msg: "Please indicate whether or not you're interested in text message alerts to continue"
        }
        {
          value: season.properties.interestIn.medical?
          msg: "Please indicate whether or not you're interested in medical supplies to continue"
        }
        {
          value: season.properties.interestIn.attendance?
          msg: "Please indicate whether you're interested in the Attendance feature to continue"
        }
        {
          value: ($scope.tlCheck == 'good' || $scope.tlCheck == 'rush')
          msg: "Please enter a valid requested launch date to continue"
        }
        {
          value: (season.updates.length > 0)
          msg: "Please upload at least one Session Information document to continue"
        }
        {
          value: !season.forms.sameAsLastSeason || season.forms.uploads.length
          msg: "Please upload forms for all groups that will be using #{$scope.organization.division.name}"
        }
      ]

      # For non-reg orgs, make Protection Plan response required
      if !season.properties.interestIn.registration && !$scope.protPlanEnabled
        requiredFields.push {
          value: season.properties.interestIn.protectionPlan?
          msg: "Please indicate whether or not you'd like to offer Protection Plans through
            #{$scope.organization.division.name} to continue
          "
        }

      # For camps, make CampGrams response required
      if $scope.organization.division.name == 'CampDoc'
        requiredFields.push {
          value: season.properties.interestIn.campGrams?
          msg: "Please indicate whether or not you are interested in CampGrams to continue"
        }

      _.every requiredFields, (field) ->
        # If false, tell the user why the Submit button is disabled
        if !field.value
          $scope.whyIsSubmitDisabled = field.msg
        return field.value

    surveyAnswersValid = () ->

      # This array must be redeclared each run, otherwise the values are stale
      conditionalQuestions = [
        {
          ifProp: $scope.season.properties.interestIn.registration,
          condition: false,
          thenProp: $scope.season.properties.survey.regSoftware,
          msg: "Please share which registration software you'll be using to continue"
        }
        {
          ifProp: $scope.season.properties.survey.regSoftware
          condition: 'other'
          thenProp: $scope.season.properties.survey.otherRegSoftware,
          msg: "Please enter the name of the other registration software you'll be using to continue"
        }
        {
          ifProp: $scope.season.properties.interestIn.medical
          condition: true
          thenProp: $scope.season.properties.survey.medicalMonth,
          msg: "Please select the month you usually purchase your medical supplies to continue"
        }
      ];

      # If they're a camp, make CampGrams survey Qs required
      if $scope.organization.division.name == 'CampDoc'

        interestInGrams = {
          ifProp: $scope.season.properties.interestIn.campGrams
          condition: false
          thenProp: $scope.season.properties.survey.camperMail,
          msg: "Please share why you're not interested in CampGrams to continue"
        }

        yNotGrams = {
          ifProp: $scope.season.properties.survey.camperMail
          condition: 'other'
          thenProp: $scope.season.properties.survey.camperMailSoftware,
          msg: "Please enter the name of the #{$scope.organization.division.patient.singular} mail software you're using to continue"
        }

        conditionalQuestions.push interestInGrams, yNotGrams

      return _.every conditionalQuestions, (conditionalObj) ->
        {ifProp, condition, thenProp, msg} = conditionalObj
        if ifProp == condition
          if thenProp? && thenProp != ""
            true
          else
            $scope.whyIsSubmitDisabled = msg
            return false
        else
          $scope.whyIsSubmitDisabled = ''
          true

    $scope.incomplete = (season) ->
      if requiredFieldsPresent(season)
        # Separate these so that we don't overwrite whyIsSubmitDisabled until we've
        # entered all required fields
        if surveyAnswersValid()
          false
        else
          true
      else
        true

    $scope.unwindStatus = (status) ->
      $scope.org.properties.reviewMode = false
      delete $scope.season.status['launched']
      delete $scope.season.status[status]
      $scope.save()

    $scope.forwardStatus = (status, notify) ->
      $scope.season.status[status] = new Date().toISOString()
      $scope.save(notify)

    $scope.readOnly = ->
      !!$scope.season.status?.submitted || $scope.season.archived

    # The watcher was placed to avoid having too many checks on the ng-show
    # in the html. By using invalidAM it allows us to understand immediately
    # what is being checked.
    $scope.$watch 'org.properties.accountManager', ((fresh) ->
      # 293665 is the user id for help desk and we want to avoid using it
      if fresh == '293665' || fresh == ''
        $scope.invalidAM = true
      else
        $scope.invalidAM = false
    )
