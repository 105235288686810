Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "LogEntry", ->

  LogEntry = new Resource
    route: "/api/profiles/:patientID/log-entries/:id"

  # This function creates behavioral subsections with the updated tooltip text.
  # baseObject refers to common subsection that is present in both general and behavioral.
  updateTooltip = (baseObject, newTooltip) ->
    obj = Object.assign({}, baseObject)
    obj.models = [
      Object.assign({}, obj.models[0], { tooltip: newTooltip })
    ]
    return obj

  # Subjective / Location subsections
  chiefComplaint = { label: "Chief Complaint",   locked: true, show: true, models: [ { label: "Chief Complaint", model: "complaint", type: "select", tooltip: "The primary symptom, problem, or condition that is the reason for the medical encounter."      } ] }
  location =       { label: "Location",          show: true, models: [ { model: "location",  type: "select", tooltip: "The location where the illness or injury first occurred."      } ] }

  chiefComplaintBehavior = updateTooltip(chiefComplaint, "The primary symptom, problem, or condition that is the reason for the encounter.")
  locationBehavior = updateTooltip(location, "The location where the incident occurred.")

  # Assessment / Plan subsections
  otcMedication = { label: "OTC Medication", show: false, models: [ {label: "OTC Medication", model: "medicationID", type: "select", tooltip: "The OTC medication given for this medical encounter."} ] }
  disposition =   { label: "Disposition",       locked: true, show: true, models: [ { model: "disposition",      type: "select", tooltip: "The patient's anticipated location or status following the medical encounter."    } ] }
  assessment =    { label: "Assessment",  locked: true, show: true, models: [ { model: "assessment",  type: "textarea", tooltip: "A summary of the symptoms, exam findings, and a list of possible causes for the medical encounter." } ] }
  plan =          { label: "Plan",        locked: true, show: true, models: [ { label: "Plan", model: "treatment",   type: "textarea", tooltip: "A description of how the patient's concerns will be treated. This may include procedures performed, medications given, and education provided." } ] }
  provider =      { label: "Provider",    show: true, locked: true, models: [ { label: "Provider", model: "providerID", type: "select" } ] }
  followup =      { label: "Follow-Up",   show: false, models: [ { model: "followUp", label: "Follow Up", type: "timestamp"    } ] }

  # Assessment / Plan subsections specifc to behavioral health
  sra =        { label: "Suicidal Risk Assessment", show: false, models: [ { label: "Suicidal Risk Assessment", model: "sra", type: "select" } ] }
  hra =        { label: "Homicidal Risk Assessment", show: false, models: [ { label: "Homicidal Risk Assessment", model: "hra", type: "select" } ] }
  siba =       { label: "Self-Injurious Behavior Assessment", show: false, models: [ { label: "Self-Injurious Behavior Assessment", model: "siba", type: "tags" } ] }
  anar =       { label: "Abuse/Neglect Risk Assessment", show: false, models: [ { label: "Abuse/Neglect Risk Assessment", model: "anra", type: "tags" } ] }
  bra =        { label: "Bullying Risk Assessment", show: false, models: [ { label: "Bullying Risk Assessment", model: "bra", type: "tags" } ] }
  assessmentBehavior = updateTooltip(assessment, "A summary of the observations, findings, and possible causes for the encounter.")
  planBehavior =  updateTooltip(plan, "A description of how the participant's concerns will be addressed. This may include ongoing meetings, observation, intervention strategies, and next steps.")

  generalHealth = [
    {
      label: "Subjective"
      subsections: [
        chiefComplaint
        location
        { label: "HPI",               locked: true, show: true, models: [ { label: "History", model: "details",   type: "textarea", tooltip: "A detailed description of the reason for the medical encounter, including location, quality, severity, duration, timing, context, modifying factors, and other associated signs or symptoms."    } ] }
      ]
    }
    {
      label: "Vitals"
      subsections: [
        { label: "Temperature",        show: false, models: [ { model: "temperatureValue",  type: "text", validate: "required|number" }, { model: "temperatureUnit", type: "select", validate: "required"  } ] }
        { label: "Heart Rate",         show: false, models: [ { model: "heartRate",         type: "select"    } ] }
        { label: "Respiratory Rate",   show: false, models: [ { model: "respiratoryRate",   type: "select"    } ] }
        { label: "Blood Pressure",     show: false, models: [ { model: "systolic", label: "Systolic", type: "select" }, { model: "diastolic", label: "Diastolic", type: "select" } ] }
        { label: "Oxygen Saturation",  show: false, models: [ { model: "oxygenSaturation",  type: "select"  } ] }
        { label: "Blood Glucose",      show: false, models: [ { model: "bloodGlucose",      type: "select"    } ] }
        { label: "Pain Level",         show: false, models: [ { model: "pain",              type: "select"  } ] }
      ]
    }
    {
      label: "Physical Exam"
      subsections: [
        { label: "General",          show: false, models: [ { exam: true, model: "general",          type: "text" } ] }
        { label: "HEENT",            show: false, models: [ { exam: true, model: "HEENT",            type: "text" } ] }
        { label: "Neck",             show: false, models: [ { exam: true, model: "neck",             type: "text" } ] }
        { label: "Cardiovascular",   show: false, models: [ { exam: true, model: "cardiovascular",   type: "text" } ] }
        { label: "Respiratory",      show: false, models: [ { exam: true, model: "respiratory",      type: "text" } ] }
        { label: "Abdomen",          show: false, models: [ { exam: true, model: "abdomen",          type: "text" } ] }
        { label: "Genitourinary",    show: false, models: [ { exam: true, model: "genitourinary",    type: "text" } ] }
        { label: "Musculoskeletal",  show: false, models: [ { exam: true, model: "musculoskeletal",  type: "text" } ] }
        { label: "Neurologic",       show: false, models: [ { exam: true, model: "neurologic",       type: "text" } ] }
        { label: "Psychiatric",      show: false, models: [ { exam: true, model: "psychiatric",      type: "text" } ] }
        { label: "Skin",             show: false, models: [ { exam: true, model: "skin",             type: "text" } ] }
      ]
    }
    {
      label: "Assessment/Plan"
      subsections: [
        otcMedication
        assessment
        plan
        provider
        disposition
        followup
      ]
    }
    {
      label: "Additional"
      subsections: [
        { label: "Attach Document",   show: true,  models: [ { model: "file",             type: "upload", tooltip: "Any photos, laboratory studies, radiology reports, or other relevant documents that pertain to the medical encounter."  } ] }
        { label: "Nursing Diagnosis", show: false, models: [ { model: "nursingDiagnosis", type: "select"  } ] }
        { label: "Medical Diagnosis", show: false, models: [ { model: "diagnosis", type: "select",  ajax: "/api/constants/medical-diagnosis/search"} ] }
      ]
    }
  ]

  behavioralHealth = [
    {
      label: "Subjective"
      subsections: [
        chiefComplaintBehavior
        { label: "History", locked: true, show: true, models: [ { label: "History", model: "details", type: "textarea", tooltip: "A detailed description of the reason for the encounter, including location, quality, severity, duration, timing, context, modifying factors, and other associated signs or symptoms." } ] }
        locationBehavior
      ]
    }
    {
      label: "Objective"
      subsections: [
        { label: "Mood", show: false, models: [ { model: "mood", type: "tags"  } ] }
        { label: "Affect", show: false, models: [ { model: "affect", type: "tags" } ] }
        { label: "Cognitive Functioning", show: false, models: [ { model: "cognitiveFunctioning", type: "tags" } ] }
      ]
    }
    {
      label: "Assessment/Plan"
      subsections: [
        sra
        hra
        siba
        anar
        bra
        assessmentBehavior
        planBehavior
        provider
        followup
      ]
    }
    {
      label: "Additional"
      subsections: [
        { label: "Attach Document",   show: true,  models: [ { model: "file", type: "upload", tooltip: "Any photos, reports, screenings, or other relevant documents that pertain to the encounter."  } ] }
      ]
    }
  ]

  # This function takes the actual general/ behavioral array and creates the array with the subsections in the desired order for sidebar
  # baseArray - this is the actual general/ behavioral array with subsections
  # subsections - this array contains the subsection in order to be displayed in side bar
  getSidebarList = (baseArray, subsections) ->
    sidebar = baseArray.map (section) ->
      if section.label is "Assessment/Plan"
        return {
          label: "Assessment/Plan"
          subsections
        }
      else
        return section
    return sidebar

  generalConfig = {
    subsections: [
        assessment
        plan
        otcMedication
        provider
        disposition
        followup
    ]}

  behavioralConfig ={
    subsections:[
      assessmentBehavior
      provider
      bra
      siba
      hra
      planBehavior
      followup
      sra
      anar
    ]
  }

  generalHealthSideBar    = getSidebarList(generalHealth, generalConfig.subsections)
  behavioralHealthSideBar = getSidebarList(behavioralHealth, behavioralConfig.subsections)

  LogEntry.sections = {
    generalHealth: generalHealth,
    behavioralHealth: behavioralHealth,
    behavioralHealthSideBar: behavioralHealthSideBar,
    generalHealthSideBar: generalHealthSideBar
  }

  LogEntry
