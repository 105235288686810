# Handle a 'select' type question
angular.module("dn").directive "qtSelect", ->
  replace: true
  templateUrl: "directives/taker/select/qt-select.directive.html"
  restrict: "A"
  scope:
    question: "=qtSelect"
    answer: "="
  controller: ($scope) ->
    $scope.question.body ?= ""
    $scope.validate = if $scope.question.required then "required" else ""

    # Map and replace newlines to expose $scope.options as a select-ready list of options
    $scope.options = _.map $scope.question.body.split(","), (item) ->
      { label: item.replace(/\n/g, ""), value: item.replace(/\n/g, "")}
