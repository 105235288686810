lib.registerState("oper.organizations.organization.finances", {
  url: "/finances",
  templateUrl: "states/oper/organizations/organization/finances/finances.state.html",
  resolve: {
    paymentMethods: ($http, organization) => {
      return $http.get(`/api/organizations/${organization.id}/payment-methods`).then(result => result.data);
    },
  },

  controller($filter, $scope, $state, $http, organization, paymentMethods, session) {
    $scope.orgPayProcessor = organization.orgPayProcessors[0];
    $scope.payoutAuthorized = session.userID === 21437; // manual payouts a michael-only feature
    $scope.paymentMethods = paymentMethods;
    $scope.ui = {
      newMethod: {
        groupID: $scope.organization.id
      }
    };

    if ($scope.payoutAuthorized) {
      $scope.ui.manualPayout = {
        orgID: organization.id,
        amount: null,
        description: null,
      };
    }

    $scope.saveMethod = () => {
      if (!$scope.ui.newMethod) { return; }
      $scope.saving = true;
      return $http.post(`/api/organizations/${$scope.organization.id}/payment-methods`, $scope.ui.newMethod).then(() => {
        $state.reload($state.current.name);
      },
      (err) => {
        $scope.saving = false;
        return window.swal({
          title: "Couldn't Save Payment Method",
          text: `Please check payment method credentials and try again \n Error: ${err.data}`,
          type: "error",
          showCancelButton: false,
          closeOnConfirm: true
        });
      });
    };

    $scope.cancel = () => {
      return $scope.adding = false;
    };

    $scope.removeMethod = (method) => {
      if (!method) { return; }
      return $http.delete(`/api/organizations/${$scope.organization.id}/payment-methods/${method.id}`).then(() => $state.reload($state.current.name));
    };

    $scope.addPayout = () => {
      $http.get(`/api/organizations/${$scope.organization.id}/ledger-balance/organization`).then(({data}) => {

        return swal({
          type: 'warning',
          title: "Manual Payout",
          text: `Create ${$filter('currency')($scope.ui.manualPayout.amount / 100)} payout for ${$scope.organization.name}? \n\nCurrent balance: ${$filter('currency')(data.balance/100)}`,
          confirmButtonText: "Confirm",
          showCancelButton: true,
          closeOnConfirm: false,
        }, (isConfirm) => {

          if (!isConfirm) return;
          $http.post('/api/operator/transaction/manual-payout', $scope.ui.manualPayout).then(() => {
            return swal({
              type: 'success',
              title: 'Manual Payout',
              text: 'Manual payout successfully created',
              confirmButtonText: 'Ledger',
              showCancelButton: true,
              cancelButtonText: 'Close',
            }, afterPayoutSwal);
          },
          (err) => {
            return swal({
              type: 'error',
              title: 'Error submitting manual payout',
              text: `Error: ${err.status}, ${err.statusText}. Please confirm in the organization's ledger that no payout was created.`,
              confirmButtonText: 'Ledger',
              showCancelButton: true,
              cancelButtonText: 'Close'
            }, afterPayoutSwal);
          });

        });

      },
      (err) => {
        return swal({
          type: 'error',
          title: 'Error getting organization balance',
          text: `Error: ${err.status}, ${err.statusText}. Please try again.`
        });
      });
    };

    function afterPayoutSwal(isConfirm) {
      $scope.ui.manualPayout.description = null;
      $scope.ui.manualPayout.amount = 0;
      if (!isConfirm) return;
      $state.go('manager.finances.orgLedger', {orgID: $scope.ui.manualPayout.orgID});
    }
  }
});
