angular.module('dn').directive('syncButton', function () {
  return {
    templateUrl: 'directives/importer/daxko-sync/sync-button/sync-button.directive.html',
    restrict: 'E',
    controller: function ($http, $rootScope, $scope) {
      $scope.handleClick = function() {
        const jobBody = {
          data: {
            identifier: $scope.groupIdentifier
          }
        };
        $scope.syncing = true;
        const url = `/api/organizations/${$rootScope.organization.id}/daxko-settings/sync`;
        $http.post(url, jobBody)
          .then(() => flash('Sync Initiated'))
          .catch(() => flash('Failed to Initiate Sync'))
          .finally(() => $scope.syncing = false);
      };
      $scope.syncing = false;
      $scope.groupIdentifier = '';
    }};
});
