lib.registerState('home.account.profiles', {
  url: '/profiles',
  resolve: {
    title: function ($rootScope) {
      $rootScope._title = 'User Settings / ' + $rootScope.division.patient.plural.capitalize();
    },
    session: function ($http) {
      return $http.get('/api/session?reaccess').then(function (result) {
        return result.data;
      });
    },
    invite: function ($location, ProfilesUser, $q) {
      var d = $q.defer();
      if (!$location.search().i) return d.resolve(null);
      var inviteRequest = ProfilesUser.byInvite($location.search().i);
      inviteRequest.then(function (invite) {
        d.resolve(invite);
      });
      inviteRequest.catch(function () {
        d.resolve('ERROR');
      });
      return d.promise;
    }
  },
  templateUrl: 'states/home/account/profiles/profiles.state.html',
  controller: function ($scope, $state, session, invite, $http) {
    $scope.invites = _.map(session.access.invites, function (invite) {
      invite.accept = true;
      invite.profileID = invite.id;
      return invite;
    });

    if (invite && invite !== 'ERROR') {
      invite.accept = true;
      $scope.invites.push(invite);
    }

    $scope.invites = _.uniqBy($scope.invites, 'profileID');

    $scope.organizations = _.map(session.access.organizations, function (organization) {
      organization.profiles = _.uniqBy(_.flattenDeep([
        organization.providers,
        organization.patients,
        organization.orphans
      ]), function (profile) { return profile.id; });
      return organization;
    });

    $scope.acceptInvites = function () {
      var accepted = _.filter($scope.invites, function (invite) {
        return invite.accept;
      });
      var keys = _.map(accepted, 'inviteKey');
      $http.post('/api/users/' + session.userID + '/link', keys).then(function () {
        $state.go('home.whereTo', {i:null});
      });
    };

  }
});
