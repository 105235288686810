lib.registerState "manager.profiles.profile.medications.list",
  url: "/list"
  templateUrl: "states/manager/profiles/profile/medications/list/medications-list.state.html"
  resolve:
    deactivatedMeds: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/medications")
        .then((result) -> result.data.filter((med) -> med.deactivated && med.otcID == null))
        .catch(() -> [])

    medications: ($http, profile, $stateParams) ->
      medIDs = _.map profile.medications, (m) -> m.id
      if medIDs.length
        $http.get("/api/organizations/#{$stateParams.orgID}/profiles/#{profile.id}/medication-count?medIDs=#{medIDs.join("|")}").then (result) ->
          result.data

  controller: ($scope, profile, medications, deactivatedMeds) ->
    $scope.medications = _(profile.medications).map((m) ->
      return null if m.otcID
      m.frequency = window.lib.meds.findFrequency(m)
      return m unless m.trackQuantity
      m.inventory = _.find (medications), (med) -> med.id is m.id
      m
    ).compact().value()

    $scope.hasMeds = profile.has.medications && (profile.has.medications == 'true' || profile.has.medications.value == 'true') && profile.medications.length

    $scope.otcMeds = _.filter profile.medications, (m) -> m.otcID

    $scope.deactivatedMeds = deactivatedMeds

    unless _.poserBoolean profile.has.medications
      $scope.medications = profile.medications
