angular.module("dn").directive "qtPrimaryProviders", ->
  templateUrl: "directives/taker/modules/primary-providers/qt-primary-providers.directive.html"
  restrict: "A"
  scope:
    question: "=qtPrimaryProviders"
    primaryProviders: "="
  controller: ($scope, $stateParams, Constants, HealthForm) ->

    profileID = $stateParams.profileID
    healthForm = HealthForm.get profileID

    $scope.states = _.map Constants._states, (s) ->
      { label: s.constant.name, value: s.constant.name }
    $scope.countries = _.map Constants._countries, (c) ->
      { label: c.constant.name, value: c.constant.name }

    qProviders = _.keyBy $scope.question.metadata.providers, "identifier"
    $scope.qProviders = qProviders

    mapProviders = ->
      $scope.primaryProviders = _.keyBy $scope.primaryProviders, "identifier"

      _.forIn qProviders, (c, id) ->
        $scope.primaryProviders[id] ?= {}
        $scope.primaryProviders[id].profileID = parseInt(profileID)
        $scope.primaryProviders[id].identifier ?= id
        $scope.primaryProviders[id].required ?= c.required
        $scope.primaryProviders[id].label ?= c.label

        if $scope.primaryProviders[id].state in _.map($scope.countries, "value")
          $scope.primaryProviders[id].international = true

      # Remove providers that might be left over
      _.forIn $scope.primaryProviders, (value, key) ->
        if not _.includes _.keys(qProviders), key then delete $scope.primaryProviders[key]

    do mapProviders

    $scope.complete = (provider) ->
      if $scope.question?.expiration and provider?.updated
        return false if $scope.question.expiration > provider?.updated

      if not provider.required
        provider.complete = true
        return true

      req = ["name", "phone", "address", "city", "state", "zip"]
      provider.complete = _.every req, (r) -> provider[r]
      provider.complete

    healthForm.completeFns[$scope.question.id].push ->
      _.map _.values($scope.primaryProviders), (p) -> $scope.complete p
      _.every _.values($scope.primaryProviders), (p) -> return p.complete

    $scope.switchInternational = (provider) -> provider.state = ""

    ###
    #   Save Queue
    ###
    saveFn = (nothing, fn=(->)) ->
      saveProviders = _.values $scope.primaryProviders
      return fn() unless saveProviders.length
      healthForm.save("primary-providers", profileID, saveProviders).success (providers) ->
        _.map providers, (p) -> $scope.primaryProviders[p.identifier].id = p.id
        fn()

    saveQueue = async.queue saveFn, 1
    addSave = _.debounce ->
      saveQueue.push saveFn
    , 1000

    initialized = false
    $scope.$watch "primaryProviders", (fresh, stale) ->
      healthForm.checkComplete[$scope.question.id]()
      if !initialized then return initialized = true
      if fresh is stale then return
      do addSave
    , true

    $scope.requirement = (str, provider) ->
      str ?= ""
      if provider.required then "required|#{str}" else str
