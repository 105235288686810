lib.registerState "manager.profiles.profile.medications.list.medication",
  url: "/:medicationID"
  templateUrl: "states/manager/profiles/profile/medications/list/medication/medication.state.html"
  resolve:
    medication: (Medication, Mar, $http, $stateParams) ->
      if $stateParams.medicationID is "new"
        return new Medication { profileID: $stateParams.profileID, willTake: true }
      else
        med = new Medication { id: $stateParams.medicationID, profileID: $stateParams.profileID }
        .load(["mars"]).then (medication) ->
          medication.frequency = window.lib.meds.findFrequency(medication)
          medication.inventory = 0
          _.map medication.mars, (mar) ->
            medication.marExists ?= true if mar.properties
            medication.inventory += mar.quantity or 0
            mar = new Mar mar

          medication

  controller: ($scope, $state, $http, flash, Constants, profile, medication, Mar, WhatDivision, session) ->
    $scope.division = WhatDivision
    $scope.deactivatedOnInit = !!medication.deactivated

    $scope.nextAdministration = window.lib.nextAdministration(profile, medication) if medication.id
    $scope.editing = !medication.id

    $scope.times = [
      [
        "Overnight"
        "Early Morning"
        "Breakfast"
        "Late Morning"
      ]
      [
        "Lunch"
        "Afternoon"
        "Dinner"
        "Evening"
      ]
      [
        "Bedtime"
        "As Needed"
      ]
    ]

    # Default these to false - fix for #4897
    medication.willTake           ?= false
    medication.emergency          ?= false
    medication.canSelfAdminister  ?= false
    medication.trackQuantity      ?= false
    $scope.medication = medication

    # Get FDA Medications
    $scope.fdaMeds = null
    Constants.fdaMeds().then (result) ->
      $scope.fdaMeds = _.keys(result).sort()
      $scope.strengths = result

    $scope.doseTypes = [
      { value: "application", label: "Application(s)" },
      { value: "capful", label: "Capful(s)" },
      { value: "capsule", label: "Capsule(s)" },
      { value: "drop", label: "Drop(s)" },
      { value: "eardrop", label: "Ear Drop(s)" },
      { value: "eyedrop", label: "Eye Drop(s)" },
      { value: "inhalation", label: "Inhalation(s)" },
      { value: "injection", label: "Injection(s)" },
      { value: "milligram", label: "Milligram(s) (mg)" },
      { value: "millileter", label: "Millileter(s) (mL)" },
      { value: "other", label: "Other" },
      { value: "packet", label: "Packet(s)" },
      { value: "pill", label: "Pill(s)" },
      { value: "spray", label: "Spray(s)" },
      { value: "suppository", label: "Suppository" },
      { value: "tablespoon", label: "Tablespoon(s)" },
      { value: "tablet", label: "Tablet(s)" },
      { value: "teaspoon", label: "Teaspoon(s)" },
      { value: "unit", label: "Unit(s)" }
    ]

    $scope.redundantDoseType = ->
      strength = $scope.medication.strength?.toLowerCase() or ""
      doseType = $scope.medication.doseType?.toLowerCase() or ""
      strength.indexOf(doseType) isnt -1

    $scope.medication.times = -> _.filter((_.keys $scope.medication.timesTaken), (k) ->
      $scope.medication.timesTaken[k]
    ).join " • "

    $scope.days = [
      "Mon"
      "Tue"
      "Wed"
      "Thu"
      "Fri"
      "Sat"
      "Sun"
    ]

    $scope.medication.daysTaken ?= {"Mon": true, "Tue": true, "Wed": true, "Thu": true, "Fri": true, "Sat": true, "Sun": true}

    $scope.frequencyChoices = window.lib.meds.frequencyChoices

    $scope.filling = false

    $scope.trackableValue = (doseValue="0") ->
      doseValue = doseValue.toString()
      return false unless doseValue.match /^-?\d*(\.\d+)?$/
      doseValue = parseFloat doseValue.toString()
      return false if _.isNaN(doseValue) or doseValue < 0.5
      !(doseValue % 0.5)

    $scope.fillMars = []

    $scope.fillMar =
      date: new Date().toISOString().slice 0, 10
      quantity: 30
      providerID: session.userID
      profileID: $scope.profile.id
      medicationID: $scope.medication.id
      slot: "fill"

    $scope.fillMed = ->
      $scope.filling = true
      mar = new Mar _.clone $scope.fillMar
      mar.properties = {
        updates: [
          {
            initials: session.user.givenName.charAt(0) + session.user.familyName.charAt(0)
            note: $scope.fillMar.note || ""
          }
        ]
      }
      mar.save().then (mar) -> $scope.medication.mars.push mar
      $scope.medication.inventory = +$scope.medication.inventory + +mar.quantity
      swal {
        title: "Medication Filled"
        type: "success"
      }, (confirmed) ->
          return $scope.filling = false

    $scope.deactivate = () ->
      swal {
        type: "warning"
        title: "Warning!"
        text: "
          <strong>Medications cannot be reactivated.</strong>
          <br><br>
          Are you sure you'd like to continue?
        "
        html: true,
        showCancelButton: true
        confirmButtonText: "Deactivate"
        confirmButtonColor: "#DD6B55"
      }, (confirmed) ->
        return unless confirmed
        $scope.medication.deactivated = new Date().toISOString()
        $http.put("/api/profiles/#{$scope.medication.profileID}/medications", [$scope.medication]).then (result) ->
          $scope.medication = result.data[0]
          _.remove $scope.profile.medications, { id: $scope.medication.id }
          flash "Medication Deactivated"
          $state.go ".", {medicationID: $scope.medication.id}, {reload: "manager.profiles.profile.medications.list"}

    $scope.saveDisabled = (medication) ->
      return !medication.doseValue  ||
             !medication.name       ||
             !medication.strength   ||
             !medication.doseType   ||
             !medication.indication ||
             !$scope.hasTimes       ||
             (medication.frequency == "schedule" && !$scope.hasDays) ||
             (medication.frequency == "interval" && (!medication.interval || +medication.interval < 2))

    $scope.save = ->
      $scope.editing = false

      # Sanity check the medication prior to saving, clean out any rogue values.
      if $scope.medication.frequency != "schedule"
        $scope.medication.daysTaken = window.lib.meds.defaultDays()

      if $scope.medication.frequency != "interval"
        $scope.medication.interval = 1

      $http.put("/api/profiles/#{$scope.medication.profileID}/medications", [$scope.medication]).then (result) ->
        $scope.medication = result.data[0]
        flash "Medication Saved"
        $scope.profile.medications.push $scope.medication
        # Switch this value to show meds in the list when this is the first med added for a profile
        _.set($scope.profile, 'has.medications.value', 'true')
        $state.go ".", {medicationID: $scope.medication.id}, {reload: "manager.profiles.profile.medications.list"}

    $scope.$watch "medication", (fresh, stale) ->
      $scope.hasDays = _.some $scope.medication.daysTaken
      $scope.hasTimes = _.some $scope.medication.timesTaken
    , true
