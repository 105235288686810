lib.registerState "checkIn",
  url: "/check-in"
  resolve:
    orgID: ($http, $state) ->
      $http.get("/api/session?reaccess").then (result) ->
        return $state.go "home" unless result.data?.checkInMode
        result.data.checkInMode
    checkIn: ($http, orgID) ->
      $http.get("/api/organizations/#{orgID}/check-in").then (r) -> r.data
  templateUrl: "states/check-in/check-in.state.html"

  controller: ($scope, $state, orgID, checkIn, $http, $interval) ->
    $interval ->
      $http.get("api/organizations/#{orgID}/triage-mode").then (
        (success) -> return),
        (err) -> console.log err if err
    , 10 * 60 * 1000

    $scope.profiles = checkIn.profiles
    $scope.instructions ?= checkIn?.properties?.healthLog?.checkIn?.instructions
    $scope.orgName = checkIn.orgName
    $scope.profileChoices = _.map checkIn.profiles, (p) ->
      value: p.id, label: "#{p.familyName}, #{p.givenName} (#{p.dob or ''})"

    $scope.stockUrl = window.lib.brandingConfig.values.wordmarks.stock

    $scope.checkIn = (entry) ->
      $scope.submitting = true
      entry.groupID = orgID
      entry.triage = true
      entry.draft = true
      entry.logType = "general-health"
      $http.post("/api/organizations/#{orgID}/check-in", entry).then ->
        swal
          title: "You've Checked-In"
          type: "success"
          timer: 2000
        $state.reload()
