lib.registerState("manager.settings.general.emergency", {
  url: "/emergency",
  templateUrl: "states/manager/settings/general/emergency/emergency.state.html",
  controller: function($scope, $filter, session, WhatDivision) {
    $scope.division = WhatDivision;

    $scope.yesNo = [
      { label: "No", value: false },
      { label: "Yes", value: true }
    ];

    $scope.subGroupProvider = window.lib.isSubGroupProvider(session, $scope.organization);

    $scope.nonOrgAccessExplanation = $scope.subGroupProvider
      ? 'You do not have sufficient privileges to change emergency plan settings. Only top-level providers may change emergency plan settings.'
      : '';

    $scope.editable = $filter('permissionVisible')({ settings_emergency_plan: 'edit' });
  }
})
