angular.module('dn').directive('scheduledRemindersCard', function () {
  return {
    templateUrl: 'directives/scheduled-reminders/scheduled-reminders-card/scheduled-reminders-card.directive.html',
    restrict: 'E',
    scope: {
      scheduledReminder: '=',
      deactivateScheduledReminder: '='
    },
    controller($rootScope, $scope, $state) {
      'use strict';

      // Defaults
      const BTN_RED = 'btn-danger';
      const BTN_GREEN = 'btn-primary';

      $scope.ui = {
        saving: false,
        showAll: false,
      };

      /**
       * It toggles the ui.show variable used for the tag-builder
       */
      $scope.toggleTagsBtn = () => $scope.ui.showAll = !$scope.ui.showAll;

      /**
       * It formats the timestamp of the reminder
       * @param {string} timestamp
       * @returns {string} Formatted timestamp
       */
      $scope.formatTime = (timestamp) => {
        let timezone;
        if ($scope.scheduledReminder.timezone.length !== 0) {
          timezone = $scope.scheduledReminder.timezone;
        }

        const momentObj = timezone ? moment(timestamp).tz(timezone) : moment(timestamp).utc();
        return momentObj.isValid() ? momentObj.format('MMM Do [at] h:mm a z') : 'Reminder Done Sending';
      };

      // Generate an array of human-readable filters and save it to scope
      const filterString = $scope.scheduledReminder.filter_string;
      const readableFilters = window.lib.filterParse.parseFilterString($rootScope.filterSets, filterString);
      $scope.readableFilters = readableFilters;

      /**
       * Generates a config that buttons share
       * @param {string} name - Name and title
       * @param {string} icon - Font awesome icon
       * @param {string} color - DN colors
       * @param {function} action - it takes reminder as a param
       * @returns {Object} Button config object
       */
      function generateButtonConfig(name, icon, color, action) {
        return {
          name,
          title: name.capitalize(),
          icon: `fas ${icon}`,
          color,
          action,
        };
      }

      /**
       * Generates a swal for use in the buttonConfig
       * @returns {Promise<SweetAlert>} A Promise that resolves to a swal alert which asks the user
       *   to continue with disabling reminders
       */
      function confirmDelete() {
        return new Promise((resolve) => {
          swal({
            title: 'Deactivate Reminder',
            text: `Are you sure you wish to deactivate ${$scope.scheduledReminder.name}?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Deactivate',
            confirmButtonColor: '#E3434A', // This is .bg-red-light according to http://scg.ar-ch.org/
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
          }, (confirmed) => resolve(confirmed));
        });
      }

      /**
       * Triggers confirmDelete and, based on user input, either moves forward with deactivating
       * the reminder, or closes the swal.
       * @returns {*} either calls deactivateScheduledReminder or resolves confirmDelete with nothing.
       */
      function handleDeactivate() {
        return confirmDelete().then((confirmed) => {
          if (confirmed) return $scope.deactivateScheduledReminder($scope.scheduledReminder);
          return Promise.resolve();
        });
      }

      $scope.buttonConfig = [
        generateButtonConfig('edit', 'fa-pencil-alt', BTN_GREEN, (scheduledReminder) => {
          $state.go('.scheduledReminder', { reminderID: scheduledReminder.id });
        }),
        generateButtonConfig('deactivate', 'fa-trash-alt', BTN_RED, handleDeactivate),
      ];
    },
  };
});
