angular.module('dn').directive('attendanceAudit', function () {
  return {
    templateUrl: 'directives/attendance-audit/attendance-audit.directive.html',
    restrict: "E",
    scope: {
      recordAndNotes: "=",
      profileId: "=",
      orgID: "=orgId",
      users: "=",
      auditUser: "="
    },
    controller($rootScope, $scope, AttendanceData, ContactData, OrgProviders) {
      const originalBackConfig = _.cloneDeep(AttendanceData.ui.buttons.back);
      const timezone = $rootScope.organization.properties.timezone;
      const types = ((($rootScope.organization.properties.features || {}).attendance || {}).types || []);
      types.push('Attendance');
      const CHECK_IN_TYPE = 'Check In';
      const CHECK_OUT_TYPE = 'Check Out';
      const { recordAndNotes, index: eventIndex, } = $scope.recordAndNotes;
      // forceSave: Monitor the deletion of notes and if one or more is deleted override _changesMade saving the record
      let forceSave = false;
      const auditListTab = { label: 'Auditors', value: 'auditors' };
      const swalInfo = {
        type: 'warning',
        title: 'Delete Entire Timeline',
        text: `
          You are about to delete the entire timeline for this record. This cannot be undone.
        `,
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Continue"
      };

      $scope.today = moment().format('YYYY-MM-DD');
      $scope.data = AttendanceData;
      $scope.tmpRecord = AttendanceData.initTmpRecord(recordAndNotes.record);
      // New records won't have an orgID and we need it to save it
      if (!$scope.tmpRecord.orgID) $scope.tmpRecord.orgID = $scope.orgID;
      // Necessary to ensure there is no cached notes
      $scope.tmpRecord.notes = [];
      $scope.notes = recordAndNotes.notes || [];
      // Sort notes from oldest updated to youngest
      if ($scope.notes && $scope.notes.length) {
        $scope.notes.sort(function(a, b) {
          if (moment(a.updated).isAfter(moment(b.updated))) return 1;
          if (moment(a.updated).isBefore(moment(b.updated))) return -1;
          return 0;
        });

        $scope.showNoteInput = $scope.notes.map(() => null);
      }
      $scope.profile = AttendanceData.getProfile($scope.profileId);
      $scope.auditor = recordAndNotes.record.auditor;
      $scope.tab = 'audit';
      $scope.newInputs = false;
      $scope.newNotesInputs = false;
      $scope.disableNewEvent = true;
      $scope.newEvent = {};
      $scope.notesToDelete = [];
      $scope.tab = 'audit';
      $scope.tabs = [
        { label: 'Audit', value: 'audit' },
        { label: 'Notes', value: 'notes' }
      ];
      // Verifies if it's a new record
      if (!recordAndNotes.record.id) {
        $scope.newRecord = true;
        $scope.hideDeleteButton = true;
      }

      if ($scope.auditor && $scope.auditor.length) {
        $scope.auditorsListExists = true;
        $scope.lastAuditor = $scope.auditor[$scope.auditor.length - 1];
        $scope.lastAuditorTime = moment.tz(new Date($scope.lastAuditor.time), timezone).format('MMMM Do, YYYY @ h:mma');
      }

      Object.defineProperty(auditListTab, 'disabled', {
        enumerable: true,
        writeable: false,
        configurable: false,
        get() {
          return !$scope.auditorsListExists;
        }
      });

      // Title will only show up if tab is disabled
      if (auditListTab.disabled) auditListTab.title = 'No Auditor History for this record.';

      $scope.tabs.push(auditListTab);

      $scope.attendanceTypes = types.map((t) => {
        return { label: t, value: t };
      });

      // Set up trusted contacts. Ensure that contactOptions is always an array.
      $scope.contactOptions = ($scope.profile.trustedContacts || []).map((c) => {
        return { label: c.name, value: c.id };
      });
      // If the organization has a universal contact, make that an option too.
      if (ContactData.universalContact) {
        $scope.contactOptions.push({
          label: ContactData.universalContact.name,
          value: ContactData.universalContact.id
        });
      }

      $scope.noTrustedContactMessage = `${$scope.profile.givenName} has no Trusted Contacts.`;
      $scope.providersLoaded = !!OrgProviders.lastUpdated;
      $scope.uiState = {
        expanded: {
          notes: false,
          attendances: false,
        }
      };

      showSpinner().then(() => {
        // If we haven't already loaded providers into the service, do so
        return $scope.providersLoaded
          ? Promise.resolve()
          : OrgProviders.fetchProviders({ includeUsers: true });
      }).then(() => {
        $scope.providersLoaded = true;
        if (!OrgProviders.userSelectOptions.length) OrgProviders.generateSelectOptions('users');
        $scope.providerOptions = OrgProviders.userSelectOptions;

        if (recordAndNotes.record.timeline) $scope.formattedEvents = recordAndNotes.record.timeline.map(formatEventForInput);
        // When we create a new record the first entry must always be a Check In and by checking newRecord we can know if it's new or not
        // Any edit on existing records will use all other types except Check In
        if ($scope.newRecord) {
          $scope.eventTypes = [{ label: 'Check In', value: 'Check In' }];
        }

        $scope.$watch('formattedEvents', function(fresh) {
          // prevents an error swal in Safari when fresh is undefined
          if (!fresh) return;

          // In here we verify if check out exists and if the user creates a new event only allow attendanceTypes
          fresh.forEach(event => {
            if (event.type === 'Check Out') {
              $scope.eventTypes = [...$scope.attendanceTypes];
            } else {
              $scope.eventTypes = [...$scope.attendanceTypes, {label: 'Check Out', value: 'Check Out'}];
            }
          });
        }, true);

        AttendanceData.setUiEntry('buttons.next.action', function() {
          const missingInfo = {
            type: 'warning',
            title: 'Missing Required Info',
            text: 'Please make sure that all timeline events are complete and try again.'
          };
          const validated = $scope.formattedEvents && $scope.formattedEvents.every(validateEvent);
          if (!validated) return swal(missingInfo);

          prepareToSave();
          if ($scope.notesToDelete.length) {
            AttendanceData.deactivateNotes($scope.notesToDelete);
            forceSave = true;
          }
          AttendanceData.manageAuditRecord(forceSave);
          AttendanceData.setUiEntry('buttons.back', originalBackConfig);
          return AttendanceData.ui.buttons.back.action();
        });

        $scope.showSpinner = false;
      }).catch((err) => {
        console.error(err);
        return swal({
          type: 'error',
          title: 'Failed to Fetch Providers',
          text: 'You will be unable to update providers on attendance records. Other updates should be unaffected.'
        });
      });

      function showSpinner() {
        $scope.showSpinner = true;
        return Promise.resolve();
      }

      AttendanceData.setUiEntry('buttons.back', {
        text: 'Cancel',
        action() {
          const goBack = () => {
            AttendanceData.clearTmpRecords();
            AttendanceData.setUiEntry('buttons.back', originalBackConfig);
            return AttendanceData.ui.buttons.back.action();
          };
          const swalWarning = () => {
            return swal({
              title: 'Are you sure?',
              text: 'Any unsaved changes may be lost.',
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Go Back',
              allowOutsideClick: true
            }, (isConfirm) => {
              if (isConfirm) goBack();
            });
          };

          // Check if a new timeline has been created, and the event has not been saved to the store
          const newRecord = !$scope.formattedEvents;
          if (newRecord) {
            return swalWarning();
          }

          // Check if any changes have been made, including note deletion
          const deletingNotes = $scope.notesToDelete.length;
          prepareToSave();
          if (AttendanceData.notifyChangesMade(deletingNotes)) {
            return swalWarning();
          } else {
            return goBack();
          }
        }
      });

      function formatTime(date, time) {
        return moment.tz(`${date} ${time}`, timezone).toISOString();
      };

      $scope.formatUpdatedTime = function(time) {
        return moment.tz(new Date(time), timezone).format('MMMM Do, YYYY');
      };

      function findAndFormatUser(userID) {
        const user = OrgProviders.users.find(user => user.id === userID);
        return user ? `${user.givenName} ${user.familyName} (${user.email})` : '';
      }

      function validateEvent(event) {
        return event.date && event.time && event.type && event.user;
      }

      $scope.deactivateRecord = function() {
        return swal(swalInfo, (isConfirm) => {
          if (isConfirm) {
            AttendanceData.deactivateAuditRecord(recordAndNotes.record)
              .then(() => {
                AttendanceData.setUiEntry('buttons.back', originalBackConfig);
                return AttendanceData.ui.buttons.back.action();
              })
              .catch((err) => {
                console.error(err);
                return swal({
                  type: 'error',
                  title: 'Failed To Delete Record',
                  text: `If the problem persists, please contact our support team and provide them with this record ID: ${recordAndNotes.record.id}`
                });
              });
          }
        });
      };

      $scope.addNewTimelineEvent = function(event) {
        // reset values to have a clear input each time because we declare the variables on top
        $scope.newInputs = false;
        $scope.newEvent = {};
        $scope.disableNewEvent = true;

        // add the new event
        if ($scope.newRecord) {
          $scope.newRecord = false;
          $scope.formattedEvents = [];
          $scope.eventTypes = [...$scope.attendanceTypes, {label: 'Check Out', value: 'Check Out'}];
        }
        $scope.formattedEvents.push(event);
        $scope.formattedEvents.sort(function(a, b){
          return moment(formatTime(a.date, a.time)) > moment(formatTime(b.date, b.time));
        });
      };

      $scope.deleteTimelineEvent = function(index) {
        const event = $scope.formattedEvents.splice(index, 1);

        if (event[0].type === 'Check Out') {
          const keys = ['check_out_time', 'check_out_provider_user_id', 'check_out_trusted_contact_id'];
          keys.forEach(key => $scope.tmpRecord[key] = null);
        }
      };

      // It's in charge of adding and removing the timeline input fields on the record
      $scope.switchToAuditorTab = function() {
        $scope.tab = 'auditors';
      };

      // It's in charge of removing the timeline input fields on the record
      $scope.hideInputs = function() {
        $scope.newInputs = false;
      };

      // Disables button if inputs are not filled out
      $scope.$watch('newEvent', function() {
        $scope.disableNewEvent = !validateEvent($scope.newEvent);
      }, true);

      // Removes save changes button if tab is auditors
      $scope.$watch('tab', function() {
        if ($scope.tab === 'auditors') {
          AttendanceData.setUiEntry('buttons.next.hide', true);
        } else {
          AttendanceData.setUiEntry('buttons.next.hide', false);
        }
      });

      function updateTableColumns(event, recordToSave) {
        const inOrOut = event.type.endsWith('In') ? 'in' : 'out';
        recordToSave[`check_${inOrOut}_provider_user_id`] = event.user;
        recordToSave[`check_${inOrOut}_time`] = formatTime(event.date, event.time);
        recordToSave[`check_${inOrOut}_trusted_contact_id`] = event.trustedContact;
      }

      function demangleTimeline(events, recordToSave) {
        recordToSave.timeline = events.map((mangledEvent) => {
          if (['Check In', 'Check Out'].includes(mangledEvent.type)) {
            updateTableColumns(mangledEvent, recordToSave);
          }
          return {
            time: formatTime(mangledEvent.date, mangledEvent.time),
            type: mangledEvent.type,
            user: findAndFormatUser(parseInt(mangledEvent.user)),
          };
        });
        return recordToSave;
      }

      function prepareToSave() {
        const recordToSave = demangleTimeline($scope.formattedEvents, $scope.tmpRecord);
        recordToSave.auditor.push({
          user: `${$scope.auditUser.givenName} ${$scope.auditUser.familyName} (${$scope.auditUser.email})`,
          time: moment.tz(timezone).toISOString()
        });
      }

      function formatEventForInput({ type, user, time }) {
        time = moment.tz(time, timezone);
        const formatted = {
          type,
          user: (matchUser(user) || {}).id,
          // We'll need these in two separate inputs because we don't have a datetime input
          // We want to be sure we use LOCAL TIME for inputs, so do not ISO string this.
          date: time.format('YYYY-MM-DD'),
          // Time input can parse full dates
          time
        };
        // If it's a check-in/out we'll want the extra info here as well
        if (type === CHECK_IN_TYPE || type === CHECK_OUT_TYPE) {
          const targetField = `check_${type === CHECK_IN_TYPE ? 'in' : 'out'}_trusted_contact_id`;
          formatted.trustedContact = recordAndNotes.record[targetField];
        }
        return formatted;
      }

      function matchUser(timelineUserString) {
        if (!timelineUserString) return;
        return OrgProviders.users.find(user => timelineUserString.includes(user.email));
      }

      // CRUD operations for a Note
      $scope.createNewNote = function() {
        $scope.tmpNote = AttendanceData.initTmpNote({ profileID: $scope.profileId });
        // Close all inputs if one was open when clicking new note
        $scope.showNoteInput = ($scope.notes || []).map(() => null);
        $scope.newNotesInputs = !$scope.newNotesInputs;
      };

      $scope.addNewNoteToTmpRecord = function() {
        if ($scope.tmpNote && $scope.tmpNote.body !== '') {
          $scope.tmpNote.providerID = $scope.auditUser.providerID;
          $scope.tmpNote.userID = $scope.auditUser.id;
          // tmpID: used to keep track of recently created notes to assist on editing and deletion
          // with no duplicates. It won't be saved on the BE and notes save have an id making it easy
          // To identify
          $scope.tmpNote.tmpID = $scope.notes.length;
          // This is for FE purposes and will get replaced on save on the BE
          $scope.tmpNote.updated = moment.tz(new Date(), timezone).toISOString();
          $scope.tmpRecord.notes.push($scope.tmpNote);
          $scope.notes.push($scope.tmpNote);
          $scope.newNotesInputs = false;
        }
      };

      $scope.closeNewNote = function() {
        $scope.newNotesInputs = false;
      };

      $scope.addNoteToTmpRecord = function(index) {
        const duplicate = checkDuplicateNote(index);
        if ($scope.tmpNote && $scope.tmpNote.body !== '' && !duplicate) {
          // Disallow edits on new or existing notes to create a new note
          const recent = recentCreatedNote(index);
          if (recent) {
            $scope.tmpRecord.notes = $scope.tmpRecord.notes.filter(existing => existing.tmpID !== $scope.notes[index].tmpID);
          } else {
            $scope.tmpRecord.notes = $scope.tmpRecord.notes.filter(existing => existing.id === $scope.notes[index].id);
          }
          $scope.tmpRecord.notes.push($scope.tmpNote);
          $scope.notes[index] = $scope.tmpNote;
          // This is for FE purposes and will get replaced on save on the BE
          $scope.notes[index].updated = moment.tz(new Date(), timezone).toISOString();
        }
        $scope.showNoteInput[index] = null;
      };

      $scope.editNote = function(index) {
        $scope.tmpNote = AttendanceData.initTmpNote($scope.notes[index]);

        // Close new inputs if you want to edit a note already existing.
        $scope.newNotesInputs = false;

        // Makes sure we can only open one input at a time
        if ($scope.showNoteInput.includes(true) && $scope.showNoteInput[index]) {
          $scope.showNoteInput = $scope.notes.map(() => null);
        } else if ($scope.showNoteInput.includes(true) && !$scope.showNoteInput[index]) {
          $scope.showNoteInput = $scope.notes.map(() => null);
          $scope.showNoteInput[index] = true;
        } else {
          $scope.showNoteInput[index] = true;
        }
      };

      $scope.deleteNote = function(index) {
        const recent = recentCreatedNote(index);
        if (!recent) {
          $scope.notesToDelete.push($scope.notes[index]);
        } else {
          $scope.tmpRecord.notes = $scope.tmpRecord.notes.filter(existing => existing.tmpID !== $scope.notes[index].tmpID);
        }
        $scope.notes.splice(index, 1);
      };

      function recentCreatedNote(index) {
        return $scope.tmpRecord.notes.find(existing => existing.tmpID === $scope.notes[index].tmpID);
      }

      function checkDuplicateNote(index) {
        return $scope.tmpNote.body === $scope.notes[index].body && $scope.tmpNote.priority === $scope.notes[index].priority;
      }
    }
  };
});
