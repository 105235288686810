angular.module('dn').filter('permissionVisible', ($rootScope) => ((allowedPermissions) => {

  if (!allowedPermissions || !Object.keys(allowedPermissions).length) return true;
  if ($rootScope.operator) return true;
  // early return if provider has access to patient profile
  if ($rootScope.hasPatientAccess) return true;
  if (!$rootScope.roleData) return false;


  return Object.entries(allowedPermissions).some(([permissionName, permissionValue]) => {
    const profilePermissionValue = $rootScope.roleData.permissions[permissionName];
    if (permissionValue === 'view') {
      return (profilePermissionValue === 'view') || (profilePermissionValue === 'edit');
    } else {
      return profilePermissionValue === permissionValue;
    }
  });
}));
