angular.module('dn').directive('manageRoles', function() {
  return {
    templateUrl: 'directives/permissions/manage-roles.directive.html',
    restrict: 'E',
    scope: {
      organization: '='
    },
    controller: function($filter, $http, $scope, $state, $rootScope, flash, GroupBranding) {
      fetchRoles();

      $scope.noneViewEdit = [
        { label: 'None', value: null },
        { label: 'View', value: 'view' },
        { label: 'Edit', value: 'edit' }
      ];

      $scope.noneView = [
        { label: 'None', value: null },
        { label: 'View', value: 'view' }
      ];

      $scope.yesNo = [
        { label: 'No', value: null },
        { label: 'Yes', value: 'allow' }
      ];

      $scope.$watch('roleID', function(val) {
        if (val) {
          $scope.role = _.find($scope.organization.roles, { 'id': parseInt(val) }) || newRole();
        }
      });

      $scope.questionnaireName = GroupBranding.aliases.healthProfile;

      $scope.funkNotesContent = $scope.organization.properties.enableFunkNotes
        ? 'Users will be able to view, download, and print CampGrams.'
        : 'CampGrams are not enabled for this organization. To find out more about this feature, contact your account manager.';

      $scope.protPlanContent = _.has($scope.organization.properties, 'features.travelInsurance')
        ? 'Users will be able to view protection plans purchased by your participants.'
        : 'Protection plans are not enabled for this organization. To find out more about this feature, contact your account manager.';

      $scope.smsContent = $scope.organization.properties.smsEnabled
        ? 'Users will be able to purchase tokens for sending text message alerts, and will be able to send bulk text messages to other users.'
        : 'Text messaging is not enabled for this organization. To find out more about this feature, contact your account manager.';

      if ((($scope.organization.properties.features || {}).attendance || {}).enabled) {
        $scope.attendanceTakingContent = 'Users with VIEW access will be able to view and report on attendance records. Users with EDIT access will be able to create and update attendance records.';
        $scope.attendanceAuditingContent = 'Users will be able to retroactively edit or create attendance records.';
        $scope.attendanceSettingContent = 'Users with VIEW access will be able to view settings for the attendance feature. Users with EDIT access will be able to modify those settings.';
      } else {
        $scope.attendanceTakingContent
        = $scope.attendanceAuditingContent
        = $scope.attendanceSettingContent
        = 'Attendance is not enabled for this organization. To find out more about this feature, contact your account manager.';
      }

      $scope.prescreeningFeatureFlag = $rootScope.featureFlags.prescreening;
      $scope.prescreeningContent = _.get($scope, 'organization.properties.features.prescreening.enabled')
        ? 'Users with VIEW access will be able to report, filter, and see stats for Prescreening entries. Users with EDIT access will be able to customize the Prescreening Template.'
        : 'Prescreening is not enabled for this organization. To find out more about this feature, contact your account manager.';

      $scope.settingsQuestionnaireContent = `Users with VIEW access will be able to view the ${$scope.questionnaireName} structure and settings for your organization.`;

      $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission);

      // Public
      $scope.deleteRole = function(role) {
        if (role.id) {
          window.swal({
            title: 'Confirm',
            text: 'Are you sure you want to delete the ' + role.name + ' role?',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false
          }, function(confirmed) {
            if (confirmed) {
              /* eslint-disable no-unused-vars */
              $http.delete('/api/roles/' + role.id).then(function(result) {
                $scope.roleID = 'new';
                flash('Role deleted.');
                fetchRoles();
              }, function(err) {
                console.log(err);
              });
            }
          });
        }
      };

      $scope.saveRole = function(role) {
        new Promise(function(resolve, reject) {
          if (role.id) {
            $http.put('/api/roles/' + role.id, role).then(function(result) {
              flash('Successfully updated role ' + result.data.name);
              resolve();
            }, function(err) {
              reject(err);
            });
          } else {
            $http.post(`/api/organizations/${ $scope.organization.id }/roles/`, role).then(function(result) {
              $scope.roleID = result.data.id;
              flash('Successfully created role ' + result.data.name);
              resolve();
            }, function(err) {
              reject(err);
            });
          }
        }).then(function() { return fetchRoles(); }).catch(function(err) {
          if (err.data === 'Role already exists') {
            flash('Role ' + role.name + ' already exists');
            return;
          }
          /* Can't throw here without an outer promise to catch it, else nothing happens. Leaving it here for now because we MIGHT want to do that :shrug:
            throw err
          */
          flash('Error saving role');
        });
      };

      $scope.cancelEdit = function() {
        $scope.role = null;
      };

      // Private
      function newRole() {
        return {
          'name': '',
          'groupID': $scope.organization.id,
          permissions: {
            'setup_org': null,
            'create_profiles': null,
            'manage_providers': null,
            'send_messages': null,
            'merge_profiles': null,
            'access_funknotes': null,
            'access_power_tools': null,
            'view_news_feed': null,
            'upload_data_imports': null,
            'import_profiles': null,
            'view_protection_plans': null,
            'reports': null,
            'send_sms': null,
            'medical_supplies': 'allow',

            'roster_registration_setup': null,
            'financial_ledgers': 'view',
            'emar': null,
            'health_log': null,
            'behavioral_health': null,
            'attendance_taking': null,
            'attendance_auditing': null,
            'prescreening': null,

            'settings_basics': 'view',
            'settings_notifications': 'view',
            'settings_profiles': 'view',
            'settings_health_log': 'view',
            'settings_medications': 'view',
            'settings_tags': 'view',
            'settings_registrations': 'view',
            'settings_billing': 'view',
            'settings_questionnaire': 'view',
            'settings_emergency_plan': 'view',
            'settings_branding': 'view',
            'settings_attendance': null,
            'settings_security': 'view',
            'settings_analytics': 'view',

            'profile_demographics': 'view',
            'profile_reviews': null,
            'profile_notes': null,
            'profile_registrations': 'view',
            'profile_tags': null,
            'profile_account': null,
            'profile_users': null,
            'profile_notifications': null,
            'profile_questionnaire': null,
            'profile_medications': null,
            'profile_encrypted_answers': null
          }
        };
      }

      /*
      Takes in a role object directly from the API results
      iterates over all available permissions keys via newRole()
      and assigns a `null` value to the processed role for any missing keys
      */
      function defaultMissingKeys(role) {
        if (!role.permissions.hasOwnProperty('settings_security')) {
          role.permissions['settings_security'] = 'view';
        }
        Object.keys(newRole().permissions).forEach((permKey) => {
          if (!role.permissions[permKey]) role.permissions[permKey] = null;
        });
        return role;
      }

      function fixBillingEditPermissions(role) {
        /*
          If billing permissions have been set to "edit",
          show them as "view" in the app and save them as such when a user saves the role.
        */
        if (role.permissions['settings_billing'] === 'edit') {
          role.permissions['settings_billing'] = 'view';
        }
        return role;
      }

      function fetchRoles() {
        $http.get(`/api/organizations/${ $scope.organization.id }/roles/`).then(function(result) {
          $scope.organization.roles = (result.data || [])
            .map(defaultMissingKeys)
            .map(fixBillingEditPermissions);

          $scope.orgRoles = _.map($scope.organization.roles, function(role) {
            return { label: role.name, value: role.id };
          });
          $scope.orgRoles.unshift({ label: 'New Role', value: 'new' });
          if ($scope.roleID) {
            $scope.role = _.find($scope.organization.roles, { 'id': parseInt($scope.roleID) }) || newRole();
          } else {
            $scope.roleID = 'new';
          }

        }, function(err) {
          window.swal({
            type: 'error',
            title: 'Error',
            text: 'There was an error retrieving role information. Please try again.',
            confirmButtonText: 'Reload',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            allowOutsideClick: false,
            allowEscapeKey: false
          }, function(confirmed) {
            if (confirmed) {
              return $state.reload();
            } else {
              return $state.go('manager.profiles');
            }
          });
        });
      }
    }
  };
});
