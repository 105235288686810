angular.module('dn').directive "paymentMethod", ->
  templateUrl: 'directives/payment-method/payment-method.directive.html'
  replace: false
  restrict: 'E'
  scope:
    model: '='
    hideBilling: '=hidebilling'
    allowPaper: '&'
    noCard: '@nocard'
    noBank: '@nobank'
    acceptedPayments: '@acceptedpayments'
    hidePermission: '@hidepermission'
    noRestrict: "="
    showOrgMerchantName: "="

  controller: ($rootScope, $scope, $timeout, PayProcessor, WhatDivision) ->
    if $scope.showOrgMerchantName
      $scope.merchantName = window.lib.merchantName($rootScope.organization.orgPayProcessors);
    else
      $scope.merchantName = window.dnim.constants.billing.DN_MERCHANT_NAME

    # conditionally gated to prevent errs when no org is expected to be defined (e.g.,user-settings,
    # which is org agnostic) OR when we don't want to restrict for other reasons (sms tokens, funk
    # tokens, etc.)
    if $rootScope.organization and !$scope.noRestrict
      # Used in messaging
      $scope.orgName = $rootScope.organization.name
      # We now allow orgs to restrict which CC types they accept (via their AM)
      $scope.acceptedCards = $rootScope.organization.properties?.billing?.acceptedCards
    else
      # default name: not used in db or JS, but only html
      $scope.orgName = WhatDivision.name.split('.')[0]

    # Default to "all"
    $scope.acceptedCards ?= window.dnim.constants.billing.ACCEPTED_CARDS
    # Maybe one day we'll have org specific bank types?
    $scope.acceptedBanks = window.dnim.constants.billing.ACCEPTED_BANKS
    # Tell the users which cards the org accepts
    $scope.cardList = $scope.acceptedCards.reduce (cardString, cardName, i) ->
      joiner = switch
        when i == 0 then ''
        when i == $scope.acceptedCards.length - 1 then ', and '
        else ', '
      cardString += "#{joiner}#{cardName}"
    , ''

    # Show ACH agreement for pay processors that require one
    $scope.showAchAgreement = $scope.showOrgMerchantName && PayProcessor.features.showAchAgreement
    if $scope.showAchAgreement
      merchantReplaceRegex = new RegExp($scope.merchantName, "g")
      $scope.agreementText = window.dnim.touchnet
        .achAgreement($scope.merchantName)
        .replace(merchantReplaceRegex, "<strong>#{$scope.merchantName}</strong>")

    $scope.$watch "model", (fresh, stale) ->
      return if (!fresh or fresh is stale)
      $scope.paymentMethod = fresh
      $scope.paymentMethod.orgID = $rootScope.organization?.id

    $scope.$watch "paymentMethod", ((fresh, stale) ->
      if (fresh.category is "paper" || validateMethod fresh) and checkOrgAuth fresh
        $scope.model = fresh
      else
        $scope.model = null
    ), true

    if $scope.acceptedPayments is 'creditCard' then $scope.noBank = true
    if $scope.acceptedPayments is 'ACH' then $scope.noCard = true

    $scope.paymentMethod = $scope.paymentMethod or {}

    baseMethod =
      FirstName: ''
      LastName: ''
      Street1: if $scope.hideBilling then 'blank' else ''
      State: if $scope.hideBilling then 'blank' else ''
      City: if $scope.hideBilling then 'blank' else ''
      Zip: if $scope.hideBilling then 'blank' else ''

    baseCard =
      CreditCardNumber: ''
      ExpirationDate: ''
      CVCCode: ''
      # Assume we accept the method until we know otherwise
      typeAccepted: true

    baseBank =
      RoutingNumber: ''
      RoutingNumberDup: ''
      BankAccountNumber: ''
      BankAccountNumberDup: ''
      BankAccountType: 'PC'
      # Assume we accept the method until we know otherwise
      typeAccepted: true

    $scope.card = Object.assign({}, baseMethod, baseCard)
    $scope.bank = Object.assign({}, baseMethod, baseBank)

    $scope.bankAccountTypes = [
      {label: 'Personal Checking', value: 'PC'}
      {label: 'Personal Savings', value: 'PS'}
      {label: 'Business Checking', value: 'BC'}
      {label: 'Business Savings', value: 'BS'}
    ]

    $scope.businessTypes = ['BC', 'BS']

    $scope.methodCategories = [
      {label: 'Credit Card', value: 'card', icon: 'credit-card', hide: $scope.noCard}
      {label: 'Bank Account', value: 'bank', icon: 'university', hide: $scope.noBank}
      {label: 'Paper Check', value: 'paper', icon: 'envelope', hide: !$scope.allowPaper()}
    ]

    #Create a year interval based on the current year
    startYear = parseInt(moment(new Date()).format("YY"), 10)
    $scope.yearRange  = _.map [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (v) ->
      year = "20" + (startYear + v).toString()
      { label: year, value: year}

    #Populate the month dropdown
    $scope.monthRange = [
            { value: '01', label: 'Jan' },
            { value: '02', label: 'Feb' },
            { value: '03', label: 'Mar' },
            { value: '04', label: 'Apr' },
            { value: '05', label: 'May' },
            { value: '06', label: 'Jun' },
            { value: '07', label: 'Jul' },
            { value: '08', label: 'Aug' },
            { value: '09', label: 'Sep' },
            { value: '10', label: 'Oct' },
            { value: '11', label: 'Nov' },
            { value: '12', label: 'Dec' }
          ]

    $scope.expirationDate = { month: undefined , year: undefined }

    $scope.$watchCollection "expirationDate", (date) ->
      if date.month and date.year
        #The logic below is to support ALL IE versions(incl. 11).
        #Other ways of doing it break the user's ability to pay with a credit card.
        #Date.getYear is deprecated, use getFullYear instead
        currentYear = new Date().getFullYear()
        #Date.getMonth starts its index at 0 because reasons
        currentMonth = new Date().getMonth() + 1
        #avoid those nasty string comparisons
        if +currentYear < +date.year || (+currentYear == +date.year and +currentMonth < +date.month)
          #convert year to last two digits for iCheck
          dateStr = date.month + "/" + (date.year[2].toString() + date.year[3].toString())
          $scope.card.ExpirationDate = dateStr
        else
          $scope.card.ExpirationDate = null

    $scope.$watch "allowPaper", (allow) ->
      $scope.methodCategories[2].show = allow() is true
      if allow() then $scope.bank.BankAccountType = 'BC'

    $scope.paymentMethod.category = if $scope.noCard then 'bank' else 'card'

    $scope.cardType = (number) ->
      if $scope.paymentMethod.category isnt "card" then return null
      type = "Card"
      if !number then return type
      _.each {
        Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MasterCard: /^5[1-5][0-9]{14}$/,
        Amex: /^3[47][0-9]{13}$/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/
      }, (pattern, key) ->
        if number.match pattern then type = key
      type

    validateMethod = (method) ->
      return false unless _.every method.tokenizer
      if $scope.businessTypes.includes method.account_type
        return false if !method.tokenizer.CompanyName
      if method.category is 'bank'
        $scope.acceptedBanks.includes method.type
      else
        $scope.acceptedCards.includes method.type


    checkOrgAuth = (method) ->
      return true if $scope.hidePermission or _.isBoolean method.properties.providerPermission

    $scope.bankType = (bank) ->
      return null if $scope.paymentMethod.category isnt "bank"
      if bank.BankAccountType.slice(-1) is "C" then "Checking" else "Savings"

    setLastFour = (number) ->
      $scope.paymentMethod.lastfour = number.slice(-4)

    updateCard = (cardNumber) ->
      $scope.paymentMethod.type = $scope.cardType(cardNumber)
      # If we've a type, provide immediate feedback if it's not accepted
      $scope.card.typeAccepted = $scope.acceptedCards.includes $scope.paymentMethod.type
      setLastFour(cardNumber)

    updateBank = (bank) ->
      $scope.paymentMethod.type = $scope.bankType(bank)
      $scope.paymentMethod.account_type = bank.BankAccountType
      if $scope.businessTypes.includes(bank.BankAccountType)
        $scope.paymentMethod.company_name = bank.CompanyName
      else delete $scope.paymentMethod.company_name
      if bank.BankAccountNumber
        setLastFour(bank.BankAccountNumber)

    $scope.$watch "card.CreditCardNumber", (number) ->
      return if $scope.paymentMethod.category is "bank"
      if !number
        # If the user clears the input, stop telling them we don't accept their card
        return $scope.paymentMethod.type = null
      updateCard(number)

    $scope.$watch "bank", ((bank) ->
      return if $scope.paymentMethod.category is "card"
      updateBank(bank)
    ), true

    $scope.dups = {'RoutingNumber': undefined, 'BankAccountNumber': undefined}
    $scope.checkFields = ->
      if $scope.bank.RoutingNumber

        if $scope.bank.RoutingNumber is $scope.dups.RoutingNumber
          $scope.bank.RoutingNumberDup = $scope.dups.RoutingNumber
          $scope.routingMismatch = false

        if $scope.bank.RoutingNumber isnt $scope.dups.RoutingNumber
          $scope.bank.RoutingNumberDup = ''
          $scope.routingMismatch = true

      if $scope.bank.BankAccountNumber

        if $scope.bank.BankAccountNumber is $scope.dups.BankAccountNumber
          $scope.bank.BankAccountNumberDup = $scope.dups.BankAccountNumber
          $scope.accountMismatch = false

        if $scope.bank.BankAccountNumber isnt $scope.dups.BankAccountNumber
          $scope.bank.BankAccountNumberDup = ''
          $scope.accountMismatch = true

    $scope.$watch 'paymentMethod.category', (category, stale) ->
      return if category is stale
      return $scope.paymentMethod.tokenizer = null if category == 'paper'
      # Pick out just the address and name fields to persist across categories
      baseAttrs = _.pick $scope.paymentMethod.tokenizer, Object.keys(baseMethod)
      $scope.paymentMethod.tokenizer = Object.assign($scope[category], baseAttrs)
      if category is 'bank'
        updateBank($scope.bank)
      if category is 'card'
        updateCard($scope.card.CreditCardNumber)

    $scope.paymentMethod.properties ?= {}
    $scope.permissionChoices = [
      { label: "Yes", value: true }
      { label: "No", value: false }
    ]

    # Verify if the payment processor allows saved payment methods
    $scope.savedPaymentMethods = PayProcessor.features.savedPaymentMethods

    # If the payment processor does not allow saved payment methods we programatically
    # turn the switch input to false to bypass it
    if (!$scope.savedPaymentMethods)
      $scope.paymentMethod.properties.providerPermission = false

    # $scope.address = {}
    $scope.$watch 'address', (address, stale) ->
      return if address is stale
      # Set address fields to "" so they fail paymentMethod watcher validation
      if (!address)
        $scope[$scope.paymentMethod.category].Street1 = ""
        $scope[$scope.paymentMethod.category].City = ""
        $scope[$scope.paymentMethod.category].State = ""
        $scope[$scope.paymentMethod.category].Zip = ""
      # If there IS an address, use it!
      else
        $scope[$scope.paymentMethod.category].Street1 = _.deburr(address.addr1).replace(/[^a-zA-Z\d\s:]/, '')
        $scope[$scope.paymentMethod.category].City = _.deburr(address.city).replace(/[^a-zA-Z\d\s:]/, '') or "blank"
        $scope[$scope.paymentMethod.category].State = _.deburr(address.state).replace(/[^a-zA-Z\d\s:]/, '') or _.deburr(address.country).replace(/[^a-zA-Z\d\s:]/, '') or "blank"
        $scope[$scope.paymentMethod.category].Zip = _.deburr(address.zip).replace(/[^a-zA-Z\d\s:]/, '') or "blank"

        # make sure the string lengths are within iCheck's specs
        $scope[$scope.paymentMethod.category].Street1 = ($scope[$scope.paymentMethod.category].Street1).substring(0, 58)
        $scope[$scope.paymentMethod.category].City = ($scope[$scope.paymentMethod.category].City).substring(0, 28)
        $scope[$scope.paymentMethod.category].State = ($scope[$scope.paymentMethod.category].State).substring(0, 23)

        # extra parens to appease the gods
        if (_.size ($scope[$scope.paymentMethod.category].Zip)) < 5 then $scope[$scope.paymentMethod.category].Zip = "blank"
        if (_.size ($scope[$scope.paymentMethod.category].Zip)) > 10 then $scope[$scope.paymentMethod.category].Zip = "blank"

      # Update the payment method with the changed address info
      $scope.paymentMethod.tokenizer = $scope[$scope.paymentMethod.category]
    return
