lib.registerState("oper.transactions", {
  url: "/transactions",
  templateUrl: "states/oper/transactions/transactions.state.html",
  controller($scope, $state) {

    $scope.searchConf = () => {
      if (!$scope.confirmation || ($scope.confirmation.length !== 12)) { return; }
      return $state.go("oper.transactions.transaction", {confirmation: $scope.confirmation}, {reload: true});
    };

  }
});
