lib.registerState("smsEmailAlreadyOptedIn", {
  url: "/sms-email-already-opted-in",
  templateUrl: "states/sms-email-already-opted-in/sms-email-already-opted-in.state.html",
  controller: function($scope, $http, WhatDivision) {
    $scope.division = WhatDivision.lcName;

    // manual DOM manipulation to remove topbar icons
    let icons = document.getElementsByClassName('icons')[0];
    icons.parentNode.removeChild(icons);

  }
});
