Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "Event", ($http) ->

  Event = new Resource
    route: "/api/profiles/:profileID/events/:id"

  translators =
    "crebit.credit":
      icon: "dollar-sign"
      title: "Account Credited"
      text: (event) -> """
        #{event.metadata.crebit.description} ($#{
          Math.abs(event.metadata.crebit.amount)/100
        })
        was added as a line item.
      """

    "crebit.payment":
      icon: "dollar-sign"
      title: "Payment Made"
      text: (event) -> """
        #{event.metadata.crebit.description} ($#{
          Math.abs(event.metadata.crebit.amount)/100
        })
        was made.
      """

    "crebit.debit":
      icon: "dollar-sign"
      title: "Account Debited"
      text: (event) -> """
        #{event.metadata.crebit.description} ($#{
          (event.metadata.crebit.amount)/100
        })
        was added as a line item.
      """

    "crebit.refund":
      icon: "dollar-sign"
      title: "Refund Given"
      text: (event) -> """
      """

    "registration.new":
      icon: "paste"
      title: "Registration Created"
      text: (event) -> if !event.metadata?.group?.name then "" else """
        Registered to #{event.metadata.group.name}.
      """

    "registration.waitlisted":
      icon: "clock"
      iconPrefix: "far"
      title: "Added to Waitlist"
      text: (event) -> """
        Added to the waitlist for #{event.metadata.group.name}.
      """

    "registration.cancellationRequested":
      icon: "calendar-minus"
      iconPrefix: "far"
      title: "Registration Cancellation Requested"
      text: (event) -> """
        A registration cancellation has been requested for #{event.metadata.group.name}.
      """

    "registration.cancellationWithdrawn":
      icon: "calendar-times"
      iconPrefix: "far"
      title: "Registration Cancellation Withdrawn"
      text: (event) -> """
        A registration cancellation for #{event.metadata.group.name} has been withdrawn.
      """

    "registration.cancellationDenied":
      icon: "ban"
      title: "Registration Cancellation Denied"
      text: (event) -> """
        A registration cancellation has been denied for #{event.metadata.group.name}.
      """

    "registration.cancellationAccepted":
      icon: "calendar-check"
      iconPrefix: "far"
      title: "Registration Cancellation Accepted"
      text: (event) -> """
        A registration cancellation has been accepted, and the registration for #{event.metadata.group.name} has been deactivated.
      """

    "demographics.changeRequest":
      icon: "exchange-alt"
      title: (event) ->
        template = "Demographic Change Request"
        if event.profile?.properties?.changeRequested
          template += ' (Pending)'
        return template
      text: (event) -> """
        A demographic change was requested.
      """

    "demographics.changeMade":
      icon: "check"
      title: "Demographic Change Made"
      text: (event) -> """
        The requested demographic change was accepted.
      """

    "demographics.changeDenied":
      icon: "times"
      title: "Demographic Change Denied"
      text: (event) -> """
        The requested demographic change was denied.
      """

    "healthForm.extensionRequested":
      icon: "unlock-alt"
      title: "Extension Requested"
      text: (event) -> "Health Form Extension Requested"

    "healthForm.completed":
      icon: "check-circle"
      iconPrefix: "far"
      title: "Profile Completed"
      text: (event) -> "Health Form has been completed"

    "emailBounce":
      icon: "envelope"
      title: "Email Bounced"
      text: (event) ->
        "An email bounced for #{event.metadata.email}."

    "eMar.lowMedCount":
      icon: "medkit"
      title: "Low Medication Count"
      text: (event) ->
        "Medication: #{event.metadata.medName}, Remaining: #{event.metadata.medCount}"

    "healthLog.followUp":
      icon: "stethoscope"
      title: "Health Log Follow-Up"
      text: (event) ->
        "A Health Log Follow-Up is scheduled for today for: #{event.metadata.entry.complaint}"

    "healthForm.extensionGiven":
      icon: "unlock-alt"
      title: "Health Profile Extension Granted"
      text: (event) -> "An extension has been granted until #{ moment(event.metadata.date).format('MMM DD, YYYY') }"

    "profile.stepUnlock":
      icon: "clock"
      iconPrefix: "far"
      title: "Health Profile Step Extension Requested"
      text: (event) ->
        "#{ event.metadata.patientName } has requested an extension for their #{ event.metadata.step } step."

    "profile.mergeRequested":
      icon: "code-branch fa-rotate-270"
      title: "Profile Merge Requested"
      text: (event) ->
        "Merge requested for duplicate profile"

    "profile.cancelMergeRequest":
      icon: "code-branch fa-rotate-90"
      title: "Profile Merge Request Withdrawn"
      text: (event) ->
        "The merge request has been withdrawn"

  Event.translate = (event, translation) ->
    return ":-/" unless translators[event.type]?[translation]?
    return translators[event.type][translation](event) if (typeof translators[event.type][translation] is 'function')
    return translators[event.type][translation]

  Event.translators = translators

  Event
