angular.module("dn").directive("rosterGroup", function() {
  return {
    templateUrl: "directives/roster-group/roster-group.directive.html",
    restrict: "E",
    scope: {
      group: "=",
      org: "=",
      shared: "=",
      sharedRoster: "="
    },

    controller: function($scope, $state) {

      /* $scope.group is a single object from an array generated in roster.state. Changes made here will reflect back to the state's object and modify it in place */

      $scope.group.rosterType = "registration";

      /* Sets up displayAddons and displayCoupons for the group */
      function assignCrebitData(group, registrations, addsOrCoups) {
        var property = addsOrCoups === 'displayAddOns' ? 'addOns' : 'coupons';

        group[addsOrCoups] = {};


        _.map(group[property], function(c) {
          var conformedDescription = $scope.conformCrebitDescription(c.description);

          if (!group[addsOrCoups][conformedDescription]) {
            group[addsOrCoups][conformedDescription] = {
              occupancy: {
                female: 0,
                male: 0,
                total: 0
              },
              profiles: [],
              capacity: c.capacity
            };
          }

          var profileMatch = _.find(registrations, function(r) { return r.profileID === c.profileID });
          if (!profileMatch) return;

          if (profileMatch.profile.sex === 'Female') group[addsOrCoups][conformedDescription].occupancy.female += 1;
          if (profileMatch.profile.sex === 'Male') group[addsOrCoups][conformedDescription].occupancy.male += 1;
          group[addsOrCoups][conformedDescription].occupancy.total += c.quantity;

          $scope.group[addsOrCoups][conformedDescription].profiles.push({
            id: profileMatch.profile.id,
            givenName: profileMatch.profile.givenName,
            familyName: profileMatch.profile.familyName,
            middleName: profileMatch.profile.middleName,
            dob: profileMatch.profile.dob,
            sex: profileMatch.profile.sex,
            regID: profileMatch.id,
            quantity: c.quantity
          });
          profileMatch = null;
        });

      }

      /* Returns the data that populates the group's table display */
      $scope.filterRegistrations = function(rosterType) {
        if (rosterType === "registration") {
          $scope.filteredRegistrations = _($scope.group.registrations).filter((reg) => !reg.waitlisted).sortBy('profile.familyName').value();

        } else if (rosterType === "waitlist") {
          $scope.filteredRegistrations = _($scope.group.registrations).filter((reg) => reg.waitlisted)
            .map((reg) => {
              let presentTimezone = ($scope.org.properties && $scope.org.properties.timezone);
              reg.updated = presentTimezone ? moment.tz(reg.updated + '+00', $scope.org.properties.timezone) : moment.utc(reg.updated + '+00');
              return reg;
            })
            .sort((a, b) => a.updated - b.updated)
            .map((reg) => {
              reg.updated = reg.updated.format('M/DD/YYYY h:mm A')
              return reg;
            })
            .value()

        } else if (rosterType === "addOn") {
          if (!$scope.group.displayAddOns) assignCrebitData($scope.group, $scope.group.registrations, "displayAddOns");
          $scope.filteredRegistrations = $scope.group.displayAddOns;

        } else if (rosterType === "coupon") {
          if (!$scope.group.displayCoupons) assignCrebitData($scope.group, $scope.group.registrations, "displayCoupons");
          $scope.filteredRegistrations =  $scope.group.displayCoupons;

        }
      }
      /* It seems you can't call IIFE functions from the HTML, otherwise this would be IIFE'd */
      $scope.filterRegistrations($scope.group.rosterType);

      $scope.$watch("group.rosterType", function(fresh, stale) {
        if (fresh === stale) return;
        $scope.filterRegistrations(fresh);
      });

      /* Because of the way I set up the display tables in one div, doing inline ui-srefs would not work for all roster types. Reg and waitlist rosters use this, while addOn and coupon use ui-sref */
      $scope.changeState = function(regObj) {
        if ($scope.group.rosterType !== 'registration' && $scope.group.rosterType !== 'waitlist') return;

        return $state.go("manager.profiles.profile.registrations.registration", { profileID: regObj.profile.id, registrationID: regObj.id });
      }

      $scope.conformCrebitDescription = function(description) {
        if (typeof description !== "string") return;
        return description.replace(/\s*→?\s*(\[ADD-ON\]|\[COUPON\])\s*/, '').trim();
      }

      /* Creates a CSV for this group for whichever roster is currently displayed */
      $scope.exportRoster = function() {
        var csvString = "";

        if ($scope.shared) {
          var condHeader = $scope.sharedRoster === "shared-add" ? "addOn" : "coupon";
          csvString += "Profile ID,Last Name,First Name,Date of Birth,Sex,Group," + condHeader + "\n";
          _.map($scope.sharedItems, function(item, key) {
            _.map(item.crebits, function(c) {
              csvString += `${c.profile.id},${c.profile.familyName},${c.profile.givenName},${c.profile.dob},${c.profile.sex},"${$scope.group.name}","${$scope.conformCrebitDescription(key)}"\n`;
            });
          });
        } else {
          var registration = $scope.group.rosterType === 'registration';
          var waitlist = $scope.group.rosterType === 'waitlist';
          var addOn = $scope.group.rosterType === 'addOn';
          var coupon = $scope.group.rosterType === 'coupon';


          if (registration || waitlist) {
            csvString += "Profile ID,Last Name,First Name,Date of Birth,Sex,Group,Registered On\n";
            _.map($scope.group.registrations, function(reg) {
              if (registration && reg.waitlisted) return;
              if (waitlist && !reg.waitlisted) return;
              csvString += `${reg.profile.id},${reg.profile.familyName},${reg.profile.givenName},${reg.profile.dob},${reg.profile.sex},"${$scope.group.name}",${reg.created}\n`;
            });
          } else if (addOn || coupon) {
            csvString += "Profile ID,Last Name,First Name,Date of Birth,Sex,Group," + $scope.group.rosterType + "\n";
            if (addOn) {
              _.map($scope.group.displayAddOns, function(a, key) {
                _.map(a.profiles, function(p) {
                  csvString += `${p.id},${p.familyName},${p.givenName},${p.dob},${p.sex},"${$scope.group.name}","${$scope.conformCrebitDescription(key)}"\n`;
                });
              });
            } else if (coupon) {
              _.map($scope.group.displayCoupons, function(c, key) {
                _.map(c.profiles, function(p) {
                  csvString += `${p.id},${p.familyName},${p.givenName},${p.dob},${p.sex},"${$scope.group.name}","${$scope.conformCrebitDescription(key)}"\n`;
                });
              });
            }
          }
        }

        let csvData = new Blob([csvString], {type: "text/csv;charset=utf-8;"});
        let filename = $scope.group.name + "_" + ($scope.shared ? "shared-" + condHeader : $scope.group.rosterType) + "_roster.csv";

        if (window.navigator.msSaveOrOpenBlob) {
          // Blob support for edge
          window.navigator.msSaveOrOpenBlob(csvData, filename);
        } else {
          let csvURL = window.URL.createObjectURL(csvData);
          let a = document.createElement('a');
          // firefox works better with an attached anchor tag
          document.getElementById("content").appendChild(a);
          a.href = csvURL;
          a.setAttribute("download", filename);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(csvURL);
          a = csvURL = null;
        }
        csvData = csvString = null;
      }

      if ($scope.shared) {
        $scope.sharedItems = {};
        _.map($scope.group.crebits, function(crebit) {
          var conformedDescription = $scope.conformCrebitDescription(crebit.description);
          if (!$scope.sharedItems[conformedDescription]) $scope.sharedItems[conformedDescription] = { crebits: [], occupancy: { female: 0, male: 0, total: 0 }, capacity: crebit.capacity };
          $scope.sharedItems[conformedDescription].crebits.push({ profile: crebit.profile, quantity: crebit.quantity });

          if (crebit.profile.sex === "Female") $scope.sharedItems[conformedDescription].occupancy.female += 1;
          if (crebit.profile.sex === "Male") $scope.sharedItems[conformedDescription].occupancy.male += 1;
          $scope.sharedItems[conformedDescription].occupancy.total += crebit.quantity;
        });
      }

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
