angular.module("dn").directive("paginateList", function() {
  return {
    templateUrl: "directives/paginate-list/paginate-list.directive.html",
    restrict: "E",
    scope: {
      data: "=",
      limit: "=",
      header: "@"
    },

    controller: function($scope, $state) {
      if (!$scope.limit) { $scope.limit = 5; }

      $scope.currentPage = 0;
      $scope.$watch('limit', () => {
        /* Dummy array for use in paginate-navigate directive */
        $scope.pages = _.times(Math.ceil($scope.data.length / $scope.limit), Number);
        if ($scope.data.length <= $scope.limit) $scope.currentPage = 0
      })


    } /* end controller */
  }; /* end return statement */
}); /* end directive */
