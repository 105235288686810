angular.module("dn").directive "qbLockout", ->
  templateUrl: "directives/builder/lockout/qb-lockout.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbLockout"
  controller: ($scope) ->

    $scope.question.metadata ?= {}
    $scope.informationLockout = !!$scope.question.metadata.lockout

    $scope.isModule = ->
      _.includes [
        "allergies"
        "dimensions"
        "emergencyContacts"
        "immunizations"
        "insurances"
        "medications"
        "primaryProviders"
      ], $scope.question.type

    $scope.$watch "informationLockout", (fresh, stale) ->
      return if fresh is stale
      return delete $scope.question.metadata.lockout unless fresh
      $scope.question.metadata.lockout = new Date().toISOString().slice 0, 10 if fresh
