lib.registerState "manager.settings.general",
  url: "/general"
  templateUrl: "states/manager/settings/general/general.state.html"
  resolve:
    title: ($rootScope, organization) ->
      $rootScope._title = "#{organization.name} / General Settings"
  controller: ($scope, $state, $filter, group, organization, session) ->

    if session.access.operator
      $scope.tabs = [
        { label: "Basics", sref: "manager.settings.general.basics", oper: true, permissions: {"settings_basics": "view"} }
        { label: "Profiles", sref: "manager.settings.general.profiles", permissions: { "settings_profiles": "view" } }
        { label: "Notifications", sref: "manager.settings.general.notifications", permissions: { "settings_notifications": "view" } }
        { label: "Registration", sref: "manager.settings.general.registration" }
        { label: "Health Log", sref: "manager.settings.general.healthLog", permissions: { "settings_health_log": "view" } }
        { label: "Medications", sref: "manager.settings.general.medications", permissions: {"settings_medications": "view"} }
        { label: "Tags", sref: "manager.settings.general.tags", permissions: {"settings_tags": "view"} }
        { label: "Emergency Plan", sref: "manager.settings.general.emergency", permissions: { "settings_emergency_plan": 'view' } }
        { label: "Attendance", sref: "manager.settings.general.attendance", oper:true}
        { label: "Subgroups", sref: "manager.settings.general.subgroups", oper: true }
        { label: "Manage", sref: "manager.settings.general.manage", oper: true }
      ]
      if $state.current.name is "manager.settings.general" then $state.go ".basics"
    else
      $scope.tabs = [
        { label: "Profiles", sref: "manager.settings.general.profiles" , permissions: { "settings_profiles": "view" } }
        { label: "Notifications", sref: "manager.settings.general.notifications", permissions: { "settings_notifications": "view" } }
        { label: "Registration", sref: "manager.settings.general.registration", permissions: { "settings_registrations": "view" }, hide: !organization.shortName }
        { label: "Health Log", sref: "manager.settings.general.healthLog", permissions: { "settings_health_log": "view" } }
        { label: "Medications", sref: "manager.settings.general.medications", permissions: {"settings_medications": "view"} }
        { label: "Tags", sref: "manager.settings.general.tags", permissions: {"settings_tags": "view"} }
        { label: "Emergency Plan", sref: "manager.settings.general.emergency", permissions: { "settings_emergency_plan": 'view' } }
        { label: "Attendance", sref: "manager.settings.general.attendance", hide: !group.properties.features?.attendance?.enabled, permissions: { "settings_attendance": "view" } }
      ]
      settingsJump = -> $scope.tabs.filter((tab) -> $filter('permissionVisible')(tab.permissions))[0]?.sref or ".registration"
      if $state.current.name is "manager.settings.general" then $state.go settingsJump()
