'use strict';

angular.module('dn').directive('defaultGlCodeCheckbox', function() {
  return {
    restrict: 'A',
    templateUrl: 'directives/default-gl-code-checkbox/default-gl-code-checkbox.directive.html',
    scope: {
      glCodeId: '=',
      glCode: '=',
      resourceType: '=',
      defaultGlCodes: '=',
      setGlCodeToDefault: '=',
      undoGlCodeToDefault: '=',
      disabled: '=',
    },
    controller: function($scope) {
      $scope.useDefaultGLCode = !$scope.glCodeId || !$scope.glCode;

      $scope.$watch('useDefaultGLCode', (fresh) => {
        if (fresh) $scope.setGlCodeToDefault($scope.defaultGlCodes[`${$scope.resourceType}_gl_code`]);
        if (!fresh) $scope.undoGlCodeToDefault();
      });
    },
  };
});
