angular.module("dn").directive "qbAllergies", ->
  templateUrl: "directives/builder/modules/allergies/qb-allergies.directive.html"
  replace: true
  restrict: "A"
  scope:
    module: "=qbAllergies"
    mode: "@"
  controller: ($scope) ->
    $scope.module.metadata ?= {}
    $scope.module.label ?= "Allergies"
    $scope.module.required ?= true
