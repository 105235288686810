lib.registerState('home.account.billing', {
  url: '/billing',
  templateUrl: 'states/home/account/billing/billing.state.html',
  resolve: {
    title: ($rootScope) => $rootScope._title = 'User Settings / Billing',
    // We don't want to and can't restrict payment methods here because
    // users can belong to multiple orgs so we can't apply org restrictions
    // re: external merchant processing accounts
    paymentMethods: (session, $http) => {
      return $http.get(`/api/users/${session.userID}/payment-methods`).then((result) => {
        return _.map(result.data, (method) => {
          method.label = method.type + ' -' + method.lastfour;
          method.value = method.id;
          return method;
        });
      });
    }
  },
  controller: ($scope, $state, $http, session, paymentMethods, WhatDivision) => {
    $scope.division = WhatDivision;
    $scope.paymentMethods = _.sortBy(paymentMethods, 'created');

    // only confuse our users if they have a reason to be confused
    // and attach merchant name when an org processor is in use
    paymentMethods.forEach((pm) => {
      if (pm.tokens && pm.tokens.length > 1) {
        $scope.showMerchantName = true;
        pm.displayMerchantName = pm.tokens.find((t) => t.org_id !== 21436).merchant_name;
      } else pm.displayMerchantName = 'DocNetwork, Inc.';
    });

    $scope.saveMethod = () => {
      if (!$scope.newPaymentMethod) return;
      $scope.saving = true;
      $http.post(`/api/users/${$scope.user.id}/payment-methods`, $scope.newPaymentMethod).success((result) => {
        $scope.paymentMethods.push(result);
        $scope.paymentMethods = _.map($scope.paymentMethods, (method) => {
          method.label = `${method.type} -${method.lastfour}`;
          method.value = method.id;
          return method;
        });
        $scope.saving = false;
        $scope.newMethod = false;;
        flash('Payment Method Added');
        $state.reload('home.account');
      }).error((result) => {
        $scope.saving = false;
        // explicitly handle cases where iCheck is not responding
        if (new RegExp('ENOTFOUND').test(result)) {
          title = 'Payment Processor Error';
          text = 'No response from payment processor. Please wait and try again in a few minutes.';
        } else if (result === 'Invalid userID') {
          title = 'Couldn\'t Save Payment Method';
          text = `
            We could not save your payment method.
            Please ensure you have no outstanding invites in your ${WhatDivision.patient.plural.capitalize()} tab and try again.
          `;
        } else {
          title = 'Couldn\'t Save Payment Method';
          text = 'Please check your payment method credentials and try again.';
        }
        window.swal({
          title: title,
          text: text,
          type: 'warning',
          showCancelButton: false,
          closeOnConfirm: true
        });
      });
    };

    $scope.deactivate = (paymentMethod) => {
      return $http.delete(`/api/users/${session.userID}/payment-methods/${paymentMethod.id}`).then(() => {
        flash('Payment Method Deactivated');
        $state.reload('home.account');
      });
    };

    $scope.modifyPermission = (paymentMethod, action) => {
      if (!paymentMethod && !action) return;
      paymentMethod.properties.providerPermission = action === 'enable';
      const url = `/api/users/${$scope.user.id}/payment-methods/${paymentMethod.id}`;
      $http.put(url, paymentMethod).then((result) => {
        if (result.status === 400) {
          window.swal({
            title: 'Error',
            type: 'error',
            text: `
              There was an issue updating your payment method:
              ${result.data}
            `
          });
        } else {
          flash('Payment Method Updated');
        }
      });
    };

    $scope.confirmDelete = (method) => {
      window.swal({
        title: 'Delete Payment Method?',
        type: 'warning',
        text: `Are you sure you want to delete ${method.label}?`,
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }, (confirm) => {
        if (!confirm) return confirm;
        $scope.deactivate(method);
      });
    };
  }
});
