angular.module('dn').directive('adjustmentsDisplay', function() {
  return {
    templateUrl: 'directives/profile-registrations/adjustments-display/adjustments-display.directive.html',
    restrict: 'E',
    scope: {
      editing: '=',
      registration: '=',
      profile: '=',
      requesting: '='
    },
    controller: function($scope, ProfileRegData) {
      const BASE_DESC_REGEX = /\[[A-Z-]+\] (.+)/;

      $scope.buttonTitle = function(crebit) {
        const matchedBase = crebit.description.match(BASE_DESC_REGEX);
        // Take only the group from the captured match, not the entire match (if we have one)
        const simpleDesc = matchedBase && matchedBase[1] ? matchedBase[1].trim() : null;
        // Always have a Plan B
        return `Remove ${simpleDesc || 'line item from registration'}`;
      };

      $scope.removeAdjustment = function(crebit) {
        const isGLCsP2FlagOn = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');

        if (isGLCsP2FlagOn) {
          // If the crebit being removed isn't an add-on, then we can just
          //   remove it
          if (!crebit.description.startsWith('[ADD-ON]')) {
            actuallyRemoveAdjustment(crebit);
            return;
          }

          // See if the add-on being removed has a non-voided coupon
          //   attributing to it
          const couponIDs = $scope.registration.crebits
            .filter(crebit => crebit.description.startsWith('[COUPON]'))
            .filter(crebit => crebit.addOnQuantity > 0)
            .map(crebit => crebit.id);
          const couponAttrs = crebit.attributions
            .filter(attr => couponIDs.includes(attr.crebitID));
          const hasCouponAttr = !!couponAttrs.length;

          // If the add-on being removed doesn't have a coupon attributing to
          //   it, then we can just remove it
          if (!hasCouponAttr) {
            actuallyRemoveAdjustment(crebit);
            return;
          }

          // Otherwise, show a confirmation modal
          const singularText = `
            Are you sure you want to delete this add-on? The associated
            coupon will also be removed as it exceeds the balance of
            this session. To adjust the balance of the session, you may need
            to apply a new coupon or custom adjustment.
          `;
          const pluralText = `
            Are you sure you want to delete this add-on? The associated
            coupons will also be removed as they exceed the balance of
            this session. To adjust the balance of the session, you may need
            to apply a new coupon or custom adjustment.
          `;
          const swalText = couponAttrs.length > 1 ? pluralText : singularText;

          swal({
            title: 'Delete Add-On?',
            type: 'warning',
            text: swalText,
            html: true,
            confirmButtonText: 'Delete Add-On',
            showCancelButton: true,
          }, (confirm) => {
            if (!confirm) return;
            actuallyRemoveAdjustment(crebit);
          });
        } else {
          actuallyRemoveAdjustment(crebit);
        }
      };

      const actuallyRemoveAdjustment = (crebit) => {
        $scope.requesting = true;
        ProfileRegData.removeAdjustment($scope.profile, crebit)
          .then(() => {
            crebit.wasJustRemoved = true;
            flash('Line item removed');
          }).catch(() => flash('Error removing line item'))
          .finally(() => $scope.requesting = false);
      };
    }
  };
});
