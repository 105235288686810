angular.module('dn').directive('iScroll', function () {
  return {
    restrict: 'A',
    scope: {
      scrollFunc: '=',
      max: '='
    },
    link: function (scope, element, attrs) {

      scope.list = {
        rendered: ()=>element[0].scrollHeight,
        scrolled: ()=>element[0].scrollTop + element[0].clientHeight
      };

      const throttledScrollFunc = _.debounce(scope.scrollFunc, 300);

      scope.$watch('list.scrolled()', (scrolled) => {
        if (scope.list.scrolled() >= scope.list.rendered()) return;
        let percent = Math.round((scope.list.scrolled() / scope.list.rendered()) * 100);
        if (percent > 80 && !scope.max) {
          throttledScrollFunc();
        }
      });
    }
  };
});
