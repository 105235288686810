lib.registerState 'patient.profile.registration',
  url: '/registration'
  templateUrl: 'states/patient/profile/registration/registration.state.html'
  resolve:
    title: ($rootScope, profile) ->
      $rootScope._title = "#{profile.givenName} #{profile.familyName} / Registration"
    alias: (organization) ->
      customAlias = organization.properties?.portals?.patient?.sections?.registration?.alias
      customAlias or "Registration"

  controller: ($sanitize, $scope, $state, $http, alias, campgramsOnly, profile, registrations, session, gaTagIDs, organization) ->
    return $state.go 'patient.profile.funkNotes' if campgramsOnly
    # apparently you don't need to call the $sanitize service anywhere, just include it in the controller and it does its thing?
    $scope.alias = alias

    $scope.profile = profile

    # templates for each registration to be plugged into the paginate-list directive
    # clickEvent will tell the directive what to do when the element is clicked

    patientRegistrations = registrations.filter((r) -> r.type is "patient")

    $scope.registrations = _(patientRegistrations).map (registration) ->
      registration.paginateConfig =
        template: do ->
          parentName = if registration.group.parent then "<span class='muted'>#{registration.group.parent.name} »</span>" else ""
          waitlisted = if registration.waitlisted then "<span>On Waitlist</span>" else ""
          """
            <div class="table-cell">
              #{parentName}
              <strong>#{registration.group.name}</strong>
            </div>
            <div class="table-cell right danger past-table">
              #{waitlisted}
            </div>
            <div class="table-cell right muted">
              #{moment(registration.group.properties.start).format('MMM D, YYYY')} -
              #{moment(registration.group.properties.finish).format('MMM D, YYYY')}
              <i aria-hidden="true" class="fas fa-fw fa-external-link-alt" title="Go to registration"></i>
            </div>
          """
        clickEvent: () ->
          return $state.go("patient.profile.registration.details", { registrationID: registration.id })
      return registration
    .sortBy (reg) ->
      return reg.group.properties.start
    .value()

    $scope.pastRegistrations = _.filter $scope.registrations, (registration) ->
      registration.group.phase == 'past' and !registration.deactivated
    $scope.presentRegistrations = _.filter $scope.registrations, (registration) ->
      registration.group.phase == 'present' and !registration.deactivated
    $scope.futureRegistrations = _.filter $scope.registrations, (registration) ->
      registration.group.phase == 'future' and !registration.deactivated

    if ($scope.profile.infoMissing or $scope.profile.photoMissing) and !session.skipDemographics
      return $state.go('patient.profile.demographics')

    # State variables for the registration code modal
    $scope.priorityRegFeatureFlag = window.lib.featureFlagClient.isEnabled('registrationCodes')
    $scope.priorityRegModal = { code: "", displayModal: false }
    $scope.directToRegister = ''
    $scope.codeFailed = false
    $scope.orgHasCode = organization.properties?.earlyRegistrationEnabled and organization.properties?.earlyRegistrationCodeSet

    # Displays the early registration modal and clear the code
    $scope.openRegModal = () ->
      $scope.priorityRegModal.displayModal = true
      $scope.priorityRegModal.code = ""
      $scope.codeFailed = false

    # Compare the code entered by the user to the organization's registration code
    $scope.compareCodes = () ->
      if $scope.priorityRegFeatureFlag
        $http.post("/api/organizations/#{organization.id}/registrationCodes/validate", { code: $scope.priorityRegModal.code }).then (response) ->
          $scope.priorityRegModal.displayModal = false
          $scope.codeFailed = false

          # Direct the user to the register page if the code is correct
          $state.go(".register", { regCode: $scope.priorityRegModal.code })
          $scope.priorityRegModal.code = ""
        .catch (error) ->
          $scope.priorityRegModal.code = ""
          $scope.codeFailed = true
