lib.registerState('manager.groups.registration.setup.addOn', {
  url: '/add-ons/:addOnID',
  templateUrl: 'states/manager/groups/registration/setup/add-ons/inspect-add-on.state.html',
  resolve: {
    addOn: ($stateParams, AddOn) => {
      if ($stateParams.addOnID === 'new') {
        return new AddOn({
          groupID: parseInt($stateParams.groupID),
          properties: {}
        });
      }
    },
    defaultGlCodes: ($http, $state, organization) => {
      return $http.get(`/api/group/${organization.id}/gl-codes/defaults`).then(
        function (success) { return success.data; },
        function () {
          window.swal({
            title: 'Page Error',
            type: 'error',
            text: 'There was an error loading default GL Code data.  \n\n Please reload and try again. If this error continues to occur, please contact our support team with the contents of this message and the URL of this page.',
            confirmButtonText: 'Reload'
          }, () => $state.reload());
        }
      );
    }
  },
  controller: ($scope, $state, $stateParams, $filter, AddOn, flash, addOn, defaultGlCodes, group) => {
    $scope.defaultGlCodes = defaultGlCodes;

    $scope.group = group;
    $scope.group.addOns = $scope.group.addOns || [];

    // Either a new addOn from resolve, or an existing addOn on the group
    $scope.addOn = addOn || $scope.group.addOns.find(a => a.id === +$stateParams.addOnID);

    if (!$scope.addOn || !$scope.group) return;

    $scope.addOn = new AddOn($scope.addOn);

    // capacity checkbox (check for null/undefined values using loose equality)
    // eslint-disable-next-line eqeqeq
    if ($scope.addOn.capacity != null) {
      $scope.addOn.hasCapacity = true;
    }

    // expiration checkbox
    $scope.addOn.hasExpires = !!$scope.addOn.expires;

    // multi choice options checkbox
    $scope.addOn.options = $scope.addOn.options || $scope.group.addOns.filter(a => a.parentID === $scope.addOn.id);
    $scope.addOn.options = _.orderBy($scope.addOn.options, ['deactivated', 'properties.displayOrder'], ['desc', 'asc']);
    $scope.addOn.hasOptions = !!$scope.addOn.options.length;

    // shared checkbox
    $scope.addOn.specifyGroup = (+$scope.addOn.groupID !== +$scope.group.id);

    // new addOn
    $scope.newAddOn = ($stateParams.addOnID === 'new');

    // permissions to edit addons
    $scope.allowEdit = $filter('permissionVisible')({ roster_registration_setup: 'edit' });

    // checkbox watchers
    $scope.$watch('addOn.hasExpires', (has) => {
      if (!has) $scope.addOn.expires = null;
    });

    // remove capacity if multiple choice
    $scope.$watch('addOn.hasOptions', (has) => {
      if (has) {
        $scope.addOn.capacity = null;
        $scope.addOn.hasCapacity = false;
      }
    });

    // initialize capacity
    $scope.$watch('addOn.hasCapacity', (has) => {
      if (!has) {
        $scope.addOn.capacity = null;
      }
    });

    $scope.addOn.usingDefaultGLCode = !$scope.addOn.gl_code;
    if ($scope.addOn.hasOptions) {
      $scope.addOn.options.forEach((option) => option.usingDefaultGLCode = !option.gl_code);
    }

    $scope.$watch('addOn.specifyGroup', (specifies) => {
      specifies ? $scope.addOn.hasExpires = false : $scope.addOn.groupID = $scope.group.id;
    });

    $scope.$watch('addOn.properties.dueAtDeposit', (fresh, stale) => {
      if (fresh === stale) return;
      if (!$scope.addOn.options || !$scope.addOn.options.length) return;
      $scope.addOn.options.map(o => o.properties.dueAtDeposit = fresh);
    });

    function fixDisplayOrder() {
      $scope.addOn.options = _.orderBy($scope.addOn.options, ['deactivated', 'properties.displayOrder'], ['desc', 'asc']);
      $scope.addOn.options.map((option, index) => {
        option.properties.displayOrder = (option.deactivated ? null : index);
      });
    }

    fixDisplayOrder();

    $scope.moveUp = (option) => {
      const index = option.properties.displayOrder;
      if (index === 0) return;
      if ($scope.addOn.options[index - 1].deactivated) return;
      $scope.addOn.options[index - 1].properties.displayOrder++;
      $scope.addOn.options[index].properties.displayOrder--;
      fixDisplayOrder();
      flash(`Moved ${option.name} up`);
    };

    $scope.moveDown = (option) => {
      const index = option.properties.displayOrder;
      if (index === ($scope.addOn.options.length - 1)) return;
      if ($scope.addOn.options[index + 1].deactivated) return;
      $scope.addOn.options[index + 1].properties.displayOrder--;
      $scope.addOn.options[index].properties.displayOrder++;
      fixDisplayOrder();
      flash(`Moved ${option.name} down`);
    };

    $scope.newOption = () => {
      $scope.addOn.options.push({
        properties: {
          displayOrder: $scope.addOn.options.length,
          dueAtDeposit: $scope.addOn.properties.dueAtDeposit
        },
        usingDefaultGLCode: $scope.addOn.usingDefaultGLCode,
        gl_code: $scope.addOn.gl_code
      });
    };

    $scope.deactivate = () => {
      $scope.addOn.destroy().then(() => {
        flash('Add-On Deactivated');
        return $state.go('^', undefined, { reload: true });
      });
    };

    $scope.reactivate = () => {
      $scope.addOn.deactivated = null;
      $scope.addOn.save().then(() => {
        flash('Add-On Reactivated');
        return $state.go('^', undefined, { reload: true });
      });
    };

    $scope.deactivateOption = (option) => {
      if (!option.id) {
        _.remove($scope.addOn.options, o => option.$$hashKey === o.$$hashKey);
        flash('Option Removed');
      } else {
        option.deactivated = new Date().toISOString();
        flash(`${option.name} Deactivated`);
      }
      fixDisplayOrder();
    };

    $scope.reactivateOption = (option) => {
      option.deactivated = null;
      flash(`${option.name} Reactivated`);
      fixDisplayOrder();
    };

    generateGroupChoices = () => {
      const parents = [ $scope.group ];
      const addParent = (group) => {
        if (!group.parent) return;
        parents.push(group.parent);
        return addParent(group.parent);
      };
      addParent($scope.group);
      return parents.map(g => { return { label: g.name, value: g.id }; });
    };

    $scope.groupChoices = generateGroupChoices();

    $scope.disableSave = (addOn) => {
      const missingName           = !addOn.name; // eslint-disable-next-line eqeqeq
      const missingPrice          = addOn.price == null && !addOn.hasOptions; // loose equality for null & undef
      const missingOptions        = addOn.hasOptions && !addOn.options.length;
      const missingOptionName     = addOn.hasOptions && !addOn.options.every(o => o.name); // eslint-disable-next-line eqeqeq
      const missingOptionPrice    = addOn.hasOptions && addOn.options.some(o => o.price == null); // loose equality for null & undef
      const allOptionsDeactivated = addOn.hasOptions && addOn.options.every(o => o.deactivated);
      const missingGLCode         = !addOn.hasOptions && !addOn.usingDefaultGLCode && !addOn.gl_code;
      const missingOptionGLCode   = addOn.hasOptions && addOn.options.some(o => !o.usingDefaultGLCode && !o.gl_code);

      return _.some([
        missingName,
        missingPrice,
        missingOptions,
        missingOptionName,
        missingOptionPrice,
        allOptionsDeactivated,
        missingGLCode,
        missingOptionGLCode,
      ]);
    };

    $scope.save = () => {
      // convert empty string capacities to null
      if ($scope.addOn.capacity === '') $scope.addOn.capacity = null;
      if ($scope.addOn.hasOptions) {
        $scope.addOn.options.map(option => { if (option.capacity === '') option.capacity = null; });
        $scope.addOn.gl_code_id = null;
      }
      if ($scope.addOn.usingDefaultGLCode) {
        $scope.addOn.gl_code = null;
      }
      $scope.addOn.options.forEach((option) => {
        if (option.usingDefaultGLCode) option.gl_code = null;
      });
      $scope.addOn.save().then(() => {
        flash('The add-on has been saved');
        return $state.go('^', undefined, { reload: true });
      });
    };
  }
});
