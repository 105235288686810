lib.registerState('manager.import.profile-import', {
  url: '/profiles',
  templateUrl: 'states/manager/import/profiles/profile-import.state.html',
  resolve: {
    title: function($rootScope) { return $rootScope._title = 'Profile Import'; },
  },
  controller: function(WhatDivision, $scope, $state, $http, organization, session) {
    // If Profile Import isn't open to the provider, send to profile search.
    const profileImportEnabled = organization.properties && organization.properties.enableProfileImport;
    $scope.showProfileImport = profileImportEnabled || session.access.operator;
    if (!$scope.showProfileImport) {
      return $state.go('manager.profiles');
    }

    // Lookin' for CampDoc or SchoolDoc. Closest is `.name` which includes `.com` at the end
    $scope.divisionName = (organization.division || {}).name || WhatDivision.name.slice(0, -4);

    $scope.importPhases = {
      upload: { running: false, complete: false },
      process: { running: false, complete: false },
      final: { running: false, complete: false }
    };

    $scope.syncTypes = [
      {label: 'Normal', value: 'normal'},
      {label: 'Soft Sync', value: 'soft'},
      {label: 'Hard Sync', value: 'hard'},
    ];
    $scope.syncChoice = 'normal';

    $scope.templateConfig = {
      groups: true,
      tags: true,
      immunizations: true
    };

    $scope.templateURL = function () {
      const queryString = Object.entries($scope.templateConfig).map(([option, selected]) => {
        return `${option}=${selected || false}`;
      }).join('&');

      return `/api/organizations/${$scope.organization.id}/import-template?${queryString}`;
    };

    $scope.acceptedTerms = false;
    $scope.isOper = session.access.operator;

    $scope.yesNo = [
      { label: 'No', value: false },
      { label: 'Yes', value: true }
    ];

    $scope.uploadComplete = function(response) {
      if (response.err) {
        const errText = response.err === 'File type must be CSV, XLS, or XLSX'
          ? response.err
          : 'There was an unexpected error while uploading your form. Please reload and try again.';
        $scope.importPhases.upload.running = false;
        window.swal({
          type: 'error',
          title: 'Upload Error',
          text: errText,
          confirmButtonText: 'Reload',
          allowOutsideClick: false,
          allowEscapeKey: false
        }, function() {
          return $state.reload();
        });
      } else {
        $scope.importPhases.upload = { running: false, complete: true };
        return processRows(response.file);
      }
    };

    let processObj = null;
    function processRows(rows) {
      $scope.importPhases.process.running = true;
      $http.post(`/api/organizations/${$scope.organization.id}/import/process/profiles`,
        { file: rows },
      ).then((success) => {
        $scope.importPhases.process = { running: false, complete: true };
        $scope.importPreview = success.data.preview;
        $scope.hasImportRows = $scope.importPreview.matchedProfiles.concat($scope.importPreview.newProfiles).length;
        processObj = success.data.processObj;
      }).catch((err) => {
        err = _.get(err, 'data.message', err.data || err);
        window.swal({
          type: 'error',
          title: 'Preview Error',
          text: 'An error occurred while building the preview. ' + err,
          allowEscapeKey: false,
          confirmButtonText: 'Reload'
        }, function(isConfirm) {
          if (isConfirm) { return $state.reload(); }
        });
      });
    }

    //This lets opers skip this section
    const defaultValue = $scope.isOper || false;

    $scope.terms = [
      {
        text: 'I have been made aware of the format requirements when importing profile data.',
        value: true
      },
      {
        text: 'I have reviewed the names, emails, and registrations, as well as all other data to be imported in the preview above for accuracy.',
        value: defaultValue
      },
      {
        text: 'I understand that if I do not format the data I am importing properly, this may lead to import errors.',
        value: defaultValue
      },
      {
        text: 'I understand that data import errors may include, but are not limited to, name and email mismatch errors in which users may have access to profiles which do not belong to them.',
        value: defaultValue
      },
      {
        text: `I will not hold ${$scope.divisionName} responsible for any import errors that may occur as a result of any data I import.`,
        value: defaultValue
      },
      {
        text: `I understand that this data import is final and cannot be undone, and that I may be charged additional fees to have a ${$scope.divisionName} team member help me fix any errors.`,
        value: defaultValue
      },
      {
        text: 'I understand as an alternative to importing this data myself, I may use the Data Upload option to have a trained data specialist complete the import for me.',
        value: defaultValue
      }
    ];

    $scope.confirmationSwal = function() {
      window.swal({
        type: 'warning',
        title: 'Import Profiles',
        text: confirmSwalText(),
        allowEscapeKey: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        html: true
      }, function(isConfirm) {
        if (isConfirm) runImport();
      });
    };

    function confirmSwalText() {
      const hasConflicts = $scope.importPreview.conflicts.length;
      return `This import will
      ${profileText('matchedProfiles')}${hasConflicts ? ',' : ' and'}
      ${profileText('newProfiles')}${hasConflicts ? `, ${profileText('conflicts')}` : '.'}
      This cannot be undone. Do you want to continue?`;
    }

    function profileText(arrayKey) {
      const numProfiles = $scope.importPreview[arrayKey].length;
      const profileOrProfiles = numProfiles === 1 ? 'profile' : 'profiles';
      /* eslint-disable indent */ //eslint thinks the cases should line up with the switch. I disagree.
      switch (arrayKey) {
        case 'matchedProfiles':
          return `update ${numProfiles} ${profileOrProfiles}`;
        case 'newProfiles':
          return `add ${numProfiles} new ${profileOrProfiles}`;
        case 'conflicts':
          return `and exclude ${numProfiles} conflict ${profileOrProfiles}.`;
      }
    }

    function setProcessPingInterval() {
      const importProcessID = window.dnim.constants.processTypes.import;
      let requestInProgress;

      const checkoInterval = setInterval(() => {
        if (requestInProgress) return;
        requestInProgress = true;
        $http.get(`/api/organizations/${$scope.organization.id}/processes/${importProcessID}/${processObj.identifier}`)
          .then((result) => {
            // if result is undefined, we timed out
            if (!result) return Promise.reject(new Error('checko timeout'));
            return result;
          })
          .then(({data}) => {
            data = data[0];
            if (!data) return Promise.reject(new Error('missing checko data'));
            $scope.status = data.status;
            if (data.complete) {
              clearInterval(checkoInterval);
              importDone();
            } else if (data.failed) {
              clearInterval(checkoInterval);
              importError(data);
            }
            requestInProgress = false;
          })
          .catch((err) => {
            if (err.message === 'checko timeout') {
              // if the process check timed out, keep trying until we get something
              // TODO: add handling to do _something_ if we're unable to retrieve a status after several minutes
              clearInterval(checkoInterval);
              setProcessPingInterval();
              return;
            } else if (err.status === 404 || err.message === 'missing checko data') {
              /*
              if we're not getting any data back, restarting the interval likely won't solve anything
              so don't do anything here right now and let it fall back to `unexpected`
              but maybe in future there'll be a need for handling in this block

              replica lag is technically possible and would benefit from a retry, but it's highly unlikely
              */
            }
            throw new Error('An unexepcted error occurred while retrieving import status.');
          });
      }, 3000);
    }

    // This function runs the import. It's called from the `confirmationSwal`'s callback.
    function runImport() {
      $scope.importPhases.final.running = true;
      const allRows = $scope.importPreview.matchedProfiles.concat($scope.importPreview.newProfiles);

      setProcessPingInterval();

      $http.post(`/api/organizations/${$scope.organization.id}/import/finalize/profiles`, {
        rows: allRows,
        syncChoice: $scope.syncChoice,
        processObj,
      })
      .catch((err) => {
        importError(err.data || err);
      });
    };

    function importDone() {
      $scope.importSuccess = true;
      $scope.importPhases.final = { running: false, complete: true };
      return window.swal({
        type: 'success',
        title: 'Import complete',
        text: 'Import is complete. Click below to go to the list builder.',
        allowOutsideClick: true,
        showCancelButton: true,
        confirmButtonText: 'List Builder',
        cancelButtonText: 'New Import'
      }, function(isConfirm) {
        if (isConfirm) {
          return $state.go('manager.profiles');
        } else {
          return $state.reload();
        }
      });
    }

    function importError({status, process_data}) {
      if (status === 'Running Sync') {
        window.swal({
          type: 'warning',
          title: 'Sync Error',
          text: 'An error occurred while running the sync operation.',
          allowEscapeKey: false,
          confirmButtonText: 'Confirm'
        }, function(isConfirm) {
          if (isConfirm) { return $state.reload(); }
        });
      } else {
        window.swal({
          type: 'warning',
          title: 'Import Error',
          text: 'An error occurred while importing data. Some data may not have been processed.\n\nError:\n' + process_data.importErrors.join('\n').substring(0, 100),
          allowEscapeKey: false,
          confirmButtonText: 'Reload'
        }, function(isConfirm) {
          if (isConfirm) { return $state.reload(); }
        });
      }
    }

    $scope.getFiles = function() {
      $scope.gettingFiles = true;
      if (!$scope.fileKeys) {
        $http.get(`/api/organizations/${$scope.organization.id}/imports`).then((success) => {
            $scope.fileKeys = _.orderBy(success.data, 'LastModified', 'desc') || [];
        }).catch(() => {
            return swal('Failed to Load History', 'An error occurred while loading upload history. Try again later.', 'error');
        }).finally(() => {
          $scope.gettingFiles = false;
          $scope.expandFiles = true;
        });
      } else {
        $scope.expandFiles = true;
        $scope.gettingFiles = false;
      }
    };

    $scope.$watch('submitting', function(fresh, stale) {
      if (fresh === stale) return;
      if (fresh && !stale) $scope.importPhases.upload.running = true;
    });

    $scope.exportPreview = function(category) {
      let csvString = 'Profile ID,Identifier,First Name,Middle Name,Last Name,Date of Birth,Sex,Conflicts\n';
      $scope.importPreview[category].map((row) => {
        // If these cells don't have values return empty instead of null, undefined or []
        const conflicts = row.conflicts.length === 0 ? '' : JSON.stringify(row.conflicts);

        csvString += `"${row.id || ''}","${row.identifier || ''}","${row.givenName || ''}","${row.middleName || ''}","${row.familyName || ''}","${row.dob || ''}", "${row.sex || ''}","${conflicts}"\n`;
      });
      const filename = `import-${category}`;

      generateCSV(csvString, filename);
    };

    $scope.exportGroupStructure = function() {
      // Variable to assign groups keyed by id to their chains between levels
      const groupChains = {};
      // Array of final group objects to push finished chains when we reach the leaf nodes
      const csvGroups = [];

      // Between each level of iteration, create a header indicating the depth in the tree as we go
      const levelHeaders = [];
      // Track depth into the tree for building out the columns for group levels
      let currentLevel = 1;

      for (let groups = $scope.organization.children; groups.length; groups = _.flatten(groups.map(({ children }) => children))) {
        // The header for the given level to serve as a key for the group name at a given level
        const levelHeader = `L${currentLevel}`;
        // Set up a variable to track if any new groups have been pushed in for the currentLevel
        // This is used to filter out any empty columns containing past leaf groups
        const initialGroupCountForLevel = csvGroups.length;
        // We don't want non-leaf nodes in the export, but we'll need to map them to the right columns
        let parentNode = false;
        groups.forEach((group) => {
          const row = { [levelHeader]: group.name };
          if (groupChains[group.parentID]) {
            groupChains[group.id] = Object.assign(row, groupChains[group.parentID]);
          } else {
            groupChains[group.id] = row;
          }
          if (group.children.length) {
            parentNode = true;
          } else if (group.phase !== 'past') {
            csvGroups.push(Object.assign(groupChains[group.id], { start: group.properties.start, finish: group.properties.finish }));
          }
        });
        if (csvGroups.length > initialGroupCountForLevel || parentNode) {
          levelHeaders.push(levelHeader);
        }
        currentLevel++;
      }

      // Date property strings for mapping dates from headers to cells
      const DATES = ['start', 'finish'];
      // Initialize headers for csv
      let csvString = `${levelHeaders.join(',')},${DATES.map(date => date.capitalize()).join(',')}\n`;

      csvString += csvGroups.reduce((csvString, group) => {
        levelHeaders.forEach((level) => {
          csvString += `"${group[level] || ''}",`;
        });
        csvString += `${DATES.map(date => `"${group[date] || ''}"`).join(',')},`;
        return `${csvString}\n`;
      }, '');
      const filename = `${$scope.organization.name}_Group_Structure`;

      generateCSV(csvString, filename);
    };

    // It would be nice to eventually have a general window.lib function for this
    // see https://github.com/docnetwork/app/issues/3155
    function generateCSV(csvString, filename) {
      filename = `${filename}.csv`;
      let csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      if (window.navigator.msSaveOrOpenBlob) {
        // Blob support for edge
        window.navigator.msSaveOrOpenBlob(csvData, filename);
      } else {
        let csvURL = window.URL.createObjectURL(csvData);
        let a = document.createElement('a');
        // firefox works better with an attached anchor tag
        document.getElementById('content').appendChild(a);
        a.href = csvURL;
        a.setAttribute('download', filename);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(csvURL);
        a = csvURL = null;
      }
      csvData = csvString = null;
    }

  }
});
