lib.registerState "manager.profiles.profile.questionnaire.answer",
    url: "/answers/:answerID/questionID/:questionID"
    templateUrl: "states/manager/profiles/profile/questionnaire/answers/answer.state.html"
    resolve: answer: ($http, profile, $stateParams) ->
      # The only answers that require a questionID in their params are modules
      if $stateParams.questionID
        $http.get("/api/profiles/#{profile.id}/module/#{$stateParams.answerID}/questionID/#{$stateParams.questionID}").then (res) ->
          res.data
      else
        $http.get("/api/profiles/#{profile.id}/answers/#{$stateParams.answerID}").then (res) ->
          res.data

    controller: ($scope, $stateParams, $state, answer, profile, Images) ->
      $scope.isModule = !!$stateParams.questionID
      $scope.noAnswer = _.isEmpty answer
      $scope.goBack = () ->
        $state.go('^')

      # In a beautiful future we will support all the modules and this will be removed
      supportedModules = [
        'immunizations',
        'allergies',
        'dimensions',
        'medications'
      ]

      if answer.err
        $scope.error = answer.err
        return

      if $scope.isModule && !supportedModules.includes($stateParams.answerID)
        $scope.unsupportedModule = true

      return if $scope.noAnswer

      $scope.answer = answer
      $scope.profile = profile
      $scope.question = answer.question
      $scope.version = _.cloneDeep _.omit(answer, "history")
      $scope.history = _.map answer.history, (version, index) ->
        value: index
        label: moment(version.state.updated or version.created).format "MMM D, YYYY @ h:mm:ss A"

      unless answer.updated is answer.history?[0]?.state?.updated
        answer.history.unshift { state: answer }

      $scope.$watch "versionIndex", (i) ->
        return unless _.isPresent i
        $scope.version = $scope.answer.history[i]?.state
        $scope.version.user = $scope.answer.history[i]?.user

      $scope.versionIndex = 0

      if $scope.question.type is "upload"
        _.each $scope.answer.history, (historyItem) ->
          historyItem.state.urls = []
          return unless historyItem.state.value
          Images.getForProfile($scope.profile.id, historyItem.state.value).then (urls) ->
            historyItem.state.urls = urls
