angular.module("dn").directive "qbAgeRestrict", ->
  templateUrl: "directives/builder/age-restrict/qb-age-restrict.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbAgeRestrict"
  controller: ($scope) ->

    $scope.question.metadata ?= {}

    $scope.overUnder = [
      { label: "As old or older than", value: true }
      { label: "Younger than", value: false }
    ]

    $scope.ageRestricted = if $scope.question.metadata.ageRestrict then true else false
    $scope.ageChoices = _.map [1..100], (i) -> { label: i.toString(), value: i }

    $scope.$watch "ageRestricted", (fresh, stale) ->
      if fresh is false and stale is true
        delete $scope.question.metadata.ageRestrict
        $scope.ageRestricted = false
    , true
