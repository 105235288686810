angular.module("dn").directive "qbAuthorization", ->
  templateUrl: "directives/builder/authorization/qb-authorization.directive.html"
  restrict: "A"
  replace: true
  scope:
    question: "=qbAuthorization"
    mode: "@"
  controller: ($scope, $http) ->
    $scope.question.metadata ?= {}
    $scope.question.metadata.declinable           ?= false
    $scope.question.metadata.hideDefaultText      ?= false
    $scope.question.metadata.relationshipRequired ?= false
    $scope.canEdit = true
    if $scope.question.id
      $http.get("/api/questions/#{$scope.question.id}/answers/count").then(
        (success) ->
          if parseInt success.data.count
            $scope.canEdit = false
      )
