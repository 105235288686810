lib.registerState('manager.prescreening.scheduledReminders.scheduledReminder', {
  url: '/:reminderID?',
  search: 'filters',
  templateUrl: 'states/manager/prescreening/scheduled-reminders/scheduled-reminder/scheduled-reminder.state.html',
  reloadOnSearch: false,
  resolve: {
    title($rootScope, GroupBranding, organization) {
      // Have to use `organization` because child resolves happen _before_ parent resolves, so
      // $rootScope.organization isn't set yet here when we hard refresh.
      return $rootScope._title = `${GroupBranding.getPrescreeningAlias(organization)} Scheduled Reminder`;
    },
    // Including scheduledReminders because we need that parent resolve to complete
    // before we attempt to use the store.
    // eslint-disable-next-line no-unused-vars
    scheduledReminder($stateParams, $vuex, organization, scheduledReminders) {
      const genericErrorMessage = 'There was a problem loading the page';

      // Checks for a reminder id and, if found, sets the editable reminder on
      // the vuex store to the found reminder
      if ($stateParams.reminderID) $vuex.commit('scheduledReminders/INIT_EDITING', $stateParams.reminderID);

      // Sets editable reminder to either the default reminder or the found reminder
      const editableReminder = $vuex.state.scheduledReminders.editable;

      if (_.isEmpty(editableReminder)) return new Error(genericErrorMessage);

      return editableReminder;
    },
    // Including scheduledReminder because we need the previous resolve to complete
    parseCronObj($vuex, scheduledReminder) {
      const genericErrorMessage = 'There was a problem loading the page';

      // Checks for repeat_schedule when editing an existing reminder and a hardcoded
      // cron expression when handling a new reminder
      $vuex.dispatch('scheduledReminders/PARSE_CRON_EXPRESSION', scheduledReminder.repeat_schedule)
        .then(() => true)
        .catch(() => new Error(genericErrorMessage));
    },
  },
  controller: function($location, $rootScope, $scope, $state, $vuex, parseCronObj, SavedLists, scheduledReminder, session) {
    'use strict';

    // Handles views for tab directive within scheduled-reminder.state.html
    $scope.tabs = [
      { label: 'Reminder Content', value: 'reminderContent' },
      { label: 'Reminder Filters', value: 'reminderFilters' }
    ];
    $scope.tab = 'reminderContent';

    // Sets user on scope and adds filters to user object to handle deleting lists
    $scope.user = session.user;
    $scope.user.filters = SavedLists.loadedLists;

    // Store any invalid field names in a set to prevent duplicate error messages
    // ng-repeat over an array since angularjs doesnt support ES6 sets
    const invalidFields = new Set();
    $scope.invalidMessages = [];
    $scope.disabled = false;

    // Grabs filter string from scheduledReminder and allows access to it
    // in reminder-filters directive
    $scope.filterString = scheduledReminder.filter_string;

    /**
     * Generate an error that the state can consume
     * TODO: Add this as an internal dependency. Window is probably not the best choice
     * @param {string} errorText: The error text we want to add to the state HTML
     */
    function generateError(errorText) {
      $scope.errorText = errorText;
      $rootScope._title = 'Error';
    }

    // Stop performing any additional logic in case of an error
    if (scheduledReminder instanceof Error) return generateError(scheduledReminder.message);
    if (parseCronObj instanceof Error) return generateError(parseCronObj.message);

    $scope.scheduledReminder = scheduledReminder;
    // Workaround because if we try to access the state immediately after the .then the mutation
    // hasn't added the data to the store. Fun times.
    const parsedCronObj = $vuex.state.scheduledReminders.parsedCronObj;

    $scope.enableEndDate = true;

    // Disassociates the reminder from the store to be consumed by the interval directive.
    // Prevents errors on the store for not running a mutation when changing data.
    $scope.dates = {
      startDate: scheduledReminder.start_date,
      endDate: scheduledReminder.end_date,
    };
    $scope.fields = JSON.parse(JSON.stringify(parsedCronObj.fields));

    // If both the reminder and the org do not have a timezone we default to UTC.
    // It is rare for this to happen, but it is an option.
    $scope.timezone = scheduledReminder.timezone
      || $rootScope.organization.properties.timezone
      || 'UTC';

    // Handle deprecated timezone values if present
    // @TODO: Remove this in AP-2721
    $scope.timezone = lib.tzHelpers.remapIfDeprecated($scope.timezone);

    $scope.details = {
      name: scheduledReminder.name,
      // Sets the message_details.body to an empty string for new reminders
      body: scheduledReminder.message_details.body || '',
    };

    // If message_details exists, but body is not a key on that object, default it to prevent being unable to edit the reminder
    if (!scheduledReminder.message_details.body) scheduledReminder.message_details.body = '';

    /**
     * FE validator that ensures the dates and reminder name are valid fields.
     * @param {string} startDate The reminders start date
     * @param {string} [endDate] The reminders end date
     * @param {string} reminderName The reminders name
     * @param {boolean} enableEndDate Toggle for 'Never Ends'
     */
    const validateFields = function(startDate, endDate, reminderName, enableEndDate) {
      // Validate that the start date is a valid date
      if (!moment(startDate).isValid()) {
        invalidFields.add('Start Date');
      } else {
        invalidFields.delete('Start Date');
      }

      // Validate that the end date is valid if enabled
      if ((enableEndDate && !moment(endDate).isValid())
        || (enableEndDate && moment(endDate).isBefore(startDate))) {
        invalidFields.add('End Date');
      } else {
        invalidFields.delete('End Date');
      }

      // Validate that the reminder has a name that isn't an empty string
      if (!reminderName || reminderName.length === 0) {
        invalidFields.add('Reminder Name');
      } else {
        invalidFields.delete('Reminder Name');
      }

      $scope.disabled = invalidFields.size > 0;
      $scope.invalidMessages = Array.from(invalidFields);
    };

    // Initial validation for new reminders
    validateFields(
      $scope.dates.startDate || null,
      $scope.dates.endDate || null,
      $scope.details.name || '',
      $scope.enableEndDate
    );

    // Watch the dates + name and ensure that they're all valid
    $scope.$watchGroup(
      ['dates.endDate', 'dates.startDate', 'details.name', 'enableEndDate'],
      ([endDate, startDate, reminderName, enableEndDate]) => {
        validateFields(startDate, endDate, reminderName, enableEndDate);
      }
    );

    $scope.cancel = function() {
      $vuex.commit('scheduledReminders/CLEAR');
      $state.go('manager.prescreening.scheduledReminders');
    };

    /**
     * Generates a swal to display when an error occurs.
     * @returns {SweetAlert}
     */
    function generateErrorSwal() {
      return swal(
        'Scheduled Reminder Error',
        'There was an error saving the scheduled reminder.\nPlease refresh the page and try again.',
        'error'
      );
    }

    /**
     * Updates the editable object to the vue store
     * @throws Will throw an error if a key of an object passed to the action does not exist.
     */
    $scope.updateEditable = function () {
      const updatedEditable = {
        start_date: $scope.dates.startDate,
        end_date: $scope.dates.endDate,
        repeat_schedule: $scope.fields,
        name: $scope.details.name,
        // This key is a string to allow lodash to find the nested object
        'message_details.body': $scope.details.body,
        filter_string: $location.$$search.filters || scheduledReminder.filter_string,
        timezone: $scope.timezone,
        org_id: scheduledReminder.org_id || $scope.organization.id,
      };

      try {
        $vuex.dispatch('scheduledReminders/VALIDATE_AND_UPDATE_EDITABLE', updatedEditable);
      // eslint-disable-line no-unused-vars
      } catch (err) {
        generateErrorSwal();
      }
    };

    $scope.upsertReminder = function() {
      $vuex
        .dispatch('scheduledReminders/UPSERT_REMINDER')
        .then(() => {
          swal(
            {
              title: 'Reminder Updated',
              text: 'The scheduled reminder has been set.',
              type: 'success',
            },
            () => {
              // Redirect the user to the list of scheduled reminders
              // after updating/creating a reminder.
              $state.go('manager.prescreening.scheduledReminders');
            }
          );
        })
        .catch(() => generateErrorSwal());
    };

    /**
     * Formats a string to have the first letter be lowercased.
     * @param {string} schedule The cronstrue string to be formatted
     * @returns The newly formatted cronstrue string
     */
    $scope.formatCronToHuman = (schedule) => {
      return schedule.charAt(0).toLowerCase() + schedule.slice(1);
    };

    $scope.confirmSchedule = () => {
      $scope.updateEditable();

      const startDate = moment($scope.scheduledReminder.start_date)
        .tz($scope.scheduledReminder.timezone).format('LL');
      const endDate = moment($scope.scheduledReminder.end_date)
        .tz($scope.scheduledReminder.timezone).format('LL');

      const cronToHumanString = window.lib.cronToHuman($scope.scheduledReminder.repeat_schedule);
      const readableSchedule = $scope.formatCronToHuman(cronToHumanString);
      const confirmationMessage = $scope.scheduledReminder.end_date
        ? `Your reminder will become active on ${startDate} ${readableSchedule}, until ${endDate}`
        : `Your reminder will become active on ${startDate} ${readableSchedule}, with no end date`;

      return swal({
        type: 'info',
        title: 'Schedule reminder?',
        text: confirmationMessage,
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        closeOnConfirm: false,
        closeOnCancel: true,
        showLoaderOnConfirm: true
      }, () => {
        $scope.upsertReminder();
      });
    };
  }
});
