lib.registerState('manager.settings.builder', {
  url: '/builder',
  templateUrl: 'states/manager/settings/builder/builder.state.html',
  resolve: {
    title: function ($rootScope, group) { return $rootScope._title = `${group.name} / Builder`; },
    parcelConfig: function () { return window.System.import('@dn/builder'); },
    parcelProps: function(organization) {
      return {
        org: organization
      };
    }
  },
  controller: function($scope, organization, session, parcelConfig, parcelProps) {
    $scope.noBuilderPermissions = !(organization.properties.enableBuilderSelfService || session.access.operator);
    blockApplyFlag = window.lib.featureFlagClient.isEnabled('operBlockApply');

    $scope.parcelConfig = parcelConfig;
    $scope.parcelProps = Object.assign({}, parcelProps, { blockApplyFlag });
  }
});
