angular.module("dn").directive("profileMerge", function() {
  return {
    templateUrl: "directives/profile-merge/profile-merge.directive.html",
    restrict: "E",
    scope: {
      profiles:    "@",
      showFinance: "@showfinance",
      loggedUser:  "@loggeduser",
      orgID:       "@orgid"
    },
    controller: function($scope, $state, $http, GroupBranding, Profile) {
      "use strict";

      $scope.screeningAlias = GroupBranding.aliases.prescreening;

      /* Reset all the things */
      $scope.closeModal = function() {
        $scope.showModal = false;
        $scope.merging = false;
        $scope.loadingMergeData = false;
        $scope.conflicts = null;
      };

      /* Things that we don't want to merge */
      $scope.blackList = {
        registrations: [],
        crebits: [],
        profilesUsers: []
      };

      $scope.processing = false;

      function loadProfile(profileID, afterLoad) {
        new Profile(profileID).load(
          ["crebits", "registrations", "profilesUsers"]
        ).then(function(profile) {
          /* Format things for the view */
          modifyDOB(profile);
          modifyCreated(profile);
          return afterLoad(profile);
        });
      }

      /* Moment-ify the profile's DOB */
      function modifyDOB(profile) {
        profile.displayDOB = moment(profile.dob).format("MMMM DD, YYYY");
        return profile;
      }

      /* Moment-ify the profile's created date */
      function modifyCreated(profile) {
        profile.displayCreated = moment(new Date(profile.created)).format("MMMM DD, YYYY hh:mm A") + " ET";
        return profile;
      }

      /*
        Figure out who's the winner and who's the loser
        Winner will either have higher completeness or created earlier (lesser ID)
        This could have all been a glorious 1-liner ternary :/
      */
      function assignProfiles(p1, p2) {
        if (p1.completeness > p2.completeness) {
          $scope.winner = p1;
          $scope.loser = p2;
        } else if (p2.completeness > p1.completeness) {
          $scope.winner = p2;
          $scope.loser = p1;
        } else if (p1.id < p2.id) {
          $scope.winner = p1;
          $scope.loser = p2;
        } else {
          $scope.winner = p2;
          $scope.loser = p1;
        }
      }

      /* Make sure they don't share any registrations, else we get unique_tuition BS */
      function validateRegistrations() {
        var winnerRegs = _.map($scope.winner.registrations, function(r) {
          return { id: r.id, groupID: r.groupID, type: r.type };
        });
        var loserRegs = _.map($scope.loser.registrations, function(r) {
          return { id: r.id, groupID: r.groupID, type: r.type };
        });

        _.forEach(winnerRegs, function(wr) {
          _.forEach(loserRegs, function(lr) {
            if (lr.groupID === wr.groupID && lr.type === wr.type) {
              /* We got a shared registration. Blacklist the reg and its associated tuition crebit before we merge */
              $scope.blackList.registrations = $scope.blackList.registrations.concat(_.remove($scope.loser.registrations, function(loserReg) {
                return loserReg.id === lr.id;
              }));
              $scope.blackList.crebits = $scope.blackList.crebits.concat(_.remove($scope.loser.crebits, function(loserCreb) {
                return loserCreb.registrationID === lr.id;
              }));
            }
          });
        });
        return;
      }

      /* Make sure none of the profileUsers have the same userID in them */
      function validateProfilesUsers() {
        var winnerPUIDs = _.compact(_.map($scope.winner.profileUsers, "userID"));

        _.forEach($scope.loser.profilesUsers, function(pu) {
          if (_.includes(winnerPUIDs, pu.userID)) {
            /* User is already managing the winner, to the blackList! */
            $scope.blackList.profilesUsers.push(pu);
          }
        });
        return;
      }

      /* Not a totally necessary function. Add more checks as needed */
      function anyConflicts() {
        return !!($scope.blackList.registrations.length || $scope.blackList.crebits.length || $scope.blackList.profilesUsers);
      }

      /* Not going to do much here for now, but I'm sure we'll need to add more computations to check more things later on. Put them here */
      function processMerge() {
        validateRegistrations();
        validateProfilesUsers();
        if (anyConflicts()) {
          $scope.conflicts = [];
          /* Only care about registration conflicts for now */
          if ($scope.blackList.registrations.length) {
            _.forEach($scope.blackList.registrations, function(reg) {
              $scope.conflicts.push("Profiles share registration to group " + reg.groupID + ". Loser's registration and related tuition line item will not be merged.");
            });
          }
        }
        /* My lame way of fixing an issue where you open the merger, close it, then reopening to cause an error for ng-repeats */
        $scope.conflicts = _.uniq($scope.conflicts);
      }

      $scope.mergeProfiles = function(inputEmail) {
        $scope.emailMismatch = !(inputEmail === $scope.loggedUser);
        if ($scope.emailMismatch) { return; }

        $scope.processing = true;

        var route = "/api/profiles/profile-merge";
        var postBody = {
          keep: $scope.winner.id,
          remove: $scope.loser.id,
          organizationID: $scope.orgID
        }
        return $http.post(route, postBody).then(function(success) {
          $scope.closeModal();
          window.swal({
            type: "success",
            title: "Merge Successful",
            text: "Profiles successfully merged. Please reload the page to apply changes.",
            confirmButtonText: "Reload",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }, function(isConfirm) {
            if (isConfirm) {
              return $state.reload();
            }
          });
        }, function(err) {
          $scope.closeModal();
          window.swal({
            title: "Unexpected Error",
            type: "error",
            text: "An unexpected error occurred while merging these profiles.\nWhen contacting support, please provide them with this identifier:\n\n" + err.data + "\n\nPlease reload the page.",
            confirmButtonText: "Reload",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }, function(isConfirm) {
            if (isConfirm) {
              return $state.reload();
            }
          });
        });
      }

      /* Main runner for the mergification */
      $scope.run = function() {
        $scope.loadingMergeData = true;
        var profileIDs = $scope.profiles.replace(/\[|\]/g, "").split(",");

        loadProfile(profileIDs[0], function(profile1) {
          loadProfile(profileIDs[1], function(profile2) {
            $scope.loadingMergeData = false;
            $scope.merging = true;
            assignProfiles(profile1, profile2);
            $scope.showModal = true;
            processMerge();
            return;
          });
        });
      }

      /* Get sum of all crebit.amounts in the profile.crebits array */
      $scope.calculateBalance = function(profile) {
        var sum = 0;
        _.forEach(profile.crebits, function(crebit) {
          sum += crebit.amount;
        });
        return sum;
      }

      /* Swap the winner and loser */
      $scope.swapProfiles = function() {
        var temp = _.cloneDeep($scope.winner);
        $scope.winner = _.cloneDeep($scope.loser);
        $scope.loser = temp;
      }

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
