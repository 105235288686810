angular.module("dn").directive("importPreview", function() {
  return {
    templateUrl: "directives/importer/preview/import-preview.directive.html",
    restrict: "E",
    scope: {
      importPreview: "=",
      exportPreview: "="
    },

    controller: function($scope, $state) {
      var allRows = $scope.importPreview.matchedProfiles.concat($scope.importPreview.newProfiles);

      $scope.stats = {
        demoChanges: 0,
        users: 0,
        registrations: {},
        tags: {},
        conflicts: {}
      }

      _.map(allRows, function(profile) {

        if (profile.demographicChanges)
          $scope.stats.demoChanges += _.size(profile.demographicChanges);

        if (profile.users)
          $scope.stats.users += profile.users.length;

        _.map(profile.registrations, function(reg) {
          if (!$scope.stats.registrations[reg.groupID])
            $scope.stats.registrations[reg.groupID] = { name: reg.groupName, count: 0 }

          $scope.stats.registrations[reg.groupID].count += 1;
        });

        _.map(profile.conflicts, function(conflict) {
          if (!$scope.stats.conflicts[conflict])
            $scope.stats.conflicts[conflict] = { name: conflict, count: 0 }

          $scope.stats.conflicts[conflict].count += 1;
        });
      });

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
