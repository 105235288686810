angular.module('dn').directive('sendMessage', function() {
  return {
    templateUrl: 'directives/send-message/send-message.directive.html',
    restrict: 'E',
    scope: {
      messageTemplates: '=',
      organization: '=',
      profiles: '=',
      provider: '=',
      tab: '=',
      user: '=',
    },

    controller($filter, $http, $scope, $state, flash, Template, WhatDivision) {
      'use strict';

      $scope.isFullTextSearchEnabled = () => {
        return window.lib.featureFlagClient.isEnabled("FullTextSearchProfiles")
      };

      $scope.managingTemplates = false;
      $scope.templateID = null;

      $scope.message = {};
      $scope.division = WhatDivision;

      const isOper = $filter('permissionVisible')({ access: 'oper' });
      const canSendEmail = $filter('permissionVisible')({ send_messages: 'allow' });
      $scope.canSendEmailAndSMS = canSendEmail && $filter('permissionVisible')({ send_sms: 'allow' });
      $scope.messageType = canSendEmail ? 'email' : 'sms';

      $scope.emailOrText = [
        { label: 'Email', value: 'email' },
        { label: 'Text', value: 'sms' },
      ];

      $scope.messageTemplateChoices = [];
      $scope.customTemplateChoices = [];

      _.map($scope.messageTemplates, (t) => {
        if ($scope.provider && t.profileID === $scope.provider.id) {
          $scope.messageTemplateChoices.push({label: t.name, value: t.id, optgroup: 'Custom'});
          $scope.customTemplateChoices.push({label: t.name, value: t.id, optgroup: 'Custom'});
        } else if (t.shared || isOper) {
          $scope.messageTemplateChoices.push({label: t.name, value: t.id, optgroup: 'Shared'});
        }
      });
      $scope.template = null;

      // populate reply-to select box for mass messages
      $scope.replyToChoices = _.map($scope.organization.properties.contacts, (c) => {
        if (c.email) {
          return {label:c.email, value: c.email};
        }
      });
      $scope.replyToChoices.unshift({
        label: $scope.user.email || $scope.organization.properties.replyTo,
        value: $scope.user.email || $scope.organization.properties.replyTo
      });

      $scope.newTemplate = () => {
        // Clear any previously selected template when managing templates.
        $scope.template = null;
        $scope.templateID = null;
        $scope.message = {
          subject: '',
          customReplyTo: '',
          body: '',
          cc: '',
          name: '',
        };
        $scope.managingTemplates = true;
      };

      $scope.loadTemplate = () => {
        if ($scope.templateID === null) {
          return;
        }
        $scope.template = _.find($scope.messageTemplates, t => t.id === parseInt($scope.templateID));
        if (!$scope.template) {
          return $scope.newTemplate();
        }
        $scope.message = {
          subject: $scope.template.template.subject,
          body: $scope.template.template.body,
          name: $scope.template.name,
        };
        if ($scope.template.template.customReplyTo) {
          $scope.message.customReplyTo = $scope.template.template.customReplyTo;
        }
      };

      $scope.saveTemplate = () => {
        const template = {
          name: $scope.message.name,
          template: {
            subject: $scope.message.subject,
            body: $scope.message.body,
          },
          groupID: $scope.organization.id,
          profileID: $scope.provider.id,
          operator: false,
          shared: ($scope.template && $scope.template.shared) || false,
          type: 'message',
        };
        if ($scope.message.customReplyTo) {
          template.template.customReplyTo = $scope.message.customReplyTo;
        }
        if ($scope.template && $scope.template.id) {
          template.id = $scope.template.id;
        }

        new Template(template).save().then(() => {
          flash('Template Saved!');
          $state.reload();
        }).catch(() => {
          swal('Error!', 'There was an error saving your template. Please try again', 'error');
          $state.reload();
        });
      };

      $scope.removeTemplate = () => {
        if (!($scope.template && $scope.template.id)) {
          return;
        }

        swal({
          title: 'Delete Template?',
          type: 'warning',
          text: `Are you sure you want to delete ${$scope.template.name}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#DD6B55',
        }, (confirm) => {
          if (!confirm) {
            return;
          }

          new Template($scope.template).destroy().then(() => {
            swal('Deleted!', `${$scope.template.name} has been deleted!`, 'success');
            // remove option from dropdown choices, clear template
            $scope.customTemplateChoices = _.reject($scope.customTemplateChoices, (choice) => +choice.value === $scope.template.id);
            $scope.messageTemplateChoices = _.reject($scope.messageTemplateChoices, (choice) => +choice.value === $scope.template.id);
            $scope.newTemplate();
          }).catch(() => {
            swal('Error!', 'There was an error deactivating your template. Please try again', 'error');
            $state.reload();
          });
        });
      };

      $scope.uploadComplete = (result) => {
        if (!$scope.message.attachments) {
          $scope.message.attachments = [];
        }
        $scope.message.attachments.push(result);
      };

      $scope.removeAttachment = (i) => $scope.message.attachments.splice(i, 1);

      // Send a mass message
      $scope.sendMessage = (message) => {
        $scope.sendingMessage = true;

        if ($scope.isFullTextSearchEnabled()) {
          $scope.message.profileIDs = $scope.profiles;
        } else {
          // Get an array of profileID ints for the post body
          if (_.some($scope.profiles, p => p.selected)) {
            $scope.message.profileIDs = _($scope.profiles).filter(p => p.selected).map('profiles.id').value();
          } else {
            $scope.message.profileIDs = _.map($scope.profiles, 'profiles.id');
          }
        }

        // Attach providerID if available so they get an email too
        $scope.message.providerID = $scope.provider && $scope.provider.id;

        // append attachment download links
        if ($scope.message.attachments && $scope.message.attachments.length) {
          $scope.message.body += '\n\n\n<hr>\n**Attachments**\n';
          _.forEach($scope.message.attachments, (a) => {
            $scope.message.body += `\n  - [${a.filename}](${a.url})`;
          });
          $scope.message.body += '  \n\nPlease Note: Attachments will expire 60 days after sending.';
          delete $scope.message.attachments;
        }

        const route = `/api/organizations/${$scope.organization.id}/send-message`;

        const swalConfig = {
          type: 'warning',
          title: 'Confirmation',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Send',
        };

        if ($scope.message.profileIDs.length === 0) {
          swalConfig.type = 'error';
          swalConfig.title = 'Error';
          swalConfig.text = 'You must select at least 1 profile before sending a message.';
          swalConfig.showConfirmButton = false;
        } else {
          swalConfig.text = `Send this message to ${$scope.message.profileIDs.length} ${($scope.message.profileIDs.length === 1) ? 'profile' : 'profiles'}?`;
        }

        swal(swalConfig, (confirmed) => {
          if (confirmed) {
            $http.post(route, message).then(() => {
              flash('Message Sent!');
              $scope.message.subject = '';
              $scope.message.body = '';
              $scope.message.customReplyTo = '';
              $scope.sendingMessage = false;
            }).catch((err) => {
              $scope.sendingMessage = false;
              swal({type: 'error', title: 'Error', text: err.data});
            });
          } else {
            $scope.sendingMessage = false;
          }
        });
      };
    }
  };
});
