lib.registerState("manager.noPermissions", {
  url: "/no-permissions",
  templateUrl: "states/manager/no-permissions/no-permissions.state.html",
  resolve: {
    title: function($rootScope) { return $rootScope._title = "No Permissions"; },
    deleteNav: function($rootScope) {
      $rootScope._nav = {};
    }
  },

  controller: ($rootScope, $state, $scope) => {
    $scope.orgContact = () => {
      if (_.has($scope.organization.properties, "contacts.general.email", false)) {
        return `at ${$scope.organization.properties.contacts.general.email}`;
      }
      return `for ${$scope.organization.name}`;
    }
  }
});
