lib.registerState('home.auth.signup', {
  url: '/signup',
  templateUrl: 'states/home/auth/signup/signup.state.html',
  params: {
    // Default to `null`
    email: null
  },
  controller: function ($scope, $state, $timeout, User, registration, invitation) {

    // Pass in parent-resolved objects
    $scope.invitation = invitation;
    $scope.registration = registration;

    // Setting current state variables/overriding parent state variables
    $scope.wrongPassword = false;
    $scope.inviteError = ($scope.invitation && $scope.invitation === 'ERROR');
    $scope.emailConfirmation = null;
    $scope.passwordConfirmation = null;

    // If (user provides an email AND there's a registration) OR invitation,
    // email address is locked to what is provided in $state.params
    // Set email and providedEmail
    if ($scope.invitation && $scope.invitation !== "ERROR") {
      $scope.email = $scope.invitation.inviteEmail;
      $scope.providedEmail = $scope.invitation.inviteEmail;
    }
    else {
      $scope.email = $state.params.email;
      $scope.providedEmail = $state.params.email
    }

    // Set branding text
    $scope.brandingText = undefined;
    if (
      $scope.registration &&
      $scope.registration.properties.branding &&
      $scope.registration.properties.branding.text &&
      $scope.registration.properties.branding.text.welcome
    ) {
      $scope.brandingText = $scope.registration.properties.branding.text.welcome;
    }

    // If the user ISN'T on mobile, auto-focus the email input
    if ($scope.viewPort.width > 720) {
      $timeout(function() {
        let targetInput;
        if (($scope.registration || $scope.invitation) && !$scope.providedEmail) {
          targetInput = $("#sign-up-email input")[0];
        }
        else {
          targetInput = $("#search-email input")[0];
        }
        return targetInput.focus();
      }, 100);
    }

    // `onClick` for the continue button
    $scope.signup = function() {

      // Initialized in auth state
      $scope.submitting = true;

      if (!$scope.validInputs()) {
        $scope.submitting = false;
        return;
      }

      // Set user email and password
      const user = {};
      user.email = $scope.email;
      user.password = $scope.password;

      new User(user).save().then(function (user) {
        $scope.submitting = false;
        // We can't return this $state.go for some reason; it will eventually error with a message
        // of "transition superseded", whatever that means.
        $state.go('home.whereTo');
      }).catch(function(err) {
        $scope.submitting = false;
        if (err.data === "Email address already in use") {
          return $scope.userAlreadyExists = true;
        }
        else if (err.data === "Invalid Password") {
          user.password = undefined;
          $scope.password = undefined;
          $scope.passwordConfirmation = undefined;
          return badPasswordAlert();
        }
        else {
          return swal({
            type: "error",
            title: "Error",
            text: `
              An unexpected error occurred:
              ${JSON.stringify(err)}
              If the error persists, please contact our support team.
            `
          });
        }
      });

    };

    // `onEnter` for search email input
    $scope.searchEmail = function() {
      return $scope.submitUser('signup', $scope.email)
    }

    function badPasswordAlert() {
      return swal({
        type: "error",
        title: "Invalid Password",
        text: `
          <p>Your password does not meet our minimum requirements.</p>
          <br>
          <p>Please create a password with at least:</p>
          <br>
          <p style="font-weight: bold;">8 characters</p>
          <br>
          <p style="font-weight: bold;">One upper case letter</p>
          <br>
          <p style="font-weight: bold;">One lower case letter</p>
          <br>
          <p style="font-weight: bold;">One number or symbol</li>

        `,
        html: true
      });
    }

    function badEmailAlert() {
      return swal({
        type: "error",
        title: "Invalid Email",
        text: `
          <p>The email address you've entered is invalid.</p>
          <br>
          <p>Please check for typos and try again.</p>
        `,
        html: true
      });
    }

    function badConfirmationField(fieldName) {
      return swal({
        type: "error",
        title: "Invalid " + fieldName.capitalize() + " Confirmation",
        text: `
          <p>
            The ${fieldName === "password" ? fieldName : "email addresse"}s
            you've entered do not match.
          </p>
          <br>
          <p>Please check for typos and try again.</p>
        `,
        html: true
      });
    }

    validPassword = function(pw) {
      if (!pw) return false
      val = _.cloneDeep(pw)
      result = lib.validate(pw, ["required", "min-length:8", "mix-case", "number-symbol"]);
      return result[0].length === 0;
    }

    validPasswordConf = function(pw, pwConf) {
      if (!pw || !pwConf) return false
      val = _.cloneDeep(pwConf)
      match = _.cloneDeep(pw)
      result = lib.validate(val, ["required", "matches:" + match]);
      return result[0].length === 0;
    }

    validEmail = function(email) {
      if (!email) return false
      val = _.cloneDeep(email)
      result = lib.validate(val, ["required", "email"]);
      return result[0].length === 0;
    }

    validEmailConf = function(email, emailConf) {
      if (!email || !emailConf) return false
      val = _.cloneDeep(emailConf)
      match = _.cloneDeep(email)
      result = lib.validate(val, ["required", "matches:" + match]);
      return result[0].length === 0;
    }

    $scope.validInputs = function(validationOnly) {
      if (!validEmail($scope.email)) {
        if (!validationOnly) badEmailAlert();
        return false;
      }
      if (!validEmailConf($scope.email, $scope.emailConfirmation)) {
        if (!validationOnly) badConfirmationField('email');
        return false;
      }
      if (!validPassword($scope.password)) {
        if (!validationOnly) badPasswordAlert();
        return false;
      }
      if (!validPasswordConf($scope.password, $scope.passwordConfirmation)) {
        if (!validationOnly) badConfirmationField('password');
        return false;
      }
      return true;
    }

    $scope.passwordEncode = function(password) {
      // base64 encode
      return window.btoa(password);
    }
  }
});
