lib.registerState "manager.profiles.profile",
  url: "/:profileID"
  templateUrl: "states/manager/profiles/profile/profile.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Profiles & Reports"
    profile: ($stateParams, Profile) ->
      return new Profile($stateParams.profileID).load(
        ["paymentPlans"],
        { addlParamsString: "excludeOrg"}
      )
    reviewTypes: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/review-types").then (result) ->
        _.filter result.data, (r) -> !r.deactivated

  controller: ($rootScope, $scope, $http, $timeout, $filter, profile, $state, flash, reviewTypes, session, Images, Review, Note, Profile, Event) ->

    $scope.revertTitle = ->
      $rootScope._title = "Profiles & Reports"

    # Initialize
    $scope.profile = profile
    $scope.state = $state
    $scope.userID = session.userID
    $scope.providerID = $scope.provider?.id
    $scope.age = moment().diff($scope.profile.dob,'years') if $scope.profile.dob
    $scope.now = moment().format('YYYY-MM-DD')
    $scope.reviewsLoaded = false

    # Permission values
    $scope.viewNotesPermission = { "profile_notes": "view" }
    $scope.editNotesPermission = { "profile_notes": "edit" }
    $scope.viewReviewsPermission = { "profile_reviews": "view" }
    $scope.editReviewsPermission = { "profile_reviews": "edit" }
    $scope.viewReviewsOrNotesPermission = { "profile_reviews": "view", "profile_notes": "view" }
    $scope.validPermission = (permToCheck) ->
      if $filter('permissionVisible')(permToCheck)
        return true
      else return false

    calcBalance = (crebits) ->
      $scope.balance = _.reduce crebits, (sum, c) ->
        sum + c.amount
      , 0

    $scope.getBalance = () ->
      return unless $filter('permissionVisible')({ "profile_account": "view" })
      $http.get("/api/profiles/#{profile.id}/account?attributedTo&attributions").then (result) ->
        crebits = result.data
        calcBalance(crebits)
      .catch (err) ->
        throw err

    $scope.getBalance()

    $scope.isRegOrg = $scope.organization.shortName != null

    # Image modal control
    $scope.show = {}
    $scope.showModal = (questionID, index) ->
      $scope.show[questionID] ?= []
      $scope.show[questionID][index] = true

    $scope.showAvatar = ->
      if $scope.validPermission({ profile_demographics: 'edit' }) or $scope.profile.avatar?.md
        $scope.show.avatar = !$scope.show.avatar

    $scope.healthProfile =
      $scope.organization.properties?.portals?.patient?.sections?.questionnaire?.alias or
      $scope.organization.properties?.branding?.text?.healthProfileAlternateName or
      "Health Profile"

    anyTags = !_.isEmpty $scope.organization.tags

    # Make tabs available
    $scope.tabs = [
      {label: 'Registrations', sref: '.registrations', permissions: { profile_registrations: 'view' }},
      {label: "Tags", sref: ".tags", hide: !anyTags, permissions: { profile_tags: 'view' }},
      {label: 'Account', sref: '.account', hide: !$scope.organization.shortName, permissions: { profile_account: 'view' }},
      {label: 'Users', sref: '.users', permissions: { profile_users: 'view' }},
      {label: 'Notifications', sref: '.notifications', permissions: { profile_notifications: 'view'}},
      {label: $scope.healthProfile, sref: ".questionnaire", permissions: { profile_questionnaire: 'view' }},
      {label: "Medications", sref: ".medications", permissions: { profile_medications: 'view' }},
      {label: "Health Log", sref: ".log", permissions: { health_log: 'view', behavioral_health: 'view' }},
      {label: "CampGrams", sref: ".funk-notes", hide: !$scope.organization.properties.enableFunkNotes, permissions: { access_funknotes: 'allow' }}
    ]

    $scope.show = {}

    # Change request mechanisms
    $scope.acceptRequest = ->
      $scope.profile = _.merge $scope.profile, $scope.profile.properties.changeRequest
      delete $scope.profile.properties.changeRequest
      delete $scope.profile.properties.changeRequested
      $scope.profile.save().then (profile) ->
        new Event({profileID: profile.id, type: "demographics.changeMade"}).save()
        $scope.show = {}

    $scope.denyRequest = ->
      delete $scope.profile.properties.changeRequest
      delete $scope.profile.properties.changeRequested

      $scope.profile.save().then (profile) ->
        new Event({profileID: profile.id, type: "demographics.changeDenied"}).save()
        $scope.show = {}

    $scope.saveEdit = ->
      if $scope.profile.identifier is ""
        $scope.profile.identifier = null

      # because we attach so much useless data to registrations, with enough of them the payload size is too large and the route returns an error
      # please remove this once we make our GET /registrations not awful
      regBak = _.cloneDeep $scope.profile.registrations
      delete $scope.profile.registrations

      $scope.profile.save().then ->
        flash "Profile Saved"
        $scope.show = {}
        $scope.profile.registrations = _.cloneDeep regBak

      , (reason) ->
        if reason.status is 409
          window.swal
            title: "Identifier Conflict"
            type: "error"
            text: """
              Another profile in #{$scope.organization.name} has this identifier.
            """
            confirmButtonText: "OK"


    $scope.postUpload = ->
      $state.reload "manager.profiles.profile"

    $scope.removeAvatar = ->
      $scope.profile.avatar = null
      $scope.profile.save().then ->
        flash "Profile Saved"
        $scope.show = {}

    $scope.rotateAvatar = (degrees) ->
      $scope.rotating = true
      data =
        resource: $scope.profile
        type:     'avatar'
        key:      $scope.profile.avatar?.lg
        degrees:  degrees

      $http.post("/api/profiles/#{$scope.profile.id}/edit-image", data).then (result) ->
        $scope.profile.avatar = result.data
        $scope.rotating = false
      , (err) ->
        swal("Rotation Error", "There was an error rotating the avatar photo.\nPlease refresh the page and try again", "error")
        $state.reload()


    # Review setup
    $scope.reviewTypes = _.sortBy reviewTypes, "name"
    $scope.reviewChoices = _.map ["No", "Pending", "Yes"], (v) -> { label: v, value: v }
    $scope.showReview = if $scope.viewPort.device is "desktop" then true else false
    $scope.reviewTabs = [{ label: "Reviews", value: "reviews", permissions: $scope.viewReviewsPermission }, { label: "Notes", value: "notes", permissions: $scope.viewNotesPermission }]
    $scope.tab = do ->
      if $filter('permissionVisible')($scope.viewReviewsPermission)
        return "reviews"
      else return "notes"

    $scope.$watch "tab", (fresh, stale) ->
      return unless fresh
      if fresh is "newNote"
        angular.element("#new-note-body").find("textarea").click().focus().click().focus()
    , true

    # Async reviews setup
    getReviews = ->
      return new Promise (resolve, reject) ->
        if $filter('permissionVisible')({ profile_reviews: 'view' })
          $http.get("/api/profiles/#{profile.id}/reviews").then (result) ->
            return resolve(result.data)
          .catch (err) ->
            flash "Error loading reviews"
        else
          $scope.showReview = false
          return resolve([])

    $scope.allReviewed = ->
      _.every $scope.reviews, (r) -> r.status is "Yes"

    initReviews = ->
      getReviews().then (loadedReviews) ->
        $scope.reviews = _.keyBy (_.filter loadedReviews, (r) -> !r.deactivated), "reviewTypeID"
        _.map $scope.reviewTypes, (type) ->
          $scope.reviews[type.id] ?= {
            userID: session.userID
            reviewTypeID: type.id
            status: "No"
            user: {}
            profileID: $scope.profile.id
          }
          if (_.isArray($scope.reviews[type.id].user))
            $scope.reviews[type.id].user = $scope.reviews[type.id].user[0]
        if $scope.allReviewed() then $scope.showReview = false
        $scope.reviewsLoaded = true
      .catch (err) ->
        console.log 'caught err', err
        $scope.reviews = []

    initReviews()

    $scope.anyNonSticky = _.some $scope.reviewTypes, (t) -> !t.sticky

    if $state.current.name isnt "manager.profiles.profile.questionnaire"
      $scope.showReview = false

    # Gather the steps that reset reviews
    $scope.reviewReset = []
    $scope.profile.reviewFlags ?= []
    findFlags = (questions, step) ->
      return unless questions?.length
      _.map questions, (q) ->
        if q.type is "step" then step = q.label
        if !q.id then return
        if q.id.toString() in $scope.profile.reviewFlags
          $scope.reviewReset.push step
        findFlags q.subQuestions, step
    findFlags $scope.profile.newQuestionnaire.questions, ""
    $scope.reviewReset = _.uniq $scope.reviewReset


    # Save a review
    $scope.saveReview = (type) ->
      return unless $filter('permissionVisible')({ profile_reviews: 'edit' })
      review = $scope.reviews[type.id]
      review.userID = session.userID
      review.user = session.user
      review.timestamp = new Date().toISOString()
      new Review(review).save().then (review) ->
        $scope.reviews[type.id] = review

        # Reset reviewFlags if review change is from "No" and its not sticky
        if !review.sticky and review.status isnt "No" and $scope.profile.reviewFlags?.length
          $scope.reviewReset = []
          $scope.profile.reviewFlags = []
          saveProfile = _.pick (_.cloneDeep $scope.profile), ["id", "reviewFlags"]
          new Profile(saveProfile).save()

    # Notes permissions things
    applyPermissions = (notes) ->
      # loop through all returned notes
      return _.reduce notes, (filteredNotes, n) ->
        # if they're an oper, they should get access to all notes - regardless of category
        if $rootScope.operator
          filteredNotes.push n
        # if any are assigned a category (They all should)
        else if n.category and n.category.length
          # see if the category is restricted to a set of roleIDs
          restrictedRoleIDs = $scope.organization.properties?.noteCategoryRestrictions?[n.category]
          if restrictedRoleIDs and restrictedRoleIDs.length
            # note category is restricted, only return it if the roleIDs match up
            if $rootScope.roleData?.roleIDs and _.intersection(restrictedRoleIDs, $rootScope.roleData.roleIDs).length
              filteredNotes.push n
          else
            # no restrictions, everyone can see it
            filteredNotes.push n
        else
          filteredNotes.push n
        return filteredNotes
      , []


    # Note setup
    getNotes = ->
      return new Promise (resolve, reject) ->
        if $filter("permissionVisible")($scope.viewNotesPermission)
          $http.get("/api/profiles/#{profile.id}/notes").then (result) ->
            return resolve(result.data) || []
          .catch (err) ->
            return reject(err)
        else return resolve([])

    initNotes = ->
      getNotes().then (loadedNotes) ->
        filteredNotes = applyPermissions(loadedNotes)
        _.map filteredNotes, (note) ->
          return unless note.file
          Images.getForProfile($scope.profile.id, note.file).then (urls) ->
            note.url = urls[0]
            note.urls = urls
        $scope.notes = filteredNotes
        $scope.priorityNotes = _.filter $scope.notes, (n) -> n.priority == 'High'
      .catch (err) ->
        $scope.notes = []

    initNotes()

    $scope.noteFilter = (note) ->
      return note if $scope.showDeactivated or !note.deactivated

    $scope.noteOrderComparator = (note) ->
      # determines note order based on priority status and created date
      # since high priority must display first, we give it a negative order
      # regular notes still need to display in desc order, so we use the inverse
      created = new Date(note.created).getTime()
      order = if note.priority == 'High' then -created else (1/created)
      return order

    $scope.newNote =
      profileID: profile.id
      providerID: $scope.providerID
      userID: session.userID
      priority: "Normal"

    $scope.noteCategories = []
    _.each $scope.organization.properties.noteCategories, (c) ->
      if $scope.organization.properties?.noteCategoryRestrictions?[c] and !$scope.operator
        $scope.noteCategories.push { label: c, value: c } if _.intersection($scope.organization.properties.noteCategoryRestrictions[c], $rootScope.roleData.roleIDs).length
      else
        $scope.noteCategories.push { label: c, value: c }

    $scope.priorities = _.map ["Low", "Normal", "High"], (p) -> { label: p, value: p }

    # Note upload function
    $scope.onUpload = (content) ->
      if content.error
        $scope.uploadError = true
        $timeout ->
          $scope.uploadError = false
        , 2000
        return flash content.error

      $scope.uploadSuccess = true
      $timeout ->
        $scope.uploadSuccess = false
      , 2000
      $scope.newNote.file = content.key
      $scope.filename = content.filename

    $scope.clearUpload = ->
      delete $scope.newNote.file
      $scope.filename = null

    # Edit a note
    $scope.edit = (note) ->
      $scope.newNote = new Note(note)
      $scope.createNote = true

    # Cancel editing a note
    $scope.cancelEdit = ->
      $scope.newNote =
        body: ""
        category: ""
        file: ""
        priority: "Normal"
        profileID: profile.id
        userID: session.userID
        providerID: $scope.providerID
      $scope.createNote = false

    # Delete (close) a note
    $scope.deleteNote = (note) ->
      window.swal
        title: "Confirm Note Closing"
        text: "Are you sure you want to close this note?"
        showCancelButton: true
        confirmButtonText: "Yes, close it"
      , ->
        note.deactivated = new Date()
        new Note(note).save().then (note) ->
          flash "Note closed"

    # Reopen a note
    $scope.reactivateNote = (note) ->
      window.swal
        title: "Confirm Note Re-Opening"
        text: "Are you sure you want to reopen this note?"
        showCancelButton: true
        confirmButtonText: "Yes, open it"
      , ->
        note.deactivated = null
        new Note(note).save().then (note) ->
          flash "Note re-opened"


    # Save a note
    $scope.saveNote = ->
      new Note($scope.newNote).save().then (note) ->
        note.user = session.user
        note.provider = $scope.provider
        if note.file
          Images.getForProfile($scope.profile.id, note.file).then (urls) ->
            note.url = urls[0]
            note.urls = urls
        if $scope.newNote.id
          $scope.notes = _.reject $scope.notes, (n) -> n.id is $scope.newNote.id
        $scope.notes.unshift note
        if note.priority == 'High' then $scope.priorityNotes.push note
        $scope.newNote =
          body: ""
          category: ""
          file: ""
          priority: "Normal"
          profileID: profile.id
          userID: session.userID
          providerID: $scope.providerID
        $scope.createNote = false
        $scope.filename = null


    profileJump = -> $scope.profileJump or "registrations"

    $scope.removeMergeRequest = ->
      $scope.removingMerge = true
      delete $scope.profile.properties.mergeRequested
      $scope.profile.save().then ->
        $scope.removingMerge = false
        $scope.show.mergeRequest = false
        flash "Merge Request Removed"

    $scope.$watch "state.current.name", (state, stale) ->
      if state is "manager.profiles.profile" then $state.go ".#{profileJump()}"
    , true
