lib.registerState "manager.healthLog.statistics",
  url: "/statistics"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Health Log Statistics"
    logEntries: ($http, organization) ->
      fourMonthsAgo = moment().subtract(4, "months").startOf("month").format "YYYY-MM-DD"
      url = "/api/organizations/#{organization.id}/log-entries"
      $http.get("#{url}?filters=[logEntries].created(date>=:#{fourMonthsAgo})").then (results) ->
        results.data
  templateUrl: "states/manager/health-log/statistics/statistics.state.html"
  controller: ($http, $scope, $timeout, logEntries, providers) ->

    # Logic duplicated from browse state:
    $scope.logEntries = []
    assignProvider = (entry) ->
      if entry.providerID
        entry.provider = providers.find (p) -> p.id == entry.providerID

    addNewEntry = (collEntry) ->
      entry = collEntry.logEntries
      entry.profile = collEntry.profiles
      assignProvider(entry)
      $scope.logEntries.push(entry)

    logEntries.forEach(addNewEntry)

    # Used for ugly .refresh() $timeout hack to resize properly
    $scope.api = {
      complaints: {}
      visits: {}
      providers: {}
      locations: {}
      sex: {}
      dispositions: {}
    }

    # Dates
    $scope.today      = moment().format "YYYY-MM-DD"

    # Duplicated from collections filter for logEntries in the resolve
    # Used to restrict the date filtering for lower range of entries
    $scope.lowerDateLimit = moment().subtract(4, "months").startOf("month").format "YYYY-MM-DD"

    $scope.presetTimes = [
      { label: "Today",         value: "today" }
      { label: "Yesterday",     value: "yesterday" }
      { label: "Last Week",     value: "lastWeek" }
      { label: "Last Month",    value: "lastMonth" }
      { label: "Last 7 Days",   value: "last7Days" }
      { label: "Last 30 Days",  value: "last30Days" }
    ]

    $scope.presetTimes =
      today:
        start:  $scope.today
        end:    $scope.today
      yesterday:
        start:  moment().subtract(1, "days").format "YYYY-MM-DD"
        end:    moment().subtract(1, "days").format "YYYY-MM-DD"
      last7Days:
        start:  moment().subtract(7, "days").format "YYYY-MM-DD"
        end:    $scope.today
      last30Days:
        start:  moment().subtract(30, "days").format "YYYY-MM-DD"
        end:    $scope.today
      lastWeek:
        start:  moment().subtract(1, "week").startOf("week").format "YYYY-MM-DD"
        end:    moment().subtract(1, "week").endOf("week").format "YYYY-MM-DD"
      lastMonth:
        start:  moment().subtract(1, "month").startOf("month").format "YYYY-MM-DD"
        end:    moment().subtract(1, "month").endOf("month").format "YYYY-MM-DD"


    $scope.setPreset = (time) ->
      return unless $scope.presetTimes[time]
      $scope.startDate  = $scope.presetTimes[time].start
      $scope.endDate    = $scope.presetTimes[time].end

    $scope.setPreset "last30Days"

    $scope.startDateFilter = $scope.startDate
    $scope.endDateFilter = $scope.endDate

    finishLoading = ->
      $scope.startDate = $scope.startDateFilter
      $scope.endDate = $scope.endDateFilter
      $scope.loadingMore = false

    $scope.getStatistics = ->
      $scope.loadingMore = true
      if moment($scope.startDateFilter) >= moment($scope.lowerDateLimit)
        finishLoading()
      else
        # Load entries with ids lower than last id but after start date
        url = "/api/organizations/#{$scope.organization.id}/log-entries"
        filters = "[logEntries].created(date>=:#{$scope.startDateFilter})"
        # If there aren't any entries, there can't be an earliest one to filter on
        if $scope.logEntries.length > 0
          filters += "|[logEntries].id(<:#{$scope.logEntries[$scope.logEntries.length - 1].id})"
        $http.get("#{url}?filters=#{filters}").then (result) ->
          result.data.forEach(addNewEntry)
          $scope.lowerDateLimit = $scope.startDateFilter
          finishLoading()

    # Color range
    $scope.colors = [
      "#6E991F"
      "#1F77B4"
      "#FF7F0E"
      "#2CA02C"
      "#D62728"
      "#9467BD"
      "#8C564B"
      "#E377C2"
      "#7F7F7F"
      "#BCBD22"
      "#17BECF"
    ]
    $scope.colorFn = -> (d, i) -> $scope.colors[i % $scope.colors.length]
    $scope.config = { refreshDataOnly: true }

    $scope.charts =
      complaints: {}
      visits: {}
      providers: {}
      locations: {}
      sex: {}
      dispositions: {}

    # Base config shared across charts
    chartConfig =
      transitionDuration: 750
      showLegend: false
      height: 350
      margin: { top: 20, right: 100, bottom: 100, left: 65 }
      x: (d) -> d[0]
      y: (d) -> +d[1]
      color: $scope.colorFn()
      xAxis: { showMaxMin: false, staggerLabels: false }
      yAxis: { showMaxMin: false }

    # Top complaints configuration
    complaintConfig = {
      type: "lineChart"
      height: 450
      clipVoronoi: false
      showLegend: true
      useInteractiveGuideline: true
      x: (d) -> new Date(d.x)
      y: (d) -> d.y
      xScale: d3.time.scale.utc()
      # getTime and getTimezoneOffset nonsense is to adjust for localization
      # new Date('9999-99-99') will parse as '9999-99-99 T00:00.000Z GMT+0000' utc,
      # which results in '9999-99-98 T00:00.000Z GMT-0400' when localized
      # and makes everything look a day off
      xAxis: { axisLabel: "Date", tickFormat: (d) -> d3.time.format('%m/%d') new Date(d.getTime() + d.getTimezoneOffset()*60000) }
      yAxis: { axisLabel: "Occurrences", axisLabelDistance: 5 }
    }

    # Generic bar chart config, it seems
    providerConfig =
      type: "discreteBarChart"
      xAxis: { rotateLabels: -45 }
      height: 400

    # Visits/day config
    visitConfig =
      type: "lineChart"
      x: (d) -> new Date(d[0])
      xScale: d3.time.scale.utc()
      # see comment in complaintConfig for explanation of date nonsense
      xAxis: { axisLabel: "Date", tickFormat: (d) -> d3.time.format('%m/%d') new Date(d.getTime() + d.getTimezoneOffset()*60000) }
      yAxis: { axisLabel: "Visits", axisLabelDistance: 5 }

    sexConfig =
      type: "pieChart"
      showLabels: true
      x: (d) -> d.label
      y: (d) -> d.value

    # Chart setup
    $scope.charts.visits.options = { chart: _.assign _.cloneDeep(chartConfig), visitConfig }
    $scope.charts.visits.data = [{ key: "Visits" }]

    $scope.charts.complaints.options = { chart: _.assign _.cloneDeep(chartConfig), complaintConfig }
    $scope.charts.complaints.data = []

    $scope.charts.providers.options = { chart: _.assign _.cloneDeep(chartConfig), providerConfig }
    $scope.charts.providers.data = [{ key: "Provider" }]

    $scope.charts.locations.options = { chart: _.assign _.cloneDeep(chartConfig), providerConfig }
    $scope.charts.locations.data = [{ key: "Location" }]

    $scope.charts.sex.options = { chart: _.assign _.cloneDeep(chartConfig), sexConfig }
    $scope.charts.sex.data = []

    $scope.charts.dispositions.options = { chart: _.assign _.cloneDeep(chartConfig), providerConfig }
    $scope.charts.dispositions.data = [{ key: "Disposition" }]


    # Ugly container hack
    $scope.$on "$viewContentLoaded", ->
      $timeout ->
        $scope.api.providers.refresh()
        $scope.api.locations.refresh()
        $scope.api.visits.refresh()
        $scope.api.sex.refresh()
        $scope.api.dispositions.refresh()
        $scope.api.complaints.refresh()
      ,100


    # Create data when date range changes
    mapData = ->
      # Modify date range if the given range is going to kill the loop below
      if $scope.endDate > $scope.today then $scope.endDate = $scope.today
      if $scope.startDate > $scope.endDate then $scope.startDate = $scope.endDate

      #
      # Visits/time - line
      #
      visitDates = _.groupBy $scope.logEntries, (l) ->
        date = l.logged or l.created
        moment(new Date(date)).utc().format('YYYY-MM-DD')

      time = _.clone $scope.startDate

      while time <= $scope.endDate
        visitDates[time] ?= []
        time = moment(time).add(1, "day").format "YYYY-MM-DD"

      visitValues = _.reduce(visitDates, (result, array, date) ->
        if date < $scope.startDate then return result
        if date > $scope.endDate then return result
        result.push [date, array.length]
        result
      , [])
      $scope.charts.visits.data[0].values = _.sortBy visitValues, (v) -> v[0]

      entries = _.filter $scope.logEntries, (e) ->
        createdDt = moment.utc(e.created).format('YYYY-MM-DD')
        createdDt <= $scope.endDate and createdDt >= $scope.startDate

      #
      # Top complaints - cumulative line
      #
      complaints = _.groupBy entries, "complaint"
      delete complaints["null"]
      complaints = _.map complaints, (array, complaint) -> [complaint, array.length]
      topComplaints = _.take (_.sortBy complaints, (c) -> c[1]).reverse(), 5
      topComplaints = _.map topComplaints, (t) -> t[0]

      # Chart expects an array of series objects
      complaintData = _.reduce topComplaints, (d, complaint) ->
        d[complaint] = { values: [], key: complaint }
        d
      , {}

      # Each complaint data point is represented as {x, y} pairs
      # We can use visitDates to aggregate this data
      time = _.clone $scope.startDate
      while time <= $scope.endDate
        _.forIn complaintData, (data, complaintName) ->
          dayAndComplaint = { x: time, y: 0}
          _.map visitDates[time], (entry) ->
            if entry.complaint is complaintName then dayAndComplaint.y += 1
          data.values.push dayAndComplaint
        time = moment(time).add(1, "day").format "YYYY-MM-DD"
      $scope.charts.complaints.data = _.values complaintData

      #
      # Top providers - bar
      #
      groupedProviders = _.groupBy entries, (e) ->
        if !e.provider?.givenName then return
        "#{e.provider?.givenName} #{e.provider?.familyName}"
      delete groupedProviders["undefined"]
      groupedProviders = _.map groupedProviders, (array, provider) -> [provider, array.length]
      $scope.charts.providers.data[0].values = (_.sortBy groupedProviders, (c) -> c[1]).reverse()

      #
      # Top locations - bar
      #
      locations = _.groupBy entries, (e) -> e.location
      delete locations["null"]
      locations = _.map locations, (array, location) -> [location, array.length]
      $scope.charts.locations.data[0].values = _.take (_.sortBy locations, (l) -> l[1]).reverse(), 15

      #
      # Male vs Female - pie
      #
      sexes = _.groupBy entries, (e) -> e.profile.sex
      delete sexes["null"]
      sexes = _.map sexes, (array, sex) -> { label: sex, value: array.length }
      $scope.charts.sex.data = sexes

      #
      # Dispositions - bar
      #
      dispositions = _.groupBy entries, (e) -> e.disposition
      delete dispositions["null"]
      dispositions = _.map dispositions, (array, disposition) -> [disposition, array.length]
      $scope.charts.dispositions.data[0].values = _.take (_.sortBy dispositions, (d) -> d[1]).reverse(), 15

      $scope.startDateFilter = $scope.startDate
      $scope.endDateFilter = $scope.endDate


    $scope.$watch "[startDate, endDate]", (good) ->
      return unless _.every good
      mapData()

      # Check if a preset is active
      _.map $scope.presetTimes, (time, key) ->
        $scope.presetTimes[key].active = false
        if time.start is $scope.startDate and time.end is $scope.endDate
          $scope.presetTimes[key].active = true

    , true
