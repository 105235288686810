angular.module("dn").directive "qbEmergencyContacts", ->
  templateUrl: "directives/builder/modules/emergency-contacts/qb-emergency-contacts.directive.html"
  replace: true
  restrict: "A"
  scope:
    module: "=qbEmergencyContacts"
    mode: "@"
  controller: ($scope) ->
    $scope.module.metadata ?= {}
    $scope.module.metadata.contacts ?= []
    $scope.module.label ?= "Emergency Contacts"
    $scope.module.required ?= true

    $scope.addContact = ->
      $scope.module.metadata.contacts.push
        label: ""
        required: true
        identifier: uuid.v4()
        phonesDisplayed: "0"
        phonesRequired: "0"
        emailsDisplayed: "0"
        emailsRequired: "0"
        addressesDisplayed: "0"
        addressesRequired: "0"

    $scope.numbers = _.map _.range(0, 5), (r) -> { label: r, value: r }
    $scope.remove = (index) -> $scope.module.metadata.contacts.splice(index, 1)
    if not $scope.module.metadata.contacts.length then do $scope.addContact
