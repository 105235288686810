angular.module('dn').filter('truncate', () => (input, length = 250) => {
  if (!input || !_.isString(input)) return input;

  let truncated = input.slice(0, length);
  if (truncated.length === input.length) return input;

  if (String.prototype.lastIndexOf())
    truncated = input.slice(0, truncated.lastIndexOf(' '));

  truncated += '...';
  return truncated;
});
