angular.module("dn").directive "displayModule", ->
  templateUrl: "directives/display-module/display-module.directive.html"
  restrict: "A"
  scope:
    question: "="
    items: "="
    profile: "="
    type: "@"
    showMedUpload: "@"
    rotatePermissions: "="
  controller: ($scope, $http, WhatDivision, Images) ->

    $scope.pieces = _.cloneDeep $scope.items
    $scope.division = WhatDivision

    findImages = ($scope) ->
      return unless $scope
      if $scope.images then return $scope.images
      return findImages $scope.$parent

    $scope.images = findImages $scope
    $scope.show = {}
    $scope.showModal = (id, index) ->
      $scope.show[id] ?= []
      $scope.show[id][index] = true

    formatData = () ->
      if $scope.type is "primaryProviders"
        newPieces = []
        grouped = _.keyBy $scope.pieces, "identifier"
        _.map $scope.question.metadata.providers, (p) ->
          if grouped[p.identifier]
            grouped[p.identifier].label = p.label
            newPieces.push grouped[p.identifier]
        $scope.pieces = newPieces

      if $scope.type is "insurances"
        newPieces = []
        grouped = _.keyBy $scope.pieces, "identifier"
        _.map $scope.question.metadata.insurances, (p) ->
          if grouped[p.identifier]
            grouped[p.identifier].label = p.label
            newPieces.push grouped[p.identifier]
        $scope.pieces = newPieces
        _.map $scope.pieces, (i) ->
          return unless i.file?.length
          $scope.insuranceImages ?= {}
          Images.getForProfile($scope.profile.id, i.file).then (urls) ->
            $scope.insuranceImages[i.id] = {
              urls: urls
              value: i.file
              resource: i
              id: i.id
            }
          if $scope.images then $scope.images[$scope.question.stepID] = true

      if $scope.type is "medications"
        # damn strings for bools. damn angular...
        if $scope.showMedUpload is "true" then $scope.reallyShowMedUpload = true
        if $scope.showMedUpload is "false" then $scope.reallyShowMedUpload = false
        $scope.pieces = _.reject($scope.pieces, 'otcID')
        _.map $scope.pieces, (medication) ->
          medication.times = _.filter (_.keys medication.timesTaken), (t) ->
            medication.timesTaken[t]
          medication.times = medication.times.join ", "
          medication.days = _.filter (_.keys medication.daysTaken), (d) ->
            medication.daysTaken[d]
          medication.days = medication.days.join ", "
          medication.frequency = window.lib.meds.findFrequency(medication)

        if $scope.profile.properties.medicationConfirmationFile and $scope.profile.has.medications?.value?.toString() is "true"
          file = $scope.profile.properties.medicationConfirmationFile
          Images.getForProfile($scope.profile.id, file).then (urls) ->
            $scope.confirmationUrls = urls
            $scope.confirmationImages =
              urls: urls
              value: file
              resource: $scope.profile
            if $scope.images then $scope.images[$scope.question.stepID] = true

      if $scope.type is "multi-auth"
        $scope.sections = _.map $scope.question.metadata.sections, (section, index) ->
          section = { label: section.label, status: '-', date: '-' }
          answer = $scope.question.answer?.metadata?.sections?[index]
          if answer
            ansArr = answer.split('|')
            section.status = ansArr[0].capitalize()
            section.date = moment(new Date(ansArr[1])).format('MM/DD/YYYY hh:mm a')
          return section

        $scope.signatures = _.map $scope.question.metadata.signatures, (signature, index) ->
          signature = { label: signature.label, name: '-', date: '-', relationship: '-'}
          answer = $scope.question.answer?.metadata?.signatures?[index]
          if answer
            ansArr = answer.split('|')
            signature.name = ansArr[0]
            signature.date = moment(new Date(ansArr[2])).format('MM/DD/YYYY hh:mm a')
            signature.relationship = ansArr[1] if ansArr[1]
          return signature

      if $scope.type is "otc"
        if $scope.profile.properties.otcMedicationConfirmationFile
          file = $scope.profile.properties.otcMedicationConfirmationFile
          Images.getForProfile($scope.profile.id, file).then (urls) ->
            $scope.otcConfirmationUrls = urls
            $scope.otcConfirmationImages =
              urls: urls
              value: file
              resource: $scope.profile
            if $scope.images then $scope.images[$scope.question.stepID] = true

      if $scope.type is "immunizations"
        rows = []
        max = 0
        uploadOnly = false
        columns = []

        # Map rows and columns for the table
        _.map $scope.question.metadata.doses, (dose) ->
          return unless dose.included
          if dose.doses > max then max = dose.doses
          rows.push { name: dose.name, key: dose.key }
        _.times max, (n) -> columns.push "Dose #{n + 1}"

        # Download images if they have 'em
        if $scope.question.metadata.inputType is "upload" then uploadOnly = true
        file = _.filter($scope.pieces, (i) -> i.file?.length)?[0]
        if file
          Images.getForProfile(file.profileID, file.file).then (urls) ->
            $scope.immImages =
              urls: urls
              value: file.file
              resource: file
              timestamp: moment(file.updated).format('MMM D, YYYY @ hh:mm:ss A')
            if $scope.images then $scope.images[$scope.question.stepID] = true

        imms = _.groupBy $scope.pieces, "name"
        _.forIn imms, (set, name) -> imms[name] = _.sortBy(set, "doseNumber")
        $scope.imms = imms

        $scope.immTable =
          rows: rows
          columns: columns
          uploadOnly: uploadOnly


      if $scope.type is "emergencyContacts"
        newPieces = []
        grouped = _.keyBy $scope.pieces, "identifier"
        _.map $scope.question.metadata.contacts, (p) ->
          if grouped[p.identifier]
            grouped[p.identifier].label = p.label

            _.map grouped[p.identifier].addresses, (a) ->
              if (_.isPlainObject(a.value)) then a.value = a.value.address

            newPieces.push grouped[p.identifier]
        $scope.pieces = newPieces

      if $scope.type is "encrypted"
        $scope.fetchAnswer = ->
          $http.get("/api/profiles/#{$scope.profile.id}/encrypted/#{$scope.question.answer.id}").then(
            (result) ->
              $scope.decryptedAnswer = atob(result.data)
          , (err) ->
            swal "Error", "There was an error loading this answer", "error"
          )



      if $scope.type is "dimensions"
        $scope.dimension = if $scope.pieces then $scope.pieces[0] else {}
        do ->
          return unless $scope.dimension?.height and $scope.dimension?.weight
          if $scope.dimension.heightUnits in ["english", "in"]
            $scope.dimension.feet = Math.floor($scope.dimension.height / 12)
            $scope.dimension.inches = $scope.dimension.height % 12
            $scope.dimension.cm = Math.floor($scope.dimension.height * 2.54)
          else
            $scope.dimension.feet = Math.floor(($scope.dimension.height * 0.393701) / 12)
            $scope.dimension.inches = Math.floor(($scope.dimension.height * 0.393701) % 12)
            $scope.dimension.cm = $scope.dimension.height

          if $scope.dimension.weightUnits in ["english", "lbs"]
            $scope.dimension.lbs = $scope.dimension.weight
            $scope.dimension.kg = Math.floor($scope.dimension.weight * 0.453592)
          else
            $scope.dimension.kg = $scope.dimension.weight
            $scope.dimension.lbs = Math.floor($scope.dimension.weight * 2.20462)



    $scope.$watch('items', () ->
      $scope.pieces = _.cloneDeep $scope.items
      formatData()
      $scope.$apply()
    )
