lib.registerState "manager.settings.general.metadata",
  url: "/metadata"
  templateUrl: "states/manager/settings/general/metadata/metadata.state.html"
  controller: ($scope, group, organization, $timeout, $http, flash, session) ->
    $scope.group = group
    $scope.isEmpty = _.isEmpty
    $scope.isOperator = session.access?.operator

    $scope.newData = { values: [] }
    $scope.add = (data) ->
      $scope.group.metadata[data.key] = {
        label: data.label
        values: data.values
      }

      $scope.group.save()
      flash "Tags Saved"
      $scope.addDialog = false
      $timeout ->
        $scope.newData = { values: [] }


    $scope.edit = (key) ->
      $scope.newData = $scope.group.metadata[key]
      $scope.newData.key = key
      $scope.addDialog = true

    $scope.delete = (key) ->
      window.swal
        type: "warning"
        title: "Clear Tags?"
        text: """
          Do you want to clear this tag for all
          profiles in #{organization.name}?
        """
        showCancelButton: true
        confirmButtonText: "Yes, clear for everyone"
        allowOutsideClick: true
        closeOnConfirm: true
        closeOnCancel: true
      , (wantToClear) ->
        if wantToClear
          body = { key: key }
          $http.post("/api/organizations/#{organization.id}/clear-metadata", body).then (result) ->
            flash "\"#{key}\" Cleared For #{result.data.length} Profiles"
          delete $scope.group.metadata[key]
          $scope.group.save()

    # tags can't really be deactivated
    # but this will attach a deactivated property to the tag
    # so it can be hidden in-app without deleting it entirely
    $scope.deReactivate = (key, deactivated) ->
      if deactivated
        window.swal
          type: "warning"
          title: "Reactivate Tag?"
          text: """
            Reactivating this tag will enable it as a condition in the list builder, and it will print out in reports.
             Continue?
          """
          closeOnConfirm: true
          confirmButtonText: "Reactivate"
          showCancelButton: true
         , (isConfirm) ->
          if isConfirm
            $scope.group.metadata[key].deactivated = false
            flash "\"#{key}\" has been reactivated"
            $scope.group.save()
      else
        window.swal
          type: "warning"
          title: "Deactivate Tag?"
          text: """
            Deactivating this tag will hide it as a condition in the list builder, and it will not print out in reports.
             Continue?
          """
          closeOnConfirm: true
          confirmButtonText: "Deactivate"
          showCancelButton: true
         , (isConfirm) ->
          if isConfirm
            $scope.group.metadata[key].deactivated = true
            flash "\"#{key}\" has been deactivated"
            $scope.group.save()

    $scope.addValue = (key, value) ->
      $scope.newData.values.push value
      $scope.newValue = ""

    $scope.remove = (key) ->
      delete $scope.group.metadata[key]
      $scope.group.save()
