lib.registerState "manager.profiles.profile.users",
  url: "/users"
  templateUrl: "states/manager/profiles/profile/users/profile-users.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Users"
    users: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/users").then (result) -> result.data
    invited: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/invited").then (result) -> result.data
    loggedUser: (session, organization) ->
      return {
        userID: session.user.id
        name: session.user.givenName + " " + session.user.familyName
        orgName: organization.name
      }
    season: ($http, organization) ->
      $http.get("/api/organizations/#{organization.id}/seasons").then(
        (result) -> _(result.data).filter({ "archived": null }).head()
      , (err) -> return null
      )

  controller: ($scope, users, invited, $http, $stateParams, $timeout, flash, $state, organization, loggedUser, season) ->

    $scope.users = _.reject users, (u) -> u.profileUser.deactivated
    $scope.deactivatedUsers = _.filter users, (u) -> u.profileUser.deactivated
    $scope.invited = invited
    $scope.organization = organization
    $scope.season = season

    # get info on logged-in user in case they add a payment method for someone
    $scope.loggedUser = loggedUser

    $scope.campgramsOnlyUser = false

    resetForm = ->
      $scope.addDialog = false
      $scope.email = ""
      $scope.campgramsOnlyUser = false

    $scope.reactivateUser = (email) ->
      $scope.email = email
      $scope.addUser()

    $scope.addUser = ->
      $scope.adding = true
      return unless $scope.email
      email = $scope.email.toLowerCase().trim()
      return resetForm() if _.includes(_.map(invited, "inviteEmail"), email)

      profileUser =
        inviteEmail: email
        profileID: $stateParams.profileID
        forceWelcome: true
        campgramsOnly: $scope.campgramsOnlyUser
      $http.post("/api/profiles/#{$stateParams.profileID}/users", profileUser).then (result) ->
        resetForm()
        if result.data is "Deactivated User"
          $scope.adding = false
          return window.swal
            title: "Deactivated Email"
            type: "warning"
            text: "The email address entered has been deactivated and cannot be added."
        if result.data.userID
          $http.get("/api/profiles/#{$stateParams.profileID}/users").then (result) ->
            $scope.deactivatedUsers = _.filter result.data, (r) -> r.profileUser.deactivated
            $scope.users = _.reject result.data, (r) -> r.profileUser.deactivated
          $scope.adding = false
        else
          $scope.invited.push result.data
        $scope.adding = false
      .catch (err) ->
        flash "Unable to add user"
        return $scope.adding = false

    $scope.unInvite = (id) ->
      $http.delete("/api/profiles/#{$stateParams.profileID}/users/#{id}").then (result) ->
        $scope.invited = _.reject $scope.invited, (i) -> i.id is id
        flash "Removed!"
        $state.reload "manager.profiles.profile.users"
