lib.registerState("manager.profiles.profile.registrations", {
  url: '/registrations',
  templateUrl: 'states/manager/profiles/profile/registrations/profile-registrations.state.html',
  resolve: {
    title: function($rootScope) {
      $rootScope._title = "Registrations";
    },
    profileProtectionPlans: function($http, $state, profile) {
      return $http.get(`/api/profiles/${profile.id}/protection-plans`).then(
        function(success) {
          return profile.protectionPlans = success.data;
        }, function(err) {
          window.swal({
            type: "error",
            title: "Error",
            text: "There was an error retrieving protection plans. Please try again.",
            confirmButtonText: "Reload",
            showCancelButton: true,
            cancelButtonText: "Go Back",
            allowOutsideClick: false,
            allowEscapeKey: false
          }, function(confirmed) {
            if (confirmed)
              return $state.reload();
            else
              return $state.go("^.^");
          });
        });
    },
    registrations: function(ProfileRegData, profile, indexedOrg) {
      ProfileRegData.indexedOrg = indexedOrg;
      ProfileRegData.profile = profile;
      const params = { params: { profileID: profile.id } };
      return ProfileRegData.fetchResources('Registration', params)
        .catch(() => swal('Error Loading Registrations', 'Something went wrong. Please try again later.', 'error'))
        .then(() => ProfileRegData.fetchResources('Crebit', params))
        .catch(() => swal('Error Loading Financials', 'Registration financial information is temporarily unavailable.', 'error'));
    },
  },
  params: {
    registrationsTab: 'registrations'
  },

  controller: function ($http, $scope, $state, $filter, ProfileRegData, indexedOrg, profile, session) {

    /* Init to empty array to make the multiReg directive work */
    ProfileRegData.indexedOrg = indexedOrg;
    ProfileRegData.profile = profile;
    $scope.registerable = [];
    $scope.sessionAccess = { groups: session.access.groups, oper: session.access.operator };

    $scope.registrationsTabs = [
      {label: 'Registrations', value: 'registrations'},
      {label: 'Protection Plans', value: 'protection-plans', permissions: { view_protection_plans: 'allow' }},
    ]

    $scope.registrationsTab = $state.params.registrationsTab;

    $scope.showBulkReg = false;
    $scope.toggleBulkReg = function () {
      return $scope.showBulkReg = !$scope.showBulkReg;
    }

    /* Get regs and prot plans for profile, get it all set up for display */
    initRegistrations();

    /*
    Get roles assigned to profile
    TODO: make it possible to chain off of initRegistrations and
      only run the role logic if the profile has an active provider registration
    */
    getRoles((err, roles) => {
      if (err) {
        /* Don't do anything. Impact is so small it's not worth showing the users */
      } else if (roles && roles.profile && roles.profile.length && roles.org && roles.org.length) {
        $scope.profileRoleNames = roles.profile.reduce((roleArray, profileRole) => {
          const orgRoleMatch = roles.org.find(orgRole => orgRole.id === profileRole.roleID && !profileRole.deactivated);
          if (orgRoleMatch && !roleArray.includes(orgRoleMatch.name)) roleArray.push(orgRoleMatch.name);
          return roleArray;
        }, []).join(', ');
      }
    });

    function initRegistrations() {
      $scope.registrations = ProfileRegData.registrations;
      $scope.deactivatable = Object.entries($scope.registrations).some(([regType, regs]) => {
        return regType !== 'deactivated' && regs.length;
      });
      initProtectionPlans();
    }

    (function() {
      const base = `Select an existing registration to view additional details`
      const end =  $filter('permissionVisible')({ "profile_registrations": "edit" })
        ? `, adjust add-ons and coupons, and deactivate a registration.
          You may also manually add a new registration for ${profile.givenName} by clicking the
          <strong>New Registration</strong> button below.`
        : "."
      $scope.instructionText = base + end;
    })();

    function initProtectionPlans() {
      // Get keys so we can ngRepeat our tables. For whatever reason
      // ng-repeat="phase in Object.keys(protectionPlans)" doesn't work
      $scope.protPlanPhases = ["Upcoming", "Deactivated", "Current", "Past"];

      $scope.protectionPlans = {
        "Upcoming": protectionFilter("future"),
        "Deactivated": protectionFilter("deactivated"),
        "Current": protectionFilter("present"),
        "Past": protectionFilter("past")
      }

      // Use this it check which instruction text we should show.
      $scope.protPlanPurchased = $scope.protPlanPhases.some((key) => {
        return $scope.protectionPlans[key].length > 0;
      });
    }

    function protectionFilter(phase) {
      return $scope.profile.protectionPlans.filter(function(plan) {
        let matchedReg = $scope.registrations[phase].find(function(reg) {
          return reg.id === plan.registrationID;
        });
        if (matchedReg && !plan.deactivated) {
          // Add some properties for displaying names of groups
          plan.groupName = matchedReg.group.name;
          plan.parentGroupName = (matchedReg.group.parent && matchedReg.group.parent.name ? matchedReg.group.parent.name : undefined);
          return true;
        }
      });
    }

    function getRoles(returnRoles) {
      return async.parallel({
        org: function(done) {
          return $http.get(`/api/organizations/${ $scope.organization.id }/roles`).then((success) => {
            return done(null, success.data || []);
          }).catch(err => done(err, []) )
        },
        profile: function(done) {
          return $http.get(`/api/profiles/${ $scope.profile.id }/roles`).then((success) => {
            return done(null, success.data || []);
          }).catch(err => done(err, []) )
        }
      }, returnRoles);
    }

  } // end controller
});
