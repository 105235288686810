lib.registerState("manager.profiles.profile.funk-notes", {
  url: '/campgrams',
  templateUrl: 'states/manager/profiles/profile/funk-notes/funk-notes.state.html',
  resolve: {
    title: function($rootScope) {
      $rootScope._title = "CampGrams";
    },
    funkNotes: function($http, profile) {
      return $http.get(`/api/profiles/${profile.id}/funk-notes?limit=10`)
        .then((res => res.data))
        .catch(err => {
          swal(
            'Error Loading CampGrams',
            'Something went wrong. If you need to see individual CampGrams, try again later.',
            'error'
          );
          return [];
        });
    }
  },

  controller: ($rootScope, $scope, $http, profile, funkNotes) => {
    $scope.organization = $rootScope.organization;
    $scope.funkNotes = funkNotes;

    $scope.resetEdit = resetEdit;
    $scope.funkEdit = resetEdit();

    // Enables the provider to see the print status when they have removed print status for the patient portal enabled.
    $scope.providerCampgramFeedOverride = true;

    // Enables ability to print a single funkNote from funk-note-feed directive
    $scope.printSingleFunkNote = true;

    $scope.tokenAction = [
      { label: 'Add', value: 'addTokens' },
      { label: 'Remove', value: 'removeTokens' },
      { label: 'Transfer', value: 'transferTokens' }
    ];

    function sendRequest(payload) {
      return $http.put('/api/profiles/' + profile.id + '/funk-tokens', payload).then((success) => {
        $scope.profile.tokens = success.data.tokens;
        window.swal({
          type: "success",
          title: "Transaction Successful",
          timer: 3000
        });
      }, (error) => {
        window.swal({
          type: "error",
          title: "Error",
          text: "There was an error performing your token transaction.\nReason: " + JSON.stringify(error.data || error)
        });
      });
    }

    $scope.tokenTransaction = function() {
      const payload = {
        type: $scope.funkEdit.transferType,
        quantity: $scope.funkEdit.quantity,
        fromProfile: profile.id,
        toProfileID: $scope.funkEdit.transferID || null,
      };
      resetEdit();
      return sendRequest(payload);
    };

    function resetEdit() {
      return {
        quantity: null,
        transferID: null,
        transferType: null,
      };
    };
  }
});
