Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "User", ($http, flash, $rootScope) ->

  User = new Resource
    route: "/api/users/:id"

  User.statusByEmail = (email) ->
    $http.get("/api/users/#{email}/status").then (response) -> response.data

  User.current = ->
    $http.get("/api/session").then (response) ->
      if not response.data.userID then return null
      $rootScope.orgScope = response.data.orgScope
      new User(response.data.userID).load().then (user) -> user

  User.prototype.authenticate = ->
    return $http.post("/api/users/authenticate", @).then (response) -> response.data

  User.deauthenticate = User.prototype.deauthenticate = ->
    $http.post("/api/users/deauthenticate").then ->
      flash "You've been successfully logged out"
      window.location = window.location.origin
    return

  User.getNotifications = User.prototype.getNotifications = ->
    $http.get("/api/users/#{@id}/notifications").then (result) -> result.data

  User
