lib.registerState('home', {
  url: '/home?i&r&e&bad-passport',
  templateUrl: 'states/home/home.state.html',
  resolve: {
    registration: ($stateParams, $http) => {
      if (!$stateParams.r) return null;
      let shortName = $stateParams.r.toLowerCase().split(/(\s|\+)/)[0];
      const alertErr = () => {
        return swal({
          type: 'error',
          title: 'Error',
          text: `
            We can\'t find registration information for <strong>"${shortName}"</strong>.
            <br><br>
            Check the URL you typed for mistakes and try again.
          `,
          html: true
        })
      };
      return $http.get(`/api/self-registration/${shortName}?noRegData=true`).then(
        (result) => result ? result.data : alertErr(),
        (err) => alertErr()
      );
    },
    invitation: function ($location, $q, $interval, ProfilesUser) {
      var d = $q.defer();
      if (!$location.search().i) return d.resolve(null);
      var inviteRequest = ProfilesUser.byInvite($location.search().i);
      inviteRequest.then(function (invite) {
        d.resolve(invite);
      });
      inviteRequest.catch(function () {
        d.resolve('ERROR');
      });
      return d.promise;
    },
    orgNameReset: ($rootScope) => $rootScope.organization = null
  },
  controller: ($location, $scope, $state) => {

    if ($state.current.name === 'home') {

      // Bad pw reset link? Redirect to pwreset state.
      if ($state.params['bad-passport']) {

        const email = decodeURIComponent($state.params.e);
        const badPassport = true;

        return $state.go('home.auth.pwreset', {email, badPassport})
      }

      return $state.go('home.auth');
    }

  }
});
