angular.module("dn").directive "tagSelector", ($timeout) ->
  templateUrl: "directives/input/types/tags/tags.directive.html"
  replace: true
  restrict: "E"
  scope:
    label: "@"
    placeholder: "@"
    choices: "="
    model: "="
    ngDisabled: "="
    createChoices: "@"

  link: (scope, elm, attrs) ->

    config =
      ariaLabel: scope.label or scope.placeholder
      placeholder: scope.placeholder
      persist: false
      delimiter: ","
      labelField: "label"
      searchField: ["label", "value"]
      sortField: "_selectorIndex"
      diacritics: true
      options: []

    if scope.createChoices
      config.create = (input) ->
        value: input,
        label: input

    $select = elm.selectize config

    $selector = $select[0].selectize

    # Add `aria-label` attribute to `.selectize-input` based on scope.label or scope.placeholder
    if $selector.settings.ariaLabel
      $selector.$control_input.attr('aria-label', $selector.settings.ariaLabel)

    # max num of options to render in the dropdown; default is 1000
    $selector.settings.maxOptions = 2000
    $selector.settings.placeholder = scope.placeholder
    $selector.updatePlaceholder()

    scope.$watch "choices", (choices) ->
      $timeout ->
        $selector.clearOptions()
        $selector.addOption _.map _.cloneDeep(choices), (choice, index) ->
          choice._selectorIndex = index
          choice
    , true

    scope.$watch "model", (fresh, stale) ->
      if stale && stale isnt fresh
        # Handle re-adding options to the dropdown after they have been un-selected.
        $selector.addOption _.map _.cloneDeep(scope.choices), (choice, index) ->
          choice._selectorIndex = index
          choice

      return if !fresh?.split or fresh is $selector.getValue()
      $timeout -> $selector.setValue fresh.split(",")

    scope.$watch "ngDisabled", (disabled, was) ->
      if disabled then $selector.disable()
      if not disabled and was then $selector.enable()
      return
    , true

    scope.$watch "placeholder", (fresh, stale) ->
      $selector.settings.placeholder = fresh or ''
      $selector.updatePlaceholder()

    return

  controller: ($scope) ->
