# Helper functions
filterMars = ($scope) ->
  $scope.filters ?= {}
  _.map $scope.mars, (mar, index) ->
    mar._hide = false
    mar.expanded = false
    return mar._hide = true unless takenToday(mar, $scope.date)
    if $scope.filters.search
      return mar._hide = true if !$scope.textFilter mar, $scope.filters.search
    if !$scope.filters.showPrevious
      return mar._hide = true if mar.id and !mar.error
    if !$scope.filters.showAsNeeded
      return mar._hide = true if mar.asNeeded
    if $scope.filters.showOnlyAsNeeded and $scope.filters.showAsNeeded
      return mar._hide = true unless mar.asNeeded
    if $scope.filters.slot and $scope.filters.slot isnt "All Times"
      return mar._hide = true if $scope.filters.slot isnt mar.slot
    if $scope.filters.onlyPending
      return mar._hide = true unless mar.pending

  # only used in the html. Shows 'X Medications for {{timeslot}}'
  # also governs display of 'No Medications in timeslot' display
  $scope.marCount = _.reduce $scope.mars, (count, mar) ->
    count = count += 1 unless mar._hide
    count
  , 0

checkPreviousDay = (orgID, date, groups, tags, $http, fn) ->
  $http.get("/api/organizations/#{orgID}/emars?date=#{date}&groups=#{groups}&tags=#{tags}&missing").then (result) ->
    fn null, result.data.missing

checkPreviousDays = (orgID, date, groups, tags, $http, fn) ->
  days = []
  _.times 3, (n) -> days.push moment(date).subtract(n + 1, "days").format "YYYY-MM-DD"
  days.reverse()

  async.map days, (day, fn) ->
    checkPreviousDay orgID, day, groups, tags, $http, (err, missing) ->
      fn err, (if missing then day else null)
  , fn

fetchMars = (orgID, date, groups, tags, filters, $http, $filter, $scope, Mar) ->
  # Can't fetch mars without permissions
  if !$filter('permissionVisible')({emar: 'view'})
    $scope.loading = false
    return

  configureSlots = (mars) ->
    # Setup our filter array
    activeSlots = _(mars).map((m) -> m.slot).uniq().compact().value()

    # Initialize slots
    slots =
      "Overnight":
        hour: 2
      "Early Morning":
        hour: 5
      "Breakfast":
        hour: 8
      "Late Morning":
        hour: 10
      "Lunch":
        hour: 12
      "Afternoon":
        hour: 15
      "Dinner":
        hour: 17
      "Evening":
        hour: 20
      "Bedtime":
        hour: 22

    # Filter the initial slots object based on the activeSlots array.
    $scope.slots = _.pick slots, activeSlots
    $scope.slots.keys = _.keys $scope.slots

    # Add in an "All Times" option for showing all MARs on a selected day.
    $scope.slots["All Times"] = { hour: -100 }
    $scope.slots.keys.unshift "All Times"

  $scope.firstLoad = true
  $scope.loading = true
  checkPreviousDays orgID, date, groups, tags, $http, (err, days) ->
    $scope.missingDays = _.compact days

  $http.get("/api/organizations/#{orgID}/emars?date=#{date}&groups=#{groups}&tags=#{tags}").then (result) ->
    configureSlots(result.data.mars)

    $scope.medications = _.mapValues result.data.medications, (medication) ->
      medication.prettySchedule = []
      medication.frequency = window.lib.meds.findFrequency(medication)
      _.each $scope.slots, (time, slot) ->
        return unless medication.timesTaken[slot]
        medication.prettySchedule.push slot

      medication.prettySchedule = medication.prettySchedule.join ", "
      if medication.daysTaken
        medication.prettyDays = _.mapKeys medication.daysTaken, (value, day) ->
          return day if value
        # mapKeys returns a new object, days with falsey values were given key = 'undefined'
        # omit the undefined, grab the keys(days), and join them for a pretty string
        medication.prettyDays = (_.keys(_.omit medication.prettyDays, "undefined")).join(", ")
      medication

    $scope.profiles = _.mapValues result.data.profiles, (profile) ->
      profile.has?.allergies?.anaphylaxis = _.some profile.allergies, (a) -> a.anaphylaxis
      profile


    $scope.providers = result.data.providers

    $scope.mars = _.map result.data.mars, (mar) ->
      mar = new Mar(mar)

      mar.profile = $scope.profiles[mar.profileID]
      mar.providerID ?= $scope.currentProvider.id
      mar.provider ?= $scope.currentProvider.givenName + " " + $scope.currentProvider.familyName if mar.providerID is $scope.currentProvider.id
      if !mar.provider
        mar.provider = $scope.providers[mar.providerID].givenName + " " + $scope.providers[mar.providerID].familyName if $scope.providers[mar.providerID]

      mar.medication = $scope.medications[mar.medicationID]
      mar.searchIndex = [mar.profile.givenName, mar.profile.familyName, mar.medication.name].join " "
      mar.specifyTime = mar.created and moment(mar.created).format("HH:mm") isnt moment(mar.recordedAt).format("HH:mm")
      mar.customTime = do ->
        return null unless mar.specifyTime and mar.recordedAt
        moment(mar.recordedAt).format("HH:mm")

      mar.quantity = do ->
        return 0 unless mar.medication.trackQuantity
        -1 * parseFloat mar.medication.doseValue

      if !mar.properties && mar.providerID && mar.recordedAt
        nameArray = mar.provider.split " " if mar.provider
        mar.properties ?=
          updates: [
            {
              initial: true
              initials: nameArray[0].charAt(0) + nameArray[1].charAt(0) if nameArray
              note: mar.note || ""
              providerName: mar.provider if mar.provider
              status: do ->
                return "Given" if !mar.notGiven && !mar.pending
                return "Not Given" if mar.notGiven
                return "Pending" if mar.pending
                return "Error" if mar.error
              time: mar.recordedAt
            }
          ]
      mar.properties ?= {}
      mar.properties.updates ?= []

      mar

    $scope.loading = false
    $scope.reloadMars = false
    filterMars $scope
    countRemaining $scope

    $scope.filters.slot = do ->
      if $scope.slot isnt "now"
        requested = _.find _.keys($scope.slots), (slot) -> _.kebabCase(slot) is $scope.slot
        return requested if requested

      now = moment()
      hour = parseInt(now.format("H")) + (parseInt(now.format("m")) / 60)
      closestHour = _.minBy _.values($scope.slots), (slot) -> Math.abs slot.hour - hour
      _.findKey $scope.slots, (slot) -> slot.hour is closestHour.hour

countRemaining = ($scope) ->
  $scope.missingSlots.length = 0
  _.each $scope.slots.keys, (slot) ->
    $scope.slots[slot]?.remaining = do ->
      remaining = _.reduce $scope.mars, (total, mar) ->
        total += 1 if !mar.id and (mar.slot is slot or slot is "All Times") and !mar.asNeeded and takenToday(mar, $scope.date)
        total
      , 0
    $scope.missingSlots.push slot if $scope.slots[slot]?.remaining and slot isnt "All Times"
  $scope.missingSlots = _.uniq $scope.missingSlots
  $scope.slots.choices = do ->
    choices = []
    _.each $scope.slots.keys, (slot) ->
      choices.push value: slot, label: "#{slot} (#{$scope.slots[slot].remaining})"
    choices

# checks if the current day of the week is included in medication.daysTaken
takenToday = (mar, date) ->
  if mar.medication.daysTaken
    today = moment(date).format("ddd")
    return false unless mar.medication.daysTaken[today]
  true

# End of helper functions, now we'll deal with creating the state.
# Thanks for hoisting my functions, CoffeeScript. Oh wait, you break that JS feature. Jerk.

lib.registerState "manager.emar",
  url: "/emar?date&slot&groups&tags"
  templateUrl: "states/manager/emar/emar.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "eMAR"
    currentProvider: (session) -> session.user
    filters: ($http, currentProvider, organization) ->
      userFilters = $http.get("/api/users/#{currentProvider.id}/filters?type=emar")
      groupFilters = $http.get("/api/groups/#{organization.id}/filters?type=emar")

      Promise.all([userFilters, groupFilters])
        .then (results) ->
          # Concat userFilters with shared groupFilters
          results[0].data.concat(results[1].data)
        .catch(() -> [])

  controller: ($scope, $state, $stateParams, $location, $http, currentProvider, Event, Mar, organization, provider, $filter, filters) ->
    $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission)

    $scope.savedFilters = filters
    $scope.newFilter = {}
    $scope.activeFilter = null
    $scope.deleting = null

    $scope.saveFilterDialog = () ->
      searchObject = $location.search()

      # if no group is searched, assume that this is saved at the org level
      if !searchObject.groups then searchObject.groups = $scope.organization.id.toString()

      $scope.showFilterModal = true
      $scope.newFilter.conditions = searchObject
      delete $scope.newFilter.conditions.date
      delete $scope.newFilter.conditions.slot

    $scope.filterNameExists = () ->
      $scope.newFilter.name and $scope.savedFilters.find((f) -> f.name is $scope.newFilter.name)

    $scope.filterConditionsExists = () ->
      filter = $scope.savedFilters.find((f) ->
        saved = f.conditions

        # Check for string equality to see if a these conditions already exist
        groupsMatch = saved.groups?.split(',').sort().join(',') is $location.$$search?.groups.split(',').sort().join(',')
        tagsMatch = saved.tags?.split(',').sort().join(',') is $location.$$search.tags?.split(',').sort().join(',')

        return groupsMatch && tagsMatch
      ) if $scope.newFilter.conditions
      return filter.name if filter

    $scope.saveTypes = [
      { label: 'Existing List', value: 'update' }
      { label: 'New List', value: 'create' }
    ]

    $scope.saveType =
      value: 'create'

    $scope.existingFilter ?= {}

    setFilterOptions = () -> $scope.savedFilters.reduce((options, filter) ->
      return options.concat({ label: filter.name, value: filter.id })
    , [])

    $scope.existingFilterOptions = setFilterOptions()

    $scope.cancelFilter = () -> $scope.showFilterModal = false

    $scope.invalidFilter = () ->
      if $scope.saveType.value is 'create'
        return $scope.filterNameExists() || $scope.filterConditionsExists() || !$scope.newFilter.name
      else
        return !$scope.existingFilter.id

    $scope.saveFilter = () ->
      if $scope.saveType.value is 'update'
        existingFilter = $scope.savedFilters.find((f) -> f.id == parseInt $scope.existingFilter.id)
        existingFilter.conditions = $scope.newFilter.conditions
        existingFilter.name = $scope.newFilter.name
        filter = existingFilter
      else
        filter =
          userID: currentProvider.id
          name: $scope.newFilter.name
          conditions: $scope.newFilter.conditions
          share: $scope.newFilter.share
          groupID: organization.id
          type: 'emar'

      $http.post("/api/users/#{currentProvider.id}/filters", filter)
        .then((result) ->
          if $scope.saveType.value is 'update'
            index = $scope.savedFilters.indexOf existingFilter
            $scope.savedFilters.splice(index, 1)
          $scope.savedFilters.push(result.data)
          $scope.existingFilterOptions = setFilterOptions()
          $scope.newFilter = {}
          $scope.showFilterModal = false
          flash('Filter successfully saved!')
        )
        .catch((err) ->
          flash('Unable to save filter')
          $scope.showFilterModal = false
        )

    # deleteFilter; setTimeout() used to refresh sidebar to clear deleted filters
    $scope.deleteFilter = () ->
      $scope.deleting = true
      filterID = parseInt $scope.activeFilter, 10
      $http.delete("/api/users/#{currentProvider.id}/filters/#{filterID}")
        .then((result) ->
          # savedFilters is used by setFilterOptions to update the sidebar with currently existing filters
          $scope.savedFilters = $scope.savedFilters.filter((f) ->
            return f.id != filterID
          )
          $scope.existingFilterOptions = setFilterOptions()
          flash 'Filter successfully deleted!'
          $scope.deleting = false
          $scope.activeFilter = false
          return
        ).catch((err) ->
          swalConfig = {
            title: "An Error Occurred"
            text: "Unable to delete filter"
            type: "error"
          }
          if +err.status >= 500
            swalConfig.title = "Something Went Wrong"
            swalConfig.text = "There was an error on our end, please try again in a moment."
          else if +err.status == 404
            swalConfig.title = "Filter Not Found"
            swalConfig.text = "Please select a filter to delete."
          else
            swalConfig.title = "Bad Request"
            swalConfig.text = "The request is missing a User ID. Unable to delete filter."
          window.swal swalConfig
          $scope.deleting = false
          $scope.activeFilter = false
        )

    $scope.setShareValue = () ->
      filter = $scope.savedFilters.find((f) -> f.id == parseInt $scope.existingFilter.id)
      $scope.newFilter.share = filter.share

    $scope.loadFilter = () ->
      filter = $scope.savedFilters.find((f) -> f.id == parseInt $scope.activeFilter)
      if filter && filter.conditions
        $scope.groups = filter.conditions.groups || ''
        $scope.tags = filter.conditions.tags || ''

    saveMar = (mar) ->

      return if !mar.saving

      # save mar
      mar.properties ?= { updates: [] }

      mar.providerID = currentProvider.id
      mar.provider = currentProvider.givenName + " " + currentProvider.familyName

      updateArr = mar.properties?.updates

      update =
        note: mar.note || ""
        time: new Date()
        userID: currentProvider.id

      if currentProvider.givenName && currentProvider.familyName
        update.initials = currentProvider.givenName.charAt(0) + currentProvider.familyName.charAt(0)
        update.providerName = currentProvider.givenName + " " + currentProvider.familyName

      if mar.error
        if updateArr && updateArr[0] && updateArr[0].status is "Error"
          mar.error = false
          if mar.pending
            update.status = "Pending"
            mar.quantity  = 0
          else if mar.notGiven
            update.status = "Not Given"
            mar.quantity  = 0
          else
            update.status = "Given"
        else
          update.status = "Error"
          mar.notGiven  = false
          mar.pending   = false
          mar.quantity  = 0
      else if !mar.notGiven && !mar.pending
        update.status = "Given"
        # This be Levi's crime. Tell Levi to atone for his sins.
        mar.quantity = do ->
          return 0 unless mar.medication.trackQuantity
          -1 * parseFloat mar.medication.doseValue
      else if mar.notGiven
        update.status = "Not Given"
        mar.quantity  = 0
      else if mar.pending
        update.status = "Pending"
        mar.quantity  = 0

      if !updateArr.length
        update.initial = true
      else
        if update.status isnt updateArr[0].status
          update.prevStatus = updateArr[0].status
          update.statusChanged = true
        else update.statusChanged = false

        if !update.statusChanged && update.note isnt updateArr[0].note
          update.noteChanged = true

      mar.properties.updates.unshift update
      mar.expanded = false

      if mar.medication.trackQuantity and organization.properties.medicationCountAlert
        $http.get("/api/organizations/#{$stateParams.orgID}/profiles/#{mar.profileID}/medication-count?medIDs=#{mar.medicationID}").then (result) ->
          inventory = result.data[0].inventory
          if +inventory < +organization.properties.medicationCountAlert
            new Event(
              profileID: mar.profileID
              type: "eMar.lowMedCount"
              metadata: {
                medName: mar.medication.name
                medCount: inventory
              }
            ).save()
      mar.save().then (data) ->
        _.defaults mar, data
        countRemaining $scope
        mar.saving = false

    $scope.currentProvider = currentProvider
    $scope.textFilter = $filter "text"
    $scope.provider = provider
    $scope.today = moment().format "YYYY-MM-DD"
    $scope.firstLoad = false
    $scope.loading = true
    $scope.missingSlots = []

    # Set initial date based on $stateParams, falling back to the current date
    $scope.date = do ->
      return $scope.today if !$stateParams.date or !moment($stateParams.date).isValid()
      $stateParams.date

    $scope.download =
      dialog: false
      start: moment($scope.date).subtract(7, "days").format "YYYY-MM-DD"
      finish: $scope.date

    $scope.filters = {}

    # Account for any existing backend filters.
    if $stateParams.groups
      $scope.groups = $stateParams.groups
    if $stateParams.tags
      $scope.tags = $stateParams.tags

    $scope.prettyDate = (date) ->
      moment(date).format("dddd, MMMM Do YYYY")

    $scope.closeModal = ->
      $scope.showModal = false

    $scope.bulkMarkDialog = ->
      $scope.groupNames = $scope.groups?.split(',')
        .map((id) ->
          return $scope.groupChoices.find((g) -> g.value == parseInt id)?.label
        )
      $scope.massMark ?= {}
      $scope.massMark.given = false
      $scope.massMark.notGiven = false
      $scope.massMark.pending = false
      $scope.massMark.slot = ""
      $scope.massMark.note = ""
      $scope.massMark.recordedAt = new Date().toISOString()
      $scope.showModal = true

    $scope.bulkMarkWarn = ->
      $scope.showModal = false

      bulkMars = _.filter $scope.mars, (mar) ->
        if !mar.id and !mar.asNeeded
          if !mar.daysTaken[moment($scope.today).format("ddd")]
            return false

          if $scope.groups
            return false if !_.some mar.profile.groups, (g) -> _.includes($scope.groups, g)

          if $scope.tags
            return false if !_.some _.compact(_.map(mar.profile.tags, "tagID").concat(_.map(mar.profile.tags, "optionID"))), (t) -> _.includes($scope.tags, t)

          if $scope.massMark.slot is "All Times" or mar.slot is $scope.massMark.slot
            return true
        false

      if bulkMars.length is 1
        marNoun = "medication administration entry"
      else marNoun = "medication administration entries"

      dateConfirm = ''
      dateConfirm = " for a past date" if moment($scope.date).isBefore(moment().format('YYYY-MM-DD'))
      dateConfirm = " for a future date" if moment($scope.date).isAfter(moment().format('YYYY-MM-DD'))

      window.swal
        title: "Confirmation"
        text: """
          You are about to bulk record #{ bulkMars.length} #{ marNoun }#{dateConfirm}, and this cannot be undone. Are you sure?
        """
        type: "warning"
        confirmButtonColor: "#6E991F"
        confirmButtonText: "Record"
        showCancelButton: true
      , ->
        bulkMarkMeds(bulkMars)

    bulkMarkMeds = (mars) ->
      _.each mars, (mar) ->
        mar.notGiven = $scope.massMark.notGiven
        mar.pending = $scope.massMark.pending
        mar.note = $scope.massMark.note
        mar.recordedAt = moment($scope.massMark.recordedAt.replace(':', ''), 'hmm').format()
        mar.saving = true
        saveMar(mar)

      $scope.showModal = false
      flash "Mass Update Complete"

    $scope.missingBeforeNow = ->
      _.filter $scope.missingSlots, (missingSlot) ->
        return false if missingSlot is $scope.filters.slot
        return $scope.slots[missingSlot].hour < $scope.slots[$scope.filters.slot].hour

    # Watch date, slot and tags for changes, update state and reload eMAR records when they change
    $scope.$watchGroup ["date", "groups", "tags"], ([date, groups, tags], [staleDate, staleGroups, staleTags]) ->
      $scope.reloadMars = false
      return if ((!date || date == staleDate) && groups == staleGroups && tags == staleTags)

      # Modify $stateParams appropriately, potentially reload the MAR collection
      $state.go ".",
        date: do ->
          return date if date is staleDate and date isnt $scope.today
          return if date is staleDate
          $scope.reloadMars = true
          $scope.download.start = moment(date).subtract(7, "days").format "YYYY-MM-DD"
          $scope.download.finish = date
          date

        groups: do ->
          return groups if groups is staleGroups
          $scope.reloadMars = true
          groups

        tags: do ->
          return tags if tags is staleTags
          $scope.reloadMars = true
          tags

      , { notify: false }
      # Reload the MAR collection if appropriate
      if $scope.reloadMars or !$scope.firstLoad
        fetchMars $stateParams.orgID, date, groups, tags, $scope.filters, $http, $filter, $scope, Mar

    $scope.manualFetch = ->
      fetchMars $stateParams.orgID, $scope.date, $scope.groups, $scope.tags, $scope.filters, $http, $filter, $scope, Mar

    # Watch filters
    $scope.$watch "filters", (filters, stale) ->
      return if filters == stale

      $scope.list.limit = 30
      $scope.list.mostScrolled = 0
      filterMars $scope
      $state.go ".",
        slot: _.kebabCase filters.slot
      , { notify: false }
    , true

    $scope.setDate = (day) -> $scope.date = day

    $scope.redundantDoseType = (strength, doseType) ->
      strength = strength?.toLowerCase() or ""
      doseType =doseType?.toLowerCase() or ""
      strength.indexOf(doseType) isnt -1


    $scope.save = (mar) ->
      now = moment().format('YYYY-MM-DD')

      if moment($scope.date).isBefore(now)
        message = "You are attempting to record a medication administration entry for a past date"
      else if moment($scope.date).isAfter(now)
        message = "You are attempting to record a medication administration entry for a future date"
      else message = ""

      if message
        window.swal
          title: "Confirmation"
          text: """
          #{message} and this cannot be undone. Are you sure?
          """
          type: "warning"
          confirmButtonColor: "#6E991F"
          confirmButtonText: "Record"
          showCancelButton: true
        , (confirmed) ->
          mar.saving = confirmed
          return saveMar(mar)
      else
        mar.saving = true
        return saveMar(mar)


    $scope.noteTemplates = [
      { label: "Patient Offsite", value: "Patient Offsite" }
      { label: "Patient Refused", value: "Patient Refused" }
      { label: "Medication Not Available", value: "Medication Not Available" }
    ]

    if $filter('permissionVisible')({emar: 'view'})
      $http.get("/api/organizations/" + organization.id + "/emars/notes").then (result) ->
        customNotes = result.data || []
        customNotes.map (note) ->
          $scope.noteTemplates.push({ label: note.name, value: note.template.note })

    # Set up choices for group-based filtering.
    $scope.groupChoices = do ->
      groups = []
      addGroup = (group) ->
        group.chain ?= []
        group.chain.push group.name if group.classification isnt "organization"
        groups.push
          id: group.id
          name: group.name
          chain: group.chain
          phase: group.phase
        return if !group.children or !group.children.length
        _.each _.sortBy(group.children, "name"), (child) ->
          child.chain = _.clone(group.chain)
          addGroup child
      addGroup organization
      groups = _.map groups, (g) ->
        value: g.id
        label: g.chain.join(" » ") or g.name
        phase: g.phase
      _.filter groups, (g) ->
        return false if g.phase is "past"
        return true if $scope.operator
        return _.includes $scope.access.groups, g.value

    flattenOrg = (org) ->
      org.children.reduce((flat, child) ->
        flat.concat if (child.children && child.children.length) then flattenOrg(child) else child
      , [org])

    flatOrg = flattenOrg(organization)

    displayGroups = (IDs) ->
      _(IDs).uniq()
        .map((id) ->
          group = flatOrg.find((g) -> g.id is parseInt(id))
          parent = flatOrg.find((g) -> g.id is group.parentID) if group
          displayName = _.compact([parent?.name, group?.name]).join(" » ")
          return displayName
        ).value()

    # Expand/Hide Mar && set registration data
    $scope.toggleMar = (mar) ->
      mar.profile.registrations ?= displayGroups(mar.profile.groupIDs)
      mar.expanded = !mar.expanded

    # Set up choices for tag-based filtering.
    tagStems = _.keyBy organization.tags, "id"

    $scope.tagChoices = _(organization.tags).filter((tag) ->
      return tag.parentID && !tag.deactivated
    ).map((tag) ->
      return { label: tagStems[tag.parentID].value + " - " + tag.value, value: tag.id }
    ).sortBy("label").value()

    # Keep track of profile list properties to enable infinite scrolling
    marList = $('.mar-list')
    $scope.list =
      rendered: -> marList[0].scrollHeight
      scrolled: -> marList[0].scrollTop
      height: -> marList.height()
      limit: 30
      mostScrolled: marList[0].scrollTop

    # Watch our scroll position, add additional rows as necessary
    # We add more rows when near the bottom of the list vs the top
    $scope.$watch "list.scrolled()", (scrolled, stale) ->
      return if scrolled <= $scope.list.mostScrolled
      $scope.list.mostScrolled = scrolled
      percent = ($scope.list.height() + $scope.list.scrolled()) / $scope.list.rendered()
      $scope.list.limit += percent * 25 if percent > 0.2 and $scope.list.limit < $scope.mars.length

    $scope.incrementDate = (arg) ->
      return $scope.date = $scope.today if arg is "today"
      $scope.date = moment($scope.date).add(arg, "days").format "YYYY-MM-DD"

    $scope.historyUrl = ->
      url = "/api/organizations/#{$stateParams.orgID}/export-emars"
      url += "?start=#{$scope.download.start}&finish=#{$scope.download.finish}"
      url += "&groups=#{$scope.download.groups}"
      url += "&tags=#{$scope.download.tags}"
      url

    $scope.downloadUrl = ->
      url = "/api/organizations/#{$scope.organization.id}/emars.csv"
      url += "?date=#{$scope.date}&groups=#{$scope.groups}&tags=#{$scope.tags}"

    # Function to turn off the "only pending" filter when "show previous" is disabled.
    $scope.showCurrent = (showPrev) ->
      $scope.filters.onlyPending = false if !showPrev

    fetchMars $stateParams.orgID, $scope.date, $scope.groups, $scope.tags, $scope.filters, $http, $filter, $scope, Mar
