angular.module('dn').factory('RegCoupon', function ($filter) {

  const RegAdjustment = angular.injector(['dn']).get('RegAdjustment');

  function RegCoupon({ adjustment, registration }) {

    // Old skool version of calling super? I suppose?
    Object.assign(this, new RegAdjustment({ adjustment, registration }));

    // Specific to Reg Coupons
    this.id = adjustment.id;
    this.code = adjustment.code;
    this.type = RegAdjustment.TYPES.COUPON;
    this.amount = adjustment.amount;
    this.selected = false;
    this.disabled = false;
    this.exceedsTuition = adjustment.exceedsTuition;
    Object.defineProperty(this, 'amount', {
      enumerable: true,
      writable: false,
      configurable: false,
      value: window.lib.calcCouponAmount(adjustment, this.tuition)
    });
    this.ui = {
      shared: !!adjustment.shared,
      percentage: !!adjustment.properties.isPercentage,
      capacity: `(${adjustment.used || 0}/${adjustment.capacity || '∞'})`,
      amount: $filter('currency')(this.amount / 100),
      // Alias "name" for compatibility with other RegAdjustments
      name: this.code,
    };
    // Full name can included "(Shared)"; useful for accessibility and on small screens
    this.ui.fullName = this.ui.name + (this.ui.shared ? ' (Shared)' : '');
    this.selected = false;

  }

  return RegCoupon;

});
