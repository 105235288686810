lib.registerState "manager.settings.general.basics",
  url: "/basics"
  templateUrl: "states/manager/settings/general/basics/basics.state.html"
  resolve:
    resourcesGLCodes: ($http, organization) ->
      $http.get("/api/group/#{organization.id}/gl-codes/resources").then (result) ->
        result.data
  controller: ($scope, group, organization, flash, $filter, $http, resourcesGLCodes) ->
    $scope.immsFlag =  window.lib.featureFlagClient.isEnabled('imms-reg')
    $scope.earlyRegFeatureFlag = window.lib.featureFlagClient.isEnabled('registrationCodes')

    $scope.organization = organization
    $scope.isOrganization = group.id is organization.id

    $scope.hasEditPermissions = $filter("permissionVisible")({ settings_basics: 'edit' })

    $scope.defaultLangs = [
      {label: 'Spanish', value: 'Spanish'}
      {label: 'Hebrew', value: 'Hebrew'}
      {label: 'German', value: 'German'}
      {label: 'French', value: 'French'}
      {label: 'Korean', value: 'Korean'}
    ]

    $scope.input = {}

    $scope.addLanguage = () ->
      langs = $scope.input.newLanguages.split(",")
      $scope.group.properties.languages ?= []
      $scope.group.properties.languages = _($scope.group.properties.languages).concat(langs).map((l) -> _.capitalize(l)).uniq().value()
      $scope.group.save(['properties']).then(
        (success) ->
          flash 'Added languages!'
        , (error) ->
          flash 'Unable to save languages'
      )
      $scope.input.newLanguages = ""

    $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission)

    $scope.removeLang = (lang) ->
      _.pull($scope.group.properties.languages, lang)
      $scope.group.save(['properties']).then(
        (success) ->
          flash 'Removed language!'
        , (error) ->
          flash 'Unable to save languages'
      )

    $scope.registrationDisabled = (->
      return true if group.id is organization.id
      parentRegisterable = (->
        found = false
        checkParent = (g) ->
          if g.parent and g.parent.registration and g.parent.registration.enabled then found = true
          if !found and g.parent then checkParent g.parent
          return
        checkParent group
        return found
      )()
      if parentRegisterable then return true

      childrenRegisterable = (->
        found = false
        checkChildren = (g) ->
          _.each g.children, (c) ->
            if c.registration and c.registration.enabled then found = true
            if not found then checkChildren c
            return
          return
        checkChildren group
        return found
      )()
      if childrenRegisterable then return true
    )()

    $scope.tab = "identity"
    $scope.subtabs = [
      { label: "Identity", value: "identity" }
      { label: "Features", value: "features" }
      { label: "Contact Information", value: "contact" }
      { label: "Dates", value: "dates" }
      { label: "Tools", value: "tools" }
      { label: "Integrations", value: "integrations" }
      { label: "Languages", value: "lang"}
    ]

    $scope.languages = window.lib.languages

    $scope.importChoices = [
      { value: "normal", label: "Normal" }
      { value: "soft sync", label: "Soft Sync" }
      { value: "hard sync", label: "Hard Sync"}
    ]

    $scope.group.properties.timezone = organization.properties.timezone || false
    $scope.timezoneChoices = [
      { value: false, label: "None" }
      { value: "US/Eastern", label: "Eastern (ET)" }
      { value: "US/Central", label: "Central (CT)" }
      { value: "US/Mountain", label: "Mountain (MT)" }
      { value: "US/Pacific", label: "Pacific (PT)" }
      { value: "US/Alaska", label: "Alaska (AKDT)" }
      { value: "US/Hawaii", label: "Hawaii (HDT)" }
      { value: "America/Phoenix", label: "Arizona - Phoenix (MST)" }
      { value: "America/Halifax", label: "Atlantic (AT)" }
      { value: "America/Puerto_Rico", label: "Puerto Rico (UTC-04:00)" }
      { value: "WET", label: "Western European Time (WET)" }
      { value: "CET", label: "Central European Time (CET)" }
      { value: "EET", label: "Eastern European TIme (EET)" }
      { value: "Asia/Kolkata", label: "Indian Standard Time (UTC+05:30)" }
      { value: "Asia/Jerusalem", label: "Jerusalem" }
      { value: "Asia/Seoul", label: "Korea (KST)" }
      { value: "Pacific/Auckland", label: "New Zealand (NZST)" }
    ]

    $scope.clearableSettings = [
      { label: "Due Date", value: "properties.due" }
      { label: "Finish Date", value: "properties.finish" }
      { label: "Lockout Date", value: "properties.lockout" }
      { label: "Start Date", value: "properties.start" }

      { label: "Age Restriction", value: "registration.age" }
      { label: "Grade Restriction", value: "registration.grade" }
      { label: "Sex-based Capacity", value: "registration.capacity" }
      { label: "Enrollment Dates", value: "registration.open" }

      { label: "Custom Add-On Text", value: "regText.addOnSelect" }
      { label: "Custom Payment Text", value: "regText.payment" }
      { label: "Custom Coupon Text", value: "regText.couponEntry"}
    ]

    # Function to set the text on the clear button and flash alerts appropriately
    $scope.selectedSettingLabel = (selected) ->
      if selected
        return  _.find $scope.clearableSettings, (obj) ->
          if obj.value is selected
            return obj
        .label
      ""

    $scope.divisions = [
      { label: "DocNetwork", value: "DocNetwork" }
      { label: "CampDoc", value: "CampDoc" }
      { label: "SchoolDoc", value: "SchoolDoc" }
      { label: "DayCareDoc", value: "DayCareDoc" }
    ]

    $scope.group.properties.portals ?= {}
    $scope.group.properties.portals.patient ?= {}
    $scope.group.properties.portals.patient.sections ?= {}
    $scope.group.properties.portals.patient.sections.questionnaire ?= {}
    $scope.group.properties.portals.patient.sections.questionnaire.alias ?= group.properties.branding?.text?.healthProfileAlternateName
    $scope.group.properties.features ?= {}
    $scope.group.properties.features.prescreening ?= { enabled: false, reminders: false }

    # added as part of #4987 to play along with our save modifier validate-identifier.js
    # the group may not have parents at this point, so we need to explicitly set them
    $scope.group.parents ?= [21436, organization.id]

    $scope.clearSettings = (setting) ->
      return unless setting
      $scope.clearing = true

      group.clearChildSettings(setting).then (result) ->
        $scope.clearing = false
        flash "Property \"#{$scope.selectedSettingLabel setting}\" cleared for child groups"
        return

    $scope.exportUrl = do ->
      csvData = window.lib.exportGroupStructure($scope.group, resourcesGLCodes?.group)
      return "data:application/csv;charset=utf-8,#{encodeURIComponent(csvData)}"

    $scope.togglePrescreening = (enabled) ->
      # Can't have prescreening reminders without prescreening
      if !enabled then $scope.group.properties.features.prescreening.reminders = false

    $scope.prescreeningRemindersTooltip = () ->
      if !$scope.isOrganization
        return 'This setting is only available from the organization settings'
      else if !$scope.group.properties.features.prescreening.enabled
        return 'This setting is only available when Prescreening is enabled'
      else
        return ''

    $scope.smsSendEmails = ->
      if group.properties.smsEmailsSent
        daysSinceSent = moment().diff(moment(group.properties.smsEmailsSent), 'days')
      else
        daysSinceSent = 365
      if daysSinceSent > 6
        swal(
          title: "Send Emails"
          type: "warning"
          text: "This will send an email to all of your users who have not confirmed their phone number for recieving texts.\n\nThis cannot be undone and can only be done once every seven days!"
          confirmButtonText: "Send Emails"
          showCancelButton: true
        , (isConfirm) ->
          if isConfirm
            $http.post("/api/organizations/#{group.id}/sms-activate").then((result) ->
              # This is set in the backend, but we also set it here so we don't run this twice
              group.properties.smsEmailsSent = Date.now()
              flash "Emails sent!"
            ).catch((err) ->
              swal(
                title: "Error sending emails!"
                type: "error"
                text: "There was an error sending emails!"
              )
            )
        )
      else
        swal(
          title: "Already sent emails"
          type: "warning"
          text: """
            Emails were already sent to this organization's users!
            #{if group.properties.smsEmailsSent then 'Last sent ' + daysSinceSent + ' days ago.' else ''}
          """
        )
