angular.module('dn').directive('bulkHealthLogEntries', function() {
  return {
    templateUrl: 'directives/bulk-health-log-entries/bulk-health-log-entries.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      provider: '=',
      back: '=',
      fullTextSearchEnabled: '='
    },

    controller($cacheFactory, $http, $scope, $state) {
      /* Setup to clear the cache if they go to health log after submitting */
      const $httpDefaultCache = $cacheFactory.get('$http');

      /* Need these three things to submit */
      const REQUIRED_SCOPE_DATA = ['selectedTemplate', 'selectedProvider', 'loggedTimestamp'];

      /*
      required fields to save a non-draft entry through the health log, minus patientID
      and instructions of where to find them within a template object
      */
      const REQUIRED_TEMPLATE_DATA = [
        {
          sectionLabel: 'Subjective',
          subsectionLabel: 'Chief Complaint',
          modelLabel: 'complaint',
        },
        {
          sectionLabel: 'Subjective',
          subsectionLabel: 'HPI',
          modelLabel: 'details',
        },
        {
          sectionLabel: 'Assessment/Plan',
          subsectionLabel: 'Assessment',
          modelLabel: 'assessment',
        },
        {
          sectionLabel: 'Assessment/Plan',
          subsectionLabel: 'Plan',
          modelLabel: 'treatment',
        },
        {
          sectionLabel: 'Assessment/Plan',
          subsectionLabel: 'Disposition',
          modelLabel: 'disposition',
        }
      ];

      $scope.loading = null;
      $scope.orgTemplates = null;
      $scope.selectedTemplate = null;

      $scope.providers = null;
      $scope.selectedProvider = $scope.provider ? $scope.provider.id : null;

      $scope.loggedTimestamp = new Date();
      $scope.futureLogDate = null;

      /* DRY up some SWAL things */
      const allowEscapeKey = true;
      const allowOutsideClick = true;
      const showCancelButton = true;

      /* Load and assign org templates for input */
      $scope.disableButton = true;
      initDirective();
      $scope.disableButton = false;

      $scope.$watch('loggedTimestamp', checkDates);

      $scope.submitEntries = function() {
        if ($scope.fullTextSearchEnabled && !getProfiles().length) {
          return swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to add log entries.'
          });
        }

        $scope.disableButton = true;
        return confirmSwal((isConfirm) => {
          if (isConfirm) {
            const chunkedProfiles = _.chunk(getProfiles(), 500);
            return async.eachSeries(chunkedProfiles, (profileChunk, afterAsync) => {
              const requestBody = {
                profileIDs: profileChunk,
                templateID: $scope.selectedTemplate,
                providerID: $scope.selectedProvider,
                logged: $scope.loggedTimestamp,
                logType: 'general-health' // hard coded here if wanted to expand for behavioral-health in the future
              };
              if (Object.keys(requestBody).some((key) => !requestBody[key])) {
                throw new `Missing value for ${key}`;
              }
              return $http.post(`/api/organizations/${$scope.organization.id}/log-entries`, requestBody).then(() => {
                return afterAsync();
              }).catch((err) => {
                return afterAsync(err);
              });
            }, (err) => {
              $scope.disableButton = false;
              if (err) return errorHandler(err, {description: 'submitting log entries'});
              else return successSwal();
            });
          } else {
            flash('Canceled');
            $scope.disableButton = false;
          }
        });
      };

      $scope.missingInfo = function() {
        return REQUIRED_SCOPE_DATA.some((scopeData) => {
          return !$scope[scopeData];
        });
      };

      function initDirective() {
        toggleLoading()
          .then(getProviders)
          .then(assignProviders)
          .then(getTemplates)
          .then(assignTemplates)
          .then(toggleLoading)
          .catch((err) => {
            return errorHandler(err, {description:'initializing the tool'});
          });
      }

      function confirmSwal(callback) {
        return swal({
          title: 'Add Health Log Entries?',
          type: 'warning',
          text: `This action will create one health log entry for each of the ${getProfiles().length} selected profiles. This cannot be undone. Continue?`,
          allowOutsideClick,
          allowEscapeKey,
          showCancelButton,
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
        }, callback);
      }

      function successSwal() {
        return swal({
          title: 'Success',
          type: 'success',
          text: 'Log entries successfully created',
          confirmButtonText: 'Go to Health Log',
          showCancelButton,
          allowEscapeKey,
          allowOutsideClick,
        }, (isConfirm) => {
          if (isConfirm) {
            $httpDefaultCache.remove(`/api/organizations/${$scope.organization.id}/log-entries`);
            return $state.go('manager.healthLog');
          }
        });
      }

      function errorHandler(err, context) {
        $scope.disableButton = null;
        setTimeout(() => {
          return swal({
            type: 'error',
            title: '',
            text: `There was an error ${context.description}:\n\n ${err.data || err}`,
            allowOutsideClick,
            allowEscapeKey,
          });
        });
      }

      function toggleLoading() {
        $scope.loading = !$scope.loading;
        return Promise.resolve();
      }

      function getProviders() {
        const url = `/api/organizations/${$scope.organization.id}/profiles`;
        const filters = '[registrations].type(is:provider)';
        return $http.get(`${url}?filters=${filters}`);
      }

      function assignProviders({data}) {
        try {
          if (data) {
            $scope.providers = data.reduce((providers, d) => {
              if (d.profiles) {
                providers.push({
                  label: `${d.profiles.familyName}, ${d.profiles.givenName}`,
                  value: d.profiles.id,
                });
              }
              return providers;
            }, []).sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
          } else $scope.providers = [];
          return Promise.resolve();
        } catch (err) {
          return Promise.reject(err);
        }
      }

      function getTemplates() {
        return $http.get(`/api/organizations/${$scope.organization.id}/templates?type=health-log`);
      }

      /*
      Filters loaded templates down to those that are
        complete - have values for all REQUIRED_TEMPLATE_DATA
        owned - the logged-in provider created the template
        shared - it's shared
      Sorts results so Custom is first, followed by Shared
        and sorted alphabetically within each optgroup
      And finally assigns that all to $scope.orgTemplates
      Returns nothing on success, error on error
      */
      function assignTemplates({data}) {
        if (data.length) {
          $scope.orgTemplates = data.reduce((availableTemplates, template) => {
            if (validTemplate(template.template)) {
              if ($scope.provider && template.profileID === $scope.provider.id) {
                availableTemplates.push({
                  label: template.name,
                  value: template.id,
                  optgroup: 'Custom Templates',
                });
              } else if (template.shared) {
                availableTemplates.push({
                  label: template.name,
                  value: template.id,
                  optgroup: 'Shared Templates',
                });
              }
            }
            return availableTemplates;
          }, []).sort((a, b) => {
            if (a.optgroup === b.optgroup) {
              // Same optgroup, sort within the optgroup
              return sortOMatic(a, b, 'label');
            } else {
              // Differing optgroups, make sure 'Custom' comes first
              return sortOMatic(a, b, 'optgroup');
            }
          });
          if ($scope.orgTemplates.length) return Promise.resolve();
        }
        /* Either no templates returned from the API route, or none with all required data */
        return Promise.reject('No available templates. Visit the Templates section of the Health Log to add more.');
      }

      function validTemplate(template) {
        return REQUIRED_TEMPLATE_DATA.every((requiredField) => {
          return templateHasValue(template, requiredField);
        });
      }

      /*
      Checks if a template has a required value at the expected location
      */
      function templateHasValue(template, {sectionLabel, subsectionLabel, modelLabel}) {
        const section = (template.sections || []).find(s => s.label === sectionLabel) || {};
        const subsection = (section.subsections || []).find(ss => ss.label === subsectionLabel) || {};
        const model = (subsection.models || []).find(m => m.model === modelLabel) || {};
        return model.value;
      }

      /*
      field will be one of ['label', 'optgroup']
      a and b will be template select option objects with {label, value, optgroup}
      assigned from assignTemplates above
      */
      function sortOMatic(a, b, field) {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return -1;
        } else return 1;
      }

      // old method, to be removed when FullTextSearchProfiles is fully implemented
      function getProfiles() {
        if ($scope.fullTextSearchEnabled) {
          return $scope.profiles;
        } else {
          return $scope.profiles.some(profile => profile.selected)
            ? $scope.profiles.filter(profile => profile.selected).map(profile => profile.profiles.id)
            : $scope.profiles.map(profile => profile.profiles.id);
        }
      }

      function checkDates(fresh, stale) {
        if (fresh && fresh !== stale) {
          $scope.futureLogDate = moment(fresh).isAfter(moment());
        }
      }
    } // end controller
  };
});
