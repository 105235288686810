lib.registerState('pw-reset-confirmation', {
  url: '/pw-reset-confirmation',
  templateUrl: 'states/pw-reset-confirmation/pw-reset-confirmation.state.html',
  controller: function($scope, $location, WhatDivision) {
    $scope.division = WhatDivision.lcName;

    // manual DOM manipulation to remove topbar icons
    const icons = document.getElementsByClassName('icons')[0];
    icons.parentNode.removeChild(icons);

    const code = $location.search().code;
    const email = $location.search().email;

    $scope.resetLink = `/?passport=${code}&e=${encodeURIComponent(email)}`;
    $scope.validParameters = !!code && !!email;
  }
});
