'use strict';

angular.module('dn').service('ContactData', (() => {
  const ERR_TAG = '[ContactData]';
  const REQUIRED_FIELDS = [
    'name',
    'relationship',
    'emails',
    'phones',
  ];

  /**
   * This store is the secret sauce of this whole approach. By putting everything into this private
   * object, we can ensure that all instances of the ContactData class are accessing the same objects
   * under the hood. This is how we can consistently and performantly share state across various
   * directives and views. All class methods and computed properties are about interacting with it.
   *
   * `trustedContacts` The trustesdContacts object on the store keyed by email address. The value for
   * each email key is another object keyed by profile id. This allows us quick and easy access to a
   * given trusted contact for multiple profiles.
   *
   * Each set may also have a `selected` key which is used to determine the selectedContactSet
   * computed property found below. As such, performing an operation like
   * `Object.keys(findContactSet(email)).length` is _not_ an accurate way to get a count of trustees.
   * You must account for the `selected` key manually.
   *
   * Example:
   * {
   *   "my@email.com": {
   *     "41234": {
   *       // Trusted contact data goes in here
   *     },
   *     "47816": {
   *       // Trusted contact data goes in here
   *     },
   *   },
   * }
   *
   * Accessing contacts or contactSets is best achieved through the methods beginning with `find`.
   *
   * `profiles`
   * The profiles key references another object, this time keyed by profile ID. Each profile ID is
   * associated with an array of 0 or more TrustedContacts. The items in the array are references to
   * objects on the trustedContacts key of the store, so they don't add any extra weight, just a way
   * to quickly look up contacts by profile_id. Use the `findContactsByProfile` method to access this
   * information.
   *
   * Example:
   * {
   *   "41234": [ TrustedContact, TrustedContact],
   *   "47816": []
   * }
   *
   * `tmp`
   * The `tmp` key is used for holding temporary information during create or edit operations on
   * TrustedContacts. You cannot access `tmp` directly; use `init-`, `save-`, and `clearTmpContact` to
   * interact with `tmp`. All `tmp` does is hold a _cloned_ copy of a contact (for edits) or an empty
   * copy of the schema (for new contacts). When saving any changes, the cloned contact will be
   * upserted into the store after the record is returned from the server.
   *
   * When using tmp, you can start from an existing contact like so:
   * const contactToEdit = ContactData.initTmpContact(ContactData.findContact(email, profileID));
   *
   * Alternatively, you can start from a totally fresh template like so:
   * const newContact = ContactData.initTmpContact();
   *
   * Saving a contact after editing:
   * ContactData.saveTmpContact().then(...);
   *
   * Clearing a tmp contact (e.g. when someone presses a "Cancel" button):
   * ContactData.clearTmpContact();
   */
  const _contactDataStore = {
    selected: null,
    trustedContacts: {},
    profiles: {},
    tmp: {}
  };

  class MissingRelationError extends Error {
    constructor(tag) {
      const tcWarning = 'a "profile_id" value';
      const ucWarning = 'a "group_id" value';
      const msg = `${tag} Must supply an object with ${tcWarning} or ${ucWarning}.`;
      super(msg);
      // Setting name on this helps with Error's custom stringification method.
      this.name = this.constructor.name;
    }
  }

  return class ContactData {
    constructor(TrustedContact, UniversalContact) {
      this.TrustedContact    = TrustedContact;
      this.UniversalContact  = UniversalContact;
    }

    // Private helper method to cast TrustedContact-like objects to TrustedContacts
    _rawToResource(target) {
      const TargetResource = target.profile_id ? this.TrustedContact : this.UniversalContact;
      if (!(target instanceof TargetResource)) target = new TargetResource(target);
      return target;
    }

    // Returns a TrustedContact-like object for use in edit or create operations
    // Use this so that you don't directly alter existing info until the user has saved the record
    initTmpContact(contact) {
      if (!contact || (!contact.profile_id && !contact.group_id)) {
        log.error(new MissingRelationError(`${ERR_TAG}${'[initTmpContact]'}`));
        return undefined;
      }
      const current = JSON.parse(JSON.stringify(contact));
      const clean = (!!contact.profile_id ? this.TrustedContact : this.UniversalContact).blankSchema();
      _contactDataStore.tmp = Object.assign(clean, current);
      return _contactDataStore.tmp;
    }

    // Wrapper around TrustedContact.save() that allows for quick updates to the store
    // Optionally pass a boolean to set the returned value as the selectedContact
    saveTmpContact(setSelected) {
      let tmpContact = _contactDataStore.tmp;
      try {
        this.validateContact(tmpContact, '[saveTmpContact]');
      } catch (err) {
        log.error(err);
        return undefined;
      }
      tmpContact = this._rawToResource(tmpContact);
      // Handle either Trusted or Universal Contacts
      let foundContact;
      if (!!tmpContact.profile_id) {
        foundContact = this.findContact(tmpContact.emails.primary_value, tmpContact.profile_id);
      } else {
        foundContact = this.universalContact;
      }
      // Can't modify deactivated contacts—if deactivated, reactivate.
      if (foundContact && foundContact.deactivated) {
        tmpContact.id = foundContact.id;
        tmpContact.deactivated = null;
      }
      // Save it to the back end
      return tmpContact.save().then((contactFromServer) => {
        this.clearTmpContact();
        const contactFromStore = this.upsertContact(contactFromServer, false);
        if (setSelected) this.selectedContact = contactFromStore;
        return contactFromStore;
      });
    }

    // Used to clear _contactDataStore.tmp; useful for a "Cancel" action
    clearTmpContact() {
      _contactDataStore.tmp = {};
    }

    // Used to clear out anything in the store that's related to trusted and universal contacts.
    // Useful for inter-state nav if you haven't done anything with tmp, but best used via
    // clearStore() below.
    clearContacts() {
      _contactDataStore.trustedContacts = {};
      _contactDataStore.profiles = {};
      _contactDataStore.selected = null;
      _contactDataStore.universalContact = undefined;
    }

    // Clear out the whole dang store. Use this when navigating between states.
    clearStore() {
      this.clearTmpContact();
      this.clearContacts();
    }

    // Given a TrustedContact or TrustedContact-like object, place it in the store.
    // If it already has an entry, update it. If it doesn't, create a new one.
    upsertContact(contact, validate = true, callerTag = '') {
      if (validate) {
        try {
          this.validateContact(contact, `${callerTag}[upsertContact]`);
        } catch (err) {
          log.error(err);
          return undefined;
        }
      }

      // If it's the UC, all we've got to do is assign it and return
      if (!!contact.group_id) return this.universalContact = this._rawToResource(contact);

      // Make these easier to access
      const email = contact.emails.primary_value;
      const pid = contact.profile_id;
      // Create it if we need to
      _contactDataStore.trustedContacts[email] = _contactDataStore.trustedContacts[email] || {};
      _contactDataStore.trustedContacts[email][pid] = _contactDataStore.trustedContacts[email][pid] || {};
      // Convert to resource, assign the new one to store
      const updatedContact = Object.assign(_contactDataStore.trustedContacts[email][pid], contact);
      const storeContact = _contactDataStore.trustedContacts[email][pid] = this._rawToResource(updatedContact);
      // Create it if we need to
      _contactDataStore.profiles[pid] = _contactDataStore.profiles[pid] || [];
      // Only `.push` contacts not already in the array
      if (!_contactDataStore.profiles[pid].some(c => c.id === storeContact.id)) {
        _contactDataStore.profiles[pid].push(storeContact);
      }
      return storeContact;
    }

    // Make sure we have all our required fields
    validateContact(contact, callerTag = '') {
      const FN_TAG = '[validateContact]';
      let err;
      if (!contact || (!contact.profile_id && !contact.group_id)) {
        err = new MissingRelationError(`${ERR_TAG}${callerTag}${FN_TAG}`);
      } else {
        REQUIRED_FIELDS.every((field) => {
          if (!contact[field]) {
            err = new TypeError(`${ERR_TAG}${callerTag}${FN_TAG} Missing required field "${field}".`);
            return false;
          }

          // Handle our nested objects
          if (typeof contact[field] === 'object') {
            return ['primary_value', 'primary_type'].every((key) => {
              if (!contact[field][key]) {
                const keyParts = key.split('_');
                const complexField = `${keyParts[0]} ${field.slice(0, field.length - 1)} ${keyParts[1]}`;
                err = new TypeError(`${ERR_TAG}${callerTag}${FN_TAG} Missing required field "${complexField}".`);
              }
              return !!contact[field][key];
            });
          }

          return true;
        });
      }

      if (err) throw err;
      else return true;
    }

    // Use this to load data into the store. It can be from anywhere so long as it's an array of
    // TrustedContact(-like) objects.
    addToStore(trustedContacts, updatedTimestamp) {
      if (!Array.isArray(trustedContacts)) {
        log.error(Error(`${ERR_TAG}[addToStore] Must provide an array of TrustedContact-like objects.`));
        return false;
      }
      trustedContacts.forEach((contact) => {
        this.upsertContact(contact, true, '[addToStore]');
        const storeContactSet = _contactDataStore.trustedContacts[contact.emails.primary_value];
        if (updatedTimestamp) storeContactSet.lastUpdated = updatedTimestamp;
      });
      return true;
    }

    // Find a specific trusted contact
    findContactsByProfile(profileID, options = {}) {
      // Usually, we only want active contacts; exclude deactivated contacts by default
      if (options.includeDeactivated) {
        return _contactDataStore.profiles[profileID];
      } else {
        const active = (_contactDataStore.profiles[profileID] || []).filter(c => !c.deactivated);
        // Filters with nothing found return an empty array. To maintain consistency with the other
        // .find* methods below, we have to force undefined when no records are returned.
        return active.length ? active : undefined;
      }
    }

    // Find a specific trusted contact
    findContact(emailAddress, profileID) {
      return (this.findContactSet(emailAddress) || {})[profileID];
    }

    // Find all the trusted contacts in the store with a given emails.primary_value
    findContactSet(emailAddress) {
      return _contactDataStore.trustedContacts[emailAddress];
    }

    /**
     * If appropriate, dispatches an API call to load the current org's Universal Contact.
     * @param {Boolean} forceLoad Force an API call to load the Univsersal Contact.
     * @returns {Promise<UniversalContact|null>} Resolves to either the Universal Contact if one
     *   exists or `null` if not or if an error occurs.
     */
    loadOrgContact(forceLoad = false) {
      // If it's undefined, we know we haven't tried to load it yet
      if (this.universalContact === undefined) {
        log.info(`${ERR_TAG} Universal Contact not yet loaded.`);
        // Force the load if this is the case.
        forceLoad = true;
      } else if (this.universalContact === null) {
        log.info(`${ERR_TAG} An error occurred previously while loading the Univeral Contact.`);
      }

      if (forceLoad) {
        log.info(`${ERR_TAG} Loading now...`);
        // Loading errors will be caught by the method, so no need for .catch here.
        return this.UniversalContact.loadOrgContact().then((contact) => {
          log.info(`${ERR_TAG} Universal Contact request complete.`);
          // Returning the assignment ultimately returns the value, so this works.
          if (contact) {
            // If we actually got a contact, then upsert it
            return this.upsertContact(contact);
          } else {
            // Handle loading errors
            return this.universalContact = null;
          }
        });
      } else {
        return Promise.resolve(this.universalContact);
      }
    }

    get selectedContactSet() {
      return Object.values(_contactDataStore.trustedContacts).find(contactSet => contactSet.selected);
    }

    // Set the selected contactSet via email address or clear it with undefined.
    // If no matching record is found in the store, selectedContactSet will be undefined.
    set selectedContactSet(emailAddress) {
      // The null/undefined check is so that we can clear out selected if we want to
      // eslint-disable-next-line eqeqeq
      if (emailAddress != null && !emailAddress) {
        throw new TypeError(`${ERR_TAG}[selectedContactSet] Must provide an emailAddress or undefined when setting.`);
      }
      // No matter what, clear our old selection
      if (this.selectedContactSet) this.selectedContactSet.selected = false;
      // If we have a selectedContact in a different email set, clear it.
      if (this.selectedContact) {
        this.selectedContact.emails.primary_value !== emailAddress;
        this.selectedContact = undefined;
      }
      // We'll return either null or undefined
      let newSelection;
      if (emailAddress) {
        // Get the contact set from the store if we can
        newSelection = this.findContactSet(emailAddress);
        // If we've found the store's version, set its selected value
        if (newSelection) newSelection.selected = true;
      }
      return newSelection;
    }

    // Just a little somethin fancy for ya~
    // Easy access to a currently selected contact object (as defined by the selected key)
    // Note: THIS RETURNS A SPECIFIC CONTACT, NOT A SET.
    // Useful for things like editing an existing contact:
    // const contactToEdit = ContactData.initTmpContact(ContactData.selectedContact);
    // See the comments up by the _contactDataStore for usage of `tmp` and why this is helpful.
    get selectedContact() {
      if ((this.universalContact || {}).selected) {
        return this.universalContact;
      } else {
        const selectedContactSet = Object.values(_contactDataStore.trustedContacts)
          .find(contactSet => contactSet.selected) || {};
        return Object.values(selectedContactSet)
          .find(contact => contact.selected);
      }
    }

    // Set the selected contact from a TrustedContact record or clear it with undefined.
    // If no matching record is found in the store, selectedContact will be undefined.
    set selectedContact(contact) {
      // The null/undefined check is so that we can clear out selected if we want to
      // eslint-disable-next-line eqeqeq
      if (contact != null && !contact) {
        throw new TypeError(`${ERR_TAG}[selectedContact] Must provide a TrustedContact or undefined when setting.`);
      }
      // No matter what, clear our old selection
      if (this.selectedContact) this.selectedContact.selected = false;
      // Get the store's version of the contact if we've actually got one
      if (contact) {
        // Set the selectedContactSet; if no matching store record, it will clear.
        this.selectedContactSet = (contact.emails || {}).primary_value;
        // Same as above, but for the specific contact. If it's already equal to the UC, then it's legit
        // and we can just skip the contact set step.
        if (contact !== this.universalContact) {
          contact = (this.selectedContactSet || {})[contact.profile_id];
        }
        if (contact) contact.selected = true;
      }
      // No matter what, return the result
      return contact;
    }

    get universalContact() {
      return _contactDataStore.universalContact;
    }

    set universalContact(contact) {
      let newVal;
      if (contact instanceof this.UniversalContact) {
        newVal = contact;
      } else if (contact === null) {
        newVal = null;
      } else {
        log.error(`${ERR_TAG}[set:universalContact] New value must be a UniversalContact or null. Got:`, contact);
        // return early to avoid overwriting existing contact
        return _contactDataStore.universalContact;
      }
      _contactDataStore.universalContact = newVal;
      return _contactDataStore.universalContact;
    }

    // "Private" computed property to aid in testing - should never be used in prod.
    get _store() {
      return JSON.parse(JSON.stringify(_contactDataStore));
    }
  };
})());
