lib.registerState("manager.funkNotes", {
  url: "/campgrams",
  templateUrl: "states/manager/funk-notes/funk-notes.state.html",
  resolve: {
    title: function($rootScope) { return $rootScope._title = "CampGrams" }
  },
  params: {
    tab: "generate"
  },
  controller: function($scope, $http, $location, $filter, $state, session) {
    "use strict";

    $scope.tab = $state.params.tab;

    $scope.oneYearAgo = moment().subtract(1, 'year');
    $scope.oneYearFromToday = moment().add(1, 'year').format("YYYY-MM-DD");
    // This date is the safe lower bound of the first funkNotes deliverOn date
    $scope.beginningOfFunkNotes = "2015-01-01";

    if($filter('permissionVisible')({ access_funknotes: 'allow' })) {
      getUnprinted();
      getForThisYear();
    }

    function getUnprinted() {
      $http.get(`/api/organizations/${$scope.organization.id}/funk-notes?unprinted=true`).then(({ data }) => {
        $scope.totalUnprinted = data;
        $scope.todayUnprinted = _.filter(data, deliverOnToday);
      })
    }

    function deliverOnToday(funkNote) {
      return moment(funkNote.deliverOn).utc().format("YYYY-MM-DD") === moment().utc().format("YYYY-MM-DD");
    }

    function getForThisYear() {
      $http.get(`/api/organizations/${$scope.organization.id}/funk-notes?after=${$scope.oneYearAgo.toISOString()}`).then(({ data }) => {
        $scope.totalThisYear = data;
      })
    }

    // Initialize scope variables for the filter-builder
    $scope.report = { date: moment().format("YYYY-MM-DD") };
    $scope.profiles = undefined;
    $scope.lists = [];
    $scope.activeList = "";
    $scope.user = session.user || {};

    // Initialize options for Funk Notes report - taken from export builder
    $scope.organizationTags = _($scope.organization.tags).filter((tag) => {
      return !tag.parentID;
    }).map((tag) => {
      return { label: tag.value, value: tag.id };
    }).sortBy("label").value();

    $scope.organizationTags.unshift({ label: "None", value: "none" });
    $scope.report.funkNotesSortByTag = "none";
    $scope.funkNotesPrintChoices = [{ label: "Not Included", value: "false" }, { label: "Included", value: "true" }];
    $scope.report.funkNotesPreviouslyPrinted = "false";
    $scope.report.funkNotesStartDate = moment().format("YYYY-MM-DD");
    $scope.report.funkNotesEndDate = moment().format("YYYY-MM-DD");

    $scope.generateName = () => {
      $scope.s3ReportName = `CampGrams (${$scope.report.funkNotesStartDate} to ${$scope.report.funkNotesEndDate})`;
    }

    $scope.generateName();

    $scope.requestReport = () => {
      return nameReport().then(() => {
        return $http.post(generateReqUrl(), generateReqBody()).then(() => {
          setTimeout(getUnprinted, 3000);
          return swal({
            title: "Report Request Successful",
            text: "Your CampGrams report will appear below when it is done. It may take up to 5 minutes for your report to appear.",
            type: "success",
            showCancelButton: false,
            closeOnConfirm: true,
            allowOutsideClick: true
          });
        })
      }).catch((err) => {
        const errorConfig = {
          title: "Error Requesting Report",
          text: "Please refresh the page and try again.",
          type: "error",
          showCancelButton: false,
          closeOnConfirm: true
        }
        if (err.status == 404) {
          errorConfig.text = err.data && err.data.message || err.statusText;
        }
        else if (err.message == "Report Not Named") {
          errorConfig.title = err.message;
          errorConfig.text = "Request your report again and be sure to name it.";
        }
        return swal(errorConfig);
      })
    }

    function generateReqUrl() {
      const urlBase = `/api/organizations/${$scope.organization.id}/funk-notes-reports`;
      const urlFilterstring = `?filters=${$location.search().filters || ""}`;

      return urlBase + urlFilterstring;
    }

    function generateReqBody() {
      const name = $scope.s3ReportName;
      let tagSort, printed, gramStart, gramEnd;

      if ($scope.report.funkNotesSortByTag && $scope.report.funkNotesSortByTag != "none") {
        tagSort = $scope.report.funkNotesSortByTag;
      }
      if ($scope.report.funkNotesPreviouslyPrinted) {
        printed = $scope.report.funkNotesPreviouslyPrinted;
      }
      if ($scope.report.funkNotesStartDate) {
        gramStart = $scope.report.funkNotesStartDate;
      } else { gramStart = moment().format("YYYY-MM-DD"); }
      if ($scope.report.funkNotesEndDate) {
        gramEnd = $scope.report.funkNotesEndDate;
      }
      else { gramEnd = moment().format("YYYY-MM-DD"); }
      return { name, tagSort, printed, gramStart, gramEnd };
    }

    function nameReport() {
      return new Promise((resolve, reject) => {
        return swal({
          title: 'Name Your Report',
          text: 'Please enter a name for your report download.',
          type: 'input',
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          confirmButtonText: 'Submit',
          showCancelButton: true,
          inputValue: $scope.s3ReportName
        }, (input) => {
          if (!input) return reject(new Error("Report Not Named"))
          $scope.s3ReportName = input;
          return resolve();
        })
      })
    }

    $scope.filters = [];

    // Don't have to click 'Add Condition' on start
    if (!$scope.filters.length) {
      $scope.filters.push(new window.lib.Filter());
    }

  }
})
