angular.module("dn").directive "qbHeading", ->
  templateUrl: "directives/builder/heading/qb-heading.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbHeading"
    isClipped: "="
    builder: "="
    form: "="
    group: "="
  controller: ($scope) ->
    $scope.builder        = $scope.$parent.builder
    $scope.cancel         = $scope.$parent.cancel
    $scope.cut            = $scope.$parent.cut
    $scope.copy           = $scope.$parent.copy
    $scope.deleteQuestion = $scope.$parent.deleteQuestion
    $scope.moveDown       = $scope.$parent.moveDown
    $scope.moveUp         = $scope.$parent.moveUp
    $scope.paste          = $scope.$parent.paste
    $scope.reset          = $scope.$parent.reset
