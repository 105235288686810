lib.registerState "manager.profiles",
  url: "/profiles"
  templateUrl: "states/manager/profiles/profiles.state.html"
  reloadOnSearch: false
  params:
    tab: "listBuilder"
  resolve:
    title: ($rootScope) ->
      # if this changes, update profile.state.coffee's revertTitle function
      $rootScope._title = "Profiles & Reports"

    savedFilters: ($http, organization, session) ->
      $http.get("/api/users/#{session.userID}/filters").then (result) ->
        saved = result.data
        $http.get("/api/groups/#{organization.id}/filters").then (result) ->
          _(saved)
            .concat result.data
            .filter (d) -> d.type isnt "health-log"
            .value()

    labelTypes: ($http) ->
      $http.get("/api/constants/label-types").then (result) ->
        vals = _.map result.data, (d) -> { label: d.constant.name, value: d.constant.type }
        _.sortBy vals, "label"

    reviewTypes: ($http, organization) ->
      $http.get("/api/groups/#{organization.id}/review-types").then (result) ->
        _.filter result.data, (r) -> !r.deactivated

    messageTemplates: ($http, organization, provider) ->
      $http.get("/api/organizations/#{organization.id}/templates?type=message").then (result) ->
        return result.data

    indexedOrg: (organization) ->
      # this creates a flat object that allows easy access to any group within the org
      # eg: { 21437: {...}, 21438: {...}, 21439: {...} }
      # it is used primarily in profile-registrations to attach groups to registrations
      # it is placed here to prevent it from re-running every time a profile is selected
      indexOrg = (organization) ->

        flatten = (children, flat) ->
          children.map((group) ->
            flat[group.id] = _.pick(group, GROUP_PROPS)
            if (group.children)
              group.children.map((child) ->
                if (!child.properties.start) then child.properties.start = group.properties.start;
                if (!child.properties.finish) then child.properties.finish = group.properties.finish;
                child.parent = { name: group.name };
              )
              flatten(group.children, flat);
          )
          return flat;

        GROUP_PROPS = [ "id", "name", "classification", "parentID", "parents", "phase", "deactivated", "registration", "shortName", "properties", "compiledProperties", "parent" ];
        flat = {}
        flat[organization.id] = _.pick(organization, GROUP_PROPS);
        return flatten(organization.children,  flat);

      return indexOrg(organization)

  controller: ($scope, $state, session, savedFilters, $location, $timeout, $http, labelTypes, reviewTypes, provider, flash, messageTemplates, $filter) ->

    hasSavedListPermissions = (list) ->
      return $filter('permissionVisible')({ manage_providers: "allow" }) if list.match(/\[roles\]\.id/)
      return $filter('permissionVisible')({ financial_ledgers: "view" }) if list.match(/balance\.amount|\[paymentPlans\]|\[paymentMethods\]/)
      return $filter('permissionVisible')({ profile_questionnaire: "view" }) if list.match(/answerMap\.|multi-auth\.\d*:\d*/)
      return true

    $scope.provider ?= provider
    defaultListString = "[registrations].type(is:patient)|phase(isnt:past)"
    savedDefaultList = provider?.profile?.properties?.preferences?.jumpToList
    isFirstLoad = true

    if savedDefaultList and hasSavedListPermissions(savedDefaultList)
      $scope.listJump = savedDefaultList
    else
      $scope.listJump = defaultListString

    $scope.activeList =
      text: $location.search().filters || $scope.listJump || ""

    $scope.state = $state
    $scope.tab = $state.params.tab
    $scope.user = session.user or {}
    $scope.user?.filters = _.filter savedFilters, (f) -> f.type is "profiles-manager" and hasSavedListPermissions(f.string)
    $scope.user?.savedReports = _.filter savedFilters, (s) -> s.type is "report"
    $scope.selectedProfileIDs = []
    $scope.selectedProfileNames = []
    $scope.totalProfileAmount = 0
    $scope.isSelectAll = false
    $scope.isSelectNone = false

    # Attach constant to org to avoid blowing up <export-builder>
    $scope.organization.labelTypes = labelTypes
    $scope.organization.reviewTypes = reviewTypes

    Filter = window.lib.Filter

    $scope.filters = []

    $scope.presets = [
      {
        label: "#{division?.patient?.plural?.capitalize() || 'Patient'} (current)"
        value: defaultListString
        optgroup: "Presets"
      }
      {
        label: "#{division?.patient?.plural?.capitalize() || 'Patient'} (incomplete)"
        value: "[registrations].type(is:patient)|phase(isnt:past)|healthFormComplete(is:false)"
        optgroup: "Presets"
      }
      {
        label: "Bounced Emails (current)"
        value: "[profilesUsers].inviteBounce(is:true)|phase(isnt:past)"
        optgroup: "Presets"
      }
      {
        label: "Providers (current)"
        value: "[registrations].type(is:provider)|phase(isnt:past)"
        optgroup: "Presets"
      }
      {
        label: "Providers (past)"
        value: "[registrations].type(is:provider)|phase(is:past)"
        optgroup: "Presets"
      }
      {
        label: "#{division?.patient?.plural?.capitalize() || 'Patient'} (today)"
        value: "[registrations].type(is:patient)|phase(is:present)"
        optgroup: "Presets"
      }
    ]

    # Create the aggregate list of filters to select from by adding
    # saved filters to the list of presets
    savedFilters = _.map $scope.user.filters, (s) ->
      return { label: s.name, value: s.string, optgroup: "Shared", owner: s.userID is session.userID } if s.share is true
      return { label: s.name, value: s.string, optgroup: "Custom", owner: s.userID is session.userID }

    $scope.lists = $scope.presets.concat savedFilters

    # Display list options alphabetically while respecting optgroup
    $scope.displayLists = _.sortBy $scope.lists, ['optgroup', 'label'], ['desc', 'asc']

    $scope.$watch "listJump", (jumpToList, stale) ->
      return if jumpToList is stale
      # Set the current filter string to jumpToList
      $scope.activeList.text = jumpToList
      $scope.selectNone()
      # Update the profile count when updating the Default Lists
      if $scope.isFullTextSearchEnabled()
        resetScroll()

      if $scope.provider
        $scope.provider.profile.properties.preferences ?= {}
        $scope.provider.profile.properties.preferences.jumpToList = jumpToList
        $scope.provider.profile.save(["properties"])

    # Set our default filters to the first default if we don't have any in the url
    $timeout ->
      if not $scope.filters.length then $scope.activeList.text = $scope.listJump

      find = _.find $scope.lists, { value: _.map($scope.filters, "string").join("|") }
      if find then $scope.activeList.text = find.value

    # Add filters to child state URLs
    $scope.$watch "state.current.name", ->
      if not $state.params.filters and $scope.filters.length
        addFilters = _.map $scope.filters, (f) -> new Filter(f.object)
        $location.search "filters", _.map(addFilters, "string").join("|")
    , true

    sortCollator = new Intl.Collator('en')
    $scope.messageTemplates = messageTemplates.sort (a, b) ->
      sortCollator.compare(a.name, b.name)

    # Clear active "power tools"
    # If supplied an arg, go to that tab
    $scope.clearTools = (tab) ->
      $scope.activeTool = null
      return unless tab
      $scope.tab = tab

    # Init active tool value
    $scope.clearTools()

    # Legacy method to select profiles
    # Display the amount of profiles we've selected
    $scope.$watch ->
      if $scope.isFullTextSearchEnabled() then return
      $scope.selectedProfileIDs = _.map((_.filter $scope.profiles, (p) -> p.selected), "profiles.id")
      $scope.selectedProfileIDs.length
    , (amount) ->
      $scope.selectedProfiles = amount
    , true

    # New function to select a profile (feature flagged)
    $scope.selectProfile = (profile) ->
      if $scope.isFullTextSearchEnabled()
        profile.selected = !profile.selected
        $scope.isSelectAll = false
        $scope.isSelectNone = false
        # Checks to see if the selectedProfileIDs are also undefined, if so run this.
        if profile.selected and !$scope.selectedProfileIDs.includes(profile.profiles.id)
          $scope.selectedProfileIDs.push(profile.profiles.id)
        else if !profile.selected and $scope.selectedProfileIDs.includes(profile.profiles.id)
          $scope.selectedProfileIDs = _.without($scope.selectedProfileIDs, profile.profiles.id)
        $scope.selectedProfiles = $scope.selectedProfileIDs.length
        getSelectedProfilesNames(profile.profiles.id)

    # New function to select all profiles (feature flagged)
    $scope.selectAll = ->
      if $scope.isFullTextSearchEnabled()
        filters = encodeURIComponent($location.search().filters or "")
        $http.get("/api/organizations/#{$scope.organization.id}/profile-select-count?all=true&filters=#{filters}",
          params: { profileSearch: $scope.search }
        ).then (response) ->
            $scope.selectedProfiles = response?.data?.length
            $scope.selectedProfileIDs = [..._.map response?.data, (p) -> p.profiles]
            # now that we know which profiles should be selected, we need to select those profiles
            # as they are paginated
            _.each $scope.profiles, (profile) ->
              profile.selected = $scope.selectedProfileIDs.includes(profile.profiles.id)
            # getSelectedProfilesNames(true, null)
            getProfileNamesSelectAll()
      else
        # Legacy methods to select all profiles
        _.map $scope.profiles, (p) -> p.selected = true

    # Clear all selected profiles
    $scope.selectNone = ->
      if $scope.isFullTextSearchEnabled()
        # clear out selectedProfiles and selectedProfileIDs
        _.map $scope.profiles, (p) -> p.selected = false
        $scope.isSelectAll = false
        $scope.selectedProfiles = 0
        $scope.selectedProfileIDs = []
        # getSelectedProfilesNames(null, null)
        $scope.selectedProfileNames = []
      else
        # Legacy methods to select no profiles
        _.map $scope.profiles, (p) -> p.selected = false

    # Check if we're on an individual's page
    $scope.profileState = ->
      $state.current.name isnt "manager.profiles"

    # populate 'Profiles Jump To' dropdown with org-set aliases if present
    groupAliases = $scope.organization.properties?.portals?.patient?.sections
    jumpToList =
      healthProfile: -> groupAliases?.questionnaire?.alias or "Health Profile"
      registration: -> groupAliases?.registration?.alias or "Registrations"
      account: -> groupAliases?.account?.alias or "Account"

    $scope.jumpListChoices = [
      { label: jumpToList.registration(), value: 'registrations', permissions: { profile_registrations: 'view' } }
      { label: 'Tags', value: 'tags', permissions: { profile_tags: 'view' } }
      { label: jumpToList.account(), value: 'account', hide: !$scope.organization.shortName, permissions: { profile_account: 'view' } }
      { label: 'Users', value: 'users', permissions: { profile_users: 'view' } }
      { label: 'Notifications', value: 'notifications', permissions: { profile_notifications: 'view' } }
      { label: jumpToList.healthProfile(), value: 'questionnaire', permissions: { profile_questionnaire: 'view' } }
      { label: 'Medications', value: 'medications', permissions: { profile_medications: 'view' } }
      { label: 'Health Log', value: 'log', permissions: { health_log: 'view', behavioral_health: 'view' } }
    ].filter (choice) -> $filter('permissionVisible')(choice.permissions)

    jumpPreference = provider?.profile?.properties?.preferences?.jumpTo

    findJumpListDefault = (option = "registrations") ->
      $scope.profileJump = $scope.jumpListChoices.find((choice) -> choice.value == option)?.value || $scope.jumpListChoices[0]?.value

    findJumpListDefault(jumpPreference)

    $scope.$watch "profileJump", (jumpTo, stale) ->
      return if jumpTo is stale or !$scope.provider?.profile
      $scope.provider.profile.properties.preferences ?= {}
      $scope.provider.profile.properties.preferences.jumpTo = jumpTo
      $scope.provider.profile.save(["properties"])

    # Keep track of profile list properties to enable infinite scrolling
    profileList = $('.profile-list')
    $scope.list =
      rendered: -> profileList?[0]?.scrollHeight
      scrolled: -> profileList?[0]?.scrollTop
      height: -> profileList?.height()
      limit: 30
      mostScrolled: profileList?[0]?.scrollTop

    $scope.reviews = {}

    $scope.isFullTextSearchEnabled = () ->
      return window.lib.featureFlagClient.isEnabled("FullTextSearchProfiles")

    # Performs client-side search on profiles only if FullTextSearch feature flag is turned off
    $scope.filteredProfiles = () ->
      if $scope.isFullTextSearchEnabled()
        return $scope.profiles;
      else
        return $filter('textWhitespace')($scope.profiles, $scope.search);

    $scope.offset = 0
    $scope.search = ""
    paginationDebounceTime = if $scope.isFullTextSearchEnabled() then 500 else 0

    # watch isSelectAll and isSelectNone to update the selectedProfiles count
    $scope.$watch "isSelectAll", (isSelectAll, stale) ->
      return unless isSelectAll isnt stale
      if isSelectAll then $scope.selectAll()
      $scope.isSelectNone = false


    $scope.$watch "isSelectNone", (isSelectNone, stale) ->
      return unless isSelectNone isnt stale
      if isSelectNone then $scope.selectNone()
      $scope.isSelectAll = false

    # Watch our scroll position, add additional rows as necessary
    # We add more rows when near the bottom of the list vs the top
    # Delays by 500ms to prevent excessive requests
    $scope.$watch "list.scrolled()", _.debounce((scrolled) ->
      return if scrolled <= $scope.list.mostScrolled
      $scope.list.mostScrolled = scrolled
      percent = ($scope.list.height() + $scope.list.scrolled()) / $scope.list.rendered()

      if $scope.isFullTextSearchEnabled()
        # When user is at the bottom of the profile results, load more profiles in
        if percent > 0.99 and $scope.list.limit <= $scope.profiles.length
          $scope.offset += 30;
          $scope.list.limit += percent * 25
          filters = encodeURIComponent($location.search().filters or "")

          # Get profiles for search results
          setSearchParams = {
            profileSearch: encodeURIComponent($scope.search),
          }
          getProfiles(setSearchParams, true)
      else
        # Legacy pagination functionality
        if percent > 0.2 and $scope.list.limit < $scope.profiles.length
          $scope.list.limit += percent * 25
    , paginationDebounceTime)

    # Reset the scroll position and
    resetScroll = () ->
      $scope.list.mostScrolled = 0
      $scope.list.limit = 30
      $scope.offset = 0

    # update active List (list of displayed profiles) when filters change
    $scope.$watch 'activeList', (filters, stale) ->
      return if _.isEqual(filters, stale)
      $scope.selectNone()
      resetScroll()

    # Searching with a high list.limit is not performant, it's a new list anyway
    $scope.$watch 'search', _.debounce((search, stale) ->
      # Don't search if the search hasn't changed and if on first load
      return unless search isnt stale
      return resetScroll() if !$scope.isFullTextSearchEnabled()

      resetScroll()
      setSearchParams = {
        profileSearch: search,
      }

      # Get profiles for search results
      getProfiles(setSearchParams)
    , 500)
    , true

    # Get profiles for search results and pagination
    getProfiles = (setSearchParams, paginate = false) ->
      filters = encodeURIComponent($location.search().filters or "")

      setLimitAndOffset = "offset=#{$scope.offset}&limitResults=30"

      # These are the filters that require post processing that are the exception to limiting profile results.
      postProcessFilters = [
        '[users].email',
        '[paymentPlans].remaining',
        '[allergies].type(in:food_drug_environmental)',
        '[registrations].type(is:false)'
      ]

      # Check if the user has any of the post process filters
      # If so, we should retrieve the whole list of profiles in the response
      # AP-7634 TODO: We should consider revamping the post processing to work in tangent with our pagination performance improvements
      # at a later point in the software cycle.
      if $location.search().filters and !paginate
        hasPostProcessFilter = _.some postProcessFilters, (filter) ->
          return $location.search().filters.includes filter

        if hasPostProcessFilter
          setLimitAndOffset = "offset=#{$scope.offset}"

      profileSearching = if setSearchParams.profileSearch then "profileSearch=#{setSearchParams.profileSearch}&" else ''

      $http.get("/api/organizations/#{$scope.organization.id}/profiles?#{setLimitAndOffset}&#{profileSearching}minimizeProfiles=true&filters=#{filters}")
        .then (response) ->
          # select the profiles which are to be selected
          if $scope.selectedProfileIDs.length
            response.data = _.map response.data, (profile) ->
              profile.selected = $scope.selectedProfileIDs.includes(profile.profiles.id)
              return profile
          if paginate
            $scope.profiles = $scope.profiles.concat response.data
          else
            $scope.profiles = response.data

    # get the names for all profiles
    # This method is used by the power tools (manage permissions) to display the selected profiles names
    # This method can only fetch 30 profiles, so it will need rethinking
    getProfileNamesSelectAll = () ->
      $scope.selectedProfileNames = _.map($scope.profiles, (p) ->
        return {
          profiles: {
            id: p.profiles.id,
            familyName: p.profiles.familyName,
            givenName: p.profiles.givenName,
            middleName: p.profiles.middleName,
          },
          selected: p.selected
        }
      )

    # get the names for all selected profiles
    # This method is used by the power tools (manage permissions) to display the selected profiles names
    getSelectedProfilesNames = (selectedprofileID) ->
      profileIndex = $scope.profiles.findIndex((p) -> p.profiles.id == selectedprofileID && p.selected)
      if ($scope.profiles[profileIndex])
        $scope.selectedProfileNames.push({
          profiles: {
            id: $scope.profiles[profileIndex].profiles.id,
            familyName: $scope.profiles[profileIndex].profiles.familyName,
            givenName: $scope.profiles[profileIndex].profiles.givenName,
            middleName: $scope.profiles[profileIndex].profiles.middleName,
          },
          selected: $scope.profiles[profileIndex].selected
        })
      else
        $scope.selectedProfileNames.splice($scope.selectedProfileNames.findIndex((p) -> p.profiles.id == selectedprofileID), 1)


    # Get the total number of profiles to display on the UI
    $scope.$on 'profilesEvent', (events, args) ->
      if args?.profileCount
        $scope.totalProfileAmount = args.profileCount
      if args?.profiles
        $scope.profiles = args.profiles
