angular.module('dn').directive('daxkoSync', function () {
  return {
    templateUrl: 'directives/importer/daxko-sync/daxko-sync.directive.html',
    restrict: 'E',
    scope: {
      organization: '='
    },
    controller: function($http, $scope) {
      $scope.daxkoSettings = {};
      loadDaxkoSettings();

      function loadDaxkoSettings() {
        const baseURL = `/api/organizations/${$scope.organization.id}/daxko-settings`;
        $http.get(baseURL).then((response) => {
          if (response.status === 200) {
            $scope.daxkoSettings = response.data;
            $scope.daxkoSettings.enabled = true;
          }
        }).catch((err) => {
          window.log.error('Unable to load Daxko Settings', err);
        });
      }
    }
  };
});
