lib.registerState('manager.attendance.list.profiles.profile.notes', {
  url: '/notes',
  templateUrl: 'states/manager/attendance/profiles/profile/notes/notes.state.html',
  controller: function($scope, AttendanceData) {
    'use strict';

    $scope.attendanceData = AttendanceData;
    $scope.selectedProfiles = AttendanceData.selectedProfiles;

    // Copy notes and priorities from the source temp note to all other temp notes
    $scope.copyNotes = function (sourceNote) {
      AttendanceData.selectedProfiles.forEach((profile) => {
        if (profile.id === sourceNote.profileID) return;
        const tmpNote = AttendanceData.getTmpNote(profile.id);
        tmpNote.body = sourceNote.body;
        tmpNote.priority = sourceNote.priority;
      });
    };
  }
});
