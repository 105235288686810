lib.registerState('patient.profile.prescreening.submission', {
  url: '/submission',
  templateUrl: 'states/patient/profile/prescreening/submission/submission.state.html',
  resolve: {
    title($rootScope) {
      return $rootScope._title = 'Submission Received';
    }
  },
  controller($scope, $rootScope, $state, campgramsOnly, PrescreeningData) {
    'use strict';

    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    // Branding
    $scope.submissionBranding = PrescreeningData.branding.submission;

    // Timestamp
    const timezone = $rootScope.organization.properties.timezone;
    $scope.timestamp = window.dnim.ScreeningFormatter.formatters.timestamp(new Date().toISOString(), timezone);

    // If the form is not filled out and the user enters this state (url hack/reload) send them back to the form
    if (!PrescreeningData.verifyFormData()) $state.go('patient.profile.prescreening');

    // Clear the store data since we are done submitting the form
    PrescreeningData.clearTemplate();
    PrescreeningData.clearTmp();


    // Button Configuration
    const buttonConfig = {
      back: {
        text: 'Return Home',
        hide: false,
        disabled: false,
        action() {
          return $state.go('patient.profile');
        }
      },
      next: {
        text: 'Continue',
        hide: true,
        disabled: false,
        action() {
          return;
        },
      },
    };

    $scope.$on('$stateChangeSuccess', () => {
      PrescreeningData.setUiEntry('buttons', buttonConfig);
    });
  }
});
