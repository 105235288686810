lib.registerState('manager.settings.builder.editor', {
  url: '/editor/:draftsetID',
  templateUrl: 'states/manager/settings/builder/editor/editor.state.html',
  resolve: {
    title: function ($rootScope, group) { return $rootScope._title = `${group.name} / Builder`; },
    parcelConfig: function () { return window.System.import('@dn/builder'); },
    parcelProps: function(organization) {
      return {
        org: organization
      };
    }
  },
  controller: function($scope, parcelConfig, parcelProps) {
    $scope.parcelConfig = parcelConfig;
    $scope.parcelProps = parcelProps;
  }
});
