lib.registerState('manager.groups.registration.setup.coupon', {
  url: '/coupons/:couponID',
  templateUrl: 'states/manager/groups/registration/setup/coupons/inspect-coupon.state.html',
  resolve: {
    coupon(Coupon, $stateParams) {
      if ($stateParams.couponID === 'new') {
        return new Coupon({
          groupID: parseInt($stateParams.groupID),
          glCode: null,
          gl_code_id: null
        });
      }
      return new Coupon({
        id: $stateParams.couponID,
        groupID: $stateParams.groupID
      }).load();
    },
    defaultGlCodes($http, $state, $stateParams) {
      return $http.get(`/api/group/${$stateParams.orgID}/gl-codes/defaults`).then(
        function (success) { return success.data; },
        function () {
          window.swal({
            title: 'Page Error',
            type: 'error',
            text: 'There was an error loading default GL Code data.  \n\n Please reload and try again. If this error continues to occur, please contact our support team with the contents of this message and the URL of this page.',
            confirmButtonText: 'Reload'
          }, () => $state.reload());
        }
      );
    },
  },
  controller($scope, $state, $filter, coupon, flash, defaultGlCodes) {
    $scope.defaultGlCodes = defaultGlCodes;

    $scope.coupon = coupon;
    if ($scope.coupon.capacity) { $scope.coupon.hasCapacity = true; }
    if ($scope.coupon.expires) { $scope.coupon.hasExpires = true; }

    $scope.allowEdit = $filter('permissionVisible')({ roster_registration_setup: 'edit' });

    $scope.$watch('coupon.hasCapacity', (has) => {
      if (has) { return; }
      return $scope.coupon.capacity = 0;
    });

    $scope.$watch('coupon.shared', (shared, stale) => {
      if (shared === stale) return;
      if (!shared) {
        $scope.coupon.multiUse = null;
        return $scope.coupon.groupID = $scope.group.id;
      }
    });

    $scope.$watch('coupon.hasExpires', (has) => {
      if (has) { return; }
      return $scope.coupon.expires = null;
    });

    $scope.$watch('coupon.properties.bypassDeposit', (bypass) => {
      if (bypass) { $scope.coupon.amount = 0; }
    });

    $scope.$watch('coupon.amount', (amount) => {
      if (!coupon.properties) coupon.properties = {};
      if (amount) { $scope.coupon.properties.bypassDeposit = false; }
    });

    function getGroupChoices() {
      // eslint-disable-next-line no-unused-vars
      var foundAll = false;
      var parents = [ $scope.group ];
      var addParent = (group) => {
        if (!group.parent) { return; }
        parents.push(group.parent);
        return addParent(group.parent);
      };

      addParent($scope.group);
      return _.map(parents, (group) => { return { label: group.name, value: group.id }; });
    }

    $scope.groupChoices = getGroupChoices();

    $scope.deactivate = () => {
      $scope.coupon.destroy().then(() => {
        flash('Coupon Deactivated');
        return $state.go('^', undefined, {reload: true});
      });
    };

    $scope.reactivate = () => {
      $scope.coupon.deactivated = null;
      return $scope.coupon.save().then(() => {
        flash('Coupon Reactivated');
        return $state.go('^', undefined, {reload: true});
      });
    };

    $scope.usingDefaultGLCode = !$scope.coupon.gl_code_id;
    let previousGLCode = $scope.coupon.glCode;
    let previousGLCodeID = $scope.coupon.gl_code_id;

    $scope.setGlCodeToDefault = (defaultValue) => {
      previousGLCode = $scope.coupon.glCode;
      previousGLCodeID = $scope.coupon.gl_code_id;
      $scope.coupon.glCode = defaultValue || null;
      $scope.usingDefaultGLCode = true;
      $scope.coupon.gl_code_id = null;
      $scope.coupon.usingDefaultGLCode = $scope.usingDefaultGLCode;
    };
    $scope.undoGlCodeToDefault = () => {
      $scope.coupon.glCode = previousGLCode;
      $scope.coupon.gl_code_id = previousGLCodeID;
      $scope.usingDefaultGLCode = false;
      $scope.coupon.usingDefaultGLCode = $scope.usingDefaultGLCode;
    };

    $scope.couponFieldsToSave = (saveScope) => {
      const fields = Object.keys(saveScope.resource);

      if ($scope.usingDefaultGLCode) {
        return fields.filter((field) => {
          return ![ 'glCode', 'gl_code_id' ].includes(field);
        });
      } else {
        return fields;
      }
    };

    $scope.disableSave = (coupon) => {
      const noCode = !coupon.code;
      const noAmountWithoutBypass = !coupon.amount && coupon.properties && !coupon.properties.bypassDeposit;
      const noGLCode = !$scope.usingDefaultGLCode && !coupon.glCode;

      return _.some([noCode, noAmountWithoutBypass, noGLCode]);
    };
  }
});
