const Resource = angular.injector(["dn"]).get("Resource");

angular.module("dn").factory("Mar", function ($http) {

  const Mar = new Resource({
    route: "/api/profiles/:profileID/mars/:id",
    schema: {
      medicationID: {
        type: "integer",
        required: true
      },
      profileID: {
        type: "integer",
        required: true
      },
      providerID: {
        type: "integer",
        required: true
      },
      date: {
        type: "date",
        required: true
      },
      slot: {
        type: "slot",
        required: true
      },
      note: {
        type: "text"
      },
      notGiven: {
        type: "boolean"
      },
      recordedAt: {
        type: "timestamp"
      },
      mistaken: {
        type: "boolean"
      },
      pending: {
        type: "boolean"
      },
      properties: {
        type: "json"
      }
    }
  });

  Mar.prototype.status = function() {
    if (!this.id) { return "fresh"; }
    if (this.pending) { return "pending"; }
    if (this.notGiven) { return "notGiven"; }
    if (this.error) { return "error"; }
    return "given";
  };

  Mar.prototype.initials = function() {
    if (!this.provider || !this.provider.givenName || !this.provider.familyName) return;
    return this.provider.givenName.charAt(0) + this.provider.familyName.charAt(0);
  };

  Mar.prototype.initSpecifyTime = function() {
    if (!this.specifyTime) {
      this.customTime = null;
      if (!this.id) delete this.recordedAt;
    } else if (this.id) {
      this.customTime = moment(this.recordedAt).format("HH:mm");
    } else {
      this.customTime = moment().format("HH:mm");
      this.recordedAt = moment(`${this.date}T${this.customTime}`).toISOString();
    }
  };

  Mar.prototype.setRecordedAt = function() {
    if (!this.customTime || !this.specifyTime) return;
    this.recordedAt = moment(`${this.date}T${this.customTime}`).toISOString();
  };

  return Mar;
});
