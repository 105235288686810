'use strict';

angular.module('dn').service('OrgProviders', (() => {
  const ERR_TAG = '[OrgProviders]';
  const USER = {
    factory: 'User',
    subCollection: 'users'
  };
  const PROFILE = {
    factory: 'Profile',
    subCollection: 'profiles'
  };
  const FACTORY_NAMES = [
    USER.factory,
    PROFILE.factory
  ];
  const SUB_COL_NAMES = [
    USER.subCollection,
    PROFILE.subCollection
  ];
  const PROVIDER_FILTERS = [
    '[registrations].type(is:provider)',
  ];

  const _orgProviderStore = {
    profiles: [],
    profilesSelectOptions: [],
    users: [],
    usersSelectOptions: [],
    lastUpdated: null,
  };

  return class OrgProviders {
    constructor($http, $rootScope, Profile, User) {
      // Get access to Angular stuff
      this.$http = $http;
      this.$rootScope = $rootScope;
      this.Profile = Profile;
      this.User = User;
      // Internal properties
      Object.defineProperty(this, 'lastUpdated', {
        enumerable: true,
        configurable: false,
        get() {
          return _orgProviderStore.lastUpdated;
        }
      });
      // TODO: this is experimental. Make sure it works.
      this.baseUrl = `/api/organizations/${$rootScope.organization.id}/profiles`;
    }

    _store() {
      return _.cloneDeep(_orgProviderStore);
    }

    _generateCollectionsUrl({ includeUsers }) {
      const filters = `&filters=${encodeURIComponent(PROVIDER_FILTERS.join('|'))}`;
      const include = includeUsers ? '&include=users' : '';
      return `${this.baseUrl}?attendance=true${filters}${include}`;
    }

    _rawToResource(factoryName, raw) {
      if (!factoryName || typeof factoryName !== 'string' || !FACTORY_NAMES.includes(factoryName)) {
        throw Error(`${ERR_TAG}[_rawToResource] Bad factory name "${factoryName}". Allowed names: ${FACTORY_NAMES.join(', ')}.`);
      }
      return new this[factoryName](raw);
    }

    _findByID(typeObj, id) {
      return _orgProviderStore[typeObj.subCollection].find(resource => {
        return resource.id === parseInt(id);
      });
    }

    // Unlike most of the other factories, we don't really intend to edit these resources. Not at the
    // time of writing, anyway. As a result, we're not gonna actually expose any upsert functions. All
    // interaction should be INPUT from collections. No need to alter and save individuals.
    _upsertResource(typeObj, raw) {
      let storeResource = this._findByID(typeObj, raw.id);
      if (!storeResource) {
        const shaped = Object.assign({}, this[typeObj.factory].blankSchema(), raw);
        storeResource = this._rawToResource(typeObj.factory, shaped);
        _orgProviderStore[typeObj.subCollection].push(storeResource);
      } else {
        Object.assign(storeResource, raw);
      }
      return storeResource;
    }

    loadProviderCollection(collections) {
      this.clearStore();
      collections.forEach((collection) => {
        const storeUsers = (collection[USER.subCollection] || []).map(user => this._upsertResource(USER, user));
        const storeProfile = this._upsertResource(PROFILE, collection[PROFILE.subCollection] || {});
        // Link em up.
        if (storeProfile) {
          if (!storeProfile.users) storeProfile.users = [];
          storeProfile.users.concat(storeUsers);
        }
        if (storeUsers.length) {
          storeUsers.forEach((user) => {
            if (!user.profiles) user.profiles = [];
            user.profiles.push(storeProfile);
          });
        };
      });
    }

    generateSelectOptions(subColName) {
      if (!SUB_COL_NAMES.includes(subColName)) {
        throw Error(`${ERR_TAG}[generateSelectOptions] Invalid subcollection name "${subColName}". Allowed names: ${SUB_COL_NAMES.join(', ')}.`);
      }
      try {
        _orgProviderStore[subColName].reduce((selectOptions, resource) => {
          const { givenName, familyName, id } = resource;
          let finalPiece;
          if (subColName === USER.subCollection) {
            finalPiece = ` (${resource.email})`;
          } else {
            finalPiece = resource.middleName ? ` ${resource.middleName}` : '';
          }
          const opt = {
            label: `${familyName}, ${givenName}${finalPiece}`,
            value: id
          };
          selectOptions.push(opt);
          return selectOptions;
        }, _orgProviderStore[`${subColName}SelectOptions`]);
      } catch (error) {
        console.error(`${ERR_TAG} Error creating provider dropdowns`, error)
      }
      return _orgProviderStore[`${subColName}SelectOptions`];
    }

    fetchProviders(options) {
      return this.$http.get(this._generateCollectionsUrl(options)).then((result) => {
        this.loadProviderCollection(result.data || []);
        return _orgProviderStore.lastUpdated = new Date();
      }).catch((err) => {
        if (!new RegExp(ERR_TAG).test(err.message)) {
          err.message = `${ERR_TAG}[fetchProviders] Failed to fetch org providers: ${err.message}`;
        }
        console.error(err);
        throw err;
      });
    }

    findProfileByID(profileID) {
      return this._findByID(PROFILE, profileID);
    }

    findUserByID(userID) {
      return this._findByID(USER, userID);
    }

    findUserByEmail(email) {
      return _orgProviderStore[USER.subCollection].find(user => user.email === email);
    }

    get userSelectOptions() {
      return _orgProviderStore.usersSelectOptions;
    }

    get profileSelectOptions() {
      return _orgProviderStore.profilesSelectOptions;
    }

    get users() {
      return _orgProviderStore.users;
    }

    get profiles() {
      return _orgProviderStore.profiles;
    }

    clearStore() {
      _orgProviderStore.profiles = [];
      _orgProviderStore.profilesSelectOptions = [];
      _orgProviderStore.users = [];
      _orgProviderStore.usersSelectOptions = [];
      _orgProviderStore.lastUpdate = null;
    }
  };
})());
