angular.module('dn').directive('merchantProcessingSettings', function () {
  return {
    templateUrl: 'directives/merchant-processing-settings/merchant-processing-settings.directive.html',
    restrict: 'E',
    scope: {
      group: '=',
      organization: '=',
    },
    controller: function ($http, $rootScope, $scope, flash) {
      $scope.yesNo = [
        {label: 'No', value: false},
        {label: 'Yes', value: true}
      ];
      $scope.showInputs = false;
      $scope.initializationErr = null;
      $scope.accountSettings = {};
      $scope.saveSettings = saveSettings;
      $scope.saving = false;
      $scope.deactivateMerchantIfFalse = deactivateMerchantIfFalse;
      $scope.payProcessorTypeChanged = payProcessorTypeChanged;
      const baseURL = `/api/organizations/${$scope.organization.id}/pay-processors`;
      const fields = ['merchant_name', 'processor_type'];

      const payProcessorTypes = [
        {
          label: 'iCheck',
          optionValue: 'iCheck',
          enabled: true,
          fields: [
            { label: 'SiteID', type: 'text' },
            { label: 'SiteKey', type: 'text' },
            { label: 'APIKey', type: 'password' },
          ],
        },
        {
          label: 'TouchNet',
          optionValue: 'TouchNet',
          enabled: true,
          fields: [
            { label: 'URL', type: 'text' },
          ],
        }
      ];

      $scope.payProcessorOptions = payProcessorTypes
        .filter((payProcessor) => payProcessor.enabled)
        .map((payProcessor) => {
          return {
            label: payProcessor.label,
            value: payProcessor.optionValue,
          };
        });

      /**
       * Reset the accountSettings config object when pay processor type is
       * changed.
       */
      function payProcessorTypeChanged() {
        const payProcessorType = payProcessorTypes.find((type) => {
          return type.optionValue === $scope.accountSettings.processor_type;
        });
        $scope.accountSettings.config = payProcessorType.fields.reduce((config, field) => {
          config[field.label] = null;
          return config;
        }, {});
        $scope.payProcessorFields = payProcessorType.fields;
      }

      loadSettings();

      /* End directive init */

      /*
      Loads settings from the API
      If it finds some, apply it to scope
      If not, fallback to defaults
      */
      function loadSettings() {
        return $http.get(baseURL)
          .then(({data}) => {
            if (data && data.length && data[0].id) {
              $scope.showInputs = true;
              $scope.savedPayProcessor = true;
              $scope.accountSettings = data[0];
            } else {
              $scope.accountSettings = setDefaults();
            }
          })
          .catch((err) => {
            flash('Error loading merchant settings');
            $scope.initializationErr = err;
          });
      }

      /*
      Constructor-like func for returning a default $scope.accountSettings
      */
      function setDefaults() {
        $scope.savedPayProcessor = false;
        $scope.payProcessorFields = [];
        return {
          merchant_name: null,
          processor_type: null,
          config: {}
        };
      }

      /*
      Handles POST, PUT, and DELETE reqs for account settings
      Disallows saving without all fields filled out
      Since `.config` won't be returned for an existing record, any record with an id
        will assume there's no point in checking config
      */
      function saveSettings() {
        const {id, deactivated} = $scope.accountSettings;
        const missingStaticFields = fields.some(f => !$scope.accountSettings[f]);
        const missingConfigFields = id ? false : Object.keys($scope.accountSettings.config).some(k => !$scope.accountSettings.config[k]);
        if (missingStaticFields || missingConfigFields) {
          flash('Missing required fields');
          return;
        }

        $rootScope.showProgress();
        $scope.saving = true;

        let method, idSlug;
        if (id) {
          method = deactivated ? 'delete' : 'put';
          idSlug = '/' + id;
        } else {
          method = 'post';
          idSlug = '';
        }

        $http[method](baseURL + idSlug, $scope.accountSettings)
          .then(res => handleSaveResponse(res, method))
          .catch(() => {
            flash('Error saving merchant settings');
          })
          .finally(() => {
            $rootScope.hideProgress();
            $scope.saving = false;
          });
      }

      /*
      Runs on buttonSwitch value change (showInputs)
      If it's been toggled to false, prompt user with a swal
      User acknowledges the deactivation, set the prop and fire off a save call
      */
      function deactivateMerchantIfFalse() {
        if ($scope.showInputs === false && $scope.accountSettings.id) {
          return swal({
            type: 'warning',
            title: 'Deactivate Merchant?',
            text: 'This will deactivate the merchant account and render existing payment method tokens unusable. Continue?',
            allowOutsideClick: true,
            showCancelButton: true,
          }, (isConfirm) => {
            if (isConfirm) {
              $scope.accountSettings.deactivated = new Date().toISOString();
              $scope.savedPayProcessor = false;
              saveSettings();
            } else {
              $scope.showInputs = true;
            }
          });
        }
      }

      /*
      Basic post-save call handling
      When we POST, overwrite existing scope data to remove config fields and apply the id
        So we can appropriately handle updates and deactivations without reloading
      When we DELETE, reset scope data back to defaults
        Again so we can make edits without any reloads
      */
      function handleSaveResponse({data}, requestMethod) {
        flash('Merchant settings saved');
        switch (requestMethod) {
          case 'post':
            $scope.accountSettings = data;
            $scope.savedPayProcessor = true;
            break;
          case 'delete':
            $scope.accountSettings = setDefaults();
            break;
          default:
            break;
        }
      }

    } //end controller

  };
});
