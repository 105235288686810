angular.module('dn').directive('payment', function() {
  return {
    templateUrl: 'directives/registration-wizard/payment/payment.directive.html',
    restrict: 'E',
    scope: {
      regData: '=',
      registration: '=',
      profile: '=',
      organization: '=',
      user: '=',
      isTestAccount: '=',
      standalone: '=',
      isComplete: '=',
      disableBack: '=',
      submit: '=',
      submitText: '=',
      gaTags: '=',
      passingOnFee: '=',
      convenienceFeeTypes: '='
    },

    controller($http, $rootScope, $scope, $state, PayProcessor) {
      $scope.recaptchaV2Active = false;
      $scope.displayCrebits = crebitsAssemble();
      $scope.total = calculateTotal($scope.displayCrebits, 'amount');
      $scope.totalDue = calculateTotal($scope.displayCrebits, 'due');
      if ($scope.totalDue > $scope.total) $scope.totalDue = $scope.total;
      $scope.paymentAmount = $scope.total;
      $scope.customPaymentAmount = $scope.total;
      $scope.oldPlanDueDate = $scope.organization.properties.paymentPlansDue && moment($scope.organization.properties.paymentPlansDue).isSameOrBefore(moment());
      $scope.existingBalance = existingBalance($scope.profile);
      $scope.disablePaymentPlans = $scope.oldPlanDueDate
        || $scope.existingBalance < 0
        || $scope.organization.properties.disablePaymentPlans
        || !PayProcessor.features.paymentPlans;
      $scope.paymentPlan = { profileID: $scope.profile.id };
      $scope.planStatus = { pastDue: false, invalid: false }; // Status variables passed into and modified by paymentPlan directive
      $scope.paymentChoices = [
        { label: 'Full', value: 'full' },
        { label: $scope.totalDue ? 'Minimum' : 'None', value: 'minimum' },
        { label: 'Other', value: 'other' }
      ];
      $scope.paymentChoice = 'full';

      $scope.boolChoices = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ];
      $scope.paymentPlanChoice = false;

      $scope.allowSavedPaymentMethods = PayProcessor.features.savedPaymentMethods;

      $scope.onlyProtectionPlanDue = false;

      // If dealing with a processor like TouchNet always display new method.
      if (!$scope.allowSavedPaymentMethods) {
        $scope.selectedPaymentMethod = 'new-method';
      } else {
        $scope.selectedPaymentMethod = $scope.regData.paymentMethods.length > 1
          ? $scope.regData.paymentMethods[$scope.regData.paymentMethods.length - 1].value
          : 'new-method';
      }

      $scope.convenienceFee = 0;

      function getPaymentType() {
        $scope.regData.paymentMethods.forEach(method => {
          if (method.value === parseInt($scope.selectedPaymentMethod)) {
            $scope.paymentType = method.meta.category;
            return;
          }
        });
      }

      function calculateFee() {
        if ($scope.passingOnFee) {
          const convenience = $scope.convenienceFeeTypes[$scope.paymentType];

          const variableFee = convenience
            ? convenience.variable || 0
            : 0;
          const flatFee = convenience
            ? convenience.flat || 0
            : 0;

          const totalLessProtPlan = Math.abs(calculateFinalPayment());
          if (totalLessProtPlan) $scope.convenienceFee = Math.ceil((variableFee / 100) * totalLessProtPlan) + flatFee;
          else $scope.convenienceFee = 0;
        }
      };

      $scope.$watch('selectedPaymentMethod', () => {
        watchPaymentMethods();
      });

      $scope.$watch('freshMethod', () => {
        watchPaymentMethods();
      });

      function watchPaymentMethods() {
        if ($scope.passingOnFee) {
          if ($scope.selectedPaymentMethod === 'new-method') {
            if ($scope.freshMethod) $scope.paymentType = $scope.freshMethod.category;
          } else {
            getPaymentType();
          }
          calculateFee();
        }
      }

      $scope.hideFee = false;
      $scope.showDueNow = true;

      $scope.$watch('paymentChoice', () => {
        let choiceHasNone = false;

        // choiceHasNone is there to differentiate between when the payment options include 'none' vs 'minimum'
        $scope.paymentChoices.forEach(choice => {
          if (choice.label === 'None') {
            choiceHasNone = true;
          }
        });

        if (
          $scope.passingOnFee
          && choiceHasNone
          && $scope.paymentChoice === 'minimum'
        ) {
          $scope.hideFee = true;
        } else {
          $scope.hideFee = false;
          getPaymentType();
          calculateFee();
        }

        if ($scope.passingOnFee) {
          // This should check for any payment choice that is valid
          $scope.showDueNow = $scope.paymentChoice === 'minimum';
        }
      });

      $scope.$watch('paymentAmount', () => {
        calculateFee();
        const protPlanCrebits = $scope.displayCrebits.filter(c => c.description === '[PROTECTION PLAN]');
        const nonProtPlanCrebits = $scope.displayCrebits.filter(c => c.description !== '[PROTECTION PLAN]');
        const nonProtPlanTotalAmount  = nonProtPlanCrebits.reduce((acc, crebit) => acc + crebit.amount, 0);

        // Do not add the convenience fee to the 'Due Now' amount if only protection plan amount is due
        if (protPlanCrebits.length) {
          let totalProtPlanAmountDue = 0;
          protPlanCrebits.forEach((crebit) => {
            totalProtPlanAmountDue += crebit.due;
          });

          // Set a flag to not add convenience fee to 'Due Now' if only protection plan amount is due
          $scope.onlyProtectionPlanDue = $scope.totalDue === totalProtPlanAmountDue;
        }

        // Do not show convenience fee if the total of non-protection plan crebits is 0
        if ($scope.paymentAmount <= 0 || nonProtPlanTotalAmount <= 0) {
          $scope.hideFee = true;
        } else $scope.hideFee = false;
      });

      $scope.waitlistGroups = $scope.registration.groups.filter(g => g.waitlistRegistration);
      $scope.anyPaymentText = anyPaymentText();

      $scope.$on('$locationChangeStart', (event) => {
        if ($scope.recaptchaV2Active) {
          event.preventDefault();
        }
      });

      $scope.resetPlanStatus = function() {
        if ($scope.paymentPlanChoice === false) {
          $scope.planStatus.pastDue = false;
          $scope.planStatus.invalid = false;
        }
      };

      $scope.changePaymentChoice = function(choice) {
        $scope.paymentAmount = choice === 'minimum' ? $scope.totalDue : $scope.total;
        $scope.customPaymentAmount = $scope.paymentAmount;
        setRemainingPayment($scope.total, $scope.paymentAmount);
        if (choice !== 'full' && $scope.organization.properties.requirePaymentPlans && !$scope.disablePaymentPlans) {
          $scope.paymentPlanChoice = true;
        } else $scope.paymentPlanChoice = false;
      };

      $scope.setCustomPayment = function(customAmount) {
        $scope.paymentAmount = customAmount >= $scope.totalDue ? customAmount : $scope.totalDue;
        setRemainingPayment($scope.total, $scope.paymentAmount);
      };

      submitRegistration = function(token, alreadyTried = false) {
        $rootScope.showProgress();
        let responseData;
        return saveMethod()
          .then(() => submitRegistrations(token, alreadyTried))
          .then((data) => {
            responseData = data;
            return submitInsurancePolicies(data);
          })
          .then(() => {
            // Show different modal if RegEnhancements is true
            if (regFlag && !$scope.standalone) {
              window.swal.close();
              $rootScope.hideProgress();

              // Modify responseData
              extractWaitlistedRegistrations(responseData);
              addParentNames(responseData);
              responseData.paymentDue = $scope.paymentAmount;

              // Define variables to pass to reg summary
              $scope.responseData = responseData;
              $scope.dialogTitle = determineDialogTitle(responseData);
              $scope.descriptionText = determineDescriptionText(responseData);

              $scope.showRegModal = true;
            } else {
              // Otherwise, show old modal
              const orgProps = $scope.organization.properties;
              let returnState = 'patient.profile.questionnaire';

              if (orgProps.portals
                && orgProps.portals.patient
                && orgProps.portals.patient.sections
                && orgProps.portals.patient.sections.questionnaire
                && orgProps.portals.patient.sections.questionnaire.hide) returnState = 'patient.profile';

              if (returnState === 'patient.profile' || (orgProps.features && orgProps.features.disableSocialPopup)) {
                swal({
                  title: 'Success!',
                  text: 'Please wait while we redirect you.',
                  type:'success',
                  confirmButtonText: 'Continue'
                });
              } else {
                /*
                closeOnConfirm=false for the pay swal so it'll flow into error swals or the one above
                in case of success with no followup swal, the pay swal persists
                so kill it here
                */
                window.swal.close();
              }
              $rootScope.hideProgress();
              return $state.go(returnState, {}, { reload:true });
            }
          }).catch(errorHandler);
      };

      regFlag = window.lib.featureFlagClient.isEnabled('RegEnhancements');

      $scope.sessionLimitText = null;
      if (regFlag && !$scope.standalone) $scope.sessionLimitText = 'Sessions and add-ons may fill and are not guaranteed until purchase is complete. The final total may differ if sessions are no longer available.';

      $scope.$watch('passingOnFee', () => {
        if ($scope.passingOnFee) $scope.sessionLimitText += ' Convenience Fees will be adjusted based on the final total.';
      });

      $scope.submit = function() {
        if ($scope.paymentAmount > 0) {
          let totalAmount = $scope.paymentAmount;
          if ($scope.passingOnFee && totalAmount > 0) {
            totalAmount += $scope.convenienceFee;
          }

          window.swal({
            title: 'Confirm Payment',
            text: $scope.sessionLimitText,
            showCancelButton: true,
            confirmButtonText: `Pay $${(totalAmount / 100).toFixed(2)} now`,
            closeOnConfirm: false,
            showLoaderOnConfirm: true,

          }, (confirmed) => {

            if (confirmed) {
              $scope.sessionLimitText += `
                <div style="margin-top: 25px">
                  <strong>Payment is processing. This may take a few moments.</strong>
                </div>
              `;

              window.swal({
                title: 'Confirm Payment',
                text: $scope.sessionLimitText,
                showCancelButton: true,
                confirmButtonText: `Pay $${(totalAmount / 100).toFixed(2)} now`,
                closeOnConfirm: false,
                showLoaderOnConfirm: true,
                html: true,
              });

              grecaptcha.execute(window.lib.recaptchaKeys.v3, { action: 'submit' })
                .then((token) => {
                  if (regFlag && !$scope.standalone) $scope.sessionLimitText = 'Sessions and add-ons may fill and are not guaranteed until purchase is complete. The final total may differ if sessions are no longer available.';
                  submitRegistration(token);
                });
            }
          });
        } else {
          grecaptcha.execute(window.lib.recaptchaKeys.v3, { action: 'submit' }).then((token) => {
            submitRegistration(token);
          });
        }
      };

      $scope.isComplete = function() {
        let totalAmount = $scope.paymentAmount;
        if ($scope.passingOnFee && totalAmount > 0) {
          totalAmount += $scope.convenienceFee;
        }

        $scope.submitText = ($scope.paymentAmount ? `Pay $${(totalAmount / 100).toFixed(2)} and ` : '') + 'Register';

        if (($scope.paymentAmount > 0 || $scope.paymentPlanChoice)
          && (!$scope.selectedPaymentMethod
            || ($scope.selectedPaymentMethod === 'new-method' && !$scope.freshMethod))) return false;
        if ($scope.submitting) return false;
        if ($scope.planStatus.pastDue || $scope.planStatus.invalid) return false;
        if ($scope.paymentAmount < $scope.totalDue) return false;
        if ($scope.organization.properties.registrationAuth
          && $scope.organization.properties.registrationAuth.enabled
          && $scope.organization.properties.registrationAuth.text
          && !$scope.acceptRegistrationAuth) return false;

        return true;
      };

      $scope.disableBack = function() {
        return !!$scope.submitting;
      };

      setRemainingPayment($scope.total, $scope.paymentAmount);

      function anyPaymentText() {
        const orgProps = $scope.organization.properties;
        const selectedGroups = $scope.registration.groups;
        return (((orgProps.branding || {}).text || {}).registration || {}).payment || selectedGroups.filter(g => (g.registration.text || {}).payment).length;
      }

      function calculateTotal(crebits, prop) {
        // prop will be `amount` or `due`
        return crebits.reduce((acc, crebit) => {
          if (!crebit[prop]) return acc;
          return acc += crebit[prop];
        }, 0);
      }

      function calculateFinalPayment() {
        const paymentAmount = $scope.paymentAmount;
        const insuranceCrebits = $scope.displayCrebits.filter(c => c.description === '[PROTECTION PLAN]');
        return (paymentAmount - calculateTotal(insuranceCrebits, 'amount')) * -1;
      }

      function crebitsAssemble() {
        /* Creates crebit-like objects strictly for the table ledger in the step. Payload is populated in submit() */
        var crebits = [];
        if ($scope.registration.donation) crebits.push({ description: '[DONATION] To ' + $scope.organization.name, amount: $scope.registration.donation, due: $scope.registration.donation });
        $scope.registration.groups.map((g) => {
          if (g.waitlistRegistration) return;
          crebits.push({
            groupID: g.id,
            description: '[TUITION] ' + g.parent.name + ' » ' + g.name,
            dueText: g.registration.deposit ? '$' + (g.registration.deposit / 100).toFixed(2) + ' Due Now' : '',
            due: g.registration.deposit || 0,
            amount: g.registration.tuition || 0
          });

          $scope.registration.addOns.map((a) => {
            if (a.appliedToGroup === g.id) {
              crebits.push({
                description: '[ADD-ON] ' + a.name + (a.quantity > 1 ? ' (' + a.quantity + ')' : ''),
                dueText: a.dueAtDeposit ? '$' + ((a.price / 100) * (a.quantity || 1)).toFixed(2) + ' Due Now' : '',
                due: (a.dueAtDeposit ? a.price : 0) * (a.quantity || 1),
                amount: a.price * (a.quantity || 1),
                adjustment: true
              });
            }
          });

          $scope.registration.coupons.map((c) => {
            if (c.appliedToGroup === g.id) {
              crebits.push({
                description: '[COUPON] ' + c.code,
                dueText: c.properties.bypassDeposit ? 'Bypasses Deposit' : '',
                amount: c.properties.bypassDeposit ? 0 : c.amount,
                due: c.properties.bypassDeposit ? c.amount : 0,
                adjustment: true
              });
              if (c.properties.bypassDeposit) {
                // removes the 'Due Now' text from tuition if bypass coup applied
                const tuition = crebits.find(c => c.groupID === g.id);
                if (tuition) tuition.dueText = '';
              }
            }
          });
        });

        const hasPayProcessor = !!$scope.organization.orgPayProcessors.length;
        $scope.registration.insurancePolicies.map((p) => {
          crebits.push({
            description: '[PROTECTION PLAN]',
            amount: p.planCost,
            due: p.planCost,
            dueText: 'Note: Payment is due now, and will appear as a separate charge'
              + (hasPayProcessor ? ' from DocNetwork, Inc.' : '.')
          });
        });
        return crebits;
      }

      const confirmButtonColor = '#DD6B55';

      /*
        Our API is unfortunately inconsistent with error responses
        We've seen the target error message in err, err.data, and err.data.message
        This func pulls each of those potential areas from a given err object
          so we can loop through them later to try and match our err to handle
      */
      function getErrLocations(err) {
        let data, message;
        if (err) data = err.data;
        if (data) message = data.message;
        return [err, data, message].filter(e => e);
      }

      const errorConditions = [
        {
          /*
            These happen when the user tries to navigate away while we're redirecting them at the end of the reg process
            Harmless error, and if we suppress it then they'll reach the state they were trying for.
          */
          matchFunction: err => new RegExp(/Error: transition superseded/).test(err),
          swalback: () => {
            return;
          }
        },
        {
          /*
            Sometimes we can't make a connection to iCheck and request hits us with an ENOTFOUND error
            Since it could be some time before they're available again, boot this user
          */
          matchFunction: err => new RegExp(/ENOTFOUND/).test(err),
          swalback: () => {
            window.swal({
              type: 'error',
              title: 'Payment Processor Error',
              text: 'No response from payment processor. Please wait and try again in a few minutes.',
              confirmButtonColor,
            });
            return $state.go('patient.profile', {}, {reload:true});
          }
        },
        {
          /*
            Standard iCheck errors that basically say the user entered bad card data
          */
          matchFunction: err => new RegExp(/(DECLINED|INVALID|INVLD|INCORRECT|CALL)/).test(err),
          swalback: err => {
            window.swal({
              type: 'warning',
              title: 'Invalid Payment Method',
              text: 'An error occurred while saving your payment method. Please ensure your information is up-to-date and try again.\n\nReason:\n' + err,
              confirmButtonColor,
              confirmButtonText: 'Double Check'
            });
          }
        },
        {
          /*
            We pull occupancy data at the front of reg, but between then and now at least one session has filled up
          */
          matchFunction: err => err.full,
          swalback: err => {
            window.swal({
              type: 'warning',
              title: 'Session Full',
              text: 'One or more of the sessions you were trying to register for is now full. We apologize for any inconvenience this may cause. Please try again, and select an available session.\n\n'
                + 'The following are now full:\n'
                + err.fullGroups.join('\n'),
              confirmButtonColor,
              confirmButtonText: 'Try Again'
            });
            return $state.go('patient.profile.registration', {}, {reload:true});
          }
        },
        {
          /*
            FE filtering/validation somehow failed and allowed the user to submit card info their org doesn't accept
          */
          matchFunction: err => err === window.dnim.constants.errors.CARD_NOT_ACCEPTED,
          swalback: err => window.swal(window.lib.cardNotAcceptedSwalConfig($scope.organization, err))
        },
        {
          matchFunction: err => {
            // These errors come from `modules/touchnet/index.js`
            // If those get updated, these will need to be handled as well.
            const tnErrors = [
              'Address verification failed. Please double check your billing address.',
              'Invalid amount',
              'Invalid card number',
              'Invalid CVC',
              'Invalid routing number',
              'Error making ACH payment. Please double check your account number.',
            ];
            return tnErrors.some(tnError => new RegExp(tnError, 'i').test(err));
          },
          swalback: err => {
            window.swal({
              type: 'warning',
              title: 'Invalid Payment Method',
              text: err,
              confirmButtonColor,
              confirmButtonText: 'Double Check'
            });
          },
        },
        {
          matchFunction: err => {
            // These errors come from `modules/touchnet/index.js`
            // If those get updated, these will need to be handled as well.
            const tnErrors = [
              'Credit card authorization failed',
              'Payment method expired',
              'Transaction limit exceeded',
            ];
            return tnErrors.some(tnError => new RegExp(tnError, 'i').test(err));
          },
          swalback: err => {
            window.swal({
              type: 'error',
              title: 'Payment Failed',
              text: err,
              confirmButtonColor,
              confirmButtonText: 'Try Another Payment Method'
            });
          },
        },
        // reCatpcha stuff
        {
          matchFunction: err => new RegExp(/Our robots think you might be a robot. Please try again/).test(err),
          swalback: () => {
            window.swal({
              type: 'info',
              title: 'reCaptcha Verification Needed',
              html: true,
              customClass: 'recaptcha2-swal',
              showConfirmButton: false,
              text: `
                <div id="grecaptcha-v2-landing"></div>
              `,
            });
          }
        },
        // Invalid Expiration Date
        {
          matchFunction: err => new RegExp(/Expiration Date Invalid/).test(err),
          swalback: () => {
            window.swal({
              type: 'error',
              title: 'Payment Failed',
              text: 'The card expiration date is invalid. Please try again.',
              confirmButtonColor,
              confirmButtonText: 'Try Another Payment Method',
            });
          }
        }
      ];

      // $scope.submitting is set to null after an error pops up when needed because if not it will leave the navigation buttons disabled
      function errorHandler(err) {
        if (err.data.code === 'BAD-TOKEN') {
          $http.post('/api/self-registration/alert-bad-request', { userID: $scope.user, reason: err });
          return grecaptcha.execute(window.lib.recaptchaKeys.v3, { action: 'submit' })
            .then((token) => {
              window.swal.close();
              submitRegistration(token, true);
            });
        }
        $scope.submitting = null;
        $rootScope.hideProgress();
        const possibleErrLocations = getErrLocations(err); // err, err.data, err.data.message

        let foundError;

        for (errLoc of possibleErrLocations) {
          foundError = errorConditions.find(condition => condition.matchFunction(errLoc));
          if (foundError) {
            const shouldRecaptchaV2 = !$scope.recaptchaV2Active;
            if (errLoc === 'Our robots think you might be a robot. Please try again.' && shouldRecaptchaV2) {
              $scope.recaptchaV2Active = true;
              grecaptcha.ready(() => {
                grecaptcha.render('grecaptcha-v2-landing', {
                  'sitekey': window.lib.recaptchaKeys.v2,
                  callback: (token) => {
                    submitRegistration(token, true);
                  },
                });
              });
            }
            foundError.swalback(errLoc);
            break;
          }
        }

        if (!foundError) {
          const supportContact = $scope.organization.properties.division || 'DocNetwork';

          window.swal({
            title: 'Unexpected Error',
            type: 'warning',
            text: 'An unexpected error has occurred while registering.\nIf you continue to experience issues, please contact ' + supportContact + ' support.'
          });

          $http.post('/api/self-registration/alert-bad-request', { userID: $scope.user, reason: err });
          return $state.go('patient.profile', {}, { reload: true });
        }
      }

      function existingBalance(profile) {
        if (profile.crebits && profile.crebits.length) {
          const profileCrebits = profile.crebits.filter(c => c.ledger === 'organization');
          return calculateTotal(profileCrebits, 'amount');
        } else return 0;
      }

      /**
       * @param {Object} method - payment method to generate description from
       * @returns {string}
       */
      function generatePaymentDescription(method) {
        if (!_.isObject(method)) return '';
        return `${method.type} -${method.lastfour}`;
      }

      function generatePayload(token, alreadyTried) {
        // When we do the reg setup we always save a payment method but for TouchNet Payment Processors
        // we don't therefore we don't have a label. We format it the same way as a label adding it to the payload.
        let paymentDescription;
        if ($scope.selectedPaymentMethod !== 'new-method') {
          paymentDescription = $scope.regData.paymentMethods.find(p => p.value === parseInt($scope.selectedPaymentMethod)).label;
        } else {
          paymentDescription = generatePaymentDescription($scope.freshMethod);
        }

        return {
          alreadyTried,
          v2Check: $scope.recaptchaV2Active,
          recaptchaToken: token,
          orgName: $scope.organization.name,
          donation: $scope.registration.donation,
          existingBalance: $scope.existingBalance,
          isTestAccount: $scope.isTestAccount,
          profileID: $scope.profile.id,
          paymentAmount: calculateFinalPayment(),
          paymentDescription,
          paymentMethodID: $scope.selectedPaymentMethod === 'new-method' ? null : $scope.selectedPaymentMethod,
          paymentMethod: $scope.selectedPaymentMethod === 'new-method' ? $scope.freshMethod : null,
          paymentPlan: $scope.paymentPlanChoice === true ? $scope.paymentPlan : null,
          paymentChoice: $scope.paymentChoice,
          addOns: $scope.registration.addOns.map((a) => {
            return {
              addOnID: a.id,
              addOnQuantity: a.quantity || 1,
              description: '[ADD-ON] ' + a.name + (a.quanity && a.quanity > 1 ? '(' + a.quanity + ')' : ''),
              amount: a.price * (a.quantity || 1),
              ledger: 'organization',
              profileID: $scope.profile.id,
              appliedToGroup: a.appliedToGroup
            };
          }),
          coupons: $scope.registration.coupons.map((c) => {
            return {
              couponID: c.id,
              description: '[COUPON] ' + c.code,
              amount: c.properties.bypassDeposit ? 0 : c.amount,
              ledger: 'organization',
              profileID: $scope.profile.id,
              appliedToGroup: c.appliedToGroup,
              bypassDeposit: !!c.properties.bypassDeposit,
              isPercentage: !!c.properties.isPercentage,
            };
          }),
          groups: $scope.registration.groups.map((g) => {
            return {
              id: g.id,
              name: g.name,
              waitlist: g.waitlistRegistration,
              tuitionAmount: g.registration.tuition,
              depositAmount: g.registration.deposit,
            };
          }),
          insurancePolicies: $scope.registration.insurancePolicies || [],
        };
      }

      function saveMethod() {
        return new Promise((resolve, reject) => {
          $scope.submitting = true;

          /* Don't bother saving a payment method if they're not paying */
          if ($scope.paymentAmount < 1 && !$scope.paymentPlanChoice) return resolve();

          // If dealing with a processor like TouchNet resolve early skipping saving the method
          if (!PayProcessor.features.savedPaymentMethods) return resolve();

          if ($scope.selectedPaymentMethod === 'new-method') {
            return $http.post('/api/users/' + $scope.user + '/payment-methods', $scope.freshMethod).then(function(success) {
              const method = success.data;
              $scope.regData.paymentMethods.push({ label: generatePaymentDescription(method), value: method.id });
              $scope.selectedPaymentMethod = method.id;
              /*
              We're doing battle with replica lag.
              In rare instances, a saved paymentMethod has not been replicated on all read replicas
                when it's time to load the method resource when saving a payment crebit
              Introduce a small delay to help mitigate these errors
              */
              return setTimeout(() => {
                return resolve();
              }, 500);
            }, function(err) {
              return reject(err);
            });
          } else return resolve();
        });
      }

      function setRemainingPayment(total, paymentAmount) {
        const remaining = total - paymentAmount;
        $scope.remaining = remaining >= 0 ? remaining : 0;
      }

      function submitInsurancePolicies(regRouteResponse) {
        return new Promise((resolve, reject) => {
          if ($scope.isTestAccount || !$scope.registration.insurancePolicies.length) return resolve();

          const policyList = (regFlag && !$scope.standalone)
            ? regRouteResponse.insurancePolicies
            : $scope.registration.insurancePolicies;

          const policies = policyList.map((p) => {
            return {
              registrationID: $scope.standalone ? p.registrationID : regRouteResponse.registrations.find(r => r.groupID === p.appliedToGroup).id,
              tuition: p.tuition,
              airfare: p.airfare,
              insured: p.insured,
              start: p.start,
              finish: p.finish,
              days: p.days,
              premium: p.planCost,
              tier: p.tier,
            };
          });

          const payload = {
            isTestAccount: $scope.isTestAccount,
            policies
          };

          // If dealing with a processor like TouchNet attach the payment method.
          if ($scope.selectedPaymentMethod === 'new-method') {
            payload.paymentMethod = $scope.freshMethod;
          // Otherwise use the selected payment id
          } else {
            payload.paymentMethodID = $scope.selectedPaymentMethod;
          }

          return $http.post('/api/profiles/' + $scope.profile.id + '/protection-plans', payload).then(() => {
            return resolve();
          }, (err) => {
            return reject(err);
          });
        });
      }

      function submitRegistrations(token, alreadyTried) {
        return new Promise((resolve, reject) => {
          if ($scope.standalone) return resolve();
          const payload = generatePayload(token, alreadyTried);

          return $http.post('/api/profiles/' + $scope.profile.id + '/register', payload).then((success) => {
            if ($scope.gaTags) {
              const config = {
                eventName: 'complete_registration',
                eventCategory: 'registration',
                eventLabel: 'complete',
                gaTagIDs: $scope.gaTags
              };
              window.lib.emitGAEvent(config);
            };

            return resolve(success.data);
          }, (err) => {
            return reject(err);
          });
        });
      }

      const extractWaitlistedRegistrations = (responseData) => {
        // Separate out waitlisted registrations from available registrations
        const availableRegistrations = [];
        const waitlistedRegistrations = [];
        responseData.registrations.forEach((registration) => {
          if (registration.waitlisted) {
            waitlistedRegistrations.push(registration);
          } else {
            availableRegistrations.push(registration);
          }
        });
        responseData.registrations = availableRegistrations;
        responseData.waitlistedRegistrations = waitlistedRegistrations;
      };

      const addParentNames = (responseData) => {
        // Find parent name for each session from scope
        const groupToParentMap = {};
        $scope.registration.groups.forEach((group) => {
          if (group.parent) {
            groupToParentMap[group.id] = group.parent.name;
          }
        });

        // Add parent name for each session to response object
        responseData.registrations.forEach((registration) => {
          registration.parent = groupToParentMap[registration.groupID];
        });
        responseData.unavailableRegistrations.forEach((registration) => {
          registration.parent = groupToParentMap[registration.groupID];
        });
        responseData.waitlistedRegistrations.forEach((registration) => {
          registration.parent = groupToParentMap[registration.groupID];
        });
      };

      const allWaitlistedAsIntended = (responseData) => {
        return !!responseData.waitlistedRegistrations.length
        && !responseData.autoWaitlisted.length
        && !responseData.unavailableRegistrations.length;
      };

      const determineDialogTitle = (responseData) => {
        // Determine title for modal
        const isEveryRegistrationAvailable = !!responseData.registrations.length
          && !responseData.waitlistedRegistrations.length
          && !responseData.unavailableRegistrations.length;
        const dialogTitle = isEveryRegistrationAvailable || allWaitlistedAsIntended(responseData)
          ? `Successfully Registered ${$scope.profile.givenName} for ${$scope.organization.name}`
          : 'Registration Summary';
        return dialogTitle;
      };

      const determineDescriptionText = (responseData) => {
        // Evaluate conditional clauses
        const isEveryRegistrationAvailable = !!responseData.registrations.length
          && !responseData.waitlistedRegistrations.length
          && !responseData.unavailableRegistrations.length;

        const isEveryRegistrationUnavailable = !!responseData.unavailableRegistrations.length
          && !responseData.registrations.length
          && !responseData.waitlistedRegistrations.length;

        const isEveryRegistrationWaitlisted = !!responseData.waitlistedRegistrations.length
          && !responseData.registrations.length
          && !responseData.unavailableRegistrations.length;

        const isAutoWaitlistAndUnavailable = !!responseData.autoWaitlisted.length
          && !!responseData.waitlistedRegistrations.length
          && !!responseData.unavailableRegistrations.length
          && !responseData.registrations.length;

        const isIntendedWaitlistAndUnavailable = !responseData.autoWaitlisted.length
          && !!responseData.waitlistedRegistrations.length
          && !!responseData.unavailableRegistrations.length
          && !responseData.registrations.length;

        const isAnyRegistrationAvailable = !!responseData.registrations.length;

        // Success if everything is waitlisted as intended
        if (isEveryRegistrationAvailable || allWaitlistedAsIntended(responseData)) return;

        // Determine counts
        const successfulRegistrationCount = responseData.registrations.length;
        const attemptedRegistrationCount = responseData.registrations.length
          + responseData.waitlistedRegistrations.length
          + responseData.unavailableRegistrations.length;

        const regCountText = `Successfully registered for ${successfulRegistrationCount} out
          of ${attemptedRegistrationCount} sessions, as some sessions are now full`;

        // Determine Description Text
        let descriptionText;

        if (isEveryRegistrationUnavailable) {
          descriptionText = `Registration was not successful, as all of the requested sessions
            are now full. You were not charged.`;

        } else if (isEveryRegistrationWaitlisted) {
          descriptionText = `Registration was not successful, as all of the requested sessions
            are now full. You were not charged for any sessions and were automatically added to
            the waitlist.`;

        } else if (isAutoWaitlistAndUnavailable) {
          descriptionText = `${regCountText}. You were not charged for any sessions and were
            automatically added to the waitlist.`;

        } else if (isIntendedWaitlistAndUnavailable) {
          descriptionText = `${regCountText}. You were not charged for any sessions.`;

        } else if (!isEveryRegistrationAvailable && successfulRegistrationCount) {
          descriptionText = `${regCountText}. You were charged a new total for successful
            registrations only.`;
        }

        if (responseData.wereInsurancePoliciesRemoved) {
          if (!isAnyRegistrationAvailable) {
            descriptionText += ' No protection plans were purchased.';
          } else {
            descriptionText += ' Protection Plans were only purchased for successful registrations.';
          }
        }

        if (responseData.paymentPlanRemoved) {
          descriptionText += ' No payment plan was created.';
        }
        return descriptionText;
      };
    }
  };
});
