/**
 * The purpose of this directive is to provide an accessibility-compliant interaction handler. For
 * proper buttons, they respond on Enter and Space as well as click. This directive lets us apply
 * that same interaction handling to other elements. Used with the proper ARIA attributes, this is
 * a good step towards accessibility.
 *
 * Much of the function design comes from a great article about implementing your own ng-click:
 * https: //www.codelord.net/2016/09/06/angular-interview-question-deep-dive-implement-ng-click/
 * Great read, check it out!
 */

angular.module('dn').directive('onInteract', function ($parse) {
  return {
    restrict: "A",
    // Cool note: this will be the _containing_ $scope since we don't create on here.
    link: function($scope, $elm, $attr) {
      // Use $parse built in to get our expression
      const interactCallback = $parse($attr.onInteract);

      function overrideDefaults(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }

      // Prevent onInteract from firing if the element is disabled
      function callCallback(e) {
        return $scope.$apply(function() {
          return interactCallback($scope, { $event: e });
        });
      }

      $elm.on('click', function(e) {
        if ($attr.disabled !== true) {
          callCallback(e);
        } else {
          overrideDefaults(e);
        }
      });

      $elm.on('keydown', function(e) {
        // only respond to Enter and Space
        if (e.keyCode === 13 || e.keyCode === 32) {
          overrideDefaults(e);
          if ($attr.disabled !== true) {
            callCallback(e);
          }
        }
      });
    }
  };
});
