# Can be called as an attribute of an element in html
# Can accept any font-awesome icons like
#   <div instruction-text="money-bill-alt">I like money</div>
#   <div instruction-text="thumbs-up">You do? I like money, too!</div>
# Default icon is 'info-circle'
angular.module("dn").directive "instructionText", ->
  templateUrl: "directives/instruction-text/instruction-text.directive.html"
  transclude: true
  replace: true
  restrict: "A"
  scope:
    icon: "@instructionText"
