angular.module('dn').directive('enterFn', function() {
  return function(scope, element, attrs) {
    return element.bind('keyup', function(event) {
      if (event.which === 13) {
        scope.$apply(function() {
          return scope.$eval(attrs.enterFn);
        });
      }
    });
  };
});
