angular.module("dn").directive "event", ->
  templateUrl: "directives/event/event.directive.html"
  restrict: "A"
  scope:
    event: "&"
    light: "&"
    profile: "&"
  controller: ($scope, Event) ->

    $scope.timeAgo = (event) -> moment(event.created).fromNow()

    $scope.icon = (event) ->

      return Event.translators[event.type]?.icon or ""

    $scope.iconPrefix = (event) ->
      return Event.translators[event.type]?.iconPrefix or 'fas'

    $scope.translate = Event.translate
