lib.registerState "manager.profiles.profile.medications.mars",
  url: "/mars"
  templateUrl: "states/manager/profiles/profile/medications/mars/profile-mars.state.html"
  resolve:
    recentMars: ($http, $stateParams) ->
      $http.get("/api/organizations/#{$stateParams.orgID}/profilemars/#{$stateParams.profileID}?limit=10&page=1").then (
        (success) ->
          return success.data
      ), (error) ->
        return []
  controller: ($scope, $stateParams, $http, recentMars) ->
    $scope.recentMars = recentMars

    $scope.nextPage = 2;

    $scope.moarMars = () ->
      $scope.fetching = true
      $http.get("/api/organizations/#{$stateParams.orgID}/profilemars/#{$stateParams.profileID}?limit=10&page=#{$scope.nextPage}")
        .then((success) ->
          $scope.nextPage++
          $scope.recentMars = $scope.recentMars.concat(success.data)
          $scope.noMoar = success.data.length < 10
          $scope.fetching = false
        )
        .catch((err) ->
          $scope.fetching = false
        )

    $scope.formatDate = (date) ->
      return moment(date).utc().format("MMM D, YYYY")

    $scope.formatDose = (dose) ->
      dose ?= ''
      arr = dose.split(' ')
      arr.pop() if arr[arr.length-1] == arr[arr.length-2]
      return arr.join(' ')

    $scope.recordedAtLabel = (mar) ->
      return 'Given' if !mar.notGiven && !mar.pending
      return 'Pending' if mar.pending
      return 'Indicated'
