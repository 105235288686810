lib.registerState "oper.profiles",
  url: "/profiles"
  templateUrl: "states/oper/profiles/profiles.state.html"
  controller: ($scope, $http, Profile, $state, flash) ->

    $scope.search = {}

    $scope.merging = []
    $scope.purging = null

    # Reset search values for non-active fields
    $scope.$watch "search", (search, was) ->
      return unless search and was
      if search.email > was.email
        $scope.search = _.assign $scope.search, { name: "", confirmation: "" }

      if search.name > was.name
        $scope.search = _.assign $scope.search, { email: "", confirmation: "" }

      if search.confirmation > was.confirmation
        $scope.search = _.assign $scope.search, { name: "", email: "" }
    , true

    $scope.doSearch = ->
      if (!_.some $scope.search) then return
      $scope.searching = true
      $scope.noneFound = false
      type = "name"
      if $scope.search.email then type = "email"
      if $scope.search.confirmation then type = "confirmation"
      search = $scope.search[type]
      route = "/api/operator/profile-search?type=#{type}&search=#{search}"
      $http.get(route).then (result) ->
        $scope.searching = false
        if result.data.length
          $scope.searchResults = _.map result.data, (data) ->
            if data.profile then return data.profile
            return data
        else
          $scope.noneFound = true
          $scope.searchResults = undefined
      , (error) ->
        $scope.noneFound = true
        $scope.searching = false

    $scope.purgeProfile = ->
      $http.post("/api/operator/profile-purge", { profileID: $scope.purging.id }).then (result) ->
        flash "#{$scope.purging.givenName} #{$scope.purging.familyName} Purged"
        $state.reload $state.current.name

    $scope.mergeProfiles = ->
      $http.post("/api/profiles/profile-merge", { keep: $scope.merge.keep, remove: $scope.merge.remove }).then (result) ->
        flash "Profiles merged"
        $state.reload $state.current.name

    $scope.getForPurge = ->
      return unless $scope.purgeID
      new Profile($scope.purgeID).load().then (profile) ->
        $scope.purging = profile
        window.swal
          title: "Purge Profiles"
          text: """
            Are you SURE you want to purge the profile for
            #{profile.givenName} #{profile.familyName}?
            This cannot be undone.
          """
          showCancelButton: true
          closeOnConfirm: true
        , (seriouslyPurging) ->
          return unless seriouslyPurging
          $scope.purgeProfile()

    $scope.getForMerge = ->
      return unless $scope.merge.remove and $scope.merge.keep
      new Profile($scope.merge.remove).load().then (profile) ->
        $scope.merging[0] = profile
        new Profile($scope.merge.keep).load().then (profile) ->
          $scope.merging[1] = profile
          window.swal
            title: "Merge Profiles?"
            text: "
              Are you sure you want to merge
              #{$scope.merging[0].givenName} #{$scope.merging[0].familyName}
              (#{$scope.merging[0].dob}) (#{$scope.merging[0].completeness}% Complete)
              into #{$scope.merging[1].givenName} #{$scope.merging[1].familyName}
              (#{$scope.merging[1].dob}) (#{$scope.merging[1].completeness}% Complete)?
            "
            showCancelButton: true
            closeOnConfirm: true
          , (theyWantTo) ->
            return unless theyWantTo
            $scope.mergeProfiles()
