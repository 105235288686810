angular.module("dn").directive "qbGroupSelect", ->
  templateUrl: "directives/builder/group-select/qb-group-select.directive.html"
  restrict: "A"
  replace: true
  scope:
    question: "=qbGroupSelect"
    group: "="
  controller: ($scope) ->

    # load selected groups into tag-selector
    $scope.selectedGroups = $scope.question.subgroups.join(',')

    $scope.children = $scope.group.$selectableChildren()
    childrenCopy    = _.cloneDeep $scope.children

    $scope.hasSubgroups = $scope.question.subgroups?.length > 0

    $scope.$watch "hasSubgroups", (fresh, stale) ->
      if fresh is false
        $scope.question.subgroups = []
        $scope.selectedGroups = ''
        $scope.children = childrenCopy
    , true

    $scope.$watch "question.subgroups", (fresh, stale) ->
      if fresh.length is 0 then $scope.hasSubgroups = false
    , true

    $scope.$watch "selectedGroups", (fresh, stale) ->
      return if fresh is stale
      $scope.question.subgroups = _(fresh?.split(',')).map((id) -> parseInt(id)).compact().value()
