angular.module("dn").directive("qtMultiAuth", function() {
  return {
    templateUrl: "directives/taker/multi-auth/qt-multi-auth.directive.html",
    restrict: "A",
    scope: {
      question: "=qtMultiAuth",
      answer: "="
    },
    controller: function($scope) {
      $scope.answer.metadata = $scope.answer.metadata || {};
      $scope.answer.metadata.sections = $scope.answer.metadata.sections || _.map($scope.question.metadata.sections, s => null);
      $scope.answer.metadata.signatures = $scope.answer.metadata.signatures || _.map($scope.question.metadata.signatures, s => null);

      if ($scope.question.expired) {
        $scope.answer.metadata.sections = _.map($scope.answer.metadata.sections, (s) => {
          s = null;
          return s;
        });
        $scope.answer.metadata.signatures = _.map($scope.answer.metadata.signatures, (s) => {
          s = null;
          return s;
        });
        $scope.answer.value = null;
      }

      function validateCompleteness(metadata) {
        let sections = _.every(metadata.sections);
        // check all required signatues are completed
        let signatures = _.every($scope.question.metadata.signatures, (signature, index) => {
          if (!signature.required ) return true;
          return metadata.signatures[index];
        })
        $scope.answer.value = sections && signatures ? 'complete' : '';
      }

      validateCompleteness($scope.answer.metadata);

      // accept or decline a section. formatted `accepted|1970-01-01T00:00:00.000Z`
      $scope.answerSection = function(index, accepted) {
        let status = accepted ? 'accepted' : 'declined';
        let now = moment().toISOString();
        $scope.answer.metadata.sections[index] = status + '|' + now;
      }

      $scope.answerSignature = function(index, name, relationship) {
        $scope.answer.metadata.signatures[index] = name + '|' + (relationship || '') + '|' + moment().toISOString();
      }

      // translates section answer to string
      $scope.sectionStatus = function(index) {
        if (!$scope.answer.metadata.sections[index]) return;
        let answer = $scope.answer.metadata.sections[index].split('|');
        let status = answer[0].capitalize();
        let date = moment(new Date(answer[1])).format('MM/DD/YYYY hh:mm a');
        return status + ' on ' + date;
      }

      // checks that all sections have been accepted or denied
      $scope.sectionsComplete = function() {
        return !!$scope.answer.metadata.sections.length && _.every($scope.answer.metadata.sections)
      }

      // translates signature answer to string
      $scope.signatureStatus = function(index) {
        if (!$scope.answer.metadata.signatures[index]) return;
        let answer = $scope.answer.metadata.signatures[index].split('|');
        let name = answer[0].capitalize();
        let date = moment(new Date(answer[2])).format('MM/DD/YYYY hh:mm a');
        return 'Electronically signed by ' + name + ' on ' + date;
      }

      // set answer.value when all conditions are met
      // relatively hacky solution to manage completeness
      $scope.$watch('answer.metadata', (metadata, stale) => {
        if(_.isEqual(metadata, stale)) return;
        // check all sections are accepted or denied
        validateCompleteness(metadata);
      }, true)

    }
  };
});
