lib.registerState "manager.finances.orgLedger.lineItems",
  url: "/line-items"
  templateUrl: "states/manager/finances/ledgers/org/line-items/line-items.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Organization Ledger"

  controller: ($http, $scope, $state, crebits, crebitsPerRequest, lastCrebitID, balanceData) ->

    orgTZ = $scope.organization.properties.timezone || 'UTC'

    depositRe = /^\[DEPOSIT\]/
    invoiceRe = /^\[INVOICE/

    orgLedgerFilter = (crebits) ->
      crebits.filter (crebit) ->
        return false if depositRe.test(crebit.description)
        return false if invoiceRe.test(crebit.description)
        return false if !(crebit.confirmation or crebit.ledger is "docnetwork")
        return false if (crebit.ledger is "docnetwork" and !crebit.groupID)

        # Add a `date` prop for easier display
        crebit.date = moment.tz(crebit.created, orgTZ).format('MM/DD/YYYY')

        return true

    $scope.crebits = orgLedgerFilter(crebits)
    $scope.lineItemsRemaining = true
    $scope.crebitsRecieved = crebits.length

    $scope.total =
      debits: parseInt(balanceData.debits, 10) / 100
      credits: parseInt(balanceData.credits, 10) / 100
      balance: parseInt(balanceData.balance, 10) / 100

    # Keep track of crebit list properties to enable infinite scrolling
    lineItems = $('#content')
    $scope.list =
      rendered: -> lineItems[0].scrollHeight
      scrolled: -> lineItems[0].scrollTop
      height: -> lineItems.height()
      limit: 100
      mostScrolled: lineItems[0].scrollTop

    # Watch our scroll position, add additional rows as necessary
    # We add more rows when near the bottom of the list vs the top
    $scope.$watch "list.scrolled()", (scrolled, stale) ->
      return if scrolled <= $scope.list.mostScrolled
      $scope.list.mostScrolled = scrolled
      percent = ($scope.list.height() + $scope.list.scrolled()) / $scope.list.rendered()
      $scope.list.limit += percent * 25 if percent > 0.2 and $scope.list.limit < $scope.crebits.length

    # Configurate the options shown from the ledger-filter directive
    $scope.ledgerConfig = {
        export: true,
        print: true,
        dates: true,
        typeSelect: true,
        fromReg: true,
        regTo: true
      }

    # Recursively retrieve all org crebits
    retrieveCrebits = (lastCrebit, nCrebits) ->

      # Implicitly return a call for the specified number of crebits with ID < lastCrebit
      $http.get("/api/organizations/#{$state.params.orgID}/crebits?resultsPerPage=#{nCrebits}&from=#{lastCrebit}")

      # With the results...
      .then (result) ->

        # If we get nothing back, we've got em all
        if !result.data || result.data.length == 0
          return $scope.lineItemsRemaining = false


        # Set newLastCrebit to the id of the last crebit recieved
        $scope.newLastCrebit = _.last(result.data).id
        $scope.crebitsRecieved += result.data.length

        # Filter down to just the stuff we need for the org ledger and add any
        # new crebits left over into to the array
        $scope.crebits = $scope.crebits.concat(orgLedgerFilter(result.data))

        # If the number of crebits we got back is less than the number we
        # requested, stop because we've got em all
        if result.data.length < nCrebits
          return $scope.lineItemsRemaining = false

        # Otherwise, recurse!
        return retrieveCrebits($scope.newLastCrebit, nCrebits)

      # If we get an error...
      .catch (err) ->
        return flash "Error Loading Additional Line Items"

    # We don't have any BE or resolve-based filtering that would reduce total
    # crebits recieved for profile ledger line items, so we can check against
    # the number recieved to determine whether or not to request more.
    if lastCrebitID != 0 && $scope.crebitsRecieved == crebitsPerRequest
      retrieveCrebits(lastCrebitID, crebitsPerRequest)
    else
      $scope.lineItemsRemaining = false
