angular.module('dn').directive('registrationNavigation', function() {
  return {
    templateUrl: 'directives/registration-wizard/navigation/navigation.directive.html',
    restrict: 'E',
    scope: {
      step: '='
    },
    controller($scope, $state) {

      let submittingRegistration = false;

      $scope.back = function() {
        if ($scope.step.current === 0) { return $state.go('^'); };
        $scope.step.direction = 'back';
        $scope.step.current--;
      };

      $scope.forward = function() {
        if ($scope.step.current === $scope.step.numSteps - 1 && !submittingRegistration) {
          if ($scope.step.submitText === 'Register') submittingRegistration = true;
          return $scope.step.submit();
        };
        if (!submittingRegistration) {
          $scope.step.direction = 'forward';
          $scope.step.onContinue();
          $scope.step.current++;
        }
      };

      $scope.step.bypass = () => {
        $scope.step.direction === 'forward' ? $scope.forward() : $scope.back();
      };

    }
  };
});
