var pad = function (number, size) {
  size = size || 2;
  return ('000000000' + number).substr(-size);
};

angular.module('dn').directive('money', function () {
  return {
    templateUrl: 'directives/input/types/money/money.directive.html',
    restrict: 'E',
    scope: {
      model: '=',
      placeholder: '=',
      negative: '&',
      icon: '@',
      ngDisabled: '='
    },
    controller: function ($scope, $filter) {
      $scope.descriptionID = window.uuid();

      $scope.$watch('model', function (model) {
        if (model === Math.round(parseFloat($scope.innerModel * 100)) || _.isNull(model)) return;
        $scope.innerModel = $filter('currency')(model / 100).replace('$', '');
      });
      $scope.$watch('innerModel', function (innerModel, old) {
        if (innerModel && innerModel.split('.')[1] && innerModel.split('.')[1].length > 2) {
          $scope.innerModel = old;
          return;
        }
        if (innerModel && innerModel.length) {
          var reformatted = '';
          _.each(innerModel, function (character, index) {
            if (character.match(/\d/) || character.match(/\./)) reformatted += character;
            if (index === 0 && character === '-' && $scope.negative()) reformatted += '-';
          });
          $scope.innerModel = innerModel = reformatted;
        }
        if (innerModel === '' || innerModel === '-') {
          $scope.model = null;
          return;
        }
        var amount = Math.round(parseFloat(innerModel * 100));
        if (amount === $scope.model) return;
        $scope.model = amount;
      });

    },
    link: function (scope, elm, attr) {

    }
  };
});
