lib.registerState('manager.attendance.list.profiles.profile.moreParticipants', {
  url: '/more-participants',
  templateUrl: 'states/manager/attendance/profiles/profile/more-participants/more-participants.state.html',
  controller: function($scope, $state, AttendanceData) {
    'use strict';

    $scope.data = AttendanceData;

    $scope.profile = AttendanceData.getProfile($state.params.profileID);

    $scope.isUniversalContact = false;
    if (!AttendanceData.selectedContact.profile_id) {
      $scope.isUniversalContact = true;
      return;
    }

    AttendanceData.fetchMoreParticipants($scope.profile).then((profiles) => {
      $scope.moreParticipants = profiles;
    }).catch(() => flash(`Error loading additional ${AttendanceData.ui.division.patient.plural}`));

    $scope.selectAdditionalParticipant = function (profileID) {
      const targetProfile = AttendanceData.getProfile(profileID);
      AttendanceData.toggleProfileSelected(targetProfile.id);
      if (targetProfile.selected) {
        let tmpRecord;
        if (targetProfile.checkedIn) {
          tmpRecord = targetProfile.mostRecentRecord;
          tmpRecord.check_out_trusted_contact_id = AttendanceData.selectedContactSet[profileID].id;
        } else {
          tmpRecord = { profile_id: targetProfile.id, orgID: targetProfile.organizationID };
          tmpRecord.check_in_trusted_contact_id = AttendanceData.selectedContactSet[profileID].id;
        }
        // Init the tmp record in the store. This will be accessed when we save our check in/out.
        AttendanceData.initTmpRecord(tmpRecord);

        const tmpNote = { profileID: targetProfile.id };
        AttendanceData.initTmpNote(tmpNote);
      } else {
        AttendanceData.deleteTmpRecord(targetProfile.id);
        AttendanceData.deleteTmpNote(targetProfile.id);
      }
    };
  }
});
