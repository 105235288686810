angular.module("dn").directive("qtOtc", function() {
  return {
    templateUrl: "directives/taker/modules/otc/qt-otc.directive.html",
    restrict: "A",
    scope: {
      question: "=qtOtc",
      profile: "="
    },

    controller: ($scope, HealthForm, Constants, WhatDivision, Images, $http) => {
      // fetch org's otc medications
      $http.get(`/api/organizations/${$scope.profile.organization.id}/otc-meds`).then(
        (success) => {
          $scope.groupOtcMeds = success.data
          afterOtcFetch()
        }
        , (error) => {
          $scope.groupOtcMeds = []
          afterOtcFetch()
        }
      )

      $scope.division = WhatDivision;

      var healthForm = HealthForm.get($scope.profile.id)
      var pleaseDebounce = _.debounce(saveMeds, 1000)
      var savingMeds = false


      healthForm.completeFns[$scope.question.id].push(function () {
        // Early return if org has no OTCs / they're all deactivated
        if (!$scope.groupOtcMeds || !$scope.groupOtcMeds.length) return true;

        let answer = JSON.parse($scope.question.answer.value || '{}')
        // check for otc medications added/removed since last answering
        if (_.isEqual($scope.authorizedOTCs, answer)) {
          let allDefined = _.every(answer, (a) => { return !_.isUndefined(a) && !_.isNull(a) });
          return allDefined
        } else {
          return false;
        }
      })

      function afterOtcFetch() {
        var existingAnswer = JSON.parse(_.cloneDeep($scope.question.answer.value) || '{}')
        $scope.authorizedOTCs = _.reduce($scope.groupOtcMeds, (accumulator, elm) => {
          accumulator[elm.id] = _.isUndefined(existingAnswer[elm.id]) ? null : existingAnswer[elm.id];
          return accumulator
        }, {})

        $scope.$watch('authorizedOTCs', function debounceSave(fresh, stale) {
          if (_.isEqual(fresh, stale)) return
          pleaseDebounce()
        }, true)
      }

      $scope.allDefined = () => {
        return _.every($scope.authorizedOTCs, (a) => { return !_.isUndefined(a) && !_.isNull(a) });
      }

      $scope.hideConfirmation = function() {
        return !$scope.question.metadata.enableConfirmation || _.every($scope.authorizedOTCs || {}, (ans) => { return ans === false})
      }

      $scope.disableConfirmation = function() {
        return !_($scope.authorizedOTCs || {}).values().every(_.isBoolean)
      }

      $scope.patient = WhatDivision.patient.singular
      $scope.otcMedConfDownload = `/api/profiles/${$scope.profile.id}/medication-confirmation?otc=true`
      $scope.choices = [
        {label: 'Yes', value: true},
        {label: 'No', value: false}
      ]

      if ($scope.profile.properties.otcMedicationConfirmationFile) {
        Images.getForProfile($scope.profile.id, $scope.profile.properties.otcMedicationConfirmationFile).then((files) => {
          $scope.files = files
        })
      }

      $scope.confirmationAction = () => {
        var route = `/api/profiles/${$scope.profile.id}/answers/upload`
        if ($scope.profile.properties.otcMedicationConfirmationFile) {
          route += `?prepend=${$scope.profile.properties.otcMedicationConfirmationFile}`
        }
        return route
      }

      $scope.onUpload = (content) => {
        $scope.profile.properties.otcMedicationConfirmationFile = content.key
        saveMeds()
      }

      $scope.removeFile = (file) => {
        let fileStr = $scope.profile.properties.otcMedicationConfirmationFile
        fileStr = _.reject(fileStr.split('|'), (f) => f === file.name ).join('|')
        saveMeds()
      }

      function checkComplete () {
        healthForm.checkComplete[$scope.question.id]()
      }

      function saveMeds () {
        var answer = _.cloneDeep($scope.question.answer)
        answer.value = $scope.authorizedOTCs
        /* Added for AP-614 as a potential solution to OTC query errors. This is in no way required for general functionality */
        answer.questionID = $scope.question.id;
        var payload = {
          answer: answer,
          groupOtcMeds: $scope.groupOtcMeds
        }

        healthForm.save('otc', $scope.profile.id, payload).success((savedAnswer) => {
          $scope.question.answer = savedAnswer
          //Fires function in qt-question
          $scope.$parent.updateAnswer(savedAnswer)
        })
      }
    }
  }
})
