angular.module('dn').directive('trustedContactsList', function () {
  return {
    templateUrl: 'directives/trusted-contacts/trusted-contacts-list/trusted-contacts-list.directive.html',
    restrict: 'E',
    scope: {
      profile: '=',
      editable: '=',
      edit: '&',
      interactFn: '&',
      contact: '=',
      viewOnly: '='
    },
    controller: function ($scope, $state, ContactData, AttendanceData) {

      const FIELD_TYPE_HIERARCHY = ['primary', 'secondary', 'tertiary'];

      if ($scope.profile) {
        $scope.contacts = ContactData.findContactsByProfile($scope.profile.id);

        // We only want to push the Universal Contact on the provider portal, when the setting is enabled and when the contact exists
        if ($state.includes('manager') && AttendanceData.ui.settings.universalContactEnabled && ContactData.universalContact) {
          // If there are no trusted contacts $scope.contacts will be undefined meaning we gotta initialize that variable
          if ($scope.contacts) {
            $scope.contacts.push(ContactData.universalContact);
          } else {
            $scope.contacts = [ContactData.universalContact];
          }
        }
      } else if ($scope.contact) {
        $scope.contacts = [$scope.contact];
      }

      $scope.contactInfo = formatContactFields($scope.contacts || []);

      $scope.expanded = ($scope.contacts || []).reduce((acc, { id }) => {
        acc[id] = false;
        return acc;
      }, {});

      $scope.handleInteract = function (trustedContact) {
        if ($scope.editable) {
          return toggleEditable(trustedContact);
        } else if (!$scope.viewOnly) {
          return $scope.interactFn()(trustedContact);
        }
      };

      // I'm not super proud of how contactInfo is being updated here with the watcher
      // but we tried out using the TrustedContact factory for this
      // and it kept causing infinite digest loops when loading the data on the page
      $scope.$watch('contacts', (fresh, stale) => {
        if (fresh === stale) return;
        $scope.contactInfo = formatContactFields(fresh);
      }, true);

      function formatContactFields (contacts) {
        if (!contacts || !Array.isArray(contacts)) {
          log.error('[trusted-contact-list][formatContactFields] Missing or malformed contacts array.');
          return {};
        }
        return contacts.reduce((contacts, contact) => {
          contacts[contact.id] = { phones: undefined, emails: undefined };

          contacts[contact.id].phones = parseContactFieldTypes(contact.phones);
          contacts[contact.id].emails = parseContactFieldTypes(contact.emails);

          return contacts;
        }, {});
      }

      function parseContactFieldTypes (field) {
        return FIELD_TYPE_HIERARCHY.reduce((fieldTypes, fieldType) => {
          const type = field[`${fieldType}_type`];
          const value = field[`${fieldType}_value`];

          if (type && value) {
            fieldTypes.push({ type, value });
          }
          return fieldTypes;
        }, []);
      }

      function toggleEditable ({ id }) {
        $scope.expanded[id] = !$scope.expanded[id];
      }
    }
  };
});
