lib.registerState("manager.settings.billing", {
  url: '/billing',
  templateUrl: 'states/manager/settings/billing/billing.state.html',
  resolve: {
    title: ($rootScope, group) => $rootScope._title = `${group.name} / Billing`,
    toOrg: ($state, group, organization, flash) => {
      if (organization.id === group.id) return;
      flash(`Group changed to ${organization.name} for billing settings`);
      return $state.go('manager.settings.billing', {groupID: organization.id});
    },
    crebits: ($http, organization) => {
      return $http.get(`/api/organizations/${organization.id}/invoice-crebits`).then(result => {
        return result.data;
      });
    },
    accountManager: ($http, organization) => {
      return $http.get("/api/account-managers").then(result => {
        return result.data.find(am => {
          return parseInt(am.id) === parseInt(organization.properties.accountManager);
        });
      });
    },
    billingContact: (organization) => {
      if (organization.properties.contacts) {
        return organization.properties.contacts.sales;
      }
    },
  },
  controller: ($rootScope, $scope, accountManager, billingContact, crebits, group, organization) => {
    'use strict';

    const billingTabs = [
      {label:'Invoices', value:'invoices'},
      {label:'Text Alerts', value: 'miscLedger'}
    ];

    Object.assign($scope, {
      accountManager,
      billingContact,
      billingTabs,
      crebits,
      group,
      organization,
      tab: 'invoices' // default to invoices
    });

    $rootScope.$on('updateInvoiceCrebits', (event, { crebits }) => {
      return $scope.crebits = crebits;
    });

  }
});
