var pad = function (number, size) {
  size = size || 2;
  return ('000000000' + number).substr(-size);
};

angular.module('dn').directive('date', function () {
  return {
    templateUrl: 'directives/input/types/date/date.directive.html',
    // replace: true,
    restrict: 'E',
    scope: {
      model: '=',
      placeholder: '=',
      max: '@',
      min: '@',
      ngDisabled: '=',
      clearOnNull: '='
    },
    controller: function ($scope, $timeout) {
      $scope.date = $scope.date || [];
      const partialDate = (date) => date.length && !date.every(d => !!d);

      $scope.$watch('date', function (fresh, stale) {
        // Check length so model is set to null even if year is entered first
        if (!_.every(fresh) || fresh.length === 1) $scope.model = null;
        var date = fresh.join('-');
        if (date.length === 10) $scope.model = date;
      }, true);

      $scope.$watch('model', function (fresh, stale) {
        // Handling for null model values when clearOnNull is set
        if ($scope.clearOnNull && fresh === null && stale) {
          if ($scope.date && !partialDate($scope.date)) {
            $scope.date.length = 0;
          }
        }

        if (!fresh) return;
        // Clear out bad model values
        if (fresh.length !== 10 || fresh.split('-').length !== 3) {
          $scope.model = null;
          return;
        }
        $scope.date = fresh.split('-');
      });

      $scope.months = [
        {value: '01', label: 'Jan'},
        {value: '02', label: 'Feb'},
        {value: '03', label: 'Mar'},
        {value: '04', label: 'Apr'},
        {value: '05', label: 'May'},
        {value: '06', label: 'Jun'},
        {value: '07', label: 'Jul'},
        {value: '08', label: 'Aug'},
        {value: '09', label: 'Sep'},
        {value: '10', label: 'Oct'},
        {value: '11', label: 'Nov'},
        {value: '12', label: 'Dec'}
      ];

      $scope.days =  _.map(_.range(1, 32), function (day) {
        return {
          value: pad(day),
          label: day
        };
      });

      var yearFromDate = function (date, end) {
        if (!date) return null;
        var result = parseInt(date.slice(0, 4));
        if (result && end) result = result + 1;
        return result;
      };

      var calculateYears = function () {
        const currentYear = new Date().getFullYear();
        // default date range is [1900, now + 11)
        // i.e. from 1900 to 10 years from now
        const years = _.range(
          yearFromDate($scope.min) || 1900,
          yearFromDate($scope.max, true) || currentYear + 11
        );
        $scope.years = _.map(years, function (year) {
          return { value: year.toString(), label: year.toString() };
        });
        // Added in #5932 to improve UX for date inputs. See the issue for more info.
        if (!$scope.min) $scope.years = $scope.years.reverse();
      };

      calculateYears();

      if ($scope.placeholder && $scope.placeholder.length === 10) {
        var defaultDate = $scope.placeholder.split('-');
        $scope.defaultYear = _.find($scope.years, function (year) {
          return year.value === defaultDate[0];
        });
        if ($scope.defaultYear) $scope.defaultYear = $scope.defaultYear.label;

        $scope.defaultMonth = _.find($scope.months, function (month) {
          return month.value === defaultDate[1];
        });
        if ($scope.defaultMonth) $scope.defaultMonth = $scope.defaultMonth.label;

        $scope.defaultDay = _.find($scope.days, function (day) {
          return day.value === defaultDate[2];
        });
        if ($scope.defaultDay) $scope.defaultDay = $scope.defaultDay.label;
      }

    },
    link: function (scope, elm, attr) {

    }
  };
});
