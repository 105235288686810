angular.module("dn").directive "modalDialog", ->
  templateUrl: "directives/modal-dialog/modal-dialog.directive.html"
  restrict: 'E'
  scope:
    show: '='
    dialogTitle: '@'
  replace: true
  transclude: true
  link: (scope, element, attrs) ->
    scope.dialogStyle = {}
    if attrs.width
      scope.dialogStyle.width = attrs.width
    if attrs.height
      scope.dialogStyle.height = attrs.height
    scope.closeModal = ->
      scope.show = false
