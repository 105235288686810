angular.module('dn').directive('regSummary', function() {
  return {
    templateUrl: 'directives/registration-wizard/reg-summary/reg-summary.directive.html',
    restrict: 'E',
    scope: {
      responseData: '=',
      dialogTitle: '=',
      descriptionText: '=',
      profile: '=',
      organization: '=',
      passingOnFee: '='
    },
    controller($scope) {
      $scope.accountAlias = _.get($scope.organization, 'properties.portals.patient.sections.account.alias');

      $scope.feeFeatureFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees');

      $scope.convenienceFee = $scope.responseData.convenienceFee;

      $scope.isEveryRegistrationAvailable = !!$scope.responseData.registrations.length
        && !$scope.responseData.waitlistedRegistrations.length
        && !$scope.responseData.unavailableRegistrations.length;
      $scope.isPaymentDue = $scope.responseData.paymentDue !== 0;
      $scope.shouldDisableButtons = false;

      $scope.disableButtons = () => {
        $scope.shouldDisableButtons = true;
      };

      // ********************
      // Social Media Buttons
      // ********************
      const org = $scope.organization;
      let shareText;
      let shareURL;

      const isAnyRegistrationAvailable = !!$scope.responseData.registrations.length;
      const isAnyRegistrationWaitlistedAsIntended
        = $scope.responseData.waitlistedRegistrations.length
        > $scope.responseData.autoWaitlisted.length;
      const showSocialMediaButtons = (
        !org.properties.features
        || !org.properties.features.disableSocialPopup
      )
      && (
        isAnyRegistrationAvailable
        || isAnyRegistrationWaitlistedAsIntended
      );

      if (showSocialMediaButtons) {
        shareText = `${$scope.profile.givenName} ${$scope.profile.familyName} signed up for ${division.place.singular} at ${org.name} using ${division.name}!`;
        shareURL = `https://app.${division.domain}/register/${org.shortName}`;
        $scope.twitterText = `?text=${shareText}&via=${division.lcName}&url=${shareURL}&hashtags=${division.lcName}`;

        $scope.shareFB = () => {
          // Determine picture
          let picture;
          if (org.properties.branding
            && org.properties.branding.wordmark) {
            picture = `${window.lib.brandingConfig.values.wordmarks.customUrl}/${org.id}.png`;
          } else {
            picture = `${window.lib.brandingConfig.values.logos.stockUrl}/${division.lcName}.png`;
          }
          FB.ui({
            method: 'feed',
            name: `${org.name} - ${division.name}`,
            description: shareText,
            picture: picture,
            link: shareURL
          });
        };

        $scope.showSocialMediaButtons = true;
      }
    }
  };
});
