'use strict';

angular.module('dn').service('Tooltip', (() => {

  const _memoized = {};
  const _initialized = [];

  const RESOURCES = [ 'allergies', 'dimensions', 'medications' ];

  return class Tooltip {
    constructor ($http) {
      this.$http = $http;
    }

    // main method: called by emar.state.coff: processes allergs, dims, and meds requests for a profile
    initProfile (profile) {
      const profileID = profile.id;

      // if no entry
      if (!_memoized[profileID])
        // initialize one with defaults for tooltip directive's $scope
        _memoized[profileID] = {
          allergies: undefined,
          dimensions: undefined,
          medications: undefined,
        };

      // set _memoized profileID key to eventual resolution of allergs, dims, and meds
      _memoized[profileID] = Promise.all(
        RESOURCES.map((resourceName) => this.fetchResource(profile, resourceName))
      ).then(([ allergies, dimensions, medications ]) => {
        return { allergies, dimensions, medications };
      });
    }

    // collects initialized profileIDs in an array to block repeat requests
    setInitialized (profileID) {
      _initialized.push(profileID);
    }

    getInitialized (profileID) {
      return _initialized.includes(profileID);
    }

    // accessing method for _memoized, resolving value if necessary
    accessor ({ profileID }) {
      return new Promise((resolve) => {
        return resolve(_memoized[profileID]);
      }).then(data =>  data);
    }

    // general resource fetching method
    fetchResource (profile, resource) {
      const profileID = profile.id;
      const profileResource = profile[resource];;

      // if already requested
      if (_memoized[profileID][resource] || _initialized.includes(profileID)) {
        // no need to repeat the request
        return;
      };

      return new Promise((resolve) => {
        // if profile already has valid allergies
        if (profileResource && validResource(profileResource, resource)) {
          // handle them and resolve: no need to repeat request
          return resolve(handleResource(profile, resource, profileResource));
        // otherwise
        } else {
          // fetch allergies
          this.$http.get(resourceRoutes(resource, profileID)).then((result) => {
            return resolve(handleResource(profile, resource, result.data));
          }).catch(() => {
            return resolve(resourceError(resource));
          });
        }
      });
    }

  };


  /*
   * HELPER FNS
   * */

  // GENERAL
  function validResource (resourceData, resourceName) {
    const resourceValidators = {
      allergies: validAllergies,
      dimensions: validDims,
      medications: validMedications,
    };

    return resourceValidators[resourceName](resourceData);
  }

  function handleResource (profile, resourceName, resourceData) {
    const resourceHandlers = {
      allergies: reduceAllergies,
      dimensions: assembleDimString,
      medications: reduceMeds,
    };

    return resourceHandlers[resourceName](resourceData, profile);
  }

  function resourceRoutes (resourceName, profileID) {
    const resourceRoutes = {
      allergies: `/api/profiles/${profileID}/allergies`,
      dimensions: `/api/profiles/${profileID}/dimensions`,
      medications: `/api/profiles/${profileID}/medications`,
    };

    return resourceRoutes[resourceName];
  }

  function resourceError (resourceName) {
    // can't trust the route to respond with an error, but
    // errors are meaningful: we want to display that there
    // was an error rather than the default "No resource found"
    return new Error(`Error fetching ${resourceName}`);
  }


  // ALLERGIES
  // check validity of profile.allergies
  function validAllergies(allergies) {
    const REQUIRED_ALLERGY_PROPS = ['name', 'reaction', 'anaphylaxis'];

    return allergies
      && allergies.length
      && allergies.every(a => REQUIRED_ALLERGY_PROPS.every(p => a.hasOwnProperty(p)));
  }

  function reduceAllergies(allergies, profile) {
    return allergies.reduce((allergies, allergy) => {
      if (allergy && !allergy.deactivated && hasAllergiesOfType(profile, allergy.type)) {
        allergies.push(allergy);
      }
      return allergies;
    }, []);
  }

  function hasAllergiesOfType(profile, allergyType) {
    return profile
      && profile.has
      && profile.has.allergies
      && profile.has.allergies[allergyType]
      && profile.has.allergies[allergyType].toString() === 'true';
  }


  // DIMENSIONS
  function validDims(dimensions) {
    if (Array.isArray(dimensions)) dimensions = dimensions[0];
    const REQUIRED_DIM_PROPS = ['height', 'weight', 'heightUnits', 'weightUnits'];

    return dimensions && REQUIRED_DIM_PROPS.every((prop) => {
      return dimensions.hasOwnProperty(prop);
    });
  }

  function assembleDimString(dimensions) {
    if (Array.isArray(dimensions)) {
      // if no dims, return empty string
      if (!dimensions[0]) return '';
      else dimensions = dimensions[0];
    }

    let dims = window.lib.DimConverter.convert(dimensions.height, dimensions.weight, dimensions.heightUnits);
    return dimensions.heightUnits === "english" ? dims.english + ", " + dims.metric : dims.metric + ", " + dims.english;
  }


  // MEDICATIONS
  function validMedications(medications) {
    return medications
      && medications.length
      // `medications` can be just an array of ints, which we don't want
      && medications.every(m => parseInt(m) !== parseInt(m) && m.hasOwnProperty('name'));
  }

  function reduceMeds(medications) {
    return (medications || []).reduce((meds, m) => {
      if (!m.otcID && !m.deactivated && !m.expired) meds.push(m.name);
      return meds;
    }, []).join(", ");
  }

})());
