lib.registerState('manager.prescreening', {
  url: '/prescreening',
  templateUrl: 'states/manager/prescreening/prescreening.state.html',
  resolve: {
    title($rootScope, GroupBranding) {
      return $rootScope._title = GroupBranding.aliases.prescreening;
    },
    // we need this to attach reviewTypes so all child states can have access
    reviewTypes($http, organization) {
      return $http.get(`/api/groups/${organization.id}/review-types`).then((result) => {
        return result.data.filter(r => !r.deactivated);
      });
    }
  },
  controller: function ($scope, $state, $rootScope, GroupBranding, organization) {
    // Prescreening flag
    const featureFlags = $rootScope.featureFlags;
    const prescreeningEnabled = _.get(organization, 'properties.features.prescreening.enabled');
    // Don't go any further if the feature isn't enabled.
    if (!featureFlags.prescreening || !prescreeningEnabled) {
      $scope.errorText = 'There was a problem loading the page';
      $rootScope._title = 'Page not found';
      return;
    }

    $scope.prescreeningAlias = GroupBranding.aliases.prescreening;

    if ($state.current.name === 'manager.prescreening') {
      $state.go('.browse');
    }
  }
});
