angular.module('dn').directive('dateTime', function () {
  return {
    templateUrl: 'directives/input/types/date-time/date-time.directive.html',
    restrict: 'E',
    scope: {
      model: '=',
      max: '@',
      min: '@',
      ngDisabled: '='
    },
    controller: function ($scope) {
      $scope.dateTime = {
        date: '',
        time: ''
      };

      // Verifies if we are dealing with an ISO and formats it properly to be used on the inputs
      if (window.dnim.dateTime.isValidDate($scope.model)) {
        const dateTime = moment($scope.model);

        $scope.dateTime.date = dateTime.format('YYYY-MM-DD');
        $scope.dateTime.time = dateTime.format('HH:mm');
      }

      $scope.$watch('dateTime', function(fresh, stale) {
        if (fresh.date && fresh.date !== '' && fresh.time && fresh.time !== '') {
          $scope.model = new Date(`${fresh.date} ${fresh.time}`).toISOString();
        }
      }, true);
    }
  };
});
