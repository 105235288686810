lib.registerState('manager.attendance.list.profiles.profile', {
  url: '/:profileID',
  templateUrl: 'states/manager/attendance/profiles/profile/profile.state.html',
  controller: function($scope, $state, AttendanceData) {
    'use strict';

    $scope.profile = AttendanceData.getProfile($state.params.profileID);
    $scope.auditing = $state.params.action === 'audit';

    $scope.tabs = [
      { label: 'Trusted Contacts',  sref: '.trustedContacts' },
      { label: 'Notes',             sref: '.notes'           }
    ];

    const moreParticipantsTab = {
      label: `More ${AttendanceData.ui.division.patient.plural.capitalize()}`,
      sref: '.moreParticipants',
      title: `A trusted contact must be selected in order to see more ${AttendanceData.ui.division.patient.plural}.`
    };

    Object.defineProperty(moreParticipantsTab, 'disabled', {
      enumerable: true,
      writeable: false,
      configurable: false,
      get() {
        return !AttendanceData.selectedContact;
      }
    });

    $scope.tabs.push(moreParticipantsTab);

  }
});
