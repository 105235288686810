angular.module('dn').directive('save', function () {
  return {
    templateUrl: 'directives/save/save.directive.html',
    restrict: 'E',
    scope: {
      after: '=',
      disabled: '=',
      fields: '=',
      go: '@',
      resource: '=',
      success: '@',
      swal: '@',
    },
    controller: function ($scope, flash, $state) {
      $scope.saveText = 'Save';

      $scope.postSave = function () {
        if ($scope.swal) {
          swal({
            title: 'Success!',
            text: 'Successfully saved!',
            type: 'success',
          });
        } else {
          flash($scope.success || 'Saved!');
        }

        $scope.saveText = 'Saved!';
        if ($scope.after) $scope.after();
        if ($scope.go) return $state.go($scope.go, undefined, { reload: true });
        setTimeout(function () {
          $scope.saveText = 'Save';
        }, 1000);
      };

      $scope.save = function () {
        const fields = typeof $scope.fields === 'function'
          ? $scope.fields($scope)
          : $scope.fields || null;

        $scope.resource.save(fields).then($scope.postSave);
      };
    }
  };
});
