_ = require("lodash") if require?
_ = window._ if window?._?

getTerms = (search) ->
  search = window.dnim.utils.defaultCoffeeParam(search, '')
  search = search.toLowerCase()
  search = search.replace /(|\)|\(|\\|\/)/g, ""
  search.split(', ')


matchText = (value, term, log) ->
  value = value.toString().toLowerCase()
  term = _.escapeRegExp(term.toLowerCase())
  value.match term

matchItem = (item, terms) ->
  values = _.filter _.values(item), (value) -> value?
  _.every terms, (term) -> _.some values, (value) -> matchText value, term

runFilter = (items, search) ->
  terms = getTerms search
  if _.isArray items
    return _.filter items, (item) -> matchItem item, terms
  matchItem items, terms

if angular?.module?
  angular.module("dn").filter "text", -> runFilter
else if module?.exports?
  module.exports = runFilter
