lib.registerState "manager.import",
  url: "/import"
  templateUrl: "states/manager/import/import.state.html"
  controller: ($filter, $scope, $state, organization, session) ->

    # Controls which state a user goes to when they click the `Data Import`
    # navigation heading or when they go directly to the import URL
    if $state.current.name is "manager.import"
      if session.access.operator
        return $state.go ".groups"
      else
        dataUploadAccess = $filter('permissionVisible')( { upload_data_imports: "allow" } )
        profileImportAccess = $filter('permissionVisible')( { import_profiles: "allow" } )

        profileImportEnabled = organization.properties?.enableProfileImport

        return $state.go "manager.import.upload" if dataUploadAccess
        return $state.go "manager.import.profile-import" if profileImportEnabled && profileImportAccess

        # If no access to either, go to upload for the permission denied screen
        return $state.go "manager.import.upload"
