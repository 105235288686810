angular.module('dn').factory('Resource', function ($http) {

  var Resource = function constructResource(constructInit) {

    var Resource = function Resource(resourceInit) {
      if (_.isNull(arguments[0])) console.error('NULL resource initialization');
      if (!resourceInit) return this;
      if (!isNaN(+resourceInit)) this.id = +resourceInit;
      _.assign(this, resourceInit);
    };

    Resource._routes = _.isArray(constructInit.route) ? constructInit.route : [constructInit.route];
    Resource._schema = constructInit.schema;
    // Useful for tests and resource init
    Resource.blankSchema = function () {
      return Object.keys(Resource._schema || {}).reduce((blank, key) => {
        let val = null;
        if (Resource._schema[key].type === 'array') val = [];
        else if (Resource._schema[key].type === 'json') val = {};
        blank[key] = val;
        return blank;
      }, {});
    };

    // Find params in Resource._route, based on leading `:`
    Resource._requiredParams = function () {
      var routes = {};
      _.each(Resource._routes, function (route) {
        routes[route] = _(route.split('/'))
          .filter(function(part) { return part.indexOf(':') === 0; })
          .map(function(param) { return param.replace(':', '').replace(/\?.+/, ''); })
          .valueOf()
        ;
      });
      return routes;
    };

    // New instances are identified by their lack of `id`
    Resource.prototype._isNew = function () {
      return !_.has(this, 'id');
    };

    // Checks to ensure all params in a route are present as properties in the instance
    // `id` property is not required for instances that are new
    Resource.prototype._requiredParamsPresent = function (route) {
      var self = this;
      return _.every(Resource._requiredParams()[route], function (param) {
        if (self._isNew() && param === 'id') return true;
        return _.has(self, param);
      });
    };

    // Finds the best route to use when multiple are defined; favors routes by order listed
    Resource.prototype._bestRoute = function () {
      var self = this;
      var validRoutes = _.filter(Resource._routes, function (route) {
        return self._requiredParamsPresent(route);
      });
      return validRoutes.length ? validRoutes[0] : Resource._routes[0];
    };

    // Determine the URI for the resource instance
    // Trailing `/:id` params are removed for new instances
    Resource.prototype._uri = function () {
      var self = this;
      var uri = self._bestRoute();
      if (!self._requiredParamsPresent(uri)) return undefined;
      _.each(Resource._requiredParams()[uri], function (param) {
        if (self._isNew() && param === 'id') {
          uri = uri.replace('/:id', '');
        } else {
          uri = uri.replace(':' + param, self[param]);
        }
      });
      return uri;
    };

    Resource.prototype.load = function (load, options) {
      var self = this;
      var uri = self._uri();
      var delimiter = uri.match(/\?/) ? '&' : '?';

      if (load && _.isArray(load) && load.length) {
        uri += delimiter + 'include=' + load.join('|');
        delimiter = '&';
      }

      if (options && options.addlParamsString) {
        uri += delimiter + options.addlParamsString;
        delimiter = '&';
      }

      uri += delimiter + 'modern';

      return $http.get(uri).then(function (result) {
        _.mergeWith(self, result.data, function (a, b) {
          if (!_.isArray(a)) return;
          return b;
        });
        self._pristine = _.cloneDeep(result.data);
        return self;
      });
    };

    /*
      if the request times out, we may get an html response from cloudflare.
      we can remove most of that except the part of the title after the pipe, eg:
      <title>app.campdoc.com | 504: Gateway time-out</title>  =>  504: Gateway time-out
    */
    function formatCloudFlare(data) {
      const titleRegEx = /\| (.*)<\/title>/
      return data.match(titleRegEx) ? data.match(titleRegEx)[1] : data
    }

    const defaultDuplicateError = 'Item already exists';
    function formatDuplicateError(data) {
      if (!data || !data.detail || !data.table) return defaultDuplicateError;
      const detailMatch = data.detail.match(/Key \((.+)\)=\((.+)\) already exists/);

      if(!detailMatch) return defaultDuplicateError;

      return `Another item already has the value '${detailMatch[2]}' for '${detailMatch[1]}'`;
    }

    Resource.prototype.save = function () {
      var pick = _.find(arguments, function (arg) {return _.isArray(arg)});   // return an Array(boolean) testing whether or not a given value in arguments{} is an Array.
      var err  = _.find(arguments, function (arg) {return _.isError(arg)});   // return an Array(boolean) testing whether or not a given value in arguments{} is an Error.
      var self = this;
      var picked = null;
      var method = self._isNew() ? 'post' : 'put';
      var uri = self._uri();
      if (pick && _.isArray(pick)) {
        pick.unshift('id');
        picked = _.pick(self, pick);
      }
      return $http[method](uri, picked || self).success(function (result) {
        _.mergeWith(self, result.data, function (a, b) {
          if (!_.isArray(a)) return;
          return b;
        });
        self._pristine = _.cloneDeep(result.data);
        return self;
      }).error(function (data, status, headers, config) {
        let err;
        if (status == 409) {
          // We check for fields that indicate a raw database error
          // We don't use `data.table`, but it will only exist in the type of error we care about
          if (data && data.detail && data.table) {
            err = formatDuplicateError(data);
          } else {
            err = defaultDuplicateError;
          }
        }
        if (err) {
          window.swal('Error', err, 'error');
        } else {
          window.swal(status + ' Error', formatCloudFlare(data), 'error');
        }
      }).then(function (result) {
        return result.data;
      });
    };

    Resource.prototype.destroy = function () {
      var self = this;
      return $http.delete(self._uri(), self).then(function (result) {
        return true;
      });
    };

    return Resource;
  };

  return Resource;
});
