lib.registerState('home.account.notifications', {
  url: '/notifications',
  resolve: {
    title: function ($rootScope) {
      $rootScope._title = "User Settings / Notifications"
    },
    session: function ($http) {
      return $http.get('/api/session?reaccess').then(function (result) {
        return result.data;
      });
    }
    ,
    notifications: function ($http, session ) {
      return $http.get(`/api/users/${session.userID}/notifications`)
        .then((success) => {
          return _.filter(success.data, 'sent')
        })
        .catch(() => []);
    }
  },
  templateUrl: 'states/home/account/notifications/notifications.state.html',
  controller: function($scope, notifications) {
    $scope.notifications = notifications;
  }
})
