# Handle an 'authorization' type question
angular.module("dn").directive "qtAuthorization", ->
  templateUrl: "directives/taker/authorization/qt-authorization.directive.html"
  restrict: "A"
  scope:
    question: "=qtAuthorization"
    answer: "="
  controller: ($scope) ->

    $scope.validate = if $scope.question.required then "required" else ""

    ###
    We used to store authorization answers as the string literal
    "Electronically signed by <Person> at <Date>", hence "oldValue" handling.
    Now it's stored as <status>|<name>|<relationship> and we use the answer
    updated date to show the date it was accepted e.g.
    accepted|Tom|self
    ###
    $scope.oldValue = $scope.answer?.value?.indexOf("Electronically") > -1
    $scope.date = null

    # Split the answer described above into parts that we bind to $scope
    $scope.answerParts =
      status: (if $scope.answer.value then $scope.answer.value.split("|")[0]  else null)
      name: (if $scope.answer.value then $scope.answer.value.split("|")[1]  else null)
      relationship: (if $scope.answer.value then $scope.answer.value.split("|")[2]  else null)

    # Are we ready to accept/deny the authorization?
    $scope.ready = (declining) ->
      ready = $scope.answerParts.name
      if $scope.question.metadata.relationshipRequired
        ready = ready and $scope.answerParts.relationship
      if declining and not $scope.question.metadata.declinable then return false
      ready

    # Join our answerParts into a new answerType
    $scope.accept = (arg) ->
      ap = $scope.answerParts
      ap.status = if arg then "accepted" else "declined"
      $scope.answer.value = [ap.status, ap.name, ap.relationship].join "|"

    # Revoke the authorization
    $scope.deauthorize = ->
      $scope.oldValue = false
      $scope.answer.value = null

    $scope.locked = -> $scope.answer?.value?.length > 0

    # Add the updated date to the string in the view. The answer object is updated
    # in the qtQuestion controller
    $scope.$watch "answer", ->
      if $scope.answer.updated
        $scope.date = moment($scope.answer.updated).format "MM/DD/YYYY hh:mm a"
    , true
