angular.module("dn").directive "qbExpiration", ->
  templateUrl: "directives/builder/expiration/qb-expiration.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbExpiration"
  controller: ($scope) ->

    $scope.question.metadata ?= {}
    $scope.informationExpires = !!$scope.question.metadata.expiration

    $scope.shouldHideExpiration = ->
      _.includes [
        "dimensions"
        "emergencyContacts"
        "immunizations"
        "insurances"
        "primaryProviders"
      ], $scope.question.type

    $scope.$watch "informationExpires", (fresh, stale) ->
      return if fresh is stale
      if fresh
        $scope.question.metadata.expiration = new Date().toISOString().slice 0, 10
      else
        delete $scope.question.metadata.expiration
        if $scope.question.metadata.relativeExpiration
          $scope.question.metadata.relativeExpiration = false
