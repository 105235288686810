angular.module('dn').directive('miscLedger', () => {
  return {
    templateUrl: 'directives/misc-ledger/misc-ledger.directive.html',
    restrict: 'A',
    scope: {
      organization: '=',
    },
    controller: ($http, $scope) => {
      'use strict';


      Object.assign($scope, {
        print: () => window.print(),
        total: undefined,
      });

      const asyncConfig = {
        list: ['smsCrebits'],
        target: $scope,
        assigners: {
          smsCrebits: {
            fn: smsCrebitsHandler,
            args: [$scope.organization.id]
          }
        }
      };

      $scope = window.lib.assignAsynchronously(asyncConfig);


      function setTotal (crebits) {
        $scope.total = crebits.reduce((sum, { amount }) => parseInt(amount) + sum, 0);
        return crebits; // pass crebs along
      }

      function smsCrebitsHandler (orgID) {
        return getSmsCrebits(orgID)
          .then(({ data: crebits }) => setTotal(crebits))
          .then(orderCrebits)
          .catch(err => window.swal('Error', err, 'error'));

      }

      function getSmsCrebits (orgID) {
        return $http.get(`/api/organizations/${orgID}/sms-tokens-purchased`);
      }

      function orderCrebits (crebits) {
        return crebits.sort(({ created: firstCrebCreated }, { created: secondCrebCreated }) => {
          return Date.parse(secondCrebCreated) - Date.parse(firstCrebCreated);
        });
      }
    }
  };
});
