lib.registerState "manager.setup",
  url: "/setup"
  templateUrl: "states/manager/setup/setup.state.html"
  resolve:
    title: ($rootScope, organization) ->
      $rootScope._title = "#{organization.name} / Setup"

    accountManagers: ($http) ->
      $http.get("/api/account-managers").then(
        (result) -> result.data || []
      , (err) -> []
      )

    seasons: ($http, organization) ->
      $http.get("/api/organizations/#{organization.id}/seasons").then(
        (result) -> result.data || []
      , (err) -> []
      )

  controller: ($scope, $state, $http, Season, flash, organization, reviewMode, accountManagers, seasons, session) ->
    $scope.org = organization
    $scope.seasons = seasons || []
    $scope.reviewMode = reviewMode

    selectChoices = ->
      return unless $scope.seasons.length
      return _($scope.seasons).map((s) ->
        return {
          value: s.id
          label: "#{s.name} (Updated #{moment(s.updated).format('MMM D YYYY')})"
          created: s.created
          optgroup: if s.archived then 'Archived' else 'Active'
        }
      ).orderBy(
        # For `created`, cast from string to Date so ordering to work properly
        [ 'optgroup', (s) -> return new Date(s.created) ]
        [ 'asc', 'desc' ]
      ).value()

    currentSeason = ->
      return unless $scope.seasons.length
      # Remove archived seasons
      activeSeasons = _.filter $scope.seasons, "archived": null
      if activeSeasons.length
        # Find seasons currently in session and return the first
        currentSeasons = _(activeSeasons).map((a) ->
          isAfterStart = moment().isSameOrAfter(moment(new Date(a.start)))
          isBeforeFinish = moment().isSameOrBefore(moment(new Date(a.finish)))
          return a if (isAfterStart && isBeforeFinish)
        ).compact().value()
        if currentSeasons.length
          return _.head currentSeasons
        else
          return _.head activeSeasons
      else
        return _.head $scope.seasons

    $scope.seasonChoices = selectChoices()
    $scope.selectedSeason = currentSeason()
    $scope.selectedSeasonID = $scope.selectedSeason?.id
    $scope.activeSeasons = _.filter $scope.seasons, "archived": null
    $scope.seasonName = ''

    $scope.setSeason = ->
      $scope.selectedSeason = _.find($scope.seasons, 'id': parseInt $scope.selectedSeasonID)

    $scope.createSeason = () ->
      swal {
        title: 'Please name your new submission'
        type: 'input'
        showCancelButton: true
        closeOnConfirm: false
        showLoaderOnConfirm: true
        inputPlaceholder: 'Submission Name'
        confirmButtonText: 'Start Submission'
      }, (input) ->
        name = _.trim(input)
        if input == false
          return false
        if name == ''
          swal.showInputError 'You need to name the submission!'
          input = ''
          return false
        season = {
          orgID: $scope.organization.id
          name: name
          updates: []
        }
        new Season(season).save().then(
          (success) ->
            $scope.seasons.push success
            $state.go("manager.setup.season", {index: success.id})
            swal.close()
          (error) ->
            swal "Error!", "There was an error creating #{name}", "error"
        )
        return

    $scope.archiveSeason = ->
      return unless $scope.selectedSeason
      index = _.indexOf( $scope.seasons, $scope.selectedSeason)
      $scope.selectedSeason.archived = moment().utc().format()
      $scope.selectedSeason.updates = JSON.stringify($scope.selectedSeason.updates)
      new Season($scope.selectedSeason).save().then(
        (result) ->
          $scope.selectedSeason = result
          $scope.seasons[index] = result
          $scope.seasonChoices = selectChoices()
          $scope.activeSeasons = _.filter $scope.seasons, "archived": null
          swal "Season Archived!", "#{$scope.selectedSeason.name} Has been archived", "success"
      , (error) ->
        swal "Error!", "There was an error archiving #{$scope.selectedSeason.name}", "error"
      )

    $scope.reactivateSeason = ->
      return unless $scope.selectedSeason
      index = _.indexOf( $scope.seasons, $scope.selectedSeason)
      $scope.selectedSeason.archived = null
      $scope.selectedSeason.updates = JSON.stringify($scope.selectedSeason.updates)
      new Season($scope.selectedSeason).save().then(
        (result) ->
          $scope.selectedSeason = result
          $scope.seasons[index] = result
          $scope.seasonChoices = selectChoices()
          $scope.activeSeasons = _.filter $scope.seasons, "archived": null
          swal "Season Reactivated!", "#{$scope.selectedSeason.name} Has been un-archived", "success"
      , (error) ->
        swal "Error!", "There was an error reactivating #{$scope.selectedSeason.name}", "error"
      )

    $scope.gradeString ?= ""
    gradeArr            = []

    $scope.$watch "gradeString", (str) ->
      gradeArr = $scope.gradeString?.replace(/\s/g).split(",")

    $scope.managerChoices = accountManagers
      .reduce((choices, m) ->
        if m.email != 'support@docnetwork.org'
          choices.push({
            value: m.id
            label: m.name
          })
        return choices
      , [])

    $scope.accountManager = ->
      manager =_.find accountManagers, (m) ->
        m.id.toString() is $scope.org.properties.accountManager
      if manager
        manager.email = manager.email.replace "@docnetwork.org", "@#{$scope.org.division.domain}"
      return manager

    $scope.mustUpdate = ->
      !$scope.org.properties.lastUpdated or moment().diff($scope.org.properties.lastUpdated, 'days') > 30

    $state.go "manager.setup.update" if $scope.mustUpdate()

    $scope.sinceLastUpdate = ->
      return null unless $scope.org.properties.lastUpdated
      moment($scope.org.properties.lastUpdated).fromNow()

    $scope.save = (org) -> org.save(["properties"]).then -> flash "Organization properties saved"

    $scope.seasonIndex = do ->
      length = $scope.org.properties.seasons?.length or 0
      if length then length - 1 else length

    $scope.seasonStatus = (season=$scope.selectedSeason) ->
      return "none" unless season
      return "archived" if season.archived
      return "confirmed" if season.status?.confirmed
      return "activated" if season.status?.activated
      return "reviewed" if season.status?.reviewed
      return "submitted" if season.status?.submitted
      return "started"


    $scope.buttonText =
      "none": "Set Up a New Season"
      "started": "Continue Setup"
      "submitted": "Review Your Submission"
      "reviewed": "Review Your Submission"
      "activated": "Review Your submission"
      "confirmed": "Review Your Submission"
      "archived": "Review Past Submission"

    $scope.yesNo = [
      { value: true,  label: "Yes" }
      { value: false, label: "No"  }
    ]

    $scope.disableReviewButton = () ->
      $scope.seasonStatus() == 'confirmed' && !session.access.operator
