lib.registerState "patient.profile.funkNotes.history",
  url: "/history"
  templateUrl: "states/patient/profile/funk-notes/note-history/note-history.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "CampGrams History"
    funkNotes: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/funk-notes").then (
        (result) ->
          return result.data or []
      ), (err) -> return []
    funkCrebits: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/funk-tokens").then (
        (result) ->
          return result.data or []
      ), (err) -> return []

  controller: ($http, $scope, funkNotes, funkCrebits, profile, registrations) ->
    $scope.profile   = profile
    $scope.funkNotes = funkNotes
    $scope.crebits = funkCrebits
    $scope.balance = _.reduce $scope.crebits, (sum, crebit) ->
      sum + parseInt(crebit.amount)
    , 0
