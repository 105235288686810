###
 Module definition
 Name: immunizations
 Metadata:
   template -> UUID of template for imm upload
   doses -> object indexed by imm type keys, holds requirement data
   dose.name -> full name of dose type
   dose.doses -> number of doses to include/require
   dose.include -> whether or not to include it at all
   dose.required -> dose is required if they have NOT uploaded a form
   dose.sticky -> dose is required REGARDLESS of upload status
###

angular.module("dn").directive "qbImmunizations", ->
  templateUrl: "directives/builder/modules/immunizations/qb-immunizations.directive.html"
  replace: true
  restrict: "A"
  scope:
    module: "=qbImmunizations"
    mode: "@"
  controller: ($scope, $stateParams, Images) ->
    groupID = $stateParams.groupID

    $scope.module.metadata ?= {}
    $scope.module.metadata.doses ?= {}
    $scope.module.label ?= "Immunizations"
    $scope.module.required ?= true
    $scope.module.metadata.inputType ?= "both"

    if $scope.module.metadata.template
      Images.getForGroup(groupID, $scope.module.metadata.template).then (imgs) ->
        $scope.existingTemplate = imgs

    $scope.inputTypes = [
      { label: "Web and Upload", value: "both" }
      { label: "Web Form", value: "web" }
      { label: "Upload", value: "upload" }
    ]

    $scope.action = -> "/api/groups/#{groupID}/upload-template"
    $scope.onComplete = (content) -> $scope.module.metadata.template = content.key

    # AP-2859 - Existing questions will have newer vaccine added, adjusting completeness %
    if $scope.module.id then $scope.defaultDoses = dnim.generateImmsUiConfig(false)
    else $scope.defaultDoses = dnim.generateImmsUiConfig()
    $scope.defaultDoses.forEach((dose) -> $scope.module.metadata.doses[dose.key] ?= dose)
