angular.module('dn').directive('regAdjustmentsEditor', function () {
  return {
    templateUrl: 'directives/reg-adjustments-editor/reg-adjustments-editor.directive.html',
    restrict: 'E',
    scope: {
      registrationID: '=registrationId',
      editing: '=',
      requesting: '=',
    },
    controller($scope, $state, ProfileRegData, RegAdjustment, RegAddOn, RegCoupon) {

      try {

        // Static(ish) declarations
        $scope.adjustmentsLoaded = false;
        $scope.REG_ADJ_TYPES = RegAdjustment.TYPES;
        $scope.regCoupons = [];
        $scope.regAddOns = [];
        $scope.regAdjustments = [];
        $scope.selectedLineItems = [];
        $scope.profile = ProfileRegData.profile;
        $scope.registration = ProfileRegData.regById($scope.registrationID);
        $scope.lineItemPresets = ProfileRegData.indexedOrg[$scope.profile.organizationID].properties.lineItemPresets;
        $scope.newAdjustment = {};
        $scope.selectAll = {
          'regAdjustments': false,
          'regAddOns': false,
          'regCoupons': false,
        };
        $scope.tabConfig = {
          tabs: [
            { label: 'Add-ons', title: 'Available Add-ons', value: 'add-ons', hide: false },
            { label: 'Coupons', title: 'Available Coupons', value: 'coupons', hide: false },
            { label: 'Custom', title: 'Custom Adjustments', value: 'custom', hide: false },
            { label: 'Review', title: 'Review Changes to Registration', value: 'complete', hide: true },
          ],
          active: null
        };

        $scope.glCodesP2FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');

        if ($scope.glCodesP2FlagEnabled) {
          /**
           * This function sets the items for the custom adjust dropdown. The
           *   dropdown consists of one tuition and every add-on with a
           *   quantity greater than 0.
           */
          const setLineItemsForAdjustment = () => {
            $scope.lineItemsForAdjustment = [
              {
                label: $scope.registration.tuition.description.replace(/\[TUITION\] /g, ''),
                value: $scope.registration.tuition.id
              }
            ];

            $scope.registration.adjustments.forEach((adjustment) => {
              if (adjustment.description.startsWith('[ADD-ON]') && adjustment.addOnQuantity > 0) {
                $scope.lineItemsForAdjustment.push({
                  label: adjustment.description.replace(/\[ADD-ON\] /g, ''),
                  value: adjustment.id
                });
              } else if (adjustment.description.startsWith('[ADD-ON]') && adjustment.addOnQuantity === 0) {
                $scope.lineItemsForAdjustment.push({
                  label: adjustment.description.replace(/\[ADD-ON\] /g, '[REMOVED] '),
                  value: adjustment.id
                });
              }
            });
          };

          /**
           * This function deletes the pending custom adjusts for add-ons that
           *   were just removed.
           */
          const deletePendingAdjusts = () => {
            $scope.regAdjustments = $scope.regAdjustments.filter((regAdjust) => {
              const adjustedCrebit = $scope.registration.crebits
                .find((crebit) => crebit.id === parseInt(regAdjust.adjustedLineItemID));
              return !adjustedCrebit.wasJustRemoved;
            });
          };

          /**
           * This function deletes the selected custom adjusts for add-ons that
           *   were just removed.
           */
          const deleteSelectedAdjusts = () => {
            $scope.selectedLineItems = $scope.selectedLineItems.filter((selectedLineItem) => {
              const isCustomAdjust = !!selectedLineItem.adjustedLineItemID;
              if (!isCustomAdjust) return true;
              const adjustedCrebit = $scope.registration.crebits
                .find((crebit) => crebit.id === parseInt(selectedLineItem.adjustedLineItemID));
              return !adjustedCrebit.wasJustRemoved;
            });
          };

          setLineItemsForAdjustment();

          // Whenever an add-on is removed, remove it from the custom adjust
          //   dropdown and delete the pending and selected custom adjusts for
          //   that add-on.
          // This watcher will trigger when the cancellation adjust
          //   is created for the add-on.
          $scope.$watchCollection('registration.crebits', () => {
            setLineItemsForAdjustment();
            deletePendingAdjusts();
            deleteSelectedAdjusts();
            const justRemovedAddOn = $scope.registration.crebits.find((crebit) => crebit.wasJustRemoved);
            justRemovedAddOn.wasJustRemoved = false;
          });
        }

        /**
         * This function calculates the adjusted amount for every
         *   non-transactional crebit in a registration. The adjusted amount
         *   is the original crebit amount plus the adjusts, coupon
         *   attributions, and negative misc attributions.
         */
        const setCrebitAdjustedAmounts = () => {
          // Create an array of coupon IDs
          const couponIDs = $scope.registration.crebits
            .filter(crebit => crebit.description.startsWith('[COUPON]'))
            .map(crebit => crebit.id);

          $scope.registration.crebits.forEach((crebit) => {
            // Only non-transactional crebits can have adjusted amounts. We
            //   also only care about crebits in a registration.
            const isNonTransactRegCrebit
              = crebit.description.startsWith('[TUITION]')
              || crebit.description.startsWith('[ADD-ON]');

            if (!isNonTransactRegCrebit) return;

            // Find the total amount of all adjustments associated with the selected line item
            const adjustTotal = $scope.profile.crebitAdjustments
              .filter((adjustment) => adjustment.crebitID === crebit.id)
              .map((adjustment) => $scope.registration.crebits.find(crebit => crebit.id === adjustment.adjustmentID))
              .reduce((sum, adj) => sum += adj.amount, 0);

            // Find the total amount of all coupons attributed to the selected line item
            const couponTotal = crebit.attributions
              .filter((attribution) => couponIDs.includes(attribution.crebitID))
              .map((attribution) => attribution.amount)
              .reduce((sum, c) => sum += c, 0);

            // Find the total amount of all negative miscs attributed to the selected line item
            const negativeMiscTotal = _.flatMap($scope.profile.negativeMisc, 'attributingTo')
              .filter((negativeMisc) => negativeMisc.attributedTo === crebit.id)
              .reduce((sum, attr) => sum += attr.amount, 0);

            const adjustedAmount
              = crebit.amount
              + adjustTotal
              + couponTotal
              + negativeMiscTotal;

            crebit.adjustedAmount = adjustedAmount;
          });
        };

        if ($scope.glCodesP2FlagEnabled) setCrebitAdjustedAmounts();

        /**
         * This function gets the adjusted amount of a given crebit with the
         *   pending custom adjusts taken into account.
         */
        $scope.getPendingAdjustedAmount = (adjustedLineItemID) => {
          const selectedLineItemID = parseInt(adjustedLineItemID);

          const selectedItem = $scope.registration.crebits.find((crebit) => crebit.id === selectedLineItemID);

          // Find the total of all custom adjustments in the process of being made
          const newAdjustmentsAmount = $scope.regAdjustments
            .filter((adjustment) => {
              const forSelected = parseInt(adjustment.adjustedLineItemID) === selectedLineItemID;
              return adjustment.selected && forSelected;
            }).map((adjustment) => adjustment.amount).reduce((sum, c) => sum += c, 0);

          const pendingAdjustedAmount
            = selectedItem.adjustedAmount
            + newAdjustmentsAmount;

          return pendingAdjustedAmount;
        };

        /**
         * This function determines what the minimum valid amount that can be
         *   entered into the custom adjustment amount input field is.
         */
        $scope.findMinimumAmount = (adjustedLineItemID) => {
          const pendingAdjustedAmount = $scope.getPendingAdjustedAmount(adjustedLineItemID);
          const calculatedMinimum = Math.max(0, pendingAdjustedAmount / 100) * -1;

          return calculatedMinimum;
        };

        // Determines if a custom adjustment or add-on is currently being added. If so, helps
        // disable any coupon input (select all/individual checkboxes)
        $scope.lockCouponInput = () => {
          if (!$scope.glCodesP2FlagEnabled) return false;
          const addOns = [...$scope.regAddOns];
          const regAdjustments = [...$scope.regAdjustments];
          const isAddOnSelected = !!addOns.find(addOn => addOn.selected);
          const isRegAdjustmentSelected = !!regAdjustments.find(addOn => addOn.selected);
          return isRegAdjustmentSelected || isAddOnSelected;
        };

        // Determines if a coupon is currently being added. If so, helps
        // disable any add-on or adjustment input (select all/individual checkboxes)
        $scope.lockAdjustAndAddOnInputs = () => {
          if (!$scope.glCodesP2FlagEnabled) return false;
          const coupons = [...$scope.regCoupons];
          const isCouponSelected = coupons.some(coupon => coupon.selected);
          return isCouponSelected;
        };

        /**
         * This function determines if the "Select All Custom Adjustments"
         *   option should be disabled. If selecting all pending custom adjusts
         *   would cause the adjusted amount of a crebit to go negative, then
         *   the option should be disabled.
         */
        $scope.shouldDisableSelectAllAdjusts = () => {
          if (!$scope.glCodesP2FlagEnabled) return false;
          const crebitToPendingAdjustTotal = {};

          // First, add the current adjusted amount for each crebit
          $scope.registration.crebits.forEach((crebit) => {
            crebitToPendingAdjustTotal[crebit.id] = crebit.adjustedAmount;
          });

          // Second, add the pending adjust amounts for each crebit
          $scope.regAdjustments.forEach((pendingAdjust) => {
            const adjustedLineItemID = parseInt(pendingAdjust.adjustedLineItemID);
            crebitToPendingAdjustTotal[adjustedLineItemID] += pendingAdjust.amount;
          });

          // If selecting all pending adjusts would cause the adjusted amount
          //   of a crebit to go negative, then the option should be disabled
          const isInvalidPendingAdjustTotal = Object.values(crebitToPendingAdjustTotal)
            .some((pendingAdjustTotal) => pendingAdjustTotal < 0);
          return isInvalidPendingAdjustTotal;
        };

        Object.defineProperty($scope.tabConfig, 'activeTitle', {
          get() {
            // Before initial load, we won't have an active tab
            if (!$scope.adjustmentsLoaded) return 'Edit Registration';
            // Afterwards, use the title from the tabConfig
            const activeTab = this.tabs.find(t => t.value === this.active);
            return activeTab.title;
          }
        });
        // Establish button config
        $scope.buttonConfig = {
          back: {},
          continue: {},
        };
        const couponParams = {
          params: {
            orgID: $scope.profile.organizationID
          },
          query: {
            groups: $scope.registration.groupID
          }
        };

        // Bring required functions onto $scope
        $scope.createCustomAdjustment = createCustomAdjustment;
        $scope.toggleSelectAll = toggleSelectAll;
        $scope.subtotal = () => {
          return $scope.selectedLineItems.reduce((sum, c) => sum += c.amount, 0);
        };

        // Start doin' stuff
        makeButtonsReactive();
        resetNewAdjustment();
        ProfileRegData.fetchResources('Coupon', couponParams)
          .then(() => addRegCoupons($scope.registration, $scope.regCoupons))
          .then(() => ProfileRegData.fetchResources('AddOn', couponParams))
          .then(() => addRegAddOns($scope.registration, $scope.regAddOns))
          .catch((err) => {
            _handleError('fetchResources', 'Failed to load adjustment data', err);
          })
          .finally(() => {
            $scope.adjustmentsLoaded = true;
            $scope.tabConfig.active = 'add-ons';

            if ($scope.glCodesP2FlagEnabled) {
              const updateCouponsRestriction = (totals) => {
                const { regTotal, couponsTotal, adjustedTuitionTotal, percentageCouponsTotal }
                  = totals;

                $scope.disableSelectAllCoupons = couponsTotal > regTotal
                  || percentageCouponsTotal > adjustedTuitionTotal;

                // If `Select All` checkbox was selected and became disabled, unselect all coupons
                if ($scope.disableSelectAllCoupons && $scope.selectAll['regCoupons']) {
                  $scope.selectAll['regCoupons'] = false;
                  toggleSelectAll('regCoupons');
                }

                // Disable the coupon if it exceeds the total registration cost
                // Disable the percentage coupon if it exceeds the adjusted tuition total
                disableExceedingCoupons(regTotal, adjustedTuitionTotal);
              };

              const totals = calculateTotals();
              updateCouponsRestriction(totals);

              // Disable unselected coupons if the total amount of selected coupons
              //   is greater than the total registration cost
              // Disable unselected percentage coupons if the total amount of selected
              //   percentage coupons is greater than the adjusted tuition total
              $scope.$watch('regCoupons', (fresh, stale) => {
                const selectionsChanged = fresh
                  .some((coupon, index) => coupon.selected !== stale[index].selected);

                if (selectionsChanged) {
                  const totals = calculateTotals();
                  const { regTotal, adjustedTuitionTotal } = totals;

                  disableUnselectedCoupons(fresh, regTotal, adjustedTuitionTotal);
                }
              }, true);

              // When the registration balance changes:
              //   1. Update the coupon restrictions
              //   2. Deselect all coupons if the balance decreases
              //   3. Remove the selected coupons if the balance decreases
              $scope.$watch('registration.balance', (fresh, stale) => {
                if (fresh !== stale) {
                  const totals = calculateTotals();
                  updateCouponsRestriction(totals);

                  if (fresh < stale) {
                    $scope.selectAll['regCoupons'] = false;
                    toggleSelectAll('regCoupons');

                    generateCrebits();
                  }
                }
              });
            }
          });


      } catch (error) {
        _handleError('CONTROLLER', 'Failed to initalize controller', error);
      }

      function _handleError(tag, msg, err = { stack: '' }) {
        const formatted = `[regAdjustmentEditor][${tag}] ${msg}${!err ? '' : `: ${err.message}\n${err.stack.substring(0, 400)}`}`;
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          console.error(formatted);
        }
      }

      function addRegCoupons(registration, regCoupons) {
        [
          ...registration.coupons.available,
          ...registration.coupons.applied,
        ].forEach((coupon) => {
          const regCoupon = new RegCoupon({ adjustment: coupon, registration });
          makeAppliedPropReactive(regCoupon, registration);
          regCoupons.push(regCoupon);
        });
      };

      function addRegAddOns(registration, regAddOns) {
        try {
          [
            ...registration.addOns.applied,
            ...registration.addOns.available
          ].forEach((addOn) => {
            createRegAddOn(addOn, registration).forEach(ra => regAddOns.push(ra));
          });
        } catch (error) {
          _handleError('addRegAddOns', 'Bulk RegAddOn creation failed', error);
        }
      };

      function makeAppliedPropReactive(regAdj, registration) {
        Object.defineProperty(regAdj, 'applied', {
          get() {
            if (this.type === RegAdjustment.TYPES.ADD_ON) {
              // Parent add-ons will have this getter on them. If any one of their options has been
              // purchased, they're all considered to be "applied" (we can't buy it again). So, we
              // just return parent's value.
              if (this.parent) return this.parent.applied;
              else return registration.addOns.applied.some(a => a.id === this.id);
            } else if (this.type === RegAdjustment.TYPES.COUPON) {
              return registration.coupons.applied.some(a => a.id === this.id);
            } else return false;
          }
        });
      }

      function createRegAddOn(addOn, registration) {
        const regAddOns = [];
        try {
          const parent = new RegAddOn({ adjustment: addOn, registration });
          makeAppliedPropReactive(parent, registration);
          regAddOns.push(parent);
          if (addOn.options && addOn.options.length) {
            addOn.options.forEach((opt) => {
              const option = new RegAddOn({ adjustment: opt, registration, parent });
              makeAppliedPropReactive(option, registration);
              regAddOns.push(option);
            });
          }
          return regAddOns;
        } catch (error) {
          _handleError('createRegAddOn', 'Failed to create RegAddOn instance', error);
        }
      };

      function resetNewAdjustment() {
        $scope.newAdjustment.description = null;
        $scope.newAdjustment.amount = null;
        $scope.newAdjustment.adjustedLineItemID = null;
      }

      function customAdjustmentErr(text) {
        return swal({
          title: 'Error Creating Adjustment',
          type: 'error',
          text
        });
      }

      function createCustomAdjustment() {
        if (!$scope.newAdjustment.description) {
          return customAdjustmentErr('Your custom adjustment is missing a description. Add one and try again.');
        }
        if (!$scope.newAdjustment.description) {
          return customAdjustmentErr('Your custom adjustment is missing an amount. Add one and try again.');
        }
        if (!$scope.newAdjustment.adjustedLineItemID && $scope.glCodesP2FlagEnabled) {
          return customAdjustmentErr('Your custom adjustment is missing a line item. Add one and try again.');
        }

        // Have to work around our totally stupid money input that will let you use _only_ negative
        // amounts, or not at all.
        const cloned = Object.assign({}, $scope.newAdjustment);
        cloned.amount = parseInt(cloned.amount * 100, 10);

        if ($scope.glCodesP2FlagEnabled) {
          const adjustedLineItem = $scope.lineItemsForAdjustment
            .find((lineItemForAdjustment) => {
              const isAdjustedLineItem = lineItemForAdjustment.value
                === parseInt($scope.newAdjustment.adjustedLineItemID);
              return isAdjustedLineItem;
            });
          const { label: adjustedLineItemDesc } = adjustedLineItem;
          cloned.description += ` (Adjusts ${adjustedLineItemDesc.replace('[REMOVED] ', '')})`;
        }

        const adjustment = new RegAdjustment({
          registration: $scope.registration,
          adjustment: cloned
        });
        if ($scope.glCodesP2FlagEnabled) adjustment.adjustedLineItemID = cloned.adjustedLineItemID;
        $scope.regAdjustments.push(adjustment);
        resetNewAdjustment();
      }

      function generateCrebits() {
        // From ALL adjustments, create crebits for those that are selected
        try {
          $scope.selectedLineItems = [
            ...$scope.regAddOns,
            ...$scope.regCoupons,
            ...$scope.regAdjustments
          ].reduce((preparedItems, item) => {
            // Must be selected, have a quantity, and NOT be applied
            // Quantity will be null/undefined if invalid thanks to validate-with settings
            if (!item.selected || !item.quantity || item.applied) return preparedItems;
            if (item instanceof RegAddOn) {
              preparedItems.push(ProfileRegData.createAddOnCrebit(item));
            } else if (item instanceof RegCoupon) {
              preparedItems.push(ProfileRegData.createCouponCrebit(item));
            } else if (item instanceof RegAdjustment) {
              preparedItems.push(ProfileRegData.createAdjustmentCrebit(item));
            }
            return preparedItems;
          }, []);
        } catch (error) {
          _handleError('generateCrebits', 'Crebit generation failed', error);
        }
      }

      function saveAdjustments() {
        $scope.requesting = true;
        return ProfileRegData.saveAdjustments($scope.profile, $scope.selectedLineItems)
          .catch((error) => {
            _handleError('saveAdjustments', 'Failed to save adjustment crebits', error);
            swal({
              type: 'error',
              title: 'Error Saving Changes',
              text: 'An error occurred while saving changes. Please try again later.'
            });
          })
          .finally(() => {
            $scope.requesting = false;
            $state.reload();
          });
      }

      function toggleSelectAll(category) {
        $scope[category].forEach((adj) => {
          if (adj.ui.multi || adj.ui.isOption) return;
          adj.selected = $scope.selectAll[category];
        });
      }

      function makeButtonsReactive() {
        Object.defineProperty($scope.buttonConfig.back, 'action', {
          get() {
            if ($scope.tabConfig.active !== 'complete') return () => $scope.editing = false;
            else return () => $scope.tabConfig.active = $scope.tabConfig.lastTab;
          }
        });
        Object.defineProperty($scope.buttonConfig.back, 'label', {
          get() {
            if ($scope.tabConfig.active !== 'complete') return 'Cancel';
            else return 'Back';
          }
        });
        Object.defineProperty($scope.buttonConfig.back, 'disabled', {
          get() {
            if ($scope.tabConfig.active !== 'complete') return false;
            // Don't allow back navigation while we're saving adjustments.
            else return $scope.requesting;
          }
        });
        Object.defineProperty($scope.buttonConfig.continue, 'action', {
          get() {
            if ($scope.tabConfig.active !== 'complete') {
              return () => {
                generateCrebits();
                $scope.tabConfig.lastTab = $scope.tabConfig.active;
                $scope.tabConfig.active = 'complete';
              };
            } else {
              return () => {
                saveAdjustments()
                  .then(() => {
                    $scope.editing = false;
                    flash('Line Item(s) Added');
                  });
              };
            }
          }
        });
        Object.defineProperty($scope.buttonConfig.continue, 'label', {
          get() {
            if ($scope.tabConfig.active !== 'complete') return 'Review Changes';
            else return 'Confirm Changes';
          }
        });
        Object.defineProperty($scope.buttonConfig.continue, 'disabled', {
          get() {
            // If we're not trying to save crebits, we should still be able to navigate like normal.
            if ($scope.tabConfig.active !== 'complete') return !$scope.adjustmentsLoaded;
            // If we're on the "confirm" step, don't allow saving crebits until the current request
            // (likely from removing an adjustment) completes or if there are no selected line items
            else return !$scope.adjustmentsLoaded || $scope.requesting || $scope.selectedLineItems.length < 1;
          }
        });
      }

      /**
       * Calculates the total registration cost, the total amount of unapplied coupons,
       *   the adjusted tuition total, and the total amount of unapplied percentage coupons.
       * @returns {{
       *   regTotal: number,
       *   couponsTotal: number,
       *   adjustedTuitionTotal: number,
       *   percentageCouponsTotal: number
       * }}
       */
      function calculateTotals() {
        let totalRelatedNegativeMiscAmount = 0;
        let totalTuitionNegativeMiscAmount = 0;

        // Calculate the total amount of negative miscellaneous line items attributed to the registration and tuition
        $scope.registration.crebits.forEach((crebit) => {
          const relatedNegativeMiscAmount = getRelatedNegativeMiscAmount(crebit.id);

          totalRelatedNegativeMiscAmount += relatedNegativeMiscAmount;
          if (crebit.description.startsWith('[TUITION]')) {
            totalTuitionNegativeMiscAmount += relatedNegativeMiscAmount;
          }
        });

        let regTotal = $scope.registration.crebits
          .reduce((acc, reg) => acc + reg.amount, 0);
        regTotal += totalRelatedNegativeMiscAmount;

        const couponsTotal = $scope.regCoupons
          .filter((coupon) => !coupon.applied)
          .reduce((acc, coupon) => acc + coupon.amount, 0);
        const percentageCouponsTotal = $scope.regCoupons
          .filter((coupon) => coupon.ui.percentage && !coupon.applied)
          .reduce((acc, coupon) => acc + coupon.amount, 0);

        const tuitionID = $scope.registration.tuition.id;
        // Get all adjustment IDs related to the tuition
        const relatedAdjustmentIDs = $scope.profile.crebitAdjustments
          .filter((crebitAdj) => crebitAdj.crebitID === tuitionID)
          .map((crebitAdj) => crebitAdj.adjustmentID);
        // Get the total amount of adjustments related to the tuition
        const adjustsTotal = $scope.registration.adjustments
          .reduce((acc, adj) => {
            if (!adj.addOnID && relatedAdjustmentIDs.includes(adj.id)) {
              acc += adj.amount;
            }
            return acc;
          }, 0);

        // Get the total coupons amount attributed to the tuition
        const appliedCoupons = $scope.registration.adjustments
          .filter((adj) => adj.description.startsWith('[COUPON]'));
        const appliedTuitionCouponsTotal = appliedCoupons
          .reduce((total, coupon) => {
            // Check if the coupon is attributed to the tuition
            const isAttributedToTuition = coupon.attributingTo.some(
              attr => attr.attributedTo === tuitionID
            );
            // Add the coupon amount if it's attributed to the tuition
            return isAttributedToTuition ? total + coupon.amount : total;
          }, 0);
        const adjustedTuitionTotal = $scope.registration.tuition.amount
          + adjustsTotal
          + appliedTuitionCouponsTotal
          + totalTuitionNegativeMiscAmount;

        return { regTotal, couponsTotal, adjustedTuitionTotal, percentageCouponsTotal };
      }

      /**
       * Calculates the total amount of negative miscellaneous line items attributed to the line item.
       * @param {number} crebitID - crebit ID
       * @returns {number} - The total amount of negative miscellaneous line items attributed to the line item
       */
      function getRelatedNegativeMiscAmount(crebitID) {
        const relatedNegativeMiscAmount = _.flatMap($scope.profile.negativeMisc, 'attributingTo')
          .filter((negativeMisc) => negativeMisc.attributedTo === crebitID)
          .reduce((sum, attr) => sum += attr.amount, 0);

        return relatedNegativeMiscAmount;
      };

      /**
       * Disables the coupon if its amount exceeds the total registration cost.
       * Disables the percentage coupon if its amount exceeds the adjusted tuition total.
       */
      function disableExceedingCoupons(regTotal, adjustedTuitionTotal) {
        $scope.regCoupons.forEach((coupon) => {
          const shouldDisableCoupon = coupon.amount > regTotal;
          const shouldDisablePercentCoupon = coupon.ui.percentage
            && coupon.amount > adjustedTuitionTotal;

          if (shouldDisablePercentCoupon) {
            coupon.disabled = true;
            coupon.selected = false;
            // Adds a property to the coupon to show a different tooltip message
            //  for percentage coupons that exceed the adjusted tuition total
            coupon.exceedsTuition = true;
          } else if (shouldDisableCoupon) {
            coupon.disabled = true;
            coupon.selected = false;
          } else {
            coupon.disabled = false;
            coupon.exceedsTuition = false;
          }
        });
      }

      /**
       * Disables unselected coupons if selected coupons total ≥ total registration cost,
       *   or adding the coupon exceeds it.
       *
       * Disables unselected percentage coupons if selected percentage coupons total
       *   ≥ adjusted tuition, or adding the coupon exceeds it.
       */
      function disableUnselectedCoupons(regCoupons, regTotal, adjustedTuitionTotal) {
        const selectedCouponsTotal = regCoupons
          .filter((coupon) => coupon.selected)
          .reduce((acc, coupon) => acc + coupon.amount, 0);
        const selectedPercentCouponsTotal = regCoupons
          .filter((coupon) => coupon.ui.percentage && coupon.selected)
          .reduce((acc, coupon) => acc + coupon.amount, 0);
        const shouldDisableAllUnselected = selectedCouponsTotal >= regTotal && regTotal > 0;

        regCoupons
          .filter((coupon) => !coupon.applied || !coupon.selected)
          .forEach((coupon) => {
            const projectedCouponsTotal = selectedCouponsTotal + coupon.amount;

            let projectedPercentCouponsTotal;
            if (coupon.ui.percentage) {
              projectedPercentCouponsTotal = selectedPercentCouponsTotal + coupon.amount;
            }

            const shouldDisableCoupons = projectedCouponsTotal > regTotal;
            const shouldDisablePercentCoupons = coupon.ui.percentage
              && ((selectedPercentCouponsTotal >= adjustedTuitionTotal && adjustedTuitionTotal > 0)
                || projectedPercentCouponsTotal > adjustedTuitionTotal
              );

            if (shouldDisableAllUnselected || shouldDisableCoupons) {
              coupon.disabled = !coupon.selected;
            } else if (shouldDisablePercentCoupons) {
              coupon.disabled = !coupon.selected;
              // Adds a property to the coupon to show a different tooltip message
              //  for percentage coupons that exceed the adjusted tuition total
              coupon.exceedsTuition = true;
            } else {
              coupon.disabled = false;
              coupon.exceedsTuition = false;
            }
          });
      }
    }
  };
});
