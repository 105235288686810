angular.module('dn').directive('trustedContactEdit', function () {
  return {
    templateUrl: 'directives/trusted-contacts/trusted-contact-edit/trusted-contact-edit.directive.html',
    restrict: 'E',
    scope: {
      back: '&',
      contact: '=',
      profile: '=',
      viewOnly: '='
    },
    controller: function ($http, $scope, $rootScope, ContactData) {
      'use strict';

      /// Avatar methods
      $scope.showModal = false;
      // Unrequire photo if universal contact
      $scope.requirePhoto = !$scope.contact.group_id ? (($rootScope.organization.properties.features || {}).attendance || {}).requirePhoto : false;
      $scope.actionRoute = function() {
        let apiRoute;
        if ($scope.contact.group_id) {
          apiRoute = `/api/organizations/${$scope.contact.group_id}/universal-contact-upload`;
        } else {
          apiRoute = `/api/organizations/${$rootScope.organization.id}/profiles/${$scope.contact.profile_id}/trusted-contact-upload`;
        }
        return apiRoute;
      };
      $scope.postUpload = s3Key => $scope.contact.picture = s3Key;

      $scope.cancel = () => $scope.showModal = false;

      $scope.rotateAvatar = function(degrees) {
        $scope.rotating = true;
        const data = {
          resource: $scope.contact,
          type:     'trustedContact',
          key:      $scope.contact.picture,
          degrees,
        };

        return $http.post(`/api/profiles/${$scope.profile.id}/edit-image`, data).then(function(result) {
          $scope.contact.picture = result.data;
          return $scope.rotating = false;
        }).catch((err) => {
          swal('Rotation Error', 'There was an error rotating the avatar photo.\nPlease refresh the page and try again', 'error');
        });
      };

      $scope.removeAvatar = function(isDropzone) {
        if (isDropzone) return $scope.contact.picture = null;

        return swal({
          type: 'warning',
          title: 'Delete Uploaded File',
          text: 'Are you sure you want to delete this file forever?',
          showCancelButton: true,
          confirmButtonColor: '#DE5347', //This is $red according to http://scg.ar-ch.org/
          confirmButtonText: 'Delete',
          allowEscapeKey: true
        }, (confirmed) => {
          if (confirmed) return $scope.contact.picture = null;
        });
      };


      $scope.phoneFieldTypes = [
        { label: 'Home',   value: 'home'   },
        { label: 'Office', value: 'office' },
        { label: 'Mobile', value: 'mobile' },
        { label: 'Other',  value: 'other'  }
      ];

      $scope.emailFieldTypes = [
        { label: 'Personal', value: 'personal' },
        { label: 'Work',     value: 'work'     },
        { label: 'Other',    value: 'other'    }
      ];

      const activeContactsEmails = (ContactData.findContactsByProfile($scope.profile.id) || []).map(({ id, name, emails }) => {
        return { id, name, email: emails.primary_value };
      });

      // Hide Users dropdown for existing Trusted Contacts and Universal Contact
      if (!$scope.contact.id && !$scope.contact.group_id) {
        $scope.existingUserOptions = [];
        $scope.existingUserID = null;
        getExistingUsers();
      }

      $scope.applyUser = function () {
        if (!$scope.existingUserID) return;
        Object.assign($scope.contact, mapExistingUserFields($scope.existingUserID));
      };

      $scope.validContact = function () {
        try {
          ContactData.validateContact($scope.contact);
          return validateAdditional($scope.contact);
        } catch (err) {
          return false;
        }
      };

      $scope.save = function () {
        const existingContact = activeContactsEmails.find(({ id, email }) => {
          return email === $scope.contact.emails.primary_value && id !== $scope.contact.id;
        });
        if (existingContact) {
          return swal({
            type: 'warning',
            title: 'Email already taken',
            text: `
              <p>The email address <strong>${existingContact.email}</strong>
              is already taken by ${existingContact.name}.</p>
            `,
            html: true,
            confirmButtonText: 'Ok'
          });
        }
        const isNew = !$scope.contact.id;
        return ContactData.saveTmpContact().then(() => {
          if (isNew) {
            flash(`${$scope.contact.name} added`);
          } else {
            flash(`${$scope.contact.name} updated`);
          }
          return $scope.back();
        });
      };

      $scope.delete = function () {
        return swal({
          type: 'info',
          title: 'Delete Trusted Contact',
          text: `
            You are about to delete ${$scope.contact.name} from ${$scope.profile.givenName}'s trusted contacts.
          `,
          showCancelButton: true,
          confirmButtonText: "Continue"
        }, (isConfirm) => {
          if (isConfirm) {
            const toDeactivate = ContactData.findContact($scope.contact.emails.primary_value, $scope.profile.id);
            return toDeactivate.destroy().then(() => {
              toDeactivate.deactivated = new Date().toISOString();
              flash(`${$scope.contact.name} deleted`);
              return $scope.back();
            });
          }
        });
      };

      function getExistingUsers () {
        return $http.get(`/api/profiles/${$scope.profile.id}/users`).then(({ data }) => {
          $scope.existingUsers = data.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
          }, {});

          data.forEach((user) => {
            if (!activeContactsEmails.some((contact) => contact.email === user.email)) {
              $scope.existingUserOptions.push({ label: user.name, value: user.id });
            }
          });
        });
      }

      function mapExistingUserFields (existingID) {
        const { name, location: address, email, phone } = $scope.existingUsers[existingID];
        const emails = {
          primary_value: email,
          primary_type: null,
          secondary_value: null,
          secondary_type: null,
          tertiary_value: null,
          tertiary_type: null,
        };
        const phones = {
          primary_value: phone,
          primary_type: null,
          secondary_value: null,
          secondary_type: null,
          tertiary_value: null,
          tertiary_type: null,
        };
        return { name, address, emails, phones };
      }

      // email and phones fields must have both type and value keys
      // simple even number check on truthy values length does the trick
      function validateAdditional ({ emails, phones, picture }) {
        return hasBothTypeAndValue(emails) && hasBothTypeAndValue(phones) && hasPicture(picture);
      }

      function hasBothTypeAndValue (field) {
        return Object.values(field).filter(f => !!f).length % 2 === 0;
      }

      function hasPicture(picture) {
        if ($scope.requirePhoto) {
          if (picture !== null) return true;
        } else {
          return true;
        }
      }
    }
  };
});
