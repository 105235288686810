angular.module('dn').directive('bulkRegDeactivate', function() {
  return {
    templateUrl: 'directives/bulk-reg-deactivate/bulk-reg-deactivate.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      back: '=',
      fullTextSearchEnabled: '='
    },

    controller($http, $scope) {
      $scope.disableButton = true;
      $scope.showPast = true;

      const allGroups = $scope.organization.$selectableChildren().map((child) => {
        return { label: child.label, value: child.value.toString() };
      });

      const currentGroups = $scope.organization.$selectableChildren().filter((child => {
        return child.phase !== 'past';
      })).map((child) => {
        return { label: child.label, value: child.value.toString() };
      });

      $scope.groupChoices = allGroups;
      $scope.selectedGroups = null;

      $scope.showPastGroups = function(showPast) {
        $scope.groupChoices = showPast ? allGroups : currentGroups;
      };

      $scope.deactivateRegistrations = function() {
        const groupIDs = $scope.selectedGroups.split(',').map((g) => parseInt(g));;
        const profileIDs = getProfiles();

        if ($scope.fullTextSearchEnabled && !profileIDs.length) {
          return window.swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to deactivate registrations.',
          });
        }

        const baseURL = `/api/operator/bulk-reg-deactivate/${$scope.organization.id}`;
        const precheckURL = `${baseURL}/deactivate-precheck`;
        const deactivateURL = `${baseURL}/deactivate`;

        return $http.post(precheckURL, {profileIDs, groupIDs}).then((precheckResults) => {
          const { registrationCount } = precheckResults.data;
          if (!registrationCount) {
            return precheckNoDataSwal();
          } else {
            precheckSuccessSwal(precheckResults.data, (doIt) => {
              if (doIt) {
                return $http.post(deactivateURL, {profileIDs, groupIDs})
                  .then(updateSuccessSwal)
                  .catch(showErrorSwal);
              }
            });
          }
        }).catch(() => {
          return showErrorSwal();
        });
      };

      // old method, to be removed when FullTextSearchProfiles is fully implemented
      function getProfiles () {
        if ($scope.fullTextSearchEnabled) {
          return $scope.profiles;
        } else {
          return $scope.profiles.some(profile => profile.selected)
            ? $scope.profiles.filter(profile => profile.selected).map(profile => profile.profiles.id)
            : $scope.profiles.map(profile => profile.profiles.id);
        }
      }

      function precheckSuccessSwal({profileCount, registrationCount}, callback) {
        const profileNoun = profileCount === 1 ? 'profile' : 'profiles';
        const registrationNoun = registrationCount === 1 ? 'registration' : 'registrations';

        return window.swal({
          title: 'Deactivate Registrations?',
          type: 'warning',
          showCancelButton: true,
          allowEscape: true,
          html: true,
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          text: `
            <h4>This action will deactivate registrations for ${profileCount} ${profileNoun}. \
            ${registrationCount} ${registrationNoun} will be deactivated.</h4>

            <p><strong>Warning</strong>: This action affects registrations only, and will not adjust the selected profiles' ledgers.
            This may cause balancing issues for organizations using Registration.</p>

            <p>Would you like to continue?</p>
          `,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Deactivate',
        }, callback);
      }

      function precheckNoDataSwal() {
        return window.swal({
          title: 'No Registrations To Deactivate',
          type: 'info',
          html: true,
          text: 'The groups chosen do not apply to any of the currently selected profiles.'
        });
      }

      function updateSuccessSwal ({ data }) {
        const successCount = data.counts.success;

        const pluralizeRegistration = (count) => count === 1 ? 'registration' : 'registrations';
        const registrationNoun = pluralizeRegistration(successCount);

        return swal({
          title: 'Success',
          type: 'success',
          html: true,
          text: `<p>Successfully deactivated <strong>${successCount}</strong> ${registrationNoun}.</p>`
        });
      }

      function showErrorSwal(response) {
        return window.swal({
          title: 'Error Deactivating Registrations',
          type: 'error',
          html: true,
          text: deactivateErrorMessage(response)
        });
      }

      function deactivateErrorMessage(response) {
        const counts = response.data && response.data.counts;

        if (!counts || !counts.success) {
          return '<p>An error occurred while deactivating registrations. Please reload the page and try again.</p>';
        } else {
          return '<p>'
            + 'Some registrations could not be deactivated due to errors. '
            + 'Please reload the page and try again.'
            + '</p>'
            + '<p>'
            + `Registrations Deactivated: <strong>${counts.success}</strong><br>`
            + `Errors: <strong>${counts.error}</strong>`
            + '</p>';
        }
      }
    }
  };
});
