lib.registerState('manager.attendance', {
  url: '/attendance?filters',
  params: {
    filters: ''
  },
  templateUrl: 'states/manager/attendance/attendance.state.html',
  controller: function($filter, $rootScope, $state, $scope, AttendanceData, ContactData, session) {
    'use strict';

    // Separate this out for easier access—we'll put it in the UI_CONFIG later but we need it now so
    // we can stop the controller from running if the feature isn't enabled.
    const settings = ($rootScope.organization.properties.features || {}).attendance || {
      enabled: false,
      universalContactEnabled: false,
      bypassModeEnabled: false,
    };
    $scope.featureEnabled = settings.enabled;
    // Has to happen here because we use the division name in the "Attendance Not Enabled" message
    const division = window.dnim.getDivision($rootScope.organization.properties.division);
    $scope.divisionName = division.name;
    // Don't go any further if the feature isn't enabled.
    if (!$scope.featureEnabled) return;

    const PROFILE_STATE = 'manager.attendance.list.profiles.profile';

    // These correspond to timeline event types
    const ACTION_TYPES = {
      CHECK_IN: 'Check In',
      CHECK_OUT: 'Check Out',
    };

    const TEXT = {
      'check-in': {
        title: 'Check In/Out',
        instructions: `
              Track ${division.patient.singular} drop-off and pick-up by first selecting a saved list
              or using any of the filters from the List Builder below. By default,
              all active ${division.patient.plural} will appear unless you filter for a specific group.
            `,
      },
      'attendance': {
        title: 'Attendance',
        instructions: `
              Track attendance using any of the filters from the List Builder below.
              By default, all current ${division.patient.plural} will appear unless you filter for a specific group.
            `,
      },
      'audit': {
        title: 'Audit Record',
        instructions: `
              Find and correct mistakes in existing records, delete erroneous records, or create missing
              records. You may also add notes or edit notes attached to records.
            `,
      }
    };

    const actions = {
      selectProfile(profileID) {
        const profile = AttendanceData.getProfile(profileID);
        if (!profile) return;
        const bypassModeEnabled = AttendanceData.ui.bypassModeFilter !== null;
        if (!(profile.mostRecentRecord || {}).checkedIn && !bypassModeEnabled) return;
        AttendanceData.toggleProfileSelected(profileID);
        if (profile.selected) {
          // If they have a most recent record and it's checked in, use that record
          let baseRecord;
          if ((profile.mostRecentRecord || {}).checkedIn) {
            baseRecord = profile.mostRecentRecord;
          } else {
            baseRecord = {
              profile_id: profile.id,
              orgID: profile.organizationID
            };
          }
          return AttendanceData.initTmpRecord(baseRecord);
        } else {
          return AttendanceData.deleteTmpRecord(profileID);
        }
      },
      goToProfile(profileID) {
        return $state.go('manager.attendance.list.profiles.profile.trustedContacts', {
          profileID
        });
      },
      auditProfile(profileID) {
        return $state.go('manager.attendance.list.profiles.profile.audit', {
          profileID
        });
      }
    };

    // Make sure we've a valid action param before setting UI stuff
    let actionParam = getActionParam();

    // Prevent any data leakage (e.g. collections calls) from child states if permissions aren't met
    $scope.hasPermissions = actionParam === 'audit'
      ? $filter('permissionVisible')({ attendance_auditing: 'allow' })
      : $filter('permissionVisible')({ attendance_taking: 'view' });
    if (!$scope.hasPermissions) return;

    // Used in setStateUi to track last visited state and prevent unnecessary setStateUi calls
    let lastLocation = 'manager.attendance';
    // This listener is CRITICAL. It sets the button functionality for ALL CHILD STATES.
    const stateChangeListener = $scope.$on('$stateChangeSuccess', function () {
      // No need to set UI for the top level state; we're immediately directed elsewhere.
      if ($state.current.name === 'manager.attendance') return;
      else {
        // Update this on state transition so we always have the right actionParam
        actionParam = getActionParam();
        return setStateUi();
      }
    });

    const buttonConfig = {
      'manager.attendance.list.profiles.profile': function(actionTitle, actionType) {
        return {
          next: Object.create(Object.prototype, {
            disabled: {
              configurable: false,
              enumerable: true,
              get() {
                return !AttendanceData.selectedContact;
              }
            },
            text: {
              configurable: false,
              enumerable: true,
              get() {
                return `${actionSentence(actionTitle)}`;
              }
            },
            action: {
              configurable: false,
              enumerable: false,
              get() {
                return function () {
                  try {
                    return configureSwal('confirmation', actionTitle, actionType);
                  } catch (error) {
                    return configureSwal('error', error);
                  }
                };
              }
            },
          }),
          back: {
            text: 'Cancel',
            action() {
              clearExistingSelections();
              // Go back to the list state
              // We know it's check-in because this state is only for check-in
              return $state.go('manager.attendance.list.profiles', { action: actionParam });
            }
          }
        };
      },
      'manager.attendance.list.profiles': function() {
        const config = {
          back: Object.defineProperties({}, {
            text: {
              enumerable: true,
              configurable: false,
              get() {
                // eslint-disable-next-line eqeqeq
                return AttendanceData.selectedProfiles.length === 0
                  ? 'Back to List Builder'
                  : 'Clear Selections';
              }
            },
            action: {
              enumerable: false,
              configurable: false,
              value: function() {
                if (AttendanceData.selectedProfiles.length === 0) {
                  return $state.go('manager.attendance.list', {
                    action: $state.params.action || 'check-in'
                  });
                } else {
                  return clearExistingSelections();
                }
              }
            },
            hide: {
              enumerable: true,
              writable: true,
              value: false
            },
            disabled: {
              enumerable: true,
              writable: true,
              value: false
            },
          }),
          next: Object.defineProperties({}, {
            text: {
              enumerable: true,
              configurable: false,
              get() {
                let actionVerb;
                switch (AttendanceData.ui.bypassModeFilter) {
                  case true:
                    actionVerb = 'Check Out';
                    break;
                  case false:
                    actionVerb = 'Check In';
                    break;
                  default:
                    actionVerb = 'Mark';
                    break;
                }
                return actionSentence(actionVerb);
              }
            },
            action: {
              enumerable: false,
              configurable: false,
              value: function () {
                const filter = AttendanceData.ui.bypassModeFilter;
                // bypassModeFilter gets reset on state change, so we can depend on it being null if
                // we're not in the Check In/Out state. If that ever changes, this will need work.
                if (filter === null) {
                  return initAttendanceSwal(() => {
                    return configureSwal('confirmation', 'Attendance', AttendanceData.ui.attendanceType);
                  });
                } else {
                  // Make sure the UC is the selected contact
                  ContactData.selectedContact = ContactData.universalContact;
                  // If the filter is false, we're checkin' folks in; true, we're checking folks out
                  const action = ACTION_TYPES[filter === false ? 'CHECK_IN' : 'CHECK_OUT'];
                  return configureSwal('confirmation', action, action);
                }
              }
            },
            hide: {
              enumerable: true,
              configurable: false,
              get() {
                return actionParam === 'audit'
                  || (actionParam === 'check-in' && AttendanceData.ui.bypassModeFilter === null);
              }
            },
            disabled: {
              enumerable: true,
              configurable: true,
              get() {
                return AttendanceData.selectedProfiles.length === 0;
              }
            },
          }),
        };

        return config;
      },
      'manager.attendance.list': function() {
        return {
          // This button should always be hidden in this state
          back: {
            text: 'Back to List Builder',
            action() { return; },
            hide: true,
            disabled: false,
          },
          next: {
            text: `View ${patientNoun('singular')} List`,
            action() {
              return $state.go('manager.attendance.list.profiles');
            },
            hide: false,
            disabled: false,
          }
        };
      },
    };

    const UI_CONFIG = {
      title: '',
      instructions: '',
      loading: null,
      whenInList: null,
      actions,
      buttons: {},
      attendanceType: '',
      helpers: {
        patientNoun
      },
      settings,
      division
    };

    // Make the UI config available throughout all child states via the service
    AttendanceData.initUi(UI_CONFIG);
    // Attach to $scope so we can bring our buttons to life.
    $scope.ui = AttendanceData.ui;
    // Load in our Universal Contact if appropriate
    getUniversalContact();
    // Move us on to one of the child states
    $state.go('manager.attendance.list', { action: actionParam });

    // Everything below gets hoisted

    function setStateUi() {
      if ($state.current.name.includes('attendance')) {
        const inSingleProfile = (/manager\.attendance\.list\.profiles\.profile.+/).test($state.current.name);
        const uiState = inSingleProfile ? PROFILE_STATE : $state.current.name;
        uiState === 'manager.attendance.list' ? AttendanceData.setUiEntry('whenInList', true) : AttendanceData.setUiEntry('whenInList', false);
        if (lastLocation === uiState) return;
        else lastLocation = uiState;
        /* eslint-disable indent */
        switch (uiState) {
          case 'manager.attendance.list.profiles.profile':
            clearExistingSelections();
            setProfileStateUI();
            break;
          case 'manager.attendance.list.profiles':
            clearExistingSelections();
            setProfileListStateUI();
            // No break! We want to fall through to the default case after clearing selection.
          default:
            AttendanceData.setUiEntry('title', TEXT[actionParam].title);
            AttendanceData.setUiEntry('instructions', TEXT[actionParam].instructions);
            AttendanceData.setUiEntry('buttons', buttonConfig[uiState]());
            break;
        }
        /* eslint-enable indent */
      } else {
        // If we're no longer in the `profiles` state, destroy the event listener for better perf.
        stateChangeListener();
      }
    }

    function setProfileStateUI() {
      const targetId = $state.params.profileID;
      const targetProfile = AttendanceData.getProfile(targetId);
      // Have to make sure the profile is in the store. It won't be if we just refreshed the page.
      if (targetProfile) AttendanceData.setProfileSelected(targetId);
      else return $state.go('manager.attendance.list.profiles', { action: actionParam });
      // Configure stuff based on profile check in status
      let actionTitle, actionType, tmpRecord;
      if ((targetProfile.mostRecentRecord || {}).checkedIn) {
        actionTitle = 'Check Out';
        actionType = ACTION_TYPES.CHECK_OUT;
        tmpRecord = targetProfile.mostRecentRecord;
      } else {
        actionTitle = 'Check In';
        actionType = ACTION_TYPES.CHECK_IN;
        // eslint-disable-next-line
        tmpRecord = { profile_id: targetProfile.id, orgID: targetProfile.organizationID };
      }
      // Init the tmp record in the store. This will be accessed when we save our check in/out.
      AttendanceData.initTmpRecord(tmpRecord);
      const tmpNote = {
        profileID: targetProfile.id,
      };
      AttendanceData.initTmpNote(tmpNote);
      // Set the button action/text/visibility
      AttendanceData.setUiEntry('buttons', buttonConfig[PROFILE_STATE](actionTitle, actionType));
    }

    function setProfileListStateUI() {
      // This controls which action takes place when we click on a profile
      /* eslint-disable indent */
      switch (actionParam) {
        case 'attendance':
          if (!AttendanceData.ui.attendanceType) {
            AttendanceData.setUiEntry('attendanceType', 'Attendance');
          }
          AttendanceData.setUiEntry('onProfileInteract', actions.selectProfile);
          break;
        case 'audit':
          AttendanceData.setUiEntry('attendanceType', null);
          AttendanceData.setUiEntry('onProfileInteract', actions.auditProfile);
          break;
        default:
          AttendanceData.setUiEntry('attendanceType', null);
          AttendanceData.setUiEntry('onProfileInteract', actions.goToProfile);
          break;
      }
      /* eslint-enable indent */
    }

    function configureSwal(swalType, actionTitle, actionType) {
      let method;
      /* eslint-disable indent */
      switch (actionType) {
        case ACTION_TYPES.CHECK_IN:
          method = 'checkIn';
          break;
        case ACTION_TYPES.CHECK_OUT:
          method = 'checkOut';
          break;
        case ACTION_TYPES.AUDIT:
          method = 'audit';
          break;
        default:
          method = 'takeAttendance';
          break;
      }
      /* eslint-enable indent */

      const preconfigured = {
        error(err) {
          const useHtml = err && err.data && err.data.failedRecords;
          return swal({
            html: useHtml,
            type: 'error',
            title: `${actionTitle} Error`,
            text: !err.data.failedRecords
              ? err.message
              : `
                  <p>Couldn't Check In All Profiles.</p>
                  <p>Potential Duplicate Check-In For:</p>
                  <br />
                  ${generateProfileList(err.data.failedRecords, true)}
                  <br />
                  <p>Please Refresh and Try Again.</p>
                `
          });
        },
        success() {
          return swal({
            type: 'success',
            title: `${actionTitle} Success`,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }, () => {
            // If we're taking attendance, we don't want to navigate
            // back to the list builder, so that users can keep taking attendance
            if (method === 'takeAttendance') return clearExistingSelections();
            return AttendanceData.ui.buttons.back.action();
          });
        },
        confirmation() {
          // Check In and Check Out are already grammatically correct, but attendance needs more.
          const verb = AttendanceData.ui.title === 'Attendance' ? 'Take Attendance for' : actionTitle;
          return swal({
            html: true,
            title: `${actionSentence(verb)}?`,
            text: `<p>Selected ${patientNoun()}:</p><br />${generateProfileList()}`,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: true,
            showCancelButton: true,
            closeOnConfirm: false,
          }, (confirmed) => {
            if (confirmed) {
              const providerID = ($scope.provider || {}).id;
              AttendanceData[method](session.user, providerID, actionType)
                .then(() => preconfigured.success())
                .catch((err) => preconfigured.error(err));
            }
          });
        }
      };

      return preconfigured[swalType]();
    }

    function patientNoun(forcedChoice) {
      const choice = forcedChoice || (AttendanceData.selectedProfiles.length === 1 ? 'singular' : 'plural');
      return division.patient[choice];
    }

    function actionSentence(verb) {
      return `${verb} ${AttendanceData.selectedProfiles.length} ${patientNoun().capitalize()}`;
    }

    function generateProfileList(profiles = AttendanceData.selectedProfiles, errorList = false) {
      if (errorList) {
        profiles = AttendanceData.selectedProfiles.filter(profile => profiles.includes(profile.id));
      }
      const listItems = _(profiles)
        .orderBy(['familyName', 'givenName', 'middleName'], ['asc', 'asc', 'asc'])
        .reduce((html, profile) => {
          html += `<li>${profile.familyName}, ${profile.givenName} ${profile.middleName || ''}</li>`;
          return html;
        }, '');
      return `<ul style="padding-left: 5rem; text-align: left;">${listItems}</ul>`;
    }

    function clearExistingSelections() {
      // Clear existing selections
      AttendanceData.deselectAllProfiles();
      AttendanceData.selectedContact = undefined;
      AttendanceData.clearTmpRecords();
      AttendanceData.clearTmpNotes();
    }

    function getActionParam() {
      return Object.keys(TEXT).includes($state.params.action) ? $state.params.action : 'check-in';
    }

    function initAttendanceSwal(nextSwal) {
      const profilesInAttendance = AttendanceData.selectedProfiles.filter((p) => p.inAttendance);
      if (profilesInAttendance.length) {
        const multiple = profilesInAttendance.length > 1;
        return swal({
          type: 'warning',
          title: 'Duplicate Attendance Warning',
          html: true,
          text: `
            <p>The following ${patientNoun(multiple ? 'plural' : 'singular')}
            already ${multiple ? 'have' : 'has'}
            an attendance for ${AttendanceData.ui.attendanceType}.
            Would you like to mark them again?</p>
            <br />
            ${generateProfileList(profilesInAttendance)}
          `,
          allowOutsideClick: true,
          allowEscapeKey: true,
          showCancelButton: true,
          closeOnConfirm: false,
        }, nextSwal);
      } else {
        return nextSwal();
      }
    }

    function getUniversalContact() {
      // When applicable, load the org's UC
      const { settings } = AttendanceData.ui;
      if (settings.universalContactEnabled || settings.bypassModeEnabled) {
        return ContactData.loadOrgContact(true)
          .catch(() => errorSwal({
            title: 'Failed to Load Universal Contact',
            text: 'If you need to edit the Universal Contact, please reload and try again'
          }));
      }
    }
  }
});
