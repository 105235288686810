angular.module('dn').directive('prescreeningTemplateEditor', function() {
  return {
    templateUrl: 'directives/prescreening/template-editor/template-editor.directive.html',
    restrict: 'E',
    controller($scope, PrescreeningData) {
      try {

        const DYNAMIC_OPTIONS = [
          { label: 'Long Answer', value: 'textarea', optgroup: 'Custom Questions' },
          { label: 'Short Answer', value: 'text', optgroup: 'Custom Questions' },
          { label: 'Number', value: 'number', optgroup: 'Custom Questions' },
          { label: 'Multi-select', value: 'tags', optgroup: 'Custom Questions' },
          { label: 'Select', value: 'select', optgroup: 'Custom Questions' },
          { label: 'Yes/No', value: 'boolean', optgroup: 'Custom Questions' },
        ];
        const STATS_ENABLED_OPTIONS = [
          { label: 'Temperature', value: 'temperature', optgroup: 'Stats-enabled Questions' },
          { label: 'Has Symptoms', value: 'stats_boolean', optgroup: 'Stats-enabled Questions' },
          { label: 'Symptoms', value: 'symptoms', optgroup: 'Stats-enabled Questions' },
          { label: 'Photo Upload', value: 'uploads', optgroup: 'Stats-enabled Questions' },
        ];
        const DATA_TYPE_TO_INPUT_TYPE = {
          'temperature': 'temperature',
          'stats_boolean': 'boolean',
          'symptoms': 'tags',
          'uploads': 'upload',
        };

        // Make UI available on $scope
        $scope.ui = PrescreeningData.ui;
        // Ideally, we wouldn't have to do this. However without it, we lose the reference to the
        // actual `.template` array and it breaks `deleteQuestion`
        $scope.PrescreeningData = PrescreeningData;
        // Will eventually hold all the question choices we need.
        $scope.questionTypeOptions = [];
        PrescreeningData.setUiEntry('newQuestionType', null);

        // Handle display of Add New Question cards
        PrescreeningData.setUiEntry('addingNewQuestion', false);

        $scope.handleNewQuestionCard = function(addNewQuestion) {
          // If the user doesn't save the changes on a question and decided to edit another one we display a warning if they want to discard their changes
          // If they confirm we discard those changes. Finally we only call this when we are going to add a new question not after we select the new question and decide to add it
          if (!addNewQuestion
              && PrescreeningData.questionEditTarget
              && (PrescreeningData.checkQuestionIfEdited(PrescreeningData.questionEditTarget) || PrescreeningData.questionEditTarget.isNew)
          ) {
            swal({
              type: 'warning',
              title: 'Unsaved Changes',
              text: 'Are you sure you want to proceed? Your changes will be discarded.',
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Discard Changes',
              cancelButtonText: 'Continue Editing'
            }, function(confirmed) {
              if (confirmed) {
                // If we made changes we need to confirm with the provider before removing
                if (PrescreeningData.questionEditTarget.isNew) PrescreeningData.deleteQuestion(PrescreeningData.questionEditTarget);
                PrescreeningData.clearTmpQuestion();
                showHideEditor();
              }
            });

          } else {
            // Since the provider has not made changes we need to close the question editor
            PrescreeningData.clearTmpQuestion();
            showHideEditor();
          }

          // Hide or reveal the editor
          function showHideEditor() {
            PrescreeningData.setUiEntry('addingNewQuestion', !PrescreeningData.ui.addingNewQuestion);
          }

          // If we're in "adding" mode, make sure to clear out any previous selections
          if ($scope.ui.addingNewQuestion) PrescreeningData.setUiEntry('newQuestionType', null);
          // If we've decided to add it to the template, handle that.
          if (addNewQuestion) {
            const newType = $scope.ui.newQuestionType;
            const questionParams = {
              dataType: DATA_TYPE_TO_INPUT_TYPE[newType] !== undefined ? newType : 'dynamic',
              inputType: DATA_TYPE_TO_INPUT_TYPE[newType] || newType
            };
            PrescreeningData.addQuestion(questionParams);
          }
        };

        // Any time the template's length changes, recompute the available question type options
        $scope.$watch('PrescreeningData.template.length', () => {
          PrescreeningData.setUiEntry('questionTypeOptions', getQuestionTypeOptions());
        });

        /**
         * Get all _available_ question type options (excludes stats-enabled questions that are
         * already in use).
         * @return {Object[]} An array of objects for use with a select input
         */
        function getQuestionTypeOptions() {
          const currentDataTypes = PrescreeningData.template.map(question => question.dataType);
          const availableStatsEnabledQuestions = STATS_ENABLED_OPTIONS.filter(opt => !currentDataTypes.includes(opt.value));
          return [...availableStatsEnabledQuestions, ...DYNAMIC_OPTIONS];
        }
      } catch (error) {
        log.error('[template-editor]', error);
      }
    }
  };
});
