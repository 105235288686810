angular.module('dn').directive('attendanceProfile', function () {
  return {
    templateUrl: 'directives/attendance-profile/attendance-profile.directive.html',
    restrict: "E",
    scope: {
      profile: "=",
      statusIndicator: "=",
    },
    link: function(scope, elm) {

      const raw = elm[0];
      // Since we have an element wrapping our list item that we cant turn into an li, we need to
      // make sure angular recognizes it as a list item
      raw.setAttribute('role', 'listitem');
      raw.setAttribute('tabindex', 0);
    }
  };
});
