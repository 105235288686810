lib.registerState "home.account",
  url: "/user-settings"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "User Settings"
      return
    session: ($http, $rootScope) ->
      $http.get("/api/session?reaccess").then (result) ->
        $rootScope.orgScope = result.data.orgScope
        result.data
    nav: ($rootScope, session) ->
      $rootScope._nav = [
        {
          text: "About You"
          state: "home.account.information"
          icon: "info-circle"
          hide: $rootScope.orgScope
        }
        {
          text: $rootScope.division.patient.plural.capitalize()
          state: "home.account.profiles"
          icon: "users"
        }
        {
          text: "Security"
          state: "home.account.security"
          icon: "lock"
          hide: $rootScope.orgScope
        }
        {
          text: "Billing"
          state: "home.account.billing"
          icon: "dollar-sign"
        }
        {
          text: "Notifications"
          state: "home.account.notifications"
          icon: "envelope"
        }
      ]
      return
    user: (session, User) ->
      new User(session.userID).load().then (user) ->
        user
  templateUrl: "states/home/account/account.state.html"
  controller: ($scope, $state, user) ->
    $scope.user = user
    return
