Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "Coupon", ($http) ->
  Coupon = new Resource(
    route: "/api/groups/:groupID/coupons/:id"
    schema:
      code:
        type: "text"
        required: true

      amount:
        type: "number"
        required: true

      capacity:
        type: "number"

      groupID:
        type: "number"

      expires:
        type: "date"

      properties:
        type: "json"

      shared:
        type: "boolean"

      multiUse:
        type: "boolean"
  )
  Coupon
