angular.module('dn').directive('protectionPlan', function() {
  return {
    templateUrl: 'directives/registration-wizard/protection-plan/protection-plan.directive.html',
    restrict: 'E',
    scope: {
      profile: '=',
      groups: '=',
      policies: '=',
      insuranceBuy: '=',
      organization: '=',
      standalone: '=',
      isTestAccount: '=',
      isComplete: '=',
      bypass: '=',
      onContinue: '='
    },
    controller($scope) {
      /*
       * Remove legacy location data that did not store address components separately,
       * forcing the participant to re-enter their address. Needed so we can test that
       * an address is protection plan eligible.
       */
      if ($scope.profile.location && !$scope.profile.location.state) {
        delete $scope.profile.location;
      }

      // name previously purchased policies
      if ($scope.standalone && $scope.profile.insured) {
        const regMap = _.keyBy($scope.profile.registrations, 'id');
        $scope.profile.insured.map(policy => {
          const reg = regMap[policy.registrationID];
          if (reg && reg.group && reg.group.name) policy.name = reg.group.name;
        });
      }

      const orgProps = $scope.organization.properties;
      const bypass = [
        ($scope.groups.every(g => g.waitlistRegistration)),
        (!orgProps.features || !orgProps.features.travelInsurance),
        (orgProps.tpLandingOnly),
      ].some(condition => condition);

      if (bypass && !$scope.standalone) return $scope.bypass();

      $scope.division = window.dnim.getDivision($scope.organization.properties.division);

      $scope.insuranceBuy = typeof($scope.insuranceBuy) === 'boolean' ? $scope.insuranceBuy : initPurchasingChoice();

      const planChoices = [
        { label: 'Basic', value: 'basic' },
        { label: 'Deluxe', value: 'deluxe' },
      ];

      const tier = 'basic';

      function getPlanChoices(policy) {
        policy.planChoices = planChoices;
        policy.tier = tier;
      };

      // Actually perform the calculation
      $scope.calculateCost = (policy) => {
        coercePlan(policy);

        policy.baseCost = window.dnim.protPlans.calculatePremium(policy, 'basic');
        policy.cfarCost = window.dnim.protPlans.calculatePremium(policy, 'deluxe');

        // This is to calculate how much the protection plan cost
        policy.planCost = window.dnim.protPlans.calculatePremium(policy);
      };

      $scope.insuranceChoices = [
        {label: 'Add Protection Plan', value: true},
        {label: 'Decline Protection Plan', value: false}
      ];

      $scope.displayPolicies = initDisplayPolicies();

      /**
       * Coerce Protection Plan and alert user to any changes. Logic is used in several places, so
       * we've put it into this function.
       * @param {Object|ProtectionPlan} policy The policy to coerce. Will be mutated.
       * @returns {boolean} Whether or not the plan was coerced.
       */
      function coercePlan(policy) {
        const messages = window.dnim.protPlans.coercePlan(policy);
        if (messages && messages.length) {
          messages.forEach(message => flash(message));
          return true;
        } else {
          return false;
        }
      }

      $scope.cancelEdit = (policy) => {
        policy.start = policy.startRef;
        policy.finish = policy.finishRef;
        // Still need to recalculate on cancel, since we restore the dates to the
        // session dates, not the previously selected dates.
        $scope.calculateCost(policy);
        policy.editing = false;
      };

      $scope.updateDates = (policy) => {
        // Coerce plan before finalizing edits; don't allow the user to close the editing session
        // until they've affirmed the new dates.
        const coerced = coercePlan(policy);
        if (!coerced) policy.editing = false;
        $scope.calculateCost(policy);
      };

      $scope.totalCost = (costType) => {
        const policiesToSum = $scope.standalone ? $scope.displayPolicies.filter(p => p.selected) : $scope.displayPolicies;
        return _.sumBy(policiesToSum, costType);
      };

      $scope.isComplete = () => {
        if (_.isNull($scope.insuranceBuy)) return false;
        else if (!_.every($scope.displayPolicies, (p) => { return p.start && p.finish; })) return false;
        else if (!$scope.planEligible.isValid && $scope.insuranceBuy === true) return false;
        else if ($scope.insuranceBuy === true && $scope.profile.location) return $scope.standalone ? _.some($scope.displayPolicies, 'selected') : true;
        else if ($scope.insuranceBuy === false) return true;
        else return false;
      };

      /**
       * Checks policy/profile validity.
       * @param {Object} profile - The profile we are checking for eligibility
       * @param {Object[]} policies - array with all the policies selected by the participant
       */
      function checkIfEligible(profile, policies) {
        let eligible = { isValid: true };

        // If any plan fails validation the loop will end.
        policies.every(policy => {
          if (!$scope.standalone || ($scope.standalone && policy.selected)) {
            eligible = window.dnim.protPlans.checkIfEligible(profile, policy);
          }
          return eligible.isValid;
        });

        $scope.planEligible = eligible;
      }

      $scope.$watch('profile.location', (fresh, stale) => {
        if (!fresh || fresh === stale) return;
        checkIfEligible($scope.profile, $scope.displayPolicies);
        $scope.profile.save(['location']);
      });

      $scope.$watch('displayPolicies', function(fresh) {
        checkIfEligible($scope.profile, fresh);
      }, true);

      $scope.onContinue = () => {
        $scope.policies.length = 0;
        if ($scope.insuranceBuy === true) {
          $scope.displayPolicies.filter((policy) => {
            return $scope.standalone ? !!policy.selected : true;
          }).map((policy) => {
            /* Set these two values in case the user backspaces over the amounts */
            policy.airfare = policy.airfare || 0;
            policy.tuition = policy.tuition || 0;
            $scope.policies.push(policy);
          });
        }
      };

      $scope.selectedPoliciesLength = function() {
        if ($scope.standalone) {
          return $scope.displayPolicies.filter((policy) => {
            return !!policy.selected;
          }).length;
        } else {
          return $scope.displayPolicies.length;
        }
      };

      function initDisplayPolicies() {
        return $scope.groups.reduce((thesePolicies, group) => {
          if (!group.waitlistRegistration) {
            const tuition = group.registration.tuition || 0;
            const days = moment(group.properties.finish).diff(moment(group.properties.start), 'days') + 1;

            const policy = {
              registrationID: group.registrationID,
              sessionName: group.name,
              sessionParentName: group.parent ? group.parent.name + ' » ' : '',
              start: group.properties.start,
              finish: group.properties.finish,
              startRef: group.properties.start,
              finishRef: group.properties.finish,
              tuition,
              airfare: 0,
              appliedToGroup: group.id,
              insured: tuition,
              days: days,
            };
            getPlanChoices(policy);
            // if a plan was previously selected, apply the changes made to the plan
            Object.assign(policy, $scope.policies.find(plan => plan.appliedToGroup === policy.appliedToGroup));
            $scope.calculateCost(policy);
            thesePolicies.push(policy);
          }
          return thesePolicies;
        }, []);
      }

      function initPurchasingChoice() {
        return $scope.isTestAccount ? false : $scope.standalone ? true : null;
      }
    }
  };
});
