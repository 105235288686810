angular.module("dn").factory "$exceptionHandler", ->
  # modifying angular's $exceptionHandler service to use swals for prettier error output
  # I THINK error 22 is "QuotaExceededError: Dom exception 22: An attempt was made to add something to storage that exceeded the quota"
  # googling shows this is often a problem with Safari and localstorage when in private browsing
  # only spot we use localstorage is as $rootScope.$storage for storing password attempts on login
  # Dropzone is a brat when loading in the background and doesn't break anything
  # if it can't load, so we're gon ignore dat too
  whitelist = [
    "$rootScope:inprog"
    "$digest"
    "this.options"
    "SyntaxError"
    "Unable to get property"
    "of undefined"
    "is undefined"
    "22"
    "Invalid dropzone element"
  ]

  return (exception, cause) ->
    return if (_.some whitelist, (wl) -> "#{exception}".indexOf(wl) > -1)

    window.swal
      title: "Page Error"
      type: "warning"
      text: """
        The following is an error that occurred on this page.

        #{exception}

        Please refresh and try again. If this error continues
        to occur, please contact our support team with the contents
        of this error message and the URL of this page.
      """

    throw exception
