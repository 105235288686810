
angular.module("dn").directive "uploader", ($timeout) ->
  templateUrl: "directives/uploader/uploader.directive.html"
  restrict: "A"
  replace: true
  scope:
    success: "@"
    post: "@uploader"
    completeFn: "="
    submitting: "="

  link: ($scope, elm, attrs) ->
    # manual dom manipulation/hacky BS to link
    # submit/clear buttons to actual form actions
    $scope.submit = -> $timeout ->
      $scope.submitting = true
      $(elm).submit()

    $scope.clear = ->
      $scope.submitting = false
      $(elm).closest('form').get(0).reset()

  controller: ($scope) ->
    $scope.fileExists = false
    $scope.uploadComplete = (content) ->
      $scope.clear()
      $scope.fileExists = false
      return $scope.completeFn(content) if $scope.completeFn
      swal "File Uploaded", $scope.success or null, "success"

    $scope.fileChanged = (elm) ->
      $scope.fileExists = !!elm.files.length
      $scope.$apply()
