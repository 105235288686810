lib.registerState "manager.healthLog.create",
  url: "/create/{patientID}/{entryID}?linkedVisit"
  params: { patientID: { value: null, squash: true }, entryID: { value: null, squash: true } }
  templateUrl: "states/manager/health-log/create/create.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "New Health Log Entry"

    templates: ($http, $filter, provider) ->
      return [] if !provider
      templates = []

      formatTemplate = (result) ->
        result = _.sortBy result, "name"
        _.each result, (template) ->
          if template.profileID is provider.id
            template.optgroup = "Custom Templates"
            templates.push template
          else if template.shared is true
            template.optgroup = "Shared Templates"
            templates.push template

      retrieveTemplates = () ->
        # Sets variables as unefined
        healthLogPromise
        behavioralPromise

        if $filter('permissionVisible')({ health_log: 'edit' })
          healthLogPromise = $http.get("/api/organizations/#{provider.profile.organizationID}/templates?type=health-log").then (result) ->
            return result.data
        if $filter('permissionVisible')({ behavioral_health: 'edit' })
          behavioralPromise = $http.get("/api/organizations/#{provider.profile.organizationID}/templates?type=behavioral-health").then (result) ->
            return result.data
        return[healthLogPromise, behavioralPromise]

      return Promise.all(retrieveTemplates())
        .then (results) ->
          # filter null in results
          results = _.filter results, (r) -> r
          _.each results, formatTemplate
          return _.sortBy templates, "optgroup"

    complaints: ($http) ->
      $http.get("/api/constants/complaints?sort=true", { cache:true }).then (result) ->
        # sort and filters complaints for behavioral health and sorts all complaints for general health
        behavioralHealthComplaints = _.map (result.data.behavioralHealth).sort(), (v) -> { label: v, value: v }
        generalHealthComplaints = _.map _.keys(result.data.generalHealth).sort(), (v) -> { label: v, value: v }

        return { "behavioral-health": behavioralHealthComplaints, "general-health": generalHealthComplaints }

    nDiag: ($http) ->
      $http.get("/api/constants/nursing-diagnosis", {cache:true}).then (result) ->
        _.map result.data, (v) -> { label: v.name, value: v.name }

    moods: ($http) ->
      $http.get("/api/constants/moods", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    sras: ($http) ->
      $http.get("/api/constants/sras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    affects: ($http) ->
      $http.get("/api/constants/affects", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    cognitiveFunctionings: ($http) ->
      $http.get("/api/constants/cognitiveFunctionings", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    anras: ($http) ->
      $http.get("/api/constants/anras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    bras: ($http) ->
      $http.get("/api/constants/bras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    sibas: ($http) ->
      $http.get("/api/constants/sibas", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    hras: ($http) ->
      $http.get("/api/constants/hras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    profile: ($stateParams, Profile) ->
      if $stateParams.patientID
        new Profile($stateParams.patientID).load().then (profile) ->
          return profile
      else
        return null

    otcChoices: (profile, $filter) ->
      # only show OTC medications if the user has the permission to edit health logs and the profile has OTC medications
      if profile and $filter('permissionVisible')({ health_log: 'edit' })
        _(profile.medications).filter('otcID').map((m) -> { label: m.name, value: m.id }).sortBy('label').value()
      else
        []

    entry: ($http, $stateParams) ->
      return null unless $stateParams.entryID and $stateParams.patientID
      route = "/api/profiles/#{$stateParams.patientID}/log-entries/#{$stateParams.entryID}"
      $http.get(route).then (result) -> result.data

  controller: ($scope, $http, $cacheFactory, $filter, LogEntry, organization, $state, complaints, moods, sras, affects, cognitiveFunctionings, anras, bras, sibas, hras, templates, entry, flash, providers, nDiag, Profile, profile, otcChoices) ->
    $scope.orgID = organization.id
    $scope.timezone = organization.properties.timezone
    $scope.profileChoices = []
    $scope.profile = profile
    $scope.searching = false
    if $scope.profile
      str = "#{profile.familyName}, #{profile.givenName}"
      if profile.dob then str += " (#{moment(profile.dob).format("MM/DD/YYYY")})"
      $scope.profileChoices = [
        { label: str, value: profile.id }
      ]
    deleting = false

    # Allows parent scope to get new entries
    invalidateCache = () -> $cacheFactory.get('$http').removeAll()

    if !$scope.provider?.id
      window.swal
        title: "Provider Account Not Found"
        text: """
          We couldn't find a provider account for you, so we can't save this log entry.
          Please refresh and verify that you are still logged in.
        """
        type: "warning"

    # Template
    $scope.templateMap = _.keyBy templates, "id"

    # Assign entry and initialize
    $scope.entry = new LogEntry { draft: true, groupID: organization.id }
    if entry then $scope.entry = _.assign $scope.entry, entry
    $scope.entry.addendum ?= []
    $scope.entry.providerID ?= $scope.provider?.id
    $scope.entry.logged ?= new Date()

    $scope.inputlabelString = () ->
      if $scope.entry.logType is 'behavioral-health'
        return "Participant"
      else
        return "Patient"
    $scope.inputPlaceholderString = () ->
      if $scope.entry.logType is 'behavioral-health'
        return "Start typing to search for participants"
      else
        return "Start typing to search for patients"

    $scope.healthType = [
      { label: 'General', value: 'general-health' }
      { label: 'Behavioral', value: 'behavioral-health' },
    ]

    $scope.healthTypeModel = $scope.healthType[0].value

    hasBothPermissions = $filter('permissionVisible')({ health_log: 'edit' }) and $filter('permissionVisible')({ behavioral_health: 'edit' })
    if !hasBothPermissions or $scope.entry.id then $scope.disableHealthType = true

    # When creating a linked visit, if the user has both permissions, they should be able to select the type
    # If they are creating a linked visit and don't have both permissions, it will default to the type they have permission for
    if hasBothPermissions and $state.params.linkedVisit
      $scope.disableHealthType = false

    if !$scope.entry.logType
      if hasBothPermissions
        $scope.entry.logType = $scope.healthType[0].value
      else if $filter('permissionVisible')({ behavioral_health: 'edit' })
        $scope.entry.logType = $scope.healthType[1].value
      else if $filter('permissionVisible')({ health_log: 'edit' })
        $scope.entry.logType = $scope.healthType[0].value

    $scope.$watch "entry.sessionTime", (fresh, stale) ->
      sessionTime = $scope.entry.sessionTime
      if sessionTime == '0' or !sessionTime then $scope.entry.sessionTime = null

    # filters templates based on log type
    $scope.$watch "entry.logType", (fresh, stable) ->
      # uses temporary variable to avoid changing the original templates
      tempTemplates = _.filter templates, (template) ->
        if $scope.entry.logType == 'general-health'
          return template.type == 'health-log'
        else
          return template.type == 'behavioral-health'
      # $scope.templates contains the choices for the select dropdown
      $scope.templates = _.map tempTemplates, (t) -> { label: t.name, value: t.id, optgroup: t.optgroup }

    # Sets up encounter type choices
    $scope.encounterTypeChoices = [
      "Individual",
      "Group",
      "Intake",
      "Risk Assessment",
      "Parent/Guardian/Family"
    ]

    # Sets up the factory table for both general-health and behavioral-health when switching between the two
    examModels = null
    $scope.switchFactory = () ->
      $scope.entry.addendum ?= []
      $scope.entry.providerID ?= $scope.provider?.id

      # Link up physical exam or location fields
      if $scope.entry.logType == 'general-health'
        $scope.entry.physicalExam ?= {}

        # If we have a physical exam, link up the fields
        examModels = (_.filter LogEntry.sections.generalHealth, (s) -> s.label is "Physical Exam")[0].subsections
        examModels = _.map (_.flatten(_.map examModels, "models")), "model"

      if $scope.entry.logType == 'general-health'
        initSections = _.cloneDeep LogEntry.sections.generalHealth
        initSectionsSideBar = _.cloneDeep LogEntry.sections.generalHealthSideBar
      else
        initSections = _.cloneDeep LogEntry.sections.behavioralHealth
        initSectionsSideBar = _.cloneDeep LogEntry.sections.behavioralHealthSideBar

      $scope.sections = chunkSections(initSections)
      $scope.sideSectionList = chunkSections(initSectionsSideBar)

    linkExam = ->
      _.map examModels, (model) ->
        return unless $scope.entry[model]?.length
        $scope.entry.physicalExam[model] = $scope.entry[model]


    # Chunks subsections into 2 columns for display in sidebar
    # Also hijacked for filtering out OTC sections
    chunkSections = (template) ->
      _.map template, (section) ->
        # Remove OTC Med input if otc are not enabled
        if !organization.properties.enableOTC && section.label == 'Assessment/Plan'
          section.subsections = _.reject(section.subsections, ['label', 'OTC Medication'])
        section.chunks = _.chunk section.subsections, Math.ceil(section.subsections.length / 2)
        _.map section.chunks, (c, i) ->
          c.chunkIndex = i
          _.last(c).lastSub = true
        section

    $scope.switchFactory()

    # Find inline inputs
    _.map $scope.sections, (s) ->
      _.map s.subsections, (sub) ->
        if (_.some sub.models, (m) -> m.type is "timestamp") then sub.timestamp = true


    $scope.validate = (model, section) ->
      if !$scope.entry.patientID then return model.validate
      if !section.locked then return model.validate
      if model.validate then return "required|#{model.validate}"
      return "required"

    $scope.loadTemplate = ->
      template = $scope.templateMap[$scope.template].template.sections


      if $scope.entry.logType == 'general-health'
        initSectionsSideBar = _.cloneDeep LogEntry.sections.generalHealthSideBar
      else
        initSectionsSideBar = _.cloneDeep LogEntry.sections.behavioralHealthSideBar

      # Inserts a fresh copy of the sideSectionList to reset the 'show' properties to false
      $scope.sideSectionList = chunkSections(initSectionsSideBar)

      # First pre-fill data in fields we set up
      template.forEach (templateSection) ->
        # Clears any value that is no longer selected on a template. ex, chosing a different template that
        # has different pick lists selected
        templateSection.subsections.filter((ss) -> !ss.show).forEach (subsection) ->
          subsection.models.forEach (model) ->
            # If no value exists, clear from entry input
            if !model.value then $scope.entry[model.model] = null

        # Goes into and enables pick list options and inserts values based on the template choosen
        templateSection.subsections.filter((ss) -> ss.show).forEach (subsection) ->
          $scope.sideSectionList.forEach (section) ->
            section.chunks.forEach (chunk) ->
              chunk.forEach (picklistModel) ->
                # Enable the picklist to be enabled on the side section list
                if subsection.label is picklistModel.label then picklistModel.show = true

          # Insert values into the entry input from template
          subsection.models.forEach (model) ->
            if model.value then $scope.entry[model.model] = model.value

      # Check if the template has an encounterType or sessionTime
      if $scope.templateMap[$scope.template]?.template?.encounterType
        $scope.entry.encounterType = $scope.templateMap[$scope.template].template.encounterType

      if $scope.templateMap[$scope.template]?.template?.sessionTime
        $scope.entry.sessionTime = $scope.templateMap[$scope.template].template.sessionTime

      # refresh template view with loaded template
      $scope.sections = chunkSections(template)

    locations = _.map organization.properties.healthLogLocations, (l) -> { label: l, value: l }

    if organization.properties.customDispositions
      dispositions = organization.properties.customDispositions
    else
      dispositions = [
        "Admitted"
        "Ambulance"
        "Discharged"
        "Emergency Department"
        "Home"
        "Observed"
        "Urgent Care"
      ]

    $scope.choices =
      location:          locations
      complaint:         complaints[$scope.entry.logType]
      providerID:        providers.map((p) -> { label: "#{p.familyName}, #{p.givenName}", value: p.id })
      medicationID:      otcChoices # loaded async after profile load
      temperatureUnit:   _.map ["Fahrenheit", "Celsius"], (v) -> { label:v, value:v }
      oxygenSaturation: (_.map _.range(1, 101), (v) -> { label: "#{v}%", value: v }).reverse()
      pain:             _.map _.range(0, 11), (v) -> { label: v, value: v }
      bloodGlucose:     _.map _.range(0, 1000), (v) -> { label: v, value: v }
      heartRate:        _.map _.range(0, 400), (v) -> { label: v, value: v }
      respiratoryRate:  _.map _.range(0, 100), (v) -> { label: v, value: v }
      disposition:      _.map dispositions, (d) -> { label: d, value: d }
      systolic:         _.map _.range(0, 250), (v) -> { label: v, value: v }
      diastolic:        _.map _.range(0, 250), (v) -> { label: v, value: v }
      nursingDiagnosis: nDiag
      mood:             moods
      sra:              sras
      affect:            affects
      cognitiveFunctioning: cognitiveFunctionings
      anra:             anras
      bra:              bras
      siba:             sibas
      hra:              hras


    $scope.notifyChoices = [
      { label: "Do NOT Notify", value: "false" }
      { label: "Notify Authorized Users", value: "true" }
    ]
    $scope.entry.notify = "false"

    # Before applying draft to entry, we need to format the physical exam fields first
    for value of $scope.entry.physicalExam
      $scope.entry[value] = $scope.entry.physicalExam[value]

    # Enables active picklist options and applies picklist draft values to the entry
    $scope.sections.forEach (section) ->
      section.subsections.forEach (subsection) ->
        model = subsection.models[0].model
        if (typeof $scope.entry[model] is "string" or typeof $scope.entry[model] is "number") and model
          subsection.show = true
          # need to manually set the value for diagnosis since the ajax call is async
          if model == 'diagnosis' and $scope.entry[model]
            $scope.choices[model] = [$scope.entry[model]]
        if typeof $scope.entry[model] is "object" and $scope.entry[model]?.length
          $scope.entry[model] = $scope.entry[model].join(",")
          subsection.show = true
        $scope.sideSectionList.forEach (sidebarSection) ->
          sidebarSection.chunks.forEach (chunk) ->
            chunk.forEach (picklistModel) ->
              if picklistModel.label is subsection.label
                picklistModel.show = subsection.show

    $scope.show = (subsection) ->
      return subsection.show = true if subsection.locked
      subsection.show = !subsection.show
      $scope.sections.forEach (section) ->
        section.subsections.forEach (chunk) ->
          if subsection.label is chunk.label
            chunk.show = !chunk.show
      # clear out the value if the subsection is hidden
      if !subsection.show
        _.map subsection.models, (model) ->
          $scope.entry[model.model] = null

    $scope.ready = ->
      return false unless checkTemp()
      if $scope.entry.logType == 'general-health'
        check = ["patientID", "details", "assessment", "treatment", "complaint", "disposition", "logType"]
      else
        check = ["patientID", "details", "assessment", "treatment", "complaint", "logType", "sessionTime", "encounterType"]

      return false unless $scope.activeInLogDate
      return false if $scope.future
      _.every check, (item) -> $scope.entry[item]

    $scope.modelName  = (str) -> _.startCase str
    $scope.placeholders = { diagnosis: "Begin typing to search" }
    $scope.any = (section) -> _.some section.subsections, (s) -> s.show
    $scope.onUpload = (content) -> $scope.entry.file = content.key
    # Display medkit icon if user has proper conditions and permission
    $scope.showMedkit = () ->
      return true if $scope.entry.patientID and $scope.profile and $filter('permissionVisible')({ health_log: 'edit' })

    $scope.noMeta = ->
      do linkExam
      _.omit $scope.entry, ["created", "updated", "deactivated"]

    $scope.activeInLogDate = null
    $scope.future = false
    checkDates = ->
      return unless $scope.registrations
      $scope.activeInLogDate = _.some $scope.registrations, (r) ->
        logDate = moment $scope.entry.logged
        start   = r.start
        finish  = r.finish

        # If group doesn't have a start or end date, allow entries with log date
        # equal to today
        return true if (!start or !finish) and logDate.isSame(moment(), "day")
        between = logDate.isBetween start, finish
        same    = logDate.isSame(start, "day") or logDate.isSame(finish, "day")
        # Preventing health log entries in the future
        $scope.future = logDate.isAfter moment()

        between or same

    # Checking for valid patient/date combination
    $scope.$watch $scope.noMeta, (fresh, stale) ->
      return unless fresh
      return if _.isEqual(fresh, stale)
      return if !$filter('permissionVisible')({ health_log: 'edit' }) and !$filter('permissionVisible')({ behavioral_health: 'edit' })

      # We need to check if they're registered to the logged date
      if fresh.patientID and !$scope.registrations and $scope.searching == false
        $scope.searching = true
        new Profile(fresh.patientID).load().then (profile) ->
          $scope.profile = profile
          $scope.choices.medicationID = _($scope.profile.medications).filter('otcID').map((m) -> { label: m.name, value: m.id }).sortBy('label').value()

        $http.get("/api/profiles/#{fresh.patientID}/registrations?area=healthlog").then (result) ->
          $scope.registrations = result.data
          checkDates()

      if fresh.logged isnt stale.logged then checkDates()

      stringEntry = clearEntry(fresh, stale)

      $scope.saveDraft(fresh, stale, stringEntry)
    , true

    $scope.deleteEntry = ->
      return unless $scope.entry.id
      deleting = true
      $scope.entry.addendum = JSON.stringify($scope.entry.addendum)
      new LogEntry($scope.entry).destroy().then ->
        flash "Entry deleted"
        invalidateCache()
        $state.go "manager.healthLog.browse", undefined, reload: true

    # Trigger confirmation for deletion
    $scope.confirmDelete = ->
      window.swal
        title: "Really delete this entry?"
        text: """
          This will remove the entry from all reports and lists.
        """
        type: "warning"
        showCancelButton: true
        confirmButtonColor: "#DF5348"
        confirmButtonText: "Delete Entry"
      , ->
        $scope.deleteEntry()

    # Used to avoid query errors when saving
    integerFields = ["heartRate", "respiratoryRate", "oxygenSaturation", "bloodGlucose", "pain", "systolic", "diastolic"]

    checkTemp = ->
      if $scope.entry.temperatureValue then return false unless $scope.entry.temperatureUnit
      if $scope.entry.temperatureUnit then return false unless $scope.entry.temperatureValue
      return true

    clearEntry = (fresh, stale) ->
      # Keep required props for draft
      logEntryOpbject = {
        draft: true,
        groupID: organization.id,
        logType: $scope.entry.logType,
        patientID: $scope.entry.patientID,
        providerID: $scope.entry.providerID,
        logged: $scope.entry.logged,
        notify: $scope.entry.notify,
        addendum: $scope.entry.addendum
        file: null # Manually clear file on switch
      }

      $scope.choices.complaint = complaints[$scope.entry.logType]

      # If the entry has an ID and the log type is changed, keep the ID
      # Else if the log type is the same, keep the entry
      if $scope.entry.id and fresh.logType != stale.logType
        logEntryOpbject.id = $scope.entry.id
      else if fresh.logType is stale.logType
        return stringEntry = _.cloneDeep $scope.entry

      # Resets the entry if the log type is changed
      $scope.entry = new LogEntry logEntryOpbject
      stringEntry = _.cloneDeep $scope.entry
      for field of fresh
        if fresh[field] != stringEntry[field]
          stringEntry[field] = null;
          $scope.entry[field] = null;

      return stringEntry

    # Iterating through keys(picklist options) of stringEntry and splits their values.
    # Assigning the value to null if the array is empty
    formatBehavioralOptions = (stringEntry) ->
      keys = ['mood', 'affect', 'cognitiveFunctioning', 'anra', 'bra', 'siba']
      keys.forEach (key) ->
        if typeof stringEntry?[key] is 'string'
          if stringEntry[key] then stringEntry[key] = stringEntry[key].split(",")
          else
            stringEntry[key] = null
      return stringEntry

    # Autosaving drafts
    $scope.saveDraft = _.debounce (fresh, stale, stringEntry) ->
      return unless $scope.entry.patientID and $scope.entry.providerID and !deleting
      return if $scope.savingFull

      stringEntry = formatBehavioralOptions(stringEntry)

      stringEntry.addendum = JSON.stringify stringEntry.addendum
      stringEntry.notify = _.poserBoolean stringEntry.notify

      # if any of these integer fields are added and then backspace'd, the value will be an empty string and `invalid input syntax for integer: "NaN"` will be thrown from the save
      _.map integerFields, (field) ->
        if !stringEntry[field] then stringEntry[field] = null

      if stringEntry.type == 'general-health'
        stringEntry.sessionTime = null
      new LogEntry(stringEntry).save().then (entry) ->
        invalidateCache()
        $scope.entry.id ?= entry.id
        $scope.entry.updated = entry.updated
    , 2000

    # Save full entry
    $scope.save = (addAnother) ->
      return unless $scope.ready()
      $scope.savingFull = true
      $scope.entry.draft = false
      stringEntry = _.cloneDeep $scope.entry
      stringEntry.addendum = JSON.stringify stringEntry.addendum
      stringEntry.notify = _.poserBoolean stringEntry.notify

      stringEntry = formatBehavioralOptions(stringEntry)

      # if any of these integer fields are added and then backspace'd, the value will be an empty string and `invalid input syntax for integer: "NaN"` will be thrown from the save
      _.map integerFields, (field) ->
        if !stringEntry[field] then stringEntry[field] = null

      new LogEntry(stringEntry).save().then (entry) ->
        flash "Entry saved"
        if !addAnother
          invalidateCache()
          return $state.go "manager.healthLog", {}, {reload: true}
        $scope.entry = {}
        return $state.go "manager.healthLog.create", {orgID: $state.params.orgID}, { inherit: false, reload: true }


    $scope.healthLogForm = "//statics.campdoc.com/#{organization.properties.division || 'CampDoc'}-Health-Log-Template.pdf"
