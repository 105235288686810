angular.module("dn").directive("multiRegistration", function() {
  return {
    templateUrl: "directives/multi-registration/multi-registration.directive.html",
    restrict: "E",
    scope: {
      profile: "=",
      organization: "=",
      session: "=",
      show: "="
    },

    controller: function($http, $rootScope, $scope, $state, Group, ProfileRegData) {
      $scope.patientType = $rootScope &&
                           $rootScope.division &&
                           $rootScope.division.patient &&
                           $rootScope.division.patient.singular ?
                           $rootScope.division.patient.singular : 'Patient';
      $scope.regTypeChoices = [
        { label: $scope.patientType.capitalize(), value: "patient" },
        { label: "Provider", value: "provider" }
      ]
      $scope.regTypeChoice = "patient";

      $scope.phaseChoices = [
        { label: "Upcoming", value: "future" },
        { label: "Current", value: "present" },
        { label: "Past", value: "past" }
      ]
      $scope.phaseChoice = "future";
      $scope.capacityData = {};
      $scope.requiredAddOnData = {};
      $scope.processing = [];

      var flatGroups = [];

      function flattenator(groups, groupArray) {
        _.map(groups, function(group) {
          if (!group.chain) group.chain = [];
          if (group.classification !== "organization") group.chain.push(group.name);
          /* Value and label are needed for the dropdown */
          group.value = group.id;
          group.label = group.chain.join(" » ");
          if (group.classification === "organization") group.label = group.name; /* docnetwork/issues#1155 */
          groupArray.push( _.pick(group, ["id", "name", "phase", "registration", "chain", "value", "label"]) );
          if (!group.children || !group.children.length) return;
          _.map(_.sortBy(group.children, "name"), function(child) {
            child.chain = _.clone(group.chain);
          });
          flattenator(group.children, groupArray);
        });
      }

      flattenator([$scope.organization], flatGroups);

      /* Update groups in the Tags input, reset selected groups anytime phase or type changes */
      $scope.filterPhaseGroups = function() {
        $scope.groupIDs = null;
        $scope.regGroups = [];
        $scope.phaseGroups = _.filter(flatGroups, function(group) {
          var phaseMatch = group.phase === $scope.phaseChoice;
          var registered = (ProfileRegData.regByGroupId(group.id) || {}).type === $scope.regTypeChoice;
          var hasAccess  = $scope.session.oper || _.includes($scope.session.groups, parseInt(group.value));
          return phaseMatch && !registered && hasAccess;
        });

        $scope.inputPlaceholder = $scope.phaseGroups.length ? "Select from " + $scope.phaseGroups.length + " Groups" : "No Available Groups";
      }
      $scope.filterPhaseGroups();

      /*
        regGroups is for the table display and passing final data to the save
        There's a fair amount of duplciated logic here, but I think due to how this function will
          be called synchronously with a potentially async result (first time per group), orgGroup
          needs to be calculated more than once. When trying to condense it by setting a regGroup
          object that I add to after receiving capacity and addOn data, the display in the regGroups
          table broke in a weird way.
      */
      function addToRegGroups(groupID) {
        if (_.includes($scope.processing, groupID)) return;
        var orgGroup = _.find(flatGroups, { value: groupID });
        if ($scope.capacityData[groupID]) {
          $scope.regGroups.push({
            id: groupID,
            name: orgGroup.name,
            nameChain: orgGroup.label,
            waitlisted: null,
            regEnabled: orgGroup.registration.enabled || false,
            addTuition: $scope.organization.shortName && $scope.regTypeChoice === "patient" && orgGroup.registration.enabled,
            addAddOns: !!$scope.requiredAddOnData[groupID] && !!$scope.requiredAddOnData[groupID].length && $scope.regTypeChoice === "patient",
            tuition: orgGroup.registration.tuition || 0,
            stats: {
              overCapacity: $scope.organization.shortName &&
                            $scope.capacityData[groupID] &&
                            $scope.capacityData[groupID].totalCapacity > 0 &&
                            $scope.capacityData[groupID].occupancy + 1 > $scope.capacityData[groupID].totalCapacity,
              noTuition: $scope.organization.shortName &&
                         !orgGroup.registration.tuition &&
                         $scope.regTypeChoice === "patient",
              hasRequiredAddOns: !!$scope.requiredAddOnData[groupID] && !!$scope.requiredAddOnData[groupID].length
            }
          });
        } else {
          $scope.processing.push(groupID);
          new Group({ id: groupID }).getCapacity(function(capacityData) {
            /*
              The tags input will remove a selected object if anything about that object changes
              Because of that, can't attach capacity data directly to the orgGroup, so we do this instead
            */
            $scope.capacityData[groupID] = {
              occupancy: capacityData.current ? capacityData.current.total || 0 : 0,
              totalCapacity: calculateCapacity(orgGroup.registration)
            }
            new Group({ id: groupID }).getAddOns({ all: true }, function(groupAddOns) {
              /* Half-baked feature idea: ignore some required addOns because it's just more convenient that way */
              var requiredAddOns = _.filter(groupAddOns, function(addOn) {
                var childAddOn = _.find(groupAddOns, { parentID: addOn.id });
                return addOn.required && !addOn.deactivated && !childAddOn;
              });
              $scope.requiredAddOnData[groupID] = requiredAddOns;
              var orgGroup = _.find(flatGroups, { value: groupID });
              $scope.regGroups.push({
                id: groupID,
                name: orgGroup.name,
                nameChain: orgGroup.label,
                waitlisted: null,
                regEnabled: orgGroup.registration.enabled || false,
                addTuition: $scope.organization.shortName && $scope.regTypeChoice === "patient" && orgGroup.registration.enabled,
                addAddOns: !!$scope.requiredAddOnData[groupID] && !!$scope.requiredAddOnData[groupID].length && $scope.regTypeChoice === "patient",
                tuition: orgGroup.registration.tuition || 0,
                stats: {
                  overCapacity: $scope.organization.shortName &&
                                $scope.capacityData[groupID] &&
                                $scope.capacityData[groupID].totalCapacity > 0 &&
                                $scope.capacityData[groupID].occupancy + 1 > $scope.capacityData[groupID].totalCapacity,
                  noTuition: $scope.organization.shortName &&
                             !orgGroup.registration.tuition &&
                             $scope.regTypeChoice === "patient",
                  hasRequiredAddOns: !!$scope.requiredAddOnData[groupID] && !!$scope.requiredAddOnData[groupID].length
                }
              });
              _.remove($scope.processing, function(n) { return n === groupID; });
            });
          });
        }
      }

      /*
        Completely ripped from manager/groups/registration/registration.state.js
        Maybe turn this into a window.lib function at some point
        Known formats for registration.capacity are: 150, "150", {"female": 75, "male": "75"}, {"female": "150"}, null
      */
      function calculateCapacity(registration) {
        registration = registration.capacity;
        if (!registration) return null;
        if (typeof registration === "string") return parseInt(registration);
        if (typeof registration === "object") {
          var total = 0;
          if (registration.male) total += parseInt(registration.male);
          if (registration.female) total += parseInt(registration.female);
          return total;
        }
        return registration;
      }

      $scope.checkGroups = function() {
        /*
          Performance-wise, the delete-all-and-rebuild approach went toe-to-toe with the more conservative method
            (check if we're adding or removing, then go through all of the groupIDs to find the new one to add)
          I chose this approach because it's easy to read and as performant as other methods when manipulating 10 groups
        */
        $scope.regGroups = [];
        if (!$scope.groupIDs) return;
        /* 'Tags' inputs return a comma-separated value string like "1234, 1235, 1236". Split, coerce to ints, compact to get rid of NaNs */
        var groupIDs = _($scope.groupIDs.split(",")).map(function(gID) { return parseInt(gID); }).compact().value();

        _.map(groupIDs, function(gID) {
          addToRegGroups(gID);
        });
      };

      /**
       * Create a nested registration object containing group data along
       * with tuition and add-on crebits.
       * @param {Object} group - Data from $scope.regGroups
       */
      function nestedReg(group) {
        const nestedReg = {
          groupID: group.id,
          groupName: group.name,
          type: $scope.regTypeChoice,
          waitlisted: group.waitlisted || undefined,
        };

        const providerReg = nestedReg.type === 'provider';
        if (group.addTuition && !providerReg) {
          nestedReg.tuition = group.tuition;
        }

        if (group.addAddOns && !providerReg) {
          const requiredAddOns = $scope.requiredAddOnData[group.id];
          const hasRequiredAddOns = requiredAddOns && requiredAddOns.length;

          if (hasRequiredAddOns) {
            nestedReg.addOns = requiredAddOns.map((addOn) => {
              return { id: addOn.id, amount: addOn.price, name: addOn.name };
            });
          }
        }

        return nestedReg;
      }

      /* Do the actual registering, create tuition and add-on crebits if applicable */
      $scope.registerGroups = function() {
        $scope.registering = true;

        const body = {
          profileID: $scope.profile.id,
          registrations: $scope.regGroups.map(nestedReg),
        };

        $http.post(`/api/profiles/${$scope.profile.id}/multi-reg`, body)
          .then(() => {
            flash('Registrations saved');
            return $state.reload();
          })
          .catch((err) => {
            let errMessage;
            if (err.data && typeof err.data === 'string') {
              errMessage = err.data;
            } else {
              errMessage = 'An unknown error occurred.';
            }

            window.swal({
              type: 'error',
              title: 'Error',
              text: errMessage + '\n\nPlease reload and try again.',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: false,
              confirmButtonText: 'Reload'
            }, function() {
              return $state.reload();
            });
          });
      };

      $scope.profile.registrations = $scope.profile.registrations || [];

      const watch = $scope.$watch("profile.registrations.length", (fresh) => {
        if (fresh && fresh > 0) {
          $scope.filterPhaseGroups();
          // Deregister the watcher since we only need it once.
          return watch();
        }
      });
    } /* end controller */
  }; /* end return statement */
}); /* end directive */
