angular.module('dn').directive('app', function () {
  return {
    templateUrl: 'directives/app/app.directive.html',
    restrict: 'E',
    controller($rootScope) {
      let hideTimeout = null;

      let resolveProgress = null;

      $rootScope.showProgress = () => {
        if (!resolveProgress) resolveProgress = $('.resolve-progress');
        clearTimeout(hideTimeout);
        resolveProgress.removeClass('transparent');
        resolveProgress.show();
      };

      $rootScope.hideProgress = () => {
        if (!resolveProgress) resolveProgress = $('.resolve-progress');
        resolveProgress.addClass('transparent');
        hideTimeout = setTimeout(() => resolveProgress.hide(), 250);
      };

      $rootScope.$on('$stateChangeStart', () => $rootScope.showProgress());

      $rootScope.$on('$stateChangeError', (event, tState, tParams, fState, fParams, err) => {
        $rootScope.hideProgress();
        console.log('Failed state change from', fState.name, fParams, 'to', tState.name, tParams, err);
      });

      $rootScope.$on('$stateChangeSuccess', () => {
        $rootScope.hideProgress();
        setTimeout(() => {
          $('.init').removeClass('init');
          $('.init-show').addClass('hide');
        });
      });
    }
  };
});
