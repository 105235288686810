lib.registerState "patient.profile",
  url: "/profiles/:profileID"
  templateUrl: "states/patient/profile/profile.state.html"
  params:
    campgramsRedirect: false
  resolve:
    title: ($rootScope, profile) ->
      $rootScope._title = profile.givenName + " " + profile.familyName
      return

    profile: ($http, Profile, $stateParams) ->
      join = [
        "crebits"
        "allergies"
        "medications"
        "dimensions"
        "immunizations"
        "emergencyContacts"
        "answers"
        "insurances"
        "primaryProviders"
        "registrations"
      ]
      route = "/api/profiles/#{$stateParams.profileID}?modern&regenerate&area=patientPortal&include=#{join.join("|")}"
      $http.get(route).then (result) ->

        profile = new Profile(result.data)
        profile.has.medications ?= {}

        return profile

    users: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/users").then (result) ->
        result.data

    user: (User, session) ->
      # this is used to check if the user has all required info (name, phone, address)
      # session.user could not be used in the window.lib check because session.user does
      #  not actually update when you hit /session?reaccess
      new User(session.userID).load().then (user) -> user

    campgramsOnly: ($rootScope, $http, profilesUsers, profile) ->
      # check if profileUser is campgrams only, disable photo reminders
      profileUser = _.find(profilesUsers, { profileID: profile.id })
      if profileUser?.campgramsOnly then $http.post('/api/users/tempSkip')
      return profileUser?.campgramsOnly

    invited: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/invited").then (result) -> result.data

    registrations: ($http, profile, organization) ->
      # regs are already on the profile from a few resolves up
      # we need to attach groups to the regs for protPlan use at the bottom of the file
      # flatten the org structure and iterate through to attach groups and group parents to these regs
      # and I think it matters that we still assign this to profile.registrations
      #   instead of simply returning the reduce
      flatOrg = window.dnim.flattenator([organization], 'children', null, (group) ->
        group.children = group.children.map (child) ->
          child.parent = _.pick(group, ['id','name','parentID','parents','registration','shortName','properties','phase','identifier'])
          child
      )
      profile.registrations = profile.registrations.reduce (regs, reg) ->
        if !reg.deactivated
          reg.group = flatOrg.find (g) -> g.id == reg.groupID
          regs.push reg
        regs
      , []
      return profile.registrations

    insured: ($http, profile, registrations) ->
      return [] unless registrations.length && registrations.length > 0
      $http.get("/api/profiles/#{profile.id}/protection-plans").then (result) ->
        registered = _.cloneDeep registrations
        registered = _.map registered, (reg) ->
          reg.group.regID = reg.id
          reg.group.selected = true
          reg.group.start = reg.group.properties.start
          reg.group.finish = reg.group.properties.finish
          reg.group.onWaitlist = reg.waitlisted
          reg.group

        registered = _.reject registered, (r) ->
          r.phase isnt "future" or r.onWaitlist

        if registered.length
          profile.registered = if registered then registered else []

        profile.insured = []
        if result.data
          # Only return plans that match IDs in registered
          # Same filtering as before, but adapted to the new API route
          profile.insured = result.data.filter (plan) ->
            matchedReg = registered.find (reg) ->
              reg.regID == plan.registrationID
            if matchedReg
              true
            else
              false

    paymentPlan: (profile, $http) ->
      $http.get("/api/profiles/#{profile.id}/payment-plan").then (result) ->
        result.data

  controller: ($http, $rootScope, $scope, $state, $stateParams, $timeout, GroupBranding, campgramsOnly, invited, paymentPlan, profile, session, user, users) ->
    # Prescreening flag
    $scope.featureFlags = $rootScope.featureFlags

    # set permissions for providers to access specific patient's profile
    sessionOrg = (session?.access?.organizations?.find (org) -> org.id == profile.organizationID) || {}
    # check for both patients with registrations AND orphans
    accessiblePatients = (sessionOrg.patients || []).concat(sessionOrg.orphans || [])
    $rootScope.hasPatientAccess = accessiblePatients?.some (patient) -> parseInt(patient.id) == parseInt($stateParams.profileID)

    $scope.campgramsOnly = campgramsOnly
    # always redir campgramsOnly users to the campgrams state (unless already there)
    if campgramsOnly && $state.current.name != "patient.profile.funkNotes"
      $state.go("patient.profile.funkNotes")

    profile.insuranceOnly = false

    $scope.profile = profile
    $scope.users   = users
    $scope.insured = profile.insured
    $scope.invited = invited
    $scope.organization.properties.requireProfile = $scope.organization.properties.requireProfile or {}
    $scope.profile.photoMissing = $scope.organization.properties.requireProfile.avatar and (!$scope.profile.avatar or !_.values($scope.profile.avatar).length)
    $scope.testAccount = _.some($scope.users, (u) -> return _.includes(u.options, '"testAccount"=>"true"'))
    $scope.paymentPlan = paymentPlan

    $scope.profile.infoMissing = do ->
      fields = [
        !$scope.profile.givenName
        !$scope.profile.familyName
        !$scope.profile.dob
      ]
      if !$scope.organization.properties.sexOptional
        fields.push !$scope.profile.sex
      _.some fields

    $scope.knobOptions =
      width: 80
      height: 80
      fgColor: "#6E991F"
      inputColor: "#6E991F"
      readOnly: true
      format: (text) -> "#{text}%"

    $scope.completeness = 0
    # someone complained that profiles with no current/upcoming registrations
    # were still showing as 100% complete if they were a returning camper. Here you go.
    patientRegs = _.filter profile.registrations, (reg) ->
      reg.type is "patient"
    $scope.allPast = _.every patientRegs, { "group": {"phase": "past"} }
    if $scope.allPast
      $scope.completeness = 0
    else
      async.eachSeries _.range(0, profile.completeness or 0), (percent, fn) ->
        $scope.completeness = percent + 1
        $timeout fn, 10

    $scope.hpName = $scope.organization.properties.portals?.patient?.sections?.questionnaire?.alias or
      $scope.organization.properties.branding?.text?.healthProfileAlternateName or
      "Health Profile"
    $scope.prescreeningAlias = GroupBranding.aliases.prescreening;
    $scope.prescreeningDescription = GroupBranding.customText.prescreeningDescription;

    $scope.profile.answers = _.keyBy($scope.profile.answers, "questionID")
    $scope.childState = -> $state.current.name != "patient.profile"

    $scope.addDialog = $state.params.campgramsRedirect
    $scope.campgramsOnlyUser = $state.params.campgramsRedirect

    resetForm = ->
      $scope.addDialog = false
      $scope.email = ""
      $scope.campgramsOnlyUser = false

    $scope.addUser = ->
      $scope.adding = true
      return unless $scope.email
      email = $scope.email.toLowerCase().trim()
      return resetForm() if _.includes(_.map(invited, "inviteEmail"), email)

      profileUser =
        inviteEmail: email
        profileID: $scope.profile.id
        forceWelcome: true

      if $scope.campgramsOnlyUser then profileUser.campgramsOnly = true

      $http.post("/api/profiles/#{$scope.profile.id}/users", profileUser).then (result) ->
        if result.data is "Deactivated User"
          $scope.adding = false
          return window.swal
            title: "Deactivated Email"
            type: "warning"
            text: "The email address entered has been deactivated and cannot be added."
        resetForm()
        $scope.invited.push result.data
        flash "Email sent to #{profileUser.inviteEmail}"
        $scope.adding = false
      .catch (err) ->
        flash "Unable to add user"
        return $scope.adding = false

    # Due/Lockout
    dueDates = []
    lockoutDates = []

    # Displaying balance
    $scope.crebits = _($scope.profile.crebits)
      .filter({ ledger: 'organization' })
      .sortBy('created')
      .value().reverse()

    balance = ->
      _.reduce $scope.crebits, (total, crebit) ->
        total += parseInt(crebit.amount, 10)
        return total
      , 0

    $scope.balance = balance()/100

    # Payment plan warning
    $scope.remainder = Math.round(
      window.dnim.calculateRemainder($scope.paymentPlan.totalAmount, $scope.paymentPlan.remaining)
    )
    $scope.paymentPlanDiff = ($scope.balance) - ($scope.paymentPlan.totalAmount / 100)

    # Pull from registrations and profile
    dueDates = _.compact(_.map profile.registrations, (r) ->
      if r.deactivated then return null else return r.group.properties.due)

    if profile.properties.extensionDate?
      extensionDate = profile.properties.extensionDate
    else
      lockoutDates = _.compact(_.map profile.registrations, (r) ->
        if r.deactivated then return null else return r.group.properties.lockout)

    # Split for [beforeOrToday, afterToday]
    if dueDates is []
      splitDue = null
    else
      splitDue = _.partition dueDates.sort(), (d) ->
        moment(d).isBefore(moment()) or moment(d).isSame(moment(), "day")

    if lockoutDates is []
      splitLockout = null
    else
      splitLockout = _.partition lockoutDates.sort(), (d) ->
        moment(d).isBefore(moment()) or moment(d).isSame(moment(), "day")

    # Grab earliest future date or latest past date if no future dates
    if splitDue[1].length
      $scope.nextDueRaw = moment(_.head(splitDue[1].sort()))
      $scope.nextDue = moment(_.head(splitDue[1].sort())).format "MMMM D, YYYY"
    else
      $scope.nextDueRaw = moment(_.last(splitDue[0].sort()))
      $scope.nextDue = moment(_.last(splitDue[0].sort())).format "MMMM D, YYYY"

    if extensionDate
      $scope.nextLockoutRaw = moment(extensionDate)
      $scope.nextLockout = moment(extensionDate).format "MMMM D, YYYY"
    else if splitLockout[1].length
      $scope.nextLockoutRaw = moment(_.head(splitLockout[1].sort()))
      $scope.nextLockout = moment(_.head(splitLockout[1].sort())).format "MMMM D, YYYY"
    else
      $scope.nextLockoutRaw = moment(_.last(splitLockout[0].sort()))
      $scope.nextLockout = moment(_.last(splitLockout[0].sort())).format "MMMM D, YYYY"

    $scope.lockedOut = $scope.nextLockoutRaw.format("YYYY-MM-DD") <= new Date().toISOString().slice(0,10)

    registered = _.cloneDeep profile.registrations
    registered = _.map registered, (reg) ->
      reg.group.regID = reg.id
      reg.group.selected = true
      reg.group.start = reg.group.properties.start
      reg.group.finish = reg.group.properties.finish
      reg.group.onWaitlist = reg.waitlisted
      reg.group

    registered = _.reject registered, (r) ->
      r.phase isnt "future" or r.onWaitlist

    if registered
      $scope.registered = registered

      profile.registered = _.map registered, (group) ->
        group.parent = group.parent.name
        group

    # throw this down here because we're going to see a flash of the state no matter what
    #  and when this is at the top, that state is a giant completeness knob with NaN in it
    #  so I give you the lesser of two evils
    if window.lib.missingUserFields(user)
      return $state.go "home.account.information", { userID: user.id }
