angular.module("dn").directive "qbMedications", ->
  replace: true
  restrict: "A"
  templateUrl: "directives/builder/modules/medications/qb-medications.directive.html"
  scope:
    module: "=qbMedications"
    mode: "@"
    group: "="
  controller: ($scope) ->
    $scope.module.label ?= "Medications"
    $scope.module.required = true
    $scope.module.metadata ?= {}

    $scope.times = [
      "Overnight", "Early Morning", "Breakfast", "Late Morning",
      "Lunch", "Afternoon", "Dinner", "Evening", "Bedtime"
    ]

    if not $scope.module.metadata.times
      $scope.module.metadata.times =
        "Breakfast": true
        "Lunch": true
        "Dinner": true
        "Bedtime": true
