lib.registerState('oper.buttons', {
  url: '/buttons',
  resolve: {
    title: function ($rootScope) {
      $rootScope._title = 'Oper Buttons';
    }
  },
  templateUrl: 'states/oper/buttons/buttons.state.html',
  controller: function ($http, $scope) { }
});
