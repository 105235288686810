angular.module('dn').directive('prescreeningScreeningCard', function() {
  return {
    templateUrl: 'directives/prescreening/screening-card/screening-card.directive.html',
    restrict: 'E',
    scope: {
      screening: '=',
      config: '='
    },
    controller($scope) {
      $scope.expanded = false;
      // Set up object for consumption by formatter
      const screeningFormatterConfig = Object.assign({ record: $scope.screening }, $scope.config);
      // Generate the display format for the card
      $scope.display = new window.dnim.ScreeningFormatter(screeningFormatterConfig).getCardFormat();

      /**
       * used to use this comparison on the html
       * @param {*} can be any value passed down to do the comparison
       */
      $scope.isArray = value => Array.isArray(value);
    }
  };
});
