lib.registerState('manager.attendance.list.profiles.profile.audit', {
  url: '/audit',
  templateUrl: 'states/manager/attendance/profiles/profile/audit/audit.state.html',
  controller: function ($scope, $state, $http, AttendanceData, session, provider) {
    'use strict';

    $scope.auditUser = session.user;
    $scope.auditUser.providerID = (provider || {}).id || null;
    $scope.data = AttendanceData;
    $scope.orgID = $state.params.orgID;
    $scope.profileID = $state.params.profileID;
    AttendanceData.setUiEntry('showEditor', false);
    AttendanceData.setProfileSelected($scope.profileID);

    // Button configuration: save stuff for later, set current configurations
    AttendanceData.setUiEntry('buttons.back.text', 'Back to List');
    const backButtonWhenViewing = _.cloneDeep(AttendanceData.ui.buttons.back);
    const backButtonWhenEditing = {
      text: 'Cancel',
      action() {
        AttendanceData.setUiEntry('showEditor', false);
        AttendanceData.setUiEntry('buttons', {
          back: backButtonWhenViewing,
          next: nextButtonWhenViewing
        });
      }
    };
    const nextButtonWhenViewing = {
      text: 'Create New Timeline',
      action() {
        const newRecord = { profile_id: $scope.profileID };
        $scope.showEditor(newRecord, 0);
      }
    };
    const nextButtonWhenEditing = {
      text: 'Save Changes'
    };
    AttendanceData.setUiEntry('buttons.next', nextButtonWhenViewing);

    // This is the interactionFn that will trigger when we tap on a timeline event
    // It's also fired when creating new records
    $scope.showEditor = function(record, index) {
      if (Object.keys(record).length > 1) {
        return $http.get(`/api/organizations/${$scope.orgID}/profiles/${$scope.profileID}/attendance-records/${record.id}`)
          .then(recordAndNotes => {
            setUiEntry();
            const record = AttendanceData.upsertAttendanceRecord(recordAndNotes.data.record);
            const notes = recordAndNotes.data.notes.map((note) => AttendanceData.upsertNote(note));
            $scope.selectedRecord = { recordAndNotes: { record, notes }, index };
          })
          .catch(err => console.error(err));
      } else {
        setUiEntry();
        $scope.selectedRecord = { recordAndNotes: { record }, index };
      }
    };

    // setUiEntry exists because we need to redeclare this inside the .then after we make our API call or on first load
    // selectedRecord will be undefined. For new records it works fine.
    function setUiEntry() {
      AttendanceData.setUiEntry('showEditor', true);
      AttendanceData.setUiEntry('buttons', {
        next: nextButtonWhenEditing,
        back: backButtonWhenEditing
      });
    }
  }
});
