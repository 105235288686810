angular.module('dn').directive 'qbUpload', ->
  templateUrl: "directives/builder/upload/qb-upload.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbUpload"
    mode: "@"
  controller: ($scope, $stateParams, Images) ->
    groupID = $stateParams.groupID
    $scope.existingFiles = []

    if $scope.question?.metadata?.template
      Images.getForGroup(groupID, $scope.question.metadata.template).then (tpl) ->
        $scope.existingFiles = tpl

    $scope.action = -> "/api/groups/#{groupID}/upload-template"

    $scope.uploadComplete = (content) ->
      if not _.isPlainObject content
        $scope.uploadError = true
      else
        $scope.uploadError = false
        $scope.question.metadata.template = content.key
