lib.registerState "manager.settings.general.profiles",
  url: "/profiles?tab"
  templateUrl: "states/manager/settings/general/profiles/profiles.state.html"
  resolve:
    reviewTypes: ($http, group) ->
      return $http.get("/api/groups/#{group.id}/review-types").then (result) ->
        return result.data
  controller: ($scope, $state, $stateParams, $filter, $http, $rootScope, $timeout, ReviewType, organization, group, reviewTypes, flash, provider, session) ->

    $scope.profilesTabs = [
      {label: 'Notes', value: 'notes'}
      {label: 'Reviews', value: 'reviews'}
      {label: 'Permissions', value: 'permissions'}
    ]

    $scope.isOrganization = group.id == organization.id

    $scope.orgRoles = []

    # stateParam tab routing
    if $stateParams.tab && $scope.profilesTabs.find((t) -> t.value == $stateParams.tab.toLowerCase())
      $scope.profilesTab = $stateParams.tab.toLowerCase()
    else
      $scope.profilesTab = 'notes'

    #fetch roles for note category restrictions
    fetchRoles = ->
      $rootScope.showProgress()
      $scope.rolesFecthed = false
      $http.get("/api/organizations/#{organization.id}/roles").then (success) ->
        $scope.orgRoles = success.data.map (r) -> return { label: r.name, value: r.id }
        $scope.rolesFetched = true
        $rootScope.hideProgress()
      , (err) ->
        console.log 'caught err', err
        $rootScope.hideProgress

    fetchRoles()

    ###
    # NOTES
    ###
    $scope.provider = provider
    group.properties.noteCategories ?= []

    # restriction data format changed with the new permissions system
    # only allow Array values through (old data will be a string)
    group.properties.noteCategoryRestrictions = _.reduce ($scope.group.properties.noteCategoryRestrictions || {}), (res, val, key) ->
      if _.isArray val then res[key] = val
      return res
    , {}

    $scope.group = group

    if (!group.properties.noteCategories.includes('Attendance'))
      group.properties.noteCategories.push('Attendance')

    $scope.noteCategories = _(group.properties.noteCategories).map (categoryString) ->
      return { label: categoryString, roleIDs: group.properties.noteCategoryRestrictions?[categoryString]?.join(',') || "" }
    .keyBy 'label'
    .value()

    $scope.checkPermissions = (permission) => !$filter('permissionVisible')(permission)

    $scope.add = (cat) ->
      if cat.replace(/[^\w\s\/\-\&]/gi, '') != cat
        return flash "Invalid Characters. Only alphanumeric characters, slashes, dashes, and ampersands are allowed."
      if _.includes _.map($scope.group.properties.noteCategories, (c) -> c.replace(/\s+/g, ' ').toLowerCase()), cat.replace(/\s+/g, ' ').toLowerCase()
        return flash "Cannot create duplicate categories."
      $scope.group.properties.noteCategories.push cat
      $scope.group.properties.noteCategories.sort()
      $scope.group.save()
      flash "'#{cat}' Added"
      $scope.addDialog = false

    $scope.remove = (cat) ->
      $scope.group.properties.noteCategories = _.without $scope.group.properties.noteCategories, cat
      delete $scope.group.properties.noteCategoryRestrictions[cat]
      flash "'#{cat}' Removed"
      $scope.group.save()

    $scope.choices = [
      { label: 'None', value: null }
      { label: 'Medical', value: 'medical' }
      { label: 'Admin', value: 'admin' }
    ]

    $scope.$watch "noteCategories", (fresh, stale) ->
      return if fresh is stale
      _.map $scope.group.properties.noteCategories, (c) ->
        delete $scope.group.properties.noteCategoryRestrictions[c] unless $scope.noteCategories[c]?.roleIDs

        if $scope.noteCategories[c]?.roleIDs
          splitIDs = $scope.noteCategories[c].roleIDs.split(',')
          mappedIDs = splitIDs.map (rID) -> parseInt(rID, 10)
          $scope.group.properties.noteCategoryRestrictions[c] = mappedIDs

      $scope.group.save()
    , true


    ###
    # REVIEWS
    ###
    $scope.reviewTypes = _.map reviewTypes, (r) -> new ReviewType(r)
    $scope.reviewTypes = _.filter $scope.reviewTypes, (r) -> !r.deactivated
    $scope.deactivatedTypes = _.filter reviewTypes, (r) -> r.deactivated
    $scope.newType = new ReviewType({ groupID: group.id })
    $scope.currentlyEditing = false

    $scope.edit = (type) -> $scope.currentlyEditing = type

    $scope.reactivate = (type) ->
      type.deactivated = null
      new ReviewType(type).save().then (type) ->
        $state.reload $state.current.name

    $scope.save = (type) ->
      type.save().then (review) ->
        flash "#{type.name} Added"
        $state.reload $state.current.name

    $scope.deactivate = (type) ->
      window.swal
        type: "warning"
        title: "Delete #{type.name}?"
        text: "This will also remove all reviews for the #{type.name} review type."
        showCancelButton: true
      , (confirm) ->
        return unless confirm
        type.destroy().then (type) ->
          $state.reload $state.current.name
