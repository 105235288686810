angular.module("dn").directive "qtDimensions", ->
  restrict: "A"
  templateUrl: "directives/taker/modules/dimensions/qt-dimensions.directive.html"
  scope:
    question: "=qtDimensions"
    dims: "=dimensions"
  controller: ($scope, $stateParams, HealthForm) ->
    profileID = $stateParams.profileID
    healthForm = HealthForm.get profileID
    $scope.required = if $scope.question.required then "required" else ""

    # Holds feet and inches for conversion
    $scope.height = {}

    # Units to choose from
    $scope.units = [
      { label: "English (ft/lbs)", value: "english" }
      { label: "Metric (cm/kg)", value: "metric" }
    ]

    # Choices for the different units
    $scope.weightChoices =    _.map _.range(0, 501),  (v) -> { label: v, value: v }
    $scope.feetChoices   =    _.map _.range(0, 8),    (v) -> { label: v, value: v }
    $scope.inchChoices   =    _.map _.range(0, 12),   (v) -> { label: v, value: v }
    $scope.cmChoices     =    _.map _.range(0, 250),  (v) -> { label: v, value: v }


    # Default dimension placeholder
    if not $scope.dims or not $scope.dims[0]
      $scope.dims = [{ profileID: profileID }]
      $scope.dims[0].heightUnits = $scope.dims[0].weightUnits = "english"

    # Check if question is expired at question level
    if $scope.question.expired and $scope.expirationType != ('step' || 'form')
      $scope.dims = [{ id: $scope.dims[0].id, profileID: profileID }]
      $scope.dims[0].heightUnits = $scope.dims[0].weightUnits = "english"

    # Convert legacy units, may be deprecated soon enough
    convertExisting = ->
      d = $scope.dims[0]
      if d.heightUnits is "in" or d.weightUnits is "lbs"
        d.units = d.heightUnits = d.weightUnits = "english"
      else if d.heightUnits is "cm" or d.weightUnits is "kg"
        d.units = d.heightUnits = d.weightUnits = "metric"
      else
        d.units = d.heightUnits

    do convertExisting

    # Carry units over to the other field, since we're only supporting one unit type at once
    parseUnits = ->
      d = $scope.dims[0]
      if d.units is "english" then d.heightUnits = d.weightUnits = "english"
      else if d.units is "metric" then d.heightUnits = d.weightUnits = "metric"
      else do convertExisting

    do parseUnits

    # Convert from full height to inches/feet
    if $scope.dims[0].units is "english" and $scope.dims[0].height
      $scope.height.feet = ~~($scope.dims[0].height / 12)
      $scope.height.inches = ~~($scope.dims[0].height % 12)


    # Convert height from inputs
    $scope.$watch "height", (height, stale) ->
      return unless height.feet? and height.inches?
      $scope.dims[0].height = (~~(height.feet) * 12) + ~~height.inches
    , true


    healthForm.completeFns[$scope.question.id].push ->
      d = $scope.dims[0]
      if !d.height
        d.height = null
      if !d.weight
        d.weight = null
      if $scope.question?.expiration and d?.updated
        return false if $scope.question.expiration > d.updated
      if $scope.question.required
        return d.units and d.height and d.weight
      d.units and (typeof d.height isnt "undefined") and (typeof d.weight isnt "undefined")


    ###
    #   Save Queue
    ###

    saveFn = (nothing, fn=(->)) ->
      return fn() unless $scope.question.complete
      if !$scope.reviewMode
        healthForm.profile.reviewFlags ?= []
        healthForm.profile.reviewFlags.push($scope.question.id.toString())
      healthForm.save("dimensions", profileID, $scope.dims[0]).success (dims) ->
        $scope.dims[0].id = dims[0].id
        fn()

    saveQueue = async.queue saveFn, 1
    addSave = _.debounce ->
      saveQueue.push saveFn
    , 200

    initialized = false
    $scope.$watch "dims", ([fresh], [stale]) ->
      do parseUnits
      do healthForm.checkComplete[$scope.question.id]
      if !initialized then return initialized = true
      if fresh is stale then return
      # This next, apparently duplicated bit of logic is necessary because `weight` is loaded
      # from the model as a number, but having it on a select element converts the value to a string.
      # The type change is picked up by the watcher as a change, so this comparison is needed to
      # prevents us from saving (and adding a review flag) on each page load.
      # See issue #5553 for more details.
      unitsMatch = ['units', 'heightUnits', 'weightUnits'].every((prop) ->
        return fresh[prop] == stale[prop]
      )
      valuesMatch = ['height', 'weight'].every((prop) ->
        return parseInt(fresh[prop]) == parseInt(stale[prop])
      )
      if unitsMatch and valuesMatch then return
      do addSave
    , true
