
lib.registerState('manager.settings.general.manage', {
  url: '/manage',
  templateUrl: 'states/manager/settings/general/manage/manage.state.html',
  controller: function($http, $scope, $state, Group) {
    'use strict';
    /*
    I'm not sure if this was intentional or not, but displayData is never actually used
    We call trimFields on it which, since it's referencing $scope.org which $scope.groups is also tracking,
      modifies $scope.groups which is what the actual HTML is tracking.
    I have to imagine we could call trimFields on $scope.org before assigning $scope.groups
      and get rid of displayData entirely, but without knowing the reasoning behind it all
      it's staying as-is for now
    */
    $scope.groups = [$scope.organization];
    $scope.displayData = $scope.organization;
    $scope.toSave = {};
    $scope.deleting = false;

    const GROUP_FIELDS = ['id', 'name', 'children', 'parentID', 'properties', 'registration', 'gl_code_id'];
    const REGISTRATION_PROPERTIES = ['due', 'start', 'finish', 'lockout'];

    trimFields($scope.displayData);

    $scope.toggle = function(scope) {
      return scope.toggle();
    };

    $scope.saveable = function() {
      return _.size($scope.toSave);
    };

    $scope.save = function() {
      $scope.phase = 1;

      async.eachSeries(_.values($scope.toSave), (group, afterAsync) => {
        return new Group(group).save().then(() => {
          return afterAsync();
        }).catch((err) => { return afterAsync(err); });
      }, (err) => {
        if (err) return window.swal({title: err});
        $scope.phase = 2;
        $http.post(`/api/operator/organizations/${$scope.organization.id}/generate-parents`).then(() => {
          $scope.phase = 3;
          $state.reload();
        }).catch(() => {
          flash('Error in phase 2. Reloading.');
          $state.reload();
        });
      });
    };

    $scope.treeOptions = {
      accept: function(sourceScope, destScope) {
        if (!destScope.$parent.$modelValue) return false;
        const current = sourceScope.$modelValue;
        if (_.some(destScope.$modelValue, (group) => { return group.name === current.name && group.id !== current.id; })) {
          return false;
        }
        return true;
      },
      dropped: function(event) {
        /*
        event.source.nodeScope = scope of source node that was dragged
        event.dest.nodesScope = the scope of ui-tree-nodes which i dropped in
        new parent ID = dest.nodesScope.$parent.$modelValue.id
        */
        const draggedNode = event.source.nodeScope.$modelValue;
        const parentNode  = event.dest.nodesScope.$parent.$modelValue;

        if (draggedNode.parentID !== parentNode.id) {
          $scope.toSave[draggedNode.id] = draggedNode;
        }
        draggedNode.parentID = parentNode.id;
      }
    };

    /*
    Makes a request to grab registration, addOn, and coupon data for each group under the given group
    Spawns a confirmation swal detailing the effects of the deletion
    If user confirms, fires the final deletion request
    */
    $scope.confirmDelete = function(group) {
      return $http.post('/api/operator/group-delete-precheck', {groupID: group.id}).then((success) => {

        let totalGroupCount = 0;
        let totalRegCount = 0;
        let totalAddOnCount = 0;
        let totalCouponCount = 0;

        const statusText = _.map(success.data, (groupStat) => {
          totalGroupCount += 1;
          totalRegCount += parseInt(groupStat.regCount, 10);
          totalAddOnCount += parseInt(groupStat.addOnCount, 10);
          totalCouponCount += parseInt(groupStat.couponCount, 10);

          return `<li><strong>${groupStat.name}</strong> - R:${groupStat.regCount}${deactivatedText(groupStat, 'regDeactivatedCount')} A:${groupStat.addOnCount}${deactivatedText(groupStat, 'addOnDeactivatedCount')} C:${groupStat.couponCount}${deactivatedText(groupStat, 'couponDeactivatedCount')}</li>`;
        }).join('\n');

        return window.swal({
          title: `Delete ${group.name}?`,
          type: 'warning',
          showCancelButton: true,
          allowEscape: true,
          html: true,
          text: `
            <h4>This action will permanently delete the following ${totalGroupCount} groups, ${totalRegCount} registrations, ${totalAddOnCount} addOns, and ${totalCouponCount} coupons:</h4>
            <ul style="text-align: left;">
            ${statusText}
            </ul>

            <p>Would you like to continue?</p>
          `.replace(/^([ \t]){3,}/gm, '')
        }, (doIt) => {
          if (doIt) {
            $scope.deleting = true;
            return $http.post('/api/operator/group-delete', {groupID: group.id}).then(() => {
              flash(`${group.name} Deleted`);
              $state.reload();
            }).catch((err) => {
              $scope.deleting = false;
              let errText = '';
              if (err && err.data && err.data.detail && err.data.message) {
                errText = `
                  ${err.data.detail}

                  ${err.data.message}
                `.replace(/^([ \t]){3,}/gm, '');
              }
              return window.swal({
                title: 'Delete Error',
                type: 'error',
                text: `
                  There was an error deleting "${group.name}" or one of its child groups.

                  Please contact the DocNetwork web wizards to delete these
                  groups for you. The group ID you want to delete is ${group.id}.

                  ${errText}
                `.replace(/^([ \t]){3,}/gm, '')
              });
            });
          } else $scope.deleting = false;
        });
      }).catch((err) => {
        return window.swal({
          title: 'Pre-Check Error',
          type: 'error',
          text: `
            There was an error while fetching group deletion pre-check stats.

            ${err}
          `.replace(/^([ \t]){3,}/gm, '')
        });
      });
    };

    /*
    Keeps group data slim for reasons. Possibly a constraint of the 3rd party ui-tree-node tool we're using
    Assigns a couple more properties that are referenced when displaying certain icons on the nodes
    */
    function trimFields(group) {
      if (!group) return;

      _.forIn(group, (value, key) => {
        if (!_.includes(GROUP_FIELDS, key)) delete group[key];
      });
      group.anyDates = _.some(REGISTRATION_PROPERTIES, p => !!group.properties[p]);
      group.anyNotifications = _.some(group.properties.notifications, n => !!n.enabled);
      group.collapsed = true;

      _.map(group.children, trimFields);
    }

    /*
    Takes in a groupStat object like { name: 'Session 1', regCount: 5, regDeactivatedCount: 2 }
    And a type like 'regDeactivatedCount'
    If the group has a count > 0 for the given type, return (count), else nothing.
    */
    function deactivatedText(groupStat, type) {
      return groupStat[type] && groupStat[type] > 0 ? `(${groupStat[type]})` : '';
    }

  }
});
