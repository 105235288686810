angular.module('dn').directive('prescreeningBranding', function() {
  return {
    templateUrl: 'directives/prescreening/template-branding/template-branding.directive.html',
    restrict: 'E',
    controller($scope, $rootScope, PrescreeningData) {
      try {
        PrescreeningData.createTmpBranding();
        $scope.branding = PrescreeningData.tmp.branding;

        $scope.ui = {
          saving: false,
          save() {
            saveTemplate();
          },
        };

        /**
         * saveTemplate - allows us to save the template by calling the method on the service.
         * It handles disabling the buttons and showing the progress
         * @returns {void}
         */
        function saveTemplate() {
          $scope.ui.saving = true;
          $rootScope.showProgress();

          PrescreeningData.applyBrandingChanges();
          PrescreeningData.saveTemplate()
            .catch(err => {
              // Logging for local dev environments.
              log.error(err);
              // Display user-friendly error
              swal({
                type: 'error',
                title: 'Error',
                text: 'There was an error submitting the template. Please try again later.'
              });
            })
            .finally(() => {
              $scope.ui.saving = false;
              $rootScope.hideProgress();
            });
        }
      } catch (error) {
        log.error('[template-editor]', error);
      }
    }
  };
});
