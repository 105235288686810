angular.module('dn').directive('sidebar', function () {
  return {
    templateUrl: 'directives/sidebar/sidebar.directive.html',
    replace: true,
    restrict: 'E',
    controller: function ($scope, $state, $rootScope, $stateParams, User, WhatDivision, SupportDirectory) {
      var hiddenByState = function (state) {
        var hidden = _.some([
          'home.auth',
          'home.whereTo',
          'renew',
          'checkIn'
        ], function (hiddenState) {
          return state.indexOf(hiddenState) >= 0;
        });
        return hidden;
      };

      $scope.division = WhatDivision;
      $rootScope.$on('$stateChangeSuccess', function () {
        if (!$scope.user && !$scope.sideBarHidden) {
          User.current().then(function (user) {
            if (!user) return;
            $scope.user = user;
          });
        }
      });
      $scope.state = $state;
      $scope.sideBarHidden = true;
      // eslint-disable-next-line no-unused-vars
      $scope.$watch('state.current.name', function (fresh, stale) {
        $scope.sideBarHidden = hiddenByState(fresh);
        if (!fresh) {
          // since we don't have schooldoc support, we're directing them to the help email
          // Change this once we have support mirrored for SchoolDoc, DaycareDoc, JailDoc, etc... :D
          if ($scope.division.domain === 'schooldoc.com') {
            $scope.helpUrl = 'https://support.schooldoc.com';
          } else {
            $scope.helpUrl = 'https://support.campdoc.com';
          }
        } else {
          $scope.helpUrl = SupportDirectory.findDoc(fresh);
        }
      });

      $scope.stateIs = function (link) {
        if (!link.state || !$state.current.name) return;

        if (link.state === 'manager.attendance.list') {
          return $state.params.action === link.params.action;
        }

        if ($state.current.name === 'patient.profile.registration.register.insurance' && link.state === 'patient.profile.registration') {
          return false;
        }

        var stateMatch = (function () {
          return !!$state.current.name.match(link.state);
        })();

        if (!stateMatch || !link.parallel) return stateMatch;

        var paramMatch = _.every(link.params, function (param, key) {
          return param.toString() === $stateParams[key].toString();
        });

        return paramMatch;
      };

      $scope.year = moment().format('YYYY');
    },
    link: function ($scope, $elm) {
      // Jira docs for multiple collectors in the commit:
      window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
        // keyed by `&collectorId` attr from the html
        'b61c4efe': {
          triggerFunction: function(showCollectorDialog) {
            $elm.find('#submit-bug').click(function(e) {
              e.preventDefault();
              showCollectorDialog();
            });
          },
          // you can add a fieldValues: {} prop if needed
        },
        'f7f4de12': {
          triggerFunction: function(showCollectorDialog) {
            $elm.find('#submit-oper-request').click(function(e) {
              e.preventDefault();
              showCollectorDialog();
            });
          },
        }
      });
    }
  };
});
