lib.registerState('home.auth.noinvite', {
  url: '/noinvite',
  templateUrl: 'states/home/auth/noinvite/noinvite.state.html',
  params: {
    // Default this to null, handle the display logic in the HTML.
    email: null
  },
  controller: function ($scope, $state, $timeout, User) {
    // Email address is locked to what is provided in $state.params
    $scope.email = $state.params.email;
  }
});
