angular.module("dn").directive("qbMultiAuth", function() {
  return {
    templateUrl: "directives/builder/multi-auth/qb-multi-auth.directive.html",
    restrict: "A",
    replace: true,
    scope: {
      question: "=qbMultiAuth",
      mode: "@"
    },
    controller: function($scope, $http) {
      $scope.question.metadata = $scope.question.metadata || {};
      $scope.question.metadata.hideDefaultText = $scope.question.metadata.hideDefaultText || false;
      $scope.question.metadata.sections = $scope.question.metadata.sections || [];
      $scope.question.metadata.signatures = $scope.question.metadata.signatures || [];

      class Section {
        constructor() {
          this.body = '';
          this.label = '';
          this.declinable = false;
        }
      }

      class Signature {
        constructor() {
          this.label = '';
          this.required = false;
          this.relationshipRequired = false;
        }
      }

      // populate sections and signatures if empty
      if (!$scope.question.metadata.sections.length) {
        $scope.question.metadata.sections.push(new Section());
      }
      if (!$scope.question.metadata.signatures.length) {
        $scope.question.metadata.signatures.push(new Signature());
      }

      // disable editing if answers exist
      $scope.canEdit = true;
      if ($scope.question.id) {
        return $http.get("/api/questions/" + $scope.question.id + "/answers/count").then(function(success) {
          if (parseInt(success.data.count)) {
            return $scope.canEdit = false;
          }
        });
      }

      $scope.addSection = function(index) {
        $scope.question.metadata.sections.splice(index + 1, 0, new Section());
      }
      $scope.removeSection = function(index) {
        if ($scope.question.metadata.sections.length === 1) return;
        $scope.question.metadata.sections.splice(index, 1);
      }

      $scope.addSignature = function(index) {
        $scope.question.metadata.signatures.splice(index + 1, 0, new Signature());
      }
      $scope.removeSignature = function(index) {
        if ($scope.question.metadata.signatures.length === 1) return;
        $scope.question.metadata.signatures.splice(index, 1);
      }


    }
  };
});
