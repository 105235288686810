angular.module("dn").directive "selector", ($timeout) ->
  templateUrl: "directives/input/types/select/selector.directive.html"
  replace: true
  restrict: "E"
  scope:
    label: "@"
    placeholder: "@"
    choices: "="
    model: "="
    ajax: "@"
    ngDisabled: "="
  link: (scope, elm, attrs) ->
    options =
      ariaLabel: scope.label or scope.placeholder
      placeholder: scope.placeholder
      selectOnTab: true
      labelField: "label"
      searchField: [
        "label"
        "value"
      ]
      sortField: "_selectorIndex"
      diacritics: true
      options: []
      optgroupField: "optgroup"
      allowEmptyOption: true
      lockOptgroupOrder: true

    openDropdown = ->
      if !$selector then return
      $timeout ->
        $selector.open()
      , 100

    if scope.ajax
      options.load = (query, fn) ->
        return fn() if !query.length
        $.ajax
          url: "#{scope.ajax}/#{query}"
          type: "GET"
          error: fn
          success: (res) ->
            scope.choices = scope.choices or []
            scope.choices = res
            fn res
            openDropdown()

    $select = elm.selectize(options)
    $selector = $select[0].selectize

    # Add `aria-label` attribute to `.selectize-input` based on scope.label or scope.placeholder
    if $selector.settings.ariaLabel
      $selector.$control_input.attr('aria-label', $selector.settings.ariaLabel)

    scope.$watch "placeholder", (fresh) ->
      $selector.settings.placeholder = fresh or ''
      $selector.updatePlaceholder()

    scope.$watch "choices", (choices) ->
      $selector.clearOptions()
      $timeout ->
        filteredChoices = _.reject choices, (choice) ->
          return true if !choice
          choice.hide
        _.map _.map(filteredChoices, "optgroup"), (o) -> $selector.addOptionGroup o, label: o

        $selector.addOption _.map _.cloneDeep(filteredChoices), (choice, index) ->
          if _.isString(choice)
            choice =
              value: choice
              label: choice
          choice._selectorIndex = index
          choice
        $selector.setValue scope.model
    , true

    scope.$watch "model", (fresh, stale) ->
      # We're clearing choices for ajax inputs to get around a bug.
      # If we don't clear choices, users sometimes won't be able to clear
      # Filter Builder inputs. There's probably a better way to fix this.
      if scope.ajax and !fresh then scope.choices = []
      return if fresh is $selector.getValue()
      $selector.setValue fresh

    scope.$watch "ngDisabled", (disabled, was) ->
      $selector.disable() if disabled
      $selector.enable() if !disabled and was
    , true

    $selector.on("focus", () ->
      $timeout(() ->
        # This early return is to prevent problems like issue #4581
        if !$selector then return
        pxlHeight = Math.floor($selector.$dropdown.height())
        $selector.$dropdown.css("margin-bottom", pxlHeight)
        )
      )
