angular.module("dn").directive("bulkCoupons", function() {
  return {
    templateUrl: "directives/bulk-coupons/bulk-coupons.directive.html",
    restrict: "E",
    scope: {
      organization: "=",
      group: "="
    },

    controller: function($scope, $state, $http, $rootScope) {

      $scope.couponCodeSearch = null;
      $scope.resultCoupons = null;
      $scope.showFields = true;
      $scope.searching = false;
      $scope.COUPON_FIELDS = [
        {label:'EXPIRES',  selected: false, updateValue: null},
        {label:'CODE',     selected: false, updateValue: null},
        {label:'AMOUNT',   selected: false, updateValue: null},
        {label:'CAPACITY', selected: false, updateValue: null}
      ];

      const flatGroups = [];

      function flattenator(groups, groupArray) {
        _.map(groups, function(group) {
          groupArray.push( _.pick(group, ["id", "name", "classification", "parentID", "parents", "phase", "deactivated", "registration", "shortName", "properties", "compiledProperties"]) );
          if (group.children) flattenator(group.children, groupArray);
        });
      }

      flattenator([$scope.organization], flatGroups);

      $scope.findCoupons = function() {
        $scope.searching = true;
        if (!$scope.couponCodeSearch) {
          $scope.searching = false;
          return flash('No coupon code entered');
        }
        return $http.get('/api/organizations/' + $scope.organization.id + '/coupons/bulk?code=' + $scope.couponCodeSearch + '&groupID=' + $scope.group).then(
          function(success) {
            $scope.searching = false;
            updateResultCoupons(success.data);
          }, function(err) {
            console.log('caught err', err);
            $scope.searching = false;
            throw err;
          }
        );
      }

      $scope.updateCoupons = function() {
        const selectedCouponIDs = $scope.resultCoupons.reduce((coupIDs, c) => {
          if (c.selected) coupIDs.push(c.id);
          return coupIDs;
        }, []);

        const bodyUpdates = $scope.COUPON_FIELDS.reduce((updates, field) => {
          if (field.selected) {
            updates[field.label.toLowerCase()] = field.value || null;
          }
          return updates;
        }, {});

        return $http.put('/api/organizations/' + $scope.organization.id + '/coupons/bulk', {
          updates: bodyUpdates,
          couponIDs: selectedCouponIDs
        }).then((success) => {
          flash('Successfully updated');
          resetFields();
          updateResultCoupons(success.data, $scope.resultCoupons);
        }, (err) => {
          console.log('caught error', err)
          swal("Error", "Encountered a problem: " + err.data + ", " + "code: " + err.status, "error");

        });
      }

      $scope.deactivateCoupons = function() {
        const selectedCouponIDs = $scope.resultCoupons.reduce((coupIDs, c) => {
          if (c.selected) coupIDs.push(c.id);
          return coupIDs;
        }, []);

        const bodyUpdates = {
          deactivated: new Date().toISOString()
        }

        return $http.put('/api/organizations/' + $scope.organization.id + '/coupons/bulk', {
          updates: bodyUpdates,
          couponIDs: selectedCouponIDs
        }).then((success) => {
          flash('Successfully deactivated');
          resetFields();
          updateResultCoupons(success.data, $scope.resultCoupons);
        }, (err) => {
          console.log('caught err')
          throw err;
        });
      }

      $scope.selectAllResults = function(value) {
        $scope.resultCoupons.map(coupon => coupon.selected = !!value);
      }

      function attachGroups(resultCoupons) {
        return resultCoupons.map((coupon) => {
          const flatGroupMatch = flatGroups.find(fg => fg.id === parseInt(coupon.groupID));
          coupon.groupName = flatGroupMatch.name;
          return coupon;
        });
      }

      function updateResultCoupons(apiResults, existing) {
        if (existing && existing.length) {
          apiResults.updatedEntries.map((updatedCoupon) => {
            const resultsMatch = existing.find(rc => rc.id === updatedCoupon.id);
            resultsMatch.code = updatedCoupon.code;
            resultsMatch.amount = updatedCoupon.amount;
            resultsMatch.capacity = updatedCoupon.capacity;
            resultsMatch.expires = updatedCoupon.expires;
            resultsMatch.deactivated = updatedCoupon.deactivated;
          });
        } else $scope.resultCoupons = attachGroups(apiResults);

        $scope.resultCoupons = _($scope.resultCoupons)
          .filter(c => !c.deactivated && !c.properties.bypassDeposit && !c.properties.isPercentage)
          .sortBy(['code', 'groupName'])
          .value();
      }

      function resetFields() {
        /*
          Date and Money inputs will get their models removed but the views don't reflect
          showFields is tied to an ng-if, so toggling it off and on causes them to re-initialize, clearing the views
          Introduce a wee bit of delay to give the inputs a chance to reset I guess. . .
        */
        $scope.showFields = false;
        $scope.COUPON_FIELDS.map((field) => {
          field.selected = false;
          field.value = null;
        });
        setTimeout(() => {
          $scope.showFields = true;
        });
      }


    } /* end controller */
  }; /* end return statement */
}); /* end directive */
