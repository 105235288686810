lib.registerState('home.auth.pwreset', {
  url: '/password-reset',
  templateUrl: 'states/home/auth/password-reset/password-reset.state.html',
  params: {
    // Default this to null, handle the display logic in the HTML.
    email: null,
    badPassport: false
  },
  controller: function ($scope, $state, $timeout, User) {
    // providedEmail is locked to what is provided in $state.params
    $scope.providedEmail = $state.params.email;
    $scope.email = $state.params.email;
    $scope.badPassport = $state.params.badPassport;
  }
});
