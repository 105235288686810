angular.module('dn').service('GroupBranding', (() => {
  'use strict';

  const ERR_TAG = '[GroupBranding]';
  const ALIAS_MAP = {
    HEALTH_PROFILE: 'properties.portals.patient.sections.questionnaire.alias',
    PRESCREENING: 'properties.portals.patient.sections.prescreening.alias',
    PROFILE_ACCOUNT: 'properties.portals.patient.sections.account.alias',
    REGISTRATION: 'properties.portals.patient.sections.registrations.alias',
  };
  // In the future, we can expand this to other stuff on the same path
  const CUSTOM_TEXT_MAP = {
    PRESCREENING: 'properties.branding.text.patient.prescreening',
  };

  const _cache = {};

  /**
   * The GroupBranding service provides convenient access to all of the root organization right on
   * the instance. It also provides methods for retrieving branding info from non-root groups.
   * @example
   * // To use an alias in a template, we can simply pull it off the service
   * $scope.prescreeningAlias = GroupBranding.aliases.prescreening
   *
   * @example
   * // To look up an alias for non-root orgs, use instance methods
   * const aliases = myOrgs.reduce((uniqueAliases, org) => {
   *   const alias = GroupBranding.getHealthProfileAlias(org);
   *   if (!uniqueAliases.includes(alias)) uniqueAliases.push(alias);
   *   return uniqueAliases;
   * }, []);
   */
  class GroupBranding {
    constructor($rootScope) {
      this.$rootScope = $rootScope;
    }

    /**************************/
    /***       GETTERS      ***/
    /**************************/

    get aliases() {
      // Log an error locally if we don't have a root org available
      if (!this.$rootScope.organization) {
        log.error(`${ERR_TAG}[get() aliases] Missing organization on $rootScope.`);
        return {};
      }
      // Easily access the various feature aliases
      if (!_cache.aliases || _cache.orgID !== this.$rootScope.organization.id) {
        this._updateCache();
      }
      return _cache.aliases;
    }

    get customText() {
      // Log an error locally if we don't have a root org available
      if (!this.$rootScope.organization) {
        log.error(`${ERR_TAG}[get() customText] Missing organization on $rootScope.`);
        return {};
      }
      // Cache branding text for easy access
      if (!_cache.customText || _cache.orgID !== this.$rootScope.organization.id) {
        this._updateCache();
      }
      return _cache.customText;
    }

    /**************************/
    /***       METHODS       **/
    /**************************/

    /**
     * Updates the _cache object sequestered within the closure. Meant to be used when we detect a
     * change in the root organization.
     * @private
     * @returns {void}
     */
    _updateCache() {
      _cache.orgID = this.$rootScope.organization.id;
      _cache.aliases = {
        healthProfile: this.getHealthProfileAlias(this.$rootScope.organization),
        prescreening: this.getPrescreeningAlias(this.$rootScope.organization),
        profileAccount: this.getProfileAccountAlias(this.$rootScope.organization),
        registration: this.getRegistrationAlias(this.$rootScope.organization),
      };
      _cache.customText = {
        prescreeningDescription: this.getPrescreeningDescription(this.$rootScope.organization),
      };
    }

    /**
     * Gets deeply nested values at a specified path. Optionally returns a fallback value if one is
     * supplied. Wraps `_.get` to handle empty string values because our inputs suck.
     * @param {Object} obj Object on which we'd like to use _.get.
     * @param {String} path Path to the value we'd like to retrieve.
     * @param {*} [fallback] Default value to return if the target is missing at the supplied path.
     * @returns {*} Whatever value is at the indicated path or, if the value is missing and a
     *   fallback was supplied, the fallback.
     */
    _wrappedGet(obj, path, fallback) {
      let val = _.get(obj, path);
      if (!val && fallback) val = fallback;
      return val;
    }

    /*
     A Note on Method Usage

     We provide these methods so that we can consistently and easily retrieve branding info for
     groups _other_ than the $rootScope org should the need arise. Everything you need for general
     use in a template or state should be available via this properties defined in the constructor.
    */

    /**
     * Look up an organization's alias for the Health Profile feature or its default.
     * @param {Object} organization The organization object we want to extract an alias from.
     * @returns {String} The feature name as defined by the organization.
     */
    getHealthProfileAlias(organization) {
      return this._wrappedGet(organization, ALIAS_MAP['HEALTH_PROFILE'], 'Health Profile');
    }

    /**
     * Look up an organization's alias for the Prescreening feature or its default.
     * @param {Object} organization The organization object we want to extract an alias from.
     * @returns {String} The feature name as defined by the organization.
     */
    getPrescreeningAlias(organization) {
      return this._wrappedGet(
        organization,
        ALIAS_MAP['PRESCREENING'],
        window.dnim.constants.prescreening.branding.alias
      );
    }

    /**
     * Look up an organization's custom description for Prescreening or our default.
     * @param {Object} organization The organization object we want to extract an alias from.
     * @returns {String} A short description of Prescreening for use on the profile landing page.
     */
    getPrescreeningDescription(organization) {
      return this._wrappedGet(
        organization,
        CUSTOM_TEXT_MAP['PRESCREENING'],
        window.dnim.constants.prescreening.branding.description
      );
    }

    /**
     * Look up an organization's alias for the Profile Account feature or its default.
     * @param {Object} organization The organization object we want to extract an alias from.
     * @returns {String} The feature name as defined by the organization.
     */
    getProfileAccountAlias(organization) {
      return this._wrappedGet(organization, ALIAS_MAP['PROFILE_ACCOUNT'], 'Account');
    }

    /**
     * Look up an organization's alias for the Registration feature or its default.
     * @param {Object} organization The organization object we want to extract an alias from.
     * @returns {String} The feature name as defined by the organization.
     */
    getRegistrationAlias(organization) {
      return this._wrappedGet(organization, ALIAS_MAP['REGISTRATION'], 'Registrations');
    }
  }

  return GroupBranding;
})());
