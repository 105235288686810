lib.registerState "manager.settings.questionnaire",
  url: "/questionnaire"
  templateUrl: "states/manager/settings/questionnaire/questionnaire.state.html"
  resolve:
    title: ($rootScope, group) ->
      $rootScope._title = "#{group.name} / Health Form"
    states: (Constants) ->
      Constants.states().then (states) -> states
    countries: (Constants) ->
      Constants.countries().then (countries) -> countries
    templates: ($http, session) ->
      return [] if !session?.access?.operator
      $http.get("/api/operator/templates").then (result) ->
        result.data
  controller: ($scope, $timeout, $state, $http, flash, group, WhatDivision, templates, session) ->
    $scope.disableLinkToClassicBuilderFlag = window.lib.featureFlagClient.isEnabled('disableLinkToClassicBuilder')
    if $scope.disableLinkToClassicBuilderFlag
      return swal {
        title: '404',
        type: 'error',
        text: 'Oops! We can’t find the page you are looking for.'
      }, () ->
        return $state.go('manager.settings.general')

    $scope.division = WhatDivision
    $scope.saved = false
    $scope.savedTemplates = templates
    $scope.session = session
    $scope.needSave = false
    $scope.autosaveChoices = [
      { label: "On", value: true }
      { label: "Off", value: false }
    ]
    $scope.autosaveEnabled = true

    # needed for a few things in qb-question directive
    # changing a $scope.$parent.variable from a child state doesn't appear to actually update it here
    # but calling a parent scope's function that sets the variable does the trick
    $scope.updateNeedSave = (bool) ->
      return if !$scope.autosaveEnabled or ($scope.needSave is bool)
      flash "Autosave prop set to #{bool}"
      $scope.needSave = bool

    $scope.templateChoices = _.map $scope.savedTemplates, (template) ->
      choice = {}
      choice.value = template.id
      choice.label = "#{template.groupName} - #{template.name}"
      choice

    findOrganization = (group) ->
      return unless group
      if group.classification is "organization" then return group
      return findOrganization group.parent

    if $scope.group.classification isnt "organization"
      $scope.notOrganization = true
      $scope.org = findOrganization $scope.group

    $scope.stepState = -> $state.current.name.indexOf("step") > -1

    $scope.group.idMap = {}
    _.map $scope.group.$selectableChildren(), (c) -> $scope.group.idMap[c.value] = c.label

    $scope.treeOptions =
      dropped: -> if $scope.stepState() then $state.go "manager.settings.questionnaire"

    $scope.questionnaire  = $scope.group.newQuestionnaire
    $scope.builder        = new window.lib.QuestionnaireBuilder $scope.questionnaire

    $scope.emptyQuestion = ->
      return unless $scope.questionnaire
      return { type: "step", label: "Step #{$scope.questionnaire.questions.length + 1}"}

    # Assign indexes to the steps
    indexify = ->
      _.map $scope.group.newQuestionnaire.questions, (q, i) -> q.index = i
    do indexify


    $scope.addStep = ->
      $scope.builder.addQuestion $scope.emptyQuestion()
      do indexify

    $scope.goToStep = (step) ->
      if $scope.needSave and $scope.autosaveEnabled
        save () ->
          $scope.needSave = false
          $state.go "manager.settings.questionnaire.step", {step: step.index + 1}
      else
        $state.go "manager.settings.questionnaire.step", {step: step.index + 1}

    $scope.showDeactivated = ->
      $state.go "manager.settings.questionnaire.step", {step: 'deactivated'}

    $scope.isActive = (step) ->
      currentStep = parseInt _.last location.href.split "/"
      currentStep is step.index + 1

    $scope.$on "stepUpdate", () ->
      $scope.needSave = true
      indexify()

    $scope.loadTemplate = ->
      window.swal
        body: $scope.savedTemplates

    $scope.saveTemplate = ->
      window.swal
        title: "Save Form as Template"
        type: "input"
        text: "Template Name"
        showCancelButton: true
      , (inputValue) ->
        if inputValue is false then return false
        if inputValue is ""
          window.swal.showInputError "Name required for template"
          return false

        cleanQuestionnaire $scope.group.newQuestionnaire.questions
        body = _.cloneDeepWith $scope.questionnaire, (node) ->
          if !node then return undefined
          if node.subgroups?.length then node.subgroups = []
          delete node.groupID
          delete node.id
          delete node.created
          delete node.updated
          delete node.deactivated
          return undefined
        template =
          groupID: $scope.group.id
          operator: true
          template: body
          name: inputValue
          type: "questionnaire"

        $http.post("/api/operator/templates", template).then ->
          window.swal "Saved", "#{inputValue} was saved."
          $state.reload "manager.settings.questionnaire"


    $scope.loadTemplate = (templateID) ->
      template = _.find $scope.savedTemplates, { id: parseInt(templateID) }
      return unless template
      $scope.questionnaire.questions ?= []
      $scope.questionnaire.questions = $scope.questionnaire.questions.concat _.cloneDeep template.template.questions
      $scope.builder        = new window.lib.QuestionnaireBuilder $scope.questionnaire
      indexify()


    $scope.deleteTemplate = (templateID) ->
      template = _.find $scope.savedTemplates, { id: parseInt(templateID) }
      return unless template
      template.deactivated = new Date()
      $http.post("/api/operator/templates", template).then ->
        window.swal "Deleted", "#{template.name} was removed."
        $state.reload "manager.settings.questionnaire"


    cleanQuestionnaire = (questions) ->
      return unless questions?.length
      questions.forEach((q, idx) ->
        delete q.parentQuestion
        q.rank = idx + 1
        cleanQuestionnaire q.subQuestions
      )


    save = (callback) ->
      return if $scope.saving
      flash "Saving..."
      $scope.saving = true
      cleanQuestionnaire $scope.group.newQuestionnaire.questions

      group.removed = _.compact(group.removed) if _.isArray(group.removed)
      group.reactivated = _.compact(group.reactivated) if _.isArray(group.reactivated)

      $http.post("/api/groups/#{$scope.group.id}/questionnaire", $scope.group).then (result) ->
        $scope.$broadcast "stepUpdate"
        $scope.saving = false
        $scope.saved = true
        indexify()
        flash "Health Form Saved"
        if callback then callback() else $state.reload()

    $scope.save = ->
      $timeout save

    $scope.$on "merged", $scope.save
