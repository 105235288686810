angular.module("dn").directive "avatar", ->
  templateUrl: "directives/avatar/avatar.directive.html"
  replace: true
  restrict: "E"
  scope:
    size: "@"
    profile: "="
    placeholder: "&"
    trustedContact: "="
  controller: ($scope) ->
    signedURLRegex = /^https:\/\//
    $scope.size ?= "sm"
    if $scope.trustedContact
      if signedURLRegex.test $scope.trustedContact?.picture?[$scope.size]
        $scope.imageKey = true # Neccesary for the image to pop up
        $scope.imgSrc = $scope.trustedContact?.picture?[$scope.size]
      else
        $scope.profileID = $scope.trustedContact.profile_id
        # Updates trustedContact image in real-time
        $scope.$watch 'trustedContact', (fresh, stale) ->
          $scope.imageKey = if fresh.picture then encodeURIComponent(fresh.picture) else null
          if $scope.trustedContact.group_id
            $scope.imgSrc = "/api/organizations/#{$scope.trustedContact.group_id}/universal-contact-upload?key=#{$scope.imageKey}"
          else
            $scope.imgSrc = "/api/profiles/#{$scope.profileID}/avatar?size=#{$scope.size}&key=#{$scope.imageKey}"
        , true
    else
      if $scope.profile?.avatar
        if signedURLRegex.test $scope.profile?.avatar?[$scope.size]
          $scope.imageKey = true # Neccesary for the image to pop up
          $scope.imgSrc = $scope.profile?.avatar?[$scope.size]
        else
          # Updates profile image in real-time
          $scope.profileID = $scope.profile.id
          $scope.$watch 'profile.avatar', (fresh, stale) ->
            $scope.imageKey = if fresh?[$scope.size] then fresh?[$scope.size] else null
            $scope.imgSrc = "/api/profiles/#{$scope.profileID}/avatar?size=#{$scope.size}&key=#{$scope.imageKey}"
          , true
