angular.module('dn').directive('addOns', function() {
  return {
    templateUrl: 'directives/registration-wizard/add-ons/add-ons.directive.html',
    restrict: 'E',
    scope: {
      addOns: '=',
      groups: '=',
      crebits: '=',
      regData: '=',
      isComplete: '=',
      onContinue: '=',
      bypass: '=',
      flatOrg: '='
    },
    controller($rootScope, $scope, $filter) {
      $scope.isComplete = () => false;

      // create object to map groupID to object
      $scope.groupMap = _.keyBy($scope.flatOrg, 'id');

      // Get org branding text for addOnSelect
      const branding = $scope.flatOrg[0].properties.branding;
      $scope.orgText
        = branding
        && branding.text
        && branding.text.registration
        && branding.text.registration.addOnSelect
          ? branding.text.registration.addOnSelect + '\n\n' : null;

      // show spinkit if addOns haven't been loaded
      if (!$scope.regData.addOns.length) $rootScope.showProgress();

      // fetch addOns and pass to main handler or error
      $scope.regData
        .fetchAddOns($scope.groups.filter(g => !g.waitlistRegistration).map(g => g.id))
        .then(addOnHandler)
        .catch(errorHandler);

      function addOnHandler() {

        // this function runs once addons have been loaded, so we can hide spinkit
        $rootScope.hideProgress();

        // Sorts addons alphabetically. Non-shared addons will retain their display order, so only
        // shared addons will display alphabetically.
        $scope.regData.addOns.sort((a, b) => a.name > b.name ? 1 : -1);

        // filter and conform addons
        $scope.availableAddOns = $scope.regData.addOns
          .filter(isValid)
          .map((addOn) => {
            setRemaining(addOn);
            setQuantity(addOn);
            restoreSelected(addOn);
            createOptions(addOn);
            selectRequired(addOn);
            return addOn;
          }).filter(removeOptions);

        // bypass step if no available addOns, or every registration is waitlisted
        if (!$scope.availableAddOns.length || _.every($scope.groups, g => g.waitlistRegistration)) return $scope.bypass();

        $scope.addOnsByGroup = _.groupBy($scope.availableAddOns, 'groupID');

        // Creates a sorted array of groupIDs by group name. Will render groups by alphabetical
        // order.
        $scope.addOnGroups = Object.keys($scope.addOnsByGroup).sort((a, b) => {
          if ($scope.groupMap[a].name < $scope.groupMap[b].name) return -1;
          if ($scope.groupMap[a].name > $scope.groupMap[b].name) return 1;
          return 0;
        });

        // Adds groups that will not display a header to the start of the display list
        const unlabeledGroups = $scope.addOnGroups.filter(group => {
          return !$scope.handleGroupNameDisplay(group);
        });
        $scope.addOnGroups = unlabeledGroups.concat($scope.addOnGroups);

        // Removes duplicates
        $scope.addOnGroups = [...new Set($scope.addOnGroups)];

        // step is complete when all required addons (or required options) have been selected
        const requiredAddOns = $scope.availableAddOns.filter(a => a.required);
        $scope.isComplete = () => {
          return _.every(requiredAddOns, a => {
            return a.options.length ? _.some(a.options, o => o.selected) : a.selected;
          });
        };

        $scope.onContinue = () => {
          // reset addOns array without destroying object reference
          // overwriting $scope.addOns will destroy the reference to the state's
          // scope and create a local scope instead that isn't bound
          $scope.addOns.length = 0;
          $scope.availableAddOns.map((addOn) => {
            if (addOn.selected) $scope.addOns.push({
              id: addOn.id,
              name: addOn.name,
              quantity: addOn.quantity,
              appliedToGroup: addOn.appliedToGroup,
              price: addOn.price,
              dueAtDeposit: addOn.properties.dueAtDeposit,
            });

            if (addOn.options.length) addOn.options.map(option => {
              if (option.selected) $scope.addOns.push({
                id: option.id,
                name: addOn.name + ' / ' + option.name,
                quantity: option.quantity,
                parentID: option.parentID,
                appliedToGroup: addOn.appliedToGroup,
                price: option.price,
                dueAtDeposit: addOn.properties.dueAtDeposit
              });
            });
          });
        };
      }

      function isValid(addOn) {
        // since addOn cancellation crebits also have addOnID, we know it's been
        // purchased by summing the addOnQuantity for all crebits with that addOnID
        const purchased = $scope.crebits.reduce((sum, crebit) => {
          // eslint-disable-next-line eqeqeq
          if (crebit.addOnID == addOn.id) sum += crebit.addOnQuantity;
          return sum;
        }, 0);

        const deactivated = addOn.deactivated;
        // eslint-disable-next-line eqeqeq
        const overCapacity = addOn.capacity != null && addOn.used >= addOn.capacity;

        addOn.appliedToGroup = getAppliedToGroup(addOn);

        return !window.lib.isExpired(addOn, $scope.regData.org.properties.timezone) && !deactivated && !overCapacity && !purchased && addOn.appliedToGroup;
      }

      function setRemaining(addOn) {
        if (!addOn.capacity) return addOn.remaining = null;
        addOn.remaining = addOn.capacity ? addOn.capacity - (addOn.used || 0) : null;
      }

      function setQuantity(addOn) {
        if (addOn.numeric) addOn.quantity = 1;
      }

      function getAppliedToGroup(addOn) {
        const groupMatch = $scope.groups.filter(g => !g.waitlistRegistration).find(group => _.includes(group.parents, addOn.groupID));
        if (groupMatch) {
          return groupMatch.id;
        } else {
          return null;
        }
      }

      function restoreSelected(addOn) {
        const previouslySelected = _.find($scope.addOns, a => a.id === addOn.id);
        if (previouslySelected) {
          addOn.selected = true;
          addOn.quantity = previouslySelected.quantity;
        }
      }

      function createSelectChoices(addOn) {
        // create selection options for material input
        if (addOn.options || addOn.options.length) {
          addOn.selectOptions = addOn.options.map((option, index) => {
            const price = option.price ? $filter('currency')(option.price / 100) : '';
            return { label: `${option.name} ${price}`, value: index };
          });
          // add `None` option for non-required addOns
          if (!addOn.required) addOn.selectOptions.unshift({label: 'None', value: -1});
        }
        return addOn;
      }

      // Handles displaying group names above lists of add-ons
      $scope.handleGroupNameDisplay = (groupID) => {
        // The original logic: Automatically displays the group name if there is no shortName.
        if (!$scope.groupMap[groupID].shortName) return true;
        // When displaying org-level shared add-ons, it should not display the org name above
        // those add-ons. eslint-disable used for comparing a number to a string.
        // eslint-disable-next-line eqeqeq
        if (groupID == $scope.regData.orgID) return false;
        // If one of the groups being registered for, or a direct parent, has the add-on, show
        // the group name header.
        return $scope.groups.find(group => {
          // Comparing string and number
          // eslint-disable-next-line eqeqeq
          return group.parentID == groupID || group.id == groupID;
        });
      };

      function createOptions(addOn) {
        // create an options array for multiple choice addOns
        // arrange by displayOrder asc
        addOn.options = $scope.regData.addOns
          .filter(a => a.parentID === addOn.id)
          .sort((a, b) => +a.properties.displayOrder - +b.properties.displayOrder);
      }

      function selectRequired(addOn) {
        if (!addOn.options.length && addOn.required) addOn.selected = true;
      }

      function removeOptions(addOn) {
        // filter purchased multichoice addons (first pass doesn't catch this since options weren't attached)
        const purchased = $scope.crebits.reduce((sum, crebit) => {
          addOn.options.map(option => {
            // eslint-disable-next-line eqeqeq
            if (crebit.addOnID == option.id) sum += crebit.addOnQuantity;
          });
          return sum;
        }, 0);

        // remove options that are expired or overcapacity
        const hadOptions = !!addOn.options.length;

        addOn.options = addOn.options.filter(o => {
          // eslint-disable-next-line eqeqeq
          const underCapacity = o.capacity == null || o.capacity > o.used;
          return underCapacity && !window.lib.isExpired(o, $scope.regData.org.properties.timezone);
        });

        const allOptionsRemoved = hadOptions && !addOn.options.length;

        // create select options for dropdown
        createSelectChoices(addOn);

        // remove purchased addOns, detached options, irrelevant shared addons, and multi-choice addons with all options filtered
        return !purchased && !addOn.parentID && addOn.appliedToGroup && !allOptionsRemoved;
      }

      function errorHandler(error) {
        $rootScope.hideProgress();
        console.error(error);
        swal('Error!', 'An unexpected error occured:\n' + error, 'error');
      }
    }
  };
});
