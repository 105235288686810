lib.registerState("manager.import.upload", {
  url: "/upload",
  templateUrl: "states/manager/import/upload/import-upload.state.html",
  resolve: {
    title: function($rootScope) {
      $rootScope._title = "Upload";
      return null;
    }
  },
  controller: function($scope, $state, organization, session) {
    "use strict";
    $scope.organization = organization;
    $scope.division = organization.division ? organization.division.name : 'CampDoc';
    const isOper = session.access.operator;

    // If provider has this feature enabled and it's not an oper redirect to the profile import
    if (organization.properties.enableProfileImport && !isOper) {
      $state.go('manager.import.profile-import');
      return null;
    }

    $scope.submitting = false;

    $scope.uploadComplete = function(response) {
      $scope.submitting = false;
      if (response.status !== 200) {
        return window.swal({
          title: "Error Uploading",
          type: "error",
          text: response.message || "Please contact support."
        });
      } else {
        return window.swal({
          title: "Upload Complete",
          type: "success",
          text: "You will receive confirmation once one of our data specialists has completed the import.",
          allowOutsideClick: true
        });
      }
    } // end uploadComplete
  } // end controller
}); // end state
