angular.module("dn").directive "qtQuestion", ($timeout, $compile, HealthForm) ->
  templateUrl: "directives/taker/question/qt-question.directive.html"
  restrict: "A"
  replace: true
  scope:
    question: "=qtQuestion"
    lockedOut: "=lockedOut"
    answers: "="
    profile: "="
    type: "@"

  link: (scope, elm, attrs) ->
    nonModules = [
      'text'
      'boolean'
      'encrypted'
      'select'
      'authorization'
      'download'
      'upload'
      'instructions'
    ]

    if scope.question.type in ["step", "form"] then return
    originalLabel = scope.question.label
    originalBody = scope.question.body
    compiled = HealthForm.compiler scope.question.type

    initQuestion = () ->
      return unless scope.question.type in nonModules
      scope.question.alert = null
      translation = scope.question.metadata.translations?[scope.profile.selectedLang]
      if translation && (translation.label || translation.body)
        scope.question.label = translation.label || originalLabel
        scope.question.body = translation.body || originalBody
      else
        scope.question.label = originalLabel
        scope.question.body = originalBody
        scope.question.alert = "#{scope.profile.selectedLang} translation is not available." unless scope.profile.selectedLang is 'English'

    initQuestion()
    created = compiled scope

    elm.append created

    scope.$watch('profile.selectedLang', (fresh, stale) ->
      return if (stale and (fresh is stale)) or !(scope.question.type in nonModules)
      initQuestion()
    )

  controller: ($scope, $stateParams, $interval) ->
    $scope.answer = $scope.profile.answers[$scope.question.id] || {}

    # Update every possible answer value to get OTC meds to update in front-end, sorry
    # this function is called in qt-otc on save
    $scope.updateAnswer = (answer) ->
      $scope.answer = answer
      $scope.question.answer = answer
      $scope.profile.answers[$scope.question.id] = answer

    healthForm = HealthForm.get $scope.profile.id
    $scope.reviewMode = $stateParams.review
    $scope.parentQuestion = $scope.question.parentQuestion

# dont create answers for questions that have their own behavior
    noAnswers = [ "step", "form", "instructions", "allergies", "dimensions",
      "emergencyContacts", "immunizations", "insurances", "medications",
      "primaryProviders", "encrypted"]
    if _.includes noAnswers, $scope.question.type
      healthForm.noAnswer[$scope.question.id] = true

    if not healthForm.noAnswer[$scope.question.id]
      $scope.answer = $scope.question.answer
      if $scope.answer
        $scope.answer.questionID ?= $scope.question.id
        $scope.answer.profileID ?= $scope.profile.id

    $scope.conditions =
      allergies: (arg) ->
        return false if _.isEmpty($scope.profile.has?.allergies)
        if arg is "true"
          return _.some $scope.profile.has.allergies, (a) -> a is "true"
        else
          return _.every $scope.profile.has?.allergies, (a) -> a isnt "true"
      foodAllergies: (arg) ->
        return false if _.isEmpty($scope.profile.has?.allergies?.food)
        if arg is "true"
          return $scope.profile.has.allergies?.food is "true"
        else
          return $scope.profile.has.allergies?.food isnt "true"
      drugAllergies: (arg) ->
        return false if _.isEmpty($scope.profile.has?.allergies?.drug)
        if arg is "true"
          return $scope.profile.has.allergies?.drug is "true"
        else
          return $scope.profile.has.allergies?.drug isnt "true"
      envAllergies: (arg) ->
        return false if _.isEmpty($scope.profile.has?.allergies?.environmental)
        if arg is "true"
          return $scope.profile.has.allergies?.environmental is "true"
        else
          return $scope.profile.has.allergies?.environmental isnt "true"
      anaphylaxis: (arg) ->
        if arg is "true"
          # show question if any allergies are at risk for anaphylaxis
          return $scope.profile.allergies.some((a) -> a.anaphylaxis && !a.deactivated)
        else
          # show question if they have allergies but none are at risk for anaphylaxis
          return $scope.profile.allergies.length && $scope.profile.allergies.every((a) -> !a.anaphylaxis && !a.deactivated)
      epiPen: (arg) ->
        if arg is "true"
          # show question if any allergies are bringing epi pen
          return $scope.profile.allergies.some((a) -> a.anaphylaxis && a.epiPen && !a.deactivated)
        else
          # show question if all anaphylaxis allergies are not bringing epi pen
          anaphylaxisAllergies = $scope.profile.allergies.filter((a) -> a.anaphylaxis && !a.deactivated)
          return anaphylaxisAllergies.length && anaphylaxisAllergies.every((a) -> !a.epiPen)
      authorization: (arg) ->
        return false if not $scope.parentQuestion.answer?.value
        status = $scope.parentQuestion.answer.value.split("|")[0]
        if arg is "true" then return (status is "accepted") else return (status is "declined")
      medications: (arg) ->
        return $scope.profile.has.medications && ($scope.profile.has.medications == arg || $scope.profile.has.medications.value == arg)
      willTakeMedications: (arg) ->
        return false if _.isEmpty($scope.profile.medications)
        if arg is "true"
          return _.some $scope.profile.medications, "willTake"
        else if arg is "false"
          return !_.some $scope.profile.medications, "willTake"
        else
          return false

      select: (arg) ->
        return false if not $scope.parentQuestion.answer?.value
        return _.includes arg.replace(/\n/g, "").split(","), $scope.parentQuestion.answer.value.replace(/\n/g, "")

    $scope.conditionMet = ->
      if $scope.question.metadata.sexRestrict
        return false if $scope.question.metadata.sexRestrict isnt $scope.profile.sex

      # Check for age restriction, return false if it isn't met, ignore it if profile has no dob
      if $scope.question.metadata?.ageRestrict && $scope.profile.dob
        overAge = moment($scope.profile.dob.toString()).add(+$scope.question.metadata.ageRestrict?.age, "years").isBefore(moment())
        if $scope.question.metadata?.ageRestrict?.olderThan
          return false if !overAge
        else if overAge
          return false

      return true if not $scope.question.condition or not $scope.parentQuestion
      return false if not healthForm.active[$scope.parentQuestion.id]
      if not healthForm.noAnswer[$scope.parentQuestion.id]

        return false if $scope.parentQuestion and not $scope.parentQuestion.answer
        return true if $scope.parentQuestion.answer.value is $scope.question.condition
        return false if not $scope.parentQuestion.answer.value
        return true if $scope.parentQuestion.answer.value.replace(/\n/g, "") is $scope.question.condition.replace(/\n/g, "")

      split = $scope.question.condition.split ":"
      if $scope.conditions[split[0]]
        return $scope.conditions[split[0]](split[1])
      return false

    healthForm.active[$scope.question.id] = $scope.conditionMet()
    $scope.active = healthForm.active[$scope.question.id]

    checkComplete = ->
      #noAnswers = [ "step", "form", "instructions", "allergies", "dimensions",
      #              "emergencyContacts", "immunizations", "insurances", "medications", "primaryProviders" ]
      #noAnswer is true if the question type is in this array

      # complete if the question isnt active
      return true if not healthForm.active[$scope.question.id]
      # complete if question.type is in the modules and the question isnt required
      return true if not healthForm.noAnswer[$scope.question.id] and not $scope.question.required
      # not complete if question.type is in modules but no value for the answer
      return false if not healthForm.noAnswer[$scope.question.id] and not $scope.answer.value
      # not complete if question has relativeExpiration and answer does not contain a relativeExpiration date
      return false if ($scope.question.metadata?.relativeExpiration && !$scope.answer.metadata?.relativeExpiration)
      # not complete if question has intervalExpiration and answer does not contain a relativeExpiration date
      return false if ($scope.question.metadata?.enableIntervalExpiration && !$scope.answer.metadata?.relativeExpiration)
      # Check if an answer is expired
      if $scope.question?.expiration and $scope.answer?.updated
        questionExp = moment(new Date($scope.question.expiration))
        # use either 'relativeExpiration' or 'updated' for the answer expiration
        if $scope.question.metadata?.relativeExpiration
          answerExp = moment(new Date($scope.answer.metadata.relativeExpiration))
        else
          answerExp = moment(new Date($scope.answer.updated))
        return false if questionExp.isAfter(answerExp)
      if $scope.question.metadata?.expirationInterval and $scope.answer.metadata?.relativeExpiration
        if $scope.answer?.metadata.relativeExpiration
          answerExp = moment(new Date($scope.answer?.metadata?.relativeExpiration)).add(parseInt($scope.question.metadata.expirationInterval), "months")
          if new moment().isAfter(answerExp)
            $scope.question.expired = true
          else
            $scope.question.expired = false
          return false if $scope.question.expired
        else
          answerExp = moment(new Date($scope.answer.updated)).add(parseInt($scope.question.metadata.expirationInterval), "months")
          $scope.question.expired = true if new moment().isAfter(answerExp)
          return false if $scope.question.expired

      complete = true
      _.each $scope.question.subQuestions, (sq) ->
        healthForm.checkComplete[sq.id]()
        if not sq.complete then complete = false
      complete

# every question has completeFns array, custom questions usually have 1, modules will push a second.
# question is complete when all functions eval to true
    healthForm.completeFns[$scope.question.id] = [checkComplete]
    healthForm.checkComplete[$scope.question.id] = ->
      $scope.question.complete = _.every healthForm.completeFns[$scope.question.id], (fn) -> fn()
      return

    $scope.parentAnswers = (->
      answers = []
      parent = $scope.parentQuestion
      while parent
        answers.push $scope.answers[parent.id]
        parent = parent.parentQuestion
      answers
    )()

    updateState = _.debounce ->
      healthForm.active[$scope.question.id] = $scope.conditionMet()
      healthForm.checkComplete[$scope.question.id]()
      $scope.active = healthForm.active[$scope.question.id]
    , 200

    updateState()

    if $scope.question.type in ["step", "form"]
      $interval updateState, 500
    else
      $scope.$watch "parentAnswers", (fresh, stale) ->
        if fresh is stale then return
        do updateState
      , true


    # Watchers for module-related follow-ups
    if $scope.question.type in ["medications", "allergies"]
      $scope.$watch "profile.has", (has, stale) ->
        if has isnt stale then do updateState
      , true

    if $scope.question.condition and $scope.question.condition.match(/(?:a|foodA|drugA|envA)llergies/g)
      $scope.$watch "profile.has", (has, stale) ->
        if has isnt stale then do updateState
      , true

    if $scope.question.condition and $scope.question.condition.match(/anaphylaxis|epiPen/g)
      $scope.$watch "profile.allergies", (allergies, stale) ->
        if allergies isnt stale then do updateState
      , true

    if $scope.question.condition and $scope.question.condition.match(/medications/g)
      $scope.$watch "profile.has", (has, stale) ->
        if has isnt stale then do updateState
      , true

    if $scope.question.condition and $scope.question.condition.match(/willTakeMedications/g)
      $scope.$watch "profile.medications", (meds, stale) ->
        if meds isnt stale then do updateState
      , true

    ###
    #   Save Queue
    ###
    saveRate = if $scope.question.type is "upload" then 0 else 300

    saveFn = (nothing, fn=(->)) ->
      healthForm.save("answers", $scope.profile.id, $scope.answer).success (answer) ->
        $scope.answer.id = answer[0].id
        $scope.answer.updated = answer[0].updated
        fn()

    saveQueue = async.queue saveFn, 1

    addSave = _.debounce ->
      saveQueue.push saveFn
    , saveRate

    $scope.$watch "answer", (fresh, stale) ->
      if fresh is stale then return
      updateState()
      if fresh and (_.isString(fresh.value) or $scope.reviewMode) and (fresh.value isnt stale.value) or (!_.isEqual(fresh.metadata, stale.metadata))
        if !$scope.reviewMode
          $scope.profile.reviewFlags ?= []
          $scope.profile.reviewFlags.push $scope.question.id.toString()
          $scope.profile.reviewFlags = _.uniq $scope.profile.reviewFlags
        do addSave
    , true
