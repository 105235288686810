angular.module("dn").directive("newFunkNote", () => {
  return {
    templateUrl: "directives/funk-notes/new-funk-note/new-funk-note.directive.html",
    restrict: 'E',
    scope: {
      organization: '=',
      profile: '=',
      user: '=',
      funkNotes: '=',
    },
    controller($http, $scope, $state, flash, FunkNote) {
      const today = moment();
      const allSessions = [];
      $scope.currentDate = today.format('YYYY-MM-DD');
      $scope.currentSessionFinishDate = '1970-01-01';

      $scope.validDates = {
        start: _.reduce($scope.profile.registrations, (start, r) => {
          if (r.group.phase === 'past') {
            return start;
          } else if (r.group.properties.start < start) {
            // currentSessionFinishDate: Keeps track of the finish date of the earliest current session and if by any remote chance there is not an end date
            // we set the finish date to be a year from the camp starting date
            $scope.currentSessionFinishDate = !r.group.properties.finish ? moment(r.group.properties.start).add(1, 'years').format('YYYY-MM-DD') : r.group.properties.finish;
            // Adds all sessions into an array of objects we can later use to verify the selected date to send a campgram
            allSessions.push({
              start: r.group.properties.start,
              finish: $scope.currentSessionFinishDate
            });
            return r.group.properties.start;
          } else {
            return start;
          }
        }, "9999-99-99"),

        finish: _.reduce($scope.profile.registrations, (finish, r) => {
          if (r.group.phase === 'past') {
            return finish;
          } else if (r.group.properties.finish > finish) {
            return r.group.properties.finish;
          } else {
            return finish;
          }
        }, "1970-01-01")

      };

      if (($scope.organization.properties.restrictFunkNotesDateSent || '').toString() === 'true'
      && (today.isBefore(moment($scope.validDates.start)) || today.isAfter(moment($scope.currentSessionFinishDate)))) {
        $scope.dateBeforeStart = {
          restricted: true,
          title: 'CampGrams Inactive',
          message: `CampGrams can only be sent when the session for ${$scope.profile.givenName} is active.`
        };
      }

      $scope.refreshNewNote = function () {
        let userInfo = ($scope.user.givenName || '') + ' ' + ($scope.user.familyName || '');
        if ($scope.user.email) userInfo += " (" + $scope.user.email + ")";

        let deliverOn = $scope.validDates.start;
        // We cannot use today instead of moment() to check here because it will add an 2 day instead of 1 if the last check passes
        if (moment($scope.validDates.start).isBefore(today) && moment().add(1, 'day').isAfter(moment($scope.currentSessionFinishDate))) {
          deliverOn = today.format('YYYY-MM-DD');
        } else if (moment($scope.validDates.start).isBefore(today)) {
          deliverOn = today.add(1, 'day').format('YYYY-MM-DD');
        }

        $scope.newNote = {
          body: "",
          subject: "",
          deliverOn: deliverOn,
          profileID: $scope.profile.id,
          sentBy: userInfo,
          files: [] // will be converted to string before saving
        };

        // Verifies that the patient is in camp
        $scope.$watch('newNote.deliverOn', (fresh) => {
          $scope.inCamp = false;
          if (fresh) {
            allSessions.map(session => {
              if (moment(fresh).isSameOrAfter(session.start) && moment(fresh).isSameOrBefore(session.finish)) $scope.inCamp = true;
            });
          } else {
            $scope.inCamp = true;
          }
        });
      };

      $scope.createNote = function () {
        $scope.sending = true;

        // converts note files from obj array to string of bar delimited keys
        $scope.newNote.files = $scope.newNote.files.map(file => file.key).join('|');

        let body = {
          noteObj: new FunkNote($scope.newNote)
        };


        $http.post(`/api/profiles/${$scope.profile.id}/funk-notes`, body).then((result) => {
          let resultExists = result && result.data && result.data.remainingTokens;
          $scope.profile.tokens = resultExists ? result.data.remainingTokens : $scope.profile.tokens - 1;
          $scope.funkNotes.push(result.data.note);
          flash("CampGram sent!");
          $state.reload();
        }, () => {
          $scope.sending = false;
          swal({
            type: 'error',
            title: 'Error Sending CampGram',
            text: 'There was an error sending this CampGram. Please refresh the page and try again.'
          });
        });
      };

      $scope.uploadComplete = function (result) {
        $scope.newNote.files.push(result);
      };

      $scope.removeAttachment = function (removed) {
        $scope.newNote.files = $scope.newNote.files.filter(file => file.filename !== removed.name);
      };

      $scope.cost = () => $scope.newNote.files.length + 1;

      $scope.insufficientFunds = () => $scope.profile.tokens < $scope.cost();

      $scope.refreshNewNote();
    }
  };
});
