lib.registerState('manager.settings.general.attendance', {
  url: '/attendance',
  templateUrl: 'states/manager/settings/general/attendance/attendance.state.html',
  controller: function($filter, $scope, group, ContactData, organization, session) {
    $scope.group = group;
    $scope.isOrganization = group.id === organization.id;
    $scope.subGroupProvider = window.lib.isSubGroupProvider(session, organization);
    $scope.showAddType = false;
    $scope.newType = null;
    $scope.editPermission = $filter('permissionVisible')({ settings_attendance: 'edit' });

    const flashMessages = {
      types: {
        add: (item) => `'${item}' Added`,
        remove: (item) => `'${item}' Removed`,
      },
      universalContactEnabled: (enabled) => `Universal Contact ${enabled}`,
      bypassModeEnabled: (enabled) => `Bypass Mode ${enabled}`,
    };

    //Sets a default mainly for Subgroups that don't have features
    $scope.group.properties.features = $scope.group.properties.features || {};

    //Sets default value for attendance if it doesn't exist
    $scope.group.properties.features.attendance = $scope.group.properties.features.attendance || {
      enabled: false,
      universalContactEnabled: false,
      requirePhoto: false,
      types: []
    };

    // This is just for easier access to the properties
    const attendanceSettings = $scope.group.properties.features.attendance;

    $scope.ui = {
      loading: false,
      attendanceTypes: {
        expanded: false
      },
      universalContact: {
        showDetails: attendanceSettings.bypassModeEnabled || attendanceSettings.universalContactEnabled,
        expanded: false,
        // After save, reinitialize the tmp contact to avoid any errors during further edits
        afterContactSave: initializeContactEditor
      }
    };

    //Allows the buttonSwitch to switch from yes and no
    $scope.yesNo = [
      { label: 'No', value: false },
      { label: 'Yes', value: true }
    ];

    $scope.handleUCDetails = function(settingName) {
      if (attendanceSettings.universalContactEnabled || attendanceSettings.bypassModeEnabled) {
        getUniversalContact().then(() => $scope.ui.universalContact.showDetails = true);
      } else {
        $scope.ui.universalContact.showDetails = false;
        $scope.ui.universalContact.expanded = false;
      }
      // Don't try to save settings that don't exist
      if (attendanceSettings[settingName] !== undefined) {
        const verb = attendanceSettings[settingName] ? 'Enabled' : 'Disabled';
        saveSettings(flashMessages[settingName](verb));
      }
    };

    $scope.clearInput = function () {
      $scope.showAddType = false;
      $scope.newType = null;
    };

    //Handles the interactivity when clicking the cancel button
    $scope.showAddTypeHandler = () => $scope.showAddType = !$scope.showAddType;

    $scope.add = function(item) {
      const typesLowerCase = $scope.group.properties.features.attendance.types.map(type => type.toLowerCase());

      // /^\s*$/ = checks if string contains any whitespace
      if (item === null || /^\s*$/.test(item)) return emptyInputSwal();
      if (typesLowerCase.includes(item.toLowerCase())) return sameNameSwal(item);
      // .replace(/ +(?= )/g, '') = replaces any extra whitespace with only one
      if (item.replace(/ +(?= )/g, '').toLowerCase() === ('check in' || 'check out')) return blockReservedTypes(item);
      $scope.group.properties.features.attendance.types.push(item);
      $scope.group.properties.features.attendance.types.sort();
      return saveSettings(flashMessages.types.add(item));
    };

    $scope.remove = function(item) {
      $scope.group.properties.features.attendance.types = $scope.group.properties.features.attendance.types.filter(type => type !== item);
      return saveSettings(flashMessages.types.remove(item));
    };

    // Clear the contact store when we leave this state so we don't carry the UC to other parts
    // of the app
    const clearUCListener = $scope.$on('$stateChangeStart', () => {
      ContactData.clearStore();
      clearUCListener();
    });

    getUniversalContact();

    function errorSwal({ title, text }) {
      return window.swal({
        type: 'warning',
        title: title || 'Something Went Wrong',
        text: text || 'Please reload the page and try again.'
      });
    };

    function emptyInputSwal() {
      return window.swal({
        type: 'warning',
        title: 'Input is empty',
        text: 'Please add a new type before hitting save'
      });
    };

    function sameNameSwal(item) {
      return window.swal({
        type: 'warning',
        title: 'Item Already Exists',
        text: `'${item}' already exists`
      });
    };

    function blockReservedTypes(item) {
      return window.swal({
        type: 'warning',
        title: 'Reserved types are not allowed',
        text: `${item} is a reserved type and cannot be added`
      });
    };

    function generateDefaultContact() {
      const generalContact = (organization.properties.contacts || {}).general || {};
      return {
        group_id: organization.id,
        name: organization.name,
        relationship: 'Organization',
        emails: {
          primary_value: generalContact.email,
          primary_type: 'work'
        },
        phones: {
          primary_value: generalContact.phone,
          primary_type: 'office',
        },
        address: (organization.properties.location || {}).mailing || {}
      };
    };

    function initializeContactEditor() {
      const contact = ContactData.universalContact || generateDefaultContact();
      $scope.contactToEdit = ContactData.initTmpContact(contact);
    }

    function getUniversalContact() {
      // If it's _not_ undefined, we've already tried to grab it. Return that instead.
      if (ContactData.universalContact !== undefined) {
        return Promise.resolve(initializeContactEditor());
      }
      // When applicable, load the org's UC
      if (
        attendanceSettings.enabled
        && (attendanceSettings.universalContactEnabled || attendanceSettings.bypassModeEnabled)
      ) {
        $scope.ui.loading = true;
        return ContactData.loadOrgContact()
          .then(initializeContactEditor)
          .catch(() => errorSwal({
            title: 'Failed to Load Universal Contact',
            text: 'If you need to edit the Universal Contact, please reload and try again'
          }))
          .finally(() => $scope.ui.loading = false);
      }
    }

    function saveSettings(message) {
      if (!message) log.error('[saveSettings] No message provided for success flash.');
      return $scope.group.save(['properties'])
        .then(() => {
          flash(message);
        })
        .catch((err) => {
          errorSwal();
          log.error(err);
        });
    }

  }
});
