lib.registerState "manager.profiles.profile.account",
  url: "/account"
  templateUrl: "states/manager/profiles/profile/account/profile-account.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Account"

    users: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/users").then (result) ->
        return result.data

    paymentMethods: (profile, organization, $http) ->
      $http.get("/api/profiles/#{profile.id}/payment-methods").then (result) ->
        return window.lib.methodFilterRestriction(
          result.data,
          organization,
          {
            value: 0,
            label: "New Payment Method",
            meta: {
              properties: {
                providerPermission: true
              }
            }
          }
        )

    accountText: (organization) ->
      organization.properties.branding.text.patient.account if organization.properties?.branding?.text?.patient?.account?

    lineItemPresets: (organization) ->
      _.map organization.properties.lineItemPresets, (p) ->
        return {label: p, value: p}

    crebits: ($http, profile) ->
      organizeCrebits = (crebits) ->
        # Filters, sorts, and organizes crebits
          # Could definitely be optimized to not use two separate var definitions, possibly 0 with one good-enough lodash chain
          # Not feeling that now though
        # non-org ledger crebits in the API should already be filtered out by the SQL in the API call
        # function is defined and called here so the ordering persists through to the line-item state
        # orders crebits by date descending

        sorted = _(crebits)
          .sortBy((c) -> new Date(c.created).getTime())
          .reverse()
          .value()

        organized = []

        _.each sorted, (c) ->
          if !c.adjusts then organized.push c
          _.each _.filter(sorted, { adjusts: c.id }), (adj) ->
            # parent object is attached to use in the line-item state
            # might be unnecessary now because parent is also assigned in line-item state
            adj.parent = c
            organized.push adj

        return organized

      $http.get("/api/profiles/#{profile.id}/account?attributedTo&attributions").then (result) ->
        return organizeCrebits(result.data)
      .catch (err) ->
        return flash "Error Loading Line Items"

    orgFeeSettings: ($http, organization) ->
      ppfFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees')
      if (ppfFlag)
        $http.get("/api/organization/#{organization.id}/finance/org-fee-settings")
          .then (({ data }) -> data)
          .catch (error) ->
            if (error.status is 404)
              return false
            else
              return flash "Error Loading Convenience Fee Settings"
      else return { feePassedOn: false}

  controller: ($filter, $http, $scope, $state, Crebit, PayProcessor, accountText, crebits, flash, lineItemPresets, organization, paymentMethods, profile, users, orgFeeSettings) ->
    lineItemViewFlag = window.lib.featureFlagClient.isEnabled('GLCodesPriority2')
    $scope.showNewAttributionProcess = lineItemViewFlag
    $scope.isGlCodesP2FlagEnabled = lineItemViewFlag
    $scope.ppfFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees')
    $scope.feesPassedOn = orgFeeSettings.feePassedOn
    $scope.cardRates = {
      'flat': orgFeeSettings.cardFlatFee
      'variable': orgFeeSettings.cardVariableRate
    }
    $scope.bankRates = {
      'flat': orgFeeSettings.bankFlatFee,
      'variable': orgFeeSettings.bankVariableRate
    }
    $scope.convenienceFee = 0
    $scope.totalPayment = 0
    $scope.planMonthlyWithFee = 0
    $scope.totalAmountWithFee = 0

    $scope.showManualTransfer =
      $scope.isGlCodesP2FlagEnabled &&
      window.lib.featureFlagClient.isEnabled('manualTransfer')

    $scope.showAmountInput = () ->
      showLegacyPaymentField = !$scope.feesPassedOn && $scope.lineItem.type is 'payment'
      showLegactRefundField = !$scope.showNewAttributionProcess && $scope.lineItem.type is 'refund'
      return showLegacyPaymentField or showLegactRefundField

    $scope.startNewAttributionProcess = (lineItemType) ->
      return if $scope.submitting
      $state.go('manager.profiles.profile.account.attributionProcess', {
        crebitID: null,
        lineItemType,
        balance: $scope.balance,
        paymentMethods: $scope.chargeableMethods,
        organization,
        profileCrebits: crebits
        profileGivenName: profile.givenName,
      })

    # if profile is linked to a test user, we don't want anyone adding crebits or whatnot
    $scope.testProfile = _.some users, (u) ->
      return _.includes(u.options, '"testAccount"=>"true"') && !u.deactivated

    # paymentMethods: base object for manipulation, no longer used in html
    $scope.paymentMethods = paymentMethods

    # chargeableMethods: user-permitted methods with the 'New Method' option
    $scope.chargeableMethods = _.filter paymentMethods, (pm) ->
      (pm.meta?.properties?.providerPermission is true) or (pm.meta?.properties?.providerPermission is "1")

    # planMethods: user-permitted methods without the 'New Method' option
    $scope.planMethods = $scope.chargeableMethods.slice 1

    # called from line-item state to update tooltip color, text
    $scope.assignScopeCrebits = (assignmentCrebits) ->
      $scope.crebits = _.map assignmentCrebits, (c) ->

        if window.lib.crebitAttributable(c) && c.attributingTo.length
          c.attributingAmount = _.reduce c.attributingTo, (sum, attr) ->
            sum += Math.abs(attr.amount)
            return sum
          , 0

          if Math.abs c.attributingAmount >= Math.abs c.amount
            c.totallyAttributed = true
          else
            c.totallyAttributed = null
        return c

    $scope.assignScopeCrebits(crebits)

    $scope.crebitSummary ?= []
    $scope.registrations ?= []
    $scope.accountText = accountText

    calculateBalance = (crebits) ->
      _.reduce crebits, (sum, c) ->
        sum + c.amount
      , 0
    $scope.balance = calculateBalance(crebits)

    $scope.paymentPlan = $scope.profile?.paymentPlans?[0]

    $scope.submitting = false

    $scope.lineItemPresets = lineItemPresets
    $scope.lineItem =
      editing: false
      type: do ->
        return "misc" if !$scope.balance
        return "payment" if !PayProcessor.features.refundAsNewCharge
        if $scope.balance > 0 then "payment" else "refund"
      payOrRefund: if $scope.balance > 0 then -1 else 1
      newPaymentMethod: {}
      extMethod: "Cash"
      whichPM: do ->
        return "0" if !PayProcessor.features.savedPaymentMethods

    $scope.allowSavedPaymentMethods = PayProcessor.features.savedPaymentMethods
    $scope.showPaymentPlanSetupButton = !$scope.organization.properties.disablePaymentPlans &&
      !$scope.paymentPlan &&
      $scope.balance > 0 &&
      PayProcessor.features.paymentPlans

    $scope.plan =
      profileID: $scope.profile.id
      totalAmount: $scope.balance
      remaining: 3
      day: _.random(1, 28)

    # Function to set the payment method type on the scope based on the methodID
    setPaymentType = () ->
      if $scope.paymentPlan
        paymentMethodID = $scope.paymentPlan.paymentMethodID
      else if $scope.plan
        paymentMethodID = $scope.plan.paymentMethodID

      [ paymentMethodUsed ] = $scope.paymentMethods.filter((method) -> parseInt(paymentMethodID) == method.value)

      if (paymentMethodUsed?.meta.type == 'Savings' || paymentMethodUsed?.meta.type == 'Checking')
        $scope.paymentType = 'bank'
      else
        $scope.paymentType = 'card'

    # Variable to track if there is a difference in the first month's fee vs the rest of the months' fees.
    # If so, show remainderWithFee.
    $scope.hasDiff = false;
    $scope.remainderWithFee = null;

    # Checks if the fee is the same for the first month's new amount, when a remainder is added
    checkFeeWithRemainder = () ->
      if !$scope.feesPassedOn
        return

      if $scope.paymentPlan
        remainder = $scope.planRemainder
        monthlyPayment = Math.floor($scope.paymentPlan?.totalAmount / $scope.paymentPlan?.remaining)
      else
        remainder = $scope.remainder
        monthlyPayment = Math.floor($scope.plan.totalAmount / $scope.plan.remaining)

      setPaymentType()

      convFee0 = calculateConvenienceFee(monthlyPayment, $scope.paymentType, $scope.feesPassedOn)
      convFee1 = calculateConvenienceFee((monthlyPayment + remainder), $scope.paymentType, $scope.feesPassedOn)

      if convFee0 != convFee1
        $scope.hasDiff = true

        delta = Math.abs(convFee0 - convFee1)
        $scope.remainderWithFee = remainder + delta

      else
        $scope.hasDiff = false

      return


    # Sets remainder and monthly payment for new payment plan
    $scope.$watch('plan', (plan) ->
      $scope.remainder = window.dnim.calculateRemainder(plan.totalAmount, plan.remaining)
      if !$scope.plan.paymentMethodID
        $scope.monthlyPayment = 0
      else
        $scope.monthlyPayment = (Math.floor(plan.totalAmount / plan.remaining) / 100).toFixed(2)

    , true)

    $scope.firstMonth = false

    # Sets remainder and monthly payment for existing payment plan
    $scope.$watch('paymentPlan', (paymentPlan) ->
      $scope.planRemainder = window.dnim.calculateRemainder($scope.paymentPlan?.totalAmount, $scope.paymentPlan?.remaining)
      if (($scope.paymentPlan?.amount * $scope.paymentPlan?.remaining) + $scope.planRemainder) == $scope.paymentPlan?.totalAmount
        $scope.firstMonth = true

      checkFeeWithRemainder()
    , true)

    externalDescription = (balance) ->
      if !balance then return "Transaction"
      if balance > 0 then return "Payment"
      else return "Refund"

    # Subtabs to display when adding a new line item
    $scope.lineItemTabs = [
      { label: "Payment", value: "payment" },
      { label: "Refund", value: "refund" },
      { label: "External #{externalDescription($scope.balance)}", value: "external" },
      { label: "Donation", value: "donation" },
      { label: "Miscellaneous", value: "misc" }
    ].filter (tab) ->
      if tab.value == "refund"
        return PayProcessor.features.refundAsNewCharge
      else
        return true

    customPrint = ->
      registrations = []
      $http.get("/api/profiles/#{profile.id}/registrations?area=profileaccount").then (regs) ->
        if regs and regs.data and regs.data.length
          _.each regs.data, (reg) ->
            if reg.deactivated is null
              registrations.push {
                name: reg.group.name
                start: reg.group.compiledProperties.start
                finish: reg.group.compiledProperties.finish
              }
        $scope.registrations = _.sortBy registrations, (reg) -> 1 / new Date(reg.start)

        # pass those shown crebits (i.e., crebit._hide !== false) to the crebit-summary route, that
        # we might generate a summary of filtered crebits
        crebitIDs = _.reduce $scope.crebits, (accArr, c) ->
          if !c._hide then accArr.push(c.id)
          accArr
        , []

        # pass crebitIDs to crebit-summary
        $http.get("/api/profiles/#{profile.id}/crebit-summary?crebitIDs=#{crebitIDs}").then (res) ->
          $scope.crebitSummary = _.orderBy res.data, ['quantity', 'amount'], ['desc', 'desc']  if res.data and res.data.length
          $scope.quantityCrebitSummary = _($scope.crebitSummary).map((c) -> parseInt(c.quantity)).sum()
          $scope.amountCrebitSummary = _($scope.crebitSummary).map((c) -> parseInt(c.amount)).sum() / 100
          $scope.limit = null
          $scope.$apply()
          return $scope._print()

    # Configurate the options shown from the ledger-filter directive
    $scope.ledgerConfig = {
        export: true,
        exportUrl: "/api/profiles/#{$scope.profile.id}/crebits.csv?ledger=organization",
        print: true,
        printFn: customPrint,
        dates: true
      }

    $scope.planReady = ->
      return unless $scope.planSetup
      if $scope.feesPassedOn
        return false unless $scope.convenienceFee >= 0
      return false unless $scope.plan
      return false unless $scope.plan.paymentMethodID
      return false unless $scope.plan.totalAmount
      return false unless $scope.plan.day
      return false unless $scope.plan.remaining
      true


    $scope.createPlan = (plan) ->
      plan.starts = $scope.firstPayment plan.day
      plan.amount = plan.totalAmount / plan.remaining
      plan.paymentMethodID = +plan.paymentMethodID
      url = "/api/profiles/#{$scope.profile.id}/provider-payment-plan"
      $http.post(url, plan).then (result) ->
        $scope.planSetup = false
        flash "Payment plan created"
        $scope.paymentPlan = result.data
        sendNotification("paymentplan-created", "PaymentPlan", $scope.paymentPlan)

    $scope.deacPlan = ->
      window.swal
        title: "Deactivate Plan?"
        text: "Are you sure you wish to deactivate #{$scope.profile.givenName}'s payment plan?"
        type: "warning"
        showCancelButton: true
        closeOnConfirm: false
        confirmButtonText: "Deactivate"
      , (isConfirm) ->
        if isConfirm
          url = "/api/profiles/#{$scope.profile.id}/payment-plan"
          $http.delete(url).then (result) ->
            if result.status is 200
              window.swal
                type: "success"
                title: "Plan Deactivated"
                timer: 2000
              sendNotification("paymentplan-deactivated", "PaymentPlan", $scope.paymentPlan)
              delete $scope.paymentPlan
              # strange, not always replicable bug where if you add a misc line item right after
              # deactivating a plan, on the lineItem's state reload it will display the plan again
              # run a state reload after deactivating to fix
              $state.reload()


    # Initiate a manual transfer for testing purposes
    $scope.manualTransfer = () ->
      url = "/api/profiles/#{$scope.profile.id}/manual-transfer"
      return $http.post(url).then () ->
        $state.reload()
      .catch (err) ->
        console.error(err)
        flash "Error Running Manual Transfer"

    $scope.firstPayment = (day) ->
      return null unless day
      proposed = moment().date(day)
      if proposed > moment()
        return proposed.format('YYYY-MM-DD')
      proposed.add(1, 'month').format 'YYYY-MM-DD'

    $scope.planWillEnd = (plan) ->
      moment($scope.firstPayment(plan.day))
        .add(plan.remaining - 1, 'months').format 'YYYY-MM-DD'

    $scope.canSubmit = (lineItem) ->
      return false if $scope.submitting
      if lineItem.type is "misc"
        return !!(lineItem.miscAmount and lineItem.description)
      if lineItem.type is "external"
        personAndAmount = !!(lineItem.extPayerName and lineItem.amount)
        digits = lineItem.extMethod is "Cash" or (lineItem.extMethod is "Card" && !!lineItem.extLastfour) or (lineItem.extMethod is "Check" && !!lineItem.extCheckNumber)
        return personAndAmount and digits
      if lineItem.type is "payment"
        if $scope.feesPassedOn
          cardFlatFeeZero = $scope.cardRates.flat is 0
          cardVariableRateZero = $scope.cardRates.variable is 0
          bankFlatFeeZero = $scope.bankRates.flat is 0
          bankVariableRateZero = $scope.bankRates.variable is 0
          # if all the rates are 0, enable submit button
          if $scope.paymentType is "card" and cardFlatFeeZero and cardVariableRateZero and lineItem.amount
            return true
          else if $scope.paymentType is "bank" and bankFlatFeeZero and bankVariableRateZero and lineItem.amount
            return true
          else return (lineItem.newPaymentMethod or lineItem.whichPM isnt "0") and lineItem.amount and $scope.convenienceFee
        return false unless lineItem.whichPM
        return (lineItem.newPaymentMethod or lineItem.whichPM isnt "0") and lineItem.amount
      if lineItem.type is "refund"
        return false unless lineItem.whichPM
        return false if !lineItem.amount
        return lineItem.newPaymentMethod or lineItem.whichPM isnt "0"
      if lineItem.type is "donation"
        return lineItem.amount
      false

    addLineItem = (lineItem, fn) ->
      crebit = new Crebit
        profileID: $scope.profile.id
        ledger: "organization"

      if lineItem.type is "donation"
        crebit.amount = lineItem.amount
        crebit.description = "[DONATION] to #{organization.name}"
        crebit.donation = true
        return crebit.save().then fn

      if lineItem.type is "misc"
        crebit.amount = lineItem.miscAmount
        crebit.description = "[MISC] #{lineItem.description}"
        return crebit.save().then fn

      if lineItem.type is "external"
        crebit.amount = lineItem.amount * lineItem.payOrRefund
        crebit.description = """[EXTERNAL-#{if lineItem.payOrRefund is -1 then "PAYMENT" else "REFUND"}]"""
        crebit.description += " #{lineItem.extPayerName} (#{lineItem.extMethod}"
        crebit.description += " ##{lineItem.extCheckNumber}" if lineItem.extMethod is "Check"
        crebit.description += " -#{lineItem.extLastfour}" if lineItem.extMethod is "Card"
        crebit.description += ")"
        return crebit.save().then fn

      if lineItem.type is "payment"
        crebit.amount = lineItem.amount * -1
        if lineItem.whichPM is "0"
          crebit.paymentMethod = lineItem.newPaymentMethod
          crebit.description = "[PAYMENT] #{crebit.paymentMethod.type} -#{crebit.paymentMethod.lastfour}"
        else
          crebit.paymentMethodID = lineItem.whichPM
          m = _.find paymentMethods, (pm) ->
            pm.meta and pm.meta.id is parseInt lineItem.whichPM
          crebit.description = "[PAYMENT] #{m.meta.type} -#{m.meta.lastfour}"
        return crebit.save().then(
          ((success)-> return fn(success)),
          ((error) ->
            if (error && error.data == window.dnim.constants.errors.CARD_NOT_ACCEPTED)
              window.swal(window.lib.cardNotAcceptedSwalConfig(organization, error.data))
            else
              errText = 'Please check the payment method credentials and try again'
              if (error && error.data)
                errText += "\nReason: " + error.data
              window.swal "Payment Failed", errText, "error"
            $scope.submitting = false)
        )

      if lineItem.type is "refund"
        crebit.amount = lineItem.amount
        if lineItem.whichPM is "0"
          crebit.paymentMethod = lineItem.newPaymentMethod
          crebit.description = "[REFUND] #{crebit.paymentMethod.type} -#{crebit.paymentMethod.lastfour}"
        else
          crebit.paymentMethodID = lineItem.whichPM
          m = _.find paymentMethods, (pm) ->
            pm.meta and pm.meta.id is parseInt lineItem.whichPM
          crebit.description = "[REFUND] #{m.meta.type} -#{m.meta.lastfour}"
        return crebit.save().then(
          ((success) -> return fn(success)),
          ((error) ->
            if (error && error.data == window.dnim.constants.errors.CARD_NOT_ACCEPTED)
              window.swal(window.lib.cardNotAcceptedSwalConfig(organization, error.data))
            else
              window.swal "Refund Failed", "Please check the payment method and try again.", "error"
            $scope.submitting = false)
        )
      fn()

    submitNewCrebit = (lineItem, type="Line Item") ->
      $scope.submitting = true
      addLineItem lineItem, (result) ->
        if result and result.id
          $scope.lineItem.editing = false
          $scope.$parent.getBalance()
          $state.reload("manager.profiles.profile.account")
          $scope.submitting = false
          flash "#{type} Added"
        else
          flash "There was an error adding the line item."

    $scope.submit = (lineItem) ->
      return if $scope.submitting
      # Check whether we're making a misc or refund transaction; these two features
      # get a confirmation SWAL.
      if lineItem.type == 'misc' || lineItem.type == 'refund'
        crebitAmount = if (lineItem.type == 'misc') then 'miscAmount' else 'amount'
        amount = (lineItem[crebitAmount]/100).toFixed(2).replace("-", "")
        balance = (($scope.balance + lineItem[crebitAmount])/100).toFixed(2)
        # This gets the SWAL's innards to come out correctly for each case: refunds, charges, and payments.
        # The last two, charges and payments, are used by the misc payments/charges feature.
        refundOrPayment = if(lineItem.type == 'refund') then 'refund' else if (lineItem[crebitAmount] > 0) then 'charge' else 'payment'
        lineTitle = "Confirm #{refundOrPayment.capitalize()}"

        window.swal
          html: true
          title: lineTitle
          type: "info"
          text: """
            <p>Add a <b>#{$filter('currency')(amount)} #{refundOrPayment}</b> to #{profile.givenName}'s balance?</p>
            <br>
            <p>The remaining balance will be <b>#{$filter('currency')(balance)}</b>.</p>
          """
          confirmButtonText: "Add Line Item"
          showCancelButton: true
        , (isConfirmed) ->
          submitNewCrebit lineItem if isConfirmed
      # Handle all other transactions that aren't either misc or refunds.
      else
        submitNewCrebit lineItem, "Transaction"

    sendNotification = (type, model, options={}) ->
      body = {}
      body.notificationType = type
      body.model = model
      body.organization = $scope.organization.name
      body.options = options
      url = "/api/profiles/#{$scope.profile.id}/send-notification"
      $http.post(url, body)

    # Data for pagination of line items
    $scope.currentPage = 0
    $scope.limit = 10

    setPages = (crebits) ->
      $scope.pages = _.times(Math.ceil(crebits.length / $scope.limit), Number)
    setPages($scope.crebits)

    $scope.$watch "crebits", (fresh) ->
      filteredCrebits = _.filter fresh, (c) -> c._hide == false
      setPages(filteredCrebits)
    , true

    # Determines if the payment type is card or bank based on the payment method ID or new payment method
    determinePaymentType = (paymentMethodID, newPaymentMethod) ->
      if paymentMethodID is "0" and newPaymentMethod
        return newPaymentMethod.category
      else selectedMethod = paymentMethods.filter (pm) ->
        return pm.value is parseInt paymentMethodID
      return selectedMethod[0]?.meta?.category

    # Calculates the convenience fee for a given amount and payment type
    calculateConvenienceFee = (amount, paymentType, feesPassedOn) ->
      try
        if feesPassedOn
          if paymentType is "card"
            if ($scope.cardRates.flat isnt 0 and !$scope.cardRates.flat) or ($scope.cardRates.variable isnt 0 and !$scope.cardRates.variable)
              throw new Error "Convenience fees are not set for card transactions"
            cardFlatFee = Math.abs $scope.cardRates.flat
            cardVariableRate = Number($scope.cardRates.variable) / 100
            cardVariableFee = Math.ceil amount * cardVariableRate
          else if paymentType is "bank"
            if ($scope.bankRates.flat isnt 0 and !$scope.bankRates.flat) or ($scope.bankRates.variable isnt 0 and !$scope.bankRates.variable)
              throw new Error "Convenience fees are not set for bank transactions"
            bankFlatFee = Math.abs $scope.bankRates.flat
            bankVariableRate = Number($scope.bankRates.variable) / 100
            bankVariableFee = Math.ceil amount * bankVariableRate

          if cardFlatFee or cardVariableFee
            return cardFlatFee + cardVariableFee
          else if bankFlatFee or bankVariableFee
            return bankFlatFee + bankVariableFee
        return 0
      catch error
        window.swal "Error Calculating Fees", error.message, "error"

    # Watches for changes in the payment method for payment line item to determine the payment type
    $scope.$watch "lineItem.whichPM", () ->
      if $scope.lineItem.editing
        if $scope.lineItem.whichPM isnt "0"
          $scope.paymentType = determinePaymentType $scope.lineItem.whichPM, $scope.lineItem.newPaymentMethod
        else if $scope.lineItem.newPaymentMethod
          $scope.paymentType = determinePaymentType $scope.lineItem.whichPM, $scope.lineItem.newPaymentMethod
        else if $scope.lineItem.whichPM is "0" and !$scope.lineItem.newPaymentMethod
          $scope.paymentType = null

    # Watches for changes in the payment-method component when changing the payment method while creating a new method
    $scope.$watch "lineItem.newPaymentMethod", () ->
      if $scope.lineItem.newPaymentMethod
        $scope.paymentType = determinePaymentType $scope.lineItem.whichPM, $scope.lineItem.newPaymentMethod
      else
        $scope.paymentType = null

    # If the amount is changed for a line item payment, recalculate the fee and total
    $scope.$watch "lineItem.amount", () ->
      if $scope.feesPassedOn and $scope.lineItem.editing
        if !$scope.lineItem.amount
          $scope.convenienceFee = 0
          $scope.totalPayment = 0
        else setFeeAndTotal $scope.lineItem.amount

    # If the payment type is changed, recalculate the fee and total
    $scope.$watch "paymentType", () ->
      if $scope.lineItem.editing and $scope.lineItem.amount
        setFeeAndTotal $scope.lineItem.amount
      else if !$scope.lineItem.editing and $scope.plan.totalAmount
        setMonthlyWithFee()

    # Resets fee calculations when editing line items is toggled
    $scope.$watch "lineItem.editing", () ->
      resetFeeAndTotal()

    # Resets fee calculations when editing line items type is changed
    $scope.$watch "lineItem.type", () ->
      resetFeeAndTotal()

    resetFeeAndTotal = () ->
      if $scope.feesPassedOn
        $scope.convenienceFee = 0
        $scope.totalPayment = 0
        $scope.totalAmount = 0
        $scope.paymentType = null
        if $scope.isGlCodesP2FlagEnabled
          $scope.lineItem.amount = null
        else
          $scope.lineItem.amount = NaN

        if !PayProcessor.features.savedPaymentMethods
          $scope.lineItem.whichPM = "0"
        else
          $scope.lineItem.whichPM = null

    # Sets the convenience fee and total payment for a given amount
    setFeeAndTotal = (amount) ->
      if $scope.feesPassedOn
        if !amount
          amount = 0
        $scope.convenienceFee = calculateConvenienceFee amount, $scope.paymentType, $scope.feesPassedOn
        if $scope.convenienceFee
          $scope.totalPayment = amount + $scope.convenienceFee
          $scope.totalAmount = $scope.totalPayment * $scope.plan.remaining
        else
          $scope.totalPayment = amount

    # For payment plans we need to calculate the monthly payment with the fee
    setMonthlyWithFee = () ->
      if $scope.feesPassedOn and $scope.planSetup
        monthlyConverted = Number($scope.monthlyPayment) * 100
        setFeeAndTotal monthlyConverted
        if $scope.convenienceFee
          $scope.planMonthlyWithFee = ($scope.totalPayment / 100).toFixed(2)
          $scope.totalAmountWithFee = ($scope.totalAmount / 100).toFixed(2)
        else
          $scope.planMonthlyWithFee = $scope.monthlyPayment
      checkFeeWithRemainder()

    # If the total is changed, recaculate the monthly payment with the fee
    $scope.$watch "plan.totalAmount", () ->
      if $scope.plan.paymentMethodID
        setMonthlyWithFee()

    # If the remaining months are changed, recalculate the monthly payment with the fee
    $scope.$watch "plan.remaining", () ->
      if $scope.plan.remaining and $scope.plan.paymentMethodID and $scope.plan.totalAmount
        if !$scope.paymentType
          $scope.paymentType = determinePaymentType $scope.plan.paymentMethodID, null
        setMonthlyWithFee()

    # If the payment method is changed, recalculate the monthly payment with the fee
    $scope.$watch "plan.paymentMethodID", () ->
      $scope.paymentType = determinePaymentType $scope.plan.paymentMethodID, null
      if $scope.plan.totalAmount
        setMonthlyWithFee()

    # If the plan setup is opened and already filled out, reset the payment type to recalculate the monthly payment
    $scope.$watch "planSetup", () ->
      if $scope.planSetup and $scope.plan.totalAmount and $scope.plan.paymentMethodID and $scope.plan.remaining
        $scope.paymentType = determinePaymentType $scope.plan.paymentMethodID, null

    # If there is an existing payment plan, this block will make sure the correct amount displays
    if ($scope.paymentPlan && $scope.feesPassedOn)
      setPaymentType()

      monthlyPayment = $scope.paymentPlan.amount

      convenienceFee = calculateConvenienceFee(monthlyPayment, $scope.paymentType, $scope.feesPassedOn)
      $scope.planMonthlyWithFee = (monthlyPayment + convenienceFee) / 100
