_ = require("lodash") if require?
_ = window._ if window?._?

getTerms = (search) ->
  search = window.dnim.utils.defaultCoffeeParam(search, '')
  search = search.toLowerCase()
  search = search.replace /(|\)|\(|\\|\/)/g, ""
  search.split(' ')

matchText = (value, term, log) ->
  value = value.toString().toLowerCase()
  term = term.toLowerCase()
  value.match term

matchItem = (item, terms) ->
  # this conditional is meant to capture the data structure of the 2017-18 collections rewrite
  if item.profiles
    item = item.profiles
  values = _.filter _.values(item), (value) -> value?
  _.every terms, (term) -> _.some values, (value) -> matchText value, term

runFilter = (items, search) ->
  # Safari seems to run this before the data to be passed in resolves.
  # Early returning to prevent that.
  if !items then return
  terms = getTerms search
  if _.isArray items
    return _.filter items, (item) -> matchItem item, terms
  matchItem items, terms

if angular?.module?
  angular.module("dn").filter "textWhitespace", -> runFilter
else if module?.exports?
  module.exports = runFilter
