lib.registerState "manager.healthLog.browse.entry",
  url: "/:entryID"
  templateUrl: "states/manager/health-log/browse/entry/entry.state.html"
  resolve:
    entry: ($http, $stateParams, logEntries, organization) ->
      url = "/api/organizations/#{organization.id}/log-entries"
      filter = "[logEntries].id(is:#{$stateParams.entryID})"
      $http.get("#{url}?filters=#{filter}").then (result) ->
        [collData] = result.data
        entry = collData.logEntries
        entry.profile = collData.profiles
        entry.medName = collData.medications?.name
        return entry
    profileEntries: ($http, entry) ->
      $http.get("/api/profiles/#{entry.patientID}/log-entries").then (result) ->
        result.data

  controller: ($cacheFactory, $http, $scope, $state, $filter, entry, organization, profileEntries, providers, session, LogEntry) ->

    #
    # Init
    #

    $scope.$parent.assignProvider(entry)
    entry.creator = profileEntries.find((pe) -> pe.id == entry.id).creator

    $scope.entry = _.cloneDeep entry
    $scope.entryInHistory = _.cloneDeep entry

    $scope.entry.show = true
    $scope.entry.fullPage = true

    $scope.originalAuthor = session.userID == $scope.entry.userID
    $scope.timezone = organization.properties.timezone

    # check if user has edit permissions to update the entry
    $scope.showUpdateComponents = (entry) ->
      if (entry.draft)
        return true
      if (entry.logType == 'general-health')
        if $filter('permissionVisible')({ health_log: 'edit' })
          return false
        else
          return true
      if (entry.logType == 'behavioral-health')
        if $filter('permissionVisible')({ behavioral_health: 'edit' })
          return false
        else
          return true

    #
    # History
    #

    # Entries that link to this one or its parents
    $scope.previous = []
    parent = profileEntries.find((pe) -> entry.parentID == pe.id)
    while parent
      $scope.$parent.assignProvider(parent)
      $scope.previous.push parent
      parent = profileEntries.find((pe) -> parent.parentID == pe.id)

    # Find entries that have been linked from this one

    # TODO - Figure out a better way of mapping
    # and displaying linked visits in AP-1545
    [seed] = profileEntries
      .filter((pe) -> entry.id == pe.parentID)
      .sort((a, b) -> a.id - b.id)
    child = seed

    $scope.laterEntries = []
    while child
      $scope.$parent.assignProvider(child)
      $scope.laterEntries.push child
      child = profileEntries.find((pe) -> pe.parentID == child.id)
    $scope.laterEntries = $scope.laterEntries.reverse()

    # Gather IDs for printing
    $scope.ids = []
    _.map $scope.laterEntries, (e) -> $scope.ids.push e.id
    $scope.ids.push $scope.entry.id
    _.map $scope.previous, (e) -> $scope.ids.push e.id


    if organization.properties.customDispositions
      $scope.dispositionOptions = organization.properties.customDispositions
    else
      $scope.dispositionOptions = [
        "Admitted"
        "Ambulance"
        "Discharged"
        "Emergency Department"
        "Home"
        "Observed"
        "Urgent Care"
      ]

    $scope.newDisposition = $scope.entry.disposition

    #
    # Addendums
    #
    createAddendum = ->
      $scope.addendum =
        content: ""
        userID: session.userID
        providerID: $scope.provider?.id
        image: {}

    do createAddendum

    $scope.updateDisposition = (newDisposition) ->
      $scope.addendum.content = "Disposition updated from \"#{$scope.entry.disposition}\" to \"#{newDisposition}\""
      $scope.addendum.disposition =
        from: $scope.entry.disposition
        to: newDisposition
      $scope.entry.disposition = newDisposition
      $scope.saveAddendum($scope.addendum)


    $scope.saveAddendum = (addendum) ->
      addendum.timestamp = moment().format("MM/DD/YYYY hh:mm a")
      addendum.name = $scope.provider?.name
      # We need to load the entry first in case
      # an addendum has been saved in another tab
      new LogEntry($scope.entry).load().then (loadedEntry) ->
        loadedEntry.addendum.push addendum
        loadedEntry.disposition = $scope.entry.disposition
        stringEntry = _.cloneDeep loadedEntry
        stringEntry.addendum = JSON.stringify stringEntry.addendum
        new LogEntry(stringEntry).save().then (entry) ->
          $scope.entry = entry
          flash "Entry Updated!"
        if addendum.review then return
        createAddendum()
      .catch (err) ->
        flash "There was an error adding the addendum"

    #
    # Review
    #
    $scope.reviewed = ->
      if !$scope.provider then return true
      _.some $scope.entry.addendum, (add) ->
        add.review is true and add.providerID is $scope.provider.id

    $scope.review =
      content: "Reviewed by #{$scope.provider?.name}"
      userID: session.userID
      providerID: $scope.provider?.id
      review: true

    $scope.saveReview = ->
      $scope.saveAddendum $scope.review

    $scope.newVisit = ->
      return $state.go "manager.healthLog.create"

    $scope.linkedVisit = ->
      entry =
        patientID: $scope.entry.patientID
        groupID: $scope.organization.id
        providerID: $scope.provider?.id
        parentID: $scope.entry.id
        complaint: $scope.entry.complaint
        draft: true
      new LogEntry(entry).save().then (entry) ->
        return $state.go "manager.healthLog.create", {patientID:entry.patientID, entryID: entry.id, linkedVisit: true}

    $scope.uploadComplete = (result) ->
      if result.key
        $scope.addendum.image = result
      else
        errorSwal
          title: "Error Uploading File"
          type: "error"
          text: """
            There was an error uploading your file.

            Error: #{result.err}
          """

    $scope.clearUpload = ->
      delete $scope.addendum.image

    $scope.getImage = (image) ->
      # I was dumb and didn't include the key in the original save response
      # so all addendum uploads prior to this commit will not have a .key property.
      # this has now been changed, so all new addendums will have a .key available.
      key = image.key or (image.url.match /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g)[0]

      Images.getForProfile($scope.entry.patientID, key).then (urls) ->
        window.open urls[0]

    #
    # Mark As Error
    #
    $scope.markAsError = ->
      # session user must be author of the entry to delete it
      if !$scope.originalAuthor
        errorSwal
          title: "Incorrect User"
          text: "You must be the original author to mark this entry as an error."
      else
        swal
          title: "Mark Log Entry as Error?"
          type: "warning"
          showCancelButton: true
          confirmButtonText: "Confirm"
          closeOnConfirm: false
          text: "Click confirm to mark as an error. This cannot be undone."
        , () ->
          $http.delete("/api/profiles/#{$scope.entry.profile.id}/log-entries/#{$scope.entry.id}?markAsError=true").then(
            (result) ->
              # delete entry from $scope.logEntries
              entryID = $scope.logEntries.findIndex (e) -> e.id == $scope.entry.id
              $scope.logEntries.splice entryID, 1

              swal
                title: "Success!"
                text: "Successfully marked as an error."
                type: "success"
              , -> $scope.goBack()

            (err) ->
              errorSwal
                title: "Something Went Wrong"
                text: "Something went wrong during your request."
              , -> $scope.goBack
          )

    #
    # Helper fns
    #

    # swal wrapper for errors: optional callback. Add a wrapper for successes if you
    # add another success swal (and replace the currently singular success swal)
    errorSwal = (config, cb) ->
      config.type = "error"
      if cb
        swal config, cb()
      else
        swal config

    #
    # Tracking Board Link
    #
    $scope.goBack = ->
      return $state.go('manager.healthLog.tracking') if $state and $state.previous and $state.previous.name is 'manager.healthLog.tracking'
      return $state.go('^')
