const Resource = angular.injector(['dn']).get('Resource');

angular.module('dn').factory('UniversalContact', function ($http, $rootScope) {

  const ERR_TAG = '[UniversalContact]';

  const UniversalContact = new Resource({
    route: '/api/organizations/:group_id/universal-contact/:id',
    schema: {
      group_id:     { type: 'integer', required: true },
      name:         { type: 'text',    required: true },
      relationship: { type: 'text',    required: true },
      emails:       { type: 'json',    required: true },
      phones:       { type: 'json',    required: true },
      address:      { type: 'json',    required: true },
      picture:      { type: 'text' },
      pin:          { type: 'text' },
    }
  });

  UniversalContact.loadOrgContact = function() {
    const FN_TAG = '[loadOrgContact]';
    // Handle missing org
    if (!$rootScope.organization || !$rootScope.organization.id) {
      log.error(Error(`${ERR_TAG}${FN_TAG} Missing organization ID on $rootScope.`));
      return Promise.resolve(null);
    }
    // Give the people what they want!
    return $http.get(`/api/organizations/${$rootScope.organization.id}/universal-contact`)
      .then((response) => {
        // If successful, from BE or cached, return an instantiated copy
        if (response.status === 200 || response.status === 304) {
          return new UniversalContact(response.data);
        // If successful but no data, return null
        } else if (response.status === 204) {
          return null;
        // Otherwise, we don't know how to act. Throw an error.
        } else {
          // We have to do this madness instead of `throw`-ing because AngularJS promises ($q)
          // behave differently than native promises :(
          const err = new Error(`${ERR_TAG}${FN_TAG} Unknown response type: ${JSON.stringify(response)}`);
          return Promise.reject(err);
        }
      })
      .catch((err) => {
        const baseMsg = `${ERR_TAG}${FN_TAG} Error loading Universal Contact:`;
        if (err && err.message && !err.message.match(/^\[/)) {
          const newMsg = `${baseMsg} ${err.message}`;
          err.message = newMsg;
        } else if (err && err.message && err.message.match(/^\[/)) {
          // Intentionally do nothing; it's already tagged
        } else if (err && err.statusText) {
          err = `${baseMsg} ${err.statusText}`;
        }
        log.error(err);
        return null;
      });
  };

  return UniversalContact;
});
