displayTime = 3000

angular.module("dn").factory "flash", ($timeout) ->
  flash = (text) -> flash.queue.push { text: text }

  flash.showMessage = (message, fn) ->
    flash.message = message
    flash.message.display = true

    $timeout ->
      flash.message.display = false
      fn()
    , displayTime - 500

  flash.queue = async.queue flash.showMessage, 1
  flash.message = {}
  window.flash = flash
  flash


angular.module("dn").directive "flash", ($interval) ->
  templateUrl: "directives/flash/flash.directive.html"
  replace: true
  restrict: "E"
  controller: ($scope, flash) ->
    $scope.message = {}
    $interval ->
      if $scope.message is flash.message then return
      $scope.message = flash.message
    , 100
