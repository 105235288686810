angular.module("dn").directive("qtEncrypted", function() {
  return {
    restrict: "A",
    templateUrl: "directives/taker/modules/encrypted/qt-encrypted.directive.html",
    scope: {
      question: "=qtEncrypted"
    },
    controller: function($scope, $stateParams, $filter, HealthForm) {

      var profileID = $stateParams.profileID;
      var healthForm = HealthForm.get(profileID);

      $scope.validate = $scope.question.required ? "required" : "";
      if ( $scope.question.metadata.number ) { $scope.validate += "|number" }

      healthForm.completeFns[$scope.question.id].push(() => {
        // complete if not required or if answer has a value
        return !$scope.question.required || !!$scope.question.answer.value;
      })

      $scope.existingAnswer = !!$scope.question.answer.value;
      $scope.answer = { profileID: +profileID, questionID: $scope.question.id };
      if ($scope.question.answer.id) {
        _.defaultsDeep($scope.answer, $scope.question.answer);
      }

      $scope.permissionDisabled = !$filter('permissionVisible')({ profile_encrypted_answers: 'edit' });

      $scope.toolTipText = $scope.permissionDisabled ? "You do not have permission to edit this." : null;

      $scope.saveAnswer = function(clearAnswer) {
        if (clearAnswer) { $scope.answer.value = null; }
        $scope.savingEncryptedQuestion = true

        return healthForm.save("encrypted", profileID, $scope.answer).success(function(savedAnswers) {
          $scope.savingEncryptedQuestion = null
          $scope.existingAnswer = !!savedAnswers[0].value
          $scope.answer = _.cloneDeep(savedAnswers[0]);
          $scope.question.answer = _.cloneDeep(savedAnswers[0]);
          healthForm.checkComplete[$scope.question.id]();
        });
      }

    }
  };
});
