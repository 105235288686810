angular.module('dn').directive('reportsList', function () {
  return {
    // We can use ng-include to dynamically set our layout type
    templateUrl: 'directives/reports-ui/reports-list.directive.html',
    restrict: 'E',
    scope: {
      organization: '=',
      tab: '=',
      reportType: '@'
    },
    controller: function ($http, $scope) {
      $scope.requesting = false;
      $scope.deleting = false;
      $scope.reports = [];
      $scope.deleteTargets = [];
      const orgID = $scope.organization.id;
      const orgTZ = $scope.organization.properties.timezone;
      const sizePostfix = ["b", "kb", "mb", "gb"];
      let loaded = false;

      $scope.generate = (function(type) {
        switch (type) {
          case 'funk-notes-reports':
            return { link: 'generate', text: 'generate' };
          default:
            return { link: 'report-wizard', text: 'reports' };
        }
      })($scope.reportType);

      // Data for pagination of line items
      $scope.currentPage = 0;
      $scope.limit = 8;
      $scope.updatePages = function() {
        $scope.pages = _.times(Math.ceil($scope.reports.length / $scope.limit), Number);
        if ($scope.currentPage > $scope.pages[$scope.pages.length - 1]) {
          return $scope.changePage($scope.pages.length - 1);
        }
      }

      function fileSizer(size, i) {
        // Loose equality to check `undefined` and `null`
        if (i == null) i = 0;

        // If we have found a good size or reach the last postfix, return.
        if (((size / 1024) < 1) || i >= (sizePostfix.length - 1)) {
          return `${size.toFixed(2)} ${sizePostfix[i]}`
        }

        // Otherwise, reduce the size, increment i, and recurse
        let reduced = size / 1024;
        i++;
        return fileSizer(reduced, i);
      }

      $scope.confirmDeleteReportMultiple = () => {
        if (!$scope.deleteTargets.length) return;
        $scope.deleting = true;
        return swal({
          type: "warning",
          title: "Are You Sure?",
          text: `
            This will delete the <b>${$scope.deleteTargets.length} selected reports</b> and cannot be undone.
          `,
          html: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          closeOnConfirm: true
        }, (confirmed) => {
          if (!confirmed) return $scope.deleting = false;
          const reportKeys = $scope.deleteTargets.join("|");
          return $http.delete(`/api/organizations/${$scope.organization.id}/${$scope.reportType}/${encodeURIComponent(reportKeys)}`).then((success) => {
            flash("Reports Deleted");
            // Clear out the targets array
            $scope.deleteTargets.length = 0;
            // The next fn will set $scope.requesting false on finish, so we
            // don't need to do so if we're successful
            $scope.getReports();
            $scope.deleting = false;
          }).catch((err) => {
            $scope.deleting = false;
            flash("Error Deleting Report - Please Try Again");
          });
        });
      };

      // Fetch new reports
      $scope.getReports = function() {
        // Used to disable button during refresh request
        $scope.requesting = true;
        $http.get(`/api/organizations/${orgID}/${$scope.reportType}`).then((success) => {
          loaded = true;
          // Format dates, title, and file size for easier display
          const processed = success.data.map((report) => {
            report.title = (report.title || '').replace(`${orgID}/`, '');
            report.requested = moment.tz(report.requested, orgTZ).format('MMM Do, h:mma');
            // FIXME: This needs to be changed to the actual value instead of a mock date
            report.expires = moment.tz(report.completed, orgTZ).add(14, 'd').format('MMM Do, h:mma');
            report.size = fileSizer(+report.size);
            $scope.currentPage = 0;
            return report;
          });
          $scope.requesting = false;
          $scope.reports = processed;
          $scope.updatePages();

        }, (error) => {
          $scope.requesting = false;
          return flash("Error Loading Reports");
        });
      }

      // Auto-populate the list on first load of tab, but not before or after
      $scope.$watch('tab', (fresh, stale) => {
        if (fresh === stale) return;
        if (fresh === 'downloads' && !loaded) return $scope.getReports();
      })
    }
  }
});
