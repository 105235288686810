angular.module("dn").directive "qtMedication", ->
  restrict: "A"
  templateUrl: "directives/taker/modules/medications/qt-medication.directive.html"
  scope:
    _status: "=status"
    fdaMeds: "="
    isNewMed: "@"
    medication: "=qtMedication"
    mode: "@"
    profile: "="
    question: "="
    strengths: "="

  controller: ($scope, WhatDivision, $timeout) ->
    $scope.medication.mode = "view"

    # Init
    $scope.division = WhatDivision
    $scope.form = {}
    $scope.copiedMed = _.cloneDeep $scope.medication

    defaultDays   = window.lib.meds.defaultDays()
    findFrequency = window.lib.meds.findFrequency

    if $scope.medication.doseValue
      $scope.medication.doseValue = parseFloat $scope.medication.doseValue
      if (_.isNaN($scope.medication.doseValue)) then $scope.medication.doseValue = ""

    delete $scope.medication.schedule

    $scope.nextStart = _.min(_.map $scope.profile.registrations, (r) -> return r.group.compiledProperties.start)

    # Form actions
    $scope.edit = -> $scope.medication.mode = "edit"
    $scope.cancel = ->
      $scope.medication = _.assign $scope.medication, _.cloneDeep($scope.copiedMed)
      $scope.medication.mode = "view"
      if $scope.isNewMed then $scope.$parent.cancelNew()

    $scope.save = ->
      $scope.medication.mode = "view"

      # Ensure that specific dates are appropriately cleared prior to saving.
      clearSpecificDates()

      # Sanity check the medication prior to saving, clean out any rogue values.
      if $scope.medication.frequency != "schedule"
        $scope.medication.daysTaken = window.lib.meds.defaultDays()

      if $scope.medication.frequency != "interval"
        $scope.medication.interval = 1

      $scope.$parent.save()

    $scope.confirmDelete = (med) ->
      $scope._status.delete = med.id

    $scope.cancelRemove = ->
      $scope._status.delete = null

    $scope.addNew = ->
      $scope.$parent.addMedication()

    $scope.frequencyChange = ->
      $scope.medication.daysTaken = window.lib.meds.defaultDays()
      $scope.medication.interval = 1

    # Form choices
    $scope.takeChoices = [
      { label: "Yes", value: true }
      { label: "No", value: false }
    ]

    $scope.doseTypes = [
      { value: "application", label: "Application(s)" },
      { value: "capful", label: "Capful(s)" },
      { value: "capsule", label: "Capsule(s)" },
      { value: "drop", label: "Drop(s)" },
      { value: "eardrop", label: "Ear Drop(s)" },
      { value: "eyedrop", label: "Eye Drop(s)" },
      { value: "inhalation", label: "Inhalation(s)" },
      { value: "injection", label: "Injection(s)" },
      { value: "milligram", label: "Milligram(s) (mg)" },
      { value: "milliliter", label: "Milliliter(s) (mL)" },
      { value: "other", label: "Other" },
      { value: "packet", label: "Packet(s)" },
      { value: "patch", label: "Patch(s)" },
      { value: "pill", label: "Pill(s)" },
      { value: "spray", label: "Spray(s)" },
      { value: "suppository", label: "Suppository" },
      { value: "tablespoon", label: "Tablespoon(s)" },
      { value: "tablet", label: "Tablet(s)" },
      { value: "teaspoon", label: "Teaspoon(s)" },
      { value: "unit", label: "Unit(s)" }
    ]

    $scope.frequencyChoices = window.lib.meds.frequencyChoices

    $scope.dateChoices = [
      { label: "Continuously", value: false },
      { label: "Specific Dates", value: true }
    ]

    $scope.booleanChoices = [
      { label: "Yes", value: true },
      { label: "No", value: false }
    ]

    # Completion functions
    $scope.complete = ->
      required = [
        "name", "strength", "doseValue", "doseType",
        "indication", "willTake", "timesTaken", "daysTaken"
      ]
      basicReq = _.every required, (r) ->
        if r in ["timesTaken", "daysTaken"] then return _.some $scope.medication[r]
        _.isPresent $scope.medication[r]
      if !$scope.$parent.expanded then return basicReq
      expandedReq = _.every ["emergency", "canSelfAdminister"], (r) ->
        _.isPresent $scope.medication[r]
      return basicReq and expandedReq

    $scope.medication.complete = $scope.complete()
    if not $scope.medication.complete
      $timeout -> $scope.medication.mode = "edit"

    # Handle time displays
    $scope.defaultTimes = $scope.$parent.defaultTimes

    # create arr of times where defaultTimes[time] is true
    $scope.times = _.reduce $scope.defaultTimes, (result, val, key) ->
      if val then result.push key
      result
    , []


    if !$scope.medication.daysTaken
      $scope.medication.daysTaken = window.lib.meds.defaultDays()

    $scope.days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

    $scope.prettyDaysTaken = []
    $scope.prettyDaysTaken = _.filter (_.keys $scope.medication.daysTaken), (d) ->
      $scope.medication.daysTaken[d]

    $scope.prettyDaysTaken = $scope.prettyDaysTaken.join(", ")
    $scope.allTimes = _.keys $scope.defaultTimes

    hasSpecificDates = (medication) ->
      return '0' if !medication.start && !medication.finish
      return '1'

    clearSpecificDates = ->
      if !parseInt($scope.specificDates)
        $scope.medication.start  = null
        $scope.medication.finish = null

    $scope.medication.frequency = findFrequency($scope.medication)
    $scope.specificDates        = hasSpecificDates($scope.medication)

    # populate times taken
    $scope.timesTaken = []
    $scope.$watch "medication", (fresh, stale) ->
      do ->
        if not $scope.medication.doseValue then return

        d = $scope.medication.doseValue.toString()
        if d.length > 1 and d.charAt(d.length - 1) isnt "."
          $scope.medication.doseValue = parseFloat($scope.medication.doseValue)
        if (_.isNaN($scope.medication.doseValue)) then $scope.medication.doseValue = ""

      $scope.timesTaken = _.filter(_.keys($scope.medication.timesTaken), (k) ->
        $scope.medication.timesTaken[k]
      ).join(', ')

      $scope.hasTimes = _.some $scope.medication.timesTaken
      $scope.hasDays = _.some $scope.medication.daysTaken

      # Handle set start date for interval meds to next registration start unless specificDates
      clearSpecificDates()
    , true
