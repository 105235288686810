Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "ReviewType", ($http) ->

  ReviewType = new Resource
    route: "/api/groups/:groupID/review-types/:id"
    schema:
      name: { type: "text", required: true }
      sticky: { type: "boolean" }
      groupID: { type: "number" }

  ReviewType
