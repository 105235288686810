angular.module("dn").directive "materialInput", ($interval, $compile) ->
  templateUrl: "directives/input/material-input.directive.html"
  restrict: "A"
  scope:
    accept: "@"
    action: "@"
    ajax: "@"
    autocomplete: "@"
    autocompleteSuggestions: "="
    ccpos: "@"
    change: "&"
    choices: "="
    choiceWidth: "@"
    clearOnNull: "="
    createChoices: "@"
    completeFn: "="
    debounce: "@"
    disabled: "@"
    disableUploads: "="
    existingFiles: "="
    formattedOnly: "="
    hideValidate: "@"
    icon: "@"
    iconPrefix: "@"
    iconHint: "@"
    id: "@"
    instructions: "@"
    label: "@"
    materialInput: "@"
    negative: "&"
    ngDisabled: "="
    ngReadonly: "="
    maxFiles: "="
    onEnter: "="
    outerModel: "=model"
    pdfSplit: "@"
    placeholder: "@"
    readonly: "@"
    removeFn: "="
    rows: "@"
    searchProgress: "="
    showAt: "="
    showCharCount: "@"
    submitText: "@"
    template: "@"
    tooltip: "@"
    tooltipIcon: "@"
    tpPosition: "@tppos" # I cannot understand how to make this work better, so it's tppos
    type: "@"
    validateWith: "@"
    viewLink: "@"

  link: (scope, elm, attrs) ->
    controls = elm.find(".controls")
    type = scope.type or scope.materialInput or "text"
    # Cleaner than doin a {{autocomplete || 'off'}} in the templates themselves
    scope.autocomplete ?= 'off'
    if type is 'text' and scope.validateWith
      validateKeys = scope.validateWith.split('|')
      type = 'number' if 'number' in validateKeys
      type = 'tel' if 'phone' in validateKeys
      type = 'email' if 'email' in validateKeys

    scope.debounce ?= "300"
    if type in ["email", "password"] then scope.debounce = "0"

    scope.modelOptions =
      updateOn: "default blur"
      debounce:
        default: parseInt(scope.debounce)
        blur: 0


    types =
      location: '
        <div location="input.model" ng-disabled="isDisabled()" formatted-only="formattedOnly"></div>
      '
      textarea: '
        <textarea
          ng-model="input.model"
          clear-validators
          novalidate
          style="line-height: 24px; padding-top:4px; margin-bottom:-2px;"
          ng-model-options="modelOptions"
          ng-disabled="isDisabled()"
          ng-readonly="isReadonly()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          rows="{{rows || 2}}"
          ng-attr-placeholder="{{::placeholder}}"
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}}"
          aria-label="{{label||placeholder}}"
        ></textarea>
      '
      mde: '
        <markdown-editor
          model="input.model"
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}}"
          ng-attr-placeholder="{{label||placeholder}}"
        ></markdown-editor>
      '
      radio: '
        <label class="radio" ng-repeat="choice in ::choices track by $index" ng-if="choice.show !== false && !choice.hide">
          <input type="radio" ng-value="choice.value" ng-disabled="ngDisabled" ng-model="input.model">
          <i aria-hidden="true" class="{{choice.iconPrefix || \'fas\'}} fa-fw fa-{{::choice.icon}}" ng-if="choice.icon"></i>
          <span ng-class="{ \'sr-only\' : !choice.label }">
            {{choice.label||choice.placeholder}}
          </span>
        </label>
      '
      checkbox: '
        <label class="checkbox checked-{{input.model}}">
          <input type="checkbox" ng-model="input.model" ng-disabled="isDisabled()">
          <span ng-class="{ \'sr-only\' : !label }">
            {{label||placeholder}}
          </span>
        </label>
      '
      select: '
        <selector placeholder="{{placeholder||label | limitTo : 100 : 0}}"
                  choices="choices"
                  ng-disabled="ngDisabled"
                  model="input.model"
                  ajax="{{ajax}}">
        </selector>
      '
      tags: '
        <tag-selector
              placeholder="{{placeholder||label}}"
              choices="choices"
              create-choices="{{createChoices}}"
              model="input.model"
              ng-disabled="ngDisabled">
        </tag-selector>
      '
      date: '
        <date
          model="input.model"
          min="{{validator(\'gte\')}}"
          max="{{validator(\'lte\')}}"
          placeholder="::placeholder"
          clear-on-null="::clearOnNull"
          ng-disabled="ngDisabled">
        </date>
      '
      relativeDate: '
        <relative-date
          model="input.model"
          placeholder="::placeholder"
          ng-disabled="ngDisabled"
        ></relative-date>
      '
      money: '<money model="input.model" icon="{{icon}}" negative="negative()" placeholder="::placeholder" ng-disabled="isDisabled()"></money>'
      time: '<time model="input.model" ng-disabled="ngDisabled" placeholder="::placeholder"></time>'
      # Allows us to use date-time validation for our collection queries
      # You can pass an empty string or an ISO string to the model and it will return a new ISO string date
      # min and max must be an ISO string for validation
      # You can validate using gte-date-time and lte-date-time on the validate-with prop
      dateTime: '
        <date-time
          model="input.model"
          ng-disabled="ngDisabled"
        ></date-time>
      '
      upload: '
        <upload model="input.model"
                complete-fn="completeFn"
                remove-fn="removeFn"
                ng-disabled="isDisabled()"
                disable-uploads="disableUploads"
                action="{{ action }}"
                submit-text="{{submitText}}"
                accept="{{accept}}"
                instructions="{{ instructions }}"
                view-link="{{viewLink}}"
                existing-files="existingFiles"
                id="{{id}}"
                template="{{template}}"
                pdf-split="{{pdfSplit}}"
                max-files="maxFiles"
                class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}}">
        </upload>
      '
      number: '
        <input
          ng-model="input.model"
          clear-validators
          novalidate
          type="number"
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}} icon-indent-{{!!icon}}"
          ng-disabled="isDisabled()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          ng-attr-placeholder="{{placeholder}}"
          ng-attr-autocomplete="{{autocomplete}}"
          aria-label="{{label||placeholder}}"
        >
      '
      tel: '
        <input
          ng-model="input.model"
          clear-validators
          novalidate
          type="tel"
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}} icon-indent-{{!!icon}}"
          ng-disabled="isDisabled()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          ng-attr-placeholder="{{placeholder}}"
          ng-attr-autocomplete="{{autocomplete}}"
          aria-label="{{label||placeholder}}"
        >
      '
      # LastPass icons cover our "Show Password" Icon, but we can make it so
      # they don't appear w/ data-lpignore. This doesn't disable LastPass itself.
      # For more info, see: https://lastpass.com/support.php?cmd=showfaq&id=10512
      password: '
        <input
          ng-model="input.model"
          novalidate
          name="password"
          type="password"
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}} icon-indent-{{!!icon}}"
          ng-disabled="isDisabled()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          ng-attr-placeholder="{{placeholder}}"
          ng-attr-autocomplete="{{autocomplete}}"
          data-lpignore="true"
          aria-label="{{label||placeholder}}"
        >
      '
      text: '
        <input
          ng-model="input.model"
          clear-validators
          novalidate
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}} icon-indent-{{!!icon}}"
          type="text"
          ng-model-options="modelOptions"
          ng-disabled="isDisabled()"
          ng-readonly="isReadonly()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          ng-attr-placeholder="{{placeholder}}"
          ng-attr-autocomplete="{{autocomplete}}"
          aria-label="{{label||placeholder}}"
        >
      '
      untrimmedText: '
        <input
          ng-model="input.model"
          clear-validators
          novalidate
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}} icon-indent-{{!!icon}}"
          type="text"
          ng-model-options="modelOptions"
          ng-disabled="isDisabled()"
          ng-readonly="isReadonly()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          ng-attr-placeholder="{{placeholder}}"
          ng-attr-autocomplete="{{autocomplete}}"
          aria-label="{{label||placeholder}}"
          ng-trim="false"
        >
      '
      email: '
        <input
          ng-model="input.model"
          clear-validators
          novalidate
          class="invalid-{{isInvalid}} required-{{isRequired}} has-value-{{hasValue()}} icon-indent-{{!!icon}}"
          name="email"
          type="email"
          ng-model-options="modelOptions"
          ng-disabled="isDisabled()"
          ng-readonly="isReadonly()"
          ng-focus="setFocus(true)"
          ng-blur="setFocus(false)"
          ng-attr-placeholder="{{placeholder}}"
          ng-attr-autocomplete="{{autocomplete}}"
          aria-label="{{label||placeholder}}"
        >
      '
      buttonSwitch: '
        <button-switch
          model="input.model"
          ng-disabled="isDisabled()"
          choices="choices"
          choice-width="choiceWidth"
          class="require-{{isRequired}}">
        <button-switch>
      '
    if !types[type] then type = "text"


    # Bind showPassword function for password inputs
    # The material-input div MUST have an ID for this to work.
    setEventListener = () ->
      # Cache input selector for use and reuse, but only if we're going to need it
      $input = undefined
      if scope.onEnter || scope.type == "password"
        $input = elm.find('input')

      # if onEnter fn provided
      if scope.onEnter
        # bind it
        $input.bind "keyup", (e) ->
          if e.keyCode == 13
            scope.onEnter()

      if scope.type == "password"
        # Used to toggle the title attr on the show pw btn
        scope.showingPassword = false
        scope.capsLockOn = false
        # Cache selectors for clarity/readability
        $showPasswordBtn = elm.find('.show-password-icon')
        $showPasswordIcon = elm.find('.show-password-icon i')

        # Caps Lock Magic
        $input[0].addEventListener "keydown", (e) ->
          # Form fills emit keydown Events too, but they're not KeyboardEvents
          # so they don't have a getModifierState fn and we get console errors
          if e instanceof KeyboardEvent
            scope.capsLockOn = e.getModifierState('CapsLock')

        # Show Password Magic
        $showPasswordBtn.on "click", (e) ->
          scope.showingPassword = !scope.showingPassword
          if $showPasswordIcon.hasClass 'fa-eye-slash'
            $input[0].type = 'text'
            $showPasswordIcon.removeClass 'fa-eye-slash'
            $showPasswordIcon.addClass 'fa-eye'
          else
            $input[0].type = 'password'
            $showPasswordIcon.addClass 'fa-eye-slash'
            $showPasswordIcon.removeClass 'fa-eye'

    # compile template from above
    controls.append($compile(types[type])(scope))
    # and add password input event listener: this must run
    # after the template is compiled, or else there'll be nothing
    # to add the event to
    setTimeout(setEventListener, 0)



  controller: ($scope, $timeout) ->
    $scope.showAt ?= 1000
    $scope.type ?= $scope.materialInput or "text"


    $scope.$watch "validateWith", (fresh, stale) ->
      $scope.required = $scope.validateWith?.match "required"
      $scope.isRequired = !!$scope.required
      validatorArray = $scope.validateWith?.split("|")
      $scope.mlRule = _.find(validatorArray, (v) ->
        return v.split(":")[0] == 'max-length'
      )
      # Maintain consistency with line 327
      return if fresh is stale or _.isUndefined fresh
      checkValidation $scope.input.model
    , true

    filterAutocompleteSuggestions = ->
      $scope.filteredAutocompleteSuggestions = _.filter $scope.autocompleteSuggestions, (a) ->
        return true unless $scope.outerModel
        a.match new RegExp($scope.outerModel.replace(/\(|\)/g, ""), "ig")

    filterAutocompleteSuggestions()

    # Used for date inputs to utilize validate-with dates to set max and min values
    $scope.validator = (key) ->
      return null unless $scope.validateWith
      rule = _.find $scope.validateWith.split("|"), (rule) -> rule.match key
      return null unless rule
      rule.split(":")[1]

    $scope.input =
      type: $scope.type or $scope.materialInput or "text"
      model: $scope.outerModel

    $scope.focus = _.includes ["radio", "date"],  $scope.input.type

    $scope.setFocus = (value) ->
      filterAutocompleteSuggestions()
      $scope.focus = value

    $scope.isInvalid = false
    $scope.validationErrors = []

    $scope.isDisabled = -> $scope.disabled?.toString() is "true" or $scope.ngDisabled
    $scope.isReadonly = -> $scope.readonly?.toString() is "true" or $scope.ngReadonly

    $scope.hasValue = (model) ->
      model = model or $scope.input.model
      if $scope.input.type is "checkbox" and !model then return false
      if $scope.input.type is "upload" and model? and model.hasOwnProperty('length') and model.length is 0 then return false
      typeof model isnt "undefined" and model isnt null and model isnt ""

    validationChecks = ->
      validateWith = $scope.validateWith?.split("|") or []
      validateWith.push $scope.type if $scope.type
      validateWith.push $scope.materialInput if $scope.materialInput
      validateWith

    resetValidity = ->
      # $scope.isInvalid = false
      # $scope.validationErrors = []

    markValidation = _.debounce(((errors) ->
      unless errors?.length
        $scope.isInvalid = false
        $scope.validationErrors = []
        return
      $scope.isInvalid = true
      $scope.validationErrors = errors
      $scope.$apply()
    ), 500)

    checkValidation = (model) ->
      feedback = lib.validate model, validationChecks()
      errors = feedback[0]; model = feedback[1]
      $scope.input.model = model if $scope.input.model isnt model
      $scope.outerModel = if errors?.length then undefined else model
      $timeout -> $scope.$apply -> $scope.change()
      markValidation errors

    $scope.$watch "outerModel", (fresh, stale) ->
      return if fresh is stale or _.isUndefined fresh
      $scope.input.model = $scope.outerModel
      filterAutocompleteSuggestions() if $scope.filteredAutocompleteSuggestions

    $scope.$watch "input.model", (fresh, stale) ->
      # Show character count
      charCountTypes = ["text", "textarea", "mde"]
      if $scope.showCharCount == "true" && charCountTypes.includes($scope.type)
        currentCount = if $scope.input.model then $scope.input.model.length else 0
        if $scope.mlRule
          maxLength = +$scope.mlRule.split(":")[1]
          $scope.charCount = maxLength - currentCount
        else
          $scope.charCount = currentCount

      # The only time `fresh` can ever be `stale` is on the first $digest
      # The stuff above does run on the first $digest, the stuff below doesn't

      return if fresh is stale or _.isUndefined fresh
      resetValidity()
      checkValidation fresh

    $scope.setModelFromAuto = ($event, option) ->
      $timeout -> $scope.input.model = option
