lib.registerState("manager.groups.roster", {
  url: "/roster",
  templateUrl: "states/manager/groups/roster/roster.state.html",
  resolve: {
    title: function($rootScope) {
      $rootScope._title = "Roster / Registrations";
    },

    registrations: function($stateParams, $state, $http) {
      return $http.get("/api/organizations/" + $stateParams.orgID + "/roster/registrations").then(
        function(success) { return success.data; },
        function(err) {
          window.swal({
            title: "Page Error",
            type: "warning",
            text: "There was an error loading registration data.\n\nPlease reload and try again. If this error continues to occur, please contact our support team with the contents of this message and the URL of this page.",
            confirmButtonText: "Reload"
          }, function(isConfirm) {
            return $state.reload();
          });
        }
      );
    }
  },
  controller: function($filter, $http, $scope, $state, organization, registrations) {
    if (!$scope.organization) $scope.organization = organization;

    /* Populates dropdown options for `Show All` selector */
    $scope.rosterChoices = [
      { label: "Registrations", value: "registration", optgroup: "General" },
      { label: "Waitlist",      value: "waitlist", optgroup: "General" },
      { label: "AddOns",        value: "addOn", optgroup: "General" },
      { label: "Coupons",       value: "coupon", optgroup: "General" },
      { label: "AddOns",        value: "shared-add", optgroup: "Shared" },
      { label: "Coupons",       value: "shared-coup", optgroup: "Shared" }
    ]

    /* Populates dropdown options for sorting page */
    $scope.generalSortChoices = [
      { label: "Name", value: "name" },
      { label: "Start Date", value: "startDate" },
      { label: "Finish Date", value: "finishDate" },
      { label: "Registration Count (Low to High)", value: "lowToHighReg" },
      { label: "Registration Count (High to Low)", value: "highToLowReg" },
      { label: "Waitlist Count (Low to High)", value: "lowToHighWait" },
      { label: "Waitlist Count (High to Low)", value: "highToLowWait" },
      { label: "Add-On Count (Low to High)", value: "lowToHighAddOn" },
      { label: "Add-On Count (High to Low)", value: "highToLowAddOn" },
      { label: "Coupon Count (Low to High)", value: "lowToHighCoupon" },
      { label: "Coupon Count (High to Low)", value: "highToLowCoupon" }
    ]

    $scope.sharedSortChoices = [
      { label: "Name", value: "name" },
      { label: "Count (Low to High)", value: "lowToHighShared" },
      { label: "Count (High to Low)", value: "highToLowShared" }
    ]

    var sharedAddOns = {};
    var sharedCoupons = {};
    $scope.sharedItems = {};
    $scope.groupLimit = 5;

    $scope.addToLimit = function(increase) {
      $scope.groupLimit += increase;
      /* Freshly-loaded groups won't have their rosters changed. Rerun to assign
      Timeout is necessary because otherwise changeRosters would run before the new groups are loaded into the HTML and have no effect */
      setTimeout(function() {
        $scope.changeRosters($scope.globalRoster);
      }, 100);
    }

    /* Set the limit to null, rerun changeRosters */
    $scope.limitDoesNotExist = function() {
      $scope.groupLimit = null;
      setTimeout(function() {
        $scope.changeRosters($scope.globalRoster);
      }, 100);
    }

    $scope.resetLimit = function() {
      $scope.groupLimit = 5;
      setTimeout(function() {
        $scope.changeRosters($scope.globalRoster);
      }, 100);
    }

    /* Flatten the org's group structure for easy access later on */
    var flattenedGroups = {};
    /* Since we start with the org's children, and some addOns/coupons can be set at the org level, artificially shove the org into the array */
    flattenedGroups[$scope.organization.id] = {
      name: $scope.organization.name,
      chain: $scope.organization.name,
      phase: $scope.organization.phase,
      properties: {
        start: $scope.organization.properties.start,
        finish: $scope.organization.properties.finish
      }
    };

    // This object is used to look up the ID of groups with shortNames so we
    // can sort by display order.
    const groupsWithShortNames = {};
    if ($scope.organization.shortName) groupsWithShortNames[$scope.organization.shortName] = $scope.organization.id;

    // Flatten the groups!
    (function flattenator(groupArray) {
      _.map(groupArray, function(g) {
        g.chain = g.chain ? g.chain + " » " + g.name : g.name;
        // Populate groupsWithShortNames
        if (g.shortName) groupsWithShortNames[g.shortName] = g.id;
        flattenedGroups[g.id] = g;
        _.map(g.children, function(child) {
          calculateCapacity(child);
          child.chain = g.chain;
          /* Compiled properties can kiss my ass */
          if (!child.properties.start) child.properties.start = g.properties.start;
          if (!child.properties.finish) child.properties.finish = g.properties.finish;
        });
        flattenator(g.children);
      });
    })($scope.organization.children);

    const flatDisplayOrder = flattenDisplayOrder($scope.organization.displayOrder, groupsWithShortNames);
    // Only show 'Display Order' as a sort option if there is a flatDisplayOrder
    if (flatDisplayOrder.length > 0) {
      const displayOrderChoice = { label: "Display Order", value: "displayOrder" };
      $scope.generalSortChoices.splice(1, 0, displayOrderChoice);
    }

    /* This is THE object. Most functions rely on this object in some way
    Gets modified after we grab addOn/coupon data, and several functions in the roster-group directive add data to these group objects for display
    Edit: no longer THE object to watch. Since shared addons and coupons came into the mix, there's also $scope.sharedItems to be aware of. Yay compromises */
    $scope.registrations = _(registrations).map(function(group) {
      if (group.groupID === $scope.organization.id) group.name = $scope.organization.name;
      let flatGroup = flattenedGroups[group.groupID];
      if (!flatGroup || !flatGroup.properties.start || !flatGroup.properties.finish) return;
      group.name = flatGroup.chain;
      group.phase = flatGroup.phase;
      group.startDate = flatGroup.properties.start;
      group.finishDate = flatGroup.properties.finish;
      group.registrations = _(group.registrations)
        .filter(function(reg) {
          return !reg.deactivated && !reg.profile.deactivated;
        })
        .sortBy(['profile.familyName', 'profile.givenName'])
        .value();
      group.capacity = {
        male: flatGroup.maleCapacity,
        female: flatGroup.femaleCapacity,
        total: flatGroup.totalCapacity
      };
      group.displayOrder = getDisplayOrder(group, flatDisplayOrder);
      calculateOccupancy(group);

      return group;
    }).compact().value();

    function calculateOccupancy(group) {
      group.occupancy = {active: { total: 0, female: 0, male: 0 }, waitlist: { total: 0, female: 0, male: 0 } };
      _.each(group.registrations, function(reg) {
        if (reg.deactivated || reg.profile.deactivated) return;
        if (reg.waitlisted) {
          group.occupancy.waitlist.total += 1;
          if (!reg.profile.sex) return;
          group.occupancy.waitlist[reg.profile.sex.toLowerCase()] += 1;
        } else {
          group.occupancy.active.total += 1;
          if (!reg.profile.sex) return;
          group.occupancy.active[reg.profile.sex.toLowerCase()] += 1;
        }
      });
    }

    // Calculate total (and if possible, gender-specific) capacity for each group
    // for use in the roster-group directive. If a particular capacity value isn't
    // set, set it to null so its value will be hidden in the directive.
    function calculateCapacity(group) {
      if (group.registration && group.registration.capacity) {

        if (typeof(group.registration.capacity) === 'string' || _.isNumber(group.registration.capacity)) {

          // If capacity is just a string, then there's only total capacity to worry about
          group.maleCapacity = null;
          group.femaleCapacity = null;
          group.totalCapacity = +group.registration.capacity

        } else if (typeof(group.registration.capacity) === 'object') {
          // If we have a capacity object, we typically have male/female
          // capacities. Set capacity for each or set it to null.
          group.maleCapacity = +group.registration.capacity.male || null;
          group.femaleCapacity = +group.registration.capacity.female || null;

          // If only one gender has capacity, we're okay. (25 + null === 25)
          // If this is a weird dealy where no gender-specific capacities are
          // actually assigned, this will be 0. (nul + null === 0)
          let combined = group.maleCapacity + group.femaleCapacity

          // For the edge case where only one gender has a capacity defined, set
          // ensure totalCapacity will be null.
          if ((group.maleCapacity && !group.femaleCapacity) || (!group.maleCapacity && group.femaleCapacity)) {
            combined = 0;
          }

          // If combined is 0, that means there is no gender-specific capacity
          // set and therefore no total capacity.
          group.totalCapacity = combined === 0 ? null : combined;

        } else {

          // If not an object or string, what the hell is it? Doesn't matter.
          group.maleCapacity = null;
          group.femaleCapacity = null;
          group.totalCapacity = null;

        }
      } else {

        // If `group.registration.capacity` isn't set, capacity is null
        group.maleCapacity = null;
        group.femaleCapacity = null;
        group.totalCapacity = null;

      }
    }


    // Flatten Display Order
    // We have to flatten the displayOrder data structure before we can figure
    // out what a group's display order is.
    // Returns a flat array of groupIDs representing display order.
    function flattenDisplayOrder(displayOrder, shortNameIdentity) {
      // Return an empty array if either arg is empty/falsey
      if (_.isEmpty(displayOrder) || _.isEmpty(shortNameIdentity)) return [];
      return (displayOrder.shortNames || []).reduce((acc, shortName) => {
        if (shortNameIdentity[shortName]) {
          // If a child group has a shortname, its displayOrder[shortName] array
          // will only contain its own ID. Here we check for that to avoid
          // duplicating IDs in the returned array.
          if (displayOrder[shortName][0] !== shortNameIdentity[shortName]) {
            acc.push(shortNameIdentity[shortName]);
          }
          return acc.concat(displayOrder[shortName]);
        } else {
          return acc;
        }
      }, []);
    }

    // Get Display Order
    // Once we have a flat displayOrder, we can use the indicies of the groupIDs
    // to determine sort order.
    // If the index is -1 (not found), place it at the bottom of the list.
    function getDisplayOrder(group, flatDisplayOrder) {
      // If flatDisplayOrder.length is 0, there is no displayOrder
      if (!flatDisplayOrder || flatDisplayOrder.length === 0) return 0;
      let index = flatDisplayOrder.indexOf(group.groupID);
      return index >= 0 ? index : Infinity;
    }

    $scope.groupPhase = "curfut";
    /* Filters groups to specific phase, also handles page sorting */
    $scope.phaseGroups = function(groups, phase, sortBy) {
      sortBy = sortBy ? sortBy : "name";
      return _(groups).filter(function(g) {
        return phase === "past" ? g.phase === "past" : g.phase !== "past";
      }).compact().sortBy(function(g) {
        if (_.includes(["name", "startDate", "finishDate", "displayOrder"], sortBy)) return g[sortBy];
        if (sortBy === 'lowToHighReg') return g.occupancy.active.total;
        if (sortBy === 'highToLowReg') return g.occupancy.active.total * -1;

        if (sortBy === 'lowToHighWait') return g.occupancy.waitlist.total;
        if (sortBy === 'highToLowWait') return g.occupancy.waitlist.total * -1;

        if (sortBy === 'lowToHighAddOn') return g.addOns ? g.addOns.length : 0;
        if (sortBy === 'highToLowAddOn') return g.addOns ? g.addOns.length * -1 : 0;

        if (sortBy === 'lowToHighCoupon') return g.coupons ? g.coupons.length : 0;
        if (sortBy === 'highToLowCoupon') return g.coupons ? g.coupons.length * -1 : 0;

        if (sortBy === 'lowToHighShared') return g.crebits.length;
        if (sortBy === 'highToLowShared') return g.crebits.length * -1;
      }).value();
    }

    /* Assigns addOns and coupons to their respective registrations group object */
    function kajiggerCoupsAndAssembleAddOns(crebitData) {

      sharedAddOns = {};
      sharedCoupons = {};

      /* Filter out any crebits that have been reversed as well as the crebit that reversed it. AddOns only */
      _.map(crebitData, function(group) {
        group.crebits = group.crebits.filter((crebit) => { return crebit.addOn ? !!crebit.quantity : true });
      });

      _.map(crebitData, function(cd) {
        var regMatch = _.find($scope.registrations, function(r) { return r.groupID === cd.groupID; });
        regMatch.coupons = _.filter(cd.crebits, function(c) { return c.coupon; });
        regMatch.addOns = _.filter(cd.crebits, function(c) { return c.addOn; });

        _.map(cd.crebits, function(crebit) {
          if (crebit.addOnGroupID && crebit.addOnGroupID !== cd.groupID && flattenedGroups[crebit.addOnGroupID]) {
            /* crebitProfileMatch won't exist if the profile attached to the crebit has been deactivated */
            let crebitProfileMatch = _.find(regMatch.registrations, function(r) { return r.profileID === crebit.profileID; });
            if (!crebitProfileMatch) return;
            crebit.profile = crebitProfileMatch.profile;

            if (!sharedAddOns[flattenedGroups[crebit.addOnGroupID].chain]) sharedAddOns[flattenedGroups[crebit.addOnGroupID].chain] = { phase: flattenedGroups[crebit.addOnGroupID].phase, crebits: [] };
            sharedAddOns[flattenedGroups[crebit.addOnGroupID].chain].crebits.push(crebit);
          }

          if (crebit.couponGroupID && crebit.couponShared && flattenedGroups[crebit.couponGroupID]) {
            /* crebitProfileMatch won't exist if the profile attached to the crebit has been deactivated */
            let crebitProfileMatch = _.find(regMatch.registrations, function(r) { return r.profileID === crebit.profileID; });
            if (!crebitProfileMatch) return;
            crebit.profile = crebitProfileMatch.profile;

            if (!sharedCoupons[flattenedGroups[crebit.couponGroupID].chain]) sharedCoupons[flattenedGroups[crebit.couponGroupID].chain] = { phase: flattenedGroups[crebit.couponGroupID].phase, crebits: [] };
            sharedCoupons[flattenedGroups[crebit.couponGroupID].chain].crebits.push(crebit);
          }
        });

        $scope.sharedAddOns = throwItOnScope(sharedAddOns);
        $scope.sharedCoupons = throwItOnScope(sharedCoupons);
      });
    }

    function throwItOnScope(sharedObj) {
      return _.map(sharedObj, function(groupData, groupName) {
        return {
          name: groupName,
          crebits: groupData.crebits,
          phase: groupData.phase
        }
      });
    }

    var groupIDs = _.map($scope.registrations, "groupID");

    /* Timeout allows the state to render without waiting for this response for perceived performance! */
    setTimeout(function() {
      if (!groupIDs || !groupIDs.length) return;

      $http.post("/api/organizations/" + $scope.organization.id + "/roster/crebits", { groupIDs: groupIDs }).then(
        function(success){
          kajiggerCoupsAndAssembleAddOns(success.data);
        }, function(err){
          /* Do nothing on this error because the page will still render.
          If they notice there are missing adds and coups, okay, but no need to interrupt the page if they're not here for this info */
          return;
        }
      );
    }, 1000)

    /* Wow. Much complex, very function */
    function setSharedItems(items) {
      $scope.sharedItems = items;
    }

    /* Change roster selection for all groups. If a shared roster is selected, assign $scope.sharedItems to be the correct object */
    $scope.changeRosters = function(rosterType) {
      if (rosterType === "shared-add") {
        $scope.showShared = true;
        setSharedItems($scope.sharedAddOns);
        return;
      } else if (rosterType === "shared-coup") {
        $scope.showShared = true;
        setSharedItems($scope.sharedCoupons);
        return;
      }

      $scope.showShared = false;
      _.map($scope.registrations, function(r) {
        r.rosterType = rosterType;
      });
    }

    $scope.changePhase = function() {
      $scope.resetLimit();
      $scope.groupPhase = $scope.groupPhase === 'curfut' ? 'past' : 'curfut';
    }

    /* Defaults for the 'Show' and 'Sort Groups' dropdowns */
    $scope.globalRoster = "registration";
    $scope.sortBy = "name";
    $scope.sharedSortBy = "name";

    function conformCrebitDescription(description) {
      if (typeof description !== "string") return;
      return description.replace(/\s*→?\s*(\[ADD-ON\]|\[COUPON\])\s*/, '').trim();
    }

    /* Used in CSV generation, takes single group's registration's crebits (either addOns or coupons, not both)
      Finds the ones for our currently-looped profile, removes the crebit-y things from the description, and sorts them alphabetically */
    function reduceItems(items, profileID) {
      return items.reduce((reduced, item) => {
        if (item.profileID === profileID) reduced.push(item.description.replace(/\[(ADD-ON|COUPON)\]/g, "").trim());
        return reduced;
      }, []).sort();
    }

    /* checks a group's phase against the currently filtered phase */
    function wrongPhase(group) {
      if ($scope.groupPhase === "past" && group.phase !== "past") return true;
      if ($scope.groupPhase === "curfut" && group.phase === "past") return true;
      return false;
    }

    /*
      adds headers to csvString based on the current global filter
      registration/waitlist: one row per reg, all info, addOns and coupons get one cell each
      addOn/coupon: one row per item, no extra reg info
    */
    function conditionalHeaders() {
      if ($scope.globalRoster === "registration" || $scope.globalRoster === "waitlist") {
        return ",Registered On,Waitlisted,AddOns,Coupons";
      } else if ($scope.globalRoster === "addOn") {
        return ",AddOn";
      } else if ($scope.globalRoster === "coupon") {
        return ",Coupon";
      }
    }

    function demographicInfo(profile, groupName) {
      return `${profile.id},${profile.familyName},${profile.givenName},${profile.dob},${profile.sex},"${groupName}",`;
    }

    /* Creates a CSV for all phaseGroups */
    $scope.exportAllGroups = function() {

      let csvString;
      /* Start writing csv string */
      if ($scope.showShared) {
        var condHeader = $scope.sharedItems[0].crebits[0].addOn ? 'AddOn' : 'Coupon';
        csvString = "Profile ID,Last Name,First Name,Date of Birth,Sex,Group," + condHeader + "\n";

        _.map($scope.sharedItems, function(group) {
          if (wrongPhase(group)) return;
          _.map(group.crebits, function(crebit) {
            csvString += `${crebit.profile.id},${crebit.profile.familyName},${crebit.profile.givenName},${crebit.profile.dob},${crebit.profile.sex},"${group.name}","${conformCrebitDescription(crebit.description)}"\n`;
          });
        });
      } else {
        csvString = "Profile ID,Last Name,First Name,Date of Birth,Sex,Group" + conditionalHeaders() + "\n";

        _.map($scope.registrations, function(g) {
          if (wrongPhase(g)) return;
          // filter on searched text (i.e., group name)
          if (!$filter('text')({name: g.name}, $scope.search)) return;
          if (["registration", "waitlist"].includes($scope.globalRoster)) {
            g.registrations.sort((a, b) => {
              /* sort logic taken from MDN. faster than lodash sortBy in my testing */
              if (a && a.profile && a.profile.givenName && a.profile.familyName && b && b.profile && b.profile.givenName && b.profile.familyName) {
                let nameA = `${a.profile.familyName.toLowerCase()}, ${a.profile.givenName.toLowerCase()}`;
                let nameB = `${b.profile.familyName.toLowerCase()}, ${b.profile.givenName.toLowerCase()}`;
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
              }
              return 0;
            }).map((r) => {
              if (r.deactivated) return;
              csvString += demographicInfo(r.profile, g.name);
              csvString += `${r.created},${!!r.waitlisted},"${reduceItems((g.addOns || []), r.profile.id).join(" | ")}","${reduceItems((g.coupons || []), r.profile.id).join(" | ")}"`;
              csvString += "\n";
            });
          } else if ($scope.globalRoster === "addOn") {
            (g.addOns || []).map((addOn) => {
              /* regMatch won't exist in cases where there's an addOn for a provider reg since the roster route only grabs patient regs, but the addOns/coupons route returns all crebits for the group */
              let regMatch = g.registrations.find(r => r.profileID === addOn.profileID);
              if (!regMatch || !regMatch.profile) return;
              csvString += demographicInfo(regMatch.profile, g.name);
              csvString += `"${conformCrebitDescription(addOn.description)}"`;
              csvString += "\n";
            });
          } else {
            (g.coupons || []).map((coupon) => {
              /* regMatch won't exist in cases where there's an coupon for a provider reg since the roster route only grabs patient regs, but the addOns/coupons route returns all crebits for the group */
              let regMatch = g.registrations.find(r => r.profileID === coupon.profileID);
              if (!regMatch || !regMatch.profile) return;
              csvString += demographicInfo(regMatch.profile, g.name);
              csvString += `"${conformCrebitDescription(coupon.description)}"`;
              csvString += "\n";
            });
          }
        });
      }

      /* Commence blobby magic */
      let csvData = new Blob([csvString], {type: "text/csv;charset=utf-8;"});
      let filename = "All-groups-roster.csv";

      if (window.navigator.msSaveOrOpenBlob) {
        // Blob support for edge
        window.navigator.msSaveOrOpenBlob(csvData, filename);
      } else {
        let csvURL = window.URL.createObjectURL(csvData);
        let a = document.createElement("a");
        // firefox works better with an attached anchor tag
        document.getElementById("content").appendChild(a);
        a.href = csvURL;
        a.setAttribute("download", filename);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(csvURL);
        a = csvURL = null;
      }
      csvData = csvString = null;
    }

  } //end controller
});
