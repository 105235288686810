lib.registerState("manager.profiles.profile.medications.otc-list", {
  url: "/otc-list",
  templateUrl: "states/manager/profiles/profile/medications/otc/otc-meds-list.state.html",
  resolve: {
    groupOtcMeds: ($http, organization) => {
      return organization.properties.enableOTC ? organization.otcMeds : []
    },

    answer: (profile) => {

      const collectedSubQuestions = (questions) => {
        return questions.reduce((arr, question) => {
          return arr.concat(question.subQuestions || []);
        }, []);
      };

      const findOTCQuestion = (questions) => {
        return questions.length && (questions.find(q => q.type === 'otc')
          || findOTCQuestion(collectedSubQuestions(questions)));
      };

      const question = findOTCQuestion(profile.newQuestionnaire.questions);
      const matchingAnswer = question ? _.find(profile.answers, {questionID: question.id}) : null;

      return matchingAnswer ? JSON.parse(matchingAnswer.value) : {};
    }
  },

  controller: ($scope, profile, groupOtcMeds, answer) => {
    let value;
    $scope.authorizations = _.map(groupOtcMeds, (med) => {
      if (answer[med.id] == true) { value = 'Yes' }
      else if (answer[med.id] == false) { value = 'No' }
      else { value = '--' }
      return {
        med: med.label,
        value: value
      }
    })
  }
})
