angular.module("dn").directive("notificationsList", function() {
  return {
    templateUrl: "directives/notifications/notifications-list.directive.html",
    restrict: "E",
    scope: {
      notifications: "="
    },

    controller: function($scope, $state, $filter) {
      var typeDict = window.lib.notificationTypes

      $scope.prettyType = (type) => { return typeDict[type] }

      // Sort
      $scope.sort = 'sent'
      $scope.sortOptions = [
        {label: 'Newest', value: '-sent'},
        {label: 'Oldest', value: 'sent'},
        {label: 'Group', value: 'groupName'},
        {label: 'Profile', value: 'profileName'}
      ]

      // Filter
      $scope.filter = '0'
      $scope.filterOptions = [{ label: "All Notifications", value: "0", optgroup: "Default" }]

      _($scope.notifications).map( (n) => {
        $scope.filterOptions.push({label: n.groupName, value: n.groupName, optgroup: 'Group'})
        $scope.filterOptions.push({label: n.profileName, value: n.profileName, optgroup: 'Profile'})
        $scope.filterOptions.push({label: n.groupName, value: n.groupName, optgroup: 'Group'})
      }).uniq().value()

    }
  }
})
