angular.module('dn').directive('upload', function (Images, $timeout) {
  return {
    templateUrl: 'directives/input/types/upload/upload.directive.html',
    restrict: 'E',
    scope: {
      accept: '@',
      action: '@',
      completeFn: '=',
      ngDisabled: '=',
      removeFn: '=',
      instructions: '@',
      maxFiles: '=',
      model: '=',
      existingFiles: '=',
      disableUploads: '=',
      pdfSplit: '@',
      id: '@',
      template: "@"
    },
    link: function (scope, elm, attrs) {

      const showThumbnail = [
        'image/gif',
        'image/png',
        'image/jpeg',
        'image/bmp',
        'image/svg+xml'
      ];

      const fallbacks = {
        csv: window.lib.brandingConfig.values.fallbacks.csv,
        xls: window.lib.brandingConfig.values.fallbacks.xls,
        xlsx: window.lib.brandingConfig.values.fallbacks.xlsx,
        doc: window.lib.brandingConfig.values.fallbacks.doc,
        docx: window.lib.brandingConfig.values.fallbacks.docx,
        pdf: window.lib.brandingConfig.values.fallbacks.pdf,
        file: window.lib.brandingConfig.values.fallbacks.file
      };

      // For use with the pdfHandler stuff
      // This allows us to actually check the boolean, which it did not do before
      // !!'false' === true. Every pdf was being "split".
      const splitPDF = function() {
        const val = (scope.pdfSplit) ? scope.pdfSplit : true;
        return (val === 'false') ? false : true;
      }

      scope.identifier = uuid.v4();

      // Only show thumbnails if `pdfSplit` cuz then we actually retrieve images
      if (splitPDF()) showThumbnail.push('application/pdf');
      if (!scope.completeFn) { scope.completeFn = function () {}; }
      if (!scope.removeFn) { scope.removeFn = function () {}; }

      const parseFileName = function(ContentDisposition="") {
        return decodeURIComponent(ContentDisposition.replace(/(attachment; filename=|")/g, ''));
      }

      const setFallbackThumb = function(ContentType="") {
        let fallbackThumb;
        switch (ContentType) {
          case fallbacks.csv.mime:
            fallbackThumb = fallbacks.csv.thumb;
            break;
          case fallbacks.xls.mime:
            fallbackThumb = fallbacks.xls.thumb;
            break;
          case fallbacks.xlsx.mime:
            fallbackThumb = fallbacks.xlsx.thumb;
            break;
          case fallbacks.doc.mime:
            fallbackThumb = fallbacks.doc.thumb;
            break;
          case fallbacks.docx.mime:
            fallbackThumb = fallbacks.docx.thumb;
            break;
          // Used if `pdfSplit` is off so we have a thumbnail
          case fallbacks.pdf.mime:
            fallbackThumb = fallbacks.pdf.thumb;
            break;
          // Fall back to a generic file icon
          default:
            fallbackThumb = fallbacks.file.thumb;
        }
        return fallbackThumb;
      }

      const getPermittedTypes = function(accepted) {
        const permittedTypes = [];
        if (_.includes(accepted, fallbacks.csv.mime)) permittedTypes.push('CSV');
        if (_.includes(accepted, fallbacks.xls.mime)) permittedTypes.push('XLS');
        if (_.includes(accepted, fallbacks.xlsx.mime)) permittedTypes.push('XLSX');
        if (_.includes(accepted, fallbacks.doc.mime)) permittedTypes.push('DOC');
        if (_.includes(accepted, fallbacks.docx.mime)) permittedTypes.push('DOCX');
        if (_.includes(accepted, fallbacks.pdf.mime)) permittedTypes.push('PDF');
        // All image types
        // Leave this string as is cos it's used many times throughout the app.
        if (_.includes(accepted, 'image/*')) permittedTypes.push('JPG', 'GIF', 'PNG')

        return permittedTypes.join(", ");
      };

      // set permittedFileTypes
      let permittedTypes = []
      if (scope.accept) {
        scope.permittedTypes = getPermittedTypes(scope.accept);
      } else {
        scope.permittedTypes = 'PDF, JPG, GIF, PNG'
      }

      var pdfHandler = false;

      // Handler for PDF uploads since they split into multiple images
      const getImages = function (urls) {

        // Default this to an empty array so we don't get errors when concating
        // See issue #4922
        scope.existingFiles = scope.existingFiles || [];

        // Find manipulated resource and download images
        var resource = '';
        if (scope.action.match(/profiles/)) { resource = 'profiles'; }
        if (scope.action.match(/groups/))   { resource = 'groups';   }
        var resourceIndex = _.findIndex(scope.action.split('/'), function(s) {
          return s === resource.toString();
        }) + 1;
        var resourceID = scope.action.split('/')[resourceIndex];

        // Separate image download methods for different types of resources
        // Used to accommodate permissions in params
        // May want to revisit later
        if (resource === 'profiles') {
          Images.getForProfile(resourceID, urls).then(function (images) {
            scope.existingFiles = scope.existingFiles.concat(images);
          });
        } else if (resource === 'groups') {
          Images.getForGroup(resourceID, urls).then(function (images) {
            scope.existingFiles = scope.existingFiles.concat(images);
          });
        } else {
          Images.getConstantImage(urls).then(function (images) {
            scope.existingFiles = scope.existingFiles.concat(images);
          });
        }
      };

      const loadCache = {};

      const elmID = 'dropzone-preview-template-' + scope.identifier;
      let previewElement;
      let dzDefaults;

      $timeout(function () {

        previewElement = document.getElementById(elmID);

        dzDefaults = {
          acceptedFiles: scope.accept || 'image/*,application/pdf',
          autoProcessQueue: false,
          clickable: true,
          previewTemplate: (previewElement || {}).innerHTML,
          maxFilesize: 5,
          maxFiles: scope.maxFiles || null,
          paramName: 'file',
          parallelUploads: 1,
          uploadMultiple: false,
          dictRemoveFileConfirmation: "Are you sure you want to delete this file forever?",

          init: function () {

            var self = this;

            // Queue begins to process when files are added
            this.on('addedfile', function (file) {
              if (!file || !file.type) return;
              // ng-disabled prevents people from downloading stuff uploaded in
              // the past, so this NEW prop only disables uploads
              if (scope.disableUploads) {
                // Remove empty thumbnail
                dropzone.emit('removedfile', file);
                return swal("Uploads Disabled", "No more uploads are allowed at this time.", "error")
              }
              $timeout(function() { self.processQueue(); });
            });

            // Change URL based on action in case it's changed
            this.on('processing', function (file) {
              $("#help-text").css("display", "block");
              scope.uploading = true;
              this.options.url = scope.action;
            });

            // Step one is to update the action in the parent scope.
            // This is done in a timeout to ensure the action is updated
            // before the next file begins updating
            this.on('success', function (file, response) {
              // Execute onComplete callback
              $timeout(function() { scope.completeFn(response); });

              pdfHandler = false;
              // If they just added a PDF, remove the blank thumbnail
              // Note: only done if we want to split them. Templates we do not.
              if (!!file.type.match('pdf') && splitPDF()) {
                pdfHandler = true;
                dropzone.emit('removedfile', file);
              }
              // If we aren't showing thumbnails, give new uploads a placeholder
              else if (!_.includes(showThumbnail, file.type)){
                const previewImg = file.previewElement.firstElementChild.children[2];
                previewImg.src = setFallbackThumb(file.type);
              }

              // After a successful upload, add the Key to the cache so we don't
              // pull in extra thumbnails when `existingFiles` gets updated.
              if (!pdfHandler) {
                if (response.hasOwnProperty('key')) {
                  let keys = response.key.split('|');
                  keys.forEach(k => loadCache[k] = true);
                }
                loadCache[response.Key] = true;
              }

              // If they uploaded a PDF, download the images it became
              // Older routes return an object that looks like this:
              // {key: '<orgID>:<uuid>|<orgID>:<uuid>|<orgID>:<uuid>'}
              // This is why we use LOWERCASE .key here.
              if (pdfHandler) getImages(response.key);

              scope.uploading = false;

              // Continue to process the queue
              $timeout(function() { self.processQueue(); });
            });

            this.on('error', function (file, error) {
              const err = error.errorMessage || (typeof error === 'string' ? error : false);
              scope.uploading = false;
              dropzone.emit('removedfile', file);
              window.swal({
                title: 'Upload Error',
                text: handleErr(err),
                type: 'warning'
              });
            });

            // handleErr: simple function to have a better error message
            // when we upload more than the max files on the dropzone
            // and when the network gets interrupted
            function handleErr(err) {
              if (err === 'You can not upload any more files.') {
                return 'You can not upload any more files, please delete the existing file before uploading a new one';
              } else if (err === 'Server responded with 0 code.') {
                return 'Network interruption. Please check your internet connection and try again.';
              } else {
                return err || "There was a problem uploading the file. Please check the file type and size and try again.";
              }
            }

            this.on('thumbnail', function (file) {
              $("#help-text").css("display", "block");
              // Since implmenting placeholder images, the img's src isn't
              // always what we really want. In fact, it's usually not. Only use
              // it as a fallback.
              const downloadURL = file.url
              file.previewElement.children[0].addEventListener('click', function (e) {
                // Because of the hover effect, users will always click the
                // dz-filename div. Its parent's 3rd child is the <img>.
                window.open(downloadURL || e.target.parentElement.children[2].src, '_blank');
              });
            });

            // Make sure to delete from the answer array
            this.on('removedfile', function (file) {
              $timeout(function() { scope.removeFn(file); });
              // We don't need to do this for array models.
              // Model manipulation should be handled in the removeFn.
              if (typeof(scope.model) === 'string') {
                return $timeout(function() {
                  const key = (file.hasOwnProperty('key') ? file.key : _.last(JSON.parse(file.xhr.reponseText).key.split('|')));
                    const splitModel = scope.model.split('|');
                    scope.model = _.without(splitModel, key).join('|');
                }, 100);
              }
              else {
                return;
              }
            });
          }
        }
      }, 50);
      // I've been getting some `Invalid Dropzone Element` errors related to
      // due to `previewTemplate` being undefined. Adding some time to these
      // timeouts (increased from 0 across the board) to see if that helps.
      // Update 10/17/2017: I haven't experienced one since this change.
      // Update 12/19/2017: Happening again, moving it from 10ms to 50ms
      // When making timeout longer, you must adjust the other timeouts too.
      // Moved those from 15 to 60 and 20 to 80 respectively.

      var target = 'form#upload-form-' + scope.identifier;
      var dropzone;
      $timeout(function () {
        if (!dzDefaults.previewTemplate) return
        dropzone = new Dropzone(target, dzDefaults);
      }, 60);

      // Load existing files (and PDF images) once via their urls
      var loadExisting = function (urls) {
        _.each(urls, function (url) {

          let mockFile;
          let thumbnailURL;

          // Handle old method of passing in existing images
          if (typeof(url) === 'string') {

            thumbnailURL = url;
            let key;
            // If it fits this older pattern, use `loadCache` for... something..
            // I believe this is PDF-related.
            if (url.indexOf("?") !== -1) {

              // This split is designed to operate on a SignedURL returned by
              // storage.getURL[Sync]. I believe that's how this is done
              // everywhere but the `season` state, where we pass enhanced
              // S3 Objects. This is meant to locate the <orgID:uuid> combo.
              key = _.last((url.split('?')[0].split('/')));

              // If a file already has a thumbnail, don't load another one.
              if (loadCache[key]) return;
              loadCache[key] = true;

            }
            // Fallback
            else {
              // If it doesn't match the paradigms above, what the heck is it?!
              // Just pretend it never happened...
              return;
            }

            mockFile = {
              name: key,
              key: key,
              url: url,
              size: 123
            };
            // This double `key` comes from the fact that the files actual name
            // can be useful, and is now saved for an improved user experience.
            // Future `removeFn`s should use file.key for matching IDs to remove
            // file references, rather than file.name

          }
          // Now, instead of URL strings, just pass the damn S3 Object!
          else if (typeof(url) === 'object') {

            // If a file already has a thumbnail, don't load another one.
            if (loadCache[url.Key]) return;
            loadCache[url.Key] = true;

            mockFile = {
              name: parseFileName(url.ContentDisposition) || url.Key,
              key: url.Key,
              url: url.url,
              size: 123
            };

            // Set fallback thumbnails for doc types that have no thumbnail
            if (showThumbnail.indexOf(url.ContentType) === -1) {
              thumbnailURL = setFallbackThumb(url.ContentType);
            }
            else {
              thumbnailURL = url.url;
            }

          }

          dropzone.emit('addedfile', mockFile);
          dropzone.emit('thumbnail', mockFile, thumbnailURL);

        });
      };

      // Watch the existing files array which will change if a PDF is
      // uploaded and split
      $timeout(function () {
        if (!dzDefaults.previewTemplate) return
        scope.$watch('existingFiles', function (urls) {
          if (urls && urls.length) {
            loadExisting(urls);
          }
        }, true);
      }, 80);

    }
  };
});
