lib.registerState("manager.profiles.profile.tags", {
  url: "/tags",
  templateUrl: "states/manager/profiles/profile/tags/profile-tags.state.html",
  resolve: {
     title: function($rootScope) {
       return $rootScope._title = "Tags";
     }
   },
  controller: function($scope, $state, profile, organization, $http, flash) {
    'use strict';
    var profileTags, profileTagsBackup;
    /* All will be revealed in $scope.reset() */
    $scope.sickHax = true;

    /* _.reduce isn't chainable by default for some reason */
    $scope.profileTags = initProfileTags(profile.tags, organization.tags);
    backUpProfileTags($scope.profileTags);

    // organize the group's tags
    (function tagParenting () {
      $scope.displayTags = {};
      _.map(organization.tags, function (t) {
        // don't display deactivated tags
        if (t.deactivated) { return; }

        _.map(organization.tags, function (c) {
          if (c.parentID === t.id) {
            $scope.displayTags[t.id] = t;
            if (!$scope.displayTags[t.id].options) { $scope.displayTags[t.id].options = {}; }
            $scope.displayTags[t.id].options[c.id] = c;
          }
        });
        if (!$scope.displayTags[t.id]) { $scope.displayTags[t.id] = t; }
      });
      // delete child tags that are now nested under the parent
      _.map($scope.displayTags, function (t) {
        if (!_.isNull(t.parentID)) { delete $scope.displayTags[t.id]; }
      });
    })();

    $scope.displayTags = _.sortBy($scope.displayTags, "value");

    /* create option objects for each tag's choices */
    $scope.tagOptions = {};
    _($scope.displayTags).filter("options").map((t) => {
      $scope.tagOptions[t.id] = _.map(t.options, (o) => {
        return {label: o.value, value: o.value};
      });
    }).value();

    /*
      close the editing fields
      set profile's tags object back to what it was when enterting the state
      sickHax + timeout is used to get around whatever angular/material input caching is going on
        without these, user can choose a value, hit cancel, hit edit, value is still in the input but the model isn't actually populated
        so instead we just blink the entire table in and out of existence and woo that fixes it
    */
    $scope.reset = function () {
      $scope.editingTags = false;
      $scope.sickHax = false;
      $scope.profileTags = initProfileTags(profile.tags, organization.tags);
      setTimeout(() => {
        $scope.sickHax = true
      });
    };

    // handle saving and updating of tags
    $scope.saveTags = function () {
      $scope.submitting = true;
      let promiseFuncs = _($scope.profileTags).map((t, key) => {
        /* profile does not already have an instance of this tag */
        if (!t.id) {
          /* no value means they made a choice then backspaced over it */
          if (!t.value) return null;
          /* find the parentTag first so we can properly search for options based on o.parentID in case they have multiple tags with values of the same name */
          let parentTag = _.find(organization.tags, {id: parseInt(key)});
          /* didn't find the main org tag, something's wrong */
          if (!parentTag) return null;
          return function() {
            return new Promise((resolve, reject) => {
              let optionTag = _.find(organization.tags, {parentID: parentTag.id, value: t.value});
              let profileTag = {
                profileID: profile.id,
                tagID: optionTag ? optionTag.parentID : parentTag.id,
                optionID: optionTag ? optionTag.id : null,
                value: optionTag ? null : t.value
              }
              $http.post("/api/profiles/" + profile.id + "/tags", profileTag).then((success) => {
                /* for whatever reason, profile.tags will be an object if they have no tags */
                if (!profile.tags || _.isEmpty(profile.tags)) profile.tags = [];
                profile.tags.push(success.data);
                return resolve();
              }, (error) => {
                return reject(error.data);
              });
            });
          }
        } else {
          /* skip saving if the value is the same as it was when we entered the state */
          if (t.value === profileTagsBackup[t.tagID].value) return null;
          return function() {
            return new Promise((resolve, reject) => {
              /* if it has an optionID, it shouldn't have a value when we save */
              if (t.optionID) {
                t.optionID = _.find(organization.tags, {parentID: t.tagID, value: t.value}).id;
                delete(t.value);
              }
              $http.put("/api/profiles/" + profile.id + "/tags", t).then((success) => {
                _.remove(profile.tags, (pt) => { return pt.tagID === t.tagID });
                profile.tags.push(success.data);
                return resolve();
              }, (error) => {
                return reject(error.data)
              });
            });
          }
        }
      }).compact().value();

      window.lib.promiseSeries(promiseFuncs).then(() => {
        $scope.profileTags = initProfileTags(profile.tags, organization.tags);
        backUpProfileTags($scope.profileTags);
        flash("Tags saved");
        $scope.editingTags = false;
        $scope.submitting = false;
      }).catch((err) => {
        flash("Error saving tags");
        $scope.submitting = false;
      });

    };
    // end saveTags

    // delete a profile's tag
    $scope.deleteTag = function (tag) {
      if (!tag || !tag.id) return;
      $scope.profileTags[tag.id].value = "";
      var deleteTag = $scope.profileTags[tag.id];

      $http.delete('/api/profiles/' + profile.id + '/tags?tag=' + deleteTag.id).then (
        function (success) {
          flash("Tag deleted");
          _.remove(profile.tags, (pt) => { return pt.tagID === tag.id });
          $scope.sickHax = false;
          $scope.profileTags = initProfileTags(profile.tags, organization.tags);
          backUpProfileTags($scope.profileTags);
          /* these sick hax are needed to avoid a profileTags bug where a user selects a value for a tag and deletes another without saving first */
          setTimeout(() => {
            $scope.sickHax = true;
          });
          return;
        },
        function (error) { return; }
      );
    };
    // end deleteTag

    function initProfileTags(profileTagsArray, orgTagsArray) {
      return _.chain(profileTagsArray || []).reduce((tags, tag) => {
        if (!tag.deactivated) {
          let orgTag = null;
          if (tag.optionID) orgTag = _.find(orgTagsArray || [], {id: tag.optionID});
          if (orgTag) tag.value = orgTag.value;
          tags.push(tag);
          orgTag = null;
        }
        return tags;
      }, []).keyBy("tagID").value();
    }

    function backUpProfileTags(tags) {
      profileTagsBackup = _.cloneDeep(tags);
    }
  }
  // end controller
});
// end state
