lib.registerState "manager.profiles.profile.feed",
  url: "/feed"
  templateUrl: "states/manager/profiles/profile/feed/profile-feed.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Provider / Profiles"
    feed: ($http, profile) ->
      $http.get("/api/profiles/#{profile.id}/feed").then (result) -> result.data

  controller: ($scope, feed) ->
    $scope.feed = feed
