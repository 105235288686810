lib.registerState('manager.healthLog.triage', {
  url: '/triage',
  templateUrl: 'states/manager/health-log/triage/triage.state.html',
  resolve: {
    title($rootScope) {
      return $rootScope._title = 'Triage';
    },
    triaged($http, organization) {
      const url = `/api/organizations/${organization.id}/log-entries`;
      const filter = '[logEntries].triage(is:true)';
      return $http.get(`${url}?filters=${filter}`)
        .then(({ data }) => {
          return data.map(({ logEntries: entry, profiles: profile }) => {
            entry.profile = profile;
            // Cast timestamp without timezone to zero UTC offset string
            entry.created = `${entry.created}Z`;
            return entry;
          });
        });
    }
  },
  controller($http, $scope, $state, $stateParams, $timeout, triaged) {
    $scope.triaged = triaged;

    $scope.minutesSince = (time) => moment().diff(time, 'minutes');

    $scope.enterCheckinMode = () => {
      return swal({
        title: 'Entering Check-In Mode',
        text: 'Once you enter check-in mode, you will only have access to check-in on this computer. \
          You will need to log in again to regain access to provider functionality.',
        type: 'warning',
        showCancelButton: true
      }, (enterMode) => {
        if (!enterMode) return;
        return $http.post(`/api/organizations/${$stateParams.orgID}/triage-mode`).then(() => {
          $timeout(() => $state.go('checkIn'), 100);
        });
      });
    };
  }
});
