lib.registerState('manager.settings.service-capacity', {
  url: '/service-capacity',
  templateUrl: 'states/manager/settings/service-capacity/service-capacity.state.html',
  resolve: {
    title: function ($rootScope, group) { return $rootScope._title = `${group.name} / Service Capacity`; },
    parcelConfig: function () { return window.System.import('@dn/billing-dashboard'); },
    parcelProps: function(organization) {
      return {
        org: organization
      };
    }
  },
  controller: function($scope, $state, parcelConfig, parcelProps, session) {
    if (!window.lib.featureFlagClient.isEnabled('billingDashboard')) {
      $state.go('manager.profiles');
    }
    $scope.noServiceCapacityPermission = !session.access.operator;
    $scope.parcelConfig = parcelConfig;
    $scope.parcelProps = parcelProps;
  }
});
