lib.registerState('manager.attendance.list.profiles', {
  url: '/profiles',
  templateUrl: 'states/manager/attendance/profiles/profiles.state.html',
  controller: function($scope, $state, AttendanceData, ContactData) {
    'use strict';

    const statusOptions = {
      'attendance': ['attendance'],
      'check-in': ['check-in'],
      'audit': ['attendance', 'check-in'],
    };

    $scope.statusIndicator = statusOptions[$state.params.action];

    $scope.data = AttendanceData;

    $scope.bypassModeOptions = [
      { label: 'Off', value: null },
      { label: 'Check In', value: false },
      { label: 'Check Out', value: true },
    ];
    // Clear out filter settings on each load (e.g. state transition)
    AttendanceData.setUiEntry('bypassModeFilter', null);
    AttendanceData.setFilter('checkInStatus', null);
    // Make sure the feature is on, we're in the right place, and we have a UC available.
    const displayBypassMode = $state.params.action === 'check-in'
      && AttendanceData.ui.settings.bypassModeEnabled
      // eslint-disable-next-line eqeqeq
      && ContactData.universalContact != null;
    AttendanceData.setUiEntry('displayBypassMode', displayBypassMode);

    // Keeps track if we reached the length of the filteredProfiles
    $scope.max = false;
    // Beginning index to track where we're starting on each group of profiles
    let startIndex = 0;
    // End index for group of profiles we're loading up to
    let endIndex = 100;
    // Size or profiles we're displaying per page
    const size = 100;

    // Holds the profiles we want to display
    Object.defineProperty($scope, 'displayProfiles', {
      enumerable: true,
      configurable: false,
      get() {
        return $scope.data.filteredProfiles.slice(startIndex, endIndex);
      }
    });

    $scope.checkMax = function() {
      if (endIndex + 1 === $scope.data.filteredProfiles.length) {
        $scope.max = true;
      }
    };

    $scope.checkMax();

    $scope.$watch('data.filteredProfiles.length', $scope.checkMax);

    $scope.loadMoreProfiles = function() {
      if (!$scope.max) {
        endIndex += size;
        // We verify that we have not hit the length after adding more data
        $scope.checkMax();
      }
    };

    AttendanceData.setUiEntry('searchProfileText', AttendanceData.ui.searchProfileText || '');

    $scope.search = function (searchTerm) {
      execQuery(searchTerm);
    };

    $scope.clear = function () {
      AttendanceData.setUiEntry('searchProfileText', '');
      execQuery();
    };

    /**
     * Change function for handling UI changes as related to Bypass Mode. The changes this function
     * makes _only apply to check in/out_ because the switch will be hidden elsewhere.
     *
     * When Bypass is Enabled:
     *   - The "next" button should be visible
     *   - Tapping on a profile should call the selectProfile action
     * When Bypass is Disabled:
     *   - The "next" button should be hidden
     *   - Tapping on a profile should navigate to that profile
     *
     * Note: changes to the "next" button _action_ and _text_ are handled in the button config in
     * attendance.state.js because those function need access to stuff we don't have here.
     */
    $scope.handleBypassChange = function () {
      let onProfileInteract;
      const filterEnabled = AttendanceData.ui.bypassModeFilter !== null;
      if (filterEnabled) {
        onProfileInteract = AttendanceData.ui.actions.selectProfile;
      } else {
        onProfileInteract = AttendanceData.ui.actions.goToProfile;
      }
      // Clear existing selections
      AttendanceData.deselectAllProfiles();
      AttendanceData.clearTmpRecords();
      // Set filter to its new value
      AttendanceData.setFilter('checkInStatus', AttendanceData.ui.bypassModeFilter);
      // Update UI settings
      AttendanceData.setUiEntry('buttons.next.hide', !filterEnabled);
      AttendanceData.setUiEntry('onProfileInteract', onProfileInteract);
    };

    function execQuery (query = '') {
      AttendanceData.searchProfiles(query);
    }
  }
});
