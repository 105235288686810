lib.registerState("patient.profile.emergency", {
  url: "/emergency",
  templateUrl: "states/patient/profile/emergency/emergency.state.html",
  resolve: {
    title($rootScope) {
      return $rootScope._title = `Emergency Plan`;
    }
  },
  controller($scope, $state, campgramsOnly, organization) {
    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    $scope.emergency = organization.properties.emergency;

    if (!$scope.emergency || !$scope.emergency.enabled) {
      return $state.go('^');
    }
  }
});
