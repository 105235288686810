# Controls a 'text' type question
angular.module("dn").directive "qtText", ->
  templateUrl: "directives/taker/text/qt-text.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qtText"
    answer: "="
  controller: ($scope) ->
    # Called immediately to format the old data into the new
    $scope.question.metadata.inputType = window.lib.builderTextInputFormatter($scope.question.metadata)

    # Create the validation string for the materialInput
    $scope.validate = if $scope.question.required then "required" else ""
    if $scope.question.metadata.inputType == 'phone' then $scope.validate += "|phone"
    if $scope.question.metadata.inputType == 'email' then $scope.validate += "|email"
    if $scope.question.metadata.inputType == 'number' then $scope.validate += "|number"

    # Use a phone icon if we want a phone input :)
    $scope.icon = if $scope.question.metadata.inputType == 'phone' then "phone" else ""
