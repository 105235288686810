lib.registerState("oauth", {
  url: "/oauth",
  templateUrl: "states/oauth/oauth.state.html",
  resolve: {
    session: ($http) => $http.get("/api/session").then((result) => result.data),
    application: ($http, $location, $state) => {
      const clientID = $location.search().client_id || '';
      const redirectUri = $location.search().redirect_uri || '';

      let url = `/api/applications/${clientID}`;
      if (redirectUri) {
        url += `?redirect_uri=${encodeURIComponent(redirectUri)}`;
      }

      return $http.get(url).then((result) => {
        return result.data;
      }).catch((err) => {
        // If the get returned a custom error message, the resolve will return
        // an object with that error. If not, redirect to home. Redirecting to
        // home because otherwise the state gets rendered with an OAuth access
        // error before the user even has a chance to log in
        const isCustomError = err.data && err.data.error;

        if (isCustomError) {
          return { error: err.data.error };
        } else {
          return $state.go('home');
        }
      });
    },
  },
  controller: function($scope, $http, $location, $window, WhatDivision, application) {
    'use strict';

    $scope.division = WhatDivision.lcName;
    $scope.application = application;

    const requiredParams = [
      'client_id',
      'response_type',
      'scope',
    ];

    const locationSearch = $location.search();

    const oauthParams = {
      client_id: locationSearch.client_id,
      response_type: locationSearch.response_type,
      redirect_uri: locationSearch.redirect_uri,
      scope: locationSearch.scope,
      state: locationSearch.state,
      code_challenge: locationSearch.code_challenge,
      code_challenge_method: locationSearch.code_challenge_method,
    };

    const oauthScopes = window.dnim.constants.oauthScopes;

    // For efficiency, we want a map from colon delimited scope names to the
    // scope description and icon
    const scopesByName = Object.entries(oauthScopes).reduce((scopes, [, value]) => {
      scopes[value.name] = value;
      return scopes;
    }, {});

    const inputScopes = oauthParams.scope.split(' ');

    const validScopes = inputScopes.filter((s) => {
      return !!scopesByName[s];
    });

    // Replace the input scope parameter with only the valid scopes
    oauthParams.scope = validScopes.join(' ');

    // Get the scope info to display to the user
    $scope.scopeInfo = validScopes.map(scope => {
      return {
        text: scopesByName[scope].description,
        icon: scopesByName[scope].icon,
      };
    });

    // manual DOM manipulation to remove topbar icons
    let icons = document.getElementsByClassName('icons')[0];
    icons.parentNode.removeChild(icons);

    $scope.validLink = requiredParams.every((p) => oauthParams[p]);

    $scope.allow = function() {
      if (!$scope.validLink) return Promise.reject();

      Object.keys(oauthParams).forEach((k) => {
        if (oauthParams[k] === undefined) delete oauthParams[k];
      });

      $http.post('/api/oauth/authorize', oauthParams).then((result) => {
        redirectToResponseURI(result);
      }).catch((err) => {
        //TODO error screen
        flash("Error: " + JSON.stringify(err.data));
      });
    };

    $scope.deny = function() {
      if (!$scope.validLink) return Promise.reject();

      $http.post('/api/oauth/deny', oauthParams).then((result) => {
        redirectToResponseURI(result);
      }).catch((err) => {
        //TODO error screen
        flash("Error: " + JSON.stringify(err.data));
      });
    };

    /**
     * Redirects to the URI passed in the $http response object
     * @param {Object} response - $http response object
     */
    function redirectToResponseURI(response) {
      if (!response.data || !response.data.redirectUri) {
        //TODO, display code?
        flash("No URI returned!");
      } else {
        $window.location.href = response.data.redirectUri;
      }
    }
  }
});
