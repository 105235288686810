'use strict';

lib.registerState('oper.notifications', {
  url: '/notifications',
  templateUrl: 'states/oper/notifications/notifications.state.html',

  controller: function($scope, $filter, Announcement) {
    $scope.addAnnouncement = function() {
      if (!$scope.newAnnouncement) return;
      new Announcement({message: $scope.newAnnouncement}).save().then(function() {
        swal({
          title: 'Announcement Added!',
          text: $filter('md')($scope.newAnnouncement),
          type: 'info',
          html: true
        });
      });
    };
  }
});
