angular.module('dn').directive('funkNoteFeed', function () {
  return {
    templateUrl: 'directives/funk-notes/funk-note-feed/funk-note-feed.directive.html',
    restrict: 'A',
    controller: function ($http, $scope) {

      $scope.print = function (funkID) {
        $http.get(printURL(funkID), { responseType: 'arraybuffer' }).then((result) => {
          const pdf = new Blob([result.data], { type: 'application/pdf' });
          const filename = `${$scope.profile.givenName}-${$scope.profile.familyName}-CampGram.pdf`;
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf, filename);
          } else {
            let pdfURL = window.URL.createObjectURL(pdf);
            let a = document.createElement('a');
            document.getElementById('content').appendChild(a);
            a.href = pdfURL;
            a.setAttribute('download', filename);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(pdfURL);
            a = pdfURL = null;
          }
          markPrinted(funkID);
        }).catch(() => {
          swal({
            title: 'CampGram not found',
            type: 'info',
            text: 'No CampGram found.'
          });
        });
      };

      function printURL (funkID) {
        return `/api/organizations/${$scope.organization.id}/funk-notes-reports/${funkID}`;
      }

      function markPrinted (id) {
        $scope.funkNotes.find((fn) => fn.id === id).printed = true;
      }
    }
  };
});
