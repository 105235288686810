lib.registerState "patient",
  url: "/org/:orgID/patient?r"
  templateUrl: "states/patient/patient.state.html"
  resolve:
    title: ($rootScope, organization) ->
      $rootScope._title = $rootScope.division.patient.plural.capitalize() + " at " + organization.name
    organization: (Group, $stateParams, $rootScope) ->
      new Group($stateParams.orgID).load().then (group) ->
        $rootScope.organization = group
        group
    session: ($http) ->
      $http.get("/api/session?reaccess").then (result) -> result.data
    user: (User, session) ->
      new User(session.userID).load().then (user) -> user
    profilesUsers: (user, $http) ->
      $http.get("/api/users/#{user.id}/profiles-users").then (result) ->
        result.data || []
    patients: (organization, session) ->
      accessOrg = _.find session.access.organizations, (org) -> organization.id is org.id
      return [] unless accessOrg
      patients = _.union accessOrg.patients, accessOrg.orphans
      patients = _.sortBy patients, (p) -> p.name
      patients
    nav: ($rootScope, $state, organization, session, patients, profilesUsers) ->
      $rootScope._nav = []

      #Feature flag for prescreening
      $rootScope.featureFlags ?= {}
      $rootScope.featureFlags.prescreening = true

      $rootScope.organizationWordMark = window?.lib?.brandingConfig?.values?.wordmarks?.custom

      # dnNext is a generic feature flag that allows us to deploy features that only development can see
      $rootScope.featureFlags.dnNext = window.lib.matchesEnv({ environments: ['development', 'staging']})

      _.each patients, (patient) ->
        profileAge = -1
        if patient.dob
          profileAge = moment().diff(patient.dob, 'years')
        nav =
          text: patient.name
          state: "patient.profile"
          params: profileID: patient.id, orgID: organization.id
          parallel: true
          icon: "child"
          subnav: [
            {
              text: do ->
                organization.properties.portals?.patient?.sections?.registration?.alias or
                "Registration"
              state: "patient.profile.registration"
              params:
                profileID: patient.id
                orgID: organization.id
              hide: organization.properties.portals?.patient?.sections?.registration?.hide
            }
            {
              text: do ->
                organization.properties.portals?.patient?.sections?.prescreening?.alias or
                "Prescreening"
              state: "patient.profile.prescreening"
              params:
                profileID: patient.id
                orgID: organization.id
              hide: do ->
                !$rootScope.featureFlags.prescreening or
                !organization.properties.features?.prescreening?.enabled
            }
            {
              text: do ->
                organization.properties.portals?.patient?.sections?.questionnaire?.alias or
                organization.properties.branding?.text?.healthProfileAlternateName or
                "Health Profile"
              state: "patient.profile.questionnaire"
              params:
                profileID: patient.id
                orgID: organization.id
              hide: organization.properties.portals?.patient?.sections?.questionnaire?.hide
            }
            {
              text: do ->
                organization.properties.portals?.patient?.sections?.account?.alias or
                "Account"
              state: "patient.profile.account"
              params:
                profileID: patient.id
                orgID: organization.id
              hide: do ->
                !organization.shortName or
                organization.properties.portals?.patient?.sections?.account?.hide
            }
            {
              text: "Protection Plan"
              state: "patient.profile.insurance"
              params:
                profileID: patient.id
                orgID: organization.id
              hide: do ->
                return !organization.properties.features?.travelInsurance ||
                  !patient.groups
            }
            {
              text: "CampGrams"
              state: "patient.profile.funkNotes"
              params:
                profileID: patient.id
                orgID: organization.id
              hide: !organization.properties.enableFunkNotes
            }
            {
              text: "Emergency Plan"
              state: "patient.profile.emergency"
              params:
                orgID: organization.id
              hide: !organization.properties.emergency?.enabled
            }
            {
              text: "Custom Labels"
              state: "patient.profile.labels"
              params:
                profileID: patient.id
              hide: !organization.properties.enableOliversLabels
            }
            {
              text: "Trusted Contacts"
              state: "patient.profile.trustedContacts"
              params:
                profileID: patient.id
              hide: !organization.properties.features?.attendance?.enabled
            }
          ]

        profileUser = _.find(profilesUsers, { profileID: patient.id })
        if profileUser && profileUser.campgramsOnly
          nav.subnav = _.filter nav.subnav, (s) -> s.state == "patient.profile.funkNotes"

        $rootScope._nav.push nav

      if organization.shortName
        unless organization.properties?.portals?.patient?.noNewParticipants
          $rootScope._nav.push
            text: "New " + $rootScope.division.patient.singular.capitalize()
            state: "patient.newProfile"
            icon: "plus"
            btn: "primary"

    roleData: ($rootScope, organization, session) ->
      accessOrg = _.find session?.access?.organizations or [], (o) -> o.id is organization.id
      if accessOrg
        $rootScope.roleData = accessOrg.roleData
      else
        $rootScope.roleData = null

    operator: (session, $rootScope) ->
      if session and session.access and session.access.operator
        $rootScope.operator = session.access.operator

    gaTagIDs: (organization, $http) ->
      $http.get("/api/organization/#{organization.id}/settings/ga")
        .then (result) ->
          if !result.data then return null
          gaTagIDs = result.data.map((gaTagInfo) -> gaTagInfo.tagID)
          return gaTagIDs
        .catch (err) -> return

  controller: ($scope, $state, organization, session, patients, $stateParams, user) ->
    $scope.organization = organization
    $scope.access = session.access
    $scope.user = user

    if $state.current.name == "patient"
      if !patients.length
        # There are no patients, make a new one
        return $state.go("patient.newProfile")
      else
        # There are patients, we want to go to the first one
        if $stateParams.r
          $state.go "patient.profile.registration.register", profileID: patients[0].id
        else
          $state.go "patient.profile", profileID: patients[0].id
