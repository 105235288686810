angular.module("dn").directive "qtEmergencyContacts", ->
  templateUrl: "directives/taker/modules/emergency-contacts/qt-emergency-contacts.directive.html"
  restrict: "A"
  scope:
    question: "=qtEmergencyContacts"
    emergencyContacts: "="
  controller: ($scope, $stateParams, HealthForm) ->

    profileID = $stateParams.profileID
    healthForm = HealthForm.get profileID

    upper = (str) -> str.charAt(0).toUpperCase() + str.slice(1)

    $scope.ranges =
      "0": []
      "1": [true]
      "2": [true, true]
      "3": [true, true, true]
      "4": [true, true, true, true]
      "5": [true, true, true, true, true]


    $scope.typeChoices = [
      { label: "Home", value: "home" }
      { label: "Work", value: "work" }
      { label: "Other", value: "other" }
    ]
    $scope.phoneChoices = _.clone $scope.typeChoices
    $scope.phoneChoices.splice 1, 0, { label: "Cell", value: "cell" }


    qContacts = _.keyBy $scope.question.metadata.contacts, "identifier"
    $scope.qContacts = qContacts


    mapContacts = ->
      $scope.emergencyContacts = _.keyBy $scope.emergencyContacts, "identifier"

      # Group contacts by health form identifiers
      _.forIn qContacts, (c, id) ->
        $scope.emergencyContacts[id] ?= {}
        $scope.emergencyContacts[id].profileID = parseInt(profileID)
        $scope.emergencyContacts[id].identifier ?= id
        $scope.emergencyContacts[id].label ?= c.label
        $scope.emergencyContacts[id].addresses ?= []
        $scope.emergencyContacts[id].phones ?= []
        $scope.emergencyContacts[id].emails ?= []

        _.times c.addressesDisplayed, (i) -> $scope.emergencyContacts[id].addresses[i] ?= {}
        _.times c.emailsDisplayed, (i) -> $scope.emergencyContacts[id].emails[i] ?= {}
        _.times c.phonesDisplayed, (i) -> $scope.emergencyContacts[id].phones[i] ?= {}

      # Remove contacts that might be left over
      _.forIn $scope.emergencyContacts, (value, key) ->
        if not _.includes _.keys(qContacts), key then delete $scope.emergencyContacts[key]

    mapContacts()


    ###
    #   Completion Functions
    ###
    propertyComplete = (contact, attr, amount) ->
      amountComplete = (_.filter contact[attr], (a) -> a.type and a.value).length
      amountComplete >= parseInt(amount)

    $scope.complete = (contact) ->
      if $scope.question?.expiration and contact?.updated
        return false if $scope.question.expiration > contact.updated
      complete = true
      if not contact.name or not contact.relationship then complete = false
      refContact = $scope.qContacts[contact.identifier]
      _.each ["addresses", "phones", "emails"], (attr) ->
        complete = false if not propertyComplete contact, attr, refContact["#{attr}Required"]
        return
      if not refContact.required then complete = true
      contact.complete = complete
      complete

    healthForm.completeFns[$scope.question.id].push ->
      _.each _.values($scope.emergencyContacts), (c) ->
        $scope.complete c
        return
      _.every _.values($scope.emergencyContacts), (c) -> c.complete


    ###
    #   Saving Queue
    ###

    saveFn = (nothing, fn=(->)) ->
      saveContacts = _.values $scope.emergencyContacts
      return fn() unless saveContacts.length
      healthForm.save("emergency-contacts", profileID, saveContacts).success (contacts) ->
        _.map contacts, (c) -> $scope.emergencyContacts[c.identifier].id = c.id
        fn()

    saveQueue = async.queue saveFn, 1
    addSave = _.debounce ->
      saveQueue.push saveFn
    , 1000


    initialized = false
    $scope.$watch "emergencyContacts", (fresh, stale) ->
      healthForm.checkComplete[$scope.question.id]()
      if !initialized then return initialized = true
      if fresh is stale then return
      do addSave
    , true
