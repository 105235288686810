angular.module("dn").directive "ledgerFilter", ->
  templateUrl: "states/manager/finances/ledgers/filter/ledger-filter.directive.html"
  restrict: "A"
  scope:
    crebits: "=ledgerFilter"
    organization: "&"
    ledger: "@"
    ledgerConfig: "="
    # Created to be used w/ corresponding values in the org/participant ledger
    # line-items states so we can tell the user when keyset loading is finished.
    itemsRemaining: "="
  controller: ($scope, $stateParams, $filter, $http, $timeout) ->
    $scope.ledger ?= "participant"

    orgTZ = $scope.organization().properties.timezone || 'UTC'
    $scope.today = moment.tz(orgTZ).format('YYYY-MM-DD')

    $scope.yesNo = [
      {label: "Yes", value: true},
      {label: "No", value: false}
    ]

    defaultFilterOptions = {
        export: false,
        exportUrl: null,
        print: false,
        printFn: -> return window.print(),
        dates: true,
        typeSelect: false,
        toggleSplit: false,
        toggleDeactivated: false,
        fromReg: false,
        regTo: false
      }

    $scope.ledgerConfig = _.defaults($scope.ledgerConfig, defaultFilterOptions)

    $scope.exportResults = (filter) ->

      $scope.exporting = true

      url = $scope.ledgerConfig.exportUrl
      if !url
        url = "/api/organizations/#{$stateParams.orgID}/crebits.csv"
        url += "?ledger=#{$scope.ledger}"
        url += "&dates=#{filter.date.start}|#{filter.date.finish}"
        url += "&text=#{filter.text}" if filter.text
        url += "&tag=#{filter.tag}" if filter.tag
        url += "&group=#{filter.group}"
        url += "&profile-group=#{filter.registeredTo}"
        url += "&split=#{!!filter.split}"
        url += "&includeDeactivatedRegistrations=#{!!filter.includeDeactivatedRegistrations}"

      $http.get(url).then (result) ->
        # Unfortunately-duplicated logic. Ref the roster state for comments
        csvData = new Blob([result.data], {type: "text/csv;charset=utf-8;"})
        filename = "line-items.csv"

        if window.navigator.msSaveOrOpenBlob
          window.navigator.msSaveOrOpenBlob(csvData, filename)
        else
          csvURL = window.URL.createObjectURL(csvData)
          a = document.createElement("a")
          document.getElementById("content").appendChild(a)
          a.href = csvURL
          a.setAttribute("download", filename)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(csvURL)
          a = csvURL = null
        csvData = csvString = null

        $scope.exporting = false

      .catch (err) ->
        $scope.exporting = false
        flash 'Error retrieving export'

    $scope.groups = do ->
      groups = []
      return if !$scope.organization()
      addGroup = (group) ->
        group.chain ?= []
        group.chain.push group.name
        groups.push
          id: group.id
          chain: group.chain
          phase: group.phase
        return if !group.children or !group.children.length
        _.each _.sortBy(group.children, "name"), (child) ->
          child.chain = _.clone(group.chain)
          addGroup child
      addGroup $scope.organization()
      _.map groups, (g, index) ->
        g.chain.shift() if g.chain.length > 1
        {
          value: g.id
          label: g.chain.join " » "
          phase: g.phase
          hide: true
        }

    $scope.groups[0].value = "all"

    # Initialize these now, populate them in the watcher
    # Must be defined ABOVE the following two functions cos their side effects
    # operate on these arrays and coffeescript sux.
    visibleGroups = []
    $scope.tags = []

    # Thanks to keyset pagination, we have fast load times. We also have an
    # annoying problem. Groups and Tags options for filters are determined by
    # mapping through the crebits. Since we no longer start with all the crebits,
    # Tags and Groups options need to be updated each time we load more crebits.

    updateVisibleGroups = (crebits) ->
      # For each crebit in the provided array...
      _.each crebits, (crebit) ->
        # Safety check to make sure the crebit exists - it always should
        if crebit
          # Assuming it does, push any associated groupIDs into visibleGroups
          visibleGroups.push crebit.groups
          visibleGroups.push crebit.profile?.registeredTo
        else
          flash "Error Parsing Groups - group filters may not work as expected."

      # Then, flatten visibleGroups (we've probably pushed some arrays in),
      # get only unique groupIDs, and sort them in ascending order.
      visibleGroups = _(visibleGroups).flattenDeep().uniq().sortBy().value()

      # For each group (retrieved from the organization's data)
      return $scope.groups.forEach (group, index) ->
        # If it's top level group OR its ID is in visibleGroups, set hide false
        if index is 0 or _.includes visibleGroups, group.id
          return group.hide = false
        # Otherwise, hide dat thang
        else
          return group.hide = true

    updateTags = (crebits) ->
      rawTags = []
      # For each crebit in the supplied array...
      _.each crebits, (crebit) ->
        return if !crebit.description
        tag = crebit.description.match /^\[(.+)\]/
        return if !tag
        # Grab the key word from the tag and push it into rawTags
        rawTags.push tag[1]

      # Then, uniq and sort those values
      uniqTags = _(rawTags).sortBy().sortedUniq().value()

      # Finally, for each uniqe tag...
      # 1) Create options for the drop down
      # 2) Merge those options with existing options and de-dup by value.
      return $scope.tags = _(uniqTags).map((tag) -> { value: tag, label: tag }).unionBy($scope.tags, 'value').value()

    $scope.filter =
      date:
        start: '2014-01-01'
        finish: $scope.today
      includeDeactivatedRegistrations: false
      split: false
      tag: ""
      group: "all"

    crebitTagMatch = (crebit, filterTag) ->
      matchOn = if filterTag then new RegExp('^\\[' + filterTag + '\\]', 'g') else ''
      return crebit.description.match(matchOn)

    textFilter = (crebit, text) ->
      visibleCrebitData = _.pick crebit, ['description']
      if $scope.ledger == "participant" and crebit.profile
        visibleCrebitData.name = "#{crebit.profile.givenName} #{crebit.profile.familyName}"
      # Organization ledger crebit amounts are displayed with fees applied to them
      if $scope.ledger == "organization"
        if crebit.amount >= 0
          visibleCrebitData.amount = $filter('currency')((crebit.amount + (crebit.flatFee || 0) + (crebit.variableFee || 0)) / 100)
        else
          visibleCrebitData.amount = $filter('currency')(((crebit.amount * -1) - (crebit.flatFee || 0) - (crebit.variableFee || 0)) / 100)
      else
        visibleCrebitData.amount = $filter('currency')(crebit.amount / 100)
      visibleCrebitData.created = moment.tz(crebit.created, orgTZ).format('MM/DD/YYYY')
      return $filter("text") visibleCrebitData, text

    $scope.runFilter = ->

      $scope.filtering = true

      tags = $scope.filter.tag.split(',')

      # Return this timeout so that we can start up our spinner
      return $timeout(() ->
        _.map $scope.crebits, (crebit) ->
          crebit._hide = false

          # hide deactivated registration crebits if the registeredTo $scope.filter is selected but includeDeactivated is not
          if $scope.ledgerConfig.toggleDeactivated && $scope.filter.registeredTo and !$scope.filter.includeDeactivatedRegistrations and !crebit.registrationActive
            return crebit._hide = true

          if moment.tz(crebit.created, orgTZ) < moment.tz($scope.filter.date.start, orgTZ)
            return crebit._hide = true

          # Set time to 11:59:59PM to include all line items from that day (defaults to midnight, stripping our all items from today)
          if moment.tz(crebit.created, orgTZ) > moment.tz($scope.filter.date.finish, orgTZ).endOf('day')
            return crebit._hide = true

          # String.split (line 176) always returns an array w/ at least one
          # entry, so always check that the first entry has length
          if tags[0].length && !_(tags).map((tag) -> crebitTagMatch(crebit, tag)).compact().value().length
            return crebit._hide = true

          if $scope.filter.text and !textFilter(crebit, $scope.filter.text)
            return crebit._hide = true

          if $scope.ledgerConfig.toggleSplit && !$scope.filter.split && crebit.split
            return crebit._hide = true

          if $scope.ledgerConfig.toggleSplit && $scope.filter.split && crebit.splitParent
            return crebit._hide = true

          if $scope.filter.group and crebit.groupID isnt parseInt($scope.filter.group) and $scope.filter.group isnt "all" and !_.includes(_.compact(crebit.groups), parseInt($scope.filter.group))
            return crebit._hide = true

          if $scope.filter.registeredTo and $scope.filter.registeredTo isnt "all" and !_.includes(_.compact(crebit.profile?.profileGroups), parseInt($scope.filter.registeredTo))
            return crebit._hide = true

        # Turn the spinner off
        $scope.filtering = false

      , 300)  # 300ms So we always show the spinner, even if it's short. It reinforces that we're doing something when we click the button.

    $scope.runFilter()

    $scope.$watch "crebits.length", (newLength, oldLength) ->
      updateCrebits = []
      # We rely on the first run to updateTags and updateVisibleGroups
      # If it's the first run, look at $scope.crebits
      if newLength == oldLength
        updateCrebits = $scope.crebits
      else
        # Otherwise, only look at the most recently loaded crebits
        updateCrebits = $scope.crebits.slice(oldLength)
      updateVisibleGroups(updateCrebits)
      updateTags(updateCrebits)
