angular.module('dn').directive('daxkoSettings', function () {
  return {
    templateUrl: 'directives/daxko-settings/daxko-settings.directive.html',
    restrict: 'E',
    scope: {
      group: '=',
      organization: '=',
    },
    controller: function ($http, $scope, flash, $filter) {
      // Initialize variables
      const baseURL = `/api/organizations/${$scope.organization.id}/daxko-settings`;
      $scope.loadErr = false;
      $scope.daxkoSettings = {
        enabled: false,
        scope: '',
      };
      $scope.hasDaxko = false;
      $scope.isOrganization = $scope.group.id === $scope.organization.id;
      $scope.hasEditPermissions = $filter('permissionVisible')({ settings_basics: 'edit' });
      $scope.requestInProgress = false;
      $scope.yesNo = [
        { label: 'No', value: false },
        { label: 'Yes', value: true }
      ];
      // Feature flag
      $scope.featureEnabled = true;

      if ($scope.featureEnabled) loadSettings();

      $scope.allowSave = () => {
        if (!$scope.hasDaxko) {
          if (!$scope.daxkoSettings.enabled) {
            return false;
          } else {
            return $scope.daxkoSettings.scope && $scope.daxkoSettings.scope !== '';
          }
        } else {
          return true;
        }
      };

      $scope.save = () => {
        $scope.requestInProgress = true;

        if ($scope.isOrganization) {
          // No need to catch errors here, since errors are handled by the called
          // functions.
          saveDaxkoSettings().then(() => $scope.requestInProgress = false);
        }
      };

      function loadSettings() {
        $http.get(baseURL).then((response) => {
          if (response.status === 200) {
            $scope.daxkoSettings = response.data;
            $scope.daxkoSettings.enabled = true;
            $scope.loadError = false;
            $scope.hasDaxko = true;
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          $scope.loadError = true;
          flash('Unable to load Daxko Settings');
        });
      }

      /**
       * Insert, update, or deactivate daxko_settings data.
       */
      function saveDaxkoSettings() {
        if ($scope.hasDaxko) {
          return ($scope.daxkoSettings.enabled ? updateSettings() : deactivateSettings());
        } else {
          if ($scope.daxkoSettings.enabled) {
            return insertSettings();
          }
        }

        // Don't think we can ever get here, but returning a promise so we have a
        // consistent return type.
        return Promise.resolve();
      }

      function updateSettings() {
        const url = `${baseURL}/${$scope.daxkoSettings.id}`;

        return $http.put(url, $scope.daxkoSettings).then((response) => {
          if (response.status === 200) {
            $scope.daxkoSettings = response.data;
            $scope.daxkoSettings.enabled = true;
            flash('Updated Daxko Settings');
          } else {
            flash('Unable to update Daxko Settings');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flash('Unable to update Daxko Settings');
        });
      }

      function deactivateSettings() {
        const url = `${baseURL}/${$scope.daxkoSettings.id}`;
        return $http.delete(url).then((response) => {
          if (response.status === 204) {
            flash('Deactivated Daxko Settings');
            // Clear fields on scope after deactivation
            Object.keys($scope.daxkoSettings).map((key) => {
              if (key !== 'enabled') {
                $scope.daxkoSettings[key] = null;
              }
            });
            $scope.hasDaxko = false;
          } else {
            flash('Unable to deactivate Daxko Settings');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flash('Unable to deactivate Daxko Settings');
        });
      }

      function insertSettings() {
        const url = baseURL;
        $scope.daxkoSettings.org_id = $scope.organization.id;

        return $http.post(url, $scope.daxkoSettings).then((response) => {
          if (response.status === 201) {
            $scope.daxkoSettings = response.data;
            $scope.daxkoSettings.enabled = true;
            $scope.hasDaxko = true;
            flash('Inserted Daxko Settings');
          } else {
            flash('Unable to insert Daxko Settings');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flash('Unable to insert Daxko Settings');
        });
      }
    }
  };
});
