angular.module("dn").directive "qtInsurances", ->
  restrict: "A"
  templateUrl: "directives/taker/modules/insurances/qt-insurances.directive.html"
  scope:
    question: "=qtInsurances"
    insurances: "="
  controller: ($scope, $stateParams, HealthForm, Images, WhatDivision) ->

    $scope.division = WhatDivision
    profileID = $stateParams.profileID
    healthForm = HealthForm.get profileID

    $scope.coverageTypes = [
      { label: "Medical", value: "medical" }
      { label: "Prescription", value: "rx" }
      { label: "Medical and Prescription", value: "both"}
    ]
    $scope.insurancesCopy = _.cloneDeep $scope.insurances
    $scope.insurancesCopy = _.filter $scope.insurancesCopy, (i) ->
      _.some $scope.question.metadata.insurances, (meta) ->
        meta.identifier is i.identifier

    $scope.files = {}

    # Group insurances by identifier
    groupInsurances = ->
      return unless _.isArray($scope.insurancesCopy)
      $scope.insurancesCopy = _.keyBy $scope.insurancesCopy, "identifier"
    groupInsurances()

    # Process existing files for insurance
    processFiles = (insurance) ->
      return unless insurance.file
      Images.getForProfile(profileID, insurance.file).then (files) ->
        $scope.files[insurance.identifier] = files

    # Prepend files for each insurance. Each gets its own action attribute
    processAction = ->
      _.map $scope.insurancesCopy, (insurance) ->
        # reassign the scope insurances object with the new files/updated timestamp etc
        # else the files don't get updated and they won't show until a reloadzzzzz
        _.map $scope.insurances, (i, index) ->
          if i.identifier is insurance.identifier
            $scope.insurances[index] = insurance
        insurance.action = "/api/profiles/#{profileID}/answers/upload"
        if insurance.file then insurance.action += "?prepend=#{insurance.file}"


    # Process existing coverage for pre-selection of coverage type
    processCoverage = (insurance) ->
      if insurance.medicalCoverage
        if insurance.rxCoverage then insurance.coverageType = "both"
        else insurance.coverageType = "medical"
      else if insurance.rxCoverage
        insurance.coverageType = "rx"

    # Assign identifiers to insurances that aren't already set up
    assignIdentifier = ->
      _.map $scope.question.metadata.insurances, (i) ->
        $scope.insurancesCopy[i.identifier] ?= { profileID: profileID, identifier: i.identifier }

    $scope.reference = _.keyBy $scope.question.metadata.insurances, "identifier"

    # Load initial coverage type for insurances and load existing images
    setup = ->
      do groupInsurances
      do assignIdentifier
      do processAction
      _.map $scope.insurancesCopy, (i) ->
        processCoverage i
        processFiles i
        i.onComplete = (response) -> i.file = response.key
        return

    do setup

    # Set the individual coverage types based on which choice they selected
    updateCoverage = (insurance) ->
      return false unless insurance.coverageType
      switch insurance.coverageType
        when "medical"
          insurance.rxCoverage = false
          insurance.medicalCoverage = true
        when "rx"
          insurance.rxCoverage = true
          insurance.medicalCoverage = false
        else
          insurance.rxCoverage = true
          insurance.medicalCoverage = true

    # Update coverage types when they change
    $scope.$watch ->
      return _.map $scope.insurancesCopy, "coverageType"
    , (types, stale) ->
      return unless types isnt stale
      _.map $scope.insurancesCopy, (i) -> updateCoverage i
    , true

    ###
    #  Saving/completion functionality
    ###

    # Individual completeness for an insurance
    $scope.complete = (insurance) ->
      if $scope.question?.expiration and insurance?.updated
        return false if $scope.question.expiration > insurance.updated
      return false unless insurance
      if !$scope.reference[insurance.identifier].required then return true
      required = ["company", "phone", "policyNumber", "policyHolder", "coverageType", "file"]
      _.every required, (r) ->
        if r in ["policyBin", "policyPcn"]
          return insurance[r] or insurance.coverageType is "medical"
        return insurance[r]?.length

    # Push to question complete functions
    healthForm.completeFns[$scope.question.id].push ->
      _.every $scope.insurancesCopy, (i) -> $scope.complete i

    # Save fn passed to worker queue to avoid overwriting things
    saveFn = (nothing, fn=(->)) ->
      healthForm.save("insurances", profileID, _.values($scope.insurancesCopy)).success (insurances) ->
        _.map insurances, (i) ->
          if i.identifier and $scope.insurancesCopy[i.identifier]
            $scope.insurancesCopy[i.identifier].id = i.id
            if !(_.find $scope.insurances, (ins) -> ins.identifier == i.identifier)
              $scope.insurances.push i
        do setup
        fn()

    saveQueue = async.queue saveFn, 1

    addSave = _.debounce ->
      saveQueue.push saveFn
    , 1000

    $scope.$watch "insurancesCopy", (fresh, stale) ->
      if fresh is stale then return
      do healthForm.checkComplete[$scope.question.id]
      do processAction
      do addSave
    , true
