lib.registerState('manager.prescreening.browse', {
  url: '/browse',
  templateUrl: 'states/manager/prescreening/browse/browse.state.html',
  resolve: {
    title($rootScope, GroupBranding, organization) {
      // Have to use `organization` because child resolves happen _before_ parent resolves, so
      // $rootScope.organization isn't set yet here when we hard refresh.
      return $rootScope._title = `${GroupBranding.getPrescreeningAlias(organization)} Reporting`;
    },
  },
  controller: function($filter, $location, $rootScope, $scope, $timeout, organization, session, reviewTypes) {
    'use strict';
    // Initialize scope variables for the filter-builder
    $scope.report = { date: moment().format('YYYY-MM-DD') };
    $scope.screenings = [];
    $scope.lists = [];
    $scope.activeList = { text: $location.search().filters || '' };
    $scope.user = session.user || {};
    $scope.filters = [];
    organization.reviewTypes = reviewTypes;

    // Initialize variables for the screenings display
    $scope.formatterConfig = {
      tz: $rootScope.organization.properties.timezone,
      temperatureUnit: 'f'
    };

    $scope.exportCSV = () => {
      $scope.exportURL = `/api/organizations/${organization.id}/screenings.csv?filters=${$scope.activeList.text}`;
      $timeout(() => {
        document.getElementById('export-download').click();
      });
    };

    const viewPermissions = $filter('permissionVisible')({ prescreening: 'view' });

    // Add the default filter
    if (viewPermissions && !$location.search().filters) {
      $timeout(() => {
        const Filter = window.lib.Filter;
        const todayYmd = moment().format('YYYY-MM-DD');
        const createdTodayFilter = new Filter(`[screenings].createdDate(dateis:${todayYmd})`);
        $scope.filters.push(createdTodayFilter);
      });
    }
  }
});
