const Resource = angular.injector(['dn']).get('Resource');

const TRUSTEE_ROUTE = '/api/organizations/:orgID/trusted-contacts/:email/profiles';

angular.module('dn').factory('TrustedContact', function ($http, $rootScope) {

  const TrustedContact = new Resource({
    route: '/api/organizations/:orgID/profiles/:profile_id/trusted-contacts/:id',
    schema: {
      // This is dumb and it kills me, but we need this here so we can interpolate it into the route
      // for saving and loading. If you have better ideas PLEASE make em happen.
      orgID:        { type: 'integer', required: true },
      profile_id:   { type: 'integer', required: true },
      name:         { type: 'text',    required: true },
      relationship: { type: 'text',    required: true },
      emails:       { type: 'json',    required: true },
      phones:       { type: 'json',    required: true },
      address:      { type: 'json',    required: true },
      picture:      { type: 'text' },
      pin:          { type: 'text' },
    }
  });

  TrustedContact.prototype.getTrustees = function() {
    const orgID = $rootScope.organization.id;
    // Swap in orgID
    let thisTrusteeRoute = TRUSTEE_ROUTE.replace(/:orgID/, orgID);
    // Swap in email
    thisTrusteeRoute = thisTrusteeRoute.replace(/:email/, this.emails.primary_value);
    // Return the Promise so we can let the caller handle everything
    return $http.get(thisTrusteeRoute);
  };

  return TrustedContact;
});
