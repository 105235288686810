lib.registerState "manager.settings",
  url: "/settings/:groupID"
  templateUrl: "states/manager/settings/settings.state.html"
  resolve:
    group: (Group, $state, $stateParams, $q, organization) ->
      # don't load groups outside of this org
      orgGroup = window.dnim.gimmeABeer({
        arrayToSearch: [organization],
        searchElements: [+$stateParams.groupID],
        searchElementKey: 'id',
        arrayNestingKey: 'children',
      })
      if orgGroup[0]
        # TODO: `group` is used in darn near every child state
        #  if our groupID is the orgID, can we avoid the load and return the org directly?
        #  might even be able to get away with it for a child group, too
        id = $stateParams.groupID or $stateParams.orgID
        new Group(id).load().then (group) ->
          group
      else
        $state.go 'manager.profiles', { orgID: organization.id }, { reload: true }
        $q.reject "Forbidden" # this stops other resolves from firing, controller from loading

  controller: ($filter, $scope, $state, group, organization) ->
    $scope.group = group
    $scope.parentGroup = group.parent
    $scope.childGroups = group.children

    settingsPermissions =
      settings_medications: 'view'
      settings_tags: 'view'
      settings_profiles: 'view'
      settings_health_log: 'view'
      settings_branding: 'view'

    if organization and organization.shortName
      settingsPermissions['settings_registrations'] = 'view'

    goToState = "manager.settings.general"

    if !$filter('permissionVisible')(settingsPermissions)
      if $filter('permissionVisible')({ settings_billing: 'view' })
        goToState = "manager.settings.billing"
      else if $filter('permissionVisible')({ settings_questionnaire: 'view' })
        goToState = "manager.settings.questionnaire"
      else if $filter('permissionVisible')({ settings_branding: 'view' })
        goToState = "manager.settings.branding"

    if $state.current.name is "manager.settings" then $state.go goToState
