lib.registerState "oper.users",
  url: "/users"
  templateUrl: "states/oper/users/users.state.html"
  controller: ($scope, $http, $state, flash) ->

    $scope.data = {}
    $scope.noneFound = false

    # Search for a user by their email address
    $scope.findUser = ->
      $scope.searching = true
      type = "name"
      type = "email" if $scope.search.email
      type = "phone" if $scope.search.phone
      type = "address" if $scope.search.address
      search = encodeURIComponent($scope.search[type])
      $http.get("/api/users/user-search?type=#{type}&search=#{search}").then (
        (result) ->
          $scope.searching = false
          $scope.foundUser = null
          if !result.data.length
            $scope.noneFound = true
            $scope.foundOper = false
          else
            $scope.foundUser = result.data
            $scope.foundOper = result.data[0].operator
        ), (error) ->
          $scope.searching = false
          $scope.foundUser = null
          $scope.noneFound = true

    # Reset their password and reload
    $scope.resetPassword = ->
      return unless $scope.data.password
      $http.post("/api/operator/users/#{$scope.foundUser[0].id}/admin-reset", $scope.data).then (result) ->
        if _.get result, 'data.success', false
          flash "Password Reset for #{$scope.foundUser[0].email}"
          $state.reload $state.current.name
        else
          flash "Error resetting password for #{$scope.foundUser[0].email}: #{JSON.stringify(result.data)}"

    $scope.$watch "search", (search, was) ->
      return unless search and was
      if $scope.noneFound then $scope.noneFound = false
      if search.name    > was.name    then $scope.search = _.assign $scope.search, { email: "", phone: "",   address: "" }
      if search.email   > was.email   then $scope.search = _.assign $scope.search, { name: "",  phone: "",   address: "" }
      if search.address > was.address then $scope.search = _.assign $scope.search, { name: "",  phone: "",   email: "" }
      if search.phone   > was.phone   then $scope.search = _.assign $scope.search, { name: "",  address: "", email: "" }
    , true
