/* eslint-disable no-unused-vars */
lib.registerState('patient.profile.account.pay', {
  url: '/pay?plans',
  templateUrl: 'states/patient/profile/account/pay.state.html',
  resolve: {
    title: function ($rootScope, profile) {
      $rootScope._title = profile.givenName + ' ' + profile.familyName + ' / Payment';
    },
    paymentMethods: function (session, organization, $http) {
      return $http.get('/api/users/' + session.userID + '/payment-methods').then(function (result) {
        return window.lib.methodFilterRestriction(result.data, organization);
      });
    },
    orgFeeSettings: function($http, organization) {
      feeFeatureFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees');

      if (feeFeatureFlag) {
        const url = `/api/organization/${organization.id}/finance/org-fee-settings`;

        return $http.get(url).then((res) => {
          if (res.status === 200) {
            return {
              feePassedOn: res.data.feePassedOn,
              bank: {
                flat: res.data.bankFlatFee,
                variable: res.data.bankVariableRate,
              },
              card: {
                flat: res.data.cardFlatFee,
                variable: res.data.cardVariableRate
              }
            };
          }
          return false;

        }).catch((err) => {
          if (err.status !== 404) return flash('Unable to load organization fee settings');
          return false;
        });
      } else return false;
    }
  },

  controller: function ($http, $rootScope, $scope, $state, $stateParams, PayProcessor, flash, organization, orgFeeSettings, paymentMethods, profile, session) {
    $scope.merchantName = window.lib.merchantName($rootScope.organization.orgPayProcessors);

    /* Test accounts don't get to look at this shitty page */
    if (_.includes(session.user.options, '"testAccount"=>"true"')) {
      return window.swal({
        type: 'warning',
        title: 'Test Account',
        text: 'Test accounts are restricted from making payments.'
      }, function(isConfirm) {
        return $state.go('^');
      });
    }

    /* If there's no acct balance and no donations on the org, they don't get to look at this shitty page */
    if (!$scope.organization.properties.donation && $scope.balance() < 1) {
      return window.swal({
        title: 'No Payment Needed',
        text: 'You do not have a balance to pay.'
      }, function(isConfirm) {
        return $state.go('^');
      });
    }

    $scope.organization = organization;
    $scope.supportsSavedPaymentMethods = PayProcessor.features.savedPaymentMethods;

    $scope.paymentPlans = $stateParams.plans;
    $scope.back = function() { $state.go('^'); };

    // exit state if payment plans shouldn't be shown. hidePaymentPlans is set in account state
    if ($scope.paymentPlans && $scope.hidePaymentPlans) return $scope.back();

    $scope.paymentMethods = paymentMethods;

    $scope.amountChoices = [
      { value: 'full', label: 'Full Balance', hide: $scope.balance() < 1 },
      { value: 'partial', label: 'Another Amount', hide: $scope.balance() < 1},
    ];
    if ($scope.organization.properties.donation) {
      $scope.amountChoices.push({ value: 'donation', label: 'Make a Donation'});
    }
    $scope.amountChoice = $scope.balance() > 0 ? 'full' : 'donation';
    if ($scope.paymentPlans && PayProcessor.features.paymentPlans) {
      $scope.amountChoices = [
        { value: 'plan', label: 'Set Up a Payment Plan', hide: !$scope.balance() || profile.organization.properties.disablePaymentPlans}
      ];
      $scope.amountChoice = 'plan';
    }
    $scope.newMethod = PayProcessor.features.savedPaymentMethods
      ? !paymentMethods.length
      : true;
    if (paymentMethods.length) $scope.savedPaymentMethod = paymentMethods[0].value;

    $scope.feeFeatureFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees');

    $scope.passingOnFee = orgFeeSettings.feePassedOn;
    $scope.convenienceFeeTypes = { bank: orgFeeSettings.bank, card: orgFeeSettings.card };
    $scope.paymentType = null;
    $scope.convenienceFee = 0;
    $scope.variableFee = 0;
    $scope.flatFee = 0;
    $scope.planMonthlyWithFee = 0;

    // Variable to track if there is a difference in the first month's fee vs the rest of the months' fees.
    // If so, show remainderWithFee.
    $scope.hasDiff = false;
    $scope.remainderWithFee = null;

    function getPaymentType() {
      $scope.paymentMethods.forEach(method => {
        if (method.value === parseInt($scope.savedPaymentMethod)) {
          $scope.paymentType = method.meta.category;
          return;
        }
      });
    }

    $scope.amountToPay = function () {
      let amount = $scope.amount;

      if ($scope.amountChoice === 'full') amount = $scope.balance();
      else if ($scope.amountChoice === 'donation') amount = $scope.donationAmount || 0;
      else amount = $scope.amount || 0;

      if ($scope.passingOnFee) {
        // We need to check if the amount exists otherwise the fee will be displayed on the pay now button,
        // even if the amount is $0.00. Meaning it will allow the user to pay the fee without anything else.
        if (!amount) {
          $scope.totalAmount = 0;
          return 0;
        }
        // calculateFee will set the convenience fee on the scope
        calculateFee(amount);
        $scope.totalAmount = amount + $scope.convenienceFee;
        return amount;
      }

      totalAmount = amount;
      return amount;
    };

    /**
     * Checks if the fee is the same for the first month's new amount, when a remainder is added
     */
    function checkFeeWithRemainder() {
      if (!$scope.passingOnFee) return;

      const monthlyPayment = Math.floor($scope.plan.totalAmount / $scope.plan.remaining);
      const remainder = $scope.remainder;

      const convFee0 = calculateFee(monthlyPayment, true);
      const convFee1 = calculateFee(monthlyPayment + remainder, true);

      if (convFee0 !== convFee1) {
        $scope.hasDiff = true;

        const delta = Math.abs(convFee0 - convFee1);
        $scope.remainderWithFee = remainder + delta;

      } else $scope.hasDiff = false;
    }

    $scope.whichPaymentMethod = function () {
      return !$scope.newMethod ? $scope.savedPaymentMethod : $scope.newPaymentMethod;
    };

    $scope.$watch('passingOnFee', () => {
      calculateFee($scope.amount, false);
    });

    $scope.$watch('savedPaymentMethod', () => {
      watchPaymentMethods();
    });

    $scope.$watch('newPaymentMethod', () => {
      watchPaymentMethods();
    });

    $scope.$watch('newMethod', (value) => {
      watchPaymentMethods();
    });

    function watchPaymentMethods() {
      if ($scope.passingOnFee) {
        if ($scope.newPaymentMethod && $scope.newMethod) $scope.paymentType = $scope.newPaymentMethod.category;
        else getPaymentType();

        if ($scope.amountChoice === 'plan') {
          calculateMonthly($scope.plan);
        }

        calculateFee($scope.balance(), false);
        checkFeeWithRemainder();
      }
    }

    function calculateFee(total, justChecking) {
      if (!$scope.passingOnFee) return;

      const convenience = $scope.convenienceFeeTypes[$scope.paymentType] || {};

      $scope.variableFee = convenience.variable
        ? convenience.variable
        : 0;
      $scope.flatFee = convenience.flat
        ? convenience.flat
        : 0;

      // Only sets the $scope fee if this isn't being called by checkFeeWithRemainder
      if (!justChecking) {
        $scope.convenienceFee = Math.ceil(($scope.variableFee / 100) * total) + $scope.flatFee;
      }

      return Math.ceil(($scope.variableFee / 100) * total) + $scope.flatFee;
    }

    $scope.payload = function (token) {
      var crebit = {
        profileID: profile.id,
        amount: ($scope.amountToPay() || 0) * -1,
        ledger: 'organization'
      };
      if ($scope.amountChoice === 'donation') crebit.makeDonation = true;
      var paymentMethod = $scope.whichPaymentMethod();
      if (_.isNumber(+paymentMethod)) crebit.paymentMethodID = +paymentMethod;
      if (_.isPlainObject(paymentMethod)) {
        crebit.paymentMethod = paymentMethod;
        crebit.paymentMethod.userID = $scope.savedPaymentMethod ? session.userID : null;
      }

      crebit.recaptchaToken = token;
      return crebit;
    };

    $scope.process = function () {
      $scope.paying = true;

      let recaptchaError = false;
      let goBack = false;
      const buttonText = () => {
        if (recaptchaError) return 'Try Again';
        else if (goBack) return 'Go Back';
        else return 'Double Check';
      };

      grecaptcha.execute(window.lib.recaptchaKeys.v3, { action: 'submit'}).then((token => {
        var url = '/api/users/' + session.userID + '/profiles/' + profile.id + '/crebits';
        $http.post(url, $scope.payload(token)).success(function (data) {
          flash($scope.amountChoice === 'donation' ? 'Thanks for your donation!' : 'Payment Successful');
          $state.go('^', undefined, {reload: true});
        }).error(function (data) {
          if (data === window.dnim.constants.errors.CARD_NOT_ACCEPTED) {
            window.swal(window.lib.cardNotAcceptedSwalConfig($scope.organization, data));
          } else {
            let errText = 'Please make sure your payment information is correct and up-to-date.';
            if (data) {
              if (data === 'Our robots think you might be a robot. Please try again.') {
                errText = data;
                recaptchaError = true;
              } else if (data.code === 'BAD-TOKEN') {
                errText = 'Our robots think you might be a robot. Please try again.';
                recaptchaError = true;
              } else if (data === "We're having a problem communicating with the payment processing gateway. Please try again later.") {
                errText = data;
              } else if (data === 'Error connecting with payment processor') {
                errText = data;
                goBack = true;
              } else {
                errText += '\nReason: ' + data;
              }
            }
            window.swal({
              title: 'Payment Failed',
              text: errText,
              type: 'warning',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: buttonText()
            }, function() {
              return $state.reload();
            });
          }
          $scope.paying = false;
        });
      }));
    };

    $scope.firstPayment = function (day) {
      if (!day) return null;
      var proposed = moment().date(day);
      if (proposed > moment()) return proposed.format('YYYY-MM-DD');
      return proposed.add(1, 'month').format('YYYY-MM-DD');
    };

    $scope.plan = {
      totalAmount: $scope.balance(),
      remaining: 3,
      day: Math.ceil(Math.random() * 28),
      start: new Date().toISOString().slice(0, 10)
    };

    $scope.$watch('plan', function(plan) {
      calculateMonthly(plan);
      checkFeeWithRemainder();
    }, true);

    /**
     * Calculates the monthly payment for the payment plan.
     * Adds the convenience fee to the monthly payment if the feature flag and passing on fee are enabled.
     * @param {Object} plan - The payment plan object
     */
    function calculateMonthly(plan) {
      $scope.remainder = window.dnim.calculateRemainder(plan.totalAmount, plan.remaining);
      const floorMonthlyPayment = Math.floor($scope.plan.totalAmount / $scope.plan.remaining);

      if ($scope.passingOnFee && $scope.paymentType) {
        calculateFee(floorMonthlyPayment, false);

        const total = $scope.convenienceFee + floorMonthlyPayment;
        $scope.totalAmountWithFee = total * $scope.plan.remaining;
        $scope.planMonthlyWithFee = (Math.floor(total) / 100).toFixed(2);

      } else {
        $scope.monthlyPayment = (Math.floor(floorMonthlyPayment) / 100).toFixed(2);
      }
    }

    $scope.planWillEnd = function (plan) {
      return moment($scope.firstPayment(plan.day)).add(plan.remaining - 1, 'months').format('YYYY-MM-DD');
    };

    if ($scope.organization.properties.paymentPlansDue) {
      $scope.$watch('plan', function (plan) {
        if ($scope.planWillEnd(plan) <= $scope.organization.properties.paymentPlansDue) return;
        if (plan.remaining > 1) return plan.remaining -= 1;
        if (plan.day > 1) return plan.day -= 1;
      }, true);
    }

    $scope.addPlan = function () {
      $scope.paying = true;
      $scope.plan.paymentMethod = $scope.whichPaymentMethod();
      $scope.plan.amount = $scope.plan.totalAmount / $scope.plan.remaining;
      $scope.plan.starts = $scope.firstPayment($scope.plan.day);
      var url = '/api/profiles/' + profile.id + '/payment-plan';

      $http.post(url, $scope.plan).success(function () {
        flash('Payment Plan Activated');
        $state.go('^', undefined, {reload: true});
      }).error(function () {
        window.swal({
          title: 'Couldn\'t Activate Payment Plan',
          type: 'warning',
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Double Check'
        }, function () {
          return $state.reload();
        });
        $scope.paying = false;
      });
    };

  }
});
