lib.registerState('home.auth.login', {
  // Use a wildcard to ensure the page loads for bookmarks to the old route: we
  // need this to match /home/auth/login/<email>, too. UI Router requires us to
  // name our wildcard grab, so I'll be calling it "extra".
  url: '/login*extra',
  templateUrl: 'states/home/auth/login/login.state.html',
  controller: function ($scope, $state, $location, $timeout, invitation, registration) {

    // If we have `extra`, clear it out so we don't confuse people
    if ($state.params.extra) {
      $location.url('home/auth/login');
    }

    // Set to false every time we load the state so that the CANCEL has the
    // expected behavior
    $scope.userAlreadyExists = false;
    $scope.email = null;
    // eslint-disable-next-line no-unused-vars
    const badPassport = $state.params['bad-passport'];

    // If they have an invitation, send em on over to signup
    if (invitation && invitation !== 'ERROR') {
      $scope.checkUserStatus(invitation.inviteEmail).then((userStatus) => {
        if (userStatus.exists && userStatus.invites) {
          $scope.userAlreadyExists = true;
          return $scope.email = invitation.inviteEmail;
        } else if (userStatus.invites && !userStatus.exists) {
          // We need to wrap this redirect in a timeout because the password
          // input's button is set up using timeouts. If we redirect before they
          // finish, we get the infamous "$passwordInput is undefined" error.
          return $timeout(function () {
            return $state.go('home.auth.signup');
          });
        }
      }).catch((err) => {
        return swal({
          type: 'error',
          title: 'An Error Occurred',
          text: JSON.stringify(err)
        });
      });
    } else if (invitation && invitation === 'ERROR') {
      $state.go('home.auth.reinvite', {invitation});
    }

    // Get custom branding text if they have it
    $scope.registration = registration;
    $scope.brandingText = undefined;
    $scope.orgDeactivated = !!($scope.registration || {}).deactivated;
    const {
      title: deacTitle,
      body: deacBody
    } = window.lib.getOrgDeactivatedMessage($scope.registration);
    $scope.deacTitle = deacTitle;
    // If an organization is deactivated, take over the branding message space.
    if ($scope.orgDeactivated) {
      $scope.brandingText = `## ${deacTitle}\n${deacBody}`;
    } else if (
      $scope.registration
      && $scope.registration.properties.branding
      && $scope.registration.properties.branding.text
      && $scope.registration.properties.branding.text.welcome
    ) {
      $scope.brandingText = $scope.registration.properties.branding.text.welcome;
    // If they haven't provided any, fall back to a generic message.
    } else if ($scope.registration && $scope.registration.name) {
      $scope.brandingText = `Welcome to ${$scope.registration.name}! Sign up or log in below.`;
    }

    // If the user ISN'T on mobile, auto-focus the email input
    if ($scope.viewPort.width > 720) {
      $timeout(function() {
        $('#email-input input')[0].focus();
      }, 100);
    }

    // Set a useful title for the login button
    $scope.loginTitle = function() {
      if ($scope.orgDeactivated) return deacTitle;
      else if (!$scope.email || !$scope.password) return 'Enter your email and password to log in';
      else if ($scope.lockedUntil()) return 'Too Many Attempts';
      else return 'Log In';
    };

    // `onEnter` function for the email input. Moves user down to pw field.
    $scope.submitEmail = function() {
      return $('input[type=password]').focus();
    };

    // Necessary so we can use this as an `onEnter` function for mat input
    // It doesn't allow actual function calls, just a supplied function
    $scope.submitPassword = function() {
      $('input[type=password]').blur();
      // Prevent people from submitting if they're `lockedUntil` or rate limited and haven't
      // requested a password reset.
      if ($scope.orgDeactivated || ($scope.lockedUntil() && !$scope.resetRequested)) return;
      return $scope.submitUser('password', $scope.email, $scope.password);
    };
  }
});
