angular.module('dn').directive('coupons', function() {
  "use strict";

  return {
    templateUrl: 'directives/registration-wizard/coupons/coupons.directive.html',
    restrict: 'E',
    scope: {
      coupons: '=',
      addOns: "=",
      groups: '=',
      crebits: '=',
      regData: '=',
      isComplete: '=',
      onContinue: '=',
      bypass: '=',
      flatOrg: '=',
      hasCoupons: '=',
      overBalance: '='
    },
    controller($rootScope, $scope, $filter) {

      // bypass step if every group is waitlisted or if no group has tuition
      if (_.every($scope.groups, g => (!g.registration.tuition && !g.registration.deposit && !addOnSum(g)) || g.waitlistRegistration)){
        return $scope.bypass();
      }

      $scope.isComplete = () => {
        return couponYesOrNo() && (!$scope.hasCoupons || (!overBalanceForGroup() && someCouponSelected()));
      }

      // incomplete if they haven't answered yes/no
      function couponYesOrNo()  {
        return !_.isNull($scope.hasCoupons);
      }

      // not a single group can be overbalance (if they answered yes for hasCoupons)
      function overBalanceForGroup() {
        return Object.values($scope.overBalance).some(groupOverBalance => !!groupOverBalance);
      }

      // must have selected at least a coupon for a group (if they answered yes for hasCoupons)
      function someCouponSelected() {
        return _.some($scope.groups, g => g.selectedCoupons && g.selectedCoupons.length);
      }

      $scope.validGroup = (group) => {
        return !group.waitlistRegistration && (group.registration.tuition || group.registration.deposit || addOnSum(group));
      }

      // Combine the `couponEntry` text for the org and all registrations so
      // that we can put it into the `instruction-text` at the top
      const branding = $scope.flatOrg[0].properties.branding
      $scope.orgText = branding &&
                       branding.text &&
                       branding.text.registration &&
                       branding.text.registration.couponEntry ?
                       branding.text.registration.couponEntry + '\n\n' : false;

      $scope.groupText = function(group) {
        let reg = group.registration;
        return reg.text && reg.text.couponEntry ? reg.text.couponEntry : false;
      }

      // show spinkit if coupons haven't been loaded
      if (!$scope.regData.coupons.length) $rootScope.showProgress();

      // answer `yes` if they already have selected coupons
      if ($scope.coupons.length) $scope.hasCoupons = true;

      // fetch coupons and pass to main handler or error
      $scope.regData
        .fetchCoupons($scope.groups.filter(g => !g.waitlistRegistration).map(g => g.id))
        .then(couponHandler)
        .then(trackTruncation)
        .then(checkAddedOverBalance)
        .catch(errorHandler);

      // custom name for `coupons`
      let orgProp = $scope.flatOrg[0].properties;
      $scope.couponLabel =
        orgProp &&
        orgProp.branding &&
        orgProp.branding.text &&
        orgProp.branding.text.registration &&
        orgProp.branding.text.registration.couponLabel ?
        orgProp.branding.text.registration.couponLabel : 'coupons or scholarship codes';

      $scope.couponChoices = [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]

      function couponHandler() {
        $scope.applicableCoupons = ($scope.regData.coupons || []).filter((c) => {
          return $scope.groups.find(g => g.parents.includes(c.groupID));
        });

        $rootScope.hideProgress();
        if (!$scope.applicableCoupons.length) return $scope.bypass();

        // restore state if going back
        if ($scope.coupons.length) {
          let previouslySelected = _.cloneDeep($scope.coupons)
          $scope.coupons.length = 0;
          previouslySelected.map(coup => {
            let group = _.find($scope.groups, g => g.id === coup.appliedToGroup);
            group.guess = coup.code;
            $scope.guess(group, true);
            group.guess = '';
          })
        } else {
          // auto-apply coupons unless already applied, starting from greatest to least amount
          $scope.regData.coupons.sort((ca, cb) => {
            return cb.amount - ca.amount;
          }).map(c => {
            if (c.autoApply) {
              let groups = $scope.groups.filter(g => {
                return !g.waitlistRegistration &&
                  (g.registration.tuition || g.registration.deposit || addOnSum(g)) &&
                  _.includes(g.parents, c.groupID)
              });
              groups.map(g => {
                g.guess = c.code;
                $scope.guess(g, true);
                g.guess = '';
              });
            }
          });
        }

        // if any coupons were autoApplied, then answer `yes` to having coupons unless they previously said no
        if (!_.isBoolean($scope.hasCoupons)) {
          $scope.hasCoupons = _.some($scope.groups, g => g.selectedCoupons && g.selectedCoupons.length) || null;
        }

      }

      // For use with the `truncate` directive
      function trackTruncation() {
        // If there are no coupons, just return
        if (!$scope.groups || !$scope.regData.coupons.length) return
        // Otherwise set up tracking
        $scope.groups.forEach(g => {
          g.truncation = {
            truncated: false,
            expanded: false
          };
        });
      }

      // check to see that previously selected coupons that are over balance continue to prevent forward navigation in reg wizard
      function checkAddedOverBalance() {
        if (!$scope.hasCoupons) return;
        _.map($scope.groups, (group) => {
          if (group.selectedCoupons) {
            _.map(group.selectedCoupons, (coupon) => {
              checkOverBalance(group, coupon, coupon.autoApply);
            });
          }
        });
      }

      // if coupon is applied that goes over the group balance, then give the user an error
      function checkOverBalance(group, coupon, isAutoApply) {
        const groupBalance = getGroupBalance(group, coupon);
        if (groupBalance < 0) {
          // dont show error to user if theyre auto-apply coupons
          if (isAutoApply && !coupon.manualEntry) return true;
          $scope.overBalance[group.id] = true;
          window.swal({
            title: "",
            text: "You have applied more coupons than needed to cover this session's balance. Please remove an applied coupon to correct the balance",
            type: "warning",
          });
          return true;
        }
        return false;
      }

      $scope.guess = function(group, isAutoApply) {

        // use no-op for flash if isAutoApply is passed in
        let flash = isAutoApply ? ()=>{} : window.flash

        if (!group.guess) return;

        group.guess = group.guess.toUpperCase()
        const match = $scope.regData.coupons.find(coupon => {
          return (group.guess === coupon.code && _.includes(group.parents, coupon.groupID));
        })

        if (match) {

          group.selectedCoupons = group.selectedCoupons || [];

          // check if previously selected
          if (match.selected || _.some(group.selectedCoupons, c => c.code === match.code)) {
            return flash(`Coupon ${match.code} already used`);
          }

          // If shared and NOT multi-use, ensure it's not applied to another group
          if (match.shared && !match.multiUse) {
            // Don't loop unless we have to.
            const used = ($scope.crebits || []).some(c => {
              if (!c) return false;
              return !(c.description || '').trim().match(/\(Void\)$/)
                     && c.addOnQuantity !== 0
                     && c.couponID === match.id;
            });
            if (used) return flash(`Coupon ${match.code} already applied to another group`);
          };

          // check if expired
          if (window.lib.isExpired(match, $scope.regData.org.properties.timezone)) {
            return flash(`Coupon ${match.code} has expired`);
          }

          // check if over capacity
          if (match.capacity && match.used >= match.capacity) {
            return flash(`Coupon ${match.code} is at capacity`);
          };

          // calculate amount for percentage coupons if not previously calculated for this group
          if (match.properties.isPercentage) {
            match.calculatedAmounts = match.calculatedAmounts || {}
            if (!match.calculatedAmounts[group.id]) {
              match.calculatedAmounts[group.id] = (+match.amount / 10000) * parseInt(group.registration.tuition || 0);
            }
          }

          if (match.properties.bypassDeposit) {
            match.calculatedAmounts = match.calculatedAmounts || {}
            if (!match.calculatedAmounts[group.id]) {
              match.calculatedAmounts[group.id] = group.registration.deposit || 0;
            }
          }

          const isOverBalance = checkOverBalance(group, match, isAutoApply);
          // early return for auto-apply coupons that are over-balance, so they never get applied
          if (isOverBalance && isAutoApply) return;

          // if the coupon made it past all of the checks above, it is because it was manually applied
          // we need to flag it this way so that it can be removed if it goes over balance
          if (!isAutoApply) match.manualEntry = true;

          // if multiUse coupon, we don't mark as selected so it can be reused
          if (!match.multiUse) match.selected = true

          // increment used and attach to group
          match.used++;
          group.selectedCoupons.push(match);
          // prevent duplicates when switching between steps
          group.selectedCoupons = _.uniq(group.selectedCoupons)
          group.guess = '';
          if (isOverBalance) return;
          flash(`Coupon ${match.code} accepted!`);
        } else {
          return flash('Coupon not found');
        }
      }

      $scope.remove = function(coupon, group) {
        coupon.selected = false;
        coupon.used--;
        _.remove(group.selectedCoupons, coupon);
        if (getGroupBalance(group) > -1) $scope.overBalance[group.id] = false;
      }

      $scope.amountText = function(coupon, groupID) {
        // shows the amount, calculated amount, or `bypassDeposit` for a coupon
        if (coupon.properties.isPercentage) {
          let amount = $filter('currency')(coupon.calculatedAmounts[groupID] / 100);
          return `(${amount})`
        } else if (coupon.properties.bypassDeposit) {
          return '(Bypasses Deposit)'
        } else {
          let amount = $filter('currency')(coupon.amount / 100);
          return `(${amount})`
        }
      }

      $scope.onContinue = function() {

        // clears coupon array without destroying reference
        $scope.coupons.length = 0;
        if (!$scope.hasCoupons) return;

        // iterate through each group's selected coupons and push into reg obj
        $scope.groups.map(g => {
          if (g.selectedCoupons) g.selectedCoupons.map(c => {
            $scope.coupons.push({
              id: c.id,
              appliedToGroup: g.id,
              code: c.code,
              amount: (c.properties.isPercentage || c.properties.bypassDeposit ? c.calculatedAmounts[g.id] : c.amount) * -1,
              properties: c.properties
            });
          });
        });
      }

      function errorHandler(error) {
        $rootScope.hideProgress();
        console.error(error);
        swal('Error!', "An unexpected error occured:\n" + error, 'error');
      }

      function addOnSum(group) {
        return $scope.addOns
          .filter(a => a.appliedToGroup === group.id)
          .reduce((sum, a) => {
            return sum + a.price
          }, 0)
      }

      function couponSum(group) {
        return (group.selectedCoupons || []).reduce((sum, coupon) => {
          let amount = coupon.properties.isPercentage ? coupon.calculatedAmounts[group.id] : coupon.amount;
          return sum + amount;
        }, 0);
      }

      function getGroupBalance(group, coupon) {
        let baseBalance = addOnSum(group) + (group.registration.tuition || 0) - couponSum(group)
        // If we're adding a new coupon, check to see if its amount will bring the balance below 0
        if (coupon && !_.some(group.selectedCoupons, c => c.code === coupon.code)) {
          let amount = coupon.properties.isPercentage ? coupon.calculatedAmounts[group.id] : coupon.amount;
          return baseBalance -= amount;
        } else {
          // If the coupon has already been applied (selectedCoupons have already been vetted and applied to the balance)
          // or we are removing a coupon (it's amount is already removed from baseBalance), just return the base balance.
          return baseBalance;
        }
      }
    }
  }
});
