angular.module('dn').directive('regAdjustmentItem', function () {
  /**
   * This is intended for use within an ng-repeat and not as a standalone.
   * It depends on various properties that only exist on an instance of RegAddOn, so it should be
   * used with that class or a structure that mimics it.
   */
  return {
    templateUrl: 'directives/reg-adjustment-item/reg-adjustment-item.directive.html',
    restrict: 'E',
    scope: {
      disabled: '=',
      adjustment: '=',
      getPendingAdjustedAmount: '='
    },
    controller($scope, RegAdjustment) {
      $scope.glCodesP2FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');
      $scope.REG_ADJ_TYPES = RegAdjustment.TYPES;

      $scope.getTooltipMessage = (adjustment) => {
        if (adjustment.disabled) {
          return adjustment.exceedsTuition
            ? 'Percentage coupon cannot be applied as it exceeds your adjusted tuition total.'
            : 'Coupon cannot be applied as it exceeds your registration total.';
        } else if ($scope.shouldDisableAdjust(adjustment)) {
          if (adjustment.selected) {
            return 'Custom adjustment cannot be deselected as it would exceed the adjusted amount of the corresponding line item.';
          } else {
            return 'Custom adjustment cannot be selected as it would exceed the adjusted amount of the corresponding line item.';
          }
        }
        return '';
      };

      /**
       * This function determines if a pending custom adjust should be
       *   disabled. If selecting or deselecting a pending custom adjust would
       *   cause the adjusted amount of a crebit to go negative, then the
       *   pending custom adjust should be disabled.
       */
      $scope.shouldDisableAdjust = (adjust) => {
        if (!$scope.glCodesP2FlagEnabled) return false;
        if (adjust.type !== 'custom') return false;
        if (adjust.selected) {
          // Selected pending negative custom adjusts can always be deselected
          if (adjust.amount <= 0) return false;
          // If the adjusted amount of the adjusted line item would go negative
          //   if this selected pending positive custom adjust was deselected,
          //   then don't allow this adjust to be deselected.
          return adjust.amount
            > $scope.getPendingAdjustedAmount(adjust.adjustedLineItemID);
        } else {
          // Unselected pending positive custom adjusts can always be selected
          if (adjust.amount >= 0) return false;
          // If the adjusted amount of the adjusted line item would go negative
          //   if this unselected pending negative custom adjust was selected,
          //   then don't allow this adjust to be selected.
          return Math.abs(adjust.amount)
            > $scope.getPendingAdjustedAmount(adjust.adjustedLineItemID);
        }
      };
    }
  };
});
