angular.module('dn').directive('bulkUserRemover', function() {
  return {
    templateUrl: 'directives/bulk-user-remover/bulk-user-remover.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      fullTextSearchEnabled: '='
    },

    controller($http, $scope) {
      $scope.disableButton = null;

      /*
      The single action for the single button
      Does all the things to clear users for the selected profiles
      */
      $scope.clearUsers = () => {
        $scope.disableButton = true;
        const profileIDs = getProfileIDs();

        if ($scope.fullTextSearchEnabled && !profileIDs.length) {
          $scope.disableButton = null;
          return swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to clear users.'
          });
        }

        return getStats(profileIDs)
          .then(displayStats)
          .then(handleUserAction)
          .then(processDone)
          .catch(errorHandler);
      };

      // old method, to be removed when FullTextSearchProfiles is fully implemented
      function getProfileIDs() {
        if ($scope.fullTextSearchEnabled) {
          return $scope.profiles;
        } else {
          return $scope.profiles.some(profile => profile.selected)
            ? $scope.profiles.filter(profile => profile.selected).map(profile => profile.profiles.id)
            : $scope.profiles.map(profile => profile.profiles.id);
        }
      }

      function getStats(profileIDs) {
        return $http.post('/api/operator/bulk-user-deactivate-stats/' + $scope.organization.id, {profileIDs});
      }

      /*
      Looks for stats returned by getStats()
      Displays various swals depending on if there's an error,
        no selected profiles have users,
        or if there are stats to display
      Any `Promise.resolve(null)` calls mean that we're bypassing logic in the next function:
        `handleUserAction()`
      */
      function displayStats(result) {
        if (!result || !result.data) {
          return swal({
            type: 'error',
            title: 'Error',
            text: 'There was an error retrieving user data for the selected profiles. Canceling.',
            allowOutsideClick: true,
            allowEscapeKey: true
          }, () => {
            return Promise.resolve(null);
          });
        }

        const stats = result.data[0] || {};
        const profileCount = stats.profileCount;
        const profileUserCount = stats.profileUserCount;

        if (!profileCount || !profileUserCount) {
          return swal({
            type: 'info',
            title: 'No users found',
            text: 'No users found for the selected profiles. Canceling.',
            allowOutsideClick: true,
            allowEscapeKey: true
          }, () => {
            return Promise.resolve(null);
          });
        }

        return new Promise((resolve) => {
          return swal({
            type: 'info',
            title: 'Preview',
            text: `Found ${profileUserCount} invited or existing users for ${profileCount} participants. This will not remove users for any profiles with provider registrations. Continue?`,
            showCancelButton: true,
            allowOutsideClick: true,
            allowEscapeKey: true
          }, (confirmed) => {
            /*
              Today I learned: can't just pass resolve in as the callback
              Else it's not actually called if the user doesn't do the confirm action
            */
            return resolve(confirmed);
          });
        });
      }

      /*
      actionConfirmed will not be truthy if:
        we encounter an error
        none of the selected profiles have associated users
        the user cancels during the preview step
      */
      function handleUserAction(actionConfirmed) {
        if (actionConfirmed) return $http.post('/api/operator/bulk-user-deactivate/' + $scope.organization.id, {profileIDs: getProfileIDs()});
        else flash('Canceled');
      }

      function processDone(requestComplete) {
        $scope.disableButton = null;
        if (requestComplete) flash('Users cleared successfully');
      }

      function errorHandler(err) {
        /*
          Timeout is necessary for the swal to display under certain circumstances
          Theory is that dismissing the swal from displayStats is still in progress by the time this one is called
        */
        $scope.disableButton = false;
        setTimeout(() => {
          return swal({
            type: 'error',
            title: '',
            text: 'There was an error removing users:\n\n' + err.data,
            allowOutsideClick: true,
            allowEscapeKey: true
          });
        });
      }
    }
  };
});
