const Resource = angular.injector(['dn']).get('Resource');

angular.module('dn').factory('AttendanceRecord', function() {

  const AttendanceRecord = new Resource({
    route: '/api/organizations/:orgID/profiles/:profile_id/attendance-records/:id',
    schema: {
      // Again, super dumb, but we need orgID here to allow for save/load/deactivate operations.
      orgID:                        { type: 'integer',     required: true },
      profile_id:                   { type: 'integer',     required: true },
      check_in_time:                { type: 'timestamp',   required: true },
      check_in_provider_user_id:    { type: 'integer',     required: true },
      check_in_trusted_contact_id:  { type: 'integer'   },
      check_out_time:               { type: 'timestamp' },
      check_out_provider_user_id:   { type: 'integer'   },
      check_out_trusted_contact_id: { type: 'integer'   },
      auditor:                      { type: 'array',       required: true },
      timeline:                     { type: 'array',       required: true },
      notes:                        { type: 'array'     },
    }
  });

  // This is some p neat stuff. Allows us to access a computed value just like a regular prop.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
  Object.defineProperty(AttendanceRecord.prototype, 'checkedIn', {
    enumerable: true,
    writeable: false,
    configurable: false,
    get() {
      return !!this.check_in_time && !this.check_out_time;
    }
  });

  return AttendanceRecord;
});
