angular.module('dn').directive('anetSettings', function () {
  return {
    templateUrl: 'directives/anet-settings/anet-settings.directive.html',
    restrict: 'E',
    scope: {
      group: '=',
      organization: '=',
    },
    controller: function ($http, $scope, flash, $filter) {

      $scope.aNetSettings = { enabled: false };
      $scope.hasANet = false;
      $scope.loadError = false;
      $scope.isOrganization = $scope.group.id === $scope.organization.id;
      $scope.syncReports = [];

      $scope.hasEditPermissions = $filter('permissionVisible')({ settings_basics: 'edit' });

      loadSettings().then(loadSyncReports).catch(err => log.error(err));

      $scope.yesNo = [
        { label: 'No', value: false },
        { label: 'Yes', value: true }
      ];

      $scope.allowSave = () => {
        if (!$scope.hasANet) {
          if (!$scope.aNetSettings.enabled) {
            return false;
          } else {
            const fields = ['anet_org_id', 'api_key'];
            return fields.some((field) => !!$scope.aNetSettings[field]);
          }
        }
        return true;
      };

      $scope.save = () => {
        if ($scope.hasANet) {
          $scope.aNetSettings.enabled ? updateSettings() : deactivateSettings();
        } else {
          if ($scope.aNetSettings.enabled) {
            insertSettings();
          }
        }
      };

      // CRUD Helper Functions
      $scope.confirmDeactivateIfDisabled = function() {
        // Just stop right if we're active
        if (!$scope.hasANet || $scope.aNetSettings.enabled) return;
        return swal({
          type: 'warning',
          title: 'Deactive ACTIVE Net Integration?',
          text: 'This action deactivates the integration and prevents future syncs. Are you sure you want to continue?',
          showCancelButton: true,
          allowClickOutside: true,
        }, (confirmed) => {
          if (confirmed) return $scope.save();
          else $scope.aNetSettings.enabled = true;
        });
      };

      $scope.formatDate = function(date) {
        return moment.tz(date, $scope.organization.properties.timezone).format('YYYY-MM-DD h:mm A');
      };

      function loadSettings() {
        return $http.get(generateUrl('settings')).then((response) => {
          if (response.status === 200) {
            $scope.aNetSettings = response.data;
            $scope.aNetSettings.enabled = true;
            $scope.hasANet = true;
            $scope.loadError = false;
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          $scope.loadError = true;
          flashSettingsError('load');
        });
      }

      function loadSyncReports() {
        if (!$scope.hasANet) return;
        return $http.get(generateUrl('sync')).then((response) => {
          if (response.status === 200) {
            $scope.syncReports = response.data;
          }
          // For any other response, we're fine to leave it as the empty array we initialize it as
        }).catch((err) => {
          log.error(err);
          flash('Unable to load ACTIVE Net Sync Reports');
        });
      }

      function updateSettings() {
        const url = `${generateUrl('settings')}/${$scope.aNetSettings.id}`;
        $http.put(url, $scope.aNetSettings).then((response) => {
          if (response.status === 200) {
            $scope.aNetSettings = response.data;
            $scope.aNetSettings.enabled = true;
            flashSettingsSuccess('updated');
          } else {
            flashSettingsError('update');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flashSettingsError('update');
        });
      }

      function deactivateSettings() {
        const url = `${generateUrl('settings')}/${$scope.aNetSettings.id}`;
        $http.delete(url).then((response) => {
          if (response.status === 204) {
            flashSettingsSuccess('deactivated');
            // Clear fields on scope after deactivation
            Object.keys($scope.aNetSettings).map((key) => {
              if (key !== 'enabled') {
                $scope.aNetSettings[key] = null;
              }
            });
            $scope.hasANet = false;
          } else {
            flashSettingsError('deactivate');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flashSettingsError('deactivate');
        });
      }

      function insertSettings() {
        const url = generateUrl('settings');
        $scope.aNetSettings.org_id = $scope.organization.id;

        $http.post(url, $scope.aNetSettings).then((response) => {
          if (response.status === 201) {
            $scope.aNetSettings = response.data;
            $scope.aNetSettings.enabled = true;
            $scope.hasANet = true;
            flashSettingsSuccess('inserted');
          } else {
            flashSettingsError('insert');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flashSettingsError('insert');
        });
      }

      function generateUrl(type) {
        const target = type === 'settings' ? 'anet-settings' : 'anet-sync-reports';
        return `/api/organizations/${$scope.organization.id}/${target}`;
      }

      function flashSettingsSuccess(verb) {
        flash(`${verb.capitalize()} ACTIVE Net Settings`);
      }

      function flashSettingsError(verb) {
        flash(`Unable to ${verb} ACTIVE Net Settings`);
      }
    }

  };
});
