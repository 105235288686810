angular.module("dn").factory("RegAddOn", function($filter) {

  const RegAdjustment = angular.injector(["dn"]).get("RegAdjustment");

  function RegAddOn({ adjustment, registration, parent }) {

    // Old skool version of calling super? I suppose?
    Object.assign(this, new RegAdjustment({ adjustment, registration }));

    // Specific to Reg Add-ons
    this.id = adjustment.id;
    this.name = adjustment.name;
    this.type = RegAdjustment.TYPES.ADD_ON;
    this.amount = adjustment.price;
    this.selected = false;
    // This circular reference setup already exists on the add-ons in the store, but we need to
    // recreate it for the reg add-ons
    if (parent) {
      this.parent = parent;
      if (!parent.options) parent.options = [];
      parent.options.push(this);
    }
    this.ui = {
      // Shared follows the same logic as the OG state. Technically, if a reg is for a non-leaf
      // group, this could be wrong. The UI prevents this from being an issue unless you're trying
      // to cause a bug, as you can't add add-ons for a group w/o a tuiton set or for providers.
      shared: adjustment.groupID !== registration.groupID,
      multi: adjustment.options && adjustment.options.length > 0,
      isOption: !!adjustment.parentID,
      numeric: adjustment.numeric,
      capacity: `(${adjustment.used || 0}/${adjustment.capacity || '∞'})`,
      amount: $filter('currency')(this.amount / 100),
      // Alias "name" for compatibility with other RegAdjustments
      name: this.name,
    };
    // For add-ons, the full name can be a complicated matter
    this.ui.fullName = (this.parent ? `${this.parent.name} / ` : '')
      + this.ui.name
      + (this.ui.shared ? ' (Shared)' : '');
    this.selected = false;

  }

  return RegAddOn;

});
