lib.registerState "manager.settings.general.subgroups",
  url: "/subgroups"
  templateUrl: "states/manager/settings/general/subgroups/subgroups.state.html"
  controller: ($scope, group, organization, $state, Group, $http) ->
    $scope.group = group
    $scope.group.children = _.sortBy $scope.group.children, "name"

    $scope.children = _.sortBy $scope.group.$selectableChildren(false), "label"
    $scope.newSubgroup = {}

    $scope.submit = ->
      if not $scope.newSubgroup.name or not $scope.newSubgroup.classification then return
      newGroup =
        name: $scope.newSubgroup.name
        classification: $scope.newSubgroup.classification
        parentID: $scope.group.id

      $http.post("/api/groups/#{organization.id}/subgroups", newGroup).then (result) ->
        $state.reload("manager.settings")
