Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "Note", ($http) ->

  Note = new Resource
    route: "/api/profiles/:profileID/notes/:id"
    schema:
      profileID: { type: "number", required: true}
      userID: { type: "number" }
      category: { type: "text" }
      priority: { type: "text" }
      body: { type: "text" }
      file: { type: "text" }

  Note
