angular.module("dn").directive "alerts", ->
  restrict: "E"
  replace: false
  templateUrl: "directives/topbar/alerts/alerts.directive.html"
  scope:
    state: "=",
    user: "=",

  controller: ($scope, $state, $stateParams, $http, $filter) ->
    fetchedEvents = false

    fetchEvents = ->
      return unless $filter('permissionVisible')({ view_news_feed: 'allow' }) and $state.params.orgID
      $http.get("/api/organizations/#{$state.params.orgID}/acknowledgements").then (result) ->
        $scope.count = 0
        if result?.data
          $scope.count += result.data?.change?.length || 0
          $scope.count += result.data?.extension?.length || 0
          $scope.count += result.data?.cancellation?.length || 0
          fetchedEvents = true

    $scope.showAlerts = ->
      $state.current.name.indexOf("manager") is 0

    $scope.$watch "state.current.name", (name, stale) ->
      $scope.orgID = $state.params.orgID
      return if name is stale
      if $scope.showAlerts() and !fetchedEvents
        fetchEvents()
    , true
