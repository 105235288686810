lib.registerState "manager.groups.registration.setup",
  url: "/:groupID"
  templateUrl: "states/manager/groups/registration/setup/setup.state.html"
  resolve:
    group: (Group, $state, $stateParams, $q, organization) ->
      # don't load groups outside of this org
      orgGroup = window.dnim.gimmeABeer({
        arrayToSearch: [organization],
        searchElements: [+$stateParams.groupID],
        searchElementKey: 'id',
        arrayNestingKey: 'children',
      })
      if orgGroup[0]
        new Group($stateParams.groupID).load [
          "coupons"
          "addOns"
        ]
      else
        $state.go 'manager.profiles', { orgID: organization.id }, { reload: true }
        $q.reject "Forbidden" # this stops the other resolves from firing


    hasRegistrations: ($http, group) ->
      return $http.get("/api/groups/" + group.id + "/has-registrations").then (result) -> return !!+result.data[0].count

    title: ($rootScope, group) ->
      $rootScope._title = group.name + " Registration Setup"
      return

    defaultGlCodes: ($http, $state, organization) ->
      $http.get("/api/group/#{organization.id}/gl-codes/defaults")
        .then (success) -> success.data,
        () ->
          window.swal({
            title: 'Page Error'
            type: 'error'
            text: 'There was an error loading default GL Code data. \n\n Please reload and try again. If this error continues to occur, please contact our support team with the contents of this message and the URL of this page.'
            confirmButtonText: 'Reload'
          }, ->
              $state.reload())

  controller: ($rootScope, $scope, $state, $filter, group, hasRegistrations, organization, $http, defaultGlCodes) ->
    $scope.defaultGlCodes = defaultGlCodes;
    $scope.editName = false
    $scope.hasRegistrations = hasRegistrations

    $scope.breadcrumbs = group.parentChain.slice(1, group.parentChain.length)

    $scope.alterName = -> return $scope.editName = !$scope.editName

    # Set up choices for the grade restriction
    if organization.properties.features?.orgGrades?
      $scope.gradeChoices = _.map organization.properties?.gradeRange, (grade, index) ->
        { label: grade, value: +index }
    else $scope.limitedGrade = false

    $scope.tab = "enrollment"
    $scope.$state = $state
    $scope.group = group
    $scope.group.coupons = if _.isEmpty(group.coupons) then [] else group.coupons
    $scope.group.addOns = if _.isEmpty(group.addOns) then [] else _.sortBy group.addOns, 'properties.displayOrder'
    $scope.group.registration = $scope.group.registration or {}
    $scope.group.registration.age = $scope.group.registration.age or []
    $scope.group.registration.open = $scope.group.registration.open or []
    $scope.group.registration.openTime = $scope.group.registration.openTime or ''
    $scope.group.registration.closeTime = $scope.group.registration.closeTime or ''
    $scope.group.registration.grade = $scope.group.registration.grade or []
    $scope.limitedGrade = true if $scope.group.registration.grade[0] or $scope.group.registration.grade[1]
    $scope.limitedAge = true  if $scope.group.registration.age[0] or $scope.group.registration.age[1]
    $scope.openDates = true  if $scope.group.registration.open[0] or $scope.group.registration.open[1]
    $scope.organization = organization
    $scope.copyGroups = []

    $scope.usingDefaultGLCode = !$scope.group.gl_code_id
    previousGLCode = $scope.group.gl_code
    previousGLCodeID = $scope.group.gl_code_id

    $scope.setGlCodeToDefault = (defaultValue) ->
      previousGLCode = $scope.group.gl_code
      previousGLCodeID = $scope.group.gl_code_id
      $scope.group.gl_code = defaultValue || null
      $scope.usingDefaultGLCode = true
      $scope.group.gl_code_id = null
      $scope.group.usingDefaultGLCode = $scope.usingDefaultGLCode

    $scope.undoGlCodeToDefault = () ->
      $scope.group.gl_code = previousGLCode
      $scope.group.gl_code_id = previousGLCodeID
      $scope.usingDefaultGLCode = false
      $scope.group.usingDefaultGLCode = $scope.usingDefaultGLCode

    # Function used to exclude big unchanged properties from the group save request
    $scope.groupFieldsToSave = (saveScope) ->
      fieldsToIgnore = ["newQuestionnaire", "parent", "parentChain",
        "compiledProperties", "_pristine"];
      if $scope.usingDefaultGLCode
        fieldsToIgnore = _.concat(fieldsToIgnore, ['gl_code', 'gl_code_id'])

      return Object.keys(saveScope.resource).filter((field) ->
        return field not in fieldsToIgnore
      )

    $scope.$watch "limitedAge", (has) ->
      return if has
      $scope.group.registration.age = [
        null
        null
      ]
      return

    $scope.$watch "limitedGrade", (has) ->
      return  if has
      $scope.group.registration.grade = [
        null
        null
      ]
      return

    $scope.limitedCapacity = !!$scope.group.registration.capacity or $scope.group.registration.capacity is 0
    $scope.sexBasedCapacity = _.isPlainObject($scope.group.registration.capacity)
    $scope.$watch "limitedCapacity", (has) ->
      return  if has
      $scope.group.registration.capacity = null
      return

    $scope.$watch "openDates", (has) ->
      return  if has
      $scope.group.registration.open = [
        null
        null
      ]
      return

    $scope.$watch "sexBasedCapacity", (fresh, stale) ->
      return unless fresh
      return if fresh is stale
      if fresh and not stale # Changing from normal to sex-based
        totalCapacity = $scope.group.registration.capacity or 0
        $scope.group.registration.capacity =
          female: Math.ceil(totalCapacity / 2)
          male: Math.floor(totalCapacity / 2)
      else # Changing from sex-based to normal
        $scope.group.registration.capacity = $scope.group.registration.capacity or {}
        $scope.group.registration.capacity = ($scope.group.registration.capacity.female or 0) + ($scope.group.registration.capacity.male or 0)
      return

    $scope.allowEdit = $filter('permissionVisible')({ roster_registration_setup: 'edit' })

    $scope.topAddOns = (addOns) -> _.filter addOns, (addOn) ->
      not addOn.parentID and addOn.groupID is $scope.group.id

    $scope.addOns = _.filter $scope.group.addOns, (addOn) -> not addOn.parentID and addOn.groupID is $scope.group.id

    originalAddons = _.cloneDeep($scope.addOns)

    swapPositions = (arr, i, j) ->
      return arr if ((j < 0) || (i < 0) || (j >= arr.length) || (i >= arr.length))
      swap = arr[i]
      arr[i] = arr[j]
      arr[j] = swap
      return arr

    $scope.moveItem = (array, index, direction) ->
      if (direction == "up")
        swapPositions(array, index, index - 1)
      else if (direction == "down")
        swapPositions(array, index, index + 1)

    $scope.saveDisplayOrder = () ->

      # Make a copy of activeAddOns
      shallowActiveAddOns = $scope.activeAddOns.slice()

      # Take that copy and push the deactivated addOns to it
      orderedAddOns = _.reduce($scope.addOns, (shallActive, addOn) ->
        if (!_.some($scope.activeAddOns, {'id': addOn.id}))
          shallActive.push(addOn)
        return shallActive
      shallowActiveAddOns
      )

      # addOns are now ordered, [active, deactivated], allowing proper reg setup functionality.
      $scope.addOns = orderedAddOns

      $scope.addOns.map((addOn, index) ->
        addOn.properties.displayOrder = index)
      $http.put("/api/groups/#{$scope.group.id}/order-add-ons", $scope.addOns).then(
        (success) ->
          $scope.addOns = success.data
          originalAddons = _.cloneDeep($scope.addOns)
          flash "Saved add-on display order"
          $scope.editingOrder = false
        , (err) ->
          flash "Unable to save order"
      )

    $scope.cancelDisplayOrder = () ->
      $scope.addOns = _.cloneDeep(originalAddons)
      $scope.activeAddOns = _.filter $scope.addOns, (addOn) -> addOn.deactivated is null
      $scope.editingOrder = false

    $scope.sharedAddOns = (addOns) ->
      _(addOns)
        .filter (addOn) -> not addOn.parentID and addOn.groupID isnt $scope.group.id
        .sortBy "level"
        .value()

    $scope.activeCoupons = _.filter $scope.group.coupons, (c) -> !c.shared && !c.deactivated
    $scope.activeSharedCoupons = _.filter $scope.group.coupons, (c) -> c.shared && !c.deactivated
    $scope.deacCoupons = _.filter $scope.group.coupons, (c) -> !c.shared && c.deactivated
    $scope.deacSharedCoupons = _.filter $scope.group.coupons, (c) -> c.shared && c.deactivated

    $scope.copyItems = (itemType) ->
      $scope.padding = 'no-pad' #for checkbox formatting
      $scope.copyingItems = true

      if itemType == "AddOn"
        flash "Please select an add-on to copy"
      else
        flash "Please select a coupon to copy"

    $scope.pasteItems = (itemType) ->
      if itemType == "Coupon" then copiedItems = _.filter $scope.activeCoupons, (c) -> c.copy
      else copiedItems = _.filter $scope.group.addOns, (a) -> a.copy
      copiedGroups = _.filter $scope.copyGroups, (g) -> g != group.id

      if !copiedItems.length then return flash "Please select at least one #{itemType} to copy!"
      if !copiedGroups.length then return flash "Please select at least one session to copy to!"



      if itemType == "Coupon"
        $http.post("/api/groups/#{organization.id}/copy-coupons", {copiedItems, copiedGroups}).then (
          (success) ->
            flash "#{copiedItems.length} Coupon(s) copied to #{copiedGroups.length} group(s)!"
            $scope.copyingItems = false
            $scope.padding = ''
        ), (error) ->
          window.swal "Error Copying Coupon", JSON.stringify(error), "error"
          $state.reload()


      else $http.post("/api/groups/#{organization.id}/copy-add-ons", {copiedItems, copiedGroups}).then (
        (success) ->
          flash "#{copiedItems.length} Add-On(s) copied to #{copiedGroups.length} group(s)!"
          $scope.copyingItems = false
          $scope.padding = ''
      ), (error) ->
        window.swal "Error Copying Add-On", JSON.stringify(error), "error"
        $state.reload()

    $scope.copyToAllGroups = (section, add) ->
      section.children.map((child) ->
        child.add = add
        $scope.copyToGroup(child.id, add)
      )

    # Update select all toggle
    clearSection = (groupID) ->
      section = $scope.sectionGroups.find((section) -> section.children.map((g) -> g.id).includes(groupID))
      section.add = false

    $scope.copyToGroup = (groupID, add) ->
      if add && !$scope.copyGroups.includes(groupID) then $scope.copyGroups.push(groupID)
      if !add
         _.remove $scope.copyGroups, (g) -> g == groupID
         clearSection(groupID)

    $scope.goBack = () ->
      $rootScope._title = "Registrations"
      return $state.go("^")
