angular.module('dn').directive('tag', function() {
  return {
    templateUrl: 'directives/tags/tag/tag.directive.html',
    restrict: 'E',
    scope: {
      color: '@',
      label: '@'
    }
  };
});
