# Controls an 'upload' type question

angular.module("dn").directive "qtUpload", ->
  templateUrl: "directives/taker/upload/qt-upload.directive.html"
  restrict: "A"
  scope:
    question: "=qtUpload"
    answer: "="
  controller: ($scope, $stateParams, Images) ->
    $scope.validate = if $scope.question.required then "required" else ""

    profileID = $stateParams.profileID
    template  = $scope.question.metadata.template

    $scope.files = []
    $scope.templateFiles = []

    $scope.answer.metadata ?= {}

    # Download the template for the question if it exists
    if $scope.question.metadata.template
      Images.getForProfile(profileID, template, true).then (tpl) ->
        $scope.templateFile = tpl[0]

    # Fetch existing images to show the thumbnails
    if $scope.answer.value
      Images.getForProfile(profileID, $scope.answer.value).then (files) -> $scope.files = files

    # Clear the date input if the question has an expiration and their date is out of range
    if $scope.question.metadata?.expirationInterval and $scope.answer.metadata?.relativeExpiration
      answerExp = moment(new Date($scope.answer?.metadata?.relativeExpiration))
        .add(parseInt($scope.question.metadata?.expirationInterval), "months")
      if new moment().isAfter(answerExp)
        $scope.answer.metadata.relativeExpiration = null

    # Set the route to POST to. Prepend any existing file keys onto the resulting key
    $scope.action = ->
      route = "/api/profiles/#{profileID}/answers/upload"
      if $scope.answer.value then route += "?prepend=#{$scope.answer.value}"
      route

    # The response should be { key: <fileKey> } where fileKey is the S3 key of the document
    # we've uploaded, possibly appended to the existing keys if they were provided.
    $scope.uploadComplete = (content) ->
      if content.err then $scope.uploadError = content.err
      else
        delete $scope.uploadError
        $scope.answer.value = content.key

    if $scope.question.metadata.enableIntervalExpiration && $scope.question.metadata.expirationInterval
      $scope.intervalValidation = moment().subtract($scope.question.metadata.expirationInterval, 'months').format('YYYY-MM-DD')
