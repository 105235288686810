lib.registerState "manager.finances.orgLedger",
  url: "/organization-ledger"
  templateUrl: "states/manager/finances/ledgers/org/org-ledger.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Organization Ledger"

    crebitsPerRequest: -> 1000

    balanceData: ($http, $stateParams) ->
      $http.get("/api/organizations/#{$stateParams.orgID}/ledger-balance/organization").then((success) ->
        return success.data
      , (err) ->
        flash("Error Retrieving Organization Balance")
        return {}
      )

    crebits: ($stateParams, $http, crebitsPerRequest) ->
      $http.get("/api/organizations/#{$stateParams.orgID}/crebits?resultsPerPage=#{crebitsPerRequest}").then (result) ->
        return result.data

      .catch (err) ->
        flash 'Error retrieving ledger data'
        return []

    lastCrebitID: (crebits) -> (_.last(crebits) || {}).id || 0

  controller: ($state) ->
    $state.go ".lineItems" if $state.current.name is "manager.finances.orgLedger"
