angular.module("dn").directive "userInfo", ->
  templateUrl: "directives/user/user-info.directive.html"
  restrict: "A"
  scope:
    user: "="
    profile: "="
    organization: "="
    provideruser: "="
    season: "="

  controller: ($scope, $http, $state) ->
    $scope.profileUser = $scope.user.profileUser
    $scope.isTestAccount = _.includes $scope.user.options, '"testAccount"=>"true"'

    $http.get("/api/organizations/#{$scope.organization.id}/profiles-users/#{$scope.user.id}/profiles").then (result) ->
      $scope.siblings = _.sortBy result.data, 'familyName' or []
      if $scope.siblings.length > 3
        while $scope.siblings.length % 3 != 0
          $scope.siblings.push {}
      $scope.siblingChunks = _.chunk($scope.siblings, 3)

    $scope.deactivateUser = (profileUserID) ->
      $http.delete("/api/profiles/#{$scope.profile.id}/users/#{profileUserID}").then ->
        flash "Removed!"
        $state.reload()

    $scope.resetPassword = (email) ->
      $http.post("/api/users/#{encodeURIComponent(email)}/reset").success ->
        flash "Password Reset Sent"

    $scope.showTestOption = do ->
      ###
      Determine whether to show the option to make user a test account:
      This checks if the current date is between the launch date
        and the test account date in the season setup
        If yes, set the account's deletion date to 3 days after the launch date.
      Lodash doesn't like the coffee existential operator.
      So running (_.last organization.compiledProperties.seasons?).timeline.dates.testAccount
        OR (_.last organization.compiledProperties.seasons)?.timeline.dates.testAccount
        breaks immediately, even if it does exist.
        Hence the return logic
      ###

      return false unless $scope.season && $scope.season.timeline && $scope.season.timeline.dates
      return false unless $scope.season.timeline.dates.testAccount && $scope.season.timeline.dates.launch
      $scope.testAccountDate = moment($scope.season.timeline.dates.testAccount)
      $scope.startDate = moment($scope.season.timeline.dates.launch)
      return false unless moment(new Date()) >= $scope.testAccountDate
      return false unless moment(new Date()) < $scope.startDate
      true

    if $scope.showTestOption
      $scope.deletionDate =
        if $scope.user.options.match(/"deleteDate"=>"(\d{4}-\d{2}-\d{2})"/)
        then $scope.user.options.match(/"deleteDate"=>"(\d{4}-\d{2}-\d{2})"/)[1]
        else $scope.startDate.add(3, 'days').format("YYYY-MM-DD")
      $scope.editingDeletionDate = false

    $scope.invalidDeletionDate = () ->
      moment($scope.deletionDate) < $scope.testAccountDate

    $scope.makeTestUser = ->
      action = if $scope.editingDeletionDate then 'Update' else 'Create'
      window.swal
        title: "#{action} Test Account"
        text: "
          <ul style=\"text-align: left;\">
            <li>This account will not be processed during payments.</li>
            <li>It will be automatically deactivated on <strong>#{moment($scope.deletionDate).format('MMM DD, YYYY')}</strong>.</li>
            <li>On deletion, all profiles managed by this user will also be deactivated.</li>
            <li>If you need to revert this change, ask the Web Team and give this ID: <strong>#{$scope.user.id}</strong>.</li>
          </ul>
        "
        html: true
        type: "warning"
        showCancelButton: true
        confirmButtonText: "#{action} Test Account"
      , (isConfirm) ->
        if isConfirm
          $http.put("/api/users/#{$scope.user.id}/test-account?deleteDate=#{$scope.deletionDate}").then (result) ->
            $scope.isTestAccount = true
            $scope.editingDeletionDate = false
            flash "Test Account #{action}d"
        else
          $scope.isTestAccount = false if !$scope.editingDeletionDate

    # Since people are lazy and trust others with their payment information, here's how a provider can save a method for someone else.
    # The properties.createdBy will look like { userID: 286447, name: "Connor Stec", orgName: "Discovery", sendToPUID: 464242 }
    # userID and name are the provider who created the method, orgName is the org they are a provider for,
    # and sendToPUID is the profileUserID to send to, which should be the ID of the PU on the current page
    $scope.savePaymentMethod = ->
      $scope.savingMethod = true

      # I apologize for provideruser. Directives never seem to like camelCase things passed into their isolate scope
      $scope.newPaymentMethod.properties.createdBy = $scope.provideruser
      $scope.newPaymentMethod.properties.providerPermission = "1"
      $scope.newPaymentMethod.properties.createdBy.sendToPUID = $scope.profileUser.id #again, sorry for the name. I think it works, though

      $http.post("/api/users/#{$scope.user.id}/payment-methods", $scope.newPaymentMethod).then(
        (success) ->
          $scope.addPaymentMethod = false
          $scope.savingMethod = false

          window.swal
            type: "info"
            title: "Payment Method Added"
            text: """
              The payment method has saved sucessfully.
              Jump to #{$scope.profile.givenName}'s Account tab?
            """
            showCancelButton: true
            cancelButtonText: "Stay"
            confirmButtonText: "Go to Account"
          , (isConfirm) ->
            if isConfirm
              $state.go "manager.profiles.profile.account"

        , (err) ->
          $scope.savingMethod = false
          throw err.data
      )
