lib.registerState "manager.profiles.profile.notifications",
  url: "/notifications"
  templateUrl: "states/manager/profiles/profile/notifications/profile-notifications.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Notifications"
    users: ($http, profile) ->
      $http.get("/api/profiles/" + profile.id + "/users").then (
        (result) ->
          return result.data || []
      ), (err) -> return []

  controller: ($scope, profile, $http, $state, session, flash, provider, users, $filter) ->
    $scope.user = session.user

    # if they don't have any authorized users we disable the `Send Message` functionality
    $scope.anyAuthorizedUser = _.some users, (u) -> return u && !u.deactivated && !u.profileUser.deactivated

    # TODO: This code is duplicated from the send-message directive. It should be refactored
    $scope.messageTemplateChoices = []
    _.map $scope.messageTemplates, (t) ->
      if t.profileID is provider?.id
        $scope.messageTemplateChoices.push {label: t.name, value: t.id, optgroup: "Custom"}
      else if t.shared or session.access?.operator
        $scope.messageTemplateChoices.push {label: t.name, value: t.id, optgroup: "Shared"}
    $scope.template = null

    $scope.loadTemplate = ->
      $scope.template = _.find $scope.messageTemplates, (t) -> t.id is parseInt($scope.templateID)
      $scope.message =
        subject: $scope.template?.template?.subject or ''
        customReplyTo: if $scope.template?.template?.customReplyTo then $scope.template.template.customReplyTo
        body: $scope.template?.template?.body or ''
        cc: $scope.template?.template?.cc or ''
        name: $scope.template?.name or ''

    $scope.sendMessage = (message) ->
      message.type = "custom"
      $http.post("/api/profiles/#{profile.id}/notifications", message).then ->
        flash "Message Sent!"
        $scope.message.subject = ""
        $scope.message.body = ""
        $state.reload("manager.profiles.profile.notifications")

    types =
      "balance": "Balance Reminder"
      "custom": "Custom Message"
      "health-form-pdfs": "Health Form PDFs"
      "incomplete": "Reminder"
      "insurance-policy": "Travel Protection Confirmation"
      "labels-reminder": "Oliver's Labels Reminder"
      "log-entry": "Health Log Entry"
      "new-charge": "Account Update"
      "new-registration": "New Registration"
      "multi-registration": "New Registrations"
      "new-provider-method": "New Payment Method"
      "payment-received": "Payment Received"
      "paymentplan-created": "New Payment Plan"
      "paymentplan-deactivated": "Deactivated Payment Plan"
      "paymentplan-failed": "Failed Payment Plan"
      "prescreening-reminder": "Scheduled Reminder"
      "rx-billed-order": "Billed ℞ Order"
      "rx-failed-payment": "℞ Failed Payment"
      "rx-med-info-sent": "℞ Medication Sent"
      "rx-new-order": "New ℞ Order"
      "rx-payment-sent": "℞ Payment"
      "rx-reminder": "℞ Reminder"
      "rxFlag": "℞ Notification"
      "travelprotection-reminder": "Travel Protection Reminder"
      "unlocked": "Unlock Request Approved"
      "waitlist-accepted": "Promoted from Waitlist"
      "waitlist-added": "Added to Waitlist"
      "welcome": "Invite"

    $scope.humanLabel = (type) -> types[type] or "Notification"

    $scope.filter = "all"
    $scope.types = _.map types, (type, key) ->
      { label: type, value: key }

    $scope.types = _.sortBy $scope.types, (t) -> t.label

    $scope.types.unshift { label: "All Notifications", value: "all" }

    $scope.notifications = []
    if $filter("permissionVisible")( {profile_notifications: 'view'} )
      $http.get("/api/profiles/#{profile.id}/notifications").then (result) ->
        _.reject(result.data, (n) -> n.originalNotificationID).reduce((all, n) ->
          all.push(n)
          return all
        , $scope.notifications)
        $scope.types = _.filter $scope.types, (t) ->
          _.some $scope.notifications, (n) -> n.type is t.value
        runFilter()

    runFilter = ->
      _.map $scope.notifications, (n) ->
        n._hide = false
        if $scope.filter is "all" then return
        n._hide = n.type isnt $scope.filter

    $scope.$watch "filter", runFilter, true
