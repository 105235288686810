lib.registerState "manager.import.groups",
  url: "/groups"
  templateUrl: "states/manager/import/groups/groups.state.html"
  controller: ($scope, $state, $http, $timeout, flash) ->

    $scope.orgID = $scope.organization.id
    $scope.anyExisting = false

    $scope.toggleExpand = ->
      return unless $scope.processedGroups
      _.map $scope.processedGroups, (g) -> g.expand = !g.expand
      $scope.allExpanded = !$scope.allExpanded


    flattenResult = (groups, depth, flat) ->
      if not groups or not groups.length then return
      _.map groups, (g) ->
        return unless g.name
        g.depth = depth

        if g.id then $scope.anyExisting = true

        kids = _.cloneDeep g.children
        delete g.children
        delete g.options

        flat.push g
        flattenResult kids, depth + 1, flat
      return flat

    $scope.processed = (content) ->
      if content.err
        swal({
          type:"error"
          title:"Error uploading file"
          text:content.err
        })
      $scope.hideUploader = true
      $scope.issues = content.issues
      $scope.originalGroups = _.cloneDeep content.result
      $scope.processedGroups = flattenResult content.result, 0, []
      _.map $scope.processedGroups, (g) ->
        g.anyInformation = (_.some g.properties) or (_.some g.registration) or g.shortName or g.identifier

    $scope.cancel = ->
      $scope.hideUploader = false
      $scope.processedGroups = null
      $scope.anyExisting = false
      $scope.issues = []

    $scope.import = ->
      return unless $scope.processedGroups and $scope.originalGroups
      data = { groups: $scope.originalGroups, overwrite: $scope.overwrite }
      $http.post("/api/organizations/#{$scope.orgID}/group-import", data).then ->
        flash "Groups Imported"
        $timeout ->
          $state.go "manager.settings", null, { reload: true }
        , 1000
      , (result) ->
        detailMatch = result.data.detail.match(/Key \((.+)\)=\((.+)\) already exists/)
        swal "Error",
          "Another item already has the value '#{detailMatch[2]}' for '#{detailMatch[1]}'",
          "error"
