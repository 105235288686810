angular.module("dn").filter("phone", () => {
  return function (number) {
    var string = number.toString()
    if (parseInt(number) == number && string.length == 10) {
      return `${string.slice(0, 3)}-${string.slice(3, 6)}-${string.slice(6, 10)}`
    } else {
      return number
    }
  }
})
