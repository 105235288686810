angular.module("dn").directive "qbFollowup", ->
  templateUrl: "directives/builder/followup/qb-followup.directive.html"
  restrict: "A"
  replace: true
  scope:
    question: "=qbFollowup"
    parentQuestion: "="
  controller: ($scope, WhatDivision) ->
    return unless $scope.parentQuestion
    division = WhatDivision

    $scope.choices =
      allergies: [
        { label: "Has any allergies", value: "allergies:true"  }
        { label: "Doesn't have any allergies", value: "allergies:false" }
        { label: "Has food allergies", value: "foodAllergies:true"  }
        { label: "Doesn't have food allergies", value: "foodAllergies:false" }
        { label: "Has drug allergies", value: "drugAllergies:true"  }
        { label: "Doesn't have drug allergies", value: "drugAllergies:false" }
        { label: "Has environmental allergies", value: "envAllergies:true"  }
        { label: "Doesn't have environmental allergies", value: "envAllergies:false" }
        { label: "Has Anaphylaxis Risk", value: "anaphylaxis:true" }
        { label: "Doesn't have Anaphylaxis Risk", value: "anaphylaxis:false" }
        { label: "Bringing Epi-Pen", value: "epiPen:true"  }
        { label: "Not Bringing Epi-Pen", value: "epiPen:false"  }
      ]
      authorization: [
        { label: "Accepted", value: "authorization:true"  }
        { label: "Not accepted", value: "authorization:false" }
      ],
      medications: [
        { label: "Takes medications", value: "medications:true"  }
        { label: "Doesn't take medications", value: "medications:false" }
        { label: "Will take medications at #{division.place.singular}", value: "willTakeMedications:true"  }
        { label: "Will not take medications at #{division.place.singular}", value: "willTakeMedications:false" }
      ],
      boolean: [
        { label: "Yes", value: "yes" }
        { label: "No", value: "no"  }
      ]

    body = if $scope.parentQuestion.body then $scope.parentQuestion.body.split(",") else []
    $scope.choices.select = _.map body, (b) -> { label: b, value: b }
