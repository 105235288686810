lib.registerState "manager",
  url: "/org/:orgID/provider"
  templateUrl: "states/manager/manager.state.html"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Provider Portal"
    session: ($http) -> $http.get("/api/session?reaccess").then (result) -> result.data
    missingUserFields: (User, session) ->
      new User(session.userID).load().then (user) -> window.lib.missingUserFields(user)
    nav: ($rootScope, $stateParams, $filter, GroupBranding, session, organization, setupMode, featureFlags) ->
      # allowed permissions to see the Settings sidebar tab
      # if reg org, add the registration settings to allowed permissions too
      settingsPermissions = {
        settings_medications: "view",
        settings_tags: "view",
        settings_profiles: "view",
        settings_health_log: "view",
        settings_questionnaire: "view",
        settings_billing: "view",
        settings_emergency_plan: "view",
        settings_branding: "view"
        settings_attendance: "view"
      }

      importPermissions = {
        upload_data_imports: "allow",
        import_profiles: "allow",
      }

      if (organization && organization.shortName)
        settingsPermissions['settings_registrations'] = 'view'

      # Feature flag for prescreening
      $rootScope.featureFlags ?= {}
      $rootScope.featureFlags.prescreening = true
      prescreeningEnabled = _.get(organization, 'properties.features.prescreening.enabled')
      scheduledRemindersEnabled = _.get(organization, 'properties.features.prescreening.reminders')

      # dnNext is a generic feature flag that allows us to deploy features that only development can see
      $rootScope.featureFlags.dnNext = window.lib.matchesEnv({ environments: ['development', 'staging']})
      $rootScope.hideClassicBuilderFlag = window.lib.featureFlagClient.isEnabled('hideClassicBuilder')

      $rootScope._nav = [
        {
          text: "Profiles"
          state: "manager.profiles"
          params: orgID: $stateParams.orgID

          icon: "users"
          subnav: [
            {
              text: "Profiles & Reports"
              state: "manager.profiles"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Create Profile"
              state: "manager.profiles.createProfile"
              params: orgID: $stateParams.orgID
              permissions: { create_profiles: "allow" }
            }
          ]
        }
        {
          text: "Groups"
          state: "manager.groups"
          params: orgID: $stateParams.orgID
          permissions: { roster_registration_setup: "view" }

          icon: "sitemap"
          subnav: [
            {
              text: "Roster / Registrations"
              state: "manager.groups.roster"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Registration Setup"
              state: "manager.groups.registration"
              params: orgID: $stateParams.orgID
              hide: (!organization.shortName and !session.access?.operator)
            }
          ]
        }
        {
          text: "Finances"
          state: "manager.finances"
          params: orgID: $stateParams.orgID
          hide: !organization.shortName
          icon: "dollar-sign"
          permissions: { financial_ledgers: "view" }
          subnav: [
            {
              text: "Participant Ledger"
              state: "manager.finances.profileLedger"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Organization Ledger"
              state: "manager.finances.orgLedger"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Finances Setup"
              state: "manager.finances.setup"
              params: orgID: $stateParams.orgID
            }
          ]

        }
        {
          text: "Protection Plan"
          state: "manager.travelProtection"
          hide: !organization.properties?.features?.travelInsurance
          params: orgID: $stateParams.orgID
          icon: "shield-alt"
          permissions: { view_protection_plans: "allow" }
        }
        {
          text: "Medical Supplies"
          state: "manager.medicalSupplies"
          params: orgID: $stateParams.orgID
          icon: "stethoscope"
          permissions: { medical_supplies: 'allow' }
        }
        {
          text: "Health Log"
          state: "manager.healthLog"
          params: orgID: $stateParams.orgID
          icon: "book"
          permissions: { health_log: "view", behavioral_health: "view" }
          subnav: [
            {
              text: "View"
              state: "manager.healthLog.browse"
              params: orgID: $stateParams.orgID
            }
            {
              text: "New"
              state: "manager.healthLog.create"
              params: orgID: $stateParams.orgID
              permissions: { health_log: "edit", behavioral_health: "edit" }
            }
            {
              text: "Triage"
              state: "manager.healthLog.triage"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Tracking Board"
              state: "manager.healthLog.tracking"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Statistics"
              state: "manager.healthLog.statistics"
              params: orgID: $stateParams.orgID
            }
            {
              text: "Templates"
              state: "manager.healthLog.templates"
              params: orgID: $stateParams.orgID
              permissions: { health_log: "edit", behavioral_health: "edit" }
            }
          ]
        }
        {
          # $rootScope.organization hasn't been set yet, so we have to use the method directly
          text: GroupBranding.getPrescreeningAlias(organization)
          state: "manager.prescreening"
          icon: "comment-medical"
          hide: !$rootScope.featureFlags.prescreening || !prescreeningEnabled
          params: { orgID: $stateParams.orgID }
          permissions: { prescreening: "view" }
          subnav: [
            {
              text: "Reporting"
              state: "manager.prescreening.browse"
              params: { orgID: $stateParams.orgID }
            }
            {
              text: "Template"
              state: "manager.prescreening.template"
              params: { orgID: $stateParams.orgID }
              permissions: { prescreening: "edit" }
            }
            {
              text: "Scheduled Reminders"
              state: "manager.prescreening.scheduledReminders"
              params: { orgID: $stateParams.orgID }
              hide: !scheduledRemindersEnabled
            }
          ]
        }
        {
          text: "eMAR"
          state: "manager.emar"
          params: orgID: $stateParams.orgID
          icon: "medkit"
          permissions: { emar: "view" }
        }
        {
          text: "CampGrams"
          state: "manager.funkNotes"
          hide: !organization.properties?.enableFunkNotes
          params: orgID: $stateParams.orgID
          icon: "envelope"
          permissions: { access_funknotes: "allow" }
        }
        {
          text: "Attendance"
          state: "manager.attendance"
          params: orgID: $stateParams.orgID
          icon: "check-circle"
          iconPrefix: "far"
          permissions: { attendance_taking: "view" }
          subnav: [
            {
              text: "Check In"
              state: "manager.attendance.list"
              params: { orgID: $stateParams.orgID, action: 'check-in' }
              permissions: { attendance_taking: "view" }
            }
            {
              text: "Attendance"
              state: "manager.attendance.list"
              params: { orgID: $stateParams.orgID, action: 'attendance' }
              permissions: { attendance_taking: "view" }
            }
            {
              text: "Audit"
              state: "manager.attendance.list"
              params: { orgID: $stateParams.orgID, action: 'audit' }
              permissions: { attendance_auditing: "allow" }
            }
          ],
          hide: !organization.properties.features?.attendance?.enabled
        }
        {
          text: "Settings"
          state: "manager.settings"
          params:
            orgID: $stateParams.orgID
            groupID: $stateParams.orgID
          permissions: settingsPermissions
          icon: "cog"
          subnav: [
            {
              text: "General"
              state: "manager.settings.general"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              permissions: _.pick(settingsPermissions, [
                'settings_medications',
                'settings_tags',
                'settings_profiles',
                'settings_health_log',
                'settings_registrations',
                'settings_emergency_plan',
                'settings_attendance'
              ])
            }
            {
              text: "Billing"
              state: "manager.settings.billing"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              permissions: { settings_billing: "view" }
            }
            {
              text: "Service Capacity"
              state: "manager.settings.service-capacity"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              hide: !window.lib.featureFlagClient.isEnabled('billingDashboard')
              permissions: { access: 'oper' }
              oper: true
            }
            {
              text: GroupBranding.getHealthProfileAlias(organization)
              state: "manager.settings.questionnaire"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              hide: $rootScope.hideClassicBuilderFlag
              permissions: { settings_questionnaire: "view" }
            }
            {
              text: "Branding"
              state: "manager.settings.branding"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              permissions: { settings_branding: "view" }
            }
            {
              text: "Security"
              state: "manager.settings.security"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              permissions: { settings_security: "view" }
            }
            {
              text: "Analytics"
              state: "manager.settings.analytics"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              permissions: { settings_analytics: "view" }
            }
            {
              text: "Builder"
              state: "manager.settings.builder"
              params:
                orgID: $stateParams.orgID
                groupID: $stateParams.orgID
              hide: !(organization.properties?.enableBuilderSelfService || session.access?.operator)
              permissions: { settings_questionnaire: "edit" }
            }
          ]
        }
        {
          text: "Setup"
          state: "manager.setup"
          params: orgID: $stateParams.orgID
          icon: "map"
          permissions: { setup_org: "allow" }
        }
        {
          text: "Data Import",
          state: "manager.import"
          params: { orgID: $stateParams.orgID },
          icon: "upload",
          subnav: [
            {
              text: "Data Upload"
              state: "manager.import.upload"
              permissions: { upload_data_imports: "allow" }
              hide: organization.properties.enableProfileImport
            }
            {
              text: "Group Import"
              state: "manager.import.groups"
              permissions: { access: 'oper' }
              oper: true
            }
            {
              text: "Profile Import"
              state: "manager.import.profile-import"
              hide: do ->
                return false if session.access?.operator
                return !organization.properties?.enableProfileImport
              permissions: { import_profiles: "allow" }
            }

          ]
          permissions: importPermissions,
          hide: do ->
            return false if session.access?.operator

            profileImportEnabled = organization.properties?.enableProfileImport
            dataUploadAccess = $filter('permissionVisible')( { upload_data_imports: "allow" } )
            return false if !profileImportEnabled && dataUploadAccess

            profileImportAccess = $filter('permissionVisible')( { import_profiles: "allow" } )
            return false if profileImportEnabled && profileImportAccess

            return true
        }
        {
          text: "Support"
          support: true
          params:
            orgID: $stateParams.orgID
          icon: "question-circle"
        }
      ]

      # Creates reference to the Settings object in the nav array
      settingsNav = _.find $rootScope._nav, (link) -> link.text is "Settings"

      if setupMode
        $rootScope._nav = _.filter $rootScope._nav, (link) -> link.text is "Setup"

      return

    organization: (Group, $stateParams, $rootScope) ->
      new Group($stateParams.orgID).load().then (group) ->
        $rootScope.organization = group
        $rootScope.organizationWordMark = window?.lib?.brandingConfig?.values?.wordmarks?.custom
        group

    featureFlags: (organization, session) ->
      context = {
        properties: {
          orgID: organization.id.toString()
        },
        userId: session.user.id.toString()
      }
      return window.lib.featureFlagClient.updateContext(context)

    setupMode: (organization, provider, session) ->
      organization.properties.setupMode and !session.access?.operator

    reviewMode: (organization, session, $http) ->
      $http.get("/api/organizations/#{organization.id}/seasons?current=true").then(
        (success) ->
          if success.data and success.data.timeline.dates and success.data.status.confirmed
            isAfterStart = moment().isSameOrAfter(moment(new Date(success.data.timeline.dates.reviewBy)))
            isBeforeFinish = moment().isSameOrBefore(moment(new Date(success.data.timeline.dates.launch)).add(14, 'days'))
            (isAfterStart && isBeforeFinish) and !success.data.status.launched and !session.access?.operator
          else
            false
        , (error) ->
          false
      )

    provider: (session, organization, $state, $q, Profile, reviewMode) ->
      accessOrg = _.find session?.access?.organizations or [], (o) -> o.id is organization.id
      provider = accessOrg?.providers?[0]
      return $state.go "home" unless provider or session?.user?.operator
      return null unless provider
      d = $q.defer()
      new Profile(provider.id).load(["roles"]).then (profile) ->
        provider.profile = profile
        d.resolve provider
      d.promise

    access: (session) -> _.pick session.access, ["profiles", "groups"]

    roleData: ($rootScope, organization, provider, session) ->
      accessOrg = _.find session?.access?.organizations or [], (o) -> o.id is organization.id
      if accessOrg
        $rootScope.roleData = accessOrg.roleData
      else if provider?.profile?.roles?[0].permissions?
        $rootScope.roleData = provider.profile.roles[0].permissions
      else
        $rootScope.roleData = null

    operator: (session, $rootScope) ->
      if session and session.access and session.access.operator
        $rootScope.operator = session.access.operator

    clearPatientAccess: ($rootScope) ->
      ###
      Patient portal introduces $rootScope.hasPatientAccess to allow people with direct access to patient profiles to use the portal uninhibited
      If you access the patient portal and have this property applied, then go to the provider portal, the property sticks and bypasses all front end permission checks
      Clear it out here to avoid the big 'ol bug
      ###
      $rootScope.hasPatientAccess = null

    addOrgRoles: (organization, $http) ->
      $http.get("/api/organizations/#{organization.id}/roles").then (success) ->
        organization.roles = success.data

  controller: ($scope, $state, $filter, organization, provider, access, session, setupMode, reviewMode, roleData, operator, missingUserFields) ->

    $scope.organization = organization
    $scope.provider = provider
    $scope.access = access
    $scope.operator = session?.access?.operator

    if $scope.organization.testOrg
      $(".top-banner").addClass('blue')
      $("#topbar .upper").addClass('blue')
      $("#sidebar .brand").addClass('blue')
    else if $scope.organization.deactivated
      $(".top-banner").addClass('red')
      $("#topbar .upper").addClass('red')
      $("#sidebar .brand").addClass('red')

    return $state.go "home.account.information", { userID: session.user.id } if missingUserFields

    return $state.go "home.whereTo" if setupMode and !$filter('permissionVisible')({ setup_org: "allow" })

    withoutPermissions = !roleData?.roleIDs?.length and !$scope.operator
    return $state.go "manager.noPermissions" if withoutPermissions

    $state.go "manager.profiles" if $state.current.name is "manager" and !setupMode
    $state.go "manager.setup" if $state.current.name is "manager" and $filter('permissionVisible')({ setup_org: "allow" }) and (setupMode or reviewMode)
