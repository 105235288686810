lib.registerState("manager.travelProtection", {
  url: '/travel-protection',
  templateUrl: 'states/manager/travel-protection/travel-protection.state.html',

  controller: function ($filter, $http, $rootScope, $scope, organization) {
    /*
    groups is the main variable for the HTML
    dataLoaded blocks the loading of the HTML until we've loaded erythang
    */
    $scope.groups = [];
    $scope.dataLoaded = null;
    let manipulatedRosterData, insuredRegistrations;

    /* Show the spinkit spinner so the user knows we're doing something */
    $rootScope.showProgress();

    /* Flatten the group structure to make it easier to find group names later */
    const flattenedGroups = {};
    function flattenator(groupArray) {
      return _.map(groupArray, (group) => {
        if (group.chain) group.chain = group.chain + " » " + group.name;
        else group.chain = group.name;

        flattenedGroups[group.id] = group;

        _.map(group.children, (child) => {
          child.chain = group.chain;
        });

        flattenator(group.children);
      });
    }
    flattenator(organization.children);

    /*
    Main chunk of logic for the state
    Get group/registration data from the roster route
    Set up group name chains for display purposes
    Get prot plans
    Use a very nasty lodash chain to ultimately assign data to $scope.groups

    TODO: refactor the lodash chain. So.Many.Loops.
    */
    getRoster(organization.id).then((rosterData) => {

      manipulatedRosterData = rosterData.reduce((resultArray, group) => {
        if (group.phase !== 'past') {
          if (group.groupID === organization.id) group.name = organization.name;
          if (!flattenedGroups[group.groupID]) return resultArray;
          group.name = flattenedGroups[group.groupID].chain;
          group.phase = flattenedGroups[group.groupID].phase;
          resultArray.push(group);
        }
        return resultArray;
      }, []);

      return organization.id;
    }).then(getPlans).then((planData) => {
      insuredRegistrations = _.map(planData, 'registrationID');

      $scope.groups = _(_.cloneDeep(manipulatedRosterData)).reject((g) => {
        return g.phase === 'past';
      }).map((g) => {
        g.registrations = _.reject(g.registrations, (r) => { return r.waitlisted; });
        g.registrations = _.sortBy(g.registrations, (r) => { return `${r.profile.familyName}${r.profile.givenName}`.toLowerCase() });
        return g;
      }).reject((g) => {
        return !g.registrations.length;
      }).sortBy((g) => {
        return g.name;
      }).value().filter((g) => {
        g.registrations = _.filter(g.registrations, (r) => {
          return _.includes(insuredRegistrations, r.id);
        });
        return g.registrations.length;
      }).map((g) => {
        _.each(g.registrations, (r) => {
          r.insurancePolicy = _.find(planData, (protPlan) => {
            return protPlan.registrationID === r.id;
          });

          if (!r.insurancePolicy) return g;

          r.insurancePolicy.created = moment(r.insurancePolicy.created).format('YYYY-MM-DD');
        });
        return g;
      });

      $rootScope.hideProgress();
      $scope.dataLoaded = true;
    }).catch((err) => {
      console.log('caught err', err);
      flash('Error loading protection plans');
    });

    function getRoster(orgID) {
      return new Promise((resolve, reject) => {
        if (!$filter('permissionVisible')({ view_protection_plans: 'allow' })) return resolve([]);
        return $http.get('/api/organizations/' + orgID + '/roster/registrations').then((result) => {
          return resolve(result.data);
        }).catch(reject);
      });
    }

    function getPlans(orgID) {
      return new Promise((resolve, reject) => {
        if (!$filter('permissionVisible')({ view_protection_plans: 'allow' })) return resolve([]);
        return $http.get('/api/organizations/' + orgID + '/protection-plans').then((result) => {
          return resolve(result.data);
        }).catch(reject);
      });
    }

  } // end controller
});
