angular.module("dn").directive("bulkAddOns", function() {
  return {
    templateUrl: "directives/add-ons/bulk-add-ons.directive.html",
    restrict: "E",
    scope: {
      organization: "=",
      group: "="
    },

    controller: function($scope, $state, $http, $rootScope){

      $scope.addOnsNameSearch = null;
      $scope.resultAddOns = null;
      $scope.showFields = true;
      $scope.searching = false;
      $scope.ADDONS_FIELDS = [
        {label: 'EXPIRES',  selected: false, updateValue: null},
        {label: 'NAME',     selected: false, updateValue: null},
        {label: 'PRICE',    selected: false, updateValue: null},
        {label: 'CAPACITY', selected: false, updateValue: null},
        {label: 'REQUIRED', selected: false, updateValue: null}
      ]

      // Values for buttonSwitch--required field.
      $scope.yesNo = [
        { label: "No", value: false },
        { label: "Yes", value: true }
      ];

      const flatGroups = []

      // Returns one-dimensional of orgs/children from deeply-nested array of orgs/children.
      function flattenator(groups, groupArray){
        _.map(groups, function(group){
          groupArray.push(_.pick(group, ["id", "name", "classification", "parentID", "parents", "phase", "deactivated", "registration", "shortName", "properties", "compiledProperties"]) )
          if (group.children) flattenator(group.children, groupArray)
        })
      }

      // Get flattened array of scope orgs.
      flattenator([$scope.organization], flatGroups)

      // Find addOns, showing spinner while searching true and results when false.
      $scope.findAddOns = function(name){
        $scope.searching = true;
        name = name ? name : $scope.addOnsNameSearch;
        if (!name){
          $scope.searching = false;
          return flash('Please enter a name');
        }
        return $http.get('/api/organizations/' + $scope.organization.id + '/addons/bulk?name=' + name + '&groupID=' + $scope.group).then(
          function(success){
            $scope.searching = false;
            updateResultAddOns(success.data);
          }, function(err){
            console.log('caught error', err)
            $scope.searching = false;
            throw err;
          }
        )
      }

      // Update addOns. Ternary for updating required--captures falsity.
      $scope.updateAddOns = function(){
        const selectedAddOnsIDs = $scope.resultAddOns.reduce((addIDs, a) => {
          if (a.selected) addIDs.push(a.id)
          return addIDs
        }, [])

        const bodyUpdates = $scope.ADDONS_FIELDS.reduce((updates, field) => {
          if (field.selected){
            // Ternary in a ternary: price updates to 0 were being set to null because 0 evaluates falsey, second tern prevents that.
            (field.label === "REQUIRED") ? updates[field.label.toLowerCase()] = field.value : (updates[field.label.toLowerCase()] = field.value === 0 ? 0 : field.value || null)
          }
          return updates
        }, {})

        return $http.put('/api/organizations/' + $scope.organization.id + '/addons/bulk', {
          updates: bodyUpdates,
          addOnsIDs: selectedAddOnsIDs
        }).then((success) => {
          flash('Successfully updated')
          resetFields()
          updateResultAddOns(success.data, $scope.resultAddOns)
        }, (err) => {
          console.log('caught error', err)
          swal("Error", "A problem occurred! Please review your updates and try again." + " Code: " + err.status + ", " + err.statusText + ".", "error")
        })
      }


      $scope.deactivateAddOns = function(){
        const selectedAddOnsIDs = $scope.resultAddOns.reduce((addIDs, a) => {
          if (a.selected) addIDs.push(a.id)
          return addIDs
        }, [])

        if (selectedAddOnsIDs.length === 0){
          swal("Error", "Please select at least one group to deactivate.", "error")
          return;
        }

        const bodyUpdates = {
          deactivated: new Date().toISOString()
        }

        return $http.put('/api/organizations/' + $scope.organization.id + '/addons/bulk/', {
          updates: bodyUpdates,
          addOnsIDs: selectedAddOnsIDs
        }).then((success) => {
          flash('Successfully deactivated')
          resetFields()
          updateResultAddOns(success.data, $scope.resultAddOns)
        }, (err) => {
          console.log('caught error', err)
          throw err
        })
      }


      $scope.selectAllResults = function(value){
        $scope.resultAddOns.map(addOn => addOn.selected = !!value)
      }

      // Match results to one-dimensional array of orgs/children
      function attachGroups(resultAddOns){
        return resultAddOns.map((addOn) => {
          const flatGroupMatch = flatGroups.find(fg => fg.id === parseInt(addOn.groupID))
          addOn.groupName = flatGroupMatch.name
          return addOn
        })
      }

      // Get results; update results if already existing, otherwise put them on scope via attachGroups; then,
      // filter deactivated groups.
      function updateResultAddOns(apiResults, existing){
        if (existing && existing.length){
          apiResults.updatedEntries.map((updatedAddOn) => {
            const resultsMatch = existing.find(addOn => addOn.id === updatedAddOn.id)
            resultsMatch.name = updatedAddOn.name
            resultsMatch.price = updatedAddOn.price
            resultsMatch.capacity = updatedAddOn.capacity
            resultsMatch.expires = updatedAddOn.expires
            resultsMatch.deactivated = updatedAddOn.deactivated
            resultsMatch.required = updatedAddOn.required
          })
        } else $scope.resultAddOns = attachGroups(apiResults)

        $scope.resultAddOns = _($scope.resultAddOns)
          .filter(a => !a.deactivated)
          .sortBy(['name', 'groupName'])
          .value()
      }

      function resetFields(){
        $scope.showFields = false
        $scope.ADDONS_FIELDS.map((field) => {
          field.selected = false
          field.value = null
        })

        setTimeout(() => {
          $scope.showFields = true
        })
      }
    }
  }
})
