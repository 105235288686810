lib.registerState("manager.healthLog.tracking", {
  url: '/tracking',
  templateUrl: 'states/manager/health-log/tracking/tracking.state.html',
  resolve: {
    title: function($rootScope) {
      $rootScope._title = 'Tracking Board';
    },
    trackingBoard: function($http, organization) {
      return $http.get(`/api/organizations/${organization.id}/tracking-log-entries`)
      .then(function(result) {
        return result.data;
      });
    }
  },
  controller: function ($scope, organization, trackingBoard) {
    $scope.trackingBoard = trackingBoard;

    // Calculate Length of Stay
    $scope.lengthOfStay = function(timestamp) {
      return moment(timestamp).fromNow();
    }

    // Setting tag drop-down options
    if (organization.properties.customDispositions && organization.properties.customDispositions.length) {
      $scope.tagOptions = organization.properties.customDispositions.map((tag) => {
        return {value: tag, label: tag }
      });
    } else {
      // Poached from settings defaults -
      // this is what they will have if they haven't set custom options
      $scope.tagOptions = [
        {value: 'Admitted',             label: 'Admitted'},
        {value: 'Ambulance',            label: 'Ambulance'},
        {value: 'Discharged',           label: 'Discharged'},
        {value: 'Emergency Department', label: 'Emergency Department'},
        {value: 'Home',                 label: 'Home'},
        {value: 'Observed',             label: 'Observed'},
        {value: 'Urgent Care',          label: 'Urgent Card'},
      ]
    }
    $scope.tagOptions.push({value: 'Other', label: 'Other'});
    $scope.defaultTag = '';

    // Filtering by tags
    const filterDispositions = function(tags) {
      let active = tags.split(',');
      let inactive = $scope.tagOptions.reduce(function(inactiveTags, tag) {
        if(!active.includes(tag.value)) inactiveTags.push(tag.value);
        return inactiveTags;
      }, []);
      $scope.trackingBoard.forEach(function(entry) {
        if (active.includes(entry.logEntries.disposition) || (active.length === 1 && active[0] === '')) {
          entry.show = true;
        } else if (active.includes('Other') && !inactive.includes(entry.logEntries.disposition)) {
          entry.show = true;
        } else {
          entry.show = false;
        }
      });
    }

    // Watch the tags
    $scope.$watch('defaultTag', function(fresh) {
      filterDispositions(fresh);
    });

  // End Controller
  }

// End State
});
