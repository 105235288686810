lib.registerState "oper.organizations.organization",
  url: "/:orgID"
  templateUrl: "states/oper/organizations/organization/organization.state.html"
  resolve:
    organization: (Group, $stateParams) ->
      new Group($stateParams.orgID).load (err, group) -> group
  controller: ($scope, $state, organization) ->
    $scope.organization = $scope.org = organization

    $scope.tabs = [
      { label: "Summary",  sref: ".summary"   }
      { label: "Finances", sref: ".finances"  }
    ]

    if $state.current.name is "oper.organizations.organization"
      $state.go ".summary"
