lib.registerState('manager.profiles.profile.registrations.registration', {
  url: '/:registrationID',
  templateUrl: 'states/manager/profiles/profile/registrations/registration/registration.state.html',
  resolve: {
    title: ($rootScope) => {
      $rootScope._title = 'Provider / Profiles';
    },
    crebitAdjustments: ($http, $state, profile) => {
      const glCodesP2FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');
      if (!glCodesP2FlagEnabled) return [];
      return $http.get(`/api/profiles/${profile.id}/crebit-adjustments`).then(
        function(success) {
          return profile.crebitAdjustments = success.data;
        // eslint-disable-next-line no-unused-vars
        }, function(err) {
          window.swal({
            type: 'error',
            title: 'Error',
            text: 'There was an error retrieving adjustments. Please try again.',
            confirmButtonText: 'Reload',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            allowOutsideClick: false,
            allowEscapeKey: false
          }, function(confirmed) {
            if (confirmed)
              return $state.reload();
            else
              return $state.go('^.^');
          });
        });
    },
    crebitAttributions: ($http, $state, profile) => {
      const glCodesP2FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');
      if (!glCodesP2FlagEnabled) return [];
      return $http.get(`/api/profiles/${profile.id}/account?attributedTo=true`).then(
        function(success) {
          const negativeMisc = success.data.filter((crebit) => {
            return crebit.description.startsWith('[MISC]') && crebit.amount < 0;
          });
          return profile.negativeMisc = negativeMisc;
        // eslint-disable-next-line no-unused-vars
        }, function(err) {
          window.swal({
            type: 'error',
            title: 'Error',
            text: 'There was an error retrieving attributions. Please try again.',
            confirmButtonText: 'Reload',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            allowOutsideClick: false,
            allowEscapeKey: false
          }, function(confirmed) {
            if (confirmed)
              return $state.reload();
            else
              return $state.go('^.^');
          });
        });
    },
  },

  controller: function ($http, $filter, $rootScope, $scope, $stateParams, Group, Registration, flash, profile, ProfileRegData) {

    try {
      $scope.registration = ProfileRegData.regById(parseInt($stateParams.registrationID), 10);
      $scope.isGLCodeP2FlagOn = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');
      $scope.isRegEditHidden = $scope.isGLCodeP2FlagOn
        ? $scope.registration.properties.cancellationRequested || !$scope.registration.tuition
        : $scope.registration.properties.cancellationRequested || $scope.registration.deactivated || !$scope.registration.tuition;
      $scope.stateUi = {
        deactivating: false,
        saving: false,
        editing: false,
      };
      $scope.profile = profile;
      $scope.regCoupons = [];
      $scope.regAddOns = [];
      Object.defineProperty($scope.stateUi, 'missingTuition', {
        enumerable: true,
        configurable: false,
        get () {
          return !!$scope.registration.group.registration.enabled
            && !$scope.registration.tuition
            && !$scope.registration.waitlisted
            && $scope.registration.type === 'patient';
        }
      });
      $scope.stateUi.parentChain
        = $scope.registration.group.parents.reduce((acc, parentID) => {
          const group = ProfileRegData.indexedOrg[parentID];
          if (!group || group.id === $scope.registration.group.id) return acc;
          return `${acc ? `${acc} » ` : ''}${group.name}`;
        }, '');
    } catch (err) {
      _handleError('CONTROLLER', 'Hol up! You broke the controller!', err);
    }

    $scope.addTuition = function () {
      $scope.addingTuition = true;
      return ProfileRegData.saveTuition($scope.registration)
        .then(() => flash('Tuition Added'))
        .catch(() => flash('Error Adding Tuition'))
        .finally(() => $scope.addingTuition = false);
    };

    $scope.confirmReactivation = function() {
      swal(confirmSwalConfig({
        title: 'Reactivate Registration?',
        confirmButtonText: 'Reactivate'
      }), (confirm) => {
        if (confirm) {
          return reactivate().then(() => {
            successSwal('Registration Reactivated');
          }).catch(() => {
            errorSwal({ title: 'Error Reactivating Registration' });
          });
        }
      });
    };

    $scope.toggleEditing = () => $scope.stateUi.editing = !$scope.stateUi.editing;

    $scope.initDeactivation = function() {
      if ($scope.registration.tuition) {
        // Set this to make sure we clear out any previous values from the input
        // https://github.com/docnetwork/app/pull/7243#issuecomment-578260323
        $scope.registration.cancellationFee = 0;
        $scope.stateUi.deactivating = true;
      } else {
        $scope.confirmDeactivation();
      }
    };

    $scope.confirmDeactivation = function() {
      let text;
      const amount = (($scope.registration.swalBalance - ($scope.registration.cancellationFee || 0)) / 100);
      if (amount === 0) {
        text
        = `
          <p>${$filter('possessive')($scope.profile.givenName)} registration will be deactivated
          without a change in balance to the account.</p>
          <br>
          Please do not refresh the page.
        `;
      } else {
        const action = amount > 0 ? { verb: 'credit', prep: 'to' } : { verb: 'debit', prep: 'from' };
        text
        = `
          <p>Deactivating will ${action.verb} <strong>${$filter('currency')(Math.abs(amount))}</strong></p>
          <p>${action.prep} ${$filter('possessive')($scope.profile.givenName)} account.</p>
          <br>
          Please do not refresh the page.
        `;
      }
      swal(confirmSwalConfig({
        title: 'Deactivate Registration?',
        type: 'warning',
        text,
        html: true,
        confirmButtonText: 'Deactivate'
      }), (confirm) => {
        if (confirm) {
          return deactivate().then(() => {
            successSwal('Registration Deactivated');
          }).catch(() => {
            errorSwal({ title: 'Error Deactivating Registration' });
          }).finally(() => $scope.stateUi.deactivating = false);
        } else {
          $scope.cancelDeactivation();
        }
      });
    };

    $scope.cancelDeactivation = function() {
      delete $scope.registration.cancellationFee;
      $scope.stateUi.deactivating = false;
    };

    $scope.moveToWaitlist = function() {
      swal(confirmSwalConfig({
        title: 'Move to Waitlist?',
        text: `
          Move to the ${$scope.registration.group.name} waitlist?
          This will remove all related line items.
        `,
      }), (confirm) => {
        if (!confirm) return;
        $scope.stateUi.saving = true;
        return ProfileRegData.waitlistRegistration($scope.registration).then(() => {
          successSwal('Added to waitlist');
        }).catch(() => {
          errorSwal({ title: 'Error Waitlisting Registration' });
        }).finally(() => $scope.stateUi.saving = false);
      });
    };

    $scope.promoteFromWaitlist = function() {
      checkCapacity($scope.registration).then(({ full }) => {
        if (full) {
          return overCapacitySwal();
        } else {
          return promoteFromWaitlistSwal();
        }
      }).catch((error) => {
        _handleError('promoteFromWaitlist', 'Failure promoting from waitlist', error);
        errorSwal({ title: 'Error Promoting From Waitlist' });
      });
    };

    $scope.acceptCancellationRequest = function() {
      $scope.stateUi.deactivating = true;
    };

    $scope.denyCancellationRequest = function(reason) {
      let reqBody;
      try {
        reqBody = {
          reason,
          registration: $scope.registration.group.name,
        };
        // Inform UI that we're making a request
        $scope.stateUi.saving = true;
      } catch (error) {
        _handleError('denyCancellationRequest', 'Failed to set up cancellation req info', error);
      }

      const cancellationReqConfig = {
        orgID: $scope.profile.organizationID,
        profileID: $scope.profile.id,
        regID: $scope.registration.id,
      };
      return $http.post(cancellationUrl(cancellationReqConfig, 'deny'), reqBody)
        .then(({ data: regFromServer }) => {
          ProfileRegData.upsertRegistration(regFromServer);
          return flash('Cancellation Request Denied');
        })
        .catch((error) => {
          _handleError('denyCancellationRequest', 'Denial failed', error);
          errorSwal({ title: 'Cancellation Request Denial Failed' });
        })
        .finally(() => $scope.stateUi.saving = false);
    };

    // This _only_ handles the call and when we do with the return result. Conf swal is delegated.
    function deactivate() {
      return ProfileRegData.deactivateRegistration($scope.registration);
    };

    function reactivate() {
      return ProfileRegData.reactivateRegistration($scope.registration);
    };

    function _handleError(fnName, msg, err = {}) {
      let formattedErr;
      if (err.message && /^\[/.test(err.message)) {
        formattedErr = err;
      } else {
        formattedErr = Error(`[ProviderRegState][${fnName}] ${msg}: ${err.stack}`);
      }
      if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        console.error(formattedErr);
      }
    }

    function checkCapacity(registration) {
      return new Group({ id: registration.group.id }).checkCapacity($scope.profile.sex, (isFull) => {
        return Promise.resolve(isFull);
      });
    }

    function promoteFromWaitlistSwal() {
      return swal(confirmSwalConfig({
        title: 'Promote from Waitlist',
        type: 'warning',
        text: `Promote from the ${$scope.registration.group.name} waitlist?`,
      }), (confirm) => {
        if (!confirm) return;
        ProfileRegData.promoteRegistrationFromWaitlist($scope.registration)
          .then(() => {
            successSwal('Promoted from Waitlist');
          })
          .catch((error) => {
            _handleError('promoteFromWaitlistSwal', 'Failure promoting from waitlist', error);
            errorSwal({ title: 'Error Promoting From Waitlist' });
          });
      });
    }

    function overCapacitySwal() {
      return swal({
        title: 'Capacity Reached',
        type: 'warning',
        text: 'Are you sure you want to promote from the waitlist even though the session is at capacity?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: false,
      }, (confirm) => {
        if (!confirm) return;
        return promoteFromWaitlistSwal();
      });
    }

    function successSwal(title) {
      swal({
        title,
        type: 'success',
        timer: 3000
      });
    }

    function cancellationUrl({ profileID, regID }, action) {
      const routeAction = action === 'deny' ? 'deny-cancellation' : 'accept-cancellation';
      return `/api/profiles/${profileID}/registrations/${regID}/${routeAction}`;
    }

    const DEFAULT_ERR_SWAL_CONFIG = {
      title: 'An Error Occurred',
      body: 'Please try again later',
      confirmButtonText: 'OK',
      showCancelButton: false,
      cancelButtonText: 'Cancel',
    };

    /**
     * A general function for providing error feedback to users.
     *
     * @param {Object} swalConfig Slimmed down swal config. See destructuring below to learn what
     *   you may configure. See the DEFAULT_ERR_SWAL_CONFIG constant for default values.
     * @param {Function} errorCallback The callback function to be run after user confirmation. It
     *   takes a single argument: the `confirm` value provided to swal callbacks.
     */
    function errorSwal({
      title,
      body,
      confirmButtonText,
      showCancelButton,
      cancelButtonText
    } = DEFAULT_ERR_SWAL_CONFIG,
    errorCallback = ()=>{}
    ) {
      $scope.stateUi.deactivating = false;
      return swal({
        type: 'error',
        title,
        body,
        confirmButtonText,
        showCancelButton,
        cancelButtonText,
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnConfirm: true,
      }, errorCallback);
    }

    /**
     * A factory function for returning a confirmation swal config object.
     *
     * @param {Object} config - provides params to add or override default params for swal
     * @returns {Object} a swal config to be passed as the first argument into swal
     */

    function confirmSwalConfig(config) {
      return Object.assign({}, {
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
      }, config);
    }
  }
});
