lib.registerState('home.auth.reinvite', {
  url: '/reinvite',
  templateUrl: 'states/home/auth/reinvite/reinvite.state.html',
  params: {
    email: null
  },
  controller: function ($location, $http, $scope, $state, $timeout, User, flash, invitation) {
    // Email address is locked to what is provided in $stateParams
    $scope.email = $state.params.email;
    $scope.invitation = invitation
    $scope.inviteError = ($scope.invitation && $scope.invitation === "ERROR");

    // No email and no invite error means a reload - send em back to login
    if (!$scope.email && !$scope.inviteError) return $state.go("home.auth.login");

    // Go back to login - requires special magic for invite errors
    $scope.backToLogin = function() {
      return $location.url("/home/auth/login");
    }

    // Resend Invites
    $scope.resendInvite = function() {

      $scope.submitting = true;

      // If they use this fn w/o an email, they're hackin'. Just fake a send.
      if (!$scope.email) {
        $scope.submitting = false;
        $scope.invitationSent = true;
        return flash("No Email Provided");
      }

      // Otherwise, POST to the resend route
      $http.post('/api/notifications/resend-welcome/' + $scope.email)
      .success(function () {
        $scope.invitationSent = true;
        $scope.submitting = false;
        return flash('Invite have been re-sent. Please check your email.');
      }).error((err) => {
        $scope.submitting = false;
        return swal({
          type: "error",
          title: "Error Re-sending Invitiation",
          text: "There was an error re-sending your invitation:\n\n" + JSON.stringify(err)
        });
      });
    };
  }
});
