angular.module('dn').directive('bulkTags', function() {
  return {
    templateUrl: 'directives/bulk-tags/bulk-tags.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      fullTextSearchEnabled: '='
    },

    controller: function($scope, $state, $http, $rootScope) {

      $scope.displayTags = {};
      $scope.tagsToUpdate = {};
      $scope.processing = null;

      const organization = $scope.organization;

      $scope.updateTags = function(groupTags) {
        groupTags = groupTags.filter((tag) => {
          return Object.keys($scope.tagsToUpdate).map(t => parseInt(t)).includes(tag.id)
            && $scope.tagsToUpdate[tag.id];
        });

        if (!groupTags.length) return flash('No tags to update');

        const selectedProfileIDs = () => {
          if ($scope.fullTextSearchEnabled) {
            return $scope.profiles;
          } else {
            // old method
            return (getSelectedProfiles()).map(p => p.profiles.id);
          }
        };

        if ($scope.fullTextSearchEnabled && !selectedProfileIDs().length) {
          window.swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to update tags.'
          });
          return;
        } else {
          window.swal({
            type: 'warning',
            title: 'Update Tags',
            text: `This action will update ${groupTags.length} tag${groupTags.length > 1 ? 's' : ''} for ${selectedProfileIDs().length} profile${selectedProfileIDs().length > 1 ? 's' : ''}. This cannot be undone. Do you want to continue?`,
            showCancelButton: true
          }, (confirmed) => {
            if (confirmed) {
              $rootScope.showProgress();

              const promiseFuncs = groupTags.map((t) => {
                return function() {
                  return new Promise((resolve, reject) => {
                    $scope.processing = t.value;
                    const body = {
                      profileIDs: selectedProfileIDs(),
                      tag: t,
                      value: $scope.tagsToUpdate[t.id]
                    };
                    $http.post('/api/organizations/' + organization.id + '/profile-tags', body).then(() => {
                      return resolve();
                    }, (error) => {
                      return reject(error);
                    });
                  });
                };
              });

              return window.lib.promiseSeries(promiseFuncs).then(() => {
                $scope.processing = null;
                $rootScope.hideProgress();
                flash('Tags successfully assigned');
              }).catch(errorHandler);
            }
          });
        }
      };

      function errorHandler() {
        flash('Error assigning tags');
        $scope.processing = null;
        $rootScope.hideProgress();
      }

      // old method, to be removed when FullTextSearchProfiles is fully implemented
      function getSelectedProfiles() {
        if (_.some($scope.profiles, 'selected')) {
          return $scope.profiles.filter(p => p.selected);
        } else return $scope.profiles;
      }

      // organize the group's tags
      (function tagParenting () {
        _.map(organization.tags, function (t) {
          // don't display deactivated tags
          if (t.deactivated) { return; }

          _.map(organization.tags, function (c) {
            if (c.parentID === t.id) {
              $scope.displayTags[t.id] = t;
              if (!$scope.displayTags[t.id].options) { $scope.displayTags[t.id].options = {}; }
              $scope.displayTags[t.id].options[c.id] = c;
            }
          });
          if (!$scope.displayTags[t.id]) { $scope.displayTags[t.id] = t; }
        });
        // delete child tags that are now nested under the parent
        _.map($scope.displayTags, function (t) {
          if (!_.isNull(t.parentID)) { delete $scope.displayTags[t.id]; }
        });
      })();

      $scope.displayTags = _.sortBy($scope.displayTags, 'value');

      // create the choices objects for each tag
      var tagsWithOptions = _.filter($scope.displayTags, function (t) {
        return t.options;
      });
      $scope.tagOptions = {};
      _.each(tagsWithOptions, function (t) {
        $scope.tagOptions[t.id] = _.map(t.options, function (o) {return {label: o.value, value: o.id}; });
      });

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
