var Resource = angular.injector(["dn"]).get("Resource");
angular.module("dn").factory("Season", function($http) {
  var Season = new Resource({
    route: "/api/organizations/:orgID/seasons/:id",
    schema: {
      name:            {type: "text",    required: true },
      orgID:           {type: "integer", required: true },
      depositRequired: {type: "integer", required: true },
      profileEstimate: {type: "integer" },
      rushFeeRequired: {type: "integer" },
      signature:       {type: "text" },
      updates:         {type: "json" },
      forms:           {type: "json" },
      properties:      {type: "json" },
      status:          {type: "json" },
      timeline:        {type: "json" },
      start:           {type: "date" },
      finish:          {type: "date" },
      created:         {type: "timestamp" },
      updated:         {type: "timestamp" },
      deactivated:     {type: "timestamp" },
      archived:        {type: "timestamp" }
    }
  });
  return Season;
});
