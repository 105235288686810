String::capitalize = -> @charAt(0).toUpperCase() + @slice(1)

_.defaults window,
  lib: {}
  domain: do ->
    return "localhost"  if window.location.hostname is "localhost"
    domainParts = window.location.hostname.split(".")
    domainParts[1] + "." + domainParts[2]

window.Dropzone.autoDiscover = false  if window.Dropzone

angular.module("dn", [
  "ui.router"
  "ngAnimate"
  "ui.tree"
  "templates"
  "ngUpload"
  "nvd3"
  "ui.knob"
  "ngStorage"
  "ngSanitize"
  "single-spa-angularjs"
])

.config(($locationProvider, $urlRouterProvider) ->
  $locationProvider.html5Mode true
  if location.pathname.length <= 3
    location.href = "#{location.protocol}//#{location.host}/!/home#{location.search}"
)
.config ($compileProvider) ->
  $compileProvider.aHrefSanitizationWhitelist /^\s*(https?|ftp|mailto|tel|data|application\/csv):/


.config ($httpProvider) ->
  $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
  $httpProvider.defaults.headers.common["x-dn"] = "wat"
  $httpProvider.defaults.headers.common["x-dn-version"] = "0.41.0"
  $httpProvider.defaults.headers.common['x-tz'] = jstz?.determine()?.name() or "America/New_York"
  $httpProvider.defaults.headers.common['Content-Type'] = "application/json"

.run(($rootScope, $interval) ->
  $rootScope.sidebar = false

  $rootScope._print = ->
    window.print()

  setViewPort = ->
    $rootScope.viewPort = $rootScope.viewPort or {}
    $rootScope.viewPort.width = $(window).width()
    $rootScope.viewPort.height = $(window).height()
    $rootScope.viewPort.mobile = $rootScope.viewPort.width <= 800
    $rootScope.viewPort.device = (->
      return "phone"  if $rootScope.viewPort.width < 520
      return "tablet"  if $rootScope.viewPort.width < 960
      "desktop"
    )()

    $rootScope.sidebar = false  if $rootScope.device isnt "phone"
    $rootScope.$apply()
    return

  $(window).resize setViewPort
  setViewPort()
)

.controller "dn", (WhatDivision, $rootScope, $http, $state, $filter, $localStorage, $interval) ->
  $rootScope._ = _
  $rootScope.$storage = $localStorage
  setTimeout ->
    lib.browserCheck.warn() if lib.browserCheck
  , 100

  timeoutWarningOpen = false
  $interval ->
    # Don't continue if there's more than 90 seconds left
    return unless window.sessionExpires
    $rootScope.expiresIn = Math.ceil((new Date(window.sessionExpires) - new Date()) / 1000)
    return unless $rootScope.expiresIn <= 90
    # Don't continue if we're not inside an organization
    warnableState = _.some ["/org/", "where-to", "user-settings"], (state) ->
      window.location.pathname.indexOf(state) > 0
    return unless warnableState
    # Go home if we're totally out of time
    return window.location.pathname = "/!" if $rootScope.expiresIn < 1
    # Alert unless there's already an open alert
    unless timeoutWarningOpen
      timeoutWarningOpen = true
      window.swal
        title: "Login Expiring"
        text: """
          It looks like you haven't been active for a while.
          In a moment, you will be automatically logged out.
        """
        type: "warning"
        confirmButtonColor: "#DD6B55"
        confirmButtonText: "Stay Logged In"
      , ->
        $http.get "/api/session?reaccess"
        setTimeout ->
          timeoutWarningOpen = false
        , 1000
  , 1000

  $rootScope.title = () ->
    title = $rootScope._title

    if title
      document.title = title + " :: " + WhatDivision.name
    else
      document.title = WhatDivision?.name or "CampDoc.com"

    $rootScope._title

  $rootScope.$on "$viewContentLoading", (event, viewConfig) ->
    $rootScope.currentState = viewConfig?.view?.self?.name
    # Apply `.hero-shot` class to `#application` if `currentState` matches `home.auth`
    $rootScope.applicationClass = ''
    if /^(home.auth)\b/g.test $rootScope.currentState
      $rootScope.applicationClass = 'hero-shot'

  $rootScope.$on "$stateChangeSuccess", (e, to, toParams, from, fromParams) ->
    $state.previous = from
    $state.previous.params = fromParams if $state.previous

  $http.get('/api/config')
    .then (response) ->
      config = {
        appName: response.data.featureFlags.appName,
        url: response.data.featureFlags.url,
        clientKey: response.data.featureFlags.key,
        environment: response.data.featureFlags.environment
      };
      window.lib.featureFlagClient.start(config)
      window.lib.brandingConfig.setValues(response.data.branding);
    .catch (err) ->
      console.error('Error getting config', err)

.service('MetaService', ->
  title = 'Test'
  metaDescription = ''
  metaURL = ''
  set: (newTitle, newMetaDescription, newMetaURL) ->
    title = newTitle
    metaDescription = newMetaDescription
    metaURL = newMetaURL
  metaTitle: -> return title
  metaDescription: -> return metaDescription
  metaURL: -> return metaURL
  )

window.swal.setDefaults
  confirmButtonText: "OK"
  confirmButtonColor: "#5CA100"

# Change up Dropzone confirm to use swal
# See lines 1407-1411 of src/dropzone.coffee for the original
# This is meant to be used by Dropzone only
window.Dropzone.confirm = (question, accepted, rejected) ->
  dzSwalConfig = {
    title: "Delete Uploaded File",
    text: question,
    type: "warning",
    showCancelButton: true,
    cancelButtonText: "Cancel",
    confirmButtonText: "Delete",
    confirmButtonColor: "#DE5347" # This is $red according to http://scg.ar-ch.org/
  }
  swal dzSwalConfig, (confirmed) ->
    # User confirms delete, run `accepted` callback
    if confirmed
      accepted()
    # User cancels delete AND there is a callback; run `rejected` callback
    else if rejected?
      rejected()

window.System.register([], (_export) ->
  return {
    execute: () ->
      _export(window.singleSpaAngularjs.default({
        angular: angular,
        mainAngularModule: 'dn',
        uiRouter: true,
        preserveGlobal: false,
        template: '<app />'
      }))
      return
  })
