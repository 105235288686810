angular.module("dn").directive "qtMedications", ->
  restrict: "A"
  templateUrl: "directives/taker/modules/medications/qt-medications.directive.html"
  scope:
    question: "=qtMedications"
    medications: "="
    profile: "=",

  controller: ($scope, $timeout, $http, $stateParams, $filter, HealthForm, Constants, Images, WhatDivision, User) ->
    # Init variables
    $scope.otcMeds = _.filter($scope.medications, 'otcID')
    $scope.medications = _.reject($scope.medications, 'otcID')
    healthForm = HealthForm.get $scope.profile.id
    initialized = false
    justSaved = false
    profileID = $scope.profile.id
    $scope._status = { adding: "" }
    $scope.properties = $scope.profile.organization.properties
    $scope.division = WhatDivision
    $scope.expanded = $scope.profile.organization.properties?.expandedMedications
    $scope.uploadInstructions = $scope.profile.organization.properties?.medicationConfirmationUploadInstructions or null

    # Get FDA Medications
    $scope.fdaMeds = null
    Constants.fdaMeds().then (result) ->
      $scope.fdaMeds = _.keys(result).sort()
      $scope.strengths = result

    $scope.editPermissions = $filter('permissionVisible')({ profile_medications: 'edit' })

    $scope.confirmationAction = ->
      route = "/api/profiles/#{profileID}/answers/upload"
      if $scope.profile.properties.medicationConfirmationFile
        route += "?prepend=#{$scope.profile.properties.medicationConfirmationFile}"
      route

    if $scope.profile.properties.medicationConfirmationFile
      Images.getForProfile(profileID, $scope.profile.properties.medicationConfirmationFile).then (files) ->
        $scope.files = files

    $scope.onUpload = (content) ->
      if !$stateParams.review #reviewMode isn't present here
        $scope.save()

      $scope.profile.properties.medicationConfirmationFile = content.key

    $scope.removeFile = () ->
      $scope.save()

    $scope.hasChoices = [
      { label: "Yes", value: "true" }
      { label: "No", value: "false" }
    ]

    $scope.defaultTimes = _.assign(
      "Overnight":     false
      "Early Morning": false
      "Breakfast":     true
      "Late Morning":  false
      "Lunch":         true
      "Afternoon":     false
      "Dinner":        true
      "Evening":       false
      "Bedtime":       true
      "As Needed":     true
    , $scope.question.metadata.times)

    $scope.downloadConfirmation = ->
      route = "/api/profiles/#{$scope.profile.id}/medication-confirmation"
      $http.get(route).then (result) -> window.open result.data, "_top"

    updateMedications = ->
      $scope.otcMeds = _.reject $scope.otcMeds, 'deactivated'
      $scope.medications = _.filter $scope.medications, (m) -> !m.deactivated
    do updateMedications

    evaluateHideConfirmation = ->
      medications = _.filter $scope.medications, (m) -> m.mode isnt "edit"
      $scope.hideConfirmation = (_.every medications, (m) -> !m.willTake) and !$scope.question.metadata.confirmNoneTaking

    $scope.resetNew = ->
      $scope.newMedication = { profileID: $scope.profile.id, mode: "edit", daysTaken: window.lib.meds.defaultDays() }

    $scope.addNewMedication = ->
      do $scope.resetNew
      $scope.adding = true

    $scope.save = ->
      meds = $scope.medications.concat($scope.otcMeds).map (med) ->
        if !med.interval then med.interval = 1
        return med
      # Make sure changes by providers don't trigger review flags
      if !$stateParams.review
        healthForm.profile.reviewFlags ?= []
        healthForm.profile.reviewFlags.push($scope.question.id.toString())
      healthForm.save("medications", profileID, meds).success (meds) ->
        justSaved = true
        _.map meds, (m) -> m.mode = "view"
        $scope.medications = _.reject(meds, 'otcID')
        $scope.otcMeds = _.filter(meds, 'otcID')
        do $scope.resetNew
        do updateMedications
        do evaluateHideConfirmation


    if $scope.question.expired && $scope.question.expirationType == "medications"
      _.map($scope.medications, (med) -> return med.deactivated = new Date())
      if _.isObject($scope.profile.has.medications)
        delete $scope.profile.has.medications.value
      else
        $scope.profile.has.medications = {}
        $scope.profile.hasMedications = null
      $scope.save()

    if _.isUndefined $scope.profile.hasMedications
      if _.isObject $scope.profile.has.medications
        $scope.profile.hasMedications = $scope.profile.has.medications.value
      else $scope.profile.hasMedications = $scope.profile.has.medications

    $scope.requireUpload = _.some $scope.profile.registrations, (r) ->
      r.group.compiledProperties.requireConfirmationUpload
    $scope.requirePrint = _.some $scope.profile.registrations, (r) ->
      r.group.compiledProperties.requireConfirmationPrint

    $scope.addMedication = ->
      $scope.medications.push _.cloneDeep $scope.newMedication
      do $scope.save
      do $scope.resetNew
      $scope.question.expired = null
      $scope.adding = false

    $scope.cancelNew = ->
      $scope.adding = false
      # Set has back to unanswered if they cancel a new medication with no other active meds
      if !$scope.medications.length then $scope.profile.hasMedications = null
      do $scope.resetNew

    $scope.cancelRemove = ->
      $scope._status.delete = null

    $scope.removeMedication = (med) ->
      med.deactivated = new Date()
      do $scope.save

    $scope.complete = (medication) ->
      required = ["name", "strength", "doseValue", "doseType", "indication", "willTake", "timesTaken"]
      basicReq = _.every required, (r) ->
        if r is "timesTaken" then return _.some medication[r]
        _.isPresent medication[r]
      if !$scope.expanded then return basicReq
      expandedReq = _.every ["emergency", "canSelfAdminister"], (r) -> _.isPresent medication[r]
      return basicReq and expandedReq

    healthForm.completeFns[$scope.question.id].push ->
      if $scope.question?.expiration
        if $scope.profile.has.medications?.entered
          return false if $scope.question.expiration > $scope.profile.has.medications.entered
        else return false

      if $scope.profile.has.medications && ($scope.profile.has.medications is "false"  || $scope.profile.has.medications.value is "false") then return true

      # If we're editing a medication, just say we're incomplete
      if (_.some $scope.medications, (m) -> m.mode is "edit")
        return false

      do evaluateHideConfirmation

      medications = _.filter $scope.medications, (m) -> m.mode isnt "edit"
      if !medications.length then return false
      if $scope.requireUpload
        if !$scope.profile.properties.medicationConfirmationFile and !$scope.hideConfirmation then return false
      if $scope.requirePrint
        if !$scope.profile.properties.confirmationPrinted && !$scope.hideConfirmation then return false
      complete = _.every medications, (m) ->
        $scope.complete m
      return complete

    $scope.medConfDownload = "/api/profiles/#{$scope.profile.id}/medication-confirmation"
    if $scope.question.metadata.includeNotTaking then $scope.medConfDownload += "?includeNotTaking=true"

    if $scope.profile.has.medications.value is "true" and !$scope.medications.length
      do $scope.addNewMedication

    $scope.$watch "profile.hasMedications", (freshHas, staleHas) ->
      if freshHas is staleHas then return
      do healthForm.checkComplete[$scope.question.id]
      if freshHas is "true" and !$scope.medications.length then do $scope.addNewMedication
      if freshHas is "false" and staleHas is "true"
        _.map $scope.medications, (m) -> m.deactivated = new Date()
        do $scope.resetNew
      $scope.profile.has.medications = { value: freshHas, entered: new Date().toISOString() }
      do $scope.save
    , true

    $scope.$watch "medications", ->
      if initialized and not justSaved then do updateMedications
      do healthForm.checkComplete[$scope.question.id]
      justSaved = false
      initialized = true
      if $scope.profile.has.medications.value is "true" and !$scope.medications.length
        do $scope.addNewMedication
    , true
