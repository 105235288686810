angular.module('dn').directive('blackbaudSettings', function () {
  return {
    templateUrl: 'directives/blackbaud-settings/blackbaud-settings.directive.html',
    restrict: 'E',
    scope: {
      group: '=',
      organization: '=',
    },
    controller: function ($http, $scope, flash, $filter) {
      $scope.baseURL = `/api/organizations/${$scope.organization.id}/blackbaud-settings`;

      $scope.applicationID = window.dnim.constants.blackbaud.applicationID;
      $scope.blackbaudSettings = { enabled: false, sync_options: { enabled: false } };
      $scope.hasBlackbaud = false;
      $scope.isOrganization = $scope.group.id === $scope.organization.id;
      $scope.loadError = false;
      $scope.origin = window.location.origin;

      $scope.requestInProgress = false;
      $scope.hasEditPermissions = $filter('permissionVisible')({ settings_basics: 'edit' });

      loadSettings();

      $scope.yesNo = [
        { label: 'No', value: false },
        { label: 'Yes', value: true }
      ];

      $scope.boardingOrDayOptions = [
        { label: 'Boarding', value: 'B' },
        { label: 'Day', value: 'D' },
      ];

      $scope.allowSave = () => {
        if (!$scope.hasBlackbaud) {
          if (!$scope.blackbaudSettings.enabled) {
            return false;
          } else {
            const fields = ['url', 'on_secret', 'on_username', 'on_password', 'sso_secret_key'];
            const fieldPopulated = fields.some((field) => !!$scope.blackbaudSettings[field]);
            return fieldPopulated && validSyncSettings();
          }
        } else {
          return validSyncSettings();
        }
      };

      $scope.save = () => {
        const saveOperations = [];

        $scope.requestInProgress = true;

        if ($scope.isOrganization) {
          saveOperations.push(saveBlackbaudSettings());
        }
        saveOperations.push(saveBlackbaudProperties());

        // No need to catch errors here, since errors are handled by the called
        // functions.
        return Promise.all(saveOperations).then(
          () => $scope.requestInProgress = false
        );
      };

      $scope.runManualSync = () => {
        return swal({
          title: 'Confirm Manual Sync',
          type: 'info',
          text: 'Run a manual profile/registration sync? The sync can take up to 5 minutes to run. You will not be notified on completion.',
          confirmButtonText: 'Confirm',
          showCancelButton: true
        }, function(isConfirmed) {
          if (isConfirmed) {
            $scope.requestInProgress = true;
            $http.post(`${$scope.baseURL}/${$scope.blackbaudSettings.id}/manual-sync`)
              .then(() => {
                // We want to leave the button for manual sync disabled when a manual sync is in progress until we improve the UX to be more reactive
                flash('Manual Sync has started');
              })
              .catch((err) => {
                $scope.requestInProgress = false;
                flash('Unable to run manual sync');
                log.error(`Unable to run manual sync: ${JSON.stringify(err)}`);
              });
          }
        });
      };

      // CRUD Helper Functions

      function loadSettings() {
        $http.get(`/api/organizations/${$scope.organization.id}/blackbaud-settings`).then((response) => {
          if (response.status === 200) {
            // Don't put the token data on scope since token updating is done through the BE
            delete response.data.sky_access_token;
            delete response.data.sky_refresh_token;
            $scope.blackbaudSettings = response.data;
            $scope.blackbaudSettings.enabled = true;
            $scope.disableBlackbaudSyncOptions = !(
              $scope.blackbaudSettings.enabled
              && $scope.blackbaudSettings.sync_options.enabled
            );
            $scope.hasBlackbaud = true;
            $scope.loadError = false;
            $scope.profileRoleIDs = profileRoleIDsString();
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          $scope.loadError = true;
          flash('Unable to load Blackbaud Settings');
        });
      }

      /**
       * Insert, update, or deactivate blackbaud_settings data.
       */
      function saveBlackbaudSettings() {
        $scope.blackbaudSettings.sync_options.profileRoleIDs = profileRoleIDsArray();
        if ($scope.hasBlackbaud) {
          return ($scope.blackbaudSettings.enabled ? updateSettings() : deactivateSettings());
        } else {
          if ($scope.blackbaudSettings.enabled) {
            return insertSettings();
          }
        }

        // Don't think we can ever get here, but returning a promise so we have a
        // consistent return type.
        return Promise.resolve();
      }

      function updateSettings() {
        const url = `${$scope.baseURL}/${$scope.blackbaudSettings.id}`;
        /*
        If you hit `Update Tokens` and then Save after authorizing the application,
        the PUT request will overwrite whatever tokens were just generated,
        either with the previous, now-invalid tokens, or null depending on your setup
        delete these keys here so the API route uses the tokens on req.blackbaudSettings, not req.body
        */
        delete $scope.blackbaudSettings.sky_access_token;
        delete $scope.blackbaudSettings.sky_refresh_token;
        return $http.put(url, $scope.blackbaudSettings).then((response) => {
          if (response.status === 200) {
            $scope.blackbaudSettings = response.data;
            $scope.blackbaudSettings.enabled = true;
            $scope.disableBlackbaudSyncOptions = !(
              $scope.blackbaudSettings.enabled
              && $scope.blackbaudSettings.sync_options.enabled
            );
            flash('Updated Blackbaud Settings');
          } else {
            flash('Unable to update Blackbaud Settings');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flash('Unable to update Blackbaud Settings');
        });
      }

      function deactivateSettings() {
        const url = `${$scope.baseURL}/${$scope.blackbaudSettings.id}`;
        return $http.delete(url).then((response) => {
          if (response.status === 204) {
            flash('Deactivated Blackbaud Settings');
            // Clear fields on scope after deactivation
            Object.keys($scope.blackbaudSettings).map((key) => {
              if (key !== 'enabled') {
                $scope.blackbaudSettings[key] = null;
              }
            });
            $scope.hasBlackbaud = false;
          } else {
            flash('Unable to deactivate Blackbaud Settings');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flash('Unable to deactivate Blackbaud Settings');
        });
      }

      function insertSettings() {
        const url = $scope.baseURL;
        $scope.blackbaudSettings.org_id = $scope.organization.id;

        return $http.post(url, $scope.blackbaudSettings).then((response) => {
          if (response.status === 201) {
            $scope.blackbaudSettings = response.data;
            $scope.blackbaudSettings.enabled = true;
            $scope.hasBlackbaud = true;
            flash('Inserted Blackbaud Settings');
          } else {
            flash('Unable to insert Blackbaud Settings');
          }
        // eslint-disable-next-line no-unused-vars
        }).catch((err) => {
          flash('Unable to insert Blackbaud Settings');
        });
      }

      /**
       * Save Blackbaud mapping data that is stored on groups.properties.blackbaud
       */
      function saveBlackbaudProperties() {
        return $scope.group.save(['properties'])
          .then(() => flash('Updated Blackbaud Sync Mapping'))
          .catch((err) => {
            log.error('Error updating Blackbaud Sync Mapping', err);
            flash('Unable to update Blackbaud Sync Mapping');
          });
      }

      /**
       * Checks if the sync settings are valid. Checks that required fields are populated
       * and that data is formatted correctly.
       * @returns {boolean} True if the sync settings are valid
       */
      function validSyncSettings() {
        const syncEnabled = _.get($scope, 'blackbaudSettings.sync_options.enabled');
        if (!syncEnabled) return true;
        if (!$scope.profileRoleIDs) return false;
        const validChars = /^[0-9,]+$/.test($scope.profileRoleIDs);
        if (!validChars) return false;
        const idCount = $scope.profileRoleIDs
          .split(',')
          .filter(id => id !== '')
          .length;
        return idCount > 0;
      }

      /**
       * Returns an array representation of the comma delimited Profile Role ID list
       * @returns {number[]} An array of Role IDs
       */
      function profileRoleIDsArray() {
        if (!$scope.profileRoleIDs) return [];
        return $scope.profileRoleIDs
          .split(',')
          .filter(id => id !== '')
          .map(id => Number.parseInt(id));
      }

      /**
       * Returns a comma delimited string representation of the Profile Role ID array
       * @returns {string} A comma delimited list of Role IDs
       */
      function profileRoleIDsString() {
        return ($scope.blackbaudSettings.sync_options.profileRoleIDs || []).join(',');
      }
    }

  };
});
