angular.module('dn').directive('clearIdentifiers', function() {
  return {
    templateUrl: 'directives/clear-identifiers/clear-identifiers.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      fullTextSearchEnabled: '='
    },

    controller($http, $scope) {

      $scope.clearIdentifiers = function() {
        let selectedProfiles = [];
        // old logic, to be removed when FullTextSearchProfiles is fully implemented
        if (!$scope.fullTextSearchEnabled) {
          if (_.some($scope.profiles, p => p.selected)) {
            selectedProfiles = _.filter($scope.profiles, p => p.selected);
          } else {
            selectedProfiles = $scope.profiles;
          }
        }

        const profileIDs = () => {
          if ($scope.fullTextSearchEnabled) {
            return $scope.profiles;
          } else {
            // old logic, to be removed when FullTextSearchProfiles is fully implemented
            return selectedProfiles.map(p => p.profiles.id);
          }
        };

        const selectedLength = () => {
          if ($scope.fullTextSearchEnabled) {
            return $scope.profiles.length;
          } else {
            // old logic, to be removed when FullTextSearchProfiles is fully implemented
            return selectedProfiles.length;
          }
        };

        if (!selectedLength()) {
          window.swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to clear identifiers.'
          });
          return;
        } else {
          profilesText = selectedLength() > 1 ? 'profiles' : 'profile';
          window.swal({
            type: 'warning',
            title: 'Clear Identifiers',
            text: `This action will permanently delete the identifiers for ${selectedLength()} ${profilesText}. This cannot be undone. Do you want to continue?`,
            showCancelButton: true
          }, (confirmed) => {
            if (confirmed) {
              $scope.updating = true;
              const body = {
                'profiles': profileIDs()
              };
              $http.put(`/api/groups/${$scope.organization.id}/profiles/clear-identifiers`, body).then(() => {
                flash(`${selectedLength()} profile(s) updated.`);
                $scope.updating = false;
              });
            }
          });
        };
      };
    }
  };
});
