angular.module("dn").directive("buttonSwitch", function($timeout) {
  return {
    templateUrl: "directives/input/types/button-switch/button-switch.directive.html",
    replace: true,
    restrict: "E",
    scope: {
      model: "=",
      label: "=",
      id: "=",
      choices: "=",
      ngDisabled: "="
    },
    controller: function($scope) {

      $scope.selectOption = (value) => {
        $scope.model = value
      }
    }
  };
});
