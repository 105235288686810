lib.registerState "manager.healthLog.templates",
  url: "/templates"
  resolve:
    title: ($rootScope) -> $rootScope._title = "Health Log Templates"
    templates: ($http, $filter, provider) ->
      return [] if !provider
      templates = []

      formatLog = (result) ->
        _.each result, (template) ->
          templates.push template if template.profileID is provider.id

      retrieveTemplates = () ->
        # Sets variables as unefined
        healthLogPromise
        behavioralPromise

        if $filter('permissionVisible')({ health_log: 'edit' })
          healthLogPromise = $http.get("/api/profiles/#{provider.id}/templates?type=health-log").then (result) ->
            return result.data
        if $filter('permissionVisible')({ behavioral_health: 'edit' })
          behavioralPromise = $http.get("/api/profiles/#{provider.id}/templates?type=behavioral-health").then (result) ->
            return result.data
        return [healthLogPromise, behavioralPromise]

      return Promise.all(retrieveTemplates())
        .then (results) ->
          # filter null in results
          results = _.filter results, (r) -> r
          _.each results, formatLog
          return _.sortBy templates, "name"

    complaints: ($http) ->
      $http.get("/api/constants/complaints?sort=true", {cache:true}).then (result) ->
        # sort and filters complaints for behavioral health and sorts all complaints for general health
        behavioralHealthComplaints = _.map (result.data.behavioralHealth).sort(), (v) -> { label: v, value: v }
        generalHealthComplaints = _.map _.keys(result.data.generalHealth).sort(), (v) -> { label: v, value: v }

        return { "behavioral-health": behavioralHealthComplaints, "general-health": generalHealthComplaints }

    moods: ($http) ->
      $http.get("/api/constants/moods", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    sras: ($http) ->
      $http.get("/api/constants/sras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    affects: ($http) ->
      $http.get("/api/constants/affects", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    cognitiveFunctionings: ($http) ->
      $http.get("/api/constants/cognitiveFunctionings", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    anras: ($http) ->
      $http.get("/api/constants/anras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    bras: ($http) ->
      $http.get("/api/constants/bras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    sibas: ($http) ->
      $http.get("/api/constants/sibas", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

    hras: ($http) ->
      $http.get("/api/constants/hras", { cache:true }).then (result) ->
        _.map result.data, (v) -> { label: v, value: v }

  templateUrl: "states/manager/health-log/templates/templates.state.html"
  controller: ($scope, provider, templates, organization, LogEntry, Template, $state, flash, complaints, moods, sras, affects, cognitiveFunctionings, anras, bras, sibas, hras, $filter) ->
    if !provider then return $state.go("manager.healthLog.browse")

    locations = _.map organization.properties.healthLogLocations, (l) -> { label: l, value: l }

    exclude = [
      "diagnosis"
      "nursingDiagnosis"
      "followUp"
      "providerID"
    ]

    exclude.push("medicationID") unless organization.properties.enableOTC

    $scope.encounterTypeChoices = [
      "Individual",
      "Group",
      "Intake",
      "Risk Assessment",
      "Parent/Guardian/Family"
    ]

    if organization.properties.customDispositions
      dispositions = organization.properties.customDispositions
    else
      dispositions = [
        "Admitted"
        "Ambulance"
        "Discharged"
        "Emergency Department"
        "Home"
        "Observed"
        "Urgent Care"
      ]

    $scope.exclude = (models) ->
      _.some models, (m) -> m.model in exclude

    $scope.modelChoices =
      encounterType:    $scope.encounterTypeChoices
      complaint:        complaints
      location:         locations
      affect:           affects
      mood:             moods
      sra:              sras
      cognitiveFunctioning: cognitiveFunctionings
      anra:             anras
      bra:              bras
      siba:             sibas
      hra:              hras
      oxygenSaturation: (_.map _.range(1, 101), (v) -> { label: "#{v}%", value: v }).reverse()
      pain:             _.map _.range(0, 11), (v) -> { label: v, value: v }
      temperatureUnit:  _.map ["Fahrenheit", "Celsius"], (v) -> { label:v, value:v }
      bloodGlucose:     _.map _.range(0, 1000), (v) -> { label: v, value: v }
      heartRate:        _.map _.range(0, 400), (v) -> { label: v, value: v }
      respiratoryRate:  _.map _.range(0, 100), (v) -> { label: v, value: v }
      disposition:      _.map dispositions, (d) -> { label: d, value: d }

    $scope.sectionType = "generalHealth"
    $scope.default =
      template: { sessionTime: null, sections: _.cloneDeep(LogEntry.sections[$scope.sectionType]) }
      shared: false

    $scope.saveType = "new"

    $scope.showSessionAndEncounter = false

    # Set the session time and encounter type based on the template type
    # and insert correct order and options of complaints
    configureTemplateProperties = () ->
      if $scope.newTemplate.type is "behavioral-health"
        $scope.showSessionAndEncounter = true
        $scope.modelChoices.complaint = complaints["behavioral-health"]
      else if $scope.newTemplate.type is "health-log"
        $scope.showSessionAndEncounter = false
        $scope.modelChoices.complaint = complaints["general-health"]

    $scope.newTemplate = new Template (
      name: ""
      template: _.cloneDeep($scope.default.template)
      profileID: provider.id
      groupID: organization.id
      shared: false
    )

    # Map templates by id or "default"
    $scope.templates            = templates
    $scope.templateMap          = _.keyBy templates, "id"
    $scope.templateMap.default  = $scope.default

    $scope.healthType = [
      { label: 'General Health', value: 'health-log' }
      { label: 'Behavioral Health', value: 'behavioral-health' },
    ]

    $scope.enableHealthType = false

    # Formats the template label in the case an acronym is being used
    # Since we repeat over the model instead of the label, we need to format it ourselves.
    $scope.formatTemplateLabel = (model) ->
      acronyms = {
        'sra': 'Suicidal Risk Assessment',
        'anra': 'Abuse/Neglect Risk Assessment'
        'bra': 'Bullying Risk Assessment'
        'siba': 'Self-Injurious Behavior Assessment'
        'hra': 'Homicidal Risk Assessment',
      }

      if acronyms[model]
        return acronyms[model]
      else
        $scope.modelName(model)

    runPermission = () ->
      if $filter('permissionVisible')({ health_log: 'edit' }) && $filter('permissionVisible')({ behavioral_health: 'edit' })
        $scope.newTemplate.type = $scope.healthType[0].value
        $scope.enableHealthType = true
        configureTemplateProperties()
      else if $filter('permissionVisible')({ behavioral_health: 'edit' })
        $scope.newTemplate.type = $scope.healthType[1].value
        configureTemplateProperties()
      else if $filter('permissionVisible')({ health_log: 'edit' })
        $scope.newTemplate.type = $scope.healthType[0].value
        $scope.showSessionAndEncounter = false

    $scope.$watch "newTemplate.type", () ->
      # clear out encounterType if not behavioral health
      if $scope.newTemplate.type is "health-log"
        $scope.newTemplate.template.sections[0].subsections[0].models[0].value = null

    # Create choices for starting from an existing one
    $scope.choices = _.compact (_.map $scope.templateMap, (value, key, map) ->
      return null if key is "default"
      { label: map[key].name,  value: key, optgroup: "Custom Templates" }
    )

    $scope.existingTemplates = _.cloneDeep $scope.choices

    $scope.choices.unshift { label: "Default Template", value: "default", optgroup: "Default"}

    # The 'slate' is what we'll start with when making a new template
    $scope.templateSlate = "default"
    $scope.slate = $scope.templateMap[$scope.templateSlate]

    $scope.newSlate = (slate) ->
      $scope.slate = $scope.templateMap[slate].template.sections
      if slate == 'default'
        runPermission()
      if $scope.templateMap[slate].type
         $scope.newTemplate.type = $scope.templateMap[slate].type

      $scope.sectionType = if $scope.newTemplate.type is "behavioral-health" then "behavioralHealth" else if 'health-log' then "generalHealth"
      $scope.default.template.sections = _.cloneDeep(LogEntry.sections[$scope.sectionType])

      configureTemplateProperties()

      defaults = _.cloneDeep $scope.templateMap[$scope.templateSlate].template.sections
      $scope.newTemplate.shared = $scope.templateMap[slate].shared
      $scope.slate = $scope.templateMap[$scope.templateSlate]
      $scope.newTemplate.template.sections = _.assign defaults, $scope.slate, (value, newVal, key) ->
        if key is "hide" then return null
        if key isnt "value" then return newVal
        if value and !newVal then value = ""
      if $scope.newTemplate.type is "behavioral-health"
        $scope.newTemplate.template.sessionTime = $scope.templateMap[slate].template.sessionTime
        $scope.newTemplate.template.encounterType = $scope.templateMap[slate].template.encounterType
    $scope.newSlate $scope.templateSlate

    $scope.modelName = (str) -> _.startCase str

    $scope.$watch "saveType", () ->
      if $scope.newTemplate.id then delete $scope.newTemplate.id
      $scope.newTemplate.name = ''
      $scope.overwriteTemplate = null

    $scope.saveTemplateCondition = () ->
      validSessionTime = false
      isNullOrEmptySessionTime = $scope.newTemplate.template.sessionTime == null or $scope.newTemplate.template.sessionTime == ''
      isValidIntSessionTime = !!parseInt($scope.newTemplate.template.sessionTime) and parseInt($scope.newTemplate.template.sessionTime) > 0

      if (isNullOrEmptySessionTime and !isValidIntSessionTime) or (!isNullOrEmptySessionTime and isValidIntSessionTime)
        validSessionTime = true
      else
        return true

      saveNew = !$scope.newTemplate.name && $scope.saveType == 'new'

      overwriteTemplateInvalid = ($scope.overwriteTemplate == null and $scope.saveType is "existing")
      overwriteExisting = overwriteTemplateInvalid or ($scope.saveType == 'existing' and $scope.newTemplate.type != $scope.templateMap[$scope.overwriteTemplate].type) or !$scope.templateSlate

      if !validSessionTime
        return true
      return saveNew or overwriteExisting

    $scope.$watch "overwriteTemplate", (overwrite, old) ->
      return if overwrite is old
      if $scope.saveType is "new"
        delete $scope.newTemplate.id
        $scope.newTemplate.name = ""
        return

      if $scope.saveType is "existing" and overwrite
        if $scope.newTemplate.type == $scope.templateMap[overwrite].type
          $scope.newTemplate.id = $scope.templateMap[overwrite].id
          $scope.newTemplate.name = $scope.templateMap[overwrite].name
          $scope.newTemplate.type = $scope.templateMap[overwrite].type
      else if $scope.saveType is "existing" and !overwrite
        $scope.newTemplate.id = ''
        $scope.newTemplate.name = ''

    , true

    $scope.$watch "newTemplate.type", (fresh, stale) ->
      return if fresh is stale
      if fresh is "behavioral-health"
        $scope.showSessionAndEncounter = true
        $scope.modelChoices.complaint = complaints["behavioral-health"]
      else
        $scope.showSessionAndEncounter = false
        $scope.newTemplate.template.sessionTime = null
        $scope.newTemplate.template.encounterType = null
        $scope.modelChoices.complaint = complaints["general-health"]

      $scope.sectionType = if $scope.newTemplate.type is "behavioral-health" then "behavioralHealth" else if 'health-log' then "generalHealth"
      $scope.default.template.sections = _.cloneDeep(LogEntry.sections[$scope.sectionType])

      defaults = _.cloneDeep $scope.templateMap[$scope.templateSlate].template.sections
      $scope.slate = $scope.templateMap[$scope.templateSlate]
      $scope.newTemplate.template.sections = _.assign defaults, $scope.slate, (value, newVal, key) ->
        if key is "hide" then return null
        if key isnt "value" then return newVal
        if value and !newVal then value = ""

    $scope.save = ->
      if $scope.newTemplate.template.sessionTime != null
        $scope.newTemplate.template.sessionTime = parseInt($scope.newTemplate.template.sessionTime)
      $scope.newTemplate.save()
      flash "Template Saved"
      $state.reload $state.current.name

    $scope.deleteTemplate = ->
      return unless $scope.templateSlate isnt "default"
      return if !$scope.templateMap[$scope.templateSlate]?.id
      new Template($scope.templateMap[$scope.templateSlate]).destroy().then ->
        flash "Template Deleted"
        $state.reload $state.current.name

    # when deselecting a field, make sure the associated models values are nullified
    $scope.resetModels = (subsection) ->
      if subsection.show isnt true
        subsection.models.forEach (m) -> m.value = null
