# The HealthForm factory is used in the taker to store all of our question completeness
# functions, save the profile's questionnaire, and store our save functions as well.
#
# We create a new one for each profile in case someone is going through and filling out
# multiple health forms for multiple profiles without reloading the page.

angular.module("dn").factory "HealthForm", ($http, flash, $timeout, $state, $compile) ->
  healthForm = {}

  # parentQuestion creates a circular reference for saving, so we create a copy
  # without the circular references
  copyQuestionnaire = (questionnaire) ->
    _.cloneDeepWith questionnaire, (node) ->
      if node and node.parentQuestion then delete node.parentQuestion
      return undefined


  # Save the profile's new questionnaire so we have updated completeness values
  # without having to digest again
  healthForm.saveProfile = _.debounce (id) ->
    if not healthForm[id].profile then return
    p = healthForm[id].profile
    copyProfile = { id: p.id }
    copyProfile.newQuestionnaire = copyQuestionnaire p.newQuestionnaire
    $http.put "/api/profiles/#{id}", copyProfile
  , 5000


  # Generic save function for different types of questions
  # e.g. answers, insurances, etc.

  # Also covers error handling if any save function returns an error.
  saveFn = (type, profileID, items) ->
    if not type or not items then return
    if not _.isArray(items) then items = [items]
    type = _.kebabCase type
    route = "/api/profiles/#{profileID}/#{type}"
    healthForm.saveProfile profileID
    healthForm[profileID].saving = true

    return $http.put(route, items)
      .success (result) ->
        healthForm[profileID].saveMessage = true
        healthForm[profileID].lastSaved = new Date()
        healthForm[profileID].saving = false
        $timeout ->
          healthForm[profileID].saveMessage = false
        , 2000
        return result
      .error (error) ->
        window.swal
          title: "Health Form Error"
          text: """
            There was an error saving your information.
            If this is the first time you've received this error,
            click below to refresh your page and try again.

            If the problem persists, please contact us via the support
            link at the top right corner of your screen.
          """
          type: "error"
          confirmButtonText: "Try Again"
          confirmButtonColor: "#5CA100"
          showCancelButton: true
        , (isConfirm) ->
          if isConfirm then $state.reload()


  # Instantiate a health form for a profile.
  healthForm.get = (profileID, profile) ->
    healthForm[profileID] ?= {}
    healthForm[profileID].active ?= {}
    healthForm[profileID].checkComplete ?= {}
    healthForm[profileID].completeFns ?= {}
    healthForm[profileID].noAnswer ?= {}
    healthForm[profileID].save = saveFn
    healthForm[profileID].saveProfile = healthForm.saveProfile
    if profile then healthForm[profileID].profile = profile
    healthForm[profileID]


  # Pre-compile different question types. Before we were using ng-if on the types, however there's
  # some wonkiness with ng-if and directives that made page-loads slow, so instead we compile JUST
  # the one that we want.
  healthForm.compiler = (type) ->
    nonModules = ["authorization", "multi-auth", "boolean", "download", "instructions", "select", "text", "upload", "encrypted"]

    # Create the directive we're rendering
    directiveType = _.kebabCase type
    tpl = "<div ng-if=\"active\" style='display:inline' qt-#{directiveType}=\"question\" "
    if type in nonModules
      tpl += "answer=\"answer\">"
    else
      tpl += "#{directiveType}=\"profile.#{type}\" profile=\"profile\" pharmacy=\"pharmacy\">"
    tpl += "</div>"
    return $compile(tpl)

  healthForm
