lib.registerState "patient.profile.questionnaire.step",
  url: "/step/:step"
  templateUrl: "states/patient/profile/questionnaire/step/step.state.html"
  controller: ($scope, $stateParams, $anchorScroll, Event, HealthForm, profile) ->

    $scope.extensionRequests = {}

    _.each profile.properties?.stepExtensions, (request) ->
      if moment(request.extensionDate).isBefore(moment new Date())
        _.remove profile.properties?.stepExtensions, (r) -> r.stepID is request.stepID
        request = null
        return new Profile(profile).save(["properties"])

      $scope.extensionRequests[request.stepID] =
        lockout: request.displayDate or null
        granted: request.granted

    healthForm = HealthForm.get $scope.profile.id, $scope.profile
    $scope.answers = $scope.profile.answers
    $scope.step = $scope.profile.newQuestionnaire.questions[parseInt($stateParams.step) - 1]

    $scope.isLocked   = moment(new Date($scope.step?.metadata?.lockout)).isBefore(moment())
    $scope.prettyLock = moment($scope.step?.metadata?.lockout).format "MMM DD, YYYY"

    flatten = (questions, flat, parent) ->
      return unless questions?.length
      _.map questions, (q, index) ->
        q.locked = true if $scope.profile.lockedOut
        q.stepLockout = !$scope.reviewMode if q.stepLockout
        q.parentQuestion = parent
        q.index = index
        q.rootQuestion = q.type isnt "form" and q.parentQuestion?.type in ["step", "form"]
        $scope.profile.answers[q.id] = $scope.answers[q.id] or { value: null }
        q.answer = $scope.profile.answers[q.id]
        q.condition = q.where if not q.condition # Accomodate unchanged newQuestionnaires
        flat.push q
        flatten q.subQuestions, flat, q
      flat

    flatStep = flatten [$scope.step], [], null
    $scope.flatStep = flatStep

    # Assign depths smartly here
    _.map $scope.flatStep, (question) ->
      if question.type in ["step", "form"] then question.depth = 0
      else if question.parentQuestion.type in ["step", "form"] then question.depth = 0
      else question.depth = question.parentQuestion.depth + 1

    stepAnswers = _.map $scope.flatStep, "answer"
    confirmableAnswers = () ->
      return $scope.flatStep.reduce (confirmableAnswers, question) ->
        if question.expired and question.expirationType is "step" and question.type isnt "step"
          confirmableAnswers.push(question.answer)
        return confirmableAnswers
      , []

    presentTypes = _.uniq _.map $scope.flatStep, "type"

    $scope.stepExpired = ->
      if $scope.stepConfirmed
        return false
      if $scope.step.expired and $scope.step.expiration
        return true
      else if $scope.step.complete
        return false
      else return !!confirmableAnswers().length

    $scope.requestExtension = ->
      profile.properties?.stepExtensions ?= []
      request =
        stepID: $scope.step.id
        stepName: $scope.step.label
        granted: false

      profile.properties?.stepExtensions.push request
      $scope.extensionRequests[request.stepID] =
        granted: request.granted

      new Event(
        profileID: profile.id
        type: "profile.stepUnlock"
        metadata: {
          patientName: profile.givenName + " " + profile.familyName
          step: $scope.step.label
        }
      ).save()

      flash "Extension Requested for #{$scope.step.label}"

    # Show blank answer values for questions expired by anything but a step
    _.map $scope.flatStep, (question, index) ->
      if question.expired and moment(question.answer?.updated).isBefore(question.expiration) and question.expirationType isnt "step"
        question.answer.value = null
        question.answer.metadata.relativeExpiration = null if question.metadata?.relativeExpiration
        if question.type == "medications"
          $scope.profile.has.medications = {}

    checkComplete = -> _.map $scope.step.subQuestions, "complete"

    $scope.$watch checkComplete, (vals) ->
      $scope.step.complete = _.every vals
    , true

    confirmModule = (name, collection) ->
      if name is "insurances" then collection = _.values collection
      if name is "medications"
        if typeof $scope.profile.has.medications is "string"
          $scope.profile.has.medications = { value: $scope.profile.has.medications, entered: new Date().toISOString() }
        else
          $scope.profile.has.medications.entered = new Date().toISOString()

      if name is "allergies" and $scope.profile.has.allergies
        $scope.profile.has.allergies.entered = new Date().toISOString()
      healthForm.save name, $scope.profile.id, collection
      _.map collection, (i) -> i.updated = Infinity

    $scope.confirmStep = ->
      confirmModule "answers", confirmableAnswers()
      modules = _.intersection [
        "allergies"
        "dimensions"
        "emergencyContacts"
        "immunizations"
        "insurances"
        "medications"
        "primaryProviders"
      ], presentTypes
      _.map modules, (m) -> confirmModule m, $scope.profile[m]
      $scope.stepConfirmed = true
      $scope.step.expired = false
