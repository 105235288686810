angular.module('dn').directive('donation', function() {
  return {
    templateUrl: 'directives/registration-wizard/donation/donation.directive.html',
    restrict: 'E',
    scope: {
      donation: '=',
      organization: '=',
      isComplete: '=',
      bypass: '='
    },
    controller($scope) {
      if (!$scope.organization.properties.donation) return $scope.bypass()

      if (typeof $scope.donation !== 'number') {
        $scope.donation = 0;
      }

      const MAX = 5000000;
      const MIN = 0;
      $scope.message = '';

      $scope.isComplete = () => MIN <= $scope.donation && $scope.donation <= MAX;

    }
  }
});
