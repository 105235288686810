angular.module("dn").directive("checkboxList", function () {
  return {
    templateUrl: "directives/checkbox-list/checkbox-list.directive.html",
    restrict: "E",
    scope: {
      terms: "=",
      acceptedTerms: "="
    },
    controller($scope) {
      //Verifies the data is correct
      $scope.validateTerms = (term) => {
        // eslint-disable-next-line eqeqeq
        if ([term.id, term.text, term.value].some(required => required == null)) {
          throw TypeError("id, text or value are undefined or null", "checkbox-list.directive.js");
        } else if (typeof term.text !== "string") {
          throw TypeError("text must be a string", "checkbox-list.directive.js");
        } else if (typeof term.value !== "boolean") {
          throw TypeError("value must be a boolean", "checkbox-list.directive.js");
        } else {
          return true;
        }
      };

      try {
        $scope.terms.map(term => {
          term.id = window.uuid();
          $scope.validateTerms(term);
        });
      } catch (err) {
        console.log(err);
      }

      //Marks checkbox if keypress was enter
      $scope.keyPress = (e, term) => {
        if (e.originalEvent.keyCode === 13) {
          term.value = !term.value;
        }
      };
      //Checks if all terms are true
      $scope.$watch('terms', () => {
        $scope.acceptedTerms = $scope.terms.every(term => term.value);
      }, true);
    }
  };
});
