lib.registerState('home.account.information', {
  url: '/information',
  templateUrl: 'states/home/account/information/information.state.html',
  resolve: {
    title: function($rootScope) {
      return $rootScope._title = "User Settings / About You";
    },
    userPhone: function($rootScope, session, UserPhone) {
      return new UserPhone({ userID: session.userID }).load()
      .then((user_phone) => {
        return user_phone || { may_text: false };
      })
      .catch((err) => {
        return { may_text: false };
      })
    }
  },
  controller: function ($scope, $state, $http, User, userPhone, flash) {

    let confirmedNumber, confirmationAttempts, fromState, fromParams;
    $scope.oldEmail = $scope.user.email;
    $scope.oldUser = _.cloneDeep($scope.user);
    $scope.userSMS = _.cloneDeep(userPhone);
    $scope.yesNo = [
      { label: "No", value: false },
      { label: "Yes", value: true }
    ];
    $scope.saving = false;
    setupRedirectOptions();


    $scope.save = function() {
      $scope.saving = true;
      // userSMS.phone_number should ALWAYS be the same as $scope.user.phone
      $scope.userSMS.phone_number = $scope.user.phone;
      $scope.userSMS.user_id = $scope.user.id;
      ($scope.user || {}).mailingAddress = (($scope.user || {}).location || {}).formatted;
      // Logic to check if the phone number or if `may_text` has changed

      new User($scope.user).save().then((user) => {
        $scope.user = user;
        if ($scope.oldEmail != user.email) {
          let emails = {
            updatedEmail: user.email,
            oldEmail: $scope.oldEmail
          }
          // This doesn't have a .then because we don't really care what happens with mailchimp emails
          $http.post(`/api/mailchimp/user/${$scope.user.id}/updateEmail`, emails);
          $scope.oldEmail = user.email;
        }
      }).catch(() => {
        return flash("Error saving user information");
      }).then(() => {
        // If they've changed their SMS info...
        if (changeNumber() && $scope.userSMS.may_text) {
          // Trigger dat conf logic
          confirmPhoneNumber();
        }
        else if (changeNumber() && !$scope.userSMS.may_text) {
          $http.post(`/api/users/${$scope.user.id}/add-sms-number`, $scope.userSMS).then(() => {
            if (userPhone.may_text) {
              return successSwal("out");
            }
            return finishAndRedirect();
          }).catch(() => {
            return swal({
              type: "error",
              title: "Error Opting Out",
              text: "Please try again in a few minutes.",
              confirmButtonText: 'Ok'
            }, (confirmed) => {
              return $scope.saving = false;
            });
          });
        }
        else {
          return finishAndRedirect();
        }
      }).catch(() => {
        $scope.saving = false;
        return flash("Error updating your account information");
      });
    }

    function changeNumber() {
      return (
        (!!$scope.oldUser.phone && !(_.isEqual($scope.oldUser.phone, $scope.user.phone))) ||
        !(_.isEqual(userPhone.may_text, $scope.userSMS.may_text)))
    }

    function finishAndRedirect() {
      $scope.saving = false;
      flash("Your account information has been updated");

      if (fromState) {
        return $state.go(fromState, fromParams);
      } else {
        return $state.go("home.whereTo");
      }
    }

    function successSwal(opted) {
      const swalText = {
        "in" : "We have confirmed your number. You will now be able to receive text message alerts.",
        "out": "We have deactivated your number. You will no longer receive text message alerts."
      }
      return swal({
        title: 'Success!',
        text: swalText[opted],
        type: 'success',
        confirmButtonText: 'Ok'
      }, (confirmed) => {
        return finishAndRedirect()
      });
    }

    function tooManyAttempts() {
      return swal({
        title: 'Too Many Attempts',
        text: 'Please try again later.',
        type: 'error',
        confirmButtonText: 'Ok'
      }, (confirmed) => {
        return $scope.saving = false;
      });
    }

    function errorSendingCode() {
      return swal({
        type: "error",
        title: "Error Sending Confirmation Code",
        text: "Please try again in a few minutes.",
        confirmButtonText: 'Ok'
      }, (confirmed) => {
        return $scope.saving = false;
      });
    }

    function yourCodeWasSent(config={}) {
      const swalConfig = {
        title: 'Your Code Was Sent',
        text: 'Please enter your code to confirm your number',
        type: 'input',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: 'Submit'
      }

      _.defaults(config, swalConfig);

      return swal(config, (input) => {
        if (input) {
          const attempt = { user_id: $scope.user.id, code: input, id: $scope.userPhoneID };
          $http.post(`/api/users/${$scope.user.id}/confirm-number`, attempt).then(() => {
            return successSwal("in");
          }).catch(() => {
            if (confirmationAttempts < 5) {
              config.title = "Incorrect Code"
              config.text = "Please try again."
              if (confirmationAttempts > 1) {
                config.text += `\n${5 - confirmationAttempts} attempts remaining.`
              }
              ++confirmationAttempts;
              return yourCodeWasSent(config);
            }
            else {
              return tooManyAttempts(confirmationAttempts);
            }
          });
        } else { // User hit cancel or entered an empty input
          $scope.userSMS.may_text = false;
          $scope.saving = false;
          // Have to post back to this route so we save false for may_text in the db
          $http.post(`/api/users/${$scope.user.id}/add-sms-number`, $scope.userSMS).then((success) => {
            return success;
          }).catch(() => {
            return errorSendingCode();
          });
          return swal.close();
        }
      });
    }

    function confirmPhoneNumber() {
      return swal({
        type: "info",
        title: "Confirm Your Phone Number",
        text: "We will send you a code to verify your access to the text message alert number you entered. Standard text messaging rates apply.",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Continue"
      }, (confirmed) => {
        if (confirmed) {
          $http.post(`/api/users/${$scope.user.id}/add-sms-number`, $scope.userSMS).then(({ data }) => {
             $scope.userPhoneID = data.id;
             confirmationAttempts = 0;
            return yourCodeWasSent();
          }).catch(() => {
            return errorSendingCode();
          });
        }
        else {
          $scope.userSMS.may_text = false;
          $http.post(`/api/users/${$scope.user.id}/add-sms-number`, $scope.userSMS).then(({ data }) => {
            $scope.userPhoneID = data.id;
            confirmationAttempts = 0;
          })
          return $scope.saving = false;
        }
      });
    }

    function setupRedirectOptions() {
      /*
      Chances are the user was brought here against their will via an automatic redirect
      Since we forced them here, better make the experience a good one
      Find whatever state they were redirected from, grab the name and params for later use
      */
      if ($state && $state.previous && $state.previous.name) {
        fromState = $state.previous.name;
        fromParams = $state.previous.params;
      } else {
        fromState = 'home.whereTo';
        fromParams = {};
      }

      /*
      If it's a profile state and somehow does not have a profileID in the params then we play it safe
      */
      if (fromState.match('profile') && !fromParams.profileID) {
        fromState = 'home.whereTo';
        fromParams = {};
      }
    }

    // Clear out old `location` objects from location directive v1
    if ((($scope.user || {}).location || {}).hasOwnProperty('street')) $scope.user.location = null;

    $scope.infoComplete = true;
    // Check that all of these fields have values
    const userFields = ['givenName', 'familyName', 'location', 'email', 'phone'];
    const watchThese = [
      'user.givenName',
      'user.familyName',
      'user.location',
      'user.email',
      'user.phone',
      'userSMS.may_text'
    ];
    // Whenever a prop on user changes...
    $scope.$watchGroup(watchThese, () => {
      // Ensure that all userFields have values
      const required = userFields.map(k => $scope.user[k]);
      $scope.infoComplete = required.every(v =>!!v);
    });
    // Check for extensions - opt them out and disable button switch if using an extention
    $scope.$watch('user.phone', (fresh) => {
      if(!fresh) return;
      if(fresh.match(/x/)) {
        $scope.disableMayText = true;
        $scope.userSMS.may_text = false;
      }
      else {
        $scope.disableMayText = false;
        $scope.userSMS.may_text = userPhone.may_text;
      }
    });
  }
});
