lib.registerState "manager.settings.general.registration",
  url: "/registration"
  templateUrl: "states/manager/settings/general/registration/registration.state.html"
  resolve:
    orgFeeSettings: ($http, organization) ->
      ppfFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees')
      if (ppfFlag)
        url = "/api/organization/#{organization.id}/finance/org-fee-settings"
        $http.get(url)
          .then(({ data }) -> data)
          .catch((error) ->
            if (error.status is 404)
              return {}
            else
              window.swal(
                {
                  type: "error"
                  title: "Unexpected Error"
                  text: "An error occurred while fetching.\n\n" + error.data + "\n\n Please try again."
                  allowOutsideClick: true
                  confirmButtonText: "Close"
                }
              )
          )

  controller: ($http, $scope, session, group, organization, flash, orgFeeSettings) ->
    $scope.ppfFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees')
    $scope.isOrganization = group.id is organization.id
    $scope.organization = organization

    validFee = (fee) ->
      return Number.isFinite(parseFloat(fee))

    setConvenienceFees = (orgFeeSettings) ->
      if (validFee(orgFeeSettings?.cardVariableRate))
        $scope.cardVariableConvenienceFee = orgFeeSettings?.cardVariableRate
      else $scope.cardVariableConvenienceFee = null

      if (validFee(orgFeeSettings?.cardFlatFee))
        $scope.cardFlatFeeConvenienceFee = orgFeeSettings?.cardFlatFee
      else $scope.cardFlatFeeConvenienceFee = null

      if (validFee(orgFeeSettings?.bankVariableRate))
        $scope.bankVariableConvenienceFee = orgFeeSettings?.bankVariableRate
      else $scope.bankVariableConvenienceFee = null

      if (validFee(orgFeeSettings?.bankFlatFee))
        $scope.bankFlatConvenienceFee = orgFeeSettings?.bankFlatFee
      else $scope.bankFlatConvenienceFee = null

    $scope.feePassedOn = orgFeeSettings?.feePassedOn || false
    setConvenienceFees(orgFeeSettings)

    $scope.validConvenienceFees = false

    validateConvenienceFees = ->
      fees =
        cardVariable:
          type: 'variable'
          value: $scope.cardVariableConvenienceFee
        cardFlat:
          type: 'flat'
          value: $scope.cardFlatFeeConvenienceFee
        bankVariable:
          type: 'variable'
          value: $scope.bankVariableConvenienceFee
        bankFlat:
          type: 'flat'
          value: $scope.bankFlatConvenienceFee

      MAX_VARIABLE_DECIMAL_PLACES = 3
      MAX_FLAT_DECIMAL_PLACES = 2

      validFees = Object.keys(fees).every((feeType) ->
        return false if !validFee(fees[feeType].value) && $scope.feePassedOn

        if fees[feeType].value
          return false if fees[feeType].value < 0

          decimalPlaces = getDecimalPlaces(fees[feeType].value)

          if fees[feeType].type == 'variable'
            return false if (decimalPlaces > MAX_VARIABLE_DECIMAL_PLACES)
          else if fees[feeType].type == 'flat'
            return false if (decimalPlaces > MAX_FLAT_DECIMAL_PLACES)
        else if fees[feeType].value == undefined
          return false

        return true
      )

      return validFees

    getDecimalPlaces = (value) ->
      return value.toString()
        ?.split('.')[1]
        ?.length || 0

    if ($scope.ppfFlag)
      $scope.$watchGroup(
        [
          'cardVariableConvenienceFee',
          'cardFlatFeeConvenienceFee',
          'bankVariableConvenienceFee',
          'bankFlatConvenienceFee'
          'feePassedOn'
        ], ->
          if !$scope.feePassedOn
            setConvenienceFees(orgFeeSettings)
          else
            $scope.validConvenienceFees = validateConvenienceFees()
      )

    $scope.regTab = 'line-items'
    $scope.regTab = 'setup' if session.access?.operator
    $scope.regTabs = [
      {label: 'Line Items', value: 'line-items'}
      {label: 'Setup', value: 'setup', oper: true, hide: !session.access?.operator}
      {label: 'Rates', value: 'rates', oper: true, hide: !session.access?.operator || !$scope.isOrganization}
      {label: 'Wizard Links', value: 'links', oper: true, hide: !session.access?.operator || !$scope.isOrganization}
    ]



    # LINE ITEMS SUBTAB
    $scope.group = group
    $scope.group.properties.lineItemPresets ?= []

    $scope.addDescription = (description) ->
      $scope.group.properties.lineItemPresets.push description
      $scope.group.properties.lineItemPresets.sort()
      $scope.group.save(['properties']).then ->
        flash "'#{description}' Added"
        $scope.showAdd = false
        $scope.newDescription = null

    $scope.removeDescription = (description) ->
      $scope.group.properties.lineItemPresets = _.without $scope.group.properties.lineItemPresets, description
      $scope.group.save(['properties']).then ->
        flash "'#{description}' Removed"



    # SETUP SUBTAB
    $scope.group.properties ?= {}
    $scope.group.properties.portals ?= {}
    $scope.group.properties.portals.patient ?= {}
    $scope.group.properties.features ?= {}

    $scope.registrationDisabled = do ->
      return true if group.id is organization.id
      parentRegisterable = do ->
        found = false
        checkParent = (g) ->
          if g.parent and g.parent.registration and g.parent.registration.enabled then found = true
          if !found and g.parent then checkParent g.parent
          return
        checkParent group
        return found
      if parentRegisterable then return true

      childrenRegisterable = do ->
        found = false
        checkChildren = (g) ->
          _.each g.children, (c) ->
            if c.registration and c.registration.enabled then found = true
            if not found then checkChildren c
            return
          return
        checkChildren group
        return found
      if childrenRegisterable then return true

    #Set up the organization's grades
    $scope.grades =
      str: group.properties?.gradeRange?.join(", ")
      arr: []

    $scope.$watch "grades.str", (str) ->
      return unless str
      $scope.grades.arr = _.compact str.replace(/(,\s)|(\s,)/g, ",").replace(/,$/g, "").split(",")
      group.properties.gradeRange = $scope.grades.arr



    # RATES SUBTAB
    # Initialize as false. If the org has no cards selected, then it will get updated below.
    $scope.noCardsAccepted = false
    $scope.ACCEPTED_CARDS = window.dnim.constants.billing.ACCEPTED_CARDS
    $scope.paymentChoices = [
      { label: "Credit Card & e-Check", value: "all" }
      { label: "Credit Card Only", value: "creditCard" }
      { label: "e-Check Only", value: "ACH" }
    ]
    $scope.cardChoices = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ]
    # Make sure we have a billing properties object at the very least
    group.properties.billing ?= {}
    # Make existing org settings easier to check
    acceptedByGroup = group.properties?.billing?.acceptedCards
    # Create card settings objet from existings settings; deafult everything to true
    $scope.cardSettings = $scope.ACCEPTED_CARDS.reduce (cardSettings, cardName) ->
      cardSettings[cardName] = if acceptedByGroup then acceptedByGroup.includes(cardName) else true
      return cardSettings
    , {}

    $scope.updateAcceptedCards = ->
      acceptedCards = Object.entries($scope.cardSettings).reduce (acceptedCards, [cardName, accepted]) ->
        if accepted then acceptedCards.push(cardName)
        return acceptedCards
      , []

      if !acceptedCards.length
        $scope.noCardsAccepted = true
        return
      else
        $scope.noCardsAccepted = false
        group.properties.billing.acceptedCards = acceptedCards


    # LINKS SUBTAB
    # Get shortnames from the organization
    shortNames = []
    flattenator = (groupArray) ->
      _.map groupArray, (g) ->
        if g.shortName then shortNames.push { id: g.id, name: g.name, shortName: g.shortName }
        flattenator g.children
    flattenator organization.children

    # Setup arrays to hold shortNames
    organization.registration.linkedSections ?= []
    $scope.linked = organization.registration.linkedSections

    # Generate a list of sections containing registerable sessions
    $scope.sectionChoices = _.map shortNames, (s) ->
      return { label: s.name, value: s.shortName }

    # Basic create/delete/save logic for linkedSections
    $scope.addLink = ->
      $scope.linked.length += 1

    $scope.removeLink = (i) ->
      $scope.linked.splice i, 1

    $scope.save = ->
      organization.save().then (err) ->
        flash "Registration Wizard Settings Saved."

    $scope.setFeeDefaults = ->
      if $scope.ppfFlag and $scope.feePassedOn
        cardFlat = $scope.group.properties.processingFees?.card.flat
        cardVariable = $scope.group.properties.processingFees?.card.variable
        bankFlat = $scope.group.properties.processingFees?.bank.flat
        bankVariable = $scope.group.properties.processingFees?.bank.variable

        if !cardFlat and cardFlat isnt 0
          $scope.group.properties.processingFees.card.flat = 30
        if !cardVariable
          $scope.group.properties.processingFees.card.variable = "3.95"
        if !bankFlat and bankFlat isnt 0
          $scope.group.properties.processingFees.bank.flat = 50
        if !bankVariable
          $scope.group.properties.processingFees.bank.variable = "1.5"

    $scope.saveGroup = ->
      $scope.setFeeDefaults()
      $scope.group.save(['properties', 'registration']).then(->
        flash 'Group settings have been saved'
      )

    $scope.saveSettings = ->
      if ($scope.ppfFlag)
        url = "/api/organization/#{organization.id}/finance/org-fee-settings"
        body =
          feePassedOn: $scope.feePassedOn
          cardVariableRate: parseFloat($scope.cardVariableConvenienceFee)
          cardFlatFee: $scope.cardFlatFeeConvenienceFee
          bankVariableRate: parseFloat($scope.bankVariableConvenienceFee)
          bankFlatFee: $scope.bankFlatConvenienceFee

        $http.put(url, body).then((response) ->
          orgFeeSettings = response?.data
          $scope.saveGroup()
        ).catch((error) ->
          window.swal(
            {
              type: "error"
              title: "Unexpected Error"
              text: "An error occurred while saving.\n\n" + error.data + "\n\n Please try again."
              allowOutsideClick: true
              confirmButtonText: "Close"
            }
          )
        )
      else $scope.saveGroup()
