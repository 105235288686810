angular.module("dn").directive("truncate", function($timeout) {
  return {
    restrict: "A",
    priority: 100,
    scope:  {
      truncate: "=",
      expand: "="
    },
    link: function(scope, elm, attrs) {
      // If the element's height is less than that of it's inner content, add
      // the .truncate class (see styles/_truncate.scss) and set model to true.
      // Otherwise, set the model to false
      $timeout(function() {
        if (elm[0].offsetHeight < elm[0].scrollHeight) {
          elm.addClass('truncate');
          scope.truncate = true;
        }
        else {
          scope.truncate = false;
        }
        // Watch for changes and conditionally add the .expanded class
        scope.$watch('expand', (fresh, stale) => {
          if (fresh === true) {
            elm.addClass('expanded');
          } else {
            elm.removeClass('expanded');
            // If we remove expanded, make sure we scroll back to the top so we
            // don't get stuck halfway down when the content collapses.
            elm[0].scrollTop = 0;
          }
        })
      });
    }
  };
});



// This directive conditionally applies the .truncate class to any element if
// its children's height is greater than its own.
//
// Things to know:
//   - To properly use this directive, set a max-height on the target element
//   - The .extended class uses a max-height of 100rem to extend (via CSS
//     transition) .truncate'd elements to full height
//   - The .extended class also applies `overflow-y: auto`
//
// You can override default behavior for the for specific uses of .truncate by
// overwriting .truncate's property in the state or directive's CSS. The same
// goes for .extended
//
//
// To use this directive...
//
//
// FIRST: Create a config object within the controller.
//
// For each .truncate'd element, you should should have something like this:
//
//   $scope.truncation = {
//     truncatedOne: {
//       truncated: false,
//       expanded: false
//     },
//     truncatedTwo : {
//       truncated: false,
//       expanded, false
//     }
//   };
//
//   You can create them programatically by doing something like this:
//
//   $scope.groups = [an array of groups that will be ng-repeated];
//   const groupIDs = $scope.groups.map(g => g.id);
//   $scope.truncation = {};
//   // Populate truncation config
//   groupIDs.map(gid => {
//     $scope.truncation[gid] = {
//       truncated: false,
//       expanded, false
//     }
//   })
//
//
// SECOND: ng-show to conditionally add a `Show More` button
//
// This is the HTML context in which I imagined this directive would be used
//
// As a one-off:
//
//  <div instruction-text="bullhorn"
//       class="some-class">
//    <div class="md-wrapper"
//         truncate="truncation.truncatedOne.truncated"
//         expand="truncation.truncatedOne.expanded"
//         ng-bind-html="theMarkdownText | md">
//    </div>
//    <div class="action-row" ng-show="truncation.truncatedOne.truncated">
//      <a href="" aria-hidden="true"
//         class="btn btn-primary"
//         ng-click="truncation.truncatedOne.expanded = !truncation.truncatedOne.expanded">
//        Show {{truncation.truncatedOne.expanded ? 'Less' : 'More'}}
//      </a>
//    </div>
//  </div>
//
// Or, with ng-repeat:
//
//  <div ng-repeat="group in groups track by group.id"
//       class="some-class">
//    <h3>{{group.name}}</h3>
//    <div class="group-description"
//         truncate="truncation.truncatedOne.truncated"
//         expand="truncation.truncatedOne.expanded"
//         ng-bind-html="group.description | md">
//    </div>
//    <div class="action-row" ng-show="truncation.[group.id].truncated">
//      <a href="" aria-hidden="true"
//         class="btn btn-primary"
//         ng-click="truncation.[group.id].expanded = !truncation.[group.id].expanded">
//        Show {{truncation.[group.id].expanded ? 'Less' : 'More'}}
//      </a>
//    </div>
//  </div>
