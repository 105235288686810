lib.registerState "manager.profiles.profile.log",
  url: "/log"
  templateUrl: "states/manager/profiles/profile/log/profile-log.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "Health Log"
    profile: (profile, $http) ->
      $http.get("/api/profiles/#{profile.id}/log-entries")
        .then((result) ->
          profile.logEntries = result.data
          profile
        )
        .catch(() ->
          profile.logEntries = []
          profile
        )
    providers: ($http, organization, profile) ->

      if !profile.logEntries?.length then return {}

      providerIDs = {}

      # Load every provider from the profile's log entries, even if they're not currently
      # a provider. Make a collection call for just those specific ones after we've fetched them
      # from the log entries.
      _.map profile.logEntries, (l) -> if l.providerID then providerIDs[l.providerID] = true
      providerIDs = _.filter _.keys(providerIDs), (p) -> !_.isNaN(parseInt(p))
      url = "/api/organizations/#{organization.id}/profiles"
      url += "?profileIDs=#{providerIDs.join("|")}"

      # Object with providers mapped by id
      $http.get(url, {cache: true}).then (result) -> _.keyBy result.data, "id"

  controller: ($scope, $state, profile, LogEntry, providers, provider) ->
    $scope.profile = profile

    # Sort by either logged, if exists, or created, by most recent to least
    $scope.profile.logEntries = (_.sortBy $scope.profile.logEntries, (l) ->
      l.logged or l.created
    ).reverse()

    # Attach providers to it to display their name
    _.map $scope.profile.logEntries, (entry) -> entry.provider = providers[entry.providerID] or {}

    # Save an entry and go to the "create" page, we want to create one here since the name needs
    # to be pre-filled.
    $scope.newEntry = ->
      $scope.creating = "Creating Entry..."
      new LogEntry(
        patientID: $scope.profile.id
        providerID: if provider then provider.id else null
        draft: true
        groupID: $scope.organization.id
      ).save().then (entry) ->
        $state.go "manager.healthLog.create", { patientID: $scope.profile.id, entryID: entry.id, linkedVisit: true }
