angular.module('dn').directive('attendanceFeedItem', function () {
  return {
    templateUrl: 'directives/attendance/attendance-feed-item/attendance-feed-item.directive.html',
    restrict: 'E',
    controller($rootScope, $scope) {
      // NOTE: This directive depends on parent $scope. It does _not_ have isolate scope. If you
      // intend to use it outside the <attendance-feed> (and you certainly can), make sure that
      // $scope.event exists on the parent.
      $scope.formatDate = function(time) {
        return moment.tz(time, _.get($rootScope, 'organization.properties.timezone') || 'UTC').format('MMMM Do YYYY, h:mm A');
      };
    }
  };
});
