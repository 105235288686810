angular.module('dn').directive('updateReviews', function() {
  return {
    templateUrl: 'directives/update-reviews/update-reviews.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      reviews: '=',
      user: '=',
      back: '=',
      fullTextSearchEnabled: '='
    },

    controller($http, $scope) {
      $scope.updateMode = 'all';
      $scope.modeChoices = [
        { label: 'All', value: 'all' },
        { label: 'By Review Date', value: 'byDate' },
      ];

      $scope.reviewChoices = _.map(['No', 'Pending', 'Yes'], (v) => { return { label: v, value: v }; });
      $scope.dateChoices = [
        { label: 'before', value: '<' },
        { label: 'before or on', value: '<=' },
        { label: 'on', value: '=' },
        { label: 'after or on', value: '>=' },
        { label: 'after', value: '>' }
      ];

      $scope.resetDateInputs = () => {
        $scope.dateChoice = '<=';
        $scope.dateRestrict = new Date().toISOString().slice(0, 10);
      };

      $scope.resetDateInputs();

      $scope.updateReviews = function(reviewTypeID, reviewTypeGroupID) {
        let selectedProfiles = [];
        if (_.some($scope.profiles, p => p.selected)) {
          selectedProfiles = _.filter($scope.profiles, p => p.selected);
        } else {
          selectedProfiles = _.map($scope.profiles);
        }

        const profileIDs = _(selectedProfiles).filter((p) => {
          if (p.profiles.organizationID === reviewTypeGroupID) {
            return true;
          } else {
            return _.some(p.profiles.registrations, r => _.includes(r.groupParents, reviewTypeGroupID));
          }
        }).map('profiles.id').value();

        const profilesLength = profileIDs.length;
        const selectedLength = selectedProfiles.length;
        let diffText = '';
        let dateText = '';

        if (!profilesLength) {
          window.swal({
            type: 'warning',
            title: 'No Relevant Profiles Selected',
            text: 'The review type chosen does not apply to any of the currently selected profiles.'
          });
          return;
        } else if (profilesLength < selectedLength) {
          const difference = selectedLength - profilesLength;
          diffText = ` ${difference} selected profile(s) will not receive the review, as they're not registered to the appropriate session(s).`;
        }

        if (!$scope.dateChoice || !$scope.dateRestrict) {
          $scope.resetDateInputs();
        }

        if ($scope.updateMode === 'byDate') {
          const criteria = $scope.dateChoices.find((dc) => dc.value === $scope.dateChoice);
          const formattedDate = moment($scope.dateRestrict).format('MMM DD, YYYY');
          dateText = ` updated ${criteria.label} ${formattedDate}`;
        } else {
          dateText = '';
        }

        const profileNoun = profilesLength === 1 ? 'profile' : 'profiles';

        window.swal({
          type: 'warning',
          title: 'Update Reviews',
          text: `This action will update the reviews${dateText} for ${profilesLength} ${profileNoun}.${diffText} This cannot be undone. Do you want to continue?`,
          showCancelButton: true
        }, (confirmed) => {
          if (confirmed) {
            $scope.updating = true;
            const body = {
              'id': reviewTypeID,
              'updateMode': $scope.updateMode,
              'status': $scope.reviews[reviewTypeID],
              'profiles': profileIDs,
              'userID': $scope.user ? $scope.user.id : null,
              'dateRestrict': `${$scope.dateChoice}'${$scope.dateRestrict}'`,
            };
            $http.put(`/api/organizations/${$scope.organization.id}/reviews/${reviewTypeID}`, body).then((result) => {
              const updateCount = result.data.updatedReviewsCount;
              const reviewNoun = updateCount === 1 ? 'review' : 'reviews';
              flash(`${updateCount} ${reviewNoun} updated.`);
              $scope.updating = false;
            });
          }
        });
      };
    }
  };
});
