angular.module("dn").directive "qtAllergy", ->
  restrict: "A"
  templateUrl: "directives/taker/modules/allergies/qt-allergy.directive.html"
  scope:
    allergies: "=qtAllergy"
    newAllergy: "="
    _status: "=status"
    addAllergy: "=add"
    allergyNames: "="
    cancel: "="
    profile: "="
    question: "="
    saveAllergy: "=save"
    type: "@"
  controller: ($scope, $stateParams, HealthForm, Constants, WhatDivision) ->
    profileID = $stateParams.profileID
    healthForm = HealthForm.get profileID

    $scope.division = WhatDivision
    $scope.form = {}

    $scope.backup = $scope.$parent.backup
    $scope.cancelEdit = $scope.$parent.cancelEdit
    $scope.removeAllergy = $scope.$parent.removeAllergy

    $scope.upper = (str) ->
      str.charAt(0).toUpperCase() + str.slice(1)

    $scope.anaChoices = [
      { label: "Yes", value: true }
      { label: "No", value: false }
    ]

    $scope.icons =
      "food": "utensils"
      "drug": "medkit"
      "environmental": "tree"

    $scope.reactions = [
      "Bleeding", "Blood Abnormalities", "Constipation",
      "Cough", "Diarrhea", "Dizziness", "Fever", "Headache",
      "Hives", "Hypotension", "Itching", "Liver Abnormalities",
      "Mental Status Change", "Myalgias", "Nausea / Vomiting",
      "Rash (Mild)", "Rash (Severe)", "Respiratory Difficulty", "Seizures",
      "Stomach Ache", "Swelling", "Wheezing"
    ]

    $scope.reactionChoices = _.map($scope.reactions, (reaction) ->
      return {
        label: reaction
        value: reaction
      }
    )

    $scope.readyToSave = (allergy) ->
      reqs = allergy.name and allergy.type and allergy.reaction
      return reqs and _.has(allergy, "anaphylaxis")


    $scope.saveExisting = (allergy) ->
      if !$stateParams.review
        healthForm.profile.reviewFlags ?= []
        healthForm.profile.reviewFlags.push($scope.question.id.toString())
      healthForm.save "allergies", profileID, allergy
      do $scope.backup
      $scope._status.editing = null

    $scope.changeAnaphylaxis = (allergy) ->
      # remove epiPen answer if anaphylaxis answer is changed to `no`
      return if allergy.anaphylaxis
      allergy.epiPen = false

    $scope.confirmDelete = (allergy) -> $scope._status.delete = allergy.id
    $scope.cancelRemove = -> $scope._status.delete = null
