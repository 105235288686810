'use strict';

lib.registerState('oper.organizations', {
  url: '/organizations',
  templateUrl: 'states/oper/organizations/organizations.state.html',
  resolve: {
    organizations: function($http) {
      return $http.get('/api/operator/organizations', { cache: true }).then(function(result) {
        return result.data;
      });
    },
    accountManagers: function($http) {
      return $http.get('/api/account-managers', { cache: true }).then(function(result) {
        return result.data;
      });
    }
  },
  controller: function($scope, $state, accountManagers, organizations, $http) {
    $scope.organizations      = organizations;
    $scope.accountManagers    = accountManagers;
    $scope.initialRoleChoices = ['Admin', 'Billing', 'Health Staff', 'Primary'].map((roleName) => {
      return {label: roleName, value: roleName};
    });
    $scope.divisionChoices = ['CampDoc', 'SchoolDoc', 'ChildCareDoc', 'DocNetwork'].map((divison) => {
      return { label: divison, value: divison };
    });

    function activeSeason (seasons) {
      return _(seasons)
        .reject('archived')
        .sortBy(seasons, function(s){
          return new Date(s.created);
        })
        .first();
    }

    _.map($scope.organizations, function(org) {
      org.manager = _.find(accountManagers, function(am) {
        return am.id.toString() === org.accountManager;
      });

      org.seasonStatus = function() {
        const season = activeSeason(org.seasons);
        if (!season) {
          return 'pending';
        } else if (season.status) {
          if (season.status.launched) {
            return 'authorized';
          } else if (season.status.confirmed) {
            return 'confirmed';
          } else if (season.status.reviewed) {
            return 'reviewed';
          } else if (season.status.submitted) {
            return 'submitted';
          }
        }
        return 'started';
      };
    });

    $scope.orgState = function() {
      return !!$state.current.name.match('organizations.organization');
    };

    $scope.orgNameTaken = function(attempt) {
      if (!attempt) return; // avoid error on page load

      return _.some($scope.organizations, function(org) {
        return org.name && org.name.toLowerCase() === attempt.toLowerCase();
      });
    };

    $scope.validOrg = function() {
      return $scope.newOrg.name
          && $scope.newOrg.properties.division
          && parseInt($scope.newOrg.properties.billing.rate) >= 0
          && $scope.newOrg.properties.billing.month
          && !($scope.orgNameTaken($scope.newOrg.name));
    };

    $scope.validUser = function() {
      return $scope.newOrg.firstUser
          && $scope.newOrg.firstUser.givenName
          && $scope.newOrg.firstUser.familyName
          && $scope.newOrg.firstUser.email
          && $scope.newOrg.firstUser.password
          && $scope.newOrg.firstUser.initialRoleChoice;
    };

    $scope.createOrg = function(org) {
      $scope.submitting = true;
      if ($scope.orgNameTaken($scope.newOrg.name)) {
        return swal('Duplicate organization name!', undefined, 'error');
      }

      return $http.post('/api/operator/new-organization', org).then(function(result) {
        $scope.submitting = null;
        return $state.go('.organization', { orgID: result.data.id });
      }).catch((err) => {
        $scope.submitting = null;
        swal({
          type: 'error',
          title: 'Error Creating Organization',
          text: `
            ${JSON.stringify(err.data) || 'There was an error creating this organization. Please contact the dev team for assistance.'}

            Depending on where the error occurred the organization may have been created.
            Please search for the organization before attempting to re-create it, and contact the dev team to investigate the server logs.
          `.replace(/^([ \t]){3,}/gm, '')
        });
      });
    };
  }
});
