angular.module('dn').directive('cancellationRequestActions', function () {
  return {
    templateUrl: 'directives/cancellation-request-actions/cancellation-request-actions.directive.html',
    restrict: 'E',
    scope: {
      deactivating: "=",
      onDeactivate: "=",
      onDeny: "=",
      registration: '=',
      requesting: '=',
    },
    controller($filter, $rootScope, $scope, ProfileRegData) {

      try {

        // Set up the more static stuff
        $scope.uiState = {
          denialReason: null,
          denyingRequest: false,
          setupError: false,
          setupErrorMessage: `
            An error occurred while loading cancellation request information. Try again later.
            If the problem persists, please contact our support team with the
            Registration ID "${$scope.registrationID}".
          `.replace(/\s+/g, ' '),
        };

        // Set up the more stateful stuff
        $scope.hasPermission = $filter('permissionVisible')({ profile_registrations: 'edit' });
        const timezone = ($rootScope.organization.properties || {}).timezone || 'UTC';
        $scope.profile = ProfileRegData.profile;
        $scope.requestedOn = moment
          .tz($scope.registration.properties.cancellationRequested, timezone)
          .format("MMMM Do, YYYY [at] h:mma");

      } catch (error) {

        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          $scope.setupError = true;
          console.error(error);
        } else {
          $scope.setupError = true;
        }

      }

    }
  };
});
