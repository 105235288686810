angular.module("dn").factory "FilterSets", (GroupBranding) ->

  # A filter object is composed of the following:
  # path -> the path to the condition evaluation
  # conditions: array of label/value conditions
  # allowMultiple: tags or single select for value
  # text: text input for values, each value.label will be placeholder

  # This should be available in any place that the filters are placed...

  create = (group, area="manager", filterPermissions) ->
    # custom optgroup values for relevant filters
    healthProfileAlias = GroupBranding.aliases.healthProfile
    prescreeningAlias = GroupBranding.aliases.prescreening

    # Condition operators mapped to choices
    c =
      any:          { label: "any", value: "any" }
      between:      { label: "between", value: "between" }
      greaterThan:  { label: "greater than", value: ">" }
      in:           { label: "contains", value: "in" }
      is:           { label: "is", value: "is" }
      isnt:         { label: "isn't", value: "isnt" }
      lessThan:     { label: "less than", value: "<" }
      matches:      { label: "matches", value: "matches" }
      trimMatch:    { label: 'answered', value: 'trimmatch' }

    # Potential app areas using the Filter Builder
    # aliases are used to map filters to their respective areas
    manager = "manager"
    healthLog = "healthLog"
    funkNotes = "funkNotes"
    attendance = "attendance"
    prescreening = "prescreening"

    # at the time of writing, most filters are allowed in these states
    # chiefly the `manager` area filters are usable in all but the healthLog
    # consider rethinking this variable and its use if we add more areas
    # that run the risk of using different combos
    standardFilterAreas = [manager, funkNotes, attendance, prescreening]


    noteCategories = group.properties?.noteCategories
    noteCategories ?= [
      "Allergy", "Emotional/Behavioral", "Financial", "General", "Illness",
      "Injury", "Medication", "Phone Call", "Other"
    ]

    reviewTypes = _.filter group.reviewTypes, (r) -> !r.deactivated

    filterTypes = []

    ###
    #   Generate question filters to filter by answer to certain types of questions
    ###

    # Set default permissions for restricted filters if they haven't been set yet
    filterPermissions = _.defaults filterPermissions, { permissions: false, financial: false, questions: false }

    if filterPermissions.questions
      filterableQuestions = (questions, array, step, form) ->
        return unless questions?.length
        _.map questions, (q) ->
          if q.type is "step" then step = "Questions - #{q.label}"
          if q.type is "form" then form = q.label
          if q.type in ["select", "boolean", "authorization", "text", "multi-auth"]
            q.step = step
            q.form = form

            # Append form name to differentiate between (e.g. parent 1, parent 2)
            if q.form and q.step and (q.form isnt q.step)
              q.label = "#{q.form} - #{q.label}"
            array.push q
          filterableQuestions q.subQuestions, array, step, form
        return array

      questions = filterableQuestions group.newQuestionnaire?.questions, [], null, null

      standardQuestionTypes = ["boolean", "select", "authorization", "text"]

      questionConditions =
        boolean: [c.is]
        select: [c.trimMatch , c.matches]
        authorization: [{ label: "is", value: "matches" }]
        text: [c.matches]

      questionValues = (q) ->
        switch q.type
          when "boolean"
            return [{ label: "Yes", value: "yes"}, { label: "No", value: "no" }]
          when "select"
            return _.map q.body.split(","), (b) -> { label: b.replace(/\n/g, '').trim(), value: b.replace(/\n/g, '').trim() }
          when "authorization"
            return [
              { label: "Accepted", value: "accepted" }
              { label: "Declined", value: "declined" }
            ]
          else
            return null

      _.each questions, (q) ->
        if q.type in standardQuestionTypes
          filterTypes.push {
            areas: [manager]
            path: { label: q.label, value: "answerMap.#{q.id}", optgroup: q.step }
            conditions: questionConditions[q.type]
            values: questionValues(q)
            text: q.type is "text"
          }
        else if q.type is "multi-auth"
          q.metadata.sections.map((section, index) ->
            filterTypes.push
              areas: [manager]
              path: { label: section.label, value: "multi-auth.#{q.id}:#{index}", optgroup: "#{q.step}" }
              conditions: [{ label: "is", value: "matches" }]
              values: [
                { label: "Accepted", value: "accepted" }
                { label: "Declined", value: "declined" }
              ]
          )

    ###
    #   Demographics
    ###

    # Filter by phase
    filterTypes.push
      path: { label: "Profile Status", value: "phase", optgroup: "Demographics" }
      conditions: [c.is, c.isnt]
      values: [ { label: "Future", value: "future" }, { label: "Present", value: "present" }, { label: "Past", value: "past" } ]
      areas: standardFilterAreas

    # Demographic Change
    filterTypes.push
      path: { label: "Demographic Change Request", value: "properties.changeRequested", optgroup: "Demographics" }
      conditions: [{ label: "has been", value: "is" }]
      values: [ { label: " Requested", value: "true" } ]
      areas: standardFilterAreas

    # Demographic Change
    filterTypes.push
      path: { label: "Duplicate Merge Request", value: "properties.mergeRequested", optgroup: "Demographics" }
      conditions: [ { label: "has been", value: "notnull" }, { label: "has NOT been", value: "null" } ]
      values: [ { label: " Requested", value: "true" } ]
      areas: standardFilterAreas

    # Sex
    filterTypes.push
      path: { label: "Sex", value: "sex", optgroup: "Demographics" }
      conditions: [c.is]
      values: [ { label: "Male", value: "Male" }, { label: "Female", value: "Female" } ]
      areas: standardFilterAreas

    filterTypes.push
      path: { label: "Date of Birth", value: "dob", optgroup: "Demographics" }
      conditions: [
        { label: "before", value: "<" }
        { label: "before or on", value: "<=" }
        { label: "on", value: "is" }
        { label: "after", value: ">" }
        { label: "after or on", value: ">="}
      ]
      date: true
      areas: standardFilterAreas

    # Age
    filterTypes.push
      path: { label: "Age", value: "age", optgroup: "Demographics" }
      conditions: [c.greaterThan, c.is, c.isnt, c.lessThan]
      values: _.map _.range(0, 100), (v) -> { label: v, value: v }
      areas: standardFilterAreas

    # Avatar
    filterTypes.push
      path: { label: "Profile Picture", value: "avatar", optgroup: "Demographics" }
      conditions: [ { label: "has been", value: "notnull" }, { label: "has NOT been", value: "null" } ]
      values: [ { label: "Uploaded", value: "true" } ]
      areas: standardFilterAreas

    ###
    #   OTC Medications
    ###
    if group.properties.enableOTC
      _(group.otcMeds)
        .sortBy((med) -> med.label.toLowerCase())
        .map((o) ->
          filterTypes.push
            path: { label: o.label, value: "otcFilter.#{o.id}", optgroup: "OTCs" }
            conditions: [c.is, c.isnt]
            values: [ { label: "Authorized", value: "true" }]
            areas: standardFilterAreas
        )
        .value()

    ###
    #   Users
    ###

    # Bounced emails
    filterTypes.push
      path: { label: "Bounced Emails", value: "[profilesUsers].inviteBounce", optgroup: "Users" }
      conditions: [{ label: "has a", value: "is" }]
      values: [{ label: "Bounced Email", value: "true"}]
      areas: standardFilterAreas

    # Email Address
    filterTypes.push
      path: { label: "Email Address", value: "emailAddress", optgroup: "Users" }
      conditions: [c.is]
      text: true
      areas: standardFilterAreas

    #   Dates for the Last Accessed filter, subtracted from today
    accessDates = [
      { label: "One Day", value: moment().subtract(1, "day").format("YYYY-MM-DD") }
      { label: "One Week", value: moment().subtract(7, "day").format("YYYY-MM-DD") }
      { label: "One Month", value: moment().subtract(1, "month").format("YYYY-MM-DD") }
      { label: "Three Months", value: moment().subtract(3, "month").format("YYYY-MM-DD") }
      { label: "Six Months", value: moment().subtract(6, "month").format("YYYY-MM-DD") }
      { label: "Year", value: moment().subtract(1, "year").format("YYYY-MM-DD") }
    ]

    # Last Accessed
    filterTypes.push
      path: { label: "Last Accessed", value: "[users].lastLogin", optgroup: "Users" }
      conditions: [
        { label: "in the last", value: ">" }
        { label: "NOT in the last", value: "<" }
      ]
      values: accessDates
      areas: standardFilterAreas

    # Users, No Users
    filterTypes.push
      path: { label: "Users", value: "[users].email", optgroup: "Users" }
      conditions: [{ label: "has", value: "is" }]
      values: [{ label: "1+ Users", value: "true" }, { label: "No Users", value: "false" }]
      areas: standardFilterAreas

    # SMS opted in/out
    if group.properties.smsEnabled
      filterTypes.push
        path: { label: "Text Message Alerts", value: "[profiles_exists].may_text", optgroup: "Users" }
        conditions: [{ label: "has", value: "is" }]
        values: [
          { label: "1+ Contacts Opted In", value: "true" }
          { label: "No Contacts Opted In", value: "false" }
        ]
        areas: standardFilterAreas
    ###
    #   Registrations
    ###

    #   Generate options for all Group Select filters
    allGroups = _.map group.$selectableChildren(), (child) -> { label: child.label, value: child.value.toString() }
    currentGroups = _.compact _.map group.$selectableChildren(), (child) ->
      if child.phase is "past" then return null
      return { label: child.label, value: child.value.toString() }

    # By Group
    filterTypes.push
      path: { label: "Group Select", value: "[registrations].groupParents", optgroup: "Registrations" }
      conditions: [{ label: "is", value: "any" }]
      values: currentGroups
      allowMultiple: true
      option:
        label: "Show Past Groups"
        values: allGroups
        selected: false
      areas: standardFilterAreas

    # Group Name
    filterTypes.push
      path: { label: "Group Name", value: "[registrations].group", optgroup: "Registrations" }
      conditions: [c.matches]
      text: true
      areas: standardFilterAreas

    # Group Start
    filterTypes.push
      path: { label: "Group Start", value: "[registrations].start", optgroup: "Registrations" }
      conditions: [
        { label: "before", value: "<" }
        { label: "before or on", value: "<=" }
        { label: "on", value: "is" }
        { label: "after", value: ">" }
        { label: "after or on", value: ">="}
        { label: "within the last", value: "relativedate<=", relative: true }
        { label: "in the next", value: "relativedate>=", relative: true }
      ]
      date: true
      areas: standardFilterAreas

    # Group Finish
    filterTypes.push
      path: { label: "Group Finish", value: "[registrations].finish", optgroup: "Registrations" }
      conditions: [
        { label: "before", value: "<" }
        { label: "before or on", value: "<=" }
        { label: "on", value: "is" }
        { label: "after", value: ">" }
        { label: "after or on", value: ">="}
        { label: "within the last", value: "relativedate<=", relative: true }
        { label: "in the next", value: "relativedate>=", relative: true }
      ]
      date: true
      areas: standardFilterAreas

    # Filter by if profile has patient/provider registrations
    filterTypes.push
      path: { label: "Registration Type", value: "[registrations].type", optgroup: "Registrations" }
      conditions: [c.is]
      values: [ { label: "#{group.division?.patient?.singular?.capitalize() || 'Patient'}", value: "patient" }, { label: "Provider", value: "provider" }, { label: "None", value: "false" } ]
      areas: standardFilterAreas

    # Registration Date
    filterTypes.push
      path: { label: "Registered Date", value: "[registrations].created", optgroup: "Registrations" }
      conditions: [
        { label: "before", value: "<" }
        { label: "before or on", value: "<=" }
        { label: "on", value: "matches" }
        { label: "after", value: ">" }
        { label: "after or on", value: ">="}
      ]
      date: true
      areas: standardFilterAreas

    # Registration Cancellation
    filterTypes.push
      path: { label: "Registration Cancellation", value: "[registrations].properties.cancellationRequested", optgroup: "Registrations" }
      conditions: [ { label: "has been", value: "notnull" } ]
      values: [ { label: "Requested", value: "true" } ]
      areas: standardFilterAreas

    # Waitlisted - Registration camps only
    if group.shortName then filterTypes.push
      path: { label: "Waitlisted Status", value: "[registrations].waitlisted", optgroup: "Registrations" }
      conditions: [c.is]
      values: [ { label: "Waitlisted", value: "true" }, { label: "Not Waitlisted", value: "false" } ]
      areas: standardFilterAreas


    ###
    #   Notes
    ###

    categoryValues = _.map noteCategories, (n) -> { label: n, value: n }
    notes =
      priority:
        path: { label: "Note Priority", value: "[notes].priority", optgroup: "Notes" }
        conditions: [c.is]
        values: [
          { label: "Low", value: "Low" }
          { label: "Normal", value: "Normal" }
          { label: "High", value: "High" }
        ]
        areas: standardFilterAreas
      category:
        path: { label: "Note Category", value: "[notes].category", optgroup: "Notes" }
        conditions: [c.is]
        values: categoryValues
        areas: standardFilterAreas
      body:
        path: { label: "Note Body", value: "[notes].body", optgroup: "Notes" }
        conditions: [c.matches]
        text: true
        areas: standardFilterAreas

    _.map notes, (n) -> filterTypes.push n

    ###
    #   Health Profile
    ###

    # Whether or not 100% complete
    filterTypes.push
      path: { label: "Completion Status", value: "healthFormComplete", optgroup: healthProfileAlias }
      conditions: [c.is]
      values: [ { label: "Complete", value: "true" }, { label: "Incomplete", value: "false" } ]
      areas: standardFilterAreas

    # Get questionnaire steps
    steps = _.map group.newQuestionnaire?.questions, (q) -> { label: q.label, value: q.id.toString() }

    # Step Completion Status
    filterTypes.push
      path: { label: "Completion Status (Steps)", value: "stepsComplete", optgroup: healthProfileAlias }
      conditions: [{ label: "has completed", value: "contains" }, { label: "has NOT completed", value: "!contains" }]
      values: steps
      allowMultiple: true
      areas: standardFilterAreas

    # Completion Percent
    filterTypes.push
      path: { label: "Completion Percent", value: "completeness", optgroup: healthProfileAlias }
      conditions: [c.greaterThan, c.is, c.isnt, c.lessThan]
      values: _.map _.range(0, 100), (v) -> { label: v, value: v }
      areas: standardFilterAreas

    filterTypes.push
      path: { label: "Date Completed", value: "completedOn", optgroup: healthProfileAlias }
      conditions: [
        { label: "before", value: "<" }
        { label: "before or on", value: "<=" }
        { label: "on", value: "is" }
        { label: "after", value: ">" }
        { label: "after or on", value: ">="}
      ]
      date: true
      areas: standardFilterAreas

    # Generate review status filters; 3 per type
    reviewValues = []
    _.map group.reviewTypes, (type) ->
      _.map ["Yes", "Pending", "No"], (status) ->
        if status is "No"
          reviewValues.push { label: "#{type.name} - #{status}", value: "#{type.id}^#{type.id}#{status}" }
        else
          reviewValues.push { label: "#{type.name} - #{status}", value: "#{type.id}#{status}" }

    # Review Status
    filterTypes.push
      path: { label: "Review Status", value: "[reviewStatus].reviews", optgroup: healthProfileAlias }
      conditions: [{ label: "is", value: "contains" }, { label: "isn't", value: "!contains" }]
      values: reviewValues
      areas: standardFilterAreas

    # Unlock Request
    filterTypes.push
      path: { label: "Unlock Request", value: "properties.extensionRequested", optgroup: healthProfileAlias }
      conditions: [ { label: "has been", value: "is" } ]
      values: [ { label: "requested", value: "true" } ]
      areas: standardFilterAreas

    ###
    # Tags
    ###
    group.tags = _.filter group.tags, (t) ->
      return true if t.deactivated is null

    parentOrFreeTags = _.filter group.tags, (t) -> t.parentID is null
    parentMap = {}
    _.each parentOrFreeTags, (t) ->
      parentMap[t.id] = t
    # Adds a 'dummy' entry to kick off the All Tags filter creation
    parentOrFreeTags.push({ value: 'Any Tags', id: 'alltags' })

    _.each parentOrFreeTags, (t) ->
      if (t.value is 'Any Tags')
        # Sort group tags by parent name
        childTags = group.tags.filter((tag) -> tag.parentID && parentMap[tag.parentID])
        sortedGroupTags =
          _.sortBy(childTags,[(o) -> parentMap[o.parentID].value])
        filter =
          path: { label: t.value, value: "[alltags].alltags", optgroup: "Tags" },
          conditions: [{ label: "include", value: "is" }],
          allowMultiple: true,
          values: _(sortedGroupTags)
                    .map((mt) ->
                      # Find the value of the parent tag in order to dynamically generate the label
                      # for the All Tag entry
                      parentLabel = parentMap[mt.parentID].value
                      return { label: "#{parentLabel} #{mt.value}", value: "#{mt.parentID}@#{mt.id.toString()}" }
                    )
                    .value()
          areas: standardFilterAreas
        filterTypes.push filter
      else if (_.some(group.tags, (gt) -> gt.parentID is t.id))
        filter =
          path: { label: t.value, value: "[tags].#{t.id}", optgroup: "Tags" }
          conditions: [c.is]
          allowMultiple: true
          values: _(group.tags)
                    .filter((gt) -> gt.parentID is t.id)
                    .sortBy('id')
                    .map((mt) -> { label: mt.value, value: mt.id.toString() })
                    .value()
          areas: standardFilterAreas
        filterTypes.push filter
      else
        filter =
          path: { label: t.value, value: "[tags].#{t.id}", optgroup: "Tags" }
          conditions: [c.matches]
          text: true
          areas: standardFilterAreas
        filterTypes.push filter

    ###
    #   Roles
    ###
    if filterPermissions.permissions
      group.roles = _.filter group.roles, (r) ->
        return r.deactivated is null

      filterTypes.push
        path: { label: "Role Name", value: "[roles].id", optgroup: "Permissions" }
        conditions: [c.is]
        values: _.map(group.roles, (r) -> { label: r.name, value: r.id.toString() })
        areas: standardFilterAreas

    ###
    #   Allergies
    ###

    # Has Allergies
    filterTypes.push
      path: { label: "Allergies", value: "[allergies].type", optgroup: "Allergies" }
      conditions: [{ label: "has", value: "in" }]
      values: [
        { label: "Food Allergy", value: "food" }
        { label: "Drug Allergy", value: "drug" }
        { label: "Environmental Allergy", value: "environmental" }
        { label: "Any Allergy", value: "food_drug_environmental" }
      ]
      areas: standardFilterAreas

    # Epi-Pen
    filterTypes.push
      path: { label: "Epi-Pen", value: "[allergies].epiPen", optgroup: "Allergies" }
      conditions: [{ label: "bringing", value: "is" }]
      values: [ { label: "Yes", value: "true" }, { label: "No", value: "false" } ]
      areas: standardFilterAreas

    # Anaphylaxis
    filterTypes.push
      path: { label: "Anaphylaxis Risk", value: "[allergies].anaphylaxis", optgroup: "Allergies" }
      conditions: [c.is]
      values: [ { label: "Yes", value: "true" }, { label: "No", value: "false" } ]
      areas: standardFilterAreas


    ###
    #   Medications Filters
    ###

    filterTypes.push
      path: { label: "Medications", value: "has.medications", optgroup: "Medications" }
      conditions: [{ label: "has", value: "is" }]
      values: [ { label: "Medications", value: "true" } ]
      areas: standardFilterAreas

    filterTypes.push
      path: { label: "Medications (As Needed)", value: "[medications].timesTaken.As Needed", optgroup: "Medications" }
      conditions: [{ label: "has", value: "is" }]
      values: [ { label: "as needed medication", value: "true" } ]
      areas: standardFilterAreas

    filterTypes.push
      path: { label: "Taking At #{group.division?.place?.singular?.capitalize() || 'Camp'}", value: "[medications].willTake", optgroup: "Medications" }
      conditions: [{ label: "is", value: "is" } , { label: "isn't", value: "isnt" }]
      values: [ { label: "taking at #{group.division?.place?.singular || 'Camp'}", value: "true" } ]
      areas: standardFilterAreas

    # Medication times of day
    filterTypes.push
      path: { label: "Times Taken", value: "[medications].timesTaken", optgroup: "Medications" }
      conditions: [{ label: "taken", value: "is" }, { label: "not taken", value: "isnt" }]
      values: [
        { label: "Overnight", value: "Overnight" }
        { label: "Early Morning", value: "Early Morning" }
        { label: "Breakfast", value: "Breakfast" }
        { label: "Late Morning", value: "Late Morning" }
        { label: "Lunch", value: "Lunch" }
        { label: "Afternoon", value: "Afternoon" }
        { label: "Dinner", value: "Dinner" }
        { label: "Evening", value: "Evening" }
        { label: "Bedtime", value: "Bedtime" }
      ]
      areas: standardFilterAreas

    # Medication days of the week
    filterTypes.push
      path: { label: "Days Taken", value: "[medications].daysTaken", optgroup: "Medications" }
      conditions: [{ label: "taken", value: "is" }, { label: "not taken", value: "isnt" }]
      values: [
        { label: "Monday", value: "Mon" }
        { label: "Tuesday", value: "Tue" }
        { label: "Wednesday", value: "Wed" }
        { label: "Thursday", value: "Thu" }
        { label: "Friday", value: "Fri" }
        { label: "Saturday", value: "Sat" }
        { label: "Sunday", value: "Sun" }
      ]
      areas: standardFilterAreas

    ###
    #   Health Log Filters
    ###

    filterTypes.push
      path: { label: "Draft Status", value: "[logEntries].draft", optgroup: "General" }
      conditions: [c.is, c.isnt]
      values: [{ label: "Draft", value: "true" }]
      areas: [healthLog]

    # Blood glucose
    filterTypes.push
      path: { label: "Blood Glucose", value: "[logEntries].bloodGlucose", optgroup: "Vitals" }
      conditions: [c.is, c.greaterThan, c.lessThan]
      values: _.map(_.range(0, 1000, 5), (v) -> { label: v, value: v })
      areas: [healthLog]

    # complaint
    # Assumes group.complaints is a { label, value } array
    filterTypes.push
      path: { label: "Chief Complaint", value: "[logEntries].complaint", optgroup: "Subjective" }
      conditions: [{ label: "is", value: "any" }]
      values: _.map group.complaints, (l) -> { label: l, value: "\"#{l}\"" }
      areas: [healthLog]
      allowMultiple: true

    # current
    filterTypes.push
      path: { label: "Profile Status", value: "phase", optgroup: "General" }
      conditions: [c.is, c.isnt]
      values: [ { label: "Future", value: "future" }, { label: "Present", value: "present" }, { label: "Past", value: "past" } ]
      areas: [healthLog]

    # By Group
    # depends on currentGroups and allGroups created for the list-builder Group Select
    filterTypes.push
      path: { label: "Group Select", value: "[registrations].groupParents", optgroup: "General" }
      conditions: [{ label: "is", value: "any" }]
      values: currentGroups
      allowMultiple: true
      option:
        label: "Show Past Groups"
        values: allGroups
        selected: false
      areas: [healthLog]

    # heart rate
    filterTypes.push
      path: { label: "Heart Rate", value: "[logEntries].heartRate", optgroup: "Vitals" }
      conditions: [c.is, c.greaterThan, c.lessThan]
      values: _.map(_.range(0, 400, 5), (v) -> { label: v, value: v })
      areas: [healthLog]

    # location
    filterTypes.push
      path: { label: "Location", value: "[logEntries].location", optgroup: "Subjective" }
      conditions: [c.is]
      values: _.map group.properties?.healthLogLocations, (l) -> { label: l, value: l }
      areas: [healthLog]

    # Attachments
    filterTypes.push
      path: { label: "Attachments", value: "[logEntries].file", optgroup: "General" }
      conditions: [{ label: "has", value: "isnt" }]
      values: [{ label: "an attachment", value: "null" }]
      areas: [healthLog]

    # med diag
    filterTypes.push
      path: { label: "Medical Diagnosis", value: "[logEntries].diagnosis", optgroup: "Diagnosis" }
      conditions: [c.is]
      ajax: "/api/constants/medical-diagnosis/search"
      values: []
      areas: [healthLog]

    # nurse diag
    filterTypes.push
      path: { label: "Nursing Diagnosis", value: "[logEntries].nursingDiagnosis", optgroup: "Diagnosis" }
      conditions: [c.is]
      values: _.map group.nursingDiagnoses, (l) -> { label: l.name, value: l.name }
      areas: [healthLog]

    # ox sat
    filterTypes.push
      path: { label: "Oxygen Saturation", value: "[logEntries].oxygenSaturation", optgroup: "Vitals" }
      conditions: [c.lessThan, c.greaterThan, c.is]
      values: _.map(_.range(0, 101, 5), (v) -> { label: "#{v}%", value: v }).reverse()
      areas: [healthLog]

    # pain
    filterTypes.push
      path: { label: "Pain", value: "[logEntries].pain", optgroup: "Vitals" }
      conditions: [c.lessThan, c.greaterThan, c.is]
      values: _.map _.range(11), (v) -> { label: v, value: v }
      areas: [healthLog]

    # provider
    filterTypes.push
      path: { label: "Provider", value: "[logEntries].providerID", optgroup: "General" }
      conditions: [c.is]
      values: _.map group.providers, (p) -> { label: "#{p.givenName} #{p.familyName}", value: p.id }
      areas: [healthLog]

    # resp rate
    filterTypes.push
      path: { label: "Respiratory Rate", value: "[logEntries].respiratoryRate", optgroup: "Vitals" }
      conditions: [c.is, c.lessThan, c.greaterThan]
      values: _.map(_.range(0, 201, 5), (v) -> { label: v, value: v })
      areas: [healthLog]

    # As part of the Health Log Optimization (AP-1275), we're deprecating temperature filters.
    # There is a task open for adding support (AP-1596). The below setup returns incorrect results
    # as is doesn't take temperature unit into account at all, and there is no logic for conversion.
    #
    # temp
    # filterTypes.push
    #   path: { label: "Temperature", value: "[logEntries].temperature", optgroup: "Vitals" }
    #   conditions: [c.between]
    #   text: true

    filterTypes.push
      path: { label: "Addendums", value: "[logEntries].addendum", optgroup: "General" }
      conditions: [{ label: "has", value: "isnt" }]
      values: [{ label: "An Addendum", value: "[]"}]
      areas: [healthLog]

    filterTypes.push
      path: { label: "Reviewed", value: "entriesReviewed", optgroup: "General" }
      conditions: [c.is, c.isnt]
      values: [{ label: "reviewed", value: "true"}]
      areas: [healthLog]

    filterTypes.push
      path: { label: "Blood Pressure (Systolic)", value: "[logEntries].systolic", optgroup: "Vitals" }
      conditions: [c.is, c.greaterThan, c.lessThan]
      values: _.map _.range(0, 250), (v) -> { label: v, value: v }
      areas: [healthLog]

    filterTypes.push
      path: { label: "Blood Pressure (Diastolic)", value: "[logEntries].diastolic", optgroup: "Vitals" }
      conditions: [c.is, c.greaterThan, c.lessThan]
      values: _.map _.range(0, 250), (v) -> { label: v, value: v }
      areas: [healthLog]

    today = moment().format "YYYY-MM-DD"
    followUpDates = [
      { label: "One Day",       value: "#{today}, #{moment().add(1, "day"  ).format("YYYY-MM-DD")}" }
      { label: "One Week",      value: "#{today}, #{moment().add(7, "day"  ).format("YYYY-MM-DD")}" }
      { label: "One Month",     value: "#{today}, #{moment().add(1, "month").format("YYYY-MM-DD")}" }
      { label: "Three Months",  value: "#{today}, #{moment().add(3, "month").format("YYYY-MM-DD")}" }
      { label: "Six Months",    value: "#{today}, #{moment().add(6, "month").format("YYYY-MM-DD")}" }
      { label: "Year",          value: "#{today}, #{moment().add(1, "year" ).format("YYYY-MM-DD")}" }
    ]

    filterTypes.push
      path: { label: "Follow-Up", value: "[logEntries].followUp", optgroup: "General" }
      conditions: [{ label: "in the next", value: "between" }]
      values: followUpDates
      areas: [healthLog]

    # Disposition filter
    dispositions = if group.properties?.customDispositions?.length then group.properties.customDispositions else [
      "Admitted"
      "Ambulance"
      "Discharged"
      "Emergency Department"
      "Home"
      "Observed"
      "Urgent Care"
    ]
    filterTypes.push
      path: { label: "Disposition", value: "[logEntries].disposition", optgroup: "General" }
      conditions: [c.is]
      values: _.map dispositions, (d) -> { label: d, value: d }
      areas: [healthLog]

    # Linked
    filterTypes.push
      path: { label: "Linked Visit", value: "[logEntries].parentID", optgroup: "General" }
      conditions: [{ label: "is", value: "isnt" }]
      values: [{ label: "linked to another visit", value: "null" }]
      areas: [healthLog]

    # HPI
    filterTypes.push
      path: { label: "History of Present Illness", value: "[logEntries].details", optgroup: "Subjective" }
      conditions: [c.matches]
      text: true
      areas: [healthLog]

    # Assessment
    filterTypes.push
      path: { label: "Assessment", value: "[logEntries].assessment", optgroup: "Assessment/Plan" }
      conditions: [c.matches]
      text: true
      areas: [healthLog]

    # Plan
    filterTypes.push
      path: { label: "Plan", value: "[logEntries].treatment", optgroup: "Assessment/Plan" }
      conditions: [c.matches]
      text: true
      areas: [healthLog]

    # Date
    filterTypes.push
      path: { label: "Date", value: "[logEntries].logged", optgroup: "General" }
      conditions: [
        { label: "before", value: "date<" }
        { label: "before or on", value: "date<=" }
        { label: "after", value: "date>" }
        { label: "after or on", value: "date>="}
      ]
      date: true
      areas: [healthLog]

    # Filters we want to show only for registration camps
    if group.shortName
      if filterPermissions.financial
        ###
        # Financial filters
        ###
        # balances
        filterTypes.push
          path: { label: "Balance", value: "balance.amount", optgroup: "Financial" }
          conditions: [{ label: "is", value: "is" }, { label: "greater than", value: ">"}, { label: "less than", value: "<" }]
          number: true
          areas: standardFilterAreas

        # Payment Plans
        filterTypes.push
          path: { label: "Payment Plans", value: "[paymentPlans].remaining", optgroup: "Financial" }
          conditions: [ { label: "has", value: "is" } ]
          values: [{ label: "Active Plan", value: "true" }, { label: "No Plan", value: "false" }]
          areas: standardFilterAreas

        # Payment Methods
        filterTypes.push
          # it doesn't really matter what property we attach to [paymentMethods]
          # the query in collections will handle the existence of permitted methods
          path: { label: "Payment Method", value: "[paymentMethods].providerPermission", optgroup: "Financial" }
          conditions: [ { label: "has", value: "is" } ]
          values: [{ label: "Methods", value: "true" }, { label: "No Methods", value: "false" }]
          areas: standardFilterAreas

      #CONSIDER: not sure why I created these 2 filters like this, might not be necessary
      coupons =
        code:
          path: { label: "Coupon Code", value: "[coupons].code", optgroup: "Coupon" }
          conditions: [c.matches]
          text: true
          areas: standardFilterAreas

      addOns =
        name:
          path: { label: "Add-On Name", value: "[addOns].name", optgroup: "AddOn" }
          conditions: [{ label: 'contains', value: 'matches' }, { label: 'matches', value: 'trimmatch' }]
          text: true
          areas: standardFilterAreas

      _.map coupons, (c) -> filterTypes.push c
      _.map addOns, (a)  -> filterTypes.push a

    if group.properties?.features?.travelInsurance
      filterTypes.push
        path: { label: "Protection Plan", value: "[insurancePolicies].purchased", optgroup: "Protection Plan" }
        conditions: [c.is]
        values: [ { label: "purchased", value: "true" }, { label: "not purchased", value: "false" } ]
        areas: standardFilterAreas

    ###
    #   Attendance Filters
    ###

    if group.properties?.features?.attendance?.enabled
      # Check-In Date
      filterTypes.push
        path: { label: "Check-In Date", value: "[attendance_records].check_in_day", optgroup: "Attendance" }
        conditions: [
          { label: "before", value: "<" }
          { label: "before or on", value: "<=" }
          { label: "on", value: "is" }
          { label: "after", value: ">" }
          { label: "after or on", value: ">="}
        ]
        date: true
        areas: standardFilterAreas

      # Check-Out Date
      filterTypes.push
        path: { label: "Check-Out Date", value: "[attendance_records].check_out_day", optgroup: "Attendance" }
        conditions: [
          { label: "before", value: "<" }
          { label: "before or on", value: "<=" }
          { label: "on", value: "is" }
          { label: "after", value: ">" }
          { label: "after or on", value: ">="}
        ]
        date: true
        areas: standardFilterAreas

      # Trusted Contact Name
      filterTypes.push
        path: { label: "Trusted Contact Name", value: "[trusted_contacts].name", optgroup: "Attendance" }
        conditions: [c.matches]
        text: true
        areas: standardFilterAreas

      # Trusted Contact Email
      filterTypes.push
        path: { label: "Trusted Contact Email Address", value: "[trusted_contacts].email", optgroup: "Attendance" }
        conditions: [c.is]
        text: true
        areas: standardFilterAreas

      # Trusted Contact Added -- existence check for a profile's trusted contacts
      filterTypes.push
        path: { label: "Trusted Contact", value: "[trusted_contacts].added", optgroup: "Attendance" }
        conditions: [{ label: "has been", value: "is" }, { label: "has NOT been", value: "isnt" }]
        values: [
          { label: "Added", value: "true" }
        ]
        areas: standardFilterAreas

    ###
    #   Prescreening Filters
    ###

    if group.properties?.features?.prescreening?.enabled
      # profiles-target submission date
      filterTypes.push
        path: { label: "Submitted Date", value: "[profiles].screenings_submitted", optgroup: prescreeningAlias }
        conditions: [
          { label: "submitted on", value: "dateis" }
          { label: "no submission for", value: "dateisnt" }
        ]
        date: true
        areas: [manager]

      # Created Date
      filterTypes.push
        path: { label: "Created Date", value: "[screenings].createdDate", optgroup: prescreeningAlias }
        conditions: [
          { label: "before", value: "date<" }
          { label: "before or on", value: "date<=" }
          { label: "on", value: "dateis" }
          { label: "after", value: "date>" }
          { label: "after or on", value: "date>="}
        ]
        date: true
        areas: [prescreening]

      # Created Date-Time
      filterTypes.push
        path: { label: "Created Date Time", value: "[screenings].created", optgroup: prescreeningAlias }
        conditions: [
          { label: "before", value: "<" }
          { label: "before or on", value: "<=" }
          { label: "after", value: ">" }
          { label: "after or on", value: ">="}
        ]
        dateTime: true
        areas: [prescreening]

      # Temperature - Celsius
      filterTypes.push
        path: { label: "Temperature (°C)", value: "[screenings].temperature", optgroup: prescreeningAlias }
        conditions: [
          { label: "less than", value: "<" }
          { label: "less than or equal to", value: "<=" }
          { label: "greater than", value: ">" }
          { label: "greater than or equal to", value: ">=" }
        ]
        number: true
        areas: [prescreening]

      # Temperature - Fahrenheit
      filterTypes.push
        path: { label: "Temperature (°F)", value: "[screenings].temperatureF", optgroup: prescreeningAlias }
        conditions: [
          { label: "less than", value: "<" }
          { label: "less than or equal to", value: "<=" }
          { label: "greater than", value: ">" }
          { label: "greater than or equal to", value: ">=" }
        ]
        number: true
        areas: [prescreening]

      # Profile Name
      filterTypes.push
        path: { label: "Profile Name", value: "[profiles].id", optgroup: "Demographics" }
        conditions: [c.is]
        ajax: "/api/organizations/#{group.id}/search-profiles"
        values: []
        areas: [prescreening]

    # defines the ordering of filters in a given area based on their optgroup
    # not all supported optgroups for an area need to be listed,
    #  only the ones whose order must be guaranteed
    # for example, Questions optgroups aren't included at all
    #  so all `manager` optgroups will be sorted above Questions
    # regexes will likely be needed if we're going to expand this further
    #  with more dynamic optgroup values, or we come up with an optgroup we want to guarantee
    #  will be sorted below Questions
    orderConstant = [
      "Demographics"
      "Users"
      "Registrations"
      "Notes"
      healthProfileAlias
      "Allergies"
      "Medications"
      "OTCs"
      "Tags"
      "Financial"
      "Coupon"
      "AddOn"
      "Protection Plan"
      "Permissions"
    ]
    areaOrders = {
      manager: orderConstant.concat([
        "Attendance"
        prescreeningAlias
      ])

      healthLog: [
        "General"
        "Subjective"
        "Vitals"
        "Diagnosis"
      ]

      attendance: [
        "Attendance"
      ].concat(orderConstant)

      prescreening: [
        prescreeningAlias
      ].concat(orderConstant)
    }

    return _(filterTypes)
      .filter (f) ->
        area in f.areas
      .sortBy (f) ->
        foundIndex = (areaOrders[area] || []).indexOf(f.path.optgroup)
        if foundIndex > -1 then return foundIndex else return Infinity
      .value()

  create
