angular.module("dn").directive "timestamp", ->
  templateUrl: "directives/timestamp/timestamp.directive.html"
  restrict: "E"
  scope:
    model: "="
    future: "="
    label: "@"
    ngDisabled: "="
  controller: ($scope) ->
    $scope.date = moment($scope.model).format "YYYY-MM-DD"
    $scope.time = moment($scope.model)
    today = moment().format "YYYY-MM-DD"
    $scope.min = "1970-01-01"
    if $scope.future then $scope.min = today

    $scope.$watch "[date,time]", ([date, time]) ->
      $scope.model = moment("#{date} #{time}").toISOString()
    , true
