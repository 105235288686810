lib.registerState "manager.support",
  url: "/support"
  templateUrl: "states/manager/support/support.state.html"
  resolve:
    documents: ($http) ->
      $http.get("/api/constants/documents").then (result) -> result.data
  controller: ($scope, organization, WhatDivision, documents, Images) ->
    $scope.organization = organization

    $scope.documents = documents.constant[WhatDivision.lcName]

    _.map $scope.documents, (doc) ->
      return unless doc.files
      Images.getConstantImage(doc.files).then (urls) ->
        doc.url = urls[0]
