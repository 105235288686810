lib.registerState('home.auth', {
  url: '/auth',
  templateUrl: 'states/home/auth/auth.state.html',
  params: {
    registration: null
  },
  resolve: {
    session: function ($http, $state) {
      $http.get('/api/session?reaccess').then(function (response) {
        if (_.has(response.data, 'access') && _.has(response.data, 'userID')) {
          $state.go('home.whereTo');
        }
        return response.data;
      });
    }
  },
  controller: function ($http, $interval, $rootScope, $scope, $state, $timeout, User, WhatDivision, registration) {

    // These scope variables are accessed from many of `auth`'s child states
    // So, we just default them here.
    $scope.submitting = false;
    $scope.wrongPassword = false;
    $scope.noUserFound = false;
    $scope.userAlreadyExists = false;
    $scope.resetRequested = false;
    $scope.invitationSent = false;

    $scope.division = WhatDivision;
    $scope.year = moment().year();
    $scope.registration = registration;

    if ($state.current.name === 'home.auth') $state.go('home.auth.login');

    var splashes = {
      docnetwork: [
        'laptop'
      ],
      campdoc: [
        'canoes', 'life-jackets', 'smores', 'tents', 'canoe'
      ],
      schooldoc: [
        'apple-laptop', 'geography', 'lockers', 'old-desk', 'pencil-meeting', 'schoolbus'
      ]
    };

    var randomSplash = splashes[division.lcName][_.random(0, splashes[division.lcName].length - 1)];

    splashUrl = function () {
      var url = 'stock/' + division.lcName + '/' + randomSplash + '.jpg';
      if (
        !$scope.registration
        || !$scope.registration.properties
        || !$scope.registration.properties.branding
      ) return url;

      if (
        $scope.registration.properties.branding.splash
        && $scope.registration.properties.branding.splash[$scope.division.lcName]
      ) url = $scope.registration.properties.branding.splash[$scope.division.lcName];

      if ($scope.registration.properties.branding.customSplash) {
        url = `custom/${$scope.division.lcName}/${$scope.registration.id}.jpg`;
      }

      return url;
    };

    // Use `ng-style` so we don't 404 when getting random splashes
    $scope.bgStyle = {
      'background': `url('//branding.campdoc.com/splash/${splashUrl()}') no-repeat center center`,
      'background-size': 'cover'
    };

    // PASSWORDLAND
    $scope.$storage.password = $scope.$storage.password || { attempts: 0 };

    const forgiveness = $interval(function() {
      if ($scope.$storage.password.attemts > 0) {
        $scope.$storage.password.attempts -= 1;
        $scope.$storage.password.lastForgive = new Date().toISOString();
      }
    }, 60000);

    // Clean up the interval - not sure this is strictly necessary...
    $scope.$on('$destroy', () => $interval.cancel(forgiveness));

    // Check if user is locked out for rate limit or too many password attempts
    $scope.lockedUntil = function() {
      // eslint-disable-next-line eqeqeq
      if ($scope.$storage.password.lockedUntil == null
         || ($scope.$storage.password.lockedUntil < new Date().toISOString())) {
        return 0;
      }
      return moment($scope.$storage.password.lockedUntil).diff(moment(), 'seconds');
    };

    // Check credentials against the server
    $scope.authenticate = function(email, password) {

      // If we're missing either argument, just stop here
      if (!email || !password) {
        return;
      }

      // Set submitting to `true` to disable buttons
      $scope.submitting = true;

      // Get authenticate'd
      const authenticate = new User({
        email: email,
        password: password
      }).authenticate();

      authenticate.then((response) => {
        return userSuccessfullyAuthenticated(response);
      })
        // Handle invalid credentials
        .catch(authenticationFailed);

      function authenticationFailed (response) {
        const STATUS = response.status;
        const MSG = response.data;

        return $timeout(function() {

          // No matter what, do all these things
          $scope.submitting = false;
          $scope.$storage.password.attempts += 1;
          $scope.$storage.password.lastAttempt = new Date().toISOString();

          // If no user is found, redirect them
          if (STATUS === 401 && MSG === 'User not found') {

            $scope.noUserFound = true;
            return $state.go('home.auth.noinvite', {email: email});
          } else if (STATUS === 401 && MSG === 'Bad password') {

            // Password is bad. Nuke it.
            $scope.password = '';
            $scope.wrongPassword = true;
          } else if (STATUS === 429) { // If they're rate limited, do the rate limited stuff
            // They're rate limited. Nuke it.
            $scope.password = '';
            $scope.authRateLimited = true;
          }

          // Penalize incorrect password attempts
          let waitSeconds;
          if ($scope.$storage.password.attempts >= 2) {
            waitSeconds = _.min([($scope.$storage.password.attempts - 1) * 5, 30]) + 1;  // +1 for readability
          }
          // If the client has been rate-limited, we need to not let them just try again
          if ($scope.authRateLimited) waitSeconds = 300;

          // Add the seconds to $storage
          if (waitSeconds) {
            $scope.$storage.password.lockedUntil = moment().add(waitSeconds, 'seconds').toISOString();
          }

          // Visualize it
          $('.wrong-password').delay(0).effect('shake', { distance: 10 });
          $scope.$apply();

          // If the user ISN'T on mobile, auto-focus the password input
          if ($scope.viewPort.width > 720) {
            $('input[type=password]').focus();
          }

        });
      }

      function userSuccessfullyAuthenticated (response) {
        // Great Success! Go to `whereTo` and reset password attempts
        $scope.$storage.password.attempts = 0;
        const userID = response.user.id;
        $state.go('home.whereTo', { userID });
      }
    }; // end $scope.authenticate

    $scope.checkUserStatus = function(email) {
      if (!email) return;
      return new Promise((resolve, reject) => {
        User.statusByEmail(email).then((status) => {
          return resolve(status);
        }).catch((err) => {
          reject(err);
        });
      });
    };

    $scope.submitUser = function(configString, email, password) {

      // For the passowrd config, just attempt to authenticate the user
      if (configString === 'password') {
        return $scope.authenticate(email, password);
      }

      // If we have the signup config and no email, just redirect to the signup
      // state and allow them to enter an email.
      if (configString === 'signup' && !email && $state.current.name !== 'home.auth.signup') {
        // Pass no email so we show the proper HTML
        return $state.go('home.auth.signup');
      }

      // Otherwise, if no email is provided, go no further.
      if (!email) return;

      // Look up user status
      $scope.checkUserStatus(email).then((status) => {

        // For the signup config, set userAlreadyExists to `true`
        if (configString === 'signup' && status.exists) {
          if ($state.current.name !== 'home.auth.signup') {
            $state.go('home.auth.signup', {email});
          }
          return $scope.userAlreadyExists = true;
        }

        // Whether they have a user or not, if there's a reg send em to `signup`
        if ($scope.registration && !status.invites) {
          return $state.go('home.auth.signup', {email, password});
        }

        // No invites, no registration, no user. Send em to `noinvite`
        if (!status.invites) {
          return $state.go('home.auth.noinvite', {email});
        }

        // No reg, no user, but there are invites. Take em to `resend`
        if (status.invites) {
          return $state.go('home.auth.reinvite', {email});
        }
      });
    };

    // Reset Password Swal
    $scope.resetPassword = function(email) {

      $scope.submitting = true;

      if (email) {
        $http.post('/api/users/' + encodeURIComponent(email.toLowerCase()) + '/reset').success(() => {
          $scope.submitting = false;
          $scope.resetRequested = true;
          if ($state.current.name !== 'home.auth.pwreset') {
            $state.go('home.auth.pwreset', {email: email});
          }
          return $('.reset-confirm').delay(0).effect('highlight');
        // eslint-disable-next-line no-unused-vars
        }).error((err) => {
          $scope.submitting = false;

          // If we don't find em, redirect em appropriately.
          return $scope.submitUser('signup', email);
        });
      } else {
        $scope.submitting = false;
        return swal({
          type: 'warning',
          title: 'No Email Provided',
          text: 'To request a password reset, please enter your email address.'
        });
      }
    };
  }
});
