lib.registerState "oper.organizations.organization.summary",
  url: "/summary"
  templateUrl: "states/oper/organizations/organization/summary/summary.state.html"
  resolve:
    profiles: ($http, organization) ->
      $http.get("/api/organizations/#{organization.id}/profiles").then (result) ->
        result.data

  controller: ($scope, $state, $http, flash, profiles) ->

    $scope.profileCount = _.keys(profiles).length

    $scope.deactivate = ->
      $scope.org.deactivated = new Date().toISOString()
      $scope.org.save().then (org) ->
        flash "#{$scope.org.name} Deactivated!"
        $state.reload $state.current.name

    $scope.reactivate = ->
      $scope.org.deactivated = null
      $scope.org.save().then (org) ->
        flash "#{$scope.org.name} Reactivated!"
        $state.reload $state.current.name

    $scope.toggleTestOrg = ->
      $scope.org.testOrg = !$scope.org.testOrg
      $scope.org.save().then ((org) ->
        flash "#{$scope.org.name} is #{if $scope.org.testOrg then 'now' else 'no longer'} a test organization"
      ), (err) ->
        swal "Save Error", "Could not change test organization status!", "error"
        $state.reload()

    $scope.saveStartYear = ->
      $scope.org.save().then ((org) ->
        flash "#{$scope.org.name}'s start year saved as #{$scope.org.start_year}"
      ), (err) ->
        swal "Save Error", "Could not change #{$scope.org.name}'s start year", "error"
        $state.reload()

    $scope.years =
      _.range(2009, (moment().year() + 1)).map((y) -> { label: y.toString(), value: y.toString() })

    if $scope.org.properties.smsEnabled
      $http.get("/api/organizations/#{$scope.org.id}/sms-tokens").then (result) ->
        $scope.tokens = {
          currentQuantity: if result.data then result.data.tokens else 0
        }

    $scope.addTokens = ->
      newQuantity = parseInt $scope.tokens.toAdd
      swal {
        title: "Add Tokens"
        text: "
          <p>Add <strong>#{newQuantity}</strong> tokens to #{$scope.org.name}?</p>
          <p>This will bring the total to <strong>#{$scope.tokens.currentQuantity + newQuantity}</strong>.</p>
        "
        html: true
        type: "warning"
        showCancelButton: true
        closeOnConfirm: false
        showLoaderOnConfirm: true
      },
      (confirmed) ->
        if confirmed
          $http.post("/api/operator/#{$scope.org.id}/sms-tokens/", { quantity: newQuantity })
            .success (result) ->
              $scope.tokens.currentQuantity = result.tokens
              $scope.tokens.toAdd = null
              swal {
                title: "Success"
                type: "success"
                text: "#{$scope.org.name} now has #{$scope.tokens.currentQuantity} tokens."
              }
            .error (error) ->
              swal {
                title: "Error"
                type: "error"
                text: error?.message || 'There was an error adding tokens.'
              }
