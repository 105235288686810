lib.registerState("patient.profile.funkNotes", {
  url: "/campgrams",
  templateUrl: "states/patient/profile/funk-notes/funk-notes.state.html",
  resolve: {

    title($rootScope) {
      return $rootScope._title = "CampGrams";
    },

    funkNotes($http, profile) {
      return $http.get("/api/profiles/" + profile.id + "/funk-notes?limit=3").then((result) => {
        return result.data;
      }, (err) => {
        return [];
      });
    }
  },
  controller($http, $scope, $state, campgramsOnly, funkNotes, organization, profile, registrations, session) {

    // ui-router show/hide bullshit. i'm not bitter.
    $scope.funkNotesState = () => $state.current.name === "patient.profile.funkNotes";
    $scope.sessionUser = session.user;
    $scope.profile = profile;
    $scope.funkNotes = funkNotes;
    $scope.organization = organization;
    $scope.campgramsOnly = campgramsOnly;

    // If they don't have a current or future reg, don't allow them to see the campgramps send UI
    $scope.currentOrFutureRegs = ($scope.profile.registrations || []).some(function(r) {
      return r.group.phase === "present" || r.group.phase === "future";
    });

    $scope.viewHistory = () => $state.go('.history');
  }
});
