lib.registerState('patient.profile.insurance', {
  url: '/protection-plan',
  templateUrl: 'states/patient/profile/insurance/insurance.state.html',
  resolve: {
    title($rootScope, profile) {
      return $rootScope._title = `${profile.givenName} ${profile.familyName} / Protection Plan`;
    }
  },
  controller($http, $scope, $state, RegData, campgramsOnly, organization, profile, session, gaTagIDs) {
    $scope.gaTagIDs = gaTagIDs;

    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    const insuranceStandalone = true;
    $scope.regData = new RegData(organization, null, insuranceStandalone);
    $scope.profile = profile;
    $scope.organization = organization;
    $scope.userID = session.user.id;

    // Flat org truthers unite
    $scope.flatOrg = flattenOrg(organization);
    $scope.insurableGroups = getInsurableRegs();

    function flattenOrg(organization) {

      const GROUP_PROPS = [ 'id', 'name', 'classification', 'parentID', 'parents', 'phase', 'deactivated', 'registration', 'shortName', 'properties', 'compiledProperties', 'parent' ];
      const org = _.pick(organization, GROUP_PROPS);
      return flatten(organization.children, [ org ]);

      function flatten(children, flat) {
        children.map((group) => {
          flat.push(_.pick(group, GROUP_PROPS));
          if (group.children) {
            group.children.map((child) => {
              if (!child.properties.start) child.properties.start = group.properties.start;
              if (!child.properties.finish) child.properties.finish = group.properties.finish;
              child.parent = { name: group.name };
            });
            flatten(group.children, flat);
          }
        });
        return flat;
      }
    }

    $scope.registration = {
      selectedGrade: '',
      groups: [],
      addOns: [],
      coupons: [],
      insurancePolicies: [],
      donation: 0,
      paymentMethod: {},
      isTestAccount: !!session.user.options.includes('"testAccount"=>"true"')
    };


    $scope.steps = [
      'protection-plan',
      'payment'
    ];

    $scope.step = {
      isComplete: () => false,
      bypass: () => function(){},
      onContinue: () => null,
      disableBack: () => null,
      hideContinue: !$scope.insurableGroups.length,
      direction: 'forward',
      numSteps: $scope.steps.length,
      current: 0
    };

    $scope.regData.fetchPaymentMethods(session.userID).then(()=>{}).catch(errHandler);

    function errHandler(err) {
      flash('Error fetching payment data');
      console.log('Error fetching payment data', err);
    }

    function getInsurableRegs() {
      return profile.registrations.reduce((acc, reg) => {
        const insuredMatch = profile.insured ? profile.insured.find(i => i.registrationID === reg.id) : null;
        if (!reg.waitlisted && !insuredMatch) {
          const groupMatch = $scope.flatOrg.find(g => g.id === reg.groupID);
          if (groupMatch && groupMatch.phase === 'future') {
            groupMatch.registrationID = reg.id;
            acc.push(groupMatch);
          }
        }
        return acc;
      }, []);
    }

  }
});
