angular.module("dn").directive("smsDirective", function() {
  return {
    templateUrl: "directives/sms-messages/sms/sms.directive.html",
    restrict: "E",
    scope: {
      provider: "=",
      profiles: "=",
      user: "=",
      organization: "="
    },
    controller: function($scope, $state, $rootScope, $http, $filter) {

      $scope.sendingMessage = false;
      $scope.buyingTokens = false;

      getTokens();

      setMessageDefaults();

      function getTokens() {
        if ($filter('permissionVisible')({ send_sms: "allow" })) {
          return $http.get(`/api/organizations/${$scope.organization.id}/sms-tokens`).then(({ data }) => {
            $scope.smsTokens = data.tokens || 0;
            $scope.formattedTokens = $scope.smsTokens.toLocaleString();
          })
        } else $scope.smsTokens = 0;
      }

      $scope.sendMessage = (message) => {
        let profileIDs;
        $scope.sendingMessage = true;
        if (_.some($scope.profiles, p => p.selected)) {
          profileIDs = _.reduce($scope.profiles, (acc, p) => {
            if (p.selected) acc.push(parseInt(p.profiles.id));
            return acc;
          }, []);
        } else profileIDs = _.map($scope.profiles, 'profiles.id');
        const sms = { message, profileIDs, userID: $scope.user.id };
        // Get the sms token estmate first so that the user knows what they're about to pay
        $http.post(`/api/organizations/${$scope.organization.id}/estimate-sms`, sms).then(({ data }) => {
          if (data.estimate === 0) {
            return noUserPhones();
          }
          else if ($scope.smsTokens < data.estimate) {
            return notEnoughTokens(data);
          }
          return confirmOrgTokens(data, sms);
        }).catch(() => {
          return errorSwal();
        });
      }

      function noUserPhones() {
        return swal({
          type: "error",
          title: "Error",
          text: `We are unable to send this text message alert
            because there are no users with activated phone numbers.
          `,
          confirmButtonText: 'Ok'
        }, (confirmed) => {
          return $scope.sendingMessage = false;
        });
      }

      function notEnoughTokens({ profilesCount, estimate }) {
        return swal({
          type: "error",
          title: "Insufficient Tokens",
          text: `
            <p style="text-align: center; font-size: 1.2em;">You are attempting to send
            <strong>${$scope.smsCount}</strong> text messages to
            <strong>${profilesCount}</strong> users.</p>
            <br>
            <p style="text-align: center; font-size: 1.2em;">This will cost a total of
            <strong>${estimate}</strong> tokens.</p>
            <br>
            <p style="text-align: center; font-size: 1.2em;">Your organization currently has
            <strong style="color: #DD6B55;">${$scope.smsTokens}</strong> tokens.</p>
          `,
          html: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Try again"
        }, (confirmed) => {
          return $scope.sendingMessage = false;
        });
      }

      function confirmOrgTokens({ profilesCount, estimate }, sms) {
        return swal({
          type: "info",
          title: "Confirmation",
          text: `
            <p style="text-align: center; font-size: 1.2em;">You are about to send
            <strong>${$scope.smsCount}</strong> text messages to
            <strong>${profilesCount}</strong> users.</p>
            <br>
            <p style="text-align: center; font-size: 1.2em;">This will cost a total of
            <strong>${estimate}</strong> tokens.</p>
          `,
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Continue",
          html: true
        }, (confirmed) => {
          if (confirmed) {
            return finallySendMessage(sms);
          } else {
            return $scope.sendingMessage = false;
          }
        });
      }

      function finallySendMessage(sms) {
        $http.post(`/api/organizations/${$scope.organization.id}/send-sms`, sms).then(({ data }) => {
          $scope.smsTokens = data.tokens;
          $scope.formattedTokens = $scope.smsTokens.toLocaleString();
          $scope.sendingMessage = false;
          $scope.message = "";
          return successSwal();
        }).catch(() => {
          return errorSwal();
        });
      }

      function successSwal() {
        return swal({
          type: "success",
          title: "Success!",
          text: "Your text message has been sent.",
          confirmButtonText: 'Ok'
        }, (confirmed) => {
          return $scope.sendingMessage = false;
        });
      }

      function errorSwal() {
        return swal({
          type: "error",
          title: "Error sending text message",
          text: "Please try again in a few minutes.",
          confirmButtonText: 'Ok'
        }, (confirmed) => {
          return $scope.sendingMessage = false;
        });
      }

      $scope.sendDisabled = () => {
        return $scope.user.operator || !$scope.smsTokens || !$scope.message || $scope.sendingMessage;
      }

      $scope.tokensPurchased = (moreTokens) => {
        $scope.smsTokens += parseInt(moreTokens);
        $scope.formattedTokens = $scope.smsTokens.toLocaleString();
        $scope.buyingTokens = false;
      }

      const MAX_LENGTH = 140;
      // saved org prefix is going to not have the [] - so we'll need format it
      $scope.orgPrefix = $scope.organization.properties.smsPrefix ? `[${$scope.organization.properties.smsPrefix}] ` : "";

      function setMessageDefaults() {
        $scope.charCount = 0;
        $scope.smsCount = 0;
        $scope.messageWithPrefix = "";
      }

      $scope.$watch('message', (fresh) => {
        if (!fresh || !fresh.length) return setMessageDefaults();
        $scope.messageWithPrefix = $scope.orgPrefix.concat(fresh);
        const chunkedMessage = _.chunk($scope.messageWithPrefix, MAX_LENGTH);
        $scope.charCount = _.last(chunkedMessage).length;
        $scope.smsCount = chunkedMessage.length;
      })
    }
  }
});
