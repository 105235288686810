lib.registerState('patient.profile.prescreening', {
  url: '/prescreening',
  templateUrl: 'states/patient/profile/prescreening/prescreening.state.html',
  resolve: {
    title($rootScope, GroupBranding) {
      return $rootScope._title = GroupBranding.aliases.prescreening;
    },
    template(campgramsOnly, organization, PrescreeningData) {

      const { features } = organization.properties;

      if (!features || !features.prescreening || !features.prescreening.enabled) {
        return new Error('Prescreening feature not enabled');
      }
      else if (campgramsOnly) {
        return new Error('There was a problem loading the page');
      } else {
        return PrescreeningData.getTemplate(true, organization.id)
          .then(data => {
            if (data && data.template && Array.isArray(data.template.questions) && data.template.questions.length > 0) {
              // In the future we will allow multiple templates but for now only one
              PrescreeningData.createTemplate({
                templateID: data.id,
                template: data.template.questions,
                branding: data.template.branding || window.dnim.constants.prescreening.branding
              });
            } else {
              return null;
            }
          })
          .catch(err => new Error('There was a problem loading the page'));
      }
    }
  },
  controller($scope, $rootScope, $state, template, campgramsOnly, PrescreeningData, GroupBranding) {
    'use strict';

    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    $scope.tmpModel = PrescreeningData.tmp;

    // Button Configuration
    const buttonConfig = {
      next: Object.create(Object.prototype, {
        disabled: {
          configurable: false,
          enumerable: true,
          get() {
            return !PrescreeningData.verifyFormData(PrescreeningData.tmpFormData);
          }
        },
        text: {
          configurable: false,
          enumerable: true,
          get() {
            return 'Submit';
          }
        },
        action: {
          configrable: false,
          enumerable: false,
          get() {
            return function() {
              $state.go('patient.profile.prescreening.confirmation');
            };
          }
        }
      }),
      back: {
        text: 'Return Home',
        action() {
          return $state.go('patient.profile');
        },
        hide: false,
        disabled: false
      }
    };


    const UI_CONFIG = {
      buttons: buttonConfig,
    };

    // Make the UI config available throughout all child states via the service
    PrescreeningData.initUi(UI_CONFIG);
    // Attach to $scope so we can bring our buttons to life.
    $scope.ui = PrescreeningData.ui;
    $scope.prescreeningAlias = GroupBranding.aliases.prescreening;

    // Prescreening flag
    const featureFlags = $rootScope.featureFlags;
    // Don't go any further if the feature isn't enabled.
    // Fair warning I tried adding this on the template resolve and it produced errors on reload of this state due to not finding $scope.FeatureFlags.prescreening
    if (!featureFlags.prescreening) {
      $scope.errorText = 'There was a problem loading the page';
      $rootScope._title = 'Page not found';
      return;
    }

    if (template instanceof Error) {
      $scope.errorText = template.message;
      $rootScope._title = 'Error';
      return;
    }

    // If no template is available disallow the user from going any further
    if (template === null) {
      $scope.noScreening = true;
      return;
    }

    $scope.$on('$stateChangeSuccess', () => {
      PrescreeningData.setUiEntry('buttons', buttonConfig);
    });

    return $state.go('patient.profile.prescreening.form');
  }
});
