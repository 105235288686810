angular.module("dn").directive "qbSexRestrict", ->
  templateUrl: "directives/builder/sex-restrict/qb-sex-restrict.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbSexRestrict"
  controller: ($scope) ->

    $scope.question.metadata ?= {}
    $scope.sexRestricted = if $scope.question.metadata.sexRestrict then true else false

    $scope.$watch "sexRestricted", (fresh, stale) ->
      if fresh is false and stale is true
        delete $scope.question.metadata.sexRestrict
        $scope.sexRestricted = false
    , true

    $scope.sexChoices = [
      { label: "Males", value: "Male" }
      { label: "Females", value: "Female" }
    ]
