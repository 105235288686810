#
# * Module definition
# * Name: primaryProviders
# * Metadata:
# *  providers -> each provider to include in form
# *    provider.label -> name of provider type
# *    provider.required
#
angular.module("dn").directive "qbPrimaryProviders", ->
  scope:
    module: "=qbPrimaryProviders"
    mode: "@"

  templateUrl: "directives/builder/modules/primary-providers/qb-primary-providers.directive.html"
  replace: true
  restrict: "A"
  controller: ($scope) ->
    $scope.module.metadata ?= {}
    $scope.module.label ?= "Primary Providers"
    $scope.module.required ?= true
    $scope.module.metadata.providers ?= []

    # Store here instead of on the group properties
    $scope.addProvider = ->
      $scope.module.metadata.providers.push
        label: ""
        required: false
        identifier: uuid.v4()

    $scope.removeProvider = (index) ->
      $scope.module.metadata.providers.splice index, 1

    # At least one has to be required
    $scope.addProvider()  unless $scope.module.metadata.providers.length
