lib.registerState "manager.profiles.profile.questionnaire",
  url: "/questionnaire"
  templateUrl: "states/manager/profiles/profile/questionnaire/profile-questionnaire.state.html"
  resolve:
    title: ($rootScope, organization) ->
      $rootScope._title =
        organization.properties?.portals?.patient?.sections?.questionnaire?.alias or
        organization.properties?.branding?.text?.healthProfileAlternateName or
        "Health Profile"

    profile: ($http, $stateParams, Profile) ->
      $http.get("/api/profiles/#{$stateParams.profileID}?regenerate&attach&excludeOrg").then (result) ->
        _.each result.data.properties?.stepExtensions, (request) ->
          if moment(request.extensionDate).isBefore(moment new Date())
            _.remove result.data.properties?.stepExtensions, (r) -> r.stepID is request.stepID
            new Profile(result.data).save(["properties"])

        profile = result.data
        profile.hasMeds = profile.has.medications && (profile.has.medications == "true" || profile.has.medications.value == "true")
        profile.blockedQuestionnaire = profile.newQuestionnaire?.blocked
        profile

    tryPast: ($http, profile) ->
      if profile.newQuestionnaire?.questions?.length or profile.newQuestionnaire?.blocked then return profile
      $http.get("/api/profiles/#{profile.id}?regenerate&attach&allRegistrations&excludeOrg").then (result) ->
        profile.newQuestionnaire = result.data.newQuestionnaire
        profile.pastQuestionnaire = true

    # Object keyed by group ID that just contains group data needed for the
    # Health Profile. Used by the registrations resolve to add group data to
    # each registration. Getting the group data this way allows us to pass
    # the regOnly flag to the registrations route, resulting in much smaller
    # API responses and better performance.
    groupData: (organization) ->
      propPaths = ['phase', 'properties.lockout']
      return window.lib.orgToGroupMap(organization, { propPaths })

    registrations: ($http, groupData, profile) ->
      if profile.registrations then return
      $http.get("/api/profiles/#{profile.id}/registrations?regOnly=true").then (result) ->
        profile.registrations = result.data.map((reg) ->
          reg.group = groupData[reg.groupID]
          return reg
        )
        return

    takerName: (organization) ->
      organization.properties?.portals?.patient?.sections?.questionnaire?.alias || "health profile"

    groupOtcMeds: ($http, organization) ->
      $http.get("/api/organizations/#{organization.id}/otc-meds").then(
        (success) ->
          return success.data
        , (error) ->
          return []
      )

    invoicedProfile: ($http, organization, profile) ->
      $http.get("/api/organizations/#{organization.id}/invoices").then(
        (result) ->
          return _.some(result.data, (invoice) -> _.includes(invoice.profiles, profile.id))
      , (err) ->
        return false
      )

  controller: ($http, $scope, $state, $timeout, $filter, Images, Review, Note, Profile, Event, profile, flash, session, takerName, organization, invoicedProfile, groupOtcMeds) ->

    # Permissions!
    $scope.editPermission = {profile_questionnaire: 'edit'}
    $scope.viewPermission = {profile_questionnaire: 'view'}

    $scope.userID = session.userID
    $scope.providerID = $scope.provider?.id
    if !$scope.allReviewed() then $scope.showReview = true

    # check for profileID in invoice (to show/hide inline uploader)
    $scope.invoicedProfile = invoicedProfile

    # Collect questions from the questionnaire object. Flatten and format
    ignore     = ["instructions"]
    modules    = ["allergies", "dimensions", "immunizations", "insurances", "multi-auth", "primaryProviders", "emergencyContacts", "medications", "encrypted", "otc"]

    # Choices for granting/denying a step extension
    $scope.stepExtChoices = [
      { label: "Grant", value: true }
      { label: "Deny", value: false }
    ]

    # Function for processing step extensions
    $scope.stepExt = (request, granted) ->
      requestArr = $scope.profile.properties.stepExtensions
      if !granted
        _.remove requestArr, (r) -> return r.stepID is request.stepID
      else request.granted = true

      request.displayDate = moment(request.extensionDate).format "MMM. DD, YYYY"

      new Profile($scope.profile).save(["properties"]).then ->
        status = if request.granted then "granted" else "denied"

        payload =
          extUntil:   request.displayDate
          stepName:   request.stepName
          division:   organization.properties.division
          takerName:  takerName

        if request.granted
          $http.post("/api/profiles/#{$scope.profile.id}/step/unlocked", payload).then ->
            return flash "Step extension #{status}."
        else return flash "Step extension #{status}."

    # Unlock date choices
    $scope.unlockDates = _.map _.range(1, 20), (r) ->
      label = "#{r} Day"
      if r > 1 then label += "s"
      { label: label, value: moment().add(r, "days").format("YYYY-MM-DD") }

    $scope.unlockDate = moment().add(7, "days").format "YYYY-MM-DD"

    $scope.unlock = ->
      $scope.unlocking = true
      $scope.profile.properties.extensionDate = $scope.unlockDate
      delete $scope.profile.properties.extensionRequested
      # only save properties
      { id, properties } = $scope.profile
      new Profile({ id, properties }).save().then (result) ->
        $http.post("/api/profiles/#{$scope.profile.id}/notify-unlock")
        new Event(
          profileID: $scope.profile.id
          type: "healthForm.extensionGiven"
          metadata: { date: $scope.unlockDate }
        ).save()
        $http.post('/api/dev/logger', {message: "Profile #{$scope.profile.id} unlocked until #{moment($scope.unlockDate).format("MM/DD/YYYY")}"})
        $scope.lockoutStatus = " Temporarily unlocked until #{moment($scope.unlockDate).format("MM/DD/YYYY")}"
        $scope.lockoutClass  = "expired"
        $scope.lockoutIcon   = "fa-unlock-alt"
        flash $scope.lockoutStatus
        $scope.showUnlock = false
        $scope.unlocking = false

    $scope.reviewReset = []
    $scope.questionIDs = []

    collect = (questions, array, depth, stepID) ->
      return unless questions?.length
      _.map questions, (q) ->
        q.historyID = "q#{q.id}"
        if q.type is "form" and depth > 1 then depth -= 1
        q.depth = depth
        q.stepID = stepID

        # Special handling for specific question types
        if q.active and q.type not in ignore and (q.type isnt "form" or q.label)
          array.push q

        if q.type is "form" then q.complete = true
        if q.type in modules then q.module = true
        if q.type is "boolean" and q.answer?.value
          if q.answer.value is "no" then q.answer.value = "No"
          if q.answer.value is "yes" then q.answer.value = "Yes"

        # TODO: make this more elegant
        if q.type is "authorization" and q.answer?.value
          value = q.answer.value
          if value.match("accepted") or value.match("declined")
            values = value.split "|"
            updated = moment(new Date(q.answer.updated)).format("MMMM D, YYYY hh:mm A")
            if value.indexOf("accepted") is 0
              value = "Electronically signed by #{values[1]} on #{updated}"
            else if value.indexOf("declined") is 0
              value = "Declined by #{values[1]} on #{updated}"
          q.answer.value = value

        # Set review questions
        if _.includes $scope.profile.reviewFlags, q.id.toString()
          $scope.reviewReset.push q.label
        if _.includes $scope.profile.reviewFlags, q.type
          $scope.reviewReset.push q.label

        newDepth = if q.type in ignore or q.type is "form" then depth else depth + 1
        collect q.subQuestions, array, newDepth, stepID
      _.map questions, (q) -> delete q.subQuestions
      array

    # Questionnaire parsing setup
    $scope.answerState = -> $state.current.name is "manager.profiles.profile.questionnaire.answer"
    $scope.profile = profile

    _.remove $scope.profile.newQuestionnaire.questions, (q) ->
      return true if q.metadata.sexRestrict && $scope.profile.sex != q.metadata.sexRestrict

    $scope.steps = $scope.profile.newQuestionnaire.questions

    # Partition subquestions of steps into "questions" array
    $scope.questions = []
    _.map $scope.steps, (step, index) ->
      $scope.questions[index] = collect step.subQuestions, [], 1, step.id

      # Don't print Medications --- Medications
      if $scope.questions[index]?[0]?.label is step.label
        if $scope.questions[index]?[0]?.type is "form"
          $scope.questions[index]  =_.tail $scope.questions[index]

      # Join IDs for printing specific steps
      $scope.questionIDs[index] = _.map($scope.questions[index], "id").join "|"

    # Lockout parsing
    nextLockout = null
    _.map $scope.profile.registrations, (r) ->
      if r.group.phase isnt "past" and !r.deactivated
        date = if r.group.compiledProperties.lockout then moment(r.group.compiledProperties.lockout).format("YYYY-MM-DD") else null
        if !date then return
        if !nextLockout or (date < nextLockout and date > moment().format("YYYY-MM-DD"))
          nextLockout = moment(r.group.compiledProperties.lockout).format "YYYY-MM-DD"

    if !nextLockout
      _.map $scope.profile.registrations, (r) ->
        if r.group.phase is "past" and !r.deactivated
          date = if r.group.compiledProperties.lockout then moment(r.group.compiledProperties.lockout).format("YYYY-MM-DD") else null
          if !date then return
          if !nextLockout or date > nextLockout
            nextLockout = moment(r.group.compiledProperties.lockout).format "YYYY-MM-DD"

    $scope.lockoutStatus = "Unlocked (Next Lockout #{moment(nextLockout).format("MM/DD/YYYY")})"
    $scope.lockoutIcon   = "fa-unlock"

    if nextLockout is null
      $scope.lockoutStatus = "Unlocked"
      $scope.lockoutIcon   = "fa-unlock"

    if $scope.profile.properties.extensionDate
      date = moment($scope.profile.properties.extensionDate).format "MM/DD/YYYY"
      $scope.lockoutStatus = "Temporarily unlocked until #{date}"
      $scope.lockoutIcon   = "fa-unlock-alt"

    if nextLockout < moment().add(1, "days").format("YYYY-MM-DD") and $scope.profile.completeness is 100
      if !$scope.profile.properties.extensionDate
        $scope.lockoutStatus = "Locked"
        $scope.lockoutIcon   = "fa-lock"

    # Gather images from profile questions
    $scope.images = {}
    _.map _.flattenDeep($scope.questions), (question) ->
      return unless question?.type is "upload"
      # create upload completefn for each question
      question.uploadComplete = (res) ->
        # upsert s3 key to answer's value
        if question.answer
          if question.answer.value
            question.answer.value += '|' + res.key
          else
            question.answer.value = res.key
        else
          question.answer =
            questionID: question.id
            profileID: $scope.profile.id
            value: res.key

        # update the backend answer
        $http.put("/api/profiles/#{$scope.profile.id}/answers", question.answer).then(
          (result) ->
            # attach id to frontend answer to prevent conflicts
            question.answer.id = result.data[0].id
            # get images for uploaded images
            Images.getForProfile($scope.profile.id, question.answer.value).then (urls) ->
              question.complete = true
              question.showUploader = false
              $scope.images[question.id] =
                value: question.answer.value
                urls: urls
        , (error) ->
          swal "Error", "There was a problem uploading the form", "error"
          $state.reload()
        )

      question.removeFile = (file) ->

        question.answer.value = question.answer.value.split("|").filter((key) -> key != file.name).join("|")

        # check for fake pdf file removal
        if question.answer.id && file.type != "application/pdf"
          # update the backend answer
          $http.put("/api/profiles/#{$scope.profile.id}/answers", question.answer).then(
            (result) ->
              if question.answer.value
                # get images for uploaded images
                Images.getForProfile($scope.profile.id, question.answer.value).then (urls) ->
                  $scope.images[question.id] =
                    value: question.answer.value
                    urls: urls
              else
                delete $scope.images[question.id]

          , (error) ->
            swal "Error", "There was a problem removing that form", "error"
            $state.reload()
          )

      return unless question.answer?.value?.length
      $scope.images[question.id] =
        value: question.answer.value
        timestamp: moment(question.answer.updated).format('MMM D, YYYY @ hh:mm:ss A')
      $scope.images[question.stepID] = true

    _.forIn $scope.images, (data, questionID) ->
      Images.getForProfile($scope.profile.id, data.value).then (urls) ->
        if (urls.length > 0)
          $scope.images[questionID].urls = urls
        else
          log.error "ERROR: Unable to fetch url from uploaded files"
          swal "Error", "Unable to fetch url from uploaded files", "error"
      , (error) ->
          log.error "ERROR: Unable to fetch url from uploaded files"
          swal "Error", "Unable to fetch url from uploaded files", "error"

    _($scope.questions).flattenDeep()
      .compact()
      .map((question) ->
        return question unless question.type == 'otc'
        if !!(question.answer?.value and groupOtcMeds.length)
          otcIDs = _(JSON.parse(question.answer?.value)).keys().value()
          answer = _.map(groupOtcMeds, (med) ->
            return {
              authorized: JSON.parse(question.answer.value)[med.id]
              label: med.label
            }
          )
          question.answer?.value = answer
        else
          question.answer?.value = []
      ).value()
