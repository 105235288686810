angular.module('dn').directive('reminderFilters', function () {
  return {
    templateUrl: 'directives/scheduled-reminders/reminder-filters/reminder-filters.directive.html',
    restrict: 'E',
    scope: {
      updateEditable: '=',
      filterString: '=',
      user: '=',
    },
    controller($location, $scope, $rootScope) {
      'use strict';

      // Sets scope variables for consumption by filter-builder
      const { createFilterObjectsFromString } = window.lib.filterParse;
      $scope.filters = createFilterObjectsFromString($scope.filterString);
      $scope.organization = $rootScope.organization;
      $scope.profiles = [];

      // Sets scope with lists divided into Shared and Custom subgroups
      $scope.lists = $scope.user.filters.map((s) => {
        return {
          label: s.name,
          value: s.string,
          optgroup: s.share ? 'Shared' : 'Custom',
          owner: s.userID === $scope.user.userID
        };
      });

      // Automatically sets url to include filters. Checks if
      // filter_string and filters params are different on first load
      // to prevent loading filters from a different reminder
      if (!$location.$$search.filters && $scope.filters.length
          || $location.$$search.filters !== $scope.filterString) {
        $location.search('filters', $scope.filterString);
      }

      // Sets activeList to avoid ng-if issues. If filterString is empty, will not set the list to an
      // already created, but empty, list.
      const activeList = $scope.filterString
        ? { text: $scope.filterString }
        : {};

      $scope.ui = {
        activeList,
      };

      // If no filters attached, avoids needing to click 'Add Condition' to start
      if (!$scope.filters.length) $scope.filters.push(new window.lib.Filter());

      // Handles list change and prevents additions to a list from changing the selected list
      $scope.listJump = $scope.ui.activeList;

      // Sets the search param in the url to prevent any reloading when adding a condition
      $scope.$watch('state.current.name', () => {
        if (!$location.$$search.filters && $scope.filters.length) {
          $location.search('filters', $scope.filterString);
        }
      }, true);

      // Changes active filters in filter-builder when the list selection changes
      $scope.$watch('listJump', (fresh) => {
        $scope.ui.activeList.text = fresh.text;
      }, true);
    }};
});
