angular.module('dn').directive "newSession", ->
  templateUrl: 'directives/new-session/new-session.directive.html'
  replace: false
  restrict: 'E'
  scope:
    addSession: '='
    organization: '='
    parentID: '@parentId'
    parentName: '@parentName'

  controller: ($http, $scope, $state, $timeout, Group, flash) ->
    $scope.newSession =
      parentID: parseInt($scope.parentID, 10)
      classification: "Session"
      properties: {}
      registration:
        enabled: true

    $scope.$watchCollection "newSession.properties", (fresh, stale) ->
      if fresh.start && fresh.finish
        start  = moment(new Date fresh.start.toString())
        finish = moment(new Date fresh.finish.toString())

        $scope.sessionValid = start.isSameOrBefore(finish)

      else $scope.sessionValid = false

    $scope.saveSession = (group) ->
      $http.post("/api/groups/#{$scope.parentID}/subgroups", group).then (group) ->
        $scope.addSession = false
        $scope.newSession =
          parentID: parseInt($scope.parentID, 10)
          classification: "Session"
          properties: {}
          registration:
            enabled: true
        flash "New Session Created"
        # reload because else if you click `cancel` from the setup state, the new group will not be there
        $state.go ".setup", { groupID: group.data.id }, { reload: true }
