###
  Module definition
  Name: insurances
  Metadata:
   insurances -> each type of insurance that shows up in the taker
     insurance.identifier -> uuid.v4() to map metadata to form
     insurance.label -> Name of insurance
     insurance.required -> Insurance required
###
angular.module("dn").directive "qbInsurances", ->
  templateUrl: "directives/builder/modules/insurances/qb-insurances.directive.html"
  replace: true
  restrict: "A"
  scope:
    module: "=qbInsurances"
    mode: "@"
  controller: ($scope) ->
    $scope.module.metadata ?= {}
    $scope.module.label ?= "Insurances"
    $scope.module.required ?= true
    $scope.module.metadata.insurances ?= []

    $scope.addInsurance = ->
      $scope.module.metadata.insurances.push
        identifier: uuid.v4()

    $scope.removeInsurance = (i) -> $scope.module.metadata.insurances.splice i, 1
