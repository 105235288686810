'use strict';

angular.module('dn').service('SupportDirectory', (() => {
  const supportURL = 'https://support.docnetwork.org/hc/en-us/';
  const onboardingSupportURL = 'https://onboarding.docnetwork.org/';
  const directory = {
    // Home is the main site before going to participant or provider
    home: [
      {
        location: ['account.information'],
        docsURL: `${supportURL}articles/115001275490-User-Information`
      },
      {
        location: ['account.security'],
        docsURL: `${supportURL}articles/115001275490-User-Information#password-reset`
      },
      {
        location: ['account.billing'],
        docsURL: `${supportURL}articles/115001223990-Payments#changing-method`
      },
      {
        location: ['other'],
        docsURL: supportURL
      },
    ],
    // Participant
    patient: [
      {
        location: ['profile', 'profile.demographics'],
        docsURL: `${supportURL}articles/360000814792-Demographic-Information`
      },
      {
        location: ['profile.registration'],
        docsURL: `${supportURL}articles/115001185990-Register`
      },
      {
        location: ['profile.questionnaire', 'profile.questionnaire.step'],
        docsURL: `${supportURL}articles/115001508030-Health-Profile`
      },
      {
        location: ['profile.account'],
        docsURL: `${supportURL}articles/115001223990-Payments`
      },
      {
        location: ['profile.insurance'],
        docsURL: `${supportURL}articles/115001225530-Protection-Plan`
      },
      {
        location: ['profile.funkNotes'],
        docsURL: `${supportURL}articles/115001230084-CampGrams`
      },
      {
        location: ['profile.emergency'],
        docsURL: `${supportURL}categories/115000363510-Participants`
      },
      {
        location: ['profile.labels'],
        docsURL: `${supportURL}articles/360021845172-Labels-`
      },
      {
        location: ['profile.trustedContacts'],
        docsURL: `${supportURL}articles/360029324392-Trusted-Contacts`
      },
      {
        location: ['newProfile'],
        docsURL: `${supportURL}articles/360000814792-Demographic-Information`
      },
      {
        location: ['other'],
        docsURL: `${supportURL}categories/115000363510-Participants`
      },
    ],
    // Provider
    manager: [
      {
        location: ['alerts'],
        docsURL: `${supportURL}articles/360000427312-News-Feed`
      },
      // Profiles
      {
        location: ['profiles', 'profiles.createProfile'],
        docsURL: `${supportURL}articles/115002871211-Profiles`
      },
      {
        location: ['profiles.profile', 'profiles.profile.registrations'],
        docsURL: `${supportURL}articles/115002805351-Registrations`
      },
      {
        location: ['groups.roster'],
        docsURL: `${supportURL}articles/360001159772-Roster-Reports`
      },
      {
        location: ['profiles.profile.users', 'profiles.profile.notifications'],
        docsURL: `${supportURL}articles/360000948752-Users-and-Notifications`
      },
      {
        location: ['groups.registration'],
        docsURL: `${supportURL}articles/115003703912-Registration-Setup`
      },
      // EMAR here is included because medications share the same article
      {
        location: ['profiles.profile.medications.list', 'emar'],
        docsURL: `${supportURL}articles/115001191430-Medications-and-eMAR`
      },
      {
        location: ['profiles.profile.log'],
        docsURL: `${supportURL}articles/115001191650-Health-Log`
      },
      // Finances
      {
        location: [
          'finances.profileLedger.lineItems',
          'finances.profileLedger.balances',
          'finances.orgLedger.lineItems',
          'finances.orgLedger.payouts'
        ],
        docsURL: `${supportURL}articles/115002868231-Ledgers-Balances-and-Payouts`
      },
      // Protection Plan
      {
        location: [
          'travelProtection'
        ],
        docsURL: `${supportURL}articles/115001197364-Protection-Plan`
      },
      // Medical Supplies
      {
        location: ['medicalSupplies'],
        docsURL: `${supportURL}articles/115001274464-Medical-Supplies`
      },
      // Health Log
      {
        location: [
          'healthLog',
          'healthLog.browse',
          'healthLog.browse.entry',
          'healthLog.create',
          'healthLog.triage',
          'healthLog.tracking',
          'healthLog.statistics',
          'healthLog.templates'
        ],
        docsURL: `${supportURL}articles/115001191650-Health-Log`
      },
      // Prescreening
      {
        location: ['prescreening.template'],
        docsURL: `${supportURL}articles/360047188672-Prescreening`
      },
      {
        location: ['prescreening.browse'],
        docsURL: `${supportURL}articles/360047188672-Prescreening#h_01EFFJKBN3E2TPYGTPD742WPR7`
      },
      // Campgrams
      {
        location: ['funkNotes'],
        docsURL: `${supportURL}articles/115001197344-CampGrams`
      },
      // Attendance
      {
        location: [
          'attendance.list',
          'attendance.list.profiles',
          'attendance.list.profiles.profile.audit'
        ],
        docsURL: `${supportURL}sections/360006925491-Attendance`
      },
      {
        location: [
          'attendance.list.profiles.profile.trustedContacts',
          'attendance.list.profiles.profile.notes',
          'attendance.list.profiles.profile.moreParticipants',
        ],
        docsURL: `${supportURL}articles/360034014292-Check-In`
      },
      // Settings General
      {
        location: [
          'settings.general.basics',
          'settings.general.profiles',
          'settings.general.notifications',
          'settings.general.registration',
          'settings.general.healthLog',
          'settings.general.medications',
          'settings.general.tags',
          'settings.general.emergency',
          'settings.general.attendance',
          'settings.general.subgroups',
          'settings.general.manage'
        ],
        docsURL: `${supportURL}articles/115001226404-Settings`
      },
      // Branding
      {
        location: [
          'settings.branding'
        ],
        docsURL: `${supportURL}articles/360024932572-Branding`
      },
      // Importer
      {
        location: [
          'import.groups',
          'import.upload',
          'import.profile-import'
        ],
        docsURL: `${supportURL}articles/115001183410-Imports`
      },
      {
        location: [
          'setup',
          'setup.season',
          'setup.update'
        ],
        docsURL: `${onboardingSupportURL}`
      },
      {
        location: ['other'],
        docsURL: `${supportURL}categories/115000365084-Organizations`
      }
    ],
  };

  return class SupportDirectory {
    constructor() {
      this.directory = directory;
    }

    findDoc(location) {
      // Default support docs URL
      let docsURL = supportURL;
      if (location && location !== '') {
        const splitLocation = location.split('.');
        // The first split will always be the portal
        const portal = splitLocation.shift();
        // Check if directory portal is defined
        if (this.directory[portal]) {
          // Since the portal was taken out we can be confident everything else is the location
          const locationWithoutPortal = splitLocation.join('.');
          const foundDirectory = this.directory[portal].find(function(page) {
            return page.location.find(location => location === locationWithoutPortal);
          });
          // If no directory matches it will return the main help site for patient or orgs
          if (!foundDirectory) {
            docsURL = this.directory[portal][this.directory[portal].length - 1].docsURL;
          } else {
            docsURL = foundDirectory.docsURL;
          }
        }
      }
      // Return support site
      return docsURL;
    }
  };
})());
