# Handle a 'boolean' type question (i.e. yes/no)
angular.module("dn").directive "qtBoolean", ->
  templateUrl: "directives/taker/boolean/qt-boolean.directive.html"
  restrict: "A"
  scope:
    question: "=qtBoolean"
    answer: "="
  controller: ($scope) ->
    $scope.validate = if $scope.question.required then "required" else ""
    $scope.choices = [
      { label: "Yes", value: "yes" }
      { label: "No", value: "no" }
    ]
