lib.registerState('manager.finances.setup', {
  url: '/finances-setup',
  templateUrl: 'states/manager/finances/setup/setup.state.html',
  resolve: {
    title: ($rootScope) => $rootScope._title = 'Finances Setup',
    glCodesDefaults: function (organization, $http, $state) {
      return $http.get(`/api/group/${organization.id}/gl-codes/defaults`).then(
        function(success) { return success.data; },
        function() {
          window.swal({
            title: 'Page Error',
            type: 'warning',
            text: 'There was an error loading GL Codes data.\n\nPlease reload and try again. If this error continues to occur, please contact our support team with the contents of this message and the URL of this page.',
            confirmButtonText: 'Reload'
          }, function() {
            return $state.reload();
          });
        }
      );
    }
  },
  controller: function($filter, $scope, $state, glCodesDefaults, organization) {
    $scope.parcelConfig = () => window.System.import('@dn/finances');
    $scope.parcelProps = {
      org: organization,
      glCodesDefaults,
      hasEditPermissions: $filter('permissionVisible')({ financial_ledgers: 'edit' }),
    };
  }
});
