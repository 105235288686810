angular.module('dn').directive('templateEditorOptions', function () {
  return {
    restrict: 'E',
    templateUrl: 'directives/prescreening/template-editor-options/template-editor-options.directive.html',
    scope: {
      question: '=',
      ui: '=',
    },
    controller($scope, PrescreeningData) {
      'use strict';
      try {
        // Used to get access to all we need
        $scope.prescreeningData = PrescreeningData;
      } catch (error) {
        log.error('[template-editor-options]', error);
      }
    }
  };
});
