angular.module('dn').directive('tagBuilder', function() {
  return {
    templateUrl: 'directives/tags/tag-builder/tag-builder.directive.html',
    restrict: 'E',
    scope: {
      showAll: '=',
      tags: '=',
      color: '@'
    },
    controller: function($scope) {

      // Validating that the tag is a human-readable string
      const validTag = tag => typeof tag === 'string' && tag.length >= 0;
      $scope.tags = [...new Set($scope.tags)].filter(tag => {
        const isValid = validTag(tag);
        if (!isValid) {
          console.error(`[tag-builder.directive] invalid tag type for ${tag} is ${typeof tag}`);
        }
        return isValid;
      });
    },
  };
});
