lib.registerState('patient.profile.trustedContacts', {
  url: '/trusted-contacts',
  templateUrl: 'states/patient/profile/trusted-contacts/trusted-contacts.state.html',
  resolve: {
    title($rootScope, profile) {
      return $rootScope._title = `${profile.givenName} ${profile.familyName} / Trusted Contacts`;
    },
    contacts($http, organization, profile) {
      return $http.get(`/api/organizations/${organization.id}/profiles/${profile.id}/trusted-contacts`).then(({ data }) => {
        return data.map((contact) => Object.assign(contact, { orgID: organization.id }));
      }).catch((err) => {
        if (err && err.status === 500) {
          flash('Error retrieving trusted contacts');
        }
        return [];
      });
    }
  },
  controller($scope, $state, ContactData, campgramsOnly, contacts, profile, organization) {
    if (campgramsOnly) {
      return $state.go('patient.profile.funkNotes');
    }

    if (!(($scope.organization.properties.features || {}).attendance || {}).enabled) {
      return $state.go('patient.profile');
    }

    if (contacts.length) {
      ContactData.addToStore(contacts);
    }

    $scope.contacts = ContactData.findContactsByProfile($scope.profile.id);
    $scope.profile = profile;
    $scope.organization = organization;
    $scope.editing = false;

    $scope.back = function () {
      ContactData.clearTmpContact();
      $scope.contacts = ContactData.findContactsByProfile($scope.profile.id);
      $scope.editing = false;
    };

    $scope.edit = function (contact) {
      $scope.editing = true;
      if (!contact) {
        $scope.contactToEdit = ContactData.initTmpContact({
          profile_id: $scope.profile.id,
          orgID: $scope.organization.id
        });
      } else {
        $scope.contactToEdit = ContactData.initTmpContact(contact);
      }
    };
  }
});
