lib.registerState 'patient.profile.account',
  url: '/account'
  templateUrl: 'states/patient/profile/account/account.state.html'
  resolve:
    title: ($rootScope, profile) ->
      $rootScope._title = profile.givenName + ' ' + profile.familyName + ' / Account'

    alias: (organization) ->
      customAlias = organization.properties?.portals?.patient?.sections?.account?.alias
      customAlias or "Account"

    orgFeeSettings: ($http, organization) ->
      ppfFlag = window.lib.featureFlagClient.isEnabled('passingOnProcessingFees')
      if (ppfFlag)
        $http.get("/api/organization/#{organization.id}/finance/org-fee-settings")
          .then (({ data }) -> {
              feePassedOn: data.feePassedOn,
              bank: {
                  flat: data.bankFlatFee,
                  variable: data.bankVariableRate,
                },
              card: {
                flat: data.cardFlatFee,
                variable: data.cardVariableRate
              }
            })
          .catch (error) ->
            if (error.status is 404)
              return false
            else
              return flash "Error Loading Convenience Fee Settings"
      else return false

  controller: ($compile, $filter, $http, $scope, $state, PayProcessor, alias, campgramsOnly, flash, paymentPlan, profile, session, user, orgFeeSettings) ->
    #super stupid bug will display a 'v5.balance is not a function' if user hasn't filled out basic camper info
    #html seems to render before the controller instantiates and does the redirect
    #hide all of the account html behind this blocker until we run this check

    $scope.blocker = true
    return $state.go 'patient.profile.demographics' if ( ($scope.profile.infoMissing or $scope.profile.photoMissing) and !session.skipDemographics )
    return $state.go 'patient.profile.funkNotes' if campgramsOnly
    $scope.blocker = false
    $scope.isTestAccount = _.includes user.options, '"testAccount"=>"true"'
    $scope.profile = profile
    $scope.organization = profile.organization
    $scope.userID = session.userID
    $scope.paymentPlan = paymentPlan
    $scope.alias = alias
    $scope.feesPassedOn = orgFeeSettings.feePassedOn
    $scope.convenienceFeeTypes = { bank: orgFeeSettings.bank, card: orgFeeSettings.card }

    $scope.crebits = []
    crebits = _($scope.profile.crebits)
      .filter({ ledger: 'organization' })
      .filter((c) -> !c.description.startsWith('[TRANSFER]'))
      .sortBy('created')
      .value().reverse()

    _.each(_.filter(crebits, (crebit) -> return !crebit.adjusts), (crebit) ->
      $scope.crebits.push crebit
      $scope.crebits = _.union($scope.crebits, _.filter(crebits, {adjusts: crebit.id}))
      )

    $scope.balance = ->
      _.reduce $scope.crebits, (total, crebit) ->
        total += parseInt(crebit.amount, 10)
        return total
      , 0

    $scope.paymentPlanBalance = $scope.balance()

    # Payment plan warning
    $scope.remainder = Math.round(
      window.dnim.calculateRemainder($scope.paymentPlan.totalAmount, $scope.paymentPlan.remaining)
    )
    $scope.paymentPlanDiff = ($scope.balance() / 100) - ($scope.paymentPlan.totalAmount / 100)

    $scope.firstPayment = false
    if (($scope.paymentPlan.amount * $scope.paymentPlan.remaining) + $scope.remainder) == $scope.paymentPlan.totalAmount
      $scope.firstPayment = true

    $scope.hidePaymentPlans =
      $scope.organization.properties.disablePaymentPlans ||
      !PayProcessor.features.paymentPlans ||
      $scope.balance() < 0 ||
      moment().isAfter(moment($scope.organization.properties.paymentPlansDue))

    $scope.cancelPaymentPlan = ->
      $http.delete("/api/profiles/#{profile.id}/payment-plan").then ->
        flash 'Payment Plan Cancelled'
        $state.go('.', `undefined`, { reload:true })

    $scope.monthlyPayment = () ->
      if $scope.feesPassedOn
        convenience = $scope.convenienceFeeTypes[$scope.paymentPlan?.paymentMethod?.category];

        variableFee = convenience?.variable || 0;
        flatFee = convenience?.flat || 0;
        convenienceFee = Math.ceil((variableFee / 100) * $scope.paymentPlan?.amount) + flatFee

        return Math.floor(convenienceFee + $scope.paymentPlan?.amount) / 100

      return $scope.paymentPlan?.amount / 100


    deducePaymentType = ->
      paymentMethod = $scope.paymentPlan.paymentMethod.type

      if paymentMethod == 'Savings' or paymentMethod == 'Checking'
        return 'bank'
      return 'card'


    # Checks if the fee is the same for the first month's new amount, when a remainder is added
    $scope.checkFeeWithRemainder = () ->
      if !$scope.feesPassedOn
        return

      monthlyPayment = Math.floor($scope.paymentPlan.totalAmount / $scope.paymentPlan.remaining)

      remainder = $scope.remainder

      paymentMethod = deducePaymentType()
      convenience = $scope.convenienceFeeTypes[paymentMethod] or {}
      $scope.variableFee = convenience.variable or 0
      $scope.flatFee = convenience.flat or 0

      convFee0 = Math.ceil($scope.variableFee / 100 * monthlyPayment) + $scope.flatFee
      convFee1 = Math.ceil($scope.variableFee / 100 * (monthlyPayment + remainder)) + $scope.flatFee

      if convFee0 != convFee1
        $scope.hasDiff = true
        delta = Math.abs(convFee0 - convFee1)
        $scope.remainderWithFee = remainder + delta
        return $scope.remainderWithFee
      else
        $scope.hasDiff = false

      return remainder


    customPrint = ->
      registrations = []
      $http.get("/api/profiles/#{profile.id}/registrations").then (regs) ->
        if regs and regs.data and regs.data.length
          _.each regs.data, (reg) ->
            if reg.deactivated is null
              registrations.push {
                name: reg.group.name
                start: reg.group.compiledProperties.start
                finish: reg.group.compiledProperties.finish
              }
        $scope.registrations = _.sortBy registrations, (reg) -> 1 / new Date(reg.start)

        # pass those shown crebits (i.e., crebit._hide !== false) to the crebit-summary route, that
        # we might generate a summary of filtered crebits
        crebitIDs = _.reduce $scope.crebits, (accArr, c) ->
          if !c._hide then accArr.push(c.id)
          accArr
        , []

        # pass crebitIDs to crebit-summary
        $http.get("/api/profiles/#{profile.id}/crebit-summary?crebitIDs=#{crebitIDs}").then (res) ->
          $scope.crebitSummary = _.orderBy res.data, ['quantity', 'amount'], ['desc', 'desc']  if res.data and res.data.length
          $scope.quantityCrebitSummary = _($scope.crebitSummary).map((c) -> parseInt(c.quantity)).sum()
          $scope.amountCrebitSummary = _($scope.crebitSummary).map((c) -> parseInt(c.amount)).sum() / 100
          $scope.limit = null
          $scope.$apply()
          return $scope._print()

    # Configurate the options shown from the ledger-filter directive
    $scope.ledgerConfig = {
        print: true,
        printFn: customPrint,
        dates: true
      }

    # Data for pagination of line items
    $scope.currentPage = 0
    $scope.limit = 10

    setPages = (crebits) ->
      $scope.pages = _.times(Math.ceil(crebits.length / $scope.limit), Number)
    setPages($scope.crebits)

    $scope.$watch "crebits", (fresh) ->
      filteredCrebits = _.filter fresh, (c) -> c._hide == false
      setPages(filteredCrebits)
    , true
