lib.registerState('manager.prescreening.scheduledReminders', {
  url: '/scheduled-reminders',
  templateUrl: 'states/manager/prescreening/scheduled-reminders/scheduled-reminders.state.html',
  resolve: {
    title($rootScope, GroupBranding, organization) {
      // Have to use `organization` because child resolves happen _before_ parent resolves, so
      // $rootScope.organization isn't set yet here when we hard refresh.
      return $rootScope._title = `${GroupBranding.getPrescreeningAlias(organization)} Scheduled Reminders`;
    },
    scheduledReminders($vuex, organization) {
      const scheduledRemindersEnabled = _.get(organization, 'properties.features.prescreening.reminders');
      const genericErrorMessage = 'There was a problem loading the page';

      if (!scheduledRemindersEnabled) return new Error(genericErrorMessage);

      // Load reminders utilizing $vuex actions from the store
      return $vuex.dispatch('scheduledReminders/LOAD_ORG_REMINDERS', organization.id)
        // If you try to resolve to scheduledReminders.data, on reload it won't have the data.
        // Resolving to scheduledReminders and accessing scheduledReminders.data in the HTML works.
        .then(() => $vuex.state.entities.scheduledReminders)
        .catch(() => new Error(genericErrorMessage));
    },

    /**
     * @param {function} FilterSets factory function for filter sets
     * @param {Object} organization organization information
     * @param {Object} $rootScope scope for state
     * @returns {undefined} $rootScope.filterSets gets set
     */
    filterSets($rootScope, FilterSets, organization, reviewTypes) {
      // Generic full-permissions object to allow parsing of all filters
      const FILTER_PERMISSIONS = { permissions: true, financial: true, questions: true };

      organization.reviewTypes = reviewTypes;

      // TODO transition to vuex store
      $rootScope.filterSets = FilterSets(organization, 'manager', FILTER_PERMISSIONS);
    },
    // Creates a sorted array of lists used in the filter builder.
    // Used in the scheduled-reminder state when rendering the filter-builder tab.
    // Only keeps lists that are of type 'profiles-manager'
    createSavedLists(organization, SavedLists, session) {
      return SavedLists.fetchSavedLists('profiles-manager', organization, session.user);
    },
  },

  controller: function($rootScope, $scope, $state, $vuex, scheduledReminders) {
    'use strict';

    /**
     * Generate an error that the state can consume
     * @param {string} errorText: The error text we want to add to the state HTML
     */
    function generateError(errorText) {
      $scope.errorText = errorText;
      $rootScope._title = 'Error';
    }

    /**
     * Sends the user to the scheduledReminder state with an id of null, triggering
     * the set-up for creating a new reminder. Also clears editable reminders to
     * prevent previously viewed reminder details from populating in the new reminder.
     */
    $scope.handleNewReminder = function() {
      $vuex.commit('scheduledReminders/CLEAR');
      $state.go('.scheduledReminder', { reminderID: null, });
    };

    // Stop performing any additional logic in case of an error
    if (scheduledReminders instanceof Error) return generateError(scheduledReminders.message);

    $scope.scheduledReminders = scheduledReminders;

    /**
     * Handler for when the deactivation of a reminder succeeds. Created
     * to prevent bulky code in the deactivateScheduledReminder function.
     * @returns {SweetAlert} A swal that informs the user that deactivation was successful
     */
    function onDeactivateSuccess() {
      return swal({ title: 'Reminder Deactivated', type: 'success' });
    }

    /**
     * Handler for when the deactivation of a reminder fails. Created
     * to prevent bulky code in the deactivateScheduledReminder function.
     * @returns {SweetAlert} a swall that informs the user that deactivation was not successful
     */
    function onDeactivateFailure() {
      return swal({
        title: 'Deactivation Failed',
        text: 'There was an error deactivating this reminder.\nPlease reload and try again.',
        type: 'error',
      });
    };

    /**
     * Saved to scope for use in scheduled-reminder-cards. Deactivates reminder on database, then vuex store,
     * then local scheduledReminders. If at any point there is an error, activates a swal to alert user.
     * @param {Object} reminder contains id used to update the correct reminder in the database, vuex store,
     * and local scheduledReminders.
     * @returns {Function} Returns either onDeactivateSuccess() or onDeactivateFailure() depending on the
     * outcome of the dispatch.
     */
    $scope.deactivateScheduledReminder = (reminder) => {
      return $vuex.dispatch('scheduledReminders/DEACTIVATE_SCHEDULED_REMINDER', reminder)
        .then((res) => {
          delete $scope.scheduledReminders.data[res.id];
          return onDeactivateSuccess();
        }).catch(onDeactivateFailure);
    };
  }
});
