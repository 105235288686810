angular.module("dn").directive("paginateNavigate", function() {
  return {
    templateUrl: "directives/paginate-navigate/paginate-navigate.directive.html",
    restrict: "E",

    controller: function($scope, $state) {
      /*
        Things to be aware of here:
          - there's no isolate scope, and as such depends on the outer scope (paginate-list). Be careful with scope variable assignments
            - counts on having $scope.currentPage and pages
          - logic has a couple of references to the number 5
            - if you want to abstract this, replace all of those references with a $scope.limit
            - but not ACTUALLY $scope.limit because the parent state uses that, so something like it that's not that
      */

      $scope.startPage = 0;

      $scope.changePage = function(pageNum) {
        /* Don't navigate out of bounds */
        if (pageNum < 0 || pageNum > $scope.pages.length - 1) { return; }

        /* pageNum is somewhere between page 2-max, gets overridden by the next block if applicable */
        if (pageNum >= 5 && pageNum <= $scope.pages.length - 1) { $scope.startPage = pageNum - 2; }

        /* pageNum is in the range of the last page available, keep it as max-limit to avoid the startFrom filter starting too far right */
        if (pageNum > $scope.pages.length - 4) { $scope.startPage = $scope.pages.length - 5; }

        /* pageNum is less than the displayed limit, keep it on page 1. Keep this last to support lists with >1 but <limit pages */
        if (pageNum < 5) { $scope.startPage = 0; }

        /* Finally set the viewable page to pageNum */
        $scope.currentPage = pageNum;
      }

    } /* end controller */
  }; /* end return statement */
}); /* end directive */
