angular.module("dn").directive "qtAllergies", ->
  templateUrl: "directives/taker/modules/allergies/qt-allergies.directive.html"
  restrict: "A"
  scope:
    question: "=qtAllergies"
    allergies: "="
    profile: "="
  controller: ($scope, $stateParams, HealthForm, Constants, WhatDivision) ->

    # Init
    healthForm = HealthForm.get $scope.profile.id
    $scope.patient = WhatDivision.patient.singular
    $scope._status = { adding: "", editing: null }
    resetReview = ->
      if !$stateParams.review
        healthForm.profile.reviewFlags ?= []
        healthForm.profile.reviewFlags.push($scope.question.id.toString())



    if not _.isPlainObject $scope.profile.has.allergies
      $scope.profile.has.allergies = {}

    if $scope.question.expired and $scope.question.expirationType isnt 'step'
      Object.keys($scope.profile.has.allergies).map((key) -> delete $scope.profile.has.allergies[key])
      $scope.allergies.map((a) -> a.deactivated = new Date())

    # Constants
    if Constants._allergies then $scope.allergyNames = Constants._allergies
    else Constants.allergies().then (allergies) -> $scope.allergyNames = allergies

    # Choices
    $scope.allergyChoices = [
      { label: "Yes", value: "true" }
      { label: "No",  value: "false" }
    ]

    $scope.types = [
      { label: "Food",          value: "food" }
      { label: "Drug",          value: "drug" }
      { label: "Environmental", value: "environmental" }
    ]

    # Update allergies and references
    updateAllergies = ->
      $scope.activeAllergies = $scope.allergies.filter((a) -> !a.deactivated)
      $scope.refAllergies = _.groupBy $scope.activeAllergies, "type"

    $scope.backup = -> _.each $scope.allergies, (a) -> a.backup = _.cloneDeep a

    # Update values based on their allergy counts
    updateStatus = ->
      $scope.profile.has.allergies.entered = new Date().toISOString()
      _.map $scope.types, (type) ->
        thisType = $scope.refAllergies[type.value]
        if not thisType or not thisType.length
          return if _.isUndefined $scope.profile.has.allergies[type.value]

    do updateAllergies
    do $scope.backup

    # Whole question completeness
    healthForm.completeFns[$scope.question.id].push ->
      # return false if $scope.question.expired
      if $scope.question?.expiration
        if $scope.profile.has.allergies?.entered
          return false if $scope.question.expiration > $scope.profile.has.allergies.entered
        else return false
      # If they haven't answered for every type return false
      return false unless _.every $scope.types, (t) -> $scope.profile.has.allergies[t.value]

      # If they don't have any allergies they're complete
      if (_.every $scope.types, (t) -> $scope.profile.has.allergies[t.value] is "false")
        return true

      # If they've answered yes + have no allergies for that return false
      filledOut = true
      _.each $scope.types, (type) ->
        allergyType = $scope.refAllergies[type.value]
        if $scope.profile.has.allergies[type.value] is "true"
          if not allergyType or not allergyType.length then filledOut = false

      if not filledOut then return false
      if not $scope.activeAllergies.length then return false
      return _.every $scope.activeAllergies, (a) -> a.name and a.type and a.reaction and _.has(a, "anaphylaxis")

    do healthForm.checkComplete[$scope.question.id]

    checkIncompletes = (newHas, oldHas) ->
      # find out if one was just answered with a yes
      _.each $scope.types, (t) ->
        return unless $scope.profile.has.allergies[t.value]
        if newHas[t.value] is "true" and (oldHas[t.value] is "false" or oldHas[t.value] is undefined)
          # if the answer has changed, modify the adding and newAllergy to use the new type
          $scope._status.adding = t.value
          $scope.newAllergy = { profileID: $scope.profile.id, type: t.value }
        return if t.value is $scope._status.adding
        return if _.some $scope.activeAllergies, { 'type': t.value }
        # if they've answered yes but haven't saved an allergy
        # for the type that isn't being currently added, switch that value to false
        $scope.profile.has.allergies[t.value] = "false"

    $scope.$watch "allergies", (fresh, stale) ->
      return if Object.is(fresh, stale)
      do updateAllergies
      do updateStatus
      do healthForm.checkComplete[$scope.question.id]
    , true

    # Check complete and add new if they've answered 'yes' and haven't filled one out
    $scope.$watch "profile.has.allergies", (fresh, stale) ->
      do healthForm.checkComplete[$scope.question.id]

      # Check if they switched to 'no' from 'yes' to re-enable adding (bugfix)
      switchedOff = _.some fresh, (value, key) ->
        return true if stale[key] is "true" and fresh[key] is "false"
        false

      if switchedOff
        resetReview()
        $scope._status.adding = ""

      _.map $scope.types, (type) ->
        if fresh[type.value] is "false" and stale[type.value] is "true"
          _.map $scope.refAllergies[type.value], (allergy) ->
            $scope.removeAllergy(allergy)
          do updateAllergies

      _.each $scope.types, (type) ->
        if fresh[type.value] isnt "true" then return
        these = $scope.refAllergies[type.value]
        these ?= []
        if not these.length
          $scope.addAllergy type.value
          $scope._status.adding = type.value
      checkIncompletes fresh, stale
    , true

    # Adding/Removing Allergies
    resetNewAllergy = -> $scope.newAllergy = { profileID: $scope.profile.id }
    do resetNewAllergy

    $scope.addAllergy = (type) ->
      $scope.newAllergy.type = type
      $scope._status.adding = type

    $scope.cancel = ->
      type = $scope._status.adding
      $scope._status.adding = ""
      do resetNewAllergy
      if not $scope.refAllergies[type]?.length
        $scope.profile.has.allergies[type] = "false"

    $scope.cancelEdit = (allergy) ->
      _.assign allergy, allergy.backup
      do updateAllergies
      do updateStatus
      do $scope.backup
      $scope._status.editing = null

    $scope.saveAllergy = ->
      $scope.allergies.push $scope.newAllergy
      resetReview()
      healthForm.save("allergies", $scope.profile.id, $scope.allergies).success (a) ->
        $scope.allergies = a
        $scope._status.adding = ""
        do resetNewAllergy
        do updateAllergies
        do $scope.backup

    $scope.removeAllergy = (a) ->
      resetReview()
      a.deactivated = new Date()
      healthForm.save("allergies", $scope.profile.id, $scope.allergies).success (a) ->
        $scope.allergies = a
        $scope._status.delete = null

    $scope.deactivateExpired = ->
      updateStatus()
      # deactivates and saves expired allergies when they answer
      if $scope.question.expired and $scope.question.expirationType isnt 'step'
        $scope.allergies.map((a) -> a.deactivated = new Date())
        healthForm.save("allergies", $scope.profile.id, $scope.allergies).success (a) ->
          $scope.allergies = a
          $scope.question.expired = false
