lib.registerState "patient.newProfile",
  url: "/new-profile"
  templateUrl: "states/patient/new-profile/new-profile.state.html"
  resolve:
    title: ($rootScope) ->
      $rootScope._title = "New #{$rootScope.division.patient.singular.capitalize()}"

  controller: ($scope, $stateParams, $state, Profile, session, $http) ->
    $scope.customText = $scope.organization?.properties?.branding?.text?.registration?.newParticipant || "Tell us about your #{$scope.division?.patient?.singular} for #{$scope.organization?.name}"
    $scope.profile ?= new Profile()
    $scope.profile.organizationID = parseInt $stateParams.orgID
    $scope.profile.userID = session.userID
    $scope.sexes = [
      { label: "Female", value: "Female" }
      { label: "Male",  value: "Male" }
    ]
    $scope.today = new Date().toISOString().slice 0, 10
    setTimeout ->
      $("input:enabled:first").focus()

    $scope.searchProfiles = ->
      scopeName = "#{($scope.profile.givenName).toLowerCase()} #{($scope.profile.familyName).toLowerCase()}"
      # make sure you're only checking for profiles in the current org
      currentOrg = _.find(session.access?.organizations, (org) -> +org?.id is +$stateParams.orgID)
      #if it's their first time creating a profile, currentOrg will be undefined
      return $scope.createProfile() if !currentOrg
      orphansPatients = []
      orphansPatients.push currentOrg.orphans
      orphansPatients.push currentOrg.patients
      orphansPatients = _.flatten orphansPatients
      orphansPatients = _.reject orphansPatients, (op) ->
        !op.dob
      orphansPatients = _.map(orphansPatients, (op) ->
        op.dob = op.dob.slice(0, 10)
        op
        )
      $scope.searching = true
      matches = _.filter(orphansPatients, (op) ->
        ((op.name).toLowerCase() is scopeName) and (op.dob.slice(0, 10) is $scope.profile.dob)
        )
      if matches.length > 0
        $scope.searching = false
        $scope.searched = true
        window.swal
          title: "Duplicate Profile"
          text: """
          We noticed the profile you're trying to create matches
          the first and last name and D.O.B of another profile
          already on your account.
          """
          type: "info"
          showCancelButton: true
          cancelButtonText: "Cancel"
          confirmButtonColor: "#5CA100"
          confirmButtonText: "Go to existing profile"
          closeOnConfirm: true
          allowOutsideClick: false
        , (goToProfile) ->
          return unless goToProfile
          $state.go 'patient.profile', {profileID: matches[0].id}, {reload: true}

      else $scope.createProfile()

    $scope.createProfile = ->
      $scope.searching = false
      $scope.searched = true
      $scope.profile.givenName = _.capitalize(($scope.profile.givenName).toLowerCase())
      $scope.profile.familyName = _.capitalize(($scope.profile.familyName).toLowerCase())
      $scope.creatingNew = true
      $scope.profile.save().then (profile) ->
        $http.get("/api/session?reaccess").then ->
          $state.go "patient.profile.registration", {profileID: profile.id}, {reload: true}
