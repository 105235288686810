angular.module("dn").directive "qtDownload", ->
  templateUrl: "directives/taker/download/qt-download.directive.html"
  restrict: "A"
  scope:
    question: "=qtDownload"
  controller: ($scope, $stateParams, Images) ->
    profileID = $stateParams.profileID
    template  = $scope.question.metadata.template

    $scope.templateFiles = []

    # Download the template for the question
    if $scope.question.metadata.template
      Images.getForProfile(profileID, template, true).then (tpl) ->
        $scope.templateFile = tpl[0]
