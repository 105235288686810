const Resource = angular.injector(['dn']).get('Resource');

angular.module('dn').factory('Registration', function($http, $filter, $rootScope, Event) {

  const Registration = new Resource({
    route: '/api/profiles/:profileID/registrations/:id',
  });

  Registration.deactivateFlow = deactivateReg;

  function deactivateReg (registration) {
    if (registration.properties.cancellationRequested) {
      return deactivateWithCancellationReq(registration);
    } else {
      return finallyDeleteRegistration(registration);
    }
  }

  function deactivateWithCancellationReq (registration) {
    return deleteCancellationReq(registration)
      .then(createAcceptCancellationEvent)
      .then(acceptCancellationForProfile)
      .then(finallyDeleteRegistration);
  }

  function deleteCancellationReq (registration) {
    delete registration.properties.cancellationRequested;
    return new Registration(registration).save();
  }

  function createAcceptCancellationEvent (registration) {
    return new Event({
      profileID: registration.profileID,
      type: 'registration.cancellationAccepted',
      medical: false,
      metadata: {
        group: {
          name: registration.group.name
        }
      }
    }).save().then(() => registration);
  }

  function acceptCancellationForProfile (registration) {
    const { id, profileID, cancellationFee } = registration;
    const acceptURL = `/api/profiles/${profileID}/registrations/${id}/accept-cancellation`;

    return new Promise((resolve, reject) => {
      return $http.get(`/api/profiles/${profileID}/users`).then(({ data }) => {
        const body = {
          division: $rootScope.division.name,
          registration: registration.group.name,
          to: data.reduce((acc, { deactivated, profileUser, email }) => {
            if (!deactivated && profileUser && !profileUser.deactivated) {
              acc.push(email);
            }
            return acc;
          }, [])
        };
        if (cancellationFee) {
          body.fee = $filter('currency')(cancellationFee / 100);
        }
        $http.post(acceptURL, body).then(() => {
          return resolve(registration);
        }).catch((error) => {
          return reject(error);
        });
      });
    });
  }

  function finallyDeleteRegistration (registration) {
    let url = `/api/profiles/${registration.profileID}/registrations/${registration.id}`;
    if (registration.cancellationFee) {
      url = `${url}?fee=${registration.cancellationFee}`;
    }
    return $http.delete(url);
  }

  return Registration;
});
