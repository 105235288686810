lib.registerState "manager.settings.general.notifications",
  url: "/notifications"
  templateUrl: "states/manager/settings/general/notifications/notifications.state.html"
  controller: ($scope, organization, $filter, session) ->

    NOTIFICATION_TYPES = [
      'missingProfilePhoto',
      'newRegistration',
      'waitlistAdded',
      'waitlistAccepted',
      'newCharge',
      'paymentReceived',
      'failedPaymentPlan',
      'balanceDue',
      'travelProtectionReminder',
      'trustedContactReminder',
      'labelsReminder'
    ]

    $scope.group.properties.notifications ?= {}

    NOTIFICATION_TYPES.forEach (type) ->
      $scope.group.properties.notifications[type] ?= {}

    daysAllowed = 60
    $scope.reminderChoices = [{ label: "Off", value: "0" }]

    _.times daysAllowed, (day) ->
      $scope.reminderChoices.push { label: (day + 1).toString(), value: (day + 1).toString() }
      return

    $scope.reminderChoices.push { label: "180", value: "180" }
    $scope.reminderChoices.push { label: "Only Invite", value: "365" }

    $scope.subGroupProvider = window.lib.isSubGroupProvider session, organization

    editPermissions = $filter('permissionVisible')({ settings_notifications: 'edit' })

    $scope.canEdit = editPermissions && !$scope.subGroupProvider
    $scope.cannotEdit = !editPermissions || $scope.subGroupProvider

    # For reasons that are unclear, it is necessary to use this to set the oper permissions on the Travel Protection Reminder input
    # Using a straight-up permissions="{{ { access: 'oper' } }}" check causes the first of the MDE inputs on the page
    # to render a bunch of dupes of that input for any non-oper user
    # This may have to do with race conditions with timeouts being used in the MDE, but fixing MDE is beyond the scope of this feature
    $scope.isOper = session.access.operator

    $scope.showLabelsSection = $scope.group.properties.enableOliversLabels && $scope.isOper
