lib.registerState "oper.finances",
  url: "/finances"
  templateUrl: "states/oper/finances/finances.state.html"
  resolve:
    balances: ($http) ->
      $http.get("/api/operator/payable-orgs").then (result) ->
        result.data
  controller: ($scope, balances, $http) ->
    $scope.dateSelected = false
    $scope.balanceDate =
      date: moment().format('YYYY-MM-DD')
      displayedDate: null

    isTodaysDate = () ->
      return $scope.balanceDate.displayedDate is moment().format('YYYY-MM-DD')

    updateBalances = ->
      $scope.balances = _.sortBy balances, "name"
      _.map $scope.balances, (b) -> _.map b.crebits, (c) -> c.group = b
      $scope.crebits = _.flattenDeep _.map balances, "crebits"

    $scope.generateBalances = () ->
      $scope.loading = true
      updateBalances()
      $scope.dateSelected = true
      $scope.balanceDate.displayedDate = $scope.balanceDate.date || moment().format('YYYY-MM-DD')

      async.eachLimit $scope.balances, 10, (org, afterGetBalance) ->
        return $http.get(
          "/api/operator/#{org.id}/get-balance",
          {
            params: {
              date: $scope.balanceDate.date || moment().format('YYYY-MM-DD')
            },
            cache: true
          }
        ).then (result) ->
          org.balance = result.data.balance
          org.credits = result.data.credits
          org.debits  = result.data.debits
          org.lastPayout = result.data.lastPayout
          return afterGetBalance();
      , (err) ->
        $scope.loading = false

    $scope.sortBy = { value: "name", asc: true, cell: "Name" }

    $scope.sort = (value, cell) ->
      if $scope.sortBy.value is value
        return $scope.sortBy.asc = !$scope.sortBy.asc
      $scope.sortBy = { value: value, asc: true, cell: cell }

    $scope.$watch "sortBy", (sort) ->
      $scope.balances = _.sortBy $scope.balances, "name"
      if sort.asc then $scope.balances = _.sortBy $scope.balances, sort.value
      else $scope.balances = _.sortBy($scope.balances, sort.value).reverse()
    , true

    $scope.total =
      balance: ->
        _.reduce $scope.balances, (sum, group) ->
          return sum if group._hide
          sum += group.balance
        , 0
      debits: ->
        _.reduce $scope.balances, (sum, group) ->
          return sum if group._hide
          sum += group.debits
        , 0
      credits: ->
        _.reduce $scope.balances, (sum, group) ->
          return sum if group._hide
          sum += group.credits
        , 0

    $scope.balanceTitle = (org) ->
      if !isTodaysDate() then return "Cannot issue payouts unless date is set for today"
      if !org.balance then return "No balance to payout"
      if !org.paymentMethodID then return "No method to payout with"
      return "Initiate payout"

    $scope.balanceOrg = (org) ->
      return unless isTodaysDate()
      return unless org.balance
      return unless org.paymentMethodID
      swal
        title: do ->
          return "Payout" if org.balance > 0
          "Reverse Payout"
        text: "
          Initiate a
          #{if org.balance > 0 then "Regular" else "Reverse"}
          Payout for \n#{org.name}\n
          in the amount of $#{Math.abs(org.balance / 100)}?
        "
        showCancelButton: true
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      , (initiatePayout) ->
        return unless initiatePayout
        $http.post("/api/operator/manual-payout", org)
          .success ->
            swal "Finance!", "The accountants will be pleased!", "success"
            org.balance = 0
          .error (err) ->
            swal "Something went wrong :-/", "#{err}\n\nCheck the logs!", "error"

    $scope.exportCsv = ->
      csvString = "Organization ID, Name, Active, Payment Method, Last Payout, Debits, Credits, Balance\n"
      _.map $scope.balances, (org) ->
        csvString += org.id.toString() + ","
        csvString += "\"" + org.name + "\","
        csvString += if org.deactivated then "No," else "Yes,"
        csvString += if org.paymentMethodID then "Yes," else "No,"
        csvString += org.lastPayout + ","
        csvString += (org.debits / 100).toString() + ","
        csvString += (org.credits / 100).toString() + ","
        csvString += (org.balance / 100).toString() + "\n"

      csvData = new Blob([csvString], {type: "text/csv;charset=utf-8;"})
      fileName = "balances.csv"

      if window.navigator.msSaveOrOpenBlob
        # Blob support for edge
        window.navigator.msSaveOrOpenBlob(csvData, filename)
      else
        csvURL = window.URL.createObjectURL(csvData)
        a = document.createElement('a')
        # firefox works better with an attached anchor tag
        document.getElementById("content").appendChild(a)
        a.href = csvURL
        a.setAttribute("download", fileName)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(csvURL)
        a = csvURL = null

      csvData = csvString = null
