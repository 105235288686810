Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "Profile", ($http) ->
  Profile = new Resource(
    route: "/api/profiles/:id?regenerate"
    schema:
      givenName:
        type: "text"
        required: true

      familyName:
        type: "text"
        required: true

      middleName:
        type: "text"

      dob:
        type: "date"

      sex:
        type: "text"

      organizationID:
        type: "integer"

      questionnaire:
        type: "json"

      properties:
        type: "json"

      digest:
        type: "boolean"

      current:
        type: "boolean"

      newQuestionnaire:
        type: "json"

      tokens:
        type: "integer"
  )
  Profile
