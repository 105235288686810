'use strict';

angular.module('dn').service('AttendanceData', (() => {
  const ERR_TAG                 = '[AttendanceData]';
  const CHECK_IN_TYPE           = 'Check In';
  const CHECK_OUT_TYPE          = 'Check Out';
  const REQUIRED_CHECK_IN       = [
    'profile_id',
    'check_in_time',
    'check_in_provider_user_id',
    'timeline'
  ];
  const REQUIRED_CHECK_OUT      = [
    'check_out_time',
    'check_out_provider_user_id',
  ];

  const _store = {
    // All the profiles currently loaded keyed by ID
    profiles: [],
    // All the trustedContacts currently loaded keyed by primary email
    trustedContacts: {},
    // All the notes currently loaded keyed by attendance_record_id
    notes: {},
    selected: {
      // There can only ever be a single attendanceRecord selected at a time.
      attendanceRecord: {}
    },
    // This is everything we are creating or editing at the time
    tmp: {
      // Attendance records that are new or under edit, keyed by profile ID
      // There can only be one record per profile being edited/updated at a time
      attendanceRecords: {},
      notes: {},
    },
    // Avoid computing group tag relationships every time we need them
    groupTags: {},
    // Architecture we can use to handle filter conditions
    // Not included in UI since that's supposed to be for local state.
    filters: {
      checkInStatus: null
    },
    // Arbitrary info that calling states and directives can use to store UI state
    ui: {},
    _initialUi: {},
  };

  function timeStampForCompare(timestamp) {
    const timeStampRegEx = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;
    let timeStampMatch;
    if (timestamp) {
      timeStampMatch = timestamp.match(timeStampRegEx);
    }
    return timeStampMatch ? timeStampMatch[0] : false;
  }

  function timeStampsDifferent(time1, time2) {
    return timeStampForCompare(time1) !== timeStampForCompare(time2);
  }

  function iterablePropertyChanged({
    filteredNewRecord,
    filteredOldRecord,
    timeFields
  }, field) {

    // if a deletion is unsaved or there's no corresponding oldRecord
    if (!filteredOldRecord) return true;

    // iterate over the relevant field in the newRecord, which is an array
    return filteredNewRecord[field].some((object, index) => {
      // and take each element of the array, looking at its keys/vals
      return Object.entries(object).some(([ propertyName, propertyValue ]) => {
        // finding the corresponding oldRecord property
        let oldPropertyValue;
        if (filteredOldRecord[field] && filteredOldRecord[field][index]) {
          oldPropertyValue = filteredOldRecord[field][index][propertyName];
        }
        // and comparing the two properties (which might be times)
        return timeFields.includes(propertyName)
          ? timeStampsDifferent(propertyValue, oldPropertyValue)
          : !_.isEqual(propertyValue, oldPropertyValue);
      });
    });
  }

  function typeCoerceIDsToNum(filteredNewRecord) {
    return [
      'check_in_provider_user_id',
      'check_out_provider_user_id',
      'check_in_trusted_contact_id',
      'check_out_trusted_contact_id'
    ].reduce((filteredNewRecord, idPropertyName) => {
      if (filteredNewRecord[idPropertyName])
        filteredNewRecord[idPropertyName] = +filteredNewRecord[idPropertyName];
      else filteredNewRecord[idPropertyName] = null;
      return filteredNewRecord;
    }, filteredNewRecord);
  }

  return class AttendanceData {
    constructor($http, $rootScope, AttendanceRecord, ContactData, Note) {
      this.$http = $http;
      this.$rootScope = $rootScope;
      this.AttendanceRecord = AttendanceRecord;
      this.ContactData = ContactData;
      this.Note = Note;
    }

    _rawToResource(target) {
      if (!(target instanceof this.AttendanceRecord)) target = new this.AttendanceRecord(target);
      return target;
    }

    _rawNoteToResource(target) {
      if (!(target instanceof this.Note)) target = new this.Note(target);
      return target;
    }

    _findProfile(profileId) {
      return _store.profiles.find(p => p.id === parseInt(profileId));
    }

    _relevantChangeableFields() {
      const relevantFields = {
        generalFields:  [
          'check_in_provider_user_id',
          'check_in_trusted_contact_id',
          'check_out_provider_user_id',
          'check_out_trusted_contact_id',
          'created',
          'deactivated',
          'id',
          'orgID',
          'profile_id',
          'updated'
        ],
        arrayFields: [
          'notes',
          'timeline'
        ],
        timeFields: [
          'check_in_time',
          'check_out_time',
          'time'
        ],
      };

      const combinedFields = [
        ...relevantFields.generalFields,
        ...relevantFields.arrayFields,
        ...relevantFields.timeFields
      ];

      relevantFields.combinedFields = combinedFields;

      return relevantFields;
    }

    /**
     * A property-first deep equality comparison of two attendance records to determine whether
     * any change has been made
     *
     * @param {Object} newRecord A new attendance record
     * @param {Object} oldRecord An old attendance record
     * @param {Number} deletingNotes A length of an array holding notes to delete. Here it's used
     *   to check for the existence of any notes queued for deletion that haven't yet been
     *   deleted, signifying an unsaved change
     * @returns {Boolean} True if there's a difference between the two records, false otherwise
     */
    _changesMade(newRecord, oldRecord, deletingNotes) {
      if (deletingNotes) return true;

      /**
       * @type {Object}
       * @property {Array} generalFields Fields that require no special handling
       * @property {Array} arrayFields Fields that require further iteration, such as timelines
       * @property {Array} timeFields Fields that require time comparison. We let users input
       *   times up to the minute, but we save it with millisecond granularity, meaning there
       *   will always be a difference. So, times are handled specially
       * @property {Array} combinedFields All the fields combined into one array
       */
      const {
        generalFields,
        arrayFields,
        timeFields,
        combinedFields,
      } = this._relevantChangeableFields();

      // Filters and converts data for records to have the same properties
      const filteredNewRecord  =  typeCoerceIDsToNum(_.pick(newRecord, combinedFields));
      const filteredOldRecord  = _.pick(oldRecord, combinedFields);
      filteredOldRecord.notes = filteredOldRecord.notes || [];

      const iterableConfig = {
        filteredNewRecord,
        filteredOldRecord,
        timeFields
      };

      /*
       * If at least one property (represented as a field in the combined fields) is different
       * between the old and new records, iteration stops and returns the boolean true
       * */

      return combinedFields.some(field => {
        return (generalFields.includes(field) && !_.isEqual(filteredNewRecord[field], filteredOldRecord[field]))
          || (arrayFields.includes(field) && iterablePropertyChanged(iterableConfig, field))
          || (timeFields.includes(field) && timeStampsDifferent(filteredNewRecord[field], filteredOldRecord[field]));
      });

    }

    get _store() {
      // _cloneDeep because ui may have functions on it
      return _.cloneDeep(_store);
    }

    /** ************************/
    /* CHECK IN/OUT FUNCTIONS */
    /** ************************/

    initTmpRecord(record) {
      if (!record || !record.profile_id) {
        throw new Error(`${ERR_TAG}[initTmpRecord] Must supply an object with at least a "profile_id" value.`);
      }
      const current = JSON.parse(JSON.stringify(record));
      const clean = this.AttendanceRecord.blankSchema();
      _store.tmp.attendanceRecords[record.profile_id] = Object.assign(clean, current);
      return _store.tmp.attendanceRecords[record.profile_id];
    }

    initTmpNote(note) {
      if (!note || !note.profileID) {
        throw new Error(`${ERR_TAG}[initTmpNote] Must supply an object with at least a "profileID" value.`);
      }
      const current = JSON.parse(JSON.stringify(note));
      const clean = this.Note.blankSchema();
      _store.tmp.notes[note.profileID] = Object.assign(clean, current);
      return _store.tmp.notes[note.profileID];
    }

    clearTmpRecords() {
      _store.tmp.attendanceRecords = {};
    }

    clearTmpNotes() {
      _store.tmp.notes = {};
    }

    deleteTmpRecord(profileID) {
      delete _store.tmp.attendanceRecords[profileID];
    }

    deleteTmpNote(profileID) {
      delete _store.tmp.notes[profileID];
    }

    // We will not require admin override because it conflicts with previous audited records and blocks regular users
    // from saving valid changes.
    validateClientRecord(actionType, clientRecord) {
      // Discern which fields we need to check
      let requiredFields = REQUIRED_CHECK_IN;
      // Check out validations include all check in validations
      if (actionType === CHECK_OUT_TYPE) requiredFields = requiredFields.concat(REQUIRED_CHECK_OUT);

      // Validate fields and, if necessary, throw an informative error message
      let msg;
      requiredFields.every((field) => {
        if (!clientRecord[field]) msg = `Missing required field "${field}" on record ${clientRecord}`;
        return !!clientRecord[field];
      });

      if (msg) throw TypeError(`${ERR_TAG}[validateClientRecord] ${msg}`);
      return true;
    }

    createTimelineEvent(type, clientRecord, user, time) {
      // prevents a bug where a single record could have multiple check ins/outs on the same timeline.
      if ((type === CHECK_IN_TYPE || type === CHECK_OUT_TYPE) && clientRecord.timeline.some(te => te.type === type)) {
        return;
      }

      clientRecord.timeline.push({
        type,
        time,
        user: `${user.givenName} ${user.familyName} (${user.email})`
      });
    }

    checkIn(user, providerID) {
      // Make sure we have required params
      if (!(user && user.email)) {
        throw Error(`${ERR_TAG}[checkIn] Must supply a provider user object.`);
      }
      return this.makeAttendanceRequest(user, providerID, CHECK_IN_TYPE);
    }

    checkOut(user, providerID) {
      // Make sure we have required params
      if (!(user && user.email)) {
        throw Error(`${ERR_TAG}[checkOut] Must supply a provider user object.`);
      }
      return this.makeAttendanceRequest(user, providerID, CHECK_OUT_TYPE);
    }

    takeAttendance(user, providerID, actionType) {
      if (!actionType) actionType = 'Attendance';
      // Make sure we have required params
      if (!(user && user.email)) {
        throw Error(`${ERR_TAG}[takeAttendance] Must supply a provider user object.`);
      }
      return this.makeAttendanceRequest(user, providerID, actionType);
    }

    upsertAttendanceRecord(newRecord) {
      newRecord = this._rawToResource(newRecord);
      const storeProfile = this._findProfile(newRecord.profile_id);
      storeProfile.attendanceRecords = storeProfile.attendanceRecords || [];
      let storeRecord = storeProfile.attendanceRecords.find(existing => existing.id === newRecord.id);
      // If we can, replace the record with the matching ID
      if (storeRecord) {
        Object.assign(storeRecord, newRecord);
        // If we can't, just push it in
      } else {
        storeProfile.attendanceRecords.push(newRecord);
        storeRecord = storeProfile.attendanceRecords[storeProfile.attendanceRecords.length - 1];
      }
      // Need orgID to save these properly if you're not an oper.
      // We have to guard $rootScope like this because transitions back to `whereTo` cause problems if
      // service functions are still running (no org in whereTo)
      if (!storeRecord.orgID) {
        storeRecord.orgID = (this.$rootScope.organization || {}).id;
      }
      if (!storeRecord.hasOwnProperty('notes')) {
        Object.defineProperty(storeRecord, 'notes', {
          enumerable: false,
          writeable: false,
          configurable: false,
          get() {
            // In this case, `this` refers to the storeRecord
            return _store.notes[this.id];
          }
        });
      }
      return storeRecord;
    }

    upsertNote(note) {
      const newNote = this._rawNoteToResource(note);
      _store.notes[note.attendance_record_id] = _store.notes[note.attendance_record_id] || [];
      let storeNote = _store.notes[note.attendance_record_id].find(existing => existing.id === note.id);
      // If we can, replace the note with the matching ID
      if (storeNote) {
        Object.assign(storeNote, newNote);
        // If we can't, just push it in
      } else {
        _store.notes[note.attendance_record_id].push(newNote);
        storeNote = newNote;
      }

      return storeNote;
    }

    processTmpRecord(user, actionType, clientRecord, time = new Date().toISOString()) {
      try {
        if (actionType === CHECK_IN_TYPE || actionType === CHECK_OUT_TYPE) {
          const short = actionType === CHECK_IN_TYPE ? 'in' : 'out';
          // TODO: There's a very real chance we won't actually have access to tcid in parctice. Consider this: we
          // hit our route to get back the _profiles we're trusted for_ but that doesn't include the copy of my
          // record that matches those profiles. That doesn't work with our paradigm.
          // This is totally possible, too, because there's no guarantee all the trusted contacts will be loaded by
          // collections. We have to make a call to ensure we have all the profiles available for check-in when we
          // do a load for a multi-check-in. Can't be sure the collections filters haven't stripped out some perfectly
          // viable option for check-in.
          const tcid = this.selectedContact.id;
          clientRecord[`check_${short}_time`] = time;
          clientRecord[`check_${short}_provider_user_id`] = user.id;
          clientRecord[`check_${short}_trusted_contact_id`] = clientRecord[`check_${short}_trusted_contact_id`] || tcid;
        }
        // Make sure we have all the fields
        this.validateClientRecord(actionType, clientRecord);
        // Create the appropriate timeline event
        this.createTimelineEvent(actionType, clientRecord, user, time);
        return clientRecord;
      } catch (error) {
        return console.error(error);
      }
    }

    /**
     * Adds the temp notes to the temp attendance records so they can be saved
     * @param {User} user
     * @param {number} providerID
     */
    applyTmpNotes(user, providerID) {
      const tmpRecords = Object.values(_store.tmp.attendanceRecords);

      tmpRecords.forEach((record) => {
        // Don't want to resubmit the existing note if one existed
        delete record.notes;

        const profileNote = this.getTmpNote(record.profile_id);
        if (profileNote && profileNote.body) {
          profileNote.profile_id = null; // will add appropriate IDs in the API
          profileNote.userID = user.id;
          profileNote.providerID = providerID;
          record.notes = [profileNote];
        }
      });
      this.clearTmpNotes();
    }

    makeAttendanceRequest(user, providerID, actionType) {
      // Everything we've created or edited will be on _store.tmp.attendanceRecords
      const tmpRecords = Object.values(_store.tmp.attendanceRecords);
      const tmpNotes = Object.values(_store.tmp.notes);

      if (!tmpRecords.length) return console.error(`${ERR_TAG}[makeAttendanceRequest] No records to save.`);
      if (tmpNotes.length) {
        this.applyTmpNotes(user, providerID);
      }

      if (tmpRecords.length === 1) {
        // Handling whatever comes next is up to the caller
        return this._rawToResource(this.processTmpRecord(user, actionType, tmpRecords[0]))
          .save()
          .then((recordFromDb) => {
            (recordFromDb.savedNotes || []).forEach(note => this.upsertNote(note));
            delete recordFromDb.savedRecord.notes;
            return this.upsertAttendanceRecord(recordFromDb.savedRecord);
          })
          .catch((error) => {
            console.error(error);
            if (error.status === 409) {
              error.message = 'Potential Duplicate Attendance Record: Please Refresh and Try Again.';
            }
            return Promise.reject(error);
          });
      } else {
        return this.bulkAttendanceRequest(user, providerID, actionType, tmpRecords);
      }
    }

    manageAuditRecord(forcedSave) {
      // Everything we've created or edited will be on _store.tmp.attendanceRecords
      const tmpRecords = Object.values(_store.tmp.attendanceRecords);
      const recordsToSave = [];

      if (!tmpRecords.length) return console.error(`${ERR_TAG}[makeAttendanceRequest] No records to save.`);

      if (!forcedSave) {
        if (tmpRecords[0].id) {
          const oldRecord = this._findProfile(tmpRecords[0].profile_id).attendanceRecords.find(old => old.id === tmpRecords[0].id);
          if (this._changesMade(tmpRecords[0], oldRecord)) recordsToSave.push(tmpRecords[0]);
        } else {
          recordsToSave.push(tmpRecords[0]);
        }

        // return early if we've got no records to save
        if (!recordsToSave.length) return;
      }

      // Handling whatever comes next is up to the caller
      return this._rawToResource(tmpRecords[0])
        .save()
        .then((recordFromDb) => {
          window.flash('Record was saved succesfully');
          (recordFromDb.savedNotes || []).forEach(note => this.upsertNote(note));
          delete recordFromDb.savedRecord.notes;
          return this.upsertAttendanceRecord(recordFromDb.savedRecord);
        })
        .catch((error) => {
          console.error(error);
          return Promise.reject(error);
        });
    };

    deactivateAuditRecord(record) {
      // Find the profile that matches
      const storeProfile = this._findProfile(record.profile_id);
      if (!storeProfile) {
        return Promise.reject(Error(`[deactivateAuditRecord] No profile found for record: ${JSON.stringify(record)}`));
      }

      // Find the record on the profile
      const existingRecord = (storeProfile.attendanceRecords || []).find(existing => {
        return existing.id === record.id;
      });

      // If we didn't find it, "throw" an error
      if (!existingRecord) {
        return Promise.reject(Error(`[deactivateAuditRecord] No matching record found in store. Provided record: ${JSON.stringify(record)}`));
      }

      // Deactivate the record on the BE, then filter it out of the FE
      return record.destroy().then(() => {
        storeProfile.attendanceRecords = storeProfile.attendanceRecords.filter(existing => existing.id !== record.id);
      });
    }

    deactivateNotes(notes) {
      return notes.forEach(note => {
        const recordNotes = _store.notes[note.attendance_record_id] || [];
        const existingNote = recordNotes.find(existing => existing.id === note.id);


        // If we didn't find it, "throw" an error
        if (!existingNote) {
          return Promise.reject(Error(`[deactivateNotes] No matching note found in store. Provided note: ${JSON.stringify(note)}`));
        }

        // Deactivate the note on the BE, then filter it out of the store
        return note.destroy().then(() => {
          _store.notes = recordNotes.filter(existing => existing.id !== note.id);
        });
      });
    }

    // Take in an array of newly-created attendance objects and post them to the server
    bulkAttendanceRequest(user, providerID, actionType, tmpRecords) {
      // Everything will have the same timestamp, even though it's bulk
      let recordsToSave;
      try {
        const time = new Date().toISOString();
        recordsToSave = tmpRecords.reduce((recordsToSave, clientRecord) => {
          const processedRecord = this.processTmpRecord(user, actionType, clientRecord, time);
          if (processedRecord) recordsToSave.push(processedRecord);
          return recordsToSave;
        }, []);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
      // Make the request, then process the result
      const method = actionType === CHECK_IN_TYPE ? 'POST' : 'PUT';
      const url = `/api/organizations/${this.$rootScope.organization.id}/attendance-records`;

      return this.$http({ method, url, data: recordsToSave}).then(({ data }) => {
        if (data.savedRecords && data.savedRecords.length) {
          // Scan through existing records
          data.savedRecords.map(record => {
            delete record.notes;
            return this.upsertAttendanceRecord(record);
          });
          if (data.savedNotes) {
            (data.savedNotes || []).forEach(note => this.upsertNote(note));
          }
        }
      }).then((recordsFromStore) => {
        return recordsFromStore;
      }).catch((err) => {
        // Handle potential duplicate errors in a different way
        if (err.status === 409) {
          err.message = `${ERR_TAG}[bulkAttendanceRequest] Bulk request failed: ${err.data.message}`;
          return Promise.reject(err);
        }
        // All this extra error handling bidness is because we're interacting with the BE here and
        // can't be totally sure what we're getting back in the form of an error.
        if (!err instanceof Error) {
          if (typeof err === 'string') {
            err = new Error(err);
          } else {
            console.error(`${ERR_TAG}[bulkAttendanceRequest] Something strange is afoot:`, err);
            err = new Error('Unknown error occurred.');
          }
        }
        err.message = `${ERR_TAG}[bulkAttendanceRequest] Bulk request failed: ${err.message}`;
        console.error(err.message, err.stack.substring(0, 400));
        return Promise.reject(err);
      });
    }

    notifyChangesMade(deletingNotes) {
      const tmpRecords = Object.values(_store.tmp.attendanceRecords);
      if (tmpRecords[0].id) {
        const oldRecord = this._findProfile(tmpRecords[0].profile_id).attendanceRecords.find(old => old.id === tmpRecords[0].id);
        return this._changesMade(tmpRecords[0], oldRecord, deletingNotes);
      // Check if new timeline has been created, and the event has been saved to the store, but changes have not been saved to the database
      } else if (tmpRecords[0].timeline && tmpRecords[0].timeline.length) {
        return true;
      }
    }

    /** *****************************************/
    /* DATA LOADING AND MANIPULATION FUNCTIONS */
    /** *****************************************/

    // Set or update the store's profile
    processProfileCollection(collection, updateTimestamp) {
      if (!collection || !collection.profiles || !collection.profiles.id) {
        const idTag = `Profile ID: ${((collection || {}).profiles || {}).id}`;
        const msg = `${ERR_TAG}[processProfileCollection] Missing or malformed collection. ${idTag}`;
        return console.error(ReferenceError(msg));
      }
      const { profiles, attendance_records, trusted_contacts, profileTags, notes } = collection;

      const existingProfile = this._findProfile(profiles.id);
      // If the profile already exists, update the information.
      if (existingProfile) {
        Object.assign(existingProfile, profiles);
      // Otherwise, just push it into the profiles array
      } else {
        _store.profiles.push(profiles);
      }
      // For ease and clarity, assign the store's reference to our collection to a variable
      const storeProfile = existingProfile || _store.profiles[_store.profiles.length - 1];
      // Load records into the store. The upsert takes care of assigning them to the profiles.
      (attendance_records || []).forEach((record) => {
        // Use existing methods wherever possible to control data flow tightly.
        this.upsertAttendanceRecord(record);
      });
      (notes || []).forEach((record) => {
        // Use existing methods wherever possible to control data flow tightly.
        this.upsertNote(record);
      });
      // Parse the profile tags
      storeProfile.tags = this.processProfileTags(profileTags || []);
      // Set or update the _store's trusted contact.
      // Don't set the updated timestamp here because that works differently for loading from a
      // collection. We can't be sure we have _all_ of a contact's associated profiles.
      this.ContactData.addToStore(trusted_contacts || []);
      storeProfile.trustedContacts = this.ContactData.findContactsByProfile(storeProfile.id);

      // If they don't already have these utility properties defined, set them
      if (!storeProfile.hasOwnProperty('mostRecentRecord')) {
        Object.defineProperty(storeProfile, 'mostRecentRecord', {
          enumerable: false,
          writeable: false,
          configurable: false,
          get() {
            // In this case, `this` refers to the storeProfile
            return _(this.attendanceRecords).orderBy(['check_in_time'], ['desc']).first();
          }
        });
      }
      if (!storeProfile.hasOwnProperty('checkedIn')) {
        Object.defineProperty(storeProfile, 'checkedIn', {
          enumerable: false,
          writeable: false,
          configurable: false,
          get() {
            // In this case, `this` refers to the storeProfile
            return !!(this.mostRecentRecord || {}).checkedIn;
          }
        });
      }
      if (!storeProfile.hasOwnProperty('inAttendance')) {
        Object.defineProperty(storeProfile, 'inAttendance', {
          enumerable: false,
          writeable: false,
          configurable: true,
          get() {
            const { attendanceType } = _store.ui;
            if (!attendanceType || !storeProfile.checkedIn) return false;
            const { timeline } = storeProfile.mostRecentRecord;
            return attendanceType === timeline[timeline.length - 1].type;
          }
        });
      }

      // Finally, set the updated timestamp on the profile and return it
      storeProfile.lastUpdated = updateTimestamp;
      return storeProfile;
    }

    processProfileTags(profileTags) {
      // If the org doesn't have tags, don't try to process anything.
      if (!Object.keys(_store.groupTags).length) return [];
      return profileTags.reduce((parsedTags, profileTag) => {
        try {
          const { tagID, optionID, value } = profileTag;
          if (!_store.groupTags[tagID]) throw Error('No matching group tag found.');
          const tagKey = _store.groupTags[tagID].value;
          const tagVal = optionID ? (_store.groupTags[tagID].options || {})[optionID] : value;
          if (!tagKey || !tagVal) throw Error('Missing parsed key or value.');
          parsedTags.push(`${tagKey}: ${tagVal}`);
        } catch (err) {
          console.error(`${ERR_TAG}[processProfileTags] ${err.message} profileTag:`, profileTag);
        }
        return parsedTags;
      }, []);
    }

    processGroupTags() {
      // We have to guard $rootScope like this because transitions back to `whereTo` cause problems if
      // service functions are still running (no org in whereTo)
      const groupTags = (this.$rootScope.organization || {}).tags || [];
      _store.groupTags = groupTags.reduce((tagMap, { id, parentID, value}) => {
        try {
          // mainKey will be the parentID (for options) or id (for parent tags)
          const mainKey = parentID || id;
          // Make sure we have an object to represent the tag
          tagMap[mainKey] = tagMap[mainKey] || {};
          // Set the parent tag's value if it's the top dawg
          if (!parentID) tagMap[mainKey].value = value;
          // Make sure we have an options object
          tagMap[mainKey].options = tagMap[mainKey].options || {};
          // Key option tag values directly to their ids
          if (parentID) tagMap[mainKey].options[id] = value;
        } catch (err) {
          console.error(`${ERR_TAG}[processGroupTags]`, err);
        }
        return tagMap;
      }, {});
    }

    clearGroupTags() {
      _store.groupTags = {};
    }

    // From a collections return, parse the data and update the _store
    loadFromCollections(collections) {
      // If we don't have any data to process, log an error and return early
      if (!Array.isArray(collections)) {
        return console.error(ReferenceError(`${ERR_TAG}[loadFromCollections] Supplied data are not a collections array.`));
      }
      // If we haven't actually processed groupTags yet, do so.
      // We have to guard $rootScope like this because transitions back to `whereTo` cause problems if
      // service functions are still running (no org in whereTo)
      if (!Object.keys(_store.groupTags).length && ((this.$rootScope.organization || {}).tags || []).length) {
        this.processGroupTags();
      }
      // We loaded all this data at the same time, so stamp it with the same time
      const updateTimestamp = new Date();
      // Time to create _store records for everything!
      collections.forEach(collection => this.processProfileCollection(collection, updateTimestamp));
    }

    setProfileSelected(profileID, optionalSetValue = true) {
      (this._findProfile(profileID) || {}).selected = !!optionalSetValue;
    }

    toggleProfileSelected(profileID) {
      const target = this._findProfile(profileID) || {};
      target.selected = !target.selected;
    }

    clearAllProfiles() {
      _store.profiles = [];
    }

    deselectAllProfiles() {
      this.selectedProfiles.forEach(profile => profile.selected = false);
    }

    getProfile(profileID) {
      return this._findProfile(profileID);
    }

    getTmpNote(profileID) {
      return _store.tmp.notes[profileID];
    }

    fetchMoreParticipants(profile) {
      return this.fetchMoreTrustees().then((participants) => {
        return participants.filter((participant) => {
          return participant.id !== profile.id && participant.checkedIn === profile.checkedIn;
        });
      });
    }

    fetchMoreTrustees() {
      return new Promise((resolve, reject) => {
        const contactEmail = this.selectedContact.emails.primary_value;
        const filter = encodeURIComponent(`[registrations].type(is:patient)|phase(is:present)|[trusted_contacts].emails.primary_value(is:${contactEmail})`);
        const collectionsRoute = `/api/organizations/${this.$rootScope.organization.id}/profiles?attendance=true&filters=${filter}`;
        return this.$http.get(collectionsRoute).then(({ data }) => {
          this.loadFromCollections(data);
          resolve(_store.profiles.filter((p) => (p.trustedContacts || []).some((tc) => tc.emails.primary_value === contactEmail)));
        }).catch(reject);
      });
    }

    searchProfiles(term) {
      term = (term || '').toLowerCase();
      _store.profiles.forEach((profile) => {
        if (!`${profile.givenName}${profile.middleName}${profile.familyName}`.toLowerCase().includes(term)) {
          profile.hide = true;
        } else {
          profile.hide = false;
        }
      });
    }

    setFilter(name, value) {
      return _store.filters[name] = value;
    }

    get filteredProfiles() {
      const statusFilter = _store.filters.checkInStatus;
      return _store.profiles.filter((p) => {
        // eslint-disable-next-line eqeqeq
        if (statusFilter != null && statusFilter !== p.checkedIn) return false;
        return !p.hide;
      });
    }

    get selectedProfiles() {
      return _store.profiles.filter(p => p.selected);
    }

    get profiles() {
      return _store.profiles;
    }

    get selectedContactSet() {
      return this.ContactData.selectedContactSet;
    }

    set selectedContactSet(contact = {}) {
      this.ContactData.selectedContactSet = (contact.emails || {}).primary_value;
      return this.ContactData.selectedContactSet;
    }

    get selectedContact() {
      return this.ContactData.selectedContact;
    }

    set selectedContact(contact) {
      this.ContactData.selectedContact = contact;
      return this.ContactData.selectedContact;
    }

    /** **********************/
    /* UI-RELATED FUNCTIONS */
    /** **********************/

    clearStore() {
      this.clearAllProfiles();
      this.clearGroupTags();
      this.ContactData.clearStore();
    }

    get ui() {
      return _store.ui;
    }

    resetUi() {
      Object.assign(_store.ui, _.cloneDeep(_store._initialUi));
    }

    setUiEntry(key, value) {
      const setPath = key.split('.');
      setPath.reduce((storeUi, key, i) => {
        if (i === setPath.length - 1) {
          storeUi[key] = value;
        }
        return storeUi[key];
      }, _store.ui);
    }

    initUi(uiConfig) {
      if (!uiConfig) uiConfig = {};
      _store.ui = _.cloneDeep(uiConfig);
      _store._initialUi = _.cloneDeep(uiConfig);
    }

  };
})());
