Resource = angular.injector(["dn"]).get("Resource")
angular.module("dn").factory "Group", ($http) ->
  Group = new Resource(route: "/api/groups/:id")

  # Return an array of children organized through depth-first search
  Group::$selectableChildren = (includeSelf) ->
    group = this
    childGroups = []
    addChildren = (children, existingPath) ->
      return  unless children
      currentPath = do ->
        return "" unless existingPath?.length
        existingPath + " » "
      _.each children, (c) ->
        childGroups.push
          label: currentPath + c.name
          value: c.id
          phase: c.phase
          show: true
          parents: c.parents

        addChildren c.children, do ->
          return "" if c.parentID is 21436
          currentPath + c.name

    if includeSelf
      addChildren [group], ""
    else
      addChildren group.children
    childGroups

  Group::clearChildSettings = (setting) ->
    group = this
    route = "/api/groups/" + group.id + "/clear-child-setting?setting=" + setting
    $http.put(route, {}).then (result) ->
      result.data

  Group::getCapacity = (afterGet) ->
    $http.get("/api/groups/" + this.id + "/get-capacity").then (result) ->
      return afterGet result.data

  Group::checkCapacity = (profileSex, afterGet) ->
    queryString = if profileSex then "?sex=#{profileSex}" else ""
    $http.get("/api/groups/" + this.id + "/check-capacity#{queryString}").then (result) ->
      return afterGet result.data

  Group::getAddOns = (config, afterGet) ->
    queryParam = if config.all then "?all" else ""
    $http.get("/api/groups/" + this.id + "/add-ons" + queryParam).then (result) ->
      return afterGet result.data

  Group::getCoupons = (config, afterGet) ->
    # Note: ?all does nothing at the time of writing. I included it in case we wanted to mirror addOn behavior
    queryParam = if config.all then "?all" else ""
    $http.get("/api/groups/" + this.id + "/coupons" + queryParam).then (result) ->
      return afterGet result.data

  Group
