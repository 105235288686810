angular.module("dn").directive "qbDimensions", ->
  templateUrl: "directives/builder/modules/dimensions/qb-dimensions.directive.html"
  replace: true
  restrict: "A"
  scope:
    module: "=qbDimensions"
    mode: "@"
  controller: ($scope) ->
    $scope.module.label ?= "Dimensions"
    $scope.module.required ?= true
