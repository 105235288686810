angular.module("dn").directive "qbSelect", ->
  templateUrl: "directives/builder/select/qb-select.directive.html"
  restrict: "A"
  replace: true
  scope:
    question: "=qbSelect"
    mode: "@"
    builder: "="
  controller: ($scope, Constants) ->
    $scope.question.body ?= ""

    $scope.states = _.map Constants._states, (s) -> s.constant.name
    $scope.countries = _.map Constants._countries, (c) -> c.constant.name
    $scope.presetChoices = [
      { label: "States",            value: "states" }
      { label: 'Countries',         value: 'countries' },
      { label: 'Grades (K-12)',     value: 'grades' },
      { label: 'Phone Types',       value: 'phones' },
      { label: 'Provinces',         value: 'provinces' },
      { label: 'States/Provinces',  value: 'statesProvinces' }
      { label: 'Numeric Scale',     value: 'numeric' }
      { label: 'Marital Status',    value: 'marital' }
      { label: 'Relationships',     value: 'relationships' }
      { label: 'Clothing Sizes',    value: 'sizes' }
      { label: 'Agreement Scale',   value: 'agreement' }
      { label: 'Race/Ethnicity',    value: 'race' }
    ]

    $scope.presets =
      states: $scope.states.sort()
      countries: $scope.countries
      grades: $scope.builder.grades
      phones: $scope.builder.phoneTypes
      provinces: $scope.builder.provinces
      statesProvinces: $scope.states.concat($scope.builder.provinces).sort()
      numeric: $scope.builder.numeric
      marital: $scope.builder.marital
      relationships: $scope.builder.relationships
      sizes: $scope.builder.sizes
      agreement: $scope.builder.agreement
      race: $scope.builder.race




    $scope.setDefaults = (choice) ->
      $scope.question.body = $scope.question.body.split(",").concat $scope.presets[choice]
      $scope.question.body = _.filter $scope.question.body, (b) -> b
      $scope.question.body = $scope.question.body.join(",\n")
