# This shows the fancy modal for rotating images and skipping through them.
# The editor is invoked with a few important data structures:
#
# "resource"  -> the raw resource we're looking at, be it an answer, an insurance, etc.
# "type"      -> the 'type' that the resource has, like above. "insurances"/"answers" etc
# "profile"   -> the profile to which the resource is tied. only really need its ID
# "show"      -> boolean value for showing the modal. we invoke the directive regardless.
# "images"    -> a structure with "urls" (from the Images resource), and
#                "value" (the raw string of UUIDs)
#                e.g. { urls: ['https://amazon...', ...], value: '123901c9jc...|1239dnnice' }

angular.module("dn").directive "imageEditor", ->
  templateUrl: "directives/image-editor/image-editor.directive.html"
  replace: true
  restrict: "E"
  scope:
    resource: "="
    type: "@"
    profile: "="
    images: "="
    index: "@"
    show: "="
    rotatePermissions: "="
  link: (scope, elm, attrs) ->
    scope.dialogStyle = {}
    if attrs.width then scope.dialogStyle.width = attrs.width
    if attrs.height then scope.dialogStyle.height = attrs.height

    scope.hideModal = ->

      # Restore to prevent weird behavior when switching between editors
      scope.currentImage = scope.images.urls[scope.originals.index]
      scope.currentKey = scope.images.value.split("|")[scope.originals.index]
      scope.index = scope.originals.index
      scope.show = false

  controller: ($scope, $http, $window, Images) ->

    $scope.currentImage = $scope.images.urls[$scope.index]
    $scope.currentKey   = $scope.images.value.split("|")[$scope.index]

    # If you close a modal, you want its state to be reset since it still persists
    # in the DOM, and therefore its scope is ACTUALLY still alive!
    $scope.originals =
      currentImage: _.clone $scope.currentImage
      currentKey:   _.clone $scope.currentKey
      index:        _.clone $scope.index


    # Open full size
    $scope.openImage = ->
      $window.open $scope.currentImage, "_blank"
      return # DONT REMOVE THIS https://github.com/angular/angular.js/issues/4853


    # Switch to a different index of the images
    $scope.switchPage = (index) ->
      if +index is +$scope.index then return

      $scope.currentImage  = $scope.images.urls[index]
      $scope.currentKey    = $scope.images.value.split("|")[index]
      $scope.index         = index


    # Make a POST request to rotate the image with graphics magick
    # then return and re-assign the URL and the currentImage
    # in $scope.images
    $scope.rotate = (degrees) ->
      $scope.rotating = true

      data =
        resource: $scope.resource
        type:     $scope.type
        key:      $scope.currentKey
        degrees:  degrees

      $http.post("/api/profiles/#{$scope.profile.id}/edit-image", data).then (result) ->
        newKey = result.data

        Images.getForProfile($scope.profile.id, newKey).then (urls) ->

          $scope.images.urls[$scope.index]  = urls[0]
          $scope.currentImage               = $scope.images.urls[$scope.index]
          $scope.currentKey                 = newKey
          $scope.rotating                   = false
