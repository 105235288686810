angular.module('dn').directive('bulkNotes', function() {
  return {
    templateUrl: 'directives/bulk-notes/bulk-notes.directive.html',
    restrict: 'E',
    scope: {
      profiles: '=',
      organization: '=',
      back: '=',
      fullTextSearchEnabled: '='
    },

    controller($http, $scope) {

      // Earliest notes were created in 2013
      const beginning = '2013-01-01';
      const today = moment().format('YYYY-MM-DD');

      $scope.validateExp = `gte:${beginning}|lte:${today}`;

      $scope.notesRange = {
        start: beginning,
        end: today
      };

      $scope.submit = function ({ start, end }) {
        if ($scope.fullTextSearchEnabled && !$scope.profiles.length) {
          return swal({
            type: 'warning',
            title: 'No Profiles Selected',
            text: 'No profiles selected to deactivatte notes.',
          });
        } else {
          return confirmSwal({ start, end });
        }
      };

      function confirmSwal({ start, end }) {
        $scope.buttonDisabled = true;
        const profileIDs = getProfiles();
        return swal({
          title: 'Bulk deactivate notes?',
          type: 'warning',
          html: true,
          text: `
            <p>This action will deactivate notes created between</p>
            <p><strong>${prettyDate(start)}</strong> and <strong>${prettyDate(end)}</strong></p>
            <p>for <strong>${profileIDs.length}</strong> ${profileIDs.length > 1 ? 'profiles' : 'profile'}</p>
            <p>Continue?</p>
          `,
          allowOutsideClick: true,
          allowEscapeKey: true,
          showCancelButton: true,
          showLoaderOnConfirm: true,
          closeOnConfirm: false
        }, (isConfirm) => {
          // will eventually be the route
          if (isConfirm) {
            bulkDeactivate({ profileIDs, start, end })
              .then(successSwal)
              .catch(errSwal);
          } else {
            $scope.buttonDisabled = false;
          }
        });
      }

      // old method, to be removed when FullTextSearchProfiles is fully implemented
      function getProfiles () {
        if ($scope.fullTextSearchEnabled) {
          return $scope.profiles;
        } else {
          return $scope.profiles.some(profile => profile.selected)
            ? $scope.profiles.filter(profile => profile.selected).map(profile => profile.profiles.id)
            : $scope.profiles.map(profile => profile.profiles.id);
        }
      }

      function prettyDate (date) {
        return moment(date).format('MMM DD, YYYY');
      }

      function bulkDeactivate ({ profileIDs, start, end }) {
        return $http.post(`/api/organizations/${$scope.organization.id}/notes/bulk`, { profileIDs, start, end });
      }

      function successSwal ({ data }) {
        $scope.buttonDisabled = false;
        return swal({
          title: 'Success',
          type: 'success',
          html: true,
          text: `<p>Succcessfully deactivated <strong>${data.count}</strong> notes.</p>`
        });
      }

      function errSwal () {
        $scope.buttonDisabled = false;
        return swal({
          type: 'error',
          title: 'Error',
          text: 'There was an error deactivating notes.',
          closeOnConfirm: true
        });
      }
    }
  };
});
