angular.module('dn').directive('bulkProfileRegistrations', function() {
  return {
    templateUrl: 'directives/bulk-profile-registrations/bulk-profile-registrations.directive.html',
    restrict: 'E',
    scope: {
      profile: '=',
      organization: '=',
      toggle: '=',
      registrations: '='
    },

    controller: function($scope, $state, $http, $filter, Registration) {
      'use strict';

      $scope.glCodesP2FlagEnabled = window.lib.featureFlagClient.isEnabled('GLCodesPriority2');

      $scope.regMap = [
        { key: 'provider', title: 'Provider', selected: false },
        { key: 'future', title: 'Upcoming', selected: false },
        { key: 'present', title: 'Current', selected: false },
        { key: 'past', title: 'Past', selected: false }
      ];

      $scope.loading = true;
      $scope.disableSubmit = null;

      $scope.tuitionMap = {};
      $scope.tuitionBalance = 0;
      $scope.adjustmentBalance = 0;
      $scope.cancellationBalance = 0;

      $scope.flatRegs = $scope.regMap.reduce((acc, { key }) => {
        $scope.registrations[key].forEach((reg) => {
          acc.push(reg);
        });
        return acc;
      }, []);

      getTuitions($scope.profile.id);

      $scope.toggleReg = (reg) => {
        toggleCancellation(reg);
        updateRegsToDeactivate();
        updateTuitionBalance();
      };

      $scope.toggleRegType = ({ key, selected }) => {
        $scope.registrations[key].forEach((reg) => {
          reg.bulkDeactivate = selected;
        });
      };

      $scope.updateCancellationBalance = () => {
        $scope.cancellationBalance = $scope.flatRegs.reduce((acc, { bulkDeactivate, cancellationFee }) => {
          if (bulkDeactivate && cancellationFee) {
            acc += (cancellationFee / 100);
          }
          return acc;
        }, 0);
      };

      function toggleCancellation (reg) {
        if (!reg.bulkDeactivate) {
          reg.cancellationFee = 0;
        }
      }

      function updateRegsToDeactivate () {
        $scope.regsToDeactivate = $scope.flatRegs.filter((r) => r.bulkDeactivate);
      }

      function updateTuitionBalance () {
        $scope.tuitionBalance = 0;
        $scope.adjustmentBalance = 0;
        $scope.flatRegs.forEach(({ id, bulkDeactivate }) => {
          if (bulkDeactivate) {
            $scope.tuitionBalance += $scope.tuitionMap[id] && $scope.tuitionMap[id].amount || 0;
            $scope.adjustmentBalance += $scope.adjustmentMap[id] || 0;
          }
        });
      }

      function getTuitions (profileID) {
        return $http.get(`/api/profiles/${profileID}/crebits`).then(({ data }) => {
          const adjustments = {};
          if ($scope.glCodesP2FlagEnabled) {
            data = data.filter((crebit) => {
              return crebit.addOnID || crebit.registrationID;
            });
          }

          data.forEach(({ id, registrationID, adjusts, amount }) => {
            amount = amount / 100;
            if (adjusts && !adjustments[adjusts]) {
              adjustments[adjusts] = amount;
            } else if (adjusts) {
              adjustments[adjusts] += amount;
            }

            if (registrationID && !$scope.tuitionMap[registrationID]) {
              $scope.tuitionMap[registrationID] = { amount, crebitID: id };
            } else if (registrationID) {
              $scope.tuitionMap[registrationID].amount += amount;
            }
          });

          $scope.adjustmentMap = Object.entries($scope.tuitionMap).reduce((acc, [regID, { crebitID }]) => {
            if (adjustments[crebitID]) {
              acc[regID] = adjustments[crebitID];
            }
            return acc;
          }, {});

          $scope.loading = false;
          updateTuitionBalance();
        });
      }

      $scope.submit = () => {
        $scope.disableSubmit = true;
        let text
          = `
          <p>You are about to deactivate <strong>${$scope.regsToDeactivate.length}</strong>
          ${regPlural($scope.regsToDeactivate.length)}.</p>
          <br />
          `;

        const amount = $scope.tuitionBalance + $scope.adjustmentBalance - $scope.cancellationBalance;

        if (amount === 0) {
          text
            += `
              <p>${$filter('possessive')($scope.profile.givenName)} ${regPlural($scope.regsToDeactivate.length)} will be deactivated
              without a change in balance to the account.</p>
            `;
        } else {
          const action = amount > 0 ? { verb: 'credit', prep: 'to' } : { verb: 'debit', prep: 'from' };
          text
            += `
              <p>Deactivating will ${action.verb} <strong>${$filter('currency')(Math.abs(amount))}</strong></p>
              <p>${action.prep} ${$filter('possessive')($scope.profile.givenName)} account.</p>
            `;
        }

        window.swal({
          title: 'Deactivate Registrations',
          text,
          showCancelButton: true,
          html: true,
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, (confirmed) => {
          if (confirmed) {
            handleBulkDeactivate($scope.regsToDeactivate);
          } else {
            $scope.disableSubmit = false;
            return;
          }
        });
      };

      function handleBulkDeactivate (registrations) {
        let regCount = 0;
        const text
          = `
            <p
              id="reg-bulk-deactivation-progress"
            >
              Finished deactivating <span id="reg-bulk-deactivation-progress-count">
              ${regCount}</span>/${registrations.length} ${regPlural(registrations.length)}
            </p>
          `;
        window.swal({
          title: 'Registration deactivation in progress',
          text,
          html: true
        });

        async.eachSeries(registrations, handleDeactivate, doneDeactivating);

        function handleDeactivate (registration, afterDeactivate) {
          Registration.deactivateFlow(registration).then(() => {
            regCount++;
            document.querySelector('#reg-bulk-deactivation-progress-count').innerHTML = regCount;
            return afterDeactivate(null);
          }, () => {
            // Don't do anything with the actual error, but pass the offending registration in
            // as the error object, so that the user can navigate to that page to see what's up
            return afterDeactivate({ registration });
          });
        }

        function doneDeactivating (err) {
          if (err) {
            return errSwal(err);
          } else {
            return successSwal(regCount);
          }
        }
      }

      function regPlural (regCount) {
        return `registration${regCount > 1 ? 's' : '' }`;
      }

      function successSwal (regCount) {
        const text
          = `
            <p>Successfully deactivated <strong>${regCount}</strong> ${regPlural(regCount)}
            for ${$scope.profile.givenName} ${$scope.profile.familyName}</p>
          `;
        return window.swal({
          type: 'success',
          title: 'Success',
          text,
          html: true,
          confirmButtonText: 'Ok'
        }, () => {
          return $state.reload();
        });
      }

      function errSwal ({ registration: { group, id } }) {
        const text
          = `
            <p>There was an error deactivating ${$filter('possessive')($scope.profile.givenName)}
            registration to <strong>${group.name}</strong>.</p>
            <br />
            <p>Click 'See Registration' below to find out more.</p>
          `;
        return window.swal({
          type: 'error',
          title: 'Error',
          text,
          html: true,
          confirmButtonText: 'See Registration',
          showCancelButton: true
        }, (confirmed) => {
          if (confirmed) {
            return $state.go('manager.profiles.profile.registrations.registration', { profileID: $scope.profile.id, registrationID: id });
          } else {
            return $state.reload();
          }
        });
      }
    }
  };
});
