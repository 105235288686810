lib.registerState "oper.transactions.transaction",
  url: "/:confirmation"
  templateUrl: "states/oper/transactions/transaction/transaction.state.html"
  controller: ($scope, $stateParams, $http, $state) ->
    $scope.$parent.confirmation ?= $stateParams.confirmation
    $scope.processing = null

    $http.get("/api/operator/transaction/#{$stateParams.confirmation}")
      .error (result) ->
        swal "Not Found", "Please double-check the confirmation number.", "error"
        $scope.$parent.confirmation = ""
        $state.go "^"
      .success (result) ->
        $scope.crebit = result
        return unless $scope.crebit.paymentMethod
        $http.get("/api/operator/getPlans?methodID=#{$scope.crebit.paymentMethod.id}").then (result) ->
          $scope.paymentPlanIDs = result.data[0].array_agg

    $scope.adjust = ->
      $scope.processing = true
      $http.post("/api/operator/transaction-reverse", {confirmation: $stateParams.confirmation,reason:$scope.chargebackReason})
        .error (err) ->
          $scope.processing = null
          swal({
            title: "Error",
            text: """
              There was a problem creating this adjustment.</br>
              </br>
              <b>#{err}</b></br>
              </br>
              Line items may have already been created.
            """,
            type: "error",
            html: true,
          })
        .success (result) ->
          swal({
            title: "Adjustment Processed"
            type: "success"
            text: if result == 'No paymentMethodID' then "Notification not created" else undefined
          })
          $scope.processing = null
          $state.reload()

    $scope.deacMethod = (payMethodID) ->
      window.swal {
        title: "Deactivate Method"
        text: "Are you sure you wish to deactivate this method?"
        showCancelButton: true
        type: "warning"
        closeOnConfirm: false
      },
        (isConfirm) ->
          if isConfirm
            $http.put("/api/operator/deacMethod", {payMethodID}).then (result) ->
              window.swal {
                title: "Method Deactivated"
                text: "Deactivated set to #{JSON.stringify result.data}"
                type: "success"
                timer: 2000
              }



    $scope.deacPlan = (payMethodID) ->
      window.swal {
        title: "Deactivate Method"
        text: "Are you sure you wish to deactivate associated plans? (#{$scope.paymentPlanIDs})"
        showCancelButton: true
        type: "warning"
        closeOnConfirm: false
        },
        (isConfirm) ->
          if isConfirm
            $http.put("/api/operator/deacPlan", {payMethodID}).then (result) ->
              window.swal {
                title: "Plan Deactivated"
                text: "Deactivated set to #{JSON.stringify result.data}"
                type: "success"
                timer: 2000
              }
