angular.module("dn").directive "qbInstructions", ->
  templateUrl: "directives/builder/instructions/qb-instructions.directive.html"
  replace: true
  restrict: "A"
  scope:
    question: "=qbInstructions"
    mode: "@"
  controller: ($scope) ->
    $scope.question.required = false
    $scope.question.label = " "
