lib.registerState("unsubscribe", {
  url: "/unsubscribed",
  templateUrl: "states/unsubscribe/unsubscribe.state.html",
  controller: function($scope, $http, $location, WhatDivision) {
    $scope.division = WhatDivision.lcName;

    // manual DOM manipulation to remove topbar icons
    let icons = document.getElementsByClassName('icons')[0];
    icons.parentNode.removeChild(icons);

    const type          = $location.search().type;
    const userID        = $location.search().uid;
    const profileUserID = $location.search().puid;

    $scope.canResubscribe = type && userID && profileUserID;

    $scope.resubscribe = function() {
      if (!$scope.canResubscribe) return;
      $http.get(`/api/users/${userID}/subscribe?type=${type}&puid=${profileUserID}`).then(
        (success) => {
          swal("Subscribed!", "You have been successfully re-subscribed to this mailing list", "success");
        }
      , (error) => {
        swal("Error!", "There was a problem re-subscribing to this mailing list", "error");
      })
    }

  }
});
