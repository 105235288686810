angular.module("dn").directive("qbTranslations", () => {
  return {
    templateUrl: "directives/builder/modules/translations/qb-translations.directive.html",
    replace: true,
    restrict: "A",
    scope: {
      question: "=question"
    },

    controller: function($scope) {
      $scope.initTranslation = (lang) => {
        $scope.question.metadata.translations[lang] = $scope.question.metadata.translations[lang] ? $scope.question.metadata.translations[lang] : {}
        $scope.question.metadata.translations[lang].label = $scope.question.metadata.translations[lang].label ? $scope.question.metadata.translations[lang].label : '';
        $scope.question.metadata.translations[lang].body = $scope.question.metadata.translations[lang].body ? $scope.question.metadata.translations[lang].body : '';
      }

      $scope.languages = $scope.$root.organization.properties.languages ? $scope.$root.organization.properties.languages : []
      $scope.question.selectedLanguage = $scope.languages[0]
    }
  }
})
